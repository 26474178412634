import tw from 'twin.macro'
import * as Popover from '@radix-ui/react-popover'

import { AddItemButton } from 'common/components/AddItemButton/AddItemButton'
import { buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'

import { createUID } from 'common/utils/createUID'
import { getNewLink } from './constants'
import { addItemClasses } from './styles'

export function NoItemsCard({ coachOrgId, existingItems, handleAdd }) {
  return (
    <div className='flex flex-col items-center p-2 rounded-md border shadow-sm relative'>
      <h3 className='text-center font-bold text-lg text-tBlack mt-4'>No items yet</h3>
      <p className='text-xs text-center text-tBlack mb-2'>Click Add Item to add your first collection item</p>
      <div className='my-4'>
        <AddItemButton
          coachOrgId={coachOrgId}
          existingItems={existingItems}
          handleAdd={handleAdd}
          btnCSS={addItemClasses}
          iconClasses='w-4 h-4 mr-2'
          hideItems={[availableItemTypes.COLLECTION]}
          isPortalled={false}
        >
          <Popover.Close
            css={[buttonBase, buttonVariants.secondary, buttonSizes.md, tw`flex-1 flex px-4`]}
            onClick={() => handleAdd(getNewLink(createUID()))}
          >
            <span className='first-letter:capitalize text-left flex-1'>Link</span>
          </Popover.Close>
        </AddItemButton>
      </div>
    </div>
  )
}
