import 'twin.macro'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { transform, filter, isEqual } from 'lodash'

import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useSetLinkMutation, useUpdateLinkHubMutation } from '../linkHubApi'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'

import { schema } from './schema'
import { Input } from 'common/components/Input/Input'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { DeleteConfirmationBanner } from 'common/components/DeleteConfirmationBanner/DeleteConfirmationBanner'
import { createUID } from 'common/utils/createUID'

export default function CreateLinkForm({
  coachOrgId,
  length,
  linkKey = null,
  resource = null,
  hideDelete = false,
  id,
  resources,
}) {
  const [setLink] = useSetLinkMutation()
  const [updateLinkHub] = useUpdateLinkHubMutation()

  const [, setDialogOpen] = useDialog()
  const { createAlert } = useAlert()

  const [loading, setLoading] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  const defaultValues = {
    title: resource?.title || '',
    description: resource?.description || '',
    link: resource?.link || '',
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const {
    watch,
    register,
    formState: { errors },
    handleSubmit,
  } = methods

  const formState = watch()

  const onSubmit = async (data) => {
    const linkData = transform(
      data,
      (acc, value, key) => {
        // remove empty string formdata
        if (typeof value === 'string' && value.trim().length === 0) {
          return
        }
        return (acc[key] = value)
      },
      {}
    )
    const alertText = resource ? 'Link updated!' : 'Link created!'

    setLoading(true)

    const isNewLink = linkKey === null
    if (isNewLink) {
      linkData.id = createUID()
    }

    await setLink({ coachOrgId, linkData, linkKey: isNewLink ? length : linkKey })

    setLoading(false)
    createAlert({ text: alertText, type: 'success' })
    setDialogOpen(false)
  }

  const handleDelete = async () => {
    const updatedResources = filter(resources, (resource, idx) => {
      return resource.id !== id
    })
    setLoading(true)
    await updateLinkHub({ coachOrgId, updatedLinkHub: { resources: updatedResources } })

    setLoading(false)
    createAlert({ text: 'Link deleted!', type: 'success' })
    setDialogOpen(false)
  }

  const { addManyInputRefs, moveFocusOnKeyPress } = useFormRefsControl()
  const titleRef = useRef()
  const descriptionRef = useRef()
  const linkRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    addManyInputRefs([
      { ref: titleRef, name: 'title' },
      { ref: descriptionRef, name: 'description' },
      { ref: linkRef, name: 'link' },
      { ref: submitRef, name: 'submit' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', (e) => moveFocusOnKeyPress(e, handleSubmit(onSubmit)))

  if (length > 20) {
    return (
      <div className='flex flex-col items-center justify-center py-8'>
        <p className='text-lg font-bold text-tGray-dark'>The max number of links is 20</p>
        <p className='text-tGray-dark'>Please delete some links to create new ones</p>
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form onSubmit={handleSubmit(onSubmit)} id='linkForm'>
          <div className='flex flex-col px-10 py-4'>
            <TextInput name='title' register={register} inputRef={titleRef} errors={errors} autoFocus={true} />
            <TextInput name='description' register={register} inputRef={descriptionRef} errors={errors} optional />
            <TextInput name='link' register={register} inputRef={linkRef} errors={errors} />
          </div>
        </form>
      </div>
      {resource && deleteConfirmation ? (
        <DeleteConfirmationBanner
          text='Are you sure?'
          handleDelete={handleDelete}
          handleGoBack={() => setDeleteConfirmation(false)}
          loading={loading}
        />
      ) : (
        <UpdateActions
          itemKey={`${linkKey}`}
          handleSubmit={handleSubmit}
          handleDelete={() => setDeleteConfirmation(true)}
          loading={loading}
          actionText={resource ? 'Save' : 'Create'}
          disabled={isEqual(formState, defaultValues)}
          form='linkForm'
          hideDelete={hideDelete}
          ref={submitRef}
        />
      )}
    </FormProvider>
  )
}

const TextInput = ({ register, inputRef, errors, name, optional, autoFocus }) => (
  <div className='mb-2'>
    <label htmlFor={name} className='capitalize inline-flex cursor-pointer font-semibold text-tBlack' tw='mb-1'>
      {name}
    </label>
    <Input
      name={name}
      type='text'
      placeholder={`Enter ${name}${optional ? ' (optional)' : ''}`}
      register={register}
      inputRef={inputRef}
      error={errors[name]?.message}
      autoFocus={autoFocus}
    />
    {errors[name] && <p className='flex text-xs mt-1 text-tRed'>{errors[name]?.message}</p>}
  </div>
)
