import tw from 'twin.macro'
import React from 'react'

import { useListenProgramGroupsQuery } from 'modules/Layout/layoutApi'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'

import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { LayoutCard } from './LayoutCard'

export const ProgramGroupCard = React.memo(({ item, handleDelete }) => {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''
  const { data: programGroups } = useListenProgramGroupsQuery({ coachOrgId })
  const programGroupsLoading = programGroups === undefined || programGroups?.isLoading

  if (programGroupsLoading) {
    return <Skeleton css={tw`w-60 h-44 rounded-xl`} />
  }

  const programGroup = programGroups?.[item?.id]

  if (!programGroup) {
    return (
      <LayoutCard
        id={item.id}
        type={item.type}
        name='This series has been deleted'
        handleDelete={() => handleDelete(item)}
      />
    )
  }

  return (
    <LayoutCard
      id={item.id}
      type={item.type}
      name={programGroup.name}
      image={programGroup.previewImg}
      subtitle={programGroup.subtitle}
      handleDelete={() => handleDelete(item)}
    />
  )
})
