import React from 'react'
import tw from 'twin.macro'

export function InputGroup({ children, className }) {
  const updatedChildren = React.Children.map(children, (child) => {
    // OR checking required for Twin styled Input which uses forwardRef
    if (child.type?.name === 'Input' || child.type?.target?.name === 'Input') {
      return React.cloneElement(child, {
        className: 'flex-1 odd:pl-0 odd:pr-4 first-of-type:ring-0',
      })
    }

    // Must check if child is not null or undefined
    if (child) {
      return React.cloneElement(child)
    }
  })
  return <Container className={className}>{updatedChildren}</Container>
}

const Container = tw.div`flex items-center w-full rounded-xl overflow-hidden bg-white ring-1 border-0 ring-gray-300 focus-within:ring-tGreen px-4`
