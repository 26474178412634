import React, { useState } from 'react'
import { CgPen } from 'react-icons/cg'

import { useUpdateLinkHubMutation } from './linkHubApi'

export default function ResourcesDescription({ coachOrgId, description }) {
  const [editMode, setEditMode] = useState(false)
  const [descriptionValue, setDescriptionValue] = useState(description)
  const [updateLinkHub] = useUpdateLinkHubMutation()

  const handleSubmit = () => {
    if (descriptionValue) {
      updateLinkHub({ coachOrgId, updatedLinkHub: { description: descriptionValue } })
    }
    setEditMode(false)
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') handleSubmit()
  }

  return (
    <div>
      <div className='flex flex-row items-center mt-4 mb-6 h-10 w-1/2'>
        {editMode ? (
          <input
            onBlur={handleSubmit}
            autoFocus
            onKeyDown={onKeyDown}
            onChange={(e) => setDescriptionValue(e.target.value)}
            value={descriptionValue}
            className='font-bold text-2xl text-tGray-dark mr-6 bg-offWhite w-full focus:outline-none'
          />
        ) : (
          <h1 className='font-bold text-2xl text-tGray-dark mr-6'>{description || 'Description'}</h1>
        )}
        {!editMode ? (
          <CgPen onClick={() => setEditMode(true)} className='w-5 h-5 text-tGray-med cursor-pointer' />
        ) : null}
      </div>
    </div>
  )
}
