import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { CgAdd } from 'react-icons/cg'
import { IoIosWater } from 'react-icons/io'
import { TbVideoPlus } from 'react-icons/tb'
import tw, { styled } from 'twin.macro'
import {
  useAddRestDayMutation,
  useCreateWorkoutMutation,
  useCleanWorkoutMutation,
  useListenWorkoutQuery,
  useAddVideoDayMutation,
} from '../programApi'
import Workout from 'modules/Programs/components/Workout'
import { DayHeader } from './DayHeader'
import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { DayHeaderContainer } from './styles'
import { Button } from 'common/components/Button/Button'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { RefsControlProvider } from 'common/components/RefsControl/WorkoutRefsControl/context'
import { titleDescMinHeight, headerMinHeight, dayViewMinHeight } from '../constants/dayViewStyleConstants'
import { useEventListener } from 'common/hooks/useEventListener'
import { weekRenderTypes } from '../programRenderingSlice'
import { useMonitorCurrWktDragging } from '../hooks/useMonitorCurrWktDragging'

const SEL_DAY_PERC_WIDTH = 0.22

const Container = styled.div(({ isEditing }) => [
  tw`relative flex flex-col bg-white border-l border-r h-full`,
  !isEditing && tw`hover:cursor-pointer`,
  isEditing ? tw`border border-tGray-ml` : tw`border-gray-200`,
])

const Day = (props) => {
  const {
    setEditIdx,
    dayIdx,
    weekIdx,
    workoutId,
    attributes,
    listeners,
    orgId,
    isEditing,
    isEditingSameWk,
    weekWidth,
    setModalWorkout,
    setEditWorkoutInfoModalOpen,
  } = props

  const cardW = getDayCardWidth({
    isEditing,
    isEditingSameWk,
    weekWidth,
  })

  const [cleanWorkout] = useCleanWorkoutMutation()

  const {
    data: workout = {},
    isLoading,
    isFetching,
  } = useListenWorkoutQuery({
    orgId,
    workoutId,
  })

  // When deleting week, workouts have only ids and parts
  const weekDeleting = workout.title === undefined && workout.dayIdx === undefined && workout.type === undefined
  const hasLoaded = !isLoading && !isFetching && !workout.stillLoading && !weekDeleting

  const clearEditIdxOnEsc = (e) => {
    if (isEditing && e.key === 'Escape') {
      setEditIdx(null)
    }
  }

  useEventListener('keydown', clearEditIdxOnEsc)

  const isWktDragging = useMonitorCurrWktDragging({ workoutId })

  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      //prevent calling on mount
      isMounted.current = true
    } else if (!isEditing && !isWktDragging) {
      cleanWorkout({
        orgId,
        workoutId: workout.id,
        workout,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  const titleRef = useRef()

  if (hasLoaded && props.renderType === weekRenderTypes.show) {
    return (
      <Container
        data-testid='dayContainer'
        className='group'
        style={{ width: cardW, minHeight: dayViewMinHeight }}
        isEditing={isEditing}
        onClick={(e) => {
          if (!isEditing) {
            if (workout.type === 'workout' && e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA') {
              titleRef.current.focus()
            }
          }
        }}
      >
        {workout ? (
          <>
            <DayHeader
              dayIdx={dayIdx}
              setEditIdx={setEditIdx}
              workout={workout}
              isEditing={isEditing}
              dragHandleProps={{ attributes, listeners }}
              orgId={orgId}
              setModalWorkout={setModalWorkout}
              setEditWorkoutInfoModalOpen={setEditWorkoutInfoModalOpen}
            />
            {workout.type === 'empty' ? (
              <EmptyDayCard workout={workout} orgId={orgId} setEditIdx={setEditIdx} dayIdx={dayIdx} />
            ) : (
              <RefsControlProvider isEditing={isEditing}>
                <Workout
                  setEditIdx={setEditIdx}
                  dayIdx={dayIdx}
                  weekIdx={weekIdx}
                  workout={workout}
                  isEditing={isEditing}
                  orgId={orgId}
                  titleRef={titleRef}
                />
              </RefsControlProvider>
            )}
          </>
        ) : null}
      </Container>
    )
  } else {
    return (
      <Container style={{ width: cardW, minHeight: dayViewMinHeight }}>
        <DayHeaderContainer style={{ minHeight: headerMinHeight }}>
          <Skeleton css={tw`w-10`} />
        </DayHeaderContainer>
        <div
          className='flex flex-col justify-center p-4 border-b border-gray-200'
          style={{ minHeight: titleDescMinHeight }}
        >
          <Skeleton css={tw`w-3/6 mb-6`} />
          <Skeleton css={tw`w-5/6`} />
        </div>
        <div className='h-full' />
      </Container>
    )
  }
}

const getDayCardWidth = ({ isEditing, isEditingSameWk, weekWidth }) => {
  let cardW

  //if nothing is selected, all widths should be week container width / 7
  if (weekWidth < 1024) {
    cardW = weekWidth
  } else if (isEditing) {
    //is selected
    cardW = weekWidth * SEL_DAY_PERC_WIDTH
  } else if (isEditingSameWk) {
    //not selected but same week
    cardW = (weekWidth * (1 - SEL_DAY_PERC_WIDTH) * 1) / 6
  } else if (!isEditingSameWk) {
    cardW = weekWidth / 7
  } else {
    //default to 1/7
    cardW = weekWidth / 7
  }

  return cardW
}

const EmptyHoverBlock = tw.div`
  flex
  items-start
  justify-center
  px-4
  py-14
  h-full
`

const btnClasses = tw`
  bg-gray-100
  text-gray-600
  hover:bg-gray-100
  ring-2
  ring-gray-100
  hover:ring-tGreen
  rounded-md
  w-12
  px-3
  py-1.5
`

export const EmptyDayCard = ({ workout, alwaysShowActions = false, orgId, setEditIdx, dayIdx }) => {
  const { id: programId } = useParams()
  const [createWorkout] = useCreateWorkoutMutation()
  const [addRestDay] = useAddRestDayMutation()
  const [addVideoDay] = useAddVideoDayMutation()

  const actionContainerClassnames = alwaysShowActions
    ? `flex items-stretch flex-wrap justify-center gap-4`
    : `hidden group-hover:flex items-stretch flex-wrap justify-center gap-4`

  return (
    <EmptyHoverBlock onClick={() => setEditIdx(null)}>
      <div className={actionContainerClassnames}>
        <Tooltip content='Add workout' triggerClasses='flex'>
          <Button
            size='md'
            variant='secondary'
            css={btnClasses}
            onClick={(e) => {
              e.stopPropagation()
              if (setEditIdx) {
                setEditIdx(dayIdx)
              }
              createWorkout({ orgId, programId, workoutId: workout.id, workout })
            }}
          >
            <CgAdd className='w-5 h-5' />
          </Button>
        </Tooltip>
        <Tooltip content='Add rest day' triggerClasses='flex'>
          <Button
            size='md'
            variant='secondary'
            css={btnClasses}
            onClick={() => addRestDay({ orgId, workoutId: workout.id, workout })}
          >
            <IoIosWater className='w-[22px] h-[22px] text-gray-500 text-opacity-95' />
          </Button>
        </Tooltip>
        <Tooltip content='Add video' triggerClasses='flex'>
          <Button
            size='md'
            variant='secondary'
            css={btnClasses}
            onClick={() => addVideoDay({ orgId, workoutId: workout.id, workout })}
          >
            <TbVideoPlus className='w-[22px] h-[22px]' />
          </Button>
        </Tooltip>
      </div>
    </EmptyHoverBlock>
  )
}

function areEqual(prevProps, nextProps) {
  const propKeys = Object.keys(nextProps)
  const propsChanged = []

  propKeys.forEach((key) => {
    const isSame = prevProps[key] === nextProps[key]
    if (!isSame) {
      propsChanged.push(key)
    }
  })

  if (propsChanged.length) {
    // console.log('===== DayView.js dayIdx:', nextProps.dayIdx, '===== ')
    // console.log('propsChanged', propsChanged)

    return false
  } else {
    return true
  }
}

export const DayView = React.memo(Day, areEqual)
