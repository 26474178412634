import React, { useEffect, useRef } from 'react'
import * as yup from 'yup'
import tw from 'twin.macro'
import { CgTrash } from 'react-icons/cg'
import { FiMove } from 'react-icons/fi'
import { CgImage } from 'react-icons/cg'
import { isEmpty } from 'lodash'
import { useFormContext } from 'react-hook-form'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { validHttpsUrlRegex } from 'common/utils/fileUploading/validators'
import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { actionContainerClasses, inputClasses } from './styles'

export const linkTitleErrKey = 'linkTitle'
export const linkUrlErrKey = 'linkUrl'
export const linkImgErrKey = 'linkImg'

export const urlSchema = yup
  .string()
  .matches(validHttpsUrlRegex, {
    excludeEmptyString: true,
    message: 'Link must include https and be a valid URL ',
  })
  .required('Link is required')

export const LinkInputCard = React.memo(
  ({
    index,
    resource,
    handleDelete,
    setErrors,
    error,
    coachOrgId,
    previewImg,
    programId,
    collectionId,
    uploadType,
    onUpload,
    uploadDisabled = false,
    refsSortMethod,
    updateValue,
    children,
    listeners,
    attributes,
  }) => {
    const { register } = useFormContext()

    const titleInputRef = useRef()
    const descriptionInputRef = useRef()
    const urlInputRef = useRef()
    const { addInputRef } = useFormRefsControl()
    useEffect(() => {
      addInputRef({
        ref: titleInputRef,
        name: `resource-${resource?.id}`,
        sortMethod: refsSortMethod,
      })

      addInputRef({
        ref: descriptionInputRef,
        name: `resource-${resource?.id}`,
        sortMethod: refsSortMethod,
      })

      addInputRef({
        ref: urlInputRef,
        name: `resource-${resource?.id}`,
        sortMethod: refsSortMethod,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const validateInput = (errorKey, errorMessage, isValid) => {
      if (!isValid) {
        setErrors((errors) => {
          let errorsUpdate = { ...errors }
          const currResourceErrors = errorsUpdate[resource?.id] || {}
          errorsUpdate[resource?.id] = { ...currResourceErrors, [errorKey]: errorMessage }

          return { ...errorsUpdate }
        })
      } else {
        setErrors((errors) => {
          let errorsUpdate = { ...errors }

          if (!isEmpty(errorsUpdate[resource?.id])) {
            delete errorsUpdate[resource?.id][errorKey]
          } else {
            delete errorsUpdate[resource?.id]
          }

          return { ...errorsUpdate }
        })
      }
    }

    useEffect(() => {
      const isTitleValid = (resource?.title || '').trim().length > 0
      if (!isTitleValid) {
        validateInput(linkTitleErrKey, 'Title is required', false)
      }

      urlSchema.validate(resource?.url).catch((error) => {
        validateInput(linkUrlErrKey, error.message, false)
      })
      // eslint-disable-next-line
    }, [])

    return (
      <div className='group flex flex-col relative p-2 rounded-md border shadow-sm bg-white'>
        <div className='relative flex items-center'>
          <div i css={actionContainerClasses} className='group-hover:visible'>
            <Tooltip content='Drag to reorder' triggerClasses='flex'>
              <button {...attributes} {...listeners} tabIndex={-1}>
                <FiMove className='cursor-move w-3.5 h-3.5 my-[3px] text-gray-500 hover:text-tGreen' />
              </button>
            </Tooltip>
            <Tooltip content='Delete'>
              <CgTrash
                className='cursor-pointer ml-1.5 w-[18px] h-[18px] text-gray-500 hover:text-tRed'
                onClick={() => handleDelete([titleInputRef, descriptionInputRef, urlInputRef])}
              />
            </Tooltip>
          </div>
        </div>
        <label
          htmlFor={`title-${resource?.id}`}
          className='inline-flex cursor-pointer font-semibold text-gray-500 text-xs mt-1 mx-1'
        >
          Title
        </label>
        <input
          id={`title-${resource?.id}`}
          ref={titleInputRef}
          type='text'
          css={[inputClasses, error?.[linkTitleErrKey] && tw`ring-1 focus:ring-1 ring-red-500 focus:ring-red-500`]}
          className='text-ellipsis bg-inherit'
          placeholder='Title'
          value={resource?.title || ''}
          onBlur={(e) => {
            const isValid = e.target.value?.trim()?.length > 0
            validateInput(linkTitleErrKey, 'Title is required', isValid)
          }}
          onChange={(e) => {
            updateValue({ ...resource, title: e.target.value })
            const isValid = e.target.value?.trim()?.length > 0
            validateInput(linkTitleErrKey, 'Title is required', isValid)
          }}
          autoFocus={index === 0}
          maxLength={40}
        />
        {error?.[linkTitleErrKey] && (
          <div className='flex flex-col text-xxs mt-1 ml-0.5'>
            <span className='text-tRed'>{error[linkTitleErrKey]}</span>
          </div>
        )}
        <label
          htmlFor={`description-${resource?.id}`}
          className='inline-flex cursor-pointer font-semibold text-gray-500 text-xs mt-2 mx-1'
        >
          Description
        </label>
        <input
          ref={descriptionInputRef}
          id={`description-${resource?.id}`}
          type='text'
          css={inputClasses}
          className='text-ellipsis bg-inherit'
          placeholder='Description (Optional)'
          value={resource?.description || ''}
          onChange={(e) => {
            updateValue({ ...resource, description: e.target.value })
          }}
          maxLength={60}
        />
        <div className='flex flex-col'>
          <label
            htmlFor={`url-${resource?.id}`}
            className='inline-flex cursor-pointer font-semibold text-gray-500 text-xs mt-2 mx-1'
          >
            Link URL
          </label>
          <input
            ref={urlInputRef}
            id={`url-${resource?.id}`}
            type='text'
            css={[inputClasses, error?.[linkUrlErrKey] && tw`ring-1 focus:ring-1 ring-red-500 focus:ring-red-500`]}
            className='text-ellipsis bg-inherit'
            placeholder='Link URL'
            value={resource?.url || ''}
            onBlur={(e) => {
              try {
                urlSchema.validateSync(e.target.value)
                validateInput(linkUrlErrKey, '', true)
              } catch (error) {
                validateInput(linkUrlErrKey, error.message, false)
              }
            }}
            onChange={(e) => {
              updateValue({ ...resource, url: e.target.value })
              try {
                urlSchema.validateSync(e.target.value)
                validateInput(linkUrlErrKey, '', true)
              } catch (error) {
                validateInput(linkUrlErrKey, error.message, false)
              }
            }}
          />
        </div>
        {error?.[linkUrlErrKey] && (
          <div className='flex flex-col text-xxs mt-1 ml-0.5'>
            <span className='text-tRed'>{error[linkUrlErrKey]}</span>
          </div>
        )}
        <UploadInput
          name='previewImg'
          label='Image'
          register={register}
          setValue={(name, liveUrl) => {
            updateValue({ ...resource, previewImg: liveUrl })
          }}
          coachOrgId={coachOrgId}
          id={resource?.id}
          uploadType={uploadType}
          liveUrl={previewImg || resource?.previewImg}
          setError={(name, error) => {
            if (error?.message) {
              setErrors((errors) => {
                console.log('setErrors ~ errors:', errors)
                let errorsUpdate = { ...errors }
                const currResourceErrors = errorsUpdate[resource?.id] || {}
                errorsUpdate[resource?.id] = { ...currResourceErrors, [linkImgErrKey]: error.message }

                return { ...errorsUpdate }
              })
            }
          }}
          previewIcon={<CgImage className='!w-5 !h-5' />}
          clearErrors={(name) => {
            setErrors((errors) => {
              let errorsUpdate = errors
              if (!isEmpty(errorsUpdate[resource?.id])) {
                delete errorsUpdate[resource?.id][linkImgErrKey]
              } else {
                delete errorsUpdate[resource?.id]
              }

              return { ...errorsUpdate }
            })
          }}
          onUpload={(e) => {
            if (onUpload) {
              onUpload(e)
            }
          }}
          fileType='image'
          programId={programId}
          collectionId={collectionId}
          labelStyle={'text-gray-500 text-sm mx-1'}
          buttonStyle={'w-8 h-8'}
          statusStyle={'text-xs'}
          uploadDisabled={uploadDisabled}
          uploadDisabledReason='Please fix form errors before uploading'
        />
        {error?.[linkImgErrKey] && (
          <div className='flex flex-col text-xxs mt-1 ml-0.5'>
            <span className='text-tRed'>{error[linkImgErrKey]}</span>
          </div>
        )}
        {children}
      </div>
    )
  }
)
