import { useEffect, useState } from 'react'
import { useReactFlow, getNodesBounds } from '@xyflow/react'

const DEFAULT_SELECT_ZOOM = 1.2

function UpdateQuizLayout({ selectedNodeId, layoutedNodes }) {
  const { setCenter, getViewport, setViewport } = useReactFlow()
  const [prevViewport, setPrevViewport] = useState(null)
  const nodeCount = layoutedNodes?.length

  useEffect(() => {
    if (selectedNodeId) {
      const viewport = getViewport()
      if (!prevViewport) {
        setPrevViewport(viewport)
      }
      const layoutedSelectedNode = layoutedNodes.find((n) => n?.id === selectedNodeId)
      const isFirstQuestion =
        layoutedSelectedNode?.type === 'question' && !layoutedSelectedNode?.data?.previousQuestionId
      const selectedNodeBounds = getNodesBounds([layoutedSelectedNode])
      const zoom = viewport.zoom > DEFAULT_SELECT_ZOOM ? viewport.zoom : DEFAULT_SELECT_ZOOM
      if (isFirstQuestion) {
        const horizontalSpacing = 32
        const quizWidth = document.documentElement.clientWidth - horizontalSpacing
        const offsetLeft = quizWidth / 2.2 / zoom
        setCenter(selectedNodeBounds?.x + offsetLeft, selectedNodeBounds?.y + selectedNodeBounds?.height / 2, {
          duration: 600,
          zoom,
        })
      } else {
        setCenter(
          selectedNodeBounds?.x + selectedNodeBounds?.width / 2,
          selectedNodeBounds?.y + selectedNodeBounds?.height / 2,
          {
            duration: 600,
            zoom,
          }
        )
      }
    } else {
      if (prevViewport) {
        setViewport(prevViewport, { duration: 600 })
        setPrevViewport(null)
      }
    }
    // eslint-disable-next-line
  }, [selectedNodeId, layoutedNodes, nodeCount])

  return null
}

export default UpdateQuizLayout
