import tw from 'twin.macro'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isEqual, pick } from 'lodash'
import { CgImage } from 'react-icons/cg'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { useListenVideoExistingQuery, useUpdateVideoMutation, useListenVideoPreviewImgQuery } from '../videoLibraryApi'

import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import useQuizResultExists from 'modules/AppOnboardingQuiz/hooks/useQuizResultExists'

import { createUID } from 'common/utils/createUID'
import { Input } from 'common/components/Input/Input'
import { Textarea } from 'common/components/Textarea/Textarea'
import { Switch } from 'common/components/Switch/Switch'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'
import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { DeleteConfirmationBanner } from 'common/components/DeleteConfirmationBanner/DeleteConfirmationBanner'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { getCustomPreviewUrl } from './utils'
import { removeFileExtension } from 'common/utils/fileUploading/nameAndIdUtils'
import { TextHint } from 'common/components/TextHint/TextHint'
import { TagInputsAccordion } from 'common/components/TagInputsAccordion/TagInputsAccordion'
import {
  useListenDifficultyQuery,
  useListenEquipmentQuery,
  useListenInstructorsQuery,
  useListenTagsQuery,
  useUpdateDifficultyContentMutation,
  useUpdateEquipmentContentMutation,
  useUpdateInstructorsContentMutation,
  useUpdateTagsContentMutation,
} from 'modules/ContentTags/contentTagsApi'
import { getTagsList } from 'modules/ContentTags/contentTagUtils'
import { NEW_USER_QUIZ } from 'modules/App/components/Header'
import { setIdsOfResultsToDelete } from 'modules/AppOnboardingQuiz/quizFlowSlice'

const DIFFICULTY_LIMIT = 10
const EQUIPMENT_LIMIT = 10
const INSTRUCTORS_LIMIT = 4
const TAGS_LIMIT = 10

const Label = tw.label`inline-flex cursor-pointer font-semibold text-tBlack`
const InputError = tw.p`flex text-xs mt-1 text-tRed`

export function VideoForm({ coachOrgId, videoKey, videoData = { isPaid: true }, deleteVideo }) {
  const [updateVideo] = useUpdateVideoMutation()
  const [updateDifficulty] = useUpdateDifficultyContentMutation()
  const [updateEquipment] = useUpdateEquipmentContentMutation()
  const [updateInstructors] = useUpdateInstructorsContentMutation()
  const [updateTags] = useUpdateTagsContentMutation()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [, setDialogOpen] = useDialog()
  const { createAlert } = useAlert()

  const [loading, setLoading] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [accordionValue, setAccordionValue] = useState(null)

  const isNewVideo = !videoKey
  const [videoId, setVideoId] = useState(null)

  const [isCreated, setIsCreated] = useState(false) //set if video is created by uploading image

  useEffect(() => {
    if (videoKey) {
      setVideoId(videoKey)
    } else {
      //need to set the state in order to maintain the newly created ID
      const newId = createUID()
      setVideoId(newId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: difficultyData } = useListenDifficultyQuery({ coachOrgId })
  const difficultyTags = getTagsList(difficultyData)

  const { data: equipmentData } = useListenEquipmentQuery({ coachOrgId })
  const equipmentTags = getTagsList(equipmentData)

  const { data: instructorsData } = useListenInstructorsQuery({ coachOrgId })
  const instructorTags = getTagsList(instructorsData)

  const { data: tagsData } = useListenTagsQuery({ coachOrgId })
  const tags = getTagsList(tagsData)

  const { data: video } = useListenVideoExistingQuery({ coachOrgId, videoKey: videoId })
  const { data: previewImg } = useListenVideoPreviewImgQuery({ coachOrgId, videoKey: videoId })
  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: videoId })
  const { quizResultExists, isQuizResultsLoading } = useQuizResultExists({ coachOrgId, itemId: videoKey })

  const defaultValues = {
    title: videoData.title || '',
    type: 'video',
    subtitle: videoData.subtitle || '',
    video: videoData.video || '',
    previewImg: videoData.previewImg || '',
    description: videoData.description || '',
    difficulty: videoData?.difficulty || [],
    equipment: videoData?.equipment || [],
    instructors: videoData?.instructors || [],
    tags: videoData?.tags || [],
    isPaid: videoData.isPaid,
  }
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    register,
    formState: { errors },
    handleSubmit,
  } = methods

  const formState = watch()
  const isFormDisabled = isEqual(formState, defaultValues)

  const isDifficultyLimitReached = formState.difficulty.length >= DIFFICULTY_LIMIT
  const isEquipmentLimitReached = formState.equipment.length >= EQUIPMENT_LIMIT
  const isInstructorsLimitReached = formState.instructors.length >= INSTRUCTORS_LIMIT
  const isTagsLimitReached = formState.tags.length >= TAGS_LIMIT

  const onSubmit = async (data) => {
    const videoData = pick(data, [
      'title',
      'type',
      'subtitle',
      'description',
      'difficulty',
      'equipment',
      'instructors',
      'tags',
      'isPaid',
    ])
    const alertText = isNewVideo && !isCreated ? 'Video created!' : 'Video updated!'
    setLoading(true)
    try {
      await updateInstructors({
        coachOrgId,
        prevInstructors: defaultValues.instructors,
        currInstructors: videoData.instructors,
        contentId: videoId,
        contentType: 'video',
      })
      await updateDifficulty({
        coachOrgId,
        prevDifficulty: defaultValues.difficulty,
        currDifficulty: videoData.difficulty,
        contentId: videoId,
        contentType: 'video',
      })
      await updateEquipment({
        coachOrgId,
        prevEquipment: defaultValues.equipment,
        currEquipment: videoData.equipment,
        contentId: videoId,
        contentType: 'video',
      })
      await updateTags({
        coachOrgId,
        prevTags: defaultValues.tags,
        currTags: videoData.tags,
        contentId: videoId,
        contentType: 'video',
      })
      await updateVideo({
        coachOrgId,
        videoKey: videoId,
        video: { ...videoData, updatedAt: Date.now(), id: videoId },
      })
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    createAlert({ text: alertText, type: 'success' })
    setDialogOpen(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await deleteVideo()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    setDialogOpen(false)
  }

  const createVideoOnUpload = (event) => {
    if (isNewVideo) {
      const file = event.target.files[0]
      if (file && file.name) {
        const title = formState.title || removeFileExtension(file.name)
        const isPaid = formState.isPaid === undefined || formState.isPaid === null ? true : formState.isPaid
        const videoData = { title, isPaid }

        updateVideo({
          coachOrgId,
          videoKey: videoId,
          video: { ...videoData, id: videoId },
        })

        const alertText = 'Creating video...'
        createAlert({ text: alertText, type: 'success' })
        setIsCreated(true)
      }
    }
  }

  // refs control
  const { moveFocusOnKeyPress, addManyInputRefs, moveFocusedInputBy } = useFormRefsControl()

  const titleRef = useRef()
  const subtitleRef = useRef()
  const descriptionRef = useRef()
  const difficultyRef = useRef()
  const equipmentRef = useRef()
  const instructorsRef = useRef()
  const tagsRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    addManyInputRefs([
      { ref: titleRef, name: 'title' },
      { ref: subtitleRef, name: 'subtitle' },
      { ref: descriptionRef, name: 'description' },
      { ref: difficultyRef, name: 'difficulty' },
      { ref: equipmentRef, name: 'equipment' },
      { ref: instructorsRef, name: 'instructors' },
      { ref: tagsRef, name: 'tags' },
      { ref: submitRef, name: 'submit' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const moveTagFocusOnKeyPress = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const isTagInputEmpty = e.target.value.trim() === ''
    if (e.code === 'Enter' || (!isTagInputEmpty && e.code === 'Tab')) {
      return
    }

    if (e.shiftKey) {
      moveFocusedInputBy(-1)
    } else if (!e.shiftKey) {
      moveFocusedInputBy(1)
    }
  }

  useEventListener('keydown', (e) => {
    if (
      e.target.name === 'instructors' ||
      e.target.name === 'equipment' ||
      e.target.name === 'difficulty' ||
      e.target.name === 'tags'
    ) {
      if (e.code === 'Enter' || e.code === 'Tab') {
        moveTagFocusOnKeyPress(e)
      } else {
        if (e.target.name === 'difficulty' && isDifficultyLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
        if (e.target.name === 'equipment' && isEquipmentLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
        if (e.target.name === 'instructors' && isInstructorsLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
        if (e.target.name === 'tags' && isTagsLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
      }
    } else {
      moveFocusOnKeyPress(e, handleSubmit(onSubmit))
    }
  })

  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form onSubmit={handleSubmit(onSubmit)} id='videoLibraryForm' name='Video form'>
          <div className='flex flex-col px-4 md:px-10 py-4'>
            <TextInput
              name='title'
              label='Title'
              placeholder='Enter video title'
              register={register}
              inputRef={titleRef}
              errors={errors}
              onKeyDown={(e) => {
                if (
                  (e.code === 'Enter' || e.code === 'Tab') &&
                  e.shiftKey &&
                  accordionValue !== 'tagInputs' &&
                  isFormDisabled
                ) {
                  setAccordionValue('tagInputs')
                  setTimeout(() => {
                    tagsRef.current.focus()
                  }, 50)
                }
              }}
              autoFocus={true}
            />
          </div>
          <div className='flex flex-col px-4 md:px-10 py-4 border-t border-gray-200'>
            <div className='mb-2'>
              <UploadInput
                name='video'
                label='Video'
                liveUrl={video || videoData.video}
                previewUrl={getCustomPreviewUrl(videoId)}
                id={videoId}
                coachOrgId={coachOrgId}
                register={register}
                setValue={setValue}
                uploadType='video-library'
                setError={setError}
                clearErrors={clearErrors}
                onUpload={isNewVideo ? createVideoOnUpload : null}
                uploadDisabled={!formState.title || formState?.title?.length < 3}
                customFileSizeLimit={5000000000} //5.0gb for large video files
                fileType='video'
              />
              {errors?.video ? <InputError>{errors.video.message}</InputError> : null}
            </div>
            {video || videoData.video ? (
              <div className='mb-2'>
                <UploadInput
                  name='previewImg'
                  label='Cover image'
                  liveUrl={previewImg || videoData.previewImg}
                  id={videoId}
                  coachOrgId={coachOrgId}
                  register={register}
                  setValue={setValue}
                  uploadType='video-image'
                  setError={setError}
                  clearErrors={clearErrors}
                  onUpload={isNewVideo ? createVideoOnUpload : null}
                  uploadDisabled={!formState.title || formState?.title?.length < 3}
                  previewIcon={<CgImage className='!w-5 !h-5' />}
                  fileType='image'
                />
                {errors?.previewImg ? <InputError>{errors.previewImg.message}</InputError> : null}
              </div>
            ) : null}
            <div>
              <TextHint
                hintText='Upload appears stuck?'
                expandedText='Large videos may take a while to process. On average it may take 1-3 minutes per minute of video to process.'
              />
            </div>
          </div>
          <div className='flex flex-col px-4 md:px-10 py-4 border-t border-gray-200'>
            <TextInput
              name='subtitle'
              label='Subtitle'
              placeholder='Enter subtitle'
              type='text'
              register={register}
              inputRef={subtitleRef}
              errors={errors}
            />
          </div>
          <div className='flex flex-col px-4 md:px-10 py-4 border-t border-gray-200'>
            <TextInput
              name='description'
              label='Description'
              placeholder='Enter description'
              type='textarea'
              register={register}
              errors={errors}
              inputRef={descriptionRef}
              onKeyDown={(e) => {
                if (e.code === 'Tab' && !e.shiftKey && accordionValue !== 'tagInputs') {
                  setAccordionValue('tagInputs')
                  setTimeout(() => {
                    difficultyRef.current.focus()
                  }, 50)
                }
              }}
            />
          </div>
          <TagInputsAccordion
            accordionValue={accordionValue}
            setAccordionValue={setAccordionValue}
            formState={formState}
            register={register}
            difficultyRef={difficultyRef}
            equipmentRef={equipmentRef}
            instructorsRef={instructorsRef}
            tagsRef={tagsRef}
            difficultyTags={difficultyTags}
            equipmentTags={equipmentTags}
            instructorTags={instructorTags}
            tags={tags}
            setValue={setValue}
            isDifficultyLimitReached={isDifficultyLimitReached}
            isEquipmentLimitReached={isEquipmentLimitReached}
            isInstructorsLimitReached={isInstructorsLimitReached}
            isTagsLimitReached={isTagsLimitReached}
            rootClasses='border-t'
          />
          <div className='flex flex-col px-4 md:px-10 py-4'>
            <Switch name='isPaid' label='Paid members only' register={register} isChecked={formState.isPaid} />
          </div>
        </form>
      </div>
      {!isNewVideo && deleteConfirmation ? (
        <DeleteConfirmationBanner
          text={
            quizResultExists
              ? 'This video cannot be deleted because it is used as a result in the new user quiz. To delete the video, please remove it from the quiz first.'
              : 'Are you sure?'
          }
          handleDelete={() => {
            if (quizResultExists) {
              dispatch(setIdsOfResultsToDelete({ itemIds: { [videoKey]: true } }))
              navigate(NEW_USER_QUIZ)
            } else {
              handleDelete()
            }
          }}
          handleGoBack={() => setDeleteConfirmation(false)}
          loading={loading}
          textCss={quizResultExists ? tw`text-xs` : null}
          buttonVariant={quizResultExists ? 'primary' : 'danger'}
          deleteText={quizResultExists ? 'Go to quiz' : 'Delete'}
        />
      ) : (
        <UpdateActions
          itemKey={videoId}
          loading={loading}
          disabled={isQuizResultsLoading || isFormDisabled}
          handleDelete={() => setDeleteConfirmation(true)}
          hideDelete={!videoKey}
          deleteDisabled={isUploadingAssets(assetDrafts)}
          disabledReason='Please wait for media upload to complete'
          actionText={'Save'}
          form='videoLibraryForm'
          ref={submitRef}
          onKeyDown={(e) => {
            if (
              (e.code === 'Enter' || e.code === 'Tab') &&
              e.shiftKey &&
              accordionValue !== 'tagInputs' &&
              !isFormDisabled
            ) {
              setAccordionValue('tagInputs')
              setTimeout(() => {
                tagsRef.current.focus()
              }, 50)
            }
          }}
        />
      )}
    </FormProvider>
  )
}

const TextInput = ({
  name,
  label,
  placeholder,
  type,
  register,
  inputRef,
  errors,
  onKeyUp,
  onKeyDown,
  onFocus,
  autoFocus,
}) => (
  <div className='mb-2'>
    <Label htmlFor={name} tw='mb-1'>
      {label}
    </Label>
    {type === 'textarea' ? (
      <Textarea
        name={name}
        placeholder={placeholder}
        register={register}
        inputRef={inputRef}
        error={errors?.[name]?.message}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      />
    ) : (
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        register={register}
        inputRef={inputRef}
        error={errors?.[name]?.message}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
    )}

    {errors && errors[name] && <InputError>{errors[name].message}</InputError>}
  </div>
)
