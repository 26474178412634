import React, { useState } from 'react'
import { sortBy } from 'lodash'

import { Dialog, DialogContent } from 'common/components/Dialog/Dialog'
import { Pagination } from 'common/components/Pagination/Pagination'
import { usePagination } from 'common/components/Pagination/hooks/usePagination'
import { handleSortOfDates } from 'common/utils/sortUtils'

import { TableHead } from './TableHead'
import { TableBody } from './TableBody'
import { EmptyTable } from './EmptyTable'
import { ProgramGroupForm } from '../ProgramGroupForm/ProgramGroupForm'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'

import { useListenPrimaryUIColorQuery } from '../programGroupApi'

export function ProgramGroupTable({
  coachOrgId,
  programGroups,
  filteredProgramGroups,
  loading,
  coachPrograms,
  limit = 20,
}) {
  const programGroupsEntries = Object.entries(filteredProgramGroups)
  const totalProgramGroups = Object.keys(filteredProgramGroups).length || 0
  const [sortMethod, setSortMethod] = useState('updated')
  const [sortOrder, setSortOrder] = useState('asc')
  const { setCurrentPage, currentPage, pageLimit, totalPages, offset, itemCount } = usePagination({
    itemCount: totalProgramGroups,
    initialLimit: limit,
  })
  const { data: primaryUIColorData } = useListenPrimaryUIColorQuery({ coachOrgId })
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [modalPg, setModalPg] = useState({})

  const tableCols = [
    { title: 'name', sortOrder, setSortOrder, sortMethod, setSortMethod },
    { title: 'created', sortOrder, setSortOrder, sortMethod, setSortMethod },
    { title: 'updated', sortOrder, setSortOrder, sortMethod, setSortMethod },
  ]

  const sortedProgramGroups = sortProgramGroups(programGroupsEntries, sortOrder, sortMethod)

  return (
    <>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        itemCount={totalProgramGroups}
        totalPages={totalPages}
      />
      <div className='overflow-x-auto'>
        <div className='align-middle inline-block min-w-full'>
          <div className='overflow-hidden border-b border-gray-200 rounded-xl' data-testid='table-wrapper'>
            {!loading && itemCount === 0 ? (
              <EmptyTable />
            ) : (
              <table className='min-w-full divide-y divide-gray-200'>
                <TableHead columns={tableCols} />
                <TableBody
                  coachOrgId={coachOrgId}
                  loading={loading}
                  filteredProgramGroups={sortedProgramGroups}
                  offset={offset}
                  currentPage={currentPage}
                  pageLimit={pageLimit}
                  setModalPg={setModalPg}
                  setEditDialogOpen={setEditDialogOpen}
                />
              </table>
            )}
          </div>
        </div>
      </div>
      <Dialog open={editDialogOpen} setOpen={setEditDialogOpen}>
        <DialogContent header='Edit series'>
          <FormRefsControlProvider>
            <ProgramGroupForm
              coachOrgId={coachOrgId}
              programGroup={modalPg.programGroup}
              programGroupKey={modalPg.programGroupKey}
              coachPrograms={coachPrograms}
              availableProgramGroups={programGroups}
              deleteProgramGroup={modalPg.handleDelete}
              primaryUIColorData={primaryUIColorData}
            />
          </FormRefsControlProvider>
        </DialogContent>
      </Dialog>
      <div className='mt-4'>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageLimit={pageLimit}
          itemCount={totalProgramGroups}
          totalPages={totalPages}
          counterContainerClasses='place-self-start'
        />
      </div>
    </>
  )
}

export function sortProgramGroups(programGroups, sortOrder, sortMethod) {
  if (sortMethod === 'updated') {
    return handleSortOfDates(programGroups, sortOrder, 'updatedAt')
  } else if (sortMethod === 'created') {
    return handleSortOfDates(programGroups, sortOrder, 'createdAt')
  } else {
    return sortOrder === 'asc'
      ? sortBy(programGroups, ([key, pg]) => {
          return pg.name && pg.name.toLowerCase()
        }).reverse()
      : sortBy(programGroups, ([key, pg]) => {
          return pg.name && pg.name.toLowerCase()
        })
  }
}
