import tw from 'twin.macro'

export const actionContainerClasses = tw`
  invisible absolute 
  z-20 right-2 
  top-2 flex 
  items-center py-1 
  px-1.5 rounded-md 
  border-[1px] bg-white 
  border-gray-200 shadow-sm
`

export const addItemClasses = tw`
  bg-gray-200 hover:bg-gray-200 
  text-gray-600 hover:ring-2 
  hover:ring-tGray-ml rounded-md 
  px-4 py-2 text-xs 
  font-medium self-center
`
