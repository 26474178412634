import { each } from 'lodash'

export const getProgramGroupIdsWithProgram = ({ programGroups, programId }) => {
  let programGroupIdsWithProgram = []

  each(programGroups, (programGroup) => {
    if (programGroup.programs?.includes(programId)) {
      programGroupIdsWithProgram.push(programGroup.id)
    }
  })

  return programGroupIdsWithProgram
}
