import { useEffect } from 'react'

export function useDebouncedEffect(effect, deps, delay, conditions = true) {
  useEffect(() => {
    let handler
    if (conditions) {
      handler = setTimeout(() => effect(), delay)
    }

    return () => clearTimeout(handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay])
}
