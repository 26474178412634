import { createContext, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { v4 as uuidv4 } from 'uuid'

import { AlertContainer } from 'common/components/Alert/AlertContainer'

export const AlertContext = createContext()
AlertContext.displayName = 'AlertContext'

export function AlertProvider({ children }) {
  const [alerts, setAlerts] = useState([])

  const createAlert = ({ text, type }) => {
    const id = uuidv4()
    setAlerts((alerts) => [...alerts, { id, text, type }])
  }

  const contextValue = useMemo(() => ({ createAlert }), [])

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      {createPortal(<AlertContainer alerts={alerts} setAlerts={setAlerts} />, document.body)}
    </AlertContext.Provider>
  )
}
