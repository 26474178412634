import { useContext } from 'react'
import { RefsControlContext } from 'common/components/RefsControl/WorkoutRefsControl/context'

export function useRefsControl() {
  const context = useContext(RefsControlContext)
  if (!context) {
    throw new Error('useRefsControl must be used within a RefsControlContext')
  }

  return context
}
