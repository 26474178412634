import tw, { styled } from 'twin.macro'
import React from 'react'

export function Skeleton({ variant = 'text', className }) {
  return <Container variant={variant} className={className} />
}

const skeletonVariants = {
  text: tw`h-2`,
}

const Container = styled.div(({ variant }) => [
  tw`bg-gray-100 relative overflow-hidden after:bg-gray-300 after:inset-0 after:absolute after:content-[""] after:animate-pulse`,
  skeletonVariants[variant],
])
