import React from 'react'
import tw from 'twin.macro'

import { InputWrapper } from 'modules/Account/components/accountForms/styles'
import { Input } from '../Input/Input'

export default function InputBlock({
  name,
  label,
  type = 'text',
  placeholder,
  register,
  inputRef,
  error,
  onFocus,
  onKeyDown,
  fullWidth = true,
}) {
  return (
    <div css={[InputWrapper, !fullWidth && tw`max-w-[50%]`]}>
      <label htmlFor={name} className='text-base font-semibold color-tBlack mb-1'>
        {label || 'Label'}
      </label>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        register={register}
        inputRef={inputRef}
        error={error}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
      {error && <p className='flex text-xs mt-1 text-tRed'>{error}</p>}
    </div>
  )
}
