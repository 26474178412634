import tw from 'twin.macro'
import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { maybePluralize } from 'common/utils/stringUtils'

import { DayHeaderTitle } from './DayHeader'
import { getWktsByIds } from '../programSlice'

export function WorkoutDragOverlay(props) {
  const { workoutIds } = props

  const firstWktRef = useRef()
  const workoutsDragged = useSelector((state) => getWktsByIds(state, workoutIds))

  useEffect(() => {
    firstWktRef.current = workoutsDragged[0]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const firstWktDayIdx = workoutsDragged[0] ? workoutsDragged[0].dayIdx : firstWktRef.current?.dayIdx

  return (
    <div className='flex flex-col ring-1 ring-gray-200 bg-white shadow-xl rounded-lg overflow-hidden'>
      <DayHeaderTitle dayIdx={firstWktDayIdx} />
      <div className='px-4 py-4'>
        <div className='mb-4'>
          <Counter>{workoutIds.length}</Counter>
          <span className='ml-2'>{maybePluralize({ text: 'workout', count: workoutIds.length })} selected</span>
        </div>
        <ul>
          {workoutsDragged.map((workout) => (
            <li key={workout.id} className='flex justify-between font-semibold mb-1 last:mb-0'>
              <span className='truncate mr-4 font-semibold'>{workout.title ? workout.title : 'Untitled workout'}</span>
              <span className='whitespace-nowrap text-gray-500'>Day {workout.dayIdx + 1}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const Counter = tw.span`
  inline-flex items-center justify-center
  font-medium leading-none text-xs text-white
  w-5 h-5 bg-tGreen rounded-full
`
