import { configureStore } from '@reduxjs/toolkit'
import userReducer from 'modules/Users/userReducer'
import programReducer from 'modules/Programs/programSlice'
import programRenderingReducer from 'modules/Programs/programRenderingSlice'
import authReducer from 'modules/Auth/authReducer'
import { globalApi } from 'modules/App/globalApi'
import exerciseLibraryReducer from 'modules/ExerciseLibrary/exerciseLibrarySlice'
import uploadingExercisesReducer from 'modules/ExerciseLibrary/uploadingExercisesSlice'
import quizFlowReducer from 'modules/AppOnboardingQuiz/quizFlowSlice'

import { setupListeners } from '@reduxjs/toolkit/query'

export const store = configureStore({
  reducer: {
    user: userReducer,
    program: programReducer,
    programRendering: programRenderingReducer,
    auth: authReducer,
    exercises: exerciseLibraryReducer,
    uploadingExercises: uploadingExercisesReducer,
    quizFlow: quizFlowReducer,
    [globalApi.reducerPath]: globalApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([globalApi.middleware]),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
