import tw from 'twin.macro'
import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { sortBy, times } from 'lodash'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  useListenCoachProgramsQuery,
  useListenProgramGroupsQuery,
  useListenTabsQuery,
  useListenTabContentQuery,
  useSetTabContentMutation,
} from './layoutApi'

import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'

import { createUID } from 'common/utils/createUID'
import { useAlert } from 'common/components/Alert/hooks/useAlert'

import { ContentContainer } from './components/ContentContainer/ContentContainer'
import LayoutRows from './components/LayoutRows/LayoutRows'
import { LoadingLayoutRow } from './components/Row/LoadingLayoutRow'
import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { Tabs } from './components/Tabs/Tabs'
import { TabActionRow } from './components/TabActionRow/TabActionRow'
import { TabVectorRight } from './components/Tabs/vectors/TabVectorRight'

function Layout() {
  const { tabId: tabIdParam } = useParams()
  const navigate = useNavigate()
  const { createAlert } = useAlert()

  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: tabs, isLoading: isLoadingTabs } = useListenTabsQuery({ coachOrgId })
  const tabsLoading = tabs === undefined || tabs?.isLoading || isLoadingTabs
  const tabEntries = tabsLoading || tabs === null ? [] : Object.entries(tabs)
  const sortedTabEntries = sortBy(tabEntries, ([_, tab]) => tab.tabIdx)

  const defaultTabEntry = sortedTabEntries[0]

  const currentTabId = tabIdParam ? tabIdParam : defaultTabEntry?.[0]
  const selectedTabEntry = tabEntries.find(([tabId, _]) => tabId === currentTabId)
  const noTabForURLParam = !tabsLoading && !selectedTabEntry && tabs !== null

  const { data: tabContent, isLoading: isLoadingTabContent } = useListenTabContentQuery({
    coachOrgId,
    tabId: selectedTabEntry?.[0],
  })
  const tabContentLoading = tabContent === undefined || tabContent?.isLoading || isLoadingTabContent

  const [saveTabContent] = useSetTabContentMutation()

  useEffect(() => {
    if (noTabForURLParam) {
      navigate('/layout')
    }
  }, [noTabForURLParam, navigate])

  const { data: programGroups } = useListenProgramGroupsQuery({ coachOrgId })
  const { data: coachPrograms } = useListenCoachProgramsQuery({ coachOrgId })
  const { data: videoData } = useListenCoachVideosQuery({ coachOrgId })
  const layoutsDataLoading = programGroups?.isLoading || coachPrograms?.isLoading || videoData?.isLoading

  const handleAddRow = async () => {
    const id = createUID()
    if (!tabContent) {
      const newTabContent = [{ id, name: 'New row', visibilityLevel: 'coaches' }]
      await saveTabContent({ coachOrgId, tabContent: newTabContent, tabId: selectedTabEntry[0] })
      return
    }

    const updatedTabContent = [...tabContent, { id, name: `New row`, visibilityLevel: 'coaches' }]
    await saveTabContent({ coachOrgId, tabContent: updatedTabContent, tabId: selectedTabEntry[0] })
    createAlert({ text: 'Row created!', type: 'success' })
  }

  const numRowLoaders = 1

  return (
    <ContentContainer>
      {tabsLoading || !selectedTabEntry ? (
        <div className='flex w-full bg-gray-100 border-b border-gray-200 rounded-t-lg mt-10'>
          <div className='w-1/5'>
            <div className='relative flex items-center px-4 py-1 bg-white rounded-t-lg h-10 w-full'>
              <Skeleton css={tw`w-full`} />
              <TabVectorRight className='absolute bottom-0 right-0 translate-x-full fill-white z-[1]' />
            </div>
          </div>
        </div>
      ) : (
        <Tabs tabEntries={sortedTabEntries} coachOrgId={coachOrgId} />
      )}
      <div className='bg-white pt-8 rounded-b-lg border-b border-gray-200 px-4 md:px-8 pb-4'>
        <TabActionRow
          coachOrgId={coachOrgId}
          selectedTabEntry={selectedTabEntry}
          handleAddRow={handleAddRow}
          isLoading={layoutsDataLoading || tabsLoading || tabContentLoading || !selectedTabEntry}
        />
        {layoutsDataLoading || tabsLoading || tabContentLoading || !selectedTabEntry ? (
          times(numRowLoaders, (idx) => <LoadingLayoutRow key={idx} css={[idx === 0 && tw`mt-8`]} />)
        ) : (
          <LayoutRows tabContent={tabContent} selectedTabEntry={selectedTabEntry} handleAddRow={handleAddRow} />
        )}
      </div>
    </ContentContainer>
  )
}

export default Layout
