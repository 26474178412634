import React, { useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'
import { CgTrash } from 'react-icons/cg'
import { FiMove } from 'react-icons/fi'
import { Combobox } from '@headlessui/react'
import * as Portal from '@radix-ui/react-portal'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import { getStandardizedName } from 'common/utils/stringUtils'
import { usePopper } from 'common/hooks/usePopper'

import { SelectedVidCard, Thumbnail, VidNameAndTime, VideoSuggestions } from '../../components/WorkoutVideo'
import { CardContainer, actionContainerClasses, inputClasses } from './styles'
import { initialInputRefsSortMethod } from './ResourcesForm'

export const videoTitleErrKey = 'videoTitle'

export const VideoResourceCard = React.memo(
  ({
    index,
    programResourcesEntries,
    resource,
    setValue,
    videos,
    handleDelete,
    setResourceErrors,
    error,
    listeners,
    attributes,
  }) => {
    const inputRef = useRef()
    const containerRef = useRef()
    const isInputFocused = inputRef.current === document.activeElement
    const video = videos[resource?.videoId] || null
    const videoTitle = video?.title || ''

    const { addInputRef } = useFormRefsControl()
    useEffect(() => {
      const resourceRefsSortMethod = programResourcesEntries.map(([resourceId, _]) => `resource-${resourceId}`)
      const newInputRefsSortMethod = [...resourceRefsSortMethod, ...initialInputRefsSortMethod]

      addInputRef({
        ref: inputRef,
        name: `resource-${resource.id}`,
        sortMethod: newInputRefsSortMethod,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [actionsVisible, setActionsVisible] = useState(true)
    const [searchText, setSearchText] = useState(videoTitle)

    const handleVideoSelect = (videoId) => {
      setValue(`programResources.${resource.id}`, { ...resource, videoId })
    }

    let [popperTrigger, popperContainer] = usePopper({
      placement: 'bottom-start',
      strategy: 'fixed',
    })

    const inputWidth = inputRef?.current?.getBoundingClientRect()?.width

    const suggestedVideos = videos
      ? Object.entries(videos).filter(([_, data]) =>
          getStandardizedName(data.title)?.includes(getStandardizedName(searchText))
        )
      : []

    const onMouseMove = () => {
      if (isInputFocused) {
        setActionsVisible(true)
      }
    }

    useEventListener('mousemove', onMouseMove, containerRef?.current, { passive: true })

    const onMouseLeave = () => {
      if (isInputFocused) {
        setActionsVisible(false)
      }
    }

    useEventListener('mouseleave', onMouseLeave, containerRef?.current, { passive: true })

    const validateInput = (videoId) => {
      const shouldAddErr = !videoId && !error?.[videoTitleErrKey]
      const shouldRemoveErr = videoId && error?.[videoTitleErrKey]

      if (shouldAddErr) {
        setResourceErrors((errors) => ({
          ...errors,
          [resource.id]: {
            [videoTitleErrKey]: 'Video is required',
          },
        }))
      } else if (shouldRemoveErr) {
        setResourceErrors((errors) => {
          let errorsUpdate = errors
          delete errorsUpdate[resource.id]
          return { ...errorsUpdate }
        })
      }
    }

    return (
      <CardContainer ref={containerRef} className='group'>
        <div className='relative'>
          {actionsVisible && (
            <div id='actions-container' css={actionContainerClasses} className='group-hover:!visible'>
              <Tooltip content='Drag to reorder' triggerClasses='flex'>
                <button
                  {...attributes}
                  {...listeners}
                  onMouseDown={(e) => {
                    // make sure actions reappear if dragging starts
                    // need timeout because onMouseLeave that hides actions would run after
                    setTimeout(() => {
                      setActionsVisible(true)
                    }, 500)
                    listeners.onMouseDown(e)
                  }}
                  tabIndex={-1}
                >
                  <FiMove className='cursor-move w-3.5 h-3.5 my-[3px] text-gray-500 hover:text-tGreen' />
                </button>
              </Tooltip>
              <Tooltip content='Delete' triggerClasses='ml-1.5'>
                <CgTrash
                  className='cursor-pointer w-[18px] h-[18px] text-gray-500 hover:text-tRed'
                  onClick={() => handleDelete([inputRef])}
                />
              </Tooltip>
            </div>
          )}
        </div>
        <Combobox
          value={resource?.videoId}
          onChange={(videoId) => {
            handleVideoSelect(videoId)
            validateInput(videoId)
          }}
          nullable
        >
          <div ref={popperTrigger} className='w-full'>
            <Combobox.Input
              ref={inputRef}
              placeholder='Search video'
              autoComplete='off'
              displayValue={() => videoTitle}
              onChange={(e) => {
                if (actionsVisible) {
                  setActionsVisible(false)
                }
                setSearchText(e.target.value)
              }}
              css={[inputClasses, error?.videoTitle && tw`ring-1 focus:ring-1 ring-red-500 focus:ring-red-500`]}
              onBlur={() => validateInput(resource?.videoId)}
              className='text-ellipsis bg-inherit'
              autoFocus={index === 0}
            />
          </div>
          {searchText?.length > 0 && suggestedVideos?.length > 0 && (
            <Portal.Root>
              <div style={{ width: inputWidth }} className='z-[999]' ref={popperContainer}>
                <VideoSuggestions suggestedVideos={suggestedVideos} containerClasses='!top-2' />
              </div>
            </Portal.Root>
          )}
        </Combobox>
        {error?.videoTitle && (
          <div className='flex flex-col text-xxs mt-1'>
            <span className='text-tRed'>{error.videoTitle}</span>
          </div>
        )}
        <SelectedVidCard className='flex items-center rounded-md px-1 mt-2 relative'>
          <Thumbnail vid={video} />
          <div className='flex-1 flex flex-col ml-3'>
            <VidNameAndTime vid={video} />
          </div>
        </SelectedVidCard>
      </CardContainer>
    )
  }
)
