import React from 'react'

import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'
import { useListenProgramWorkoutsQuery } from 'modules/Layout/layoutApi'

import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'

import ResultCard from './ResultCard'
import ResultLoadingCard from './ResultLoadingCard'

function WorkoutResult({ coachOrgId, item, selected }) {
  const { data: workouts } = useListenProgramWorkoutsQuery({ programId: item.programId })
  const workoutsLoading = workouts === undefined || workouts?.isLoading
  const { data: videoData } = useListenCoachVideosQuery({ coachOrgId })
  const videosLoading = videoData === undefined || videoData?.isLoading
  const videos = videoData || {}

  if (workoutsLoading || videosLoading) {
    return <ResultLoadingCard selected={selected} />
  }

  const workout = workouts?.[item.id]
  if (!workout) {
    return null
  }

  if (workout.type !== 'single_video') {
    return (
      <ResultCard
        name={workout.title}
        description={workout.subtitle}
        type={availableItemTypes.WORKOUT}
        previewImg={workout.previewImg}
        selected={selected}
      />
    )
  }

  const videoImage = videos?.[workout.videoId]?.previewImg
  const wktImage = workout.previewImg
  return (
    <ResultCard
      name={workout.title}
      description={workout.subtitle}
      type={availableItemTypes.WORKOUT}
      previewImg={wktImage || videoImage}
      selected={selected}
    />
  )
}

export default WorkoutResult
