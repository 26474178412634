import tw from 'twin.macro'
import { TabVectorLeft } from '../vectors/TabVectorLeft'
import { TabVectorRight } from '../vectors/TabVectorRight'
import { MAX_TAB_COUNT } from '../../../constants/constants'

export function TagDragOverlay({ activeDragTab, isTabSelected, indexDragIsOver }) {
  return (
    <div
      className='cursor-grabbing'
      css={[tw`flex items-center px-4 py-2 bg-gray-200 rounded-t-lg w-full`, isTabSelected && tw`bg-white`]}
    >
      {indexDragIsOver !== 0 && <TabVectorLeft className='absolute fill-white bottom-0 left-0 -translate-x-full' />}
      <h4 className='font-semibold text-gray-500 py-1 outline-none truncate w-full'>{activeDragTab.tab.name}</h4>
      {indexDragIsOver + 1 !== MAX_TAB_COUNT && (
        <TabVectorRight className='absolute fill-white bottom-0 right-0 translate-x-full' />
      )}
    </div>
  )
}
