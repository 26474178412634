import React, { useState } from 'react'
import tw from 'twin.macro'
import { BiChevronDown } from 'react-icons/bi'
import * as Popover from '@radix-ui/react-popover'

import { useSetWorkoutExerciseMutation } from '../programApi'

import { useRefsControl } from 'common/components/RefsControl/WorkoutRefsControl/useRefsControl'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { IS_MAC_OS } from 'common/utils/detectOS'
import { getNewExercise } from '../programModels'

import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { Dialog, DialogContent } from 'common/components/Dialog/Dialog'
import BenchmarksList from './Benchmarks/BenchmarksList'
import BenchmarkForm from './Benchmarks/BenchmarkForm'

export default function SplitDropdownAddExercise({
  orgId,
  partIdx,
  workout,
  addExRef,
  isEditingWkt,
  benchmarkDialogInfo,
  setBenchmarkDialogInfo,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isBenchmarkPopoverOpen, setIsBenchmarkPopoverOpen] = useState(false)

  const [setExercise] = useSetWorkoutExerciseMutation()

  const { setNewExParams, setActiveInput } = useRefsControl()

  if (!isEditingWkt) {
    return <div className='h-4' />
  }

  return (
    <div className='flex items-center justify-center pb-6 pt-2 mt-4'>
      <Tooltip side='bottom' content={`Add Exercise (${IS_MAC_OS ? '⌘' : 'Ctrl'}+E)`}>
        <Button
          ref={addExRef}
          size='md'
          css={[dropdownBtnClasses, tw`rounded-r-none`]}
          variant='secondary'
          onFocus={() => setActiveInput(addExRef)}
          onClick={() => {
            setExercise({
              orgId,
              workoutId: workout.id,
              partIdx: partIdx,
              exIdx: workout?.parts?.[partIdx]?.exercises?.length || 0,
              exercise: getNewExercise(),
            })
            setNewExParams({ partIdx, exIdx: workout?.parts?.[partIdx]?.exercises?.length || 0 })
          }}
        >
          Add exercise
        </Button>
      </Tooltip>
      <Popover.Root
        open={isMenuOpen}
        onOpenChange={(isOpen) => {
          setIsMenuOpen(isOpen)
          if (!isOpen) {
            setIsBenchmarkPopoverOpen(isOpen)
          }
        }}
      >
        <Popover.Trigger
          onClick={(e) => {
            e.stopPropagation()
            setIsMenuOpen(true)
          }}
          tabIndex={-1}
          css={[buttonBase, buttonSizes.md, buttonVariants.secondary, dropdownBtnClasses, tw`px-1 rounded-l-none`]}
        >
          <BiChevronDown size='1rem' />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className='bg-white px-2 py-2 rounded-md shadow-xl border-2 border-gray-300 z-50'
            align='end'
            alignOffset={-7}
          >
            <Popover.Arrow offset={12} className='fill-gray-300' />
            {isBenchmarkPopoverOpen && (
              <BenchmarksList
                orgId={orgId}
                workoutId={workout.id}
                partIdx={partIdx}
                exIdx={workout?.parts?.[partIdx]?.exercises?.length || 0}
                setBenchmarkDialogInfo={setBenchmarkDialogInfo}
              />
            )}
            {!isBenchmarkPopoverOpen && !benchmarkDialogInfo && (
              <>
                <PopoverBtn onClick={() => setIsBenchmarkPopoverOpen(true)}>Add benchmark</PopoverBtn>
                <PopoverBtn
                  onClick={() => {
                    setBenchmarkDialogInfo({ open: true })
                    setIsMenuOpen(false)
                  }}
                >
                  Create benchmark
                </PopoverBtn>
              </>
            )}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      <Dialog
        open={benchmarkDialogInfo?.open}
        setOpen={(open) => (open ? setBenchmarkDialogInfo(benchmarkDialogInfo) : setBenchmarkDialogInfo(null))}
      >
        <DialogContent header={benchmarkDialogInfo?.benchmark ? 'Edit benchmark' : 'Create benchmark'}>
          <FormRefsControlProvider>
            <BenchmarkForm
              coachOrgId={orgId}
              benchmarkDialogInfo={benchmarkDialogInfo}
              setBenchmarkDialogInfo={setBenchmarkDialogInfo}
              workoutId={workout.id}
              partIdx={partIdx}
              exIdx={workout?.parts?.[partIdx]?.exercises?.length || 0}
            />
          </FormRefsControlProvider>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const dropdownBtnClasses = tw`
  relative bg-gray-200
  hover:bg-gray-200 text-gray-600
  hover:ring-2 hover:ring-tGray-ml
  rounded-md px-2.5 py-2
  text-xs font-medium
  hover:z-10
`

const PopoverBtn = tw.button`
  flex items-center 
  bg-white hover:bg-gray-300 
  hover:bg-opacity-30 
  text-sm text-tBlack 
  transition-all rounded-md 
  justify-start font-medium 
  px-2 py-2 w-full
`
