/**
 * Utilities used to prepare exercise id/name strings for proper AWS or Firebase uploading
 * E.g., Removing characters that cause issues, trimming whitespace, etc.
 */
import { createUID } from 'common/utils/createUID'
import { each, trim } from 'lodash'

const filetypeToExtension = {
  'image/jpeg': '.jpg',
  'image/jpg': '.jpg',
  'image/png': '.png',
  'video/mp4': '.mp4',
  'video/mpeg': '.mpeg',
  'video/x-msvideo': '.avi',
  'video/quicktime': '.mov',
}

export function prepNameForAWS(name, filetype) {
  if (!name || !filetype) {
    return name
  }

  const nameWithoutExt = removeFileExtension(name)
  const validName = removeInvalidAWSChars(nameWithoutExt)
  const nameWithId = addIdToName(validName, filetype)
  return nameWithId
}

export function prepAsNameString(name) {
  const nameWithoutExt = removeFileExtension(name)
  const validName = removeInvalidNameStringChars(nameWithoutExt)
  return validName
}

export function addIdToName(name, filetype) {
  if (!name || !filetype) {
    return name
  }

  let fileExt = filetypeToExtension[filetype]
  if (fileExt) {
    const uid = createUID() //Creates a new UID each time for any AWS upload.
    return `${name}${uid}${fileExt}`
  } else {
    return name
  }
}

export function removeFileExtension(name) {
  if (!name || typeof name !== 'string') return ''

  var trimmedName = name.substring(0, name.lastIndexOf('.')) || name
  return trimmedName
}

export function removeInvalidAWSChars(name) {
  if (!name) return ''

  const parsedName = name.replace(/[^\w-]/g, '') //Remove all special chars and whitespace
  return parsedName
}

export function removeInvalidFirebaseChars(name) {
  if (!name) return ''

  const parsedName = name.replace(/[^\w\- ]/g, '') //Remove all special chars. Allows whitespace unlike aws
  return parsedName
}

export function removeInvalidNameStringChars(name) {
  if (!name) return ''

  const parsedName = name.replace(/[^\w\-&+. ]/g, '') //For the 'name' string of an exercise. Most strings may be allowed, but limiting to common special chars just in case
  return parsedName
}

/**
 * Takes an array of exercises and converts their IDs into human-readable versions based on name + uid
 * @param {array} exercises An array of exercise objects that include a uid, name, video and thumbnail
 */
export function addReadableIds(exercises) {
  const exListCopy = {}

  each(exercises, (ex, id) => {
    const { name, video, thumbnail } = ex
    const validName = name && typeof name === 'string' ? removeInvalidFirebaseChars(name) : ''
    const newId = `${validName}${id}-` //Added an arbitrary "-" string because errors can occur if it its an "s", due to mobile app parsing
    const lowercaseId = newId.toLowerCase() //must be lowercase as mobile app does not match to capital properly right now

    exListCopy[lowercaseId] = {
      name: trim(name),
      video,
      thumbnail,
    }
  })

  return exListCopy
}
