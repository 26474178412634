import React, { useState } from 'react'
import { CgArrowsScrollV } from 'react-icons/cg'

import { TableCell } from 'modules/ExerciseLibrary/ExerciseTable/styles'

export function CueCell({ exercise, hideOnMobile }) {
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = (e) => {
    e.stopPropagation()
    setExpanded(!expanded)
  }

  if (!exercise?.cues) {
    return (
      <TableCell tw='w-[320px]' hideOnMobile={hideOnMobile}>
        <div className='w-[320px] text-sm text-gray-500'>No cues for this exercise</div>
      </TableCell>
    )
  }

  if (expanded) {
    return (
      <TableCell tw='w-[320px]' hideOnMobile={hideOnMobile}>
        <ul className='w-[320px] list-disc list-inside text-sm text-gray-500'>
          {exercise.cues.map((cue, index) => (
            <li key={`${exercise.name}${index}`} className='first-letter:capitalize truncate'>
              {cue}
            </li>
          ))}
          <li
            className='inline-flex items-center cursor-pointer list-none hover:text-tGreen transition-colors'
            onClick={toggleExpand}
          >
            <CgArrowsScrollV className='w-5 h-5' />
            Collapse
          </li>
        </ul>
      </TableCell>
    )
  }

  return (
    <TableCell tw='w-[320px]' hideOnMobile={hideOnMobile}>
      <ul className='w-[320px] list-disc list-inside text-sm text-gray-500'>
        {exercise.cues.map(
          (cue, index) =>
            index <= 1 && (
              <li key={`${exercise.name}${index}`} className='first-letter:capitalize truncate'>
                {cue}
              </li>
            )
        )}
        {exercise.cues.length > 2 && (
          <li
            onClick={toggleExpand}
            className='inline-block cursor-pointer list-none hover:text-tGreen transition-colors'
          >
            +{exercise.cues.length - 2} more
          </li>
        )}
      </ul>
    </TableCell>
  )
}
