import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useGetIsUserAdminQuery } from 'modules/Users/userApi'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import AccountLayout from './AccountLayout'
import TrybeAdminForm from './components/TrybeAdmin/index'

export default function TrybeAdmin() {
  const navigate = useNavigate()
  const { userId } = useAuth()

  const { data: isTrybeAdmin } = useGetIsUserAdminQuery({ userId })
  const isTrybeAdminLoading = isTrybeAdmin === undefined || isTrybeAdmin.isLoading

  useEffect(() => {
    if (!isTrybeAdminLoading && !isTrybeAdmin) navigate('/account')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrybeAdminLoading])

  return (
    <AccountLayout>
      <TrybeAdminForm userId={userId} />
    </AccountLayout>
  )
}
