import { each } from 'lodash'
import { getFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'

export const removeProgramInProgramGroups = async ({ coachOrgId, programId }) => {
  const { data: programGroups } = await getFirebase(`/coachOrgs/${coachOrgId}/programGroups`)

  if (!programGroups) return

  const progGrpsWithProgram = Object.entries(programGroups).filter(
    ([_, pg]) => pg.programs && pg.programs.includes(programId)
  )

  if (progGrpsWithProgram.length) {
    const PGsWithProgsRemoved = progGrpsWithProgram.map(([pgID, pg]) => [
      pgID,
      pg.programs.filter((program) => program !== programId),
    ])

    each(PGsWithProgsRemoved, async ([pgID, programs]) => {
      const path = `coachOrgs/${coachOrgId}/programGroups/${pgID}/programs`
      await setFirebase(path, programs)
    })
  }
}

export const deleteDraftWktsOfProgram = async ({ coachOrgId, programWkts }) => {
  if (!programWkts) return

  const fbUpdatePath = `drafts/${coachOrgId}/workouts`
  const fbWorkoutsUpdate = {}
  Object.keys(programWkts).forEach((workoutId) => (fbWorkoutsUpdate[workoutId] = null))

  await updateFirebase(fbUpdatePath, fbWorkoutsUpdate)
}
