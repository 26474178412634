import React, { useEffect, useRef, memo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual } from 'lodash'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import {
  useSetCoachOrgNameMutation,
  useSetCoachNameMutation,
  useSetCoachOrgPrimaryUIColorMutation,
} from 'modules/Account/accountApi'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'

import { brandingSchema } from 'modules/Account/schema'
import { Divider } from './styles'
import { ColorInput } from 'common/components/Color/ColorInput'
import InputBlock from 'common/components/InputBlock/InputBlock'
import AccoutFormActions from './AccoutFormActions'

function BrandingForm({ userId, coachOrgId, orgName, coachName, primaryUIColor }) {
  const { createAlert } = useAlert()

  const [setCoachOrgName] = useSetCoachOrgNameMutation()
  const [setCoachName] = useSetCoachNameMutation()
  const [setCoachOrgPrimaryUIColor] = useSetCoachOrgPrimaryUIColorMutation()

  const defaultValues = {
    orgName,
    coachName,
    color: primaryUIColor,
  }

  const {
    register,
    setValue,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange', defaultValues, resolver: yupResolver(brandingSchema) })

  const formState = watch()

  const onSubmit = async (formData) => {
    try {
      if (!isEqual(defaultValues, formData)) {
        await Promise.allSettled([
          setCoachOrgName({ userId, coachOrgId, orgName: formData.orgName }),
          setCoachName({ userId, coachOrgId, coachName: formData.coachName }),
          setCoachOrgPrimaryUIColor({ coachOrgId, primaryUIColor: formData.color }),
        ])
        createAlert({ text: 'Branding updated!', type: 'success' })
        reset({
          orgName: formData.orgName,
          coachName: formData.coachName,
          color: formData.color,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    reset(defaultValues)
  }

  const { addManyInputRefs, moveFocusOnKeyPress } = useFormRefsControl()
  const orgNameRef = useRef()
  const coachNameRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    addManyInputRefs([
      { ref: orgNameRef, name: 'orgName' },
      { ref: coachNameRef, name: 'coachNameRef' },
      { ref: submitRef, name: 'submit' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', (e) => moveFocusOnKeyPress(e, handleSubmit(onSubmit)))

  return (
    <form id='brandingForm' onSubmit={handleSubmit(onSubmit)} className='flex items-center flex-wrap pb-2.5'>
      <InputBlock
        name='orgName'
        label='Mobile App Title'
        placeholder='Name Your App'
        register={register}
        inputRef={orgNameRef}
        error={errors?.orgName?.message}
      />
      <InputBlock
        name='coachName'
        label='Coach Name'
        placeholder='Coach Name'
        register={register}
        inputRef={coachNameRef}
        error={errors?.coachName?.message}
      />
      <div className='px-2.5 mb-2.5'>
        <ColorInput label='Primary UI Color' watchColor={formState.color} setValue={setValue} />
      </div>
      <Divider />
      <AccoutFormActions
        handleCancel={handleCancel}
        disabled={isEqual(defaultValues, formState)}
        submitRef={submitRef}
      />
    </form>
  )
}

export default memo(BrandingForm)
