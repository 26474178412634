import tw, { css } from 'twin.macro'

export const rowContainerBaseStyles = tw`bg-white rounded-xl overflow-hidden mb-6 last:mb-0 border border-gray-200`
export const cardContainerBaseStyles = tw`relative flex w-60 h-44 rounded-xl overflow-hidden mr-6 last:mr-0`
export const CardRowContainer = tw.div`flex px-4 py-4`
export const RowHeaderContainer = tw.div`flex items-center bg-gray-100 border-b border-gray-200 px-4 py-2 h-12 rounded-t-xl`
export const cardHoverStyles = css`
  &:hover #dialog-button {
    visibility: visible;
  }
`
