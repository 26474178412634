import { useState } from 'react'
import { useDndMonitor } from '@dnd-kit/core'

export const useMonitorAnyWktDragging = () => {
  const [isAnyWktDragging, setIsAnyWktDragging] = useState(false)

  const onDragStart = ({ active }) => {
    const { itemType } = active.data.current

    if (itemType === 'workout') {
      setIsAnyWktDragging(true)
    }
  }

  const onDragEnd = ({ active }) => {
    const { itemType } = active.data.current

    if (itemType === 'workout') {
      setIsAnyWktDragging(false)
    }
  }

  useDndMonitor({ onDragStart, onDragEnd })

  return isAnyWktDragging
}
