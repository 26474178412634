import { globalApi, listenFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'

export const linkHubApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenLinkHub: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        await listenFirebase(`coachOrgs/${coachOrgId}/linkHub`, api)
      },
    }),
    setLink: build.mutation({
      queryFn: async ({ coachOrgId, linkKey, linkData }) => {
        const { data, error } = await setFirebase(`coachOrgs/${coachOrgId}/linkHub/resources/${linkKey}`, linkData)
        return { data, error }
      },
    }),
    updateLinkHub: build.mutation({
      queryFn: async ({ coachOrgId, updatedLinkHub }) => {
        const { data, error } = await updateFirebase(`coachOrgs/${coachOrgId}/linkHub`, updatedLinkHub)
        return { data, error }
      },
    }),
    listenLinkHubEnabled: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        await listenFirebase(`coachOrgs/${coachOrgId}/mobilePreMountSettings/visibleLayout/linkHub`, api)
      },
    }),
    setLinkHubEnabled: build.mutation({
      queryFn: async ({ coachOrgId, enabled }) => {
        const { data, error } = await setFirebase(
          `coachOrgs/${coachOrgId}/mobilePreMountSettings/visibleLayout/linkHub`,
          enabled
        )
        return { data, error }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenLinkHubQuery,
  useSetLinkMutation,
  useUpdateLinkHubMutation,
  useListenLinkHubEnabledQuery,
  useSetLinkHubEnabledMutation,
} = linkHubApi
