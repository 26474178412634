export function CircularProgress({ percent, radius = '22', strokeWidth = '2', textClassnames }) {
  const normalizedRadius = radius - strokeWidth * 2
  const circumference = normalizedRadius * 2 * Math.PI

  return (
    <div
      className='relative inline-flex items-center justify-center overflow-hidden rounded-full'
      data-testid='circularProgress'
    >
      <svg height={radius * 2} width={radius * 2}>
        <circle
          className='text-gray-300'
          strokeWidth={strokeWidth}
          stroke='currentColor'
          fill='transparent'
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className='text-tGreen'
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (percent / 100) * circumference}
          strokeLinecap='round'
          stroke='currentColor'
          fill='transparent'
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <span className={`absolute text-sm font-mono font-normal text-tBlack ${textClassnames ? textClassnames : ''}`}>
        {percent}%
      </span>
    </div>
  )
}
