import { createSlice, createSelector } from '@reduxjs/toolkit'
import { each, inRange } from 'lodash'

//New Exercise status is held in local reducer to allow for a more seamless UX experience (e.g., exercise is no longer new if sorted or clicked on)
export const initialState = {
  newExercises: {},
  selectedExercises: [],
  exSelectFromId: null,
  exSelectToId: null,
  exMultiSelected: [],
}

const exerciseLibrarySlice = createSlice({
  name: 'exercises',
  initialState,
  reducers: {
    exerciseSelected: (state, action) => {
      const { exId } = action.payload

      state.exSelectFromId = exId
      state.exSelectToId = initialState.exSelectToId
      state.exMultiSelected = initialState.exMultiSelected

      if (state.selectedExercises.indexOf(exId) === -1) {
        state.selectedExercises.push(exId)
      } else {
        state.selectedExercises = state.selectedExercises.filter((selectedExId) => selectedExId !== exId)
      }
    },
    exercisesMultiSelected: (state, action) => {
      const { exSelectToId, currPageExEntries } = action.payload

      // Exercise multi select TO anchor was set and is different than current exSelectToId
      if (state.exSelectToId !== null && exSelectToId !== state.exSelectToId) {
        // Remove previously multi selected exercises
        const updatedExSelected = state.selectedExercises.filter(
          (selectedExId) => !state.exMultiSelected.some((exId) => exId === selectedExId)
        )
        state.selectedExercises = updatedExSelected
      }

      if (state.exSelectFromId !== null) {
        const sortedExIds = currPageExEntries.map(([exId]) => exId)
        const selectFromExId = state.exSelectFromId
        const selectToExIdx = sortedExIds.indexOf(exSelectToId)
        const selectFromExIdx = sortedExIds.indexOf(selectFromExId)
        const isUpwardSelection = selectToExIdx > selectFromExIdx

        let exMultiSelected = []
        sortedExIds.forEach((exId, sortedExIdx) => {
          const exAlreadyIncluded = state.selectedExercises.includes(exId)

          const isExInRange = isUpwardSelection
            ? inRange(sortedExIdx, selectFromExIdx, selectToExIdx + 1)
            : inRange(sortedExIdx, selectToExIdx, selectFromExIdx + 1)

          if (isExInRange && !exAlreadyIncluded && exId !== selectFromExId) {
            exMultiSelected.push(exId)
          } else if (isExInRange && exAlreadyIncluded && exId !== selectFromExId) {
            state.selectedExercises = state.selectedExercises.filter((selectedExId) => selectedExId !== exId)
          }
        })

        state.selectedExercises = [...state.selectedExercises, ...exMultiSelected]
        state.exSelectToId = exSelectToId
        state.exMultiSelected = exMultiSelected
      }
    },
    exercisesPageSelected: (state, action) => {
      const { pageExIds } = action.payload

      const allExAlreadySelected = pageExIds.every((exId) => state.selectedExercises.indexOf(exId) !== -1)

      if (allExAlreadySelected) {
        state.selectedExercises = state.selectedExercises.filter((selectedExId) => !pageExIds.includes(selectedExId))
      } else {
        pageExIds.forEach((exId) => {
          if (!state.selectedExercises.includes(exId)) {
            state.selectedExercises.push(exId)
          }
        })
      }
    },
    multiSelectReferencesCleared: (state) => {
      state.exSelectFromId = initialState.exSelectFromId
      state.exSelectToId = initialState.exSelectToId
      state.exMultiSelected = initialState.exMultiSelected
    },
    selectedExercisesCleared: (state, _) => {
      state.selectedExercises = initialState.selectedExercises
    },
    newExerciseUploaded: (state, action) => {
      const { exId } = action.payload
      if (!state.newExercises[exId]) {
        state.newExercises[exId] = { showTag: true, filterToTop: true }
      }
    },
    newExercisesResorted: (state, action) => {
      const { newExercises } = state
      const newState = {}
      each(newExercises, (exercise, exId) => {
        newState[exId] = {
          ...exercise,
          filterToTop: false,
        }
      })
      state.newExercises = newState
    },
    newExerciseViewed: (state, action) => {
      const { exId } = action.payload
      state.newExercises[exId] = {
        ...state.newExercises[exId],
        showTag: false,
      }
    },
  },
})

export const {
  exerciseSelected,
  exercisesPageSelected,
  exercisesMultiSelected,
  multiSelectReferencesCleared,
  selectedExercisesCleared,
  newExerciseUploaded,
  newExercisesResorted,
  newExerciseViewed,
} = exerciseLibrarySlice.actions
export default exerciseLibrarySlice.reducer

export const getIsNewExercise = createSelector(
  (state) => state.exercises.newExercises,
  (_, exId) => exId,
  (newExercises, exId) => (newExercises[exId] ? newExercises[exId] : false)
)

export const getIsAnyExSelected = createSelector(
  (state) => state.exercises.selectedExercises,
  (selectedExercises) => selectedExercises.length > 0
)

export const getIsExSelected = createSelector(
  (state) => state.exercises.selectedExercises,
  (_, exId) => exId,
  (selectedExercises, exId) => (selectedExercises.indexOf(exId) !== -1 ? true : false)
)

export const getNewExerciseIds = createSelector(
  (state) => state.exercises.newExercises,
  (newExercises) => {
    const newExIds = Object.keys(newExercises)
    return newExIds
  }
)

export const getIsAllPageExSelected = createSelector(
  (state) => state.exercises.selectedExercises,
  (_, pageExIds) => pageExIds,
  (selectedExercises, pageExIds) => pageExIds.every((exId) => selectedExercises.indexOf(exId) !== -1)
)
