import React, { useRef, useEffect } from 'react'
import { each } from 'lodash'

import { getStandardizedName } from 'common/utils/stringUtils'
import { MatchInput } from './MatchInput'
import { ItemMissingVideo } from './ItemMissingVideo'
import { ItemsMissingVideoContainer } from './ItemsMissingVideoContainer'

export function ItemsMissingVideoListWithMatchSelect({
  missingVids,
  foundMatches,
  setSelectedExMatches,
  setSelectedWktVidMatches,
}) {
  // Default select first available matches
  useEffect(() => {
    if (foundMatches) {
      each(foundMatches.foundExMatches, (matches, matchName) => {
        setSelectedExMatches((selMatches) => {
          if (selMatches[matchName]) {
            return { ...selMatches }
          }

          return { ...selMatches, [matchName]: matches[0].id }
        })
      })

      each(foundMatches.foundWktVidMatches, (matches, matchName) => {
        setSelectedWktVidMatches((selMatches) => {
          if (selMatches[matchName]) {
            return { ...selMatches }
          }

          return { ...selMatches, [matchName]: matches[0].id }
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const scrollAreaRef = useRef()

  const foundExMatches = foundMatches?.foundExMatches
  const foundWktVidMatches = foundMatches?.foundWktVidMatches

  let manyMatchExercises = {}
  each(missingVids.exercises, (exMissingVid, exKey) => {
    const matchingExercises = foundExMatches[getStandardizedName(exMissingVid.name)]
    const hasManyMatchingEx = matchingExercises?.length > 1

    if (hasManyMatchingEx) {
      manyMatchExercises[exKey] = exMissingVid
    }
  })

  let manyMatchVideos = {}
  each(missingVids.workoutVids, (wktVidMissingVid, wktVidKey) => {
    const matchingVideos = foundWktVidMatches[getStandardizedName(wktVidMissingVid.name)]
    const hasManyMatchingVideos = matchingVideos?.length > 1

    if (hasManyMatchingVideos) {
      manyMatchVideos[wktVidKey] = wktVidMissingVid
    }
  })

  return (
    <>
      {Object.values(manyMatchExercises).length > 0 && (
        <ItemsMissingVideoContainer scrollAreaRef={scrollAreaRef} title='Exercises'>
          {Object.values(manyMatchExercises).map((exMissingVid, exMissingVidIdx) => (
            <ItemMissingVideo
              key={`${exMissingVid.name}-exercise`}
              itemMissingVid={exMissingVid}
              itemMissingVidIdx={exMissingVidIdx}
              itemType='exercise'
            >
              <MatchInput
                scrollAreaRef={scrollAreaRef}
                suggestedMatches={foundExMatches[getStandardizedName(exMissingVid.name)]}
                setSelectedMatches={setSelectedExMatches}
                itemMissingVid={exMissingVid}
                itemType='exercise'
              />
            </ItemMissingVideo>
          ))}
        </ItemsMissingVideoContainer>
      )}
      {Object.values(manyMatchVideos).length > 0 && (
        <ItemsMissingVideoContainer scrollAreaRef={scrollAreaRef} title='Full-length videos'>
          {Object.values(manyMatchVideos).map((wktVidMissingVid, wktVidMissingVidIdx) => (
            <ItemMissingVideo
              key={`${wktVidMissingVid.name}-wktVideo`}
              itemMissingVid={wktVidMissingVid}
              itemMissingVidIdx={wktVidMissingVidIdx}
              itemType='Video workout'
            >
              <MatchInput
                scrollAreaRef={scrollAreaRef}
                suggestedMatches={foundWktVidMatches[getStandardizedName(wktVidMissingVid.name)]}
                setSelectedMatches={setSelectedWktVidMatches}
                itemMissingVid={wktVidMissingVid}
                itemType='Video workout'
              />
            </ItemMissingVideo>
          ))}
        </ItemsMissingVideoContainer>
      )}
    </>
  )
}
