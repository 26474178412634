export const allMediaTypes = ['video', 'audio']

export const initialFilterState = {
  media: null, // used for landscape exVidOrientation
  video: null, // used for portrait exVidOrientation
  level: null,
  cues: null,
  tags: null,
  'recently-created': null,
}

export const initialActiveFilterState = {
  media: { video: false, audio: false }, // used for landscape exVidOrientation
  video: 'off', // used for portrait exVidOrientation
  level: { comparison: 'greater', value: 0, upperBound: 100 },
  cues: 'incomplete',
  tags: ['general'],
  'recently-created': true,
}
