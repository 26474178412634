import tw from 'twin.macro'
import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Popover from '@radix-ui/react-popover'
import { CgTrash, CgCopy, CgMoreAlt } from 'react-icons/cg'

import {
  useDeleteWeekMutation,
  useListenDraftProgramQuery,
  useReorderProgramMutation,
  useUpdateWorkoutsMutation,
  useLazyGetWorkoutsByIdsQuery,
} from '../programApi'

import useQuizResultsExists from 'modules/AppOnboardingQuiz/hooks/useQuizResultsExists'
import { setIdsOfResultsToDelete } from 'modules/AppOnboardingQuiz/quizFlowSlice'
import { NEW_USER_QUIZ } from 'modules/App/components/Header'

import { Dialog, DialogContent } from 'common/components/Dialog/Dialog'
import { CreateDialogBanner } from 'common/components/CreateDialogBanner/CreateDialogBanner'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'

import { handleInsertNewWeek, handlePasteWeek } from '../utils/weekUtils'
import { weeksCopied, getWeeksCopied } from '../programSlice'
import { clipboardCounterId, clipboardTextId } from '../constants/cssSelectorConstants'

export function WeekHeader({ orgId, weekIdx, weekWorkouts }) {
  const { id: programId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { createAlert } = useAlert()

  const weekWktIds = weekWorkouts?.map(({ id }) => id)
  const weeksCopiedState = useSelector(getWeeksCopied)

  const [deleteWeek] = useDeleteWeekMutation()
  const [reorderProgram] = useReorderProgramMutation()
  const [updateWorkouts] = useUpdateWorkoutsMutation()

  const [getWorkoutsByIds] = useLazyGetWorkoutsByIdsQuery()
  const { data: programData } = useListenDraftProgramQuery({ orgId, programId })
  const { wktIdsSorted } = programData

  let wktByIds = {}
  weekWktIds.forEach((wktId) => (wktByIds[wktId] = true))
  const { quizResultExists } = useQuizResultsExists({
    coachOrgId: orgId,
    itemIds: wktByIds,
  })

  const [loading, setLoading] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [quizNoticeDialogOpen, setQuizNoticeDialogOpen] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <div className='group sticky top-0 z-50 flex flex-row items-center justify-between px-4 py-4 border border-gray-200 bg-gray-50'>
      <h3 className='text-tBlack text-med font-bold'>Week {weekIdx + 1}</h3>
      <div
        css={[
          tw`hidden items-center py-1 px-1.5 absolute z-10 right-2 rounded-md bg-white border-[1px] border-gray-100 shadow`,
          isPopoverOpen && tw`flex`,
        ]}
        id='weekheader-actions'
      >
        <button
          onClick={() => handleCopy()}
          className='cursor-pointer text-gray-500 hover:text-tGreen mr-1.5'
          tabIndex={-1}
        >
          <Tooltip content='Copy'>
            <CgCopy className='w-[18px] h-[18px]' />
          </Tooltip>
        </button>
        <button
          onClick={() => {
            if (quizResultExists) {
              setQuizNoticeDialogOpen(true)
            } else {
              setDeleteDialogOpen(true)
            }
          }}
          className='cursor-pointer text-gray-500 hover:text-tRed mr-1'
          tabIndex={-1}
        >
          <Tooltip content='Delete'>
            <CgTrash className='w-5 h-5' />
          </Tooltip>
        </button>
        <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <Popover.Trigger
            onClick={(e) => {
              e.stopPropagation()
              setIsPopoverOpen(true)
            }}
          >
            <CgMoreAlt className='cursor-pointer w-5 h-5 text-gray-500 hover:text-tGreen' />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className='bg-white px-2 py-2 rounded-md shadow-xl border-2 border-gray-300 z-50'
              align='end'
              alignOffset={-7}
              sideOffset={5}
            >
              <Popover.Arrow className='fill-gray-300' />
              <Popover.Close asChild={true}>
                <button css={popoverBtnCss} onClick={() => handleCopy()}>
                  Copy week
                </button>
              </Popover.Close>
              {weeksCopiedState.length > 0 && (
                <Popover.Close asChild={true}>
                  <button
                    button
                    css={popoverBtnCss}
                    onClick={() => {
                      handlePasteMouseLeave()
                      handlePaste()
                    }}
                    onMouseEnter={handlePasteMouseEnter}
                    onMouseLeave={handlePasteMouseLeave}
                  >
                    Paste week
                  </button>
                </Popover.Close>
              )}
              <Popover.Close asChild={true}>
                <button css={popoverBtnCss} onClick={() => handleInsert()}>
                  Insert new week
                </button>
              </Popover.Close>
              <button
                css={popoverBtnCss}
                onClick={() => {
                  if (quizResultExists) {
                    setQuizNoticeDialogOpen(true)
                  } else {
                    setDeleteDialogOpen(true)
                  }
                }}
              >
                Delete week
              </button>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
      <Dialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen}>
        <DialogContent>
          <DeleteDialogBanner
            text={`This will permanently delete week ${weekIdx + 1}`}
            handleDelete={handleDelete}
            loading={loading}
            typeToConfirm={true}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={quizNoticeDialogOpen} setOpen={setQuizNoticeDialogOpen}>
        <DialogContent>
          <CreateDialogBanner
            titleText={null}
            createBtnText='Go to quiz'
            text='This week cannot be deleted because its workouts are used in the new user quiz. To delete the week, please delete its workouts from the quiz first.'
            handleCreate={() => {
              dispatch(setIdsOfResultsToDelete({ itemIds: wktByIds }))
              navigate(NEW_USER_QUIZ)
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )

  async function handleDelete() {
    setLoading(true)
    await deleteWeek({ orgId, programId, weekIdx, weekWktIds, wktIdsSorted })
    setLoading(false)
    setDeleteDialogOpen(false)
  }

  async function handlePaste() {
    const copiedWeekWktIds = weeksCopiedState[0].weekWktIds
    const pastedAtWeekWktIds = weekWktIds
    handlePasteWeek({
      copiedWeekWktIds,
      pastedAtWeekWktIds,
      createAlert,
      orgId,
      programId,
      reorderProgram,
      updateWorkouts,
      getWorkoutsByIds,
      wktIdsSorted,
    })
  }

  async function handleInsert() {
    handleInsertNewWeek({ weekIdx, reorderProgram, programId, orgId, wktIdsSorted, createAlert })
  }

  function handleCopy() {
    const copiedWeek = { programId, weekWktIds, weekIdx }
    dispatch(weeksCopied({ week: copiedWeek }))
    createAlert({ text: 'Week copied!', type: 'success' })
  }

  function handlePasteMouseEnter() {
    document.getElementById(clipboardTextId['week']).classList.add('!text-tGreen')
    document.getElementById(clipboardCounterId['week']).classList.add('!bg-tGreen', '!text-white')
  }

  function handlePasteMouseLeave() {
    document.getElementById(clipboardTextId['week']).classList.remove('!text-tGreen')
    document.getElementById(clipboardCounterId['week']).classList.remove('!bg-tGreen', '!text-white')
  }
}

const popoverBtnCss = tw`
  flex items-center bg-white hover:bg-gray-300 hover:bg-opacity-30
  text-sm text-tBlack transition-all rounded-md justify-start
  font-medium px-2 py-2 w-full`
