const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
  const day = date.getDate()
  const month = MONTH_NAMES[date.getMonth()]
  const year = date.getFullYear()
  const hours = date.getHours()
  let minutes = date.getMinutes()
  const amOrPm = hours > 12 ? 'PM' : 'AM'
  const hrsReg = hours % 12

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`
  }

  if (prefomattedDate) {
    // Today at 10:20pm
    // Yesterday at 10:20pm
    return `${prefomattedDate} at ${hrsReg}:${minutes} ${amOrPm}`
  }

  if (hideYear) {
    // January 10, at 10:20
    return `${month} ${day} at ${hrsReg}:${minutes} ${amOrPm}`
  }

  // January 10, 2017 at 10:20
  return `${month} ${day}, ${year} at ${hrsReg}:${minutes} ${amOrPm}`
}

// --- Main function
export function timeAgo(dateParam) {
  if (!dateParam) {
    return null
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam)
  const DAY_IN_MS = 86400000 // 24 * 60 * 60 * 1000
  const today = new Date()
  const yesterday = new Date(today - DAY_IN_MS)
  const seconds = Math.round((today - date) / 1000)
  const minutes = Math.round(seconds / 60)
  const isToday = today.toDateString() === date.toDateString()
  const isYesterday = yesterday.toDateString() === date.toDateString()
  const isThisYear = today.getFullYear() === date.getFullYear()

  if (seconds < 30) {
    return 'seconds ago'
  } else if (seconds < 90) {
    return 'about a minute ago'
  } else if (minutes < 60) {
    return `${minutes} minutes ago`
  } else if (isToday) {
    return getFormattedDate(date, 'today') // Today at 10:20am
  } else if (isYesterday) {
    return getFormattedDate(date, 'yesterday') // Yesterday at 10:20am
  } else if (isThisYear) {
    return getFormattedDate(date, false, true) // January 10 at 10:20am
  }

  return getFormattedDate(date) // January 10, 2017 at 10:20am
}
