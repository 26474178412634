import * as yup from 'yup'
import {
  checkExerciseInOtherProgressions,
  checkDuplicateExercisesInProgression,
  trimString,
  checkExArrHasFilledStr,
} from 'common/utils/yupSchemaUtils'
import { validFirebaseCharRegex } from 'common/utils/fileUploading/validators'

export const schema = yup
  .object({
    progressionName: yup
      .string()
      .required('Progression name required')
      .min(2, 'Progression name must be at least 2 characters')
      .max(50, 'Progression name must be under 50 characters')
      .matches(validFirebaseCharRegex, 'Please avoid special characters such as . [ ] : ; $ # /')
      .transform(trimString),
    exercises: yup
      .array()
      .min(1)
      .of(
        yup
          .string()
          .nullable()
          .test('one-required', checkExArrHasFilledStr)
          .test('in-use', checkExerciseInOtherProgressions)
          .test('duplicate', checkDuplicateExercisesInProgression)
      ),
  })
  .required()
