import * as yup from 'yup'
import { trimString } from 'common/utils/yupSchemaUtils'
import { validHttpsUrlRegex } from 'common/utils/fileUploading/validators'

export const schema = yup
  .object({
    title: yup
      .string()
      .required('Title required')
      .max(30, `Title should be no longer than 30 characters`)
      .transform(trimString),
    description: yup.string().max(50, `Description should be no longer than 50 characters`).transform(trimString),
    link: yup
      .string()
      .matches(validHttpsUrlRegex, {
        excludeEmptyString: true,
        message: 'Link must include https and be a valid URL ',
      })
      .required('Link required'),
  })
  .required()
