import { insertManyToArray } from 'common/utils/arrayUtils'
import { createUID } from 'common/utils/createUID'
import { maybePluralize } from 'common/utils/stringUtils'
import { clipboardCounterId, clipboardTextId } from '../constants/cssSelectorConstants'

export const handlePastePart = async ({ workout, partIdx, partsCopied, saveParts, createAlert, orgId }) => {
  const updatedCopiedParts = partsCopied.map((part) => createPartWithNewIds(part))
  const updatedParts = insertManyToArray(workout.parts, partIdx, updatedCopiedParts)

  const blockText = maybePluralize({ text: 'Block', count: partsCopied.length })

  await saveParts({
    orgId: orgId,
    workoutId: workout.id,
    parts: updatedParts,
  })

  createAlert({ text: `${blockText} pasted!`, type: 'success' })
}

export const createPartWithNewIds = (part) => {
  const partId = createUID()
  let newPart = { ...part, id: partId }

  //replace dndUIDs for each exercise
  const newExercises = []
  if (part.exercises && part.exercises.length > 0) {
    part.exercises.forEach((exercise, idx) => {
      const newExId = createUID()
      newExercises[idx] = { ...exercise, dndUID: newExId }
    })
    newPart['exercises'] = newExercises
  }

  return newPart
}

export const handlePartBtnMouseEnter = () => {
  document.getElementById(clipboardTextId['part']).classList.add('!text-tGreen')
  document.getElementById(clipboardCounterId['part']).classList.add('!bg-tGreen', '!text-white')
}

export const handlePartBtnMouseLeave = () => {
  document.getElementById(clipboardTextId['part']).classList.remove('!text-tGreen')
  document.getElementById(clipboardCounterId['part']).classList.remove('!bg-tGreen', '!text-white')
}
