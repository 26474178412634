import tw from 'twin.macro'
import React, { Fragment, useRef } from 'react'
import { CgChevronDown } from 'react-icons/cg'
import { Combobox } from '@headlessui/react'
import * as Portal from '@radix-ui/react-portal'

import { SuggestionContainer, SuggestionItem } from 'common/components/Suggestions/Suggestions'
import { inputBase } from 'common/components/Input/Input'
import { usePopper } from 'common/hooks/usePopper'

export const availableTypes = [
  { value: 'link', displayValue: 'Link' },
  { value: 'scene', displayValue: 'App Scene' },
  { value: 'video', displayValue: 'Video' },
]

export default function TypeInput({ inputRef, value, setValue, clearErrors }) {
  const comboboxBtnRef = useRef()

  const handleInputKeyDown = (e) => {
    const acceptedCodes = ['Enter', 'Tab', 'ArrowUp', 'ArrowDown']
    if (!acceptedCodes.includes(e.code)) {
      e.preventDefault()
    } else if (e.code === 'Enter' && !e.shiftKey) {
      comboboxBtnRef.current.click()
    }
  }

  let [popperTrigger, popperContainer] = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  const inputWidth = inputRef?.current?.getBoundingClientRect()?.width

  return (
    <div className='mb-4'>
      <label className='capitalize inline-flex cursor-pointer font-semibold text-tBlack' tw='mb-1'>
        Type
      </label>
      <Combobox
        value={value}
        onChange={(newValue) => {
          setValue('type', newValue)
          if (value !== newValue) {
            clearErrors()
          }
        }}
      >
        <div ref={popperTrigger} className='w-full'>
          <Combobox.Button ref={comboboxBtnRef} as='div' className='relative w-full'>
            <Combobox.Input
              ref={(e) => {
                inputRef.current = e
              }}
              placeholder='Enter type'
              autoComplete='off'
              displayValue={(val) => availableTypes.find(({ value }) => value === val).displayValue}
              onKeyDown={handleInputKeyDown}
              className='text-ellipsis bg-inherit cursor-pointer'
              css={[inputBase, tw`caret-transparent`]}
              autoFocus={true}
            />
            <CgChevronDown className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500 w-4 h-4' />
          </Combobox.Button>
        </div>
        <Portal.Root>
          <Combobox.Options className='z-[999]' ref={popperContainer} modal={false}>
            <SuggestionContainer containerStyle={{ width: inputWidth }}>
              {availableTypes.map(({ value, displayValue }) => (
                <Combobox.Option as={Fragment} key={value} value={value}>
                  {({ active }) => (
                    <SuggestionItem active={active}>
                      <div className='flex items-center'>{displayValue}</div>
                    </SuggestionItem>
                  )}
                </Combobox.Option>
              ))}
            </SuggestionContainer>
          </Combobox.Options>
        </Portal.Root>
      </Combobox>
    </div>
  )
}
