import React from 'react'

import { TableHead } from 'modules/Progressions/ProgressionTable/TableHead'
import { TableBody } from 'modules/Progressions/ProgressionTable/TableBody'

import { Pagination } from 'common/components/Pagination/Pagination'
import { usePagination } from 'common/components/Pagination/hooks/usePagination'
import { EmptyStateContainer } from 'common/components/EmptyStateContainer/EmptyStateContainer'

function ProgressionTable({ progressions, loading, exerciseList, limit = 20 }) {
  const totalProgressions = loading || progressions === null ? 0 : Object.keys(progressions).length

  const { setCurrentPage, currentPage, pageLimit, totalPages, offset, itemCount } = usePagination({
    itemCount: totalProgressions,
    initialLimit: limit,
  })

  const tableCols = ['Progression', 'Exercises']

  return (
    <>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        itemCount={totalProgressions}
        totalPages={totalPages}
      />
      <div className='overflow-x-auto'>
        <div className='align-middle inline-block min-w-full'>
          <div className='overflow-hidden border-b border-gray-200 rounded-xl' data-testid='table-wrapper'>
            {!loading && itemCount === 0 ? (
              <EmptyStateContainer text='No progressions' />
            ) : (
              <table className='min-w-full divide-y divide-gray-200'>
                <TableHead columns={tableCols} />
                <TableBody
                  loading={loading}
                  progressions={progressions}
                  offset={offset}
                  currentPage={currentPage}
                  pageLimit={pageLimit}
                  exerciseList={exerciseList}
                />
              </table>
            )}
          </div>
        </div>
      </div>
      <div className='mt-4'>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageLimit={pageLimit}
          itemCount={totalProgressions}
          totalPages={totalPages}
          counterContainerClasses='place-self-start'
        />
      </div>
    </>
  )
}

export default ProgressionTable
