import { useContext } from 'react'
import { FormRefsControlContext } from 'common/components/RefsControl/FormRefsControl/context'

export function useFormRefsControl() {
  const context = useContext(FormRefsControlContext)
  if (!context) {
    throw new Error('useFormRefsControl must be used within a FormRefsControlContext')
  }

  return context
}
