import Loading from 'modules/App/components/Loading'
import Error from 'modules/App/components/Error'
import AppRouter from './AppRouter'
import useAuth from 'modules/Auth/AuthContext'

function App() {
  const authContext = useAuth()

  const { authLoading, authError, auth0LoginStatusChecked, fbLoginStatusChecked, auth0Authed, fbAuthed } = authContext

  //This should capture errors for users that would otherwise be authed and typically need to be solved by refreshing or else
  //they will be caught by the Loading component below. All other errors will be displayed on the login/signup page accordingly
  if (authError && authError.statusCode === 429) {
    return <Error error={authError} />
  }

  const isAnyAuthLoading =
    authLoading ||
    !auth0LoginStatusChecked ||
    (auth0LoginStatusChecked && !fbLoginStatusChecked) ||
    (auth0Authed && !fbAuthed)

  if (isAnyAuthLoading) {
    return <Loading />
  }

  return <AppRouter />
}

export default App
