import tw from 'twin.macro'
import React, { useState, useRef, useEffect } from 'react'
import { CgArrowsVAlt, CgClose } from 'react-icons/cg'
import { Combobox } from '@headlessui/react'
import { useFormContext } from 'react-hook-form'

import { ExerciseInputOptions } from './ExerciseInputOptions'
import { ExerciseInputErrorWithDelete } from 'common/components/ExerciseInputList/components/ExerciseInputErrorWithDelete'

import { Spinner } from 'common/components/Spinner/Spinner'
import { useDebounce } from 'common/hooks/useDebounce'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { usePopper } from 'common/hooks/usePopper'

export const ExerciseInput = React.memo(
  ({
    handleRemoveProgression,
    exerciseKey,
    index,
    setValue,
    remove,
    exerciseList,
    selectedExId,
    progressionKey,
    attributes,
    listeners,
    isDragOverlay,
    progressions,
    onKeyUp,
    setAddingWithSelect,
    addNewItem,
    isLastExercise,
    inputRefsSortMethod,
    autoFocus,
  }) => {
    const inputRef = useRef(null)
    const [selectedExercise, setSelectedExercise] = useState(selectedExId || '') //Holds exercise. Submitted to form.
    const [inputText, setInputText] = useState(exerciseList?.[selectedExId]?.name || '') //Displays exercise name, used for search
    const debNameInput = useDebounce(inputText, 200)
    const { addInputRef, removeInputRef } = useFormRefsControl()
    const isEditingEx = exerciseKey === selectedExercise
    const [editingExErr, setEditingExErr] = useState(null)

    const {
      trigger,
      getValues,
      setFocus,
      formState: { errors },
      register,
    } = useFormContext()

    const { ref } = register(`exercises.${index}`)
    const error = errors?.exercises?.[index]
    const formExerciseValues = getValues('exercises')

    const handleSelect = (exId) => {
      if (!isEditingEx) {
        setSelectedExercise(exId)
        setValue(exId)
        trigger(`exercises.${index}`)
        setAddingWithSelect((state) => ({ ...state, [index]: true }))

        if (isLastExercise) {
          addNewItem()
        } else {
          setFocus(`exercises.${index + 1}`)
        }
      } else {
        setEditingExErr('Deleting this will remove the progression from this exercise.')
      }
    }

    useEffect(() => {
      addInputRef({
        ref: inputRef,
        posIdx: index,
        name: 'exercise',
        exerciseIdx: index,
        sortMethod: inputRefsSortMethod,
      })
      if (autoFocus && index === 0) inputRef.current?.focus()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let [popperTrigger, popperContainer] = usePopper({
      placement: 'bottom-start',
      strategy: 'fixed',
    })

    if (!exerciseList || exerciseList.length === 0) {
      return <Spinner />
    }

    return (
      <>
        <div className='relative flex items-center'>
          {selectedExercise && formExerciseValues.length > 1 && (
            <div
              {...attributes}
              {...listeners}
              className='group-hover:visible group-hover:text-opacity-50 hover:!text-opacity-80 -left-3 md:-left-5 -translate-y-1/2
              invisible text-opacity-0 absolute top-1/2 text-tBlack transition-all duration-200 cursor-move'
              tabIndex={-1}
            >
              <CgArrowsVAlt className='w-5 h-5' />
            </div>
          )}
          <Combobox value={selectedExercise} onChange={handleSelect}>
            {/* If input is not used as dnd overlay, register it, otherwise do not to avoid conflicts*/}
            <div className='text-gray-400 font-semibold ml-3 w-6'>{index + 1}.</div>
            {!isDragOverlay ? (
              <div ref={popperTrigger} className='w-full mr-4 md:mr-0'>
                <Combobox.Input
                  ref={(e) => {
                    ref(e)
                    inputRef.current = e
                  }}
                  aria-label={selectedExercise}
                  placeholder={`Enter exercise ${index + 1}`}
                  autoComplete='off'
                  displayValue={(exId) => {
                    const exName = exerciseList[exId]?.name || ''
                    return exName
                  }}
                  onKeyUp={onKeyUp} //onKeyDown does not work for some reason
                  onChange={(event) => {
                    setInputText(event.target.value)
                  }}
                  className='text-ellipsis bg-inherit cursor-pointer'
                  css={[exInputClasses, error && tw`focus:ring-1 ring-1 ring-tRed focus:ring-tRed`]}
                />
              </div>
            ) : (
              <Combobox.Input
                placeholder={`Enter exercise ${index + 1}`}
                autoComplete='off'
                displayValue={(exId) => {
                  const exName = exerciseList[exId]?.name || ''
                  return exName
                }}
                className='text-ellipsis bg-inherit cursor-pointer'
                css={[
                  exInputClasses,
                  error && tw`focus:ring-1 hover:ring-1 ring-1 ring-tRed focus:ring-tRed hover:ring-tRed`,
                ]}
              />
            )}
            {inputText?.length > 1 && (
              <ExerciseInputOptions
                popperContainer={popperContainer}
                value={debNameInput}
                exerciseList={exerciseList}
                progressionKey={progressionKey}
                formExerciseValues={formExerciseValues}
                progressions={progressions}
                inputRef={inputRef}
              />
            )}
          </Combobox>
          {formExerciseValues.length > 1 && (
            <button
              type='button'
              aria-label='remove exercise'
              onClick={() => {
                if (!isEditingEx) {
                  removeInputRef(inputRef, 'exercise')
                  remove(index)
                } else {
                  setEditingExErr('Deleting this will remove the progression from this exercise.')
                }
              }}
              className='group-hover:visible group-hover:text-opacity-50 hover:!text-opacity-80 -right-1 md:-right-6 -translate-y-1/2
              invisible text-opacity-0 absolute top-1/2 ml-2 text-tBlack transition-all duration-200 cursor-pointer'
              tabIndex={-1}
            >
              <CgClose />
            </button>
          )}
        </div>
        {error && error.type === 'in-use' && (
          <ExerciseInputErrorWithDelete
            message={error.message}
            index={index}
            value={selectedExId}
            progressions={progressions}
            containerClasses='ml-3'
          />
        )}
        {error && error.type !== 'in-use' && (
          <div className='flex flex-col text-xs mt-1 ml-3'>
            <span className='text-tRed'>{error.message}</span>
          </div>
        )}
        {editingExErr && handleRemoveProgression && (
          <div className='flex flex-col text-xs mt-1 ml-3'>
            <span className='text-tRed'>{editingExErr}</span>
            <span>
              Are you sure?
              <button
                type='button'
                className='ml-2 underline hover:text-tRed transition-colors'
                onClick={() => handleRemoveProgression()}
              >
                Yes
              </button>
              <button
                type='button'
                className='ml-2 underline hover:text-tGreen transition-colors'
                onClick={() => setEditingExErr(null)}
              >
                No
              </button>
            </span>
          </div>
        )}
      </>
    )
  }
)

const exInputClasses = tw`font-medium focus:ring-0 p-2 capitalize w-full
border-none overflow-hidden whitespace-nowrap placeholder:text-gray-400
hover:bg-gray-100 hover:bg-opacity-70 focus:bg-gray-100 focus:bg-opacity-70 rounded`
