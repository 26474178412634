import { Storage } from 'aws-amplify'
import { prepNameForAWS } from './nameAndIdUtils'
import { some } from 'lodash'

export const AWSupload = async ({
  file,
  uniqueId,
  coachOrgId,
  userId,
  uploadType,
  setProgressCb,
  onCompleteCb,
  setErrorCb,
  orientation,
  enableLandscapeExAudio,
  programId,
  collectionId,
}) => {
  let result
  const environment = process.env.NODE_ENV || 'development' //Guides Lambda functions on which buckets to use
  const preppedName = prepNameForAWS(file.name, file.type) //Add a uid, remove invalid characters, trim etc

  const metadata = {
    coachorgid: coachOrgId,
    uniqueid: uniqueId,
    uploadtype: uploadType,
    environment,
    orientation: orientation || 'landscape',
    enable_landscape_ex_audio: enableLandscapeExAudio ? 'true' : 'false', //accepts strings only. must all be lowercase
    programid: programId || '', //programId used for programResources
    collectionid: collectionId || '', //collectionId used for collection links
  } //AWS metadata tags Keys are always all lowercase
  if (userId) {
    metadata.userid = userId
  }

  try {
    const storageKey = `${coachOrgId}/${preppedName}`
    result = await Storage.put(storageKey, file, {
      completeCallback: (event) => {
        //On complete callback doesn't work unless the resumable: true flag is set to on, which we do not currently use
        if (onCompleteCb) {
          onCompleteCb()
        }
      },
      progressCallback: (progress) => {
        const percentDone = Number.parseFloat(progress.loaded / progress.total) * 100
        if (setProgressCb) {
          setProgressCb(Number.parseInt(percentDone), storageKey)
        }
      },
      errorCallback: (err) => {
        console.error('Error occured while uploading', err)
        if (setErrorCb) {
          setErrorCb(err)
        }
      },
      metadata,
      level: 'public', //Public means it will put into the folder "creator-uploads"
    })
  } catch (error) {
    console.log('Error uploading video: ', error)
    if (setErrorCb) {
      setErrorCb(error)
    }
  } finally {
    return result
  }
}

//Item is any node (exercise, program, programGroup) that could have assetDrafts as a child node
//Returns true if any item within 'assetDrafts' child is true
export const isUploadingAssets = (assetDrafts) => {
  if (!assetDrafts || assetDrafts.isFiller) {
    return false
  } else {
    const assetsByStatus = Object.values(assetDrafts)
    const isUploading = some(assetsByStatus, (assetStatus) => assetStatus === true || assetStatus === 'compressing') //True or compressing means waiting on AWS
    return isUploading
  }
}

export const isUploadingAsset = (assetDrafts, assetKey) => {
  if (!assetDrafts || assetDrafts.isFiller || !assetDrafts?.[assetKey]) {
    return false
  } else {
    const assetStatus = assetDrafts[assetKey]
    const isUploading = assetStatus === true || assetStatus === 'compressing' //True or compressing means waiting on AWS
    return isUploading
  }
}

export const cancelAWSUpload = async (storageKey, onCancelCb) => {
  const storagePutPromise = Storage.put(storageKey)
  Storage.cancel(storagePutPromise, 'Upload cancelled!')

  try {
    await storagePutPromise
  } catch (error) {
    // We can confirm the error is thrown by the cancellation here
    if (Storage.isCancelError(error)) {
      if (onCancelCb) {
        onCancelCb()
      }
    }
  }
}
