import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual, isEmpty } from 'lodash'

import { useUpdateTabsMutation } from 'modules/Layout/layoutApi'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { Input } from 'common/components/Input/Input'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { DeleteConfirmationBanner } from 'common/components/DeleteConfirmationBanner/DeleteConfirmationBanner'
import { schema } from './schema'

export function TabForm({ coachOrgId, tab, tabId, handleDelete, isOnlyTab }) {
  const [, setDialogOpen] = useDialog()
  const { createAlert } = useAlert()
  const [updateTabs] = useUpdateTabsMutation()

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  const defaultValues = {
    name: tab.name,
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = methods

  const formState = watch()

  const onSubmit = async (data) => {
    let tabsUpdate = {
      [tabId]: { ...tab, name: data.name },
    }
    updateTabs({ coachOrgId, tabs: tabsUpdate })
    setDialogOpen(false)
    createAlert({ text: 'Tab updated!', type: 'success' })
  }

  // ref control
  const { addManyInputRefs, moveFocusOnKeyPress } = useFormRefsControl()
  const nameRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    addManyInputRefs([
      { ref: nameRef, name: 'name' },
      { ref: submitRef, name: 'submit' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', (e) => moveFocusOnKeyPress(e, handleSubmit(onSubmit)))

  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form onSubmit={handleSubmit(onSubmit)} id='tabForm'>
          <div className='flex flex-col px-10 py-4'>
            <div className='mb-2'>
              <label htmlFor='name' className='inline-flex cursor-pointer font-semibold text-tBlack mb-1'>
                Tab name
              </label>
              <Input
                name='name'
                type='text'
                placeholder='Enter tab name'
                register={register}
                inputRef={nameRef}
                error={errors?.name?.message}
                autoFocus={true}
              />
              {errors.name && <p className='flex text-xs mt-1 text-tRed'>{errors.name.message}</p>}
            </div>
          </div>
        </form>
      </div>
      {deleteConfirmation ? (
        <DeleteConfirmationBanner
          text='Are you sure?'
          handleDelete={handleDelete}
          handleGoBack={() => setDeleteConfirmation(false)}
        />
      ) : (
        <UpdateActions
          itemKey={tabId}
          handleSubmit={handleSubmit}
          actionText='Save'
          disabled={isEqual(defaultValues, formState) || !isEmpty(errors)}
          form='tabForm'
          ref={submitRef}
          handleDelete={() => setDeleteConfirmation(true)}
          hideDelete={isOnlyTab}
          onKeyUp={(e) => {
            if (e.code === 'Enter') {
              moveFocusOnKeyPress(e, handleSubmit(onSubmit))
            }
          }}
        />
      )}
    </FormProvider>
  )
}
