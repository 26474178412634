import React from 'react'
import 'twin.macro'

import { TableCell } from 'modules/Progressions/ProgressionTable/styles'
import { Skeleton } from 'common/components/Skeleton/Skeleton'

export function LoadingRow() {
  return (
    <tr className='h-24' aria-label='loading-row'>
      <TableCell tw='w-8/12 md:w-3/12'>
        <Skeleton />
      </TableCell>
      <TableCell tw='w-6/12' hideOnMobile>
        <Skeleton />
      </TableCell>
      <td className='relative'>
        <div />
      </td>
    </tr>
  )
}
