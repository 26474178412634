import { getStandardizedName } from 'common/utils/stringUtils'

export const getSuggestedPrograms = ({ availablePrograms, inputValue, allProgamInputValues }) => {
  if (!availablePrograms) {
    return []
  }

  const filteredPrograms = Object.entries(availablePrograms).filter(([programId, programName]) => {
    const stdProgramName = getStandardizedName(programName)
    const stdInputValue = getStandardizedName(inputValue)

    if (!stdInputValue || typeof stdProgramName !== 'string' || typeof stdInputValue !== 'string') {
      return false
    }

    const progAlreadyAdded = allProgamInputValues.includes(programId)
    const progIncludesInputText = stdProgramName.includes(stdInputValue)

    return progIncludesInputText && !progAlreadyAdded
  })

  return Object.fromEntries(filteredPrograms)
}
