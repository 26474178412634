import React from 'react'
import { CgChevronDown } from 'react-icons/cg'
import * as Accordion from '@radix-ui/react-accordion'
import TagInput from 'common/components/Tag/TagInput'

export const TagInputsAccordion = ({
  accordionValue,
  setAccordionValue,
  formState,
  register,
  difficultyRef,
  equipmentRef,
  instructorsRef,
  tagsRef,
  difficultyTags,
  equipmentTags,
  instructorTags,
  tags,
  setValue,
  isDifficultyLimitReached,
  isEquipmentLimitReached,
  isInstructorsLimitReached,
  isTagsLimitReached,
  rootClasses,
}) => {
  return (
    <Accordion.Root
      type='single'
      collapsible='true'
      value={accordionValue}
      onValueChange={setAccordionValue}
      className={`border-b border-gray-200 ${rootClasses ? rootClasses : ''}`}
    >
      <Accordion.Item value='tagInputs'>
        <Accordion.Header>
          <Accordion.Trigger
            type='button'
            className='flex items-center w-full group px-4 md:px-10 py-4 min-h-[63px]'
            tabIndex={-1}
          >
            <label className='inline-flex items-center cursor-pointer font-semibold text-tBlack group-radix-state-open:hidden'>
              <span className='text-left'>Difficulty, Equipment, Instructors, & Tags</span>
              <span className='ml-2 text-xs text-gray-500'>(Optional)</span>
            </label>
            <CgChevronDown className='w-6 h-6 group-radix-state-open:-rotate-180 transition-all ml-auto' />
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <div className='flex flex-col px-4 md:px-10 pb-4 border-b border-gray-200'>
            <label htmlFor='difficulty' className='inline-flex cursor-pointer font-semibold text-tBlack mb-1'>
              Difficulty
            </label>
            <TagInput
              activeTags={formState.difficulty}
              tagsList={difficultyTags}
              setActiveTags={(value) => setValue('difficulty', value)}
              register={register}
              controlledInputRef={difficultyRef}
              placeholder={isDifficultyLimitReached ? 'limit reached (10)' : 'Add difficulty'}
              id='difficulty'
              name='difficulty'
              maxLength={50}
              type='difficulty'
            />
          </div>
          <div className='flex flex-col px-4 md:px-10 py-4 border-b border-gray-200'>
            <label htmlFor='equipment' className='inline-flex cursor-pointer font-semibold text-tBlack mb-1'>
              Equipment
            </label>
            <TagInput
              activeTags={formState.equipment}
              tagsList={equipmentTags}
              setActiveTags={(value) => setValue('equipment', value)}
              register={register}
              controlledInputRef={equipmentRef}
              placeholder={isEquipmentLimitReached ? 'limit reached (10)' : 'Add equipment'}
              id='equipment'
              name='equipment'
              maxLength={20}
              type='equipment'
            />
          </div>
          <div className='flex flex-col px-4 md:px-10 py-4 border-b border-gray-200'>
            <label htmlFor='instructors' className='inline-flex cursor-pointer font-semibold text-tBlack mb-1'>
              Instructors
            </label>
            <TagInput
              activeTags={formState.instructors}
              tagsList={instructorTags}
              setActiveTags={(value) => setValue('instructors', value)}
              register={register}
              controlledInputRef={instructorsRef}
              placeholder={isInstructorsLimitReached ? 'limit reached (4)' : 'Add instructors'}
              id='instructors'
              name='instructors'
              type='instructor'
              maxLength={50}
            />
          </div>
          <div className='flex flex-col px-4 md:px-10 py-4'>
            <label htmlFor='tags' className='inline-flex cursor-pointer font-semibold text-tBlack mb-1'>
              Tags
            </label>
            <TagInput
              activeTags={formState.tags}
              tagsList={tags}
              setActiveTags={(value) => setValue('tags', value)}
              register={register}
              controlledInputRef={tagsRef}
              placeholder={isTagsLimitReached ? 'limit reached (10)' : 'Add tags'}
              id='tags'
              name='tags'
              maxLength={50}
            />
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}
