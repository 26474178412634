import React from 'react'
import { NavLink } from 'react-router-dom'

import { buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'

export default function PageNotFound() {
  return (
    <div className='flex items-center justify-center bg-offWhite py-14 px-16 min-h-screen'>
      <div className='flex flex-col items-center w-full max-w-[1050px]'>
        <div className='flex flex-col items-center pb-6 mb-6 border-b-[1px] border-tGray-light'>
          <h1 className='text-9xl font-bold text-tBlack mb-10'>404</h1>
          <h4 className='text-2xl text-tGray-dark mb-5'>Oops, sorry we can't find that page</h4>
          <span className='text-sm text-tGray-dark'>Either something went wrong or the page doesn't exist anymore</span>
        </div>
        <NavLink to='/' css={[buttonBase, buttonVariants.primary, buttonSizes.md]}>
          Home page
        </NavLink>
      </div>
    </div>
  )
}
