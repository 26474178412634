import { each, sortBy } from 'lodash'
import { asyncForEach } from 'common/utils/asyncForEach'
import { createUID } from 'common/utils/createUID'
import { getNewPart, getNewExercise } from '../programModels'
import { getNumSets } from './instructionsSetsUtils'
import { EMPTY_TIME_STRING } from './timeUtils'
import { getFirebase } from 'modules/App/globalApi'

export const prepDraftProgram = (program) => {
  if (!program || !program.workouts) {
    return {
      wktsByWeek: {},
      numWeeks: 1,
      hasWorkouts: false,
      wktIdsSorted: [],
      wktIdsToDayIdcs: {},
    }
  }

  let maxDayIdx = 0
  let wktsByWeek = {}
  let hasWorkouts = false
  let dayIdxToIds = {}

  each(program.workouts, (dayIdx, wktId) => {
    if (dayIdx || dayIdx === 0) hasWorkouts = true

    maxDayIdx = Math.max(dayIdx, maxDayIdx)
    orgWorkoutsToWeeks(dayIdx, wktId, wktsByWeek)
    dayIdxToIds[dayIdx] = wktId
  })

  const dayIdxToIdsSortedEntries = sortBy(Object.entries(dayIdxToIds), ([dayIdx]) => Number(dayIdx))
  const wktIdsSorted = dayIdxToIdsSortedEntries.map(([, wktId]) => wktId)
  const wktIdsToDayIdcs = { ...program.workouts }
  program.workouts = null

  return {
    wktsByWeek,
    numWeeks: Math.floor(maxDayIdx / 7) + 1,
    hasWorkouts,
    wktIdsSorted,
    wktIdsToDayIdcs,
  }
}

export const prepWorkout = (wkt) => {
  const workout = { ...wkt }

  if (!workout.id) {
    workout.id = createUID()
  }

  if (!workout.parts) {
    workout.parts = []
  }

  if (!workout.type) {
    if (wkt.activity) {
      workout.type = wkt.activity
    }

    if (wkt.title && wkt.title.toLowerCase().includes('rest day')) {
      workout.type = 'rest'
    }
  }

  each(workout.parts, (part, partIdx) => {
    if (workout.type === 'rest') {
      return
    }

    if (!part) {
      const newPart = getNewPart()
      workout.parts[partIdx] = newPart
      part = newPart
    }

    if (!part.id) {
      part.id = createUID()
    }

    if (!part.exercises) {
      const emptyExercise = getNewExercise()
      workout.parts[partIdx].exercises = [emptyExercise]
    }

    if (!part.sets) {
      part.sets = getNumSets(part.instructions)
    }

    each(part.exercises, (ex) => {
      if (!ex || ex.dndUID) return

      ex.dndUID = createUID()
    })
  })

  return workout
}

const orgWorkoutsToWeeks = (dayIdx, wktId, wktsByWeek) => {
  const weekIdx = Math.floor(dayIdx / 7)
  const idxDayOfWk = dayIdx % 7

  if (!wktsByWeek[weekIdx]) {
    wktsByWeek[weekIdx] = []
  }

  const wktData = {
    id: wktId,
    dayIdx: dayIdx,
  }

  wktsByWeek[weekIdx][idxDayOfWk] = wktData
}

export const cleanDraftProgramForPublishing = (workouts) => {
  const cleaned = { ...workouts }

  each(workouts, (wkt, wktId) => {
    const shouldRemoveWkt = getShouldRemoveWkt(wkt, wktId)

    if (shouldRemoveWkt) {
      cleaned[wktId] = null
    } else {
      const cleanedWorkout = cleanWorkout(wkt, true)
      cleaned[wktId] = cleanedWorkout
    }
  })

  return cleaned
}

const getShouldRemoveWkt = (workout, workoutId) => {
  if (!workout) {
    return true
  }

  if (!workoutId || workoutId === 'null' || workoutId === 'undefined') {
    return true
  }

  //remove empty workouts
  if (workout.type === 'empty') {
    return true
  }

  if (workout.type === 'rest') {
    return false
  }

  if (workout.type === 'single_video') {
    return false
  }

  if (!workout.parts || !workout.parts.length) {
    return true
  }

  return false
}

export const cleanWorkout = (workout, isForPublishing) => {
  const newWkt = JSON.parse(JSON.stringify(workout))
  const parts = newWkt.parts || []

  const cleanedParts = parts
    .map((pt) => {
      if (pt?.type === 'rest') {
        return pt
      }

      let part = { ...pt }
      let cleanedExercises = part.exercises.filter((ex) => Boolean(ex.name.trim()))

      cleanedExercises.forEach((ex) => {
        if (ex.instructions && typeof ex.instructions === 'string') {
          ex.instructions = ex.instructions.trim()
        }

        if (isForPublishing) {
          ex.dndUID = null

          if (ex.time === EMPTY_TIME_STRING) {
            ex.time = null
          }

          if (ex.rest === EMPTY_TIME_STRING) {
            ex.rest = null
          }
        }
      })

      part.exercises = cleanedExercises
      return part
    })
    .filter((pt) => Boolean(pt.exercises.length))

  newWkt.parts = cleanedParts

  if (!newWkt.parts.length && !newWkt.title && !newWkt.instructions) {
    newWkt.type = 'empty'
    newWkt.activity = null
  }

  return newWkt
}

export const findKeyByValue = (value, obj) => {
  let neededKey
  for (let key in obj) {
    if (obj[key] === value) {
      neededKey = key
    }
  }
  return neededKey
}

const fetchDraftProgramWorkouts = async ({ orgId, programId }) => {
  try {
    const path = `drafts/${orgId}/programs/${programId}/workouts`
    const { data } = await getFirebase(path)
    return data
  } catch (e) {
    console.error('fetchDraftProgram e', e)
  }
}

const fetchDraftWorkout = async ({ orgId, workoutId }) => {
  try {
    const path = `drafts/${orgId}/workouts/${workoutId}`
    const { data } = await getFirebase(path)
    return data
  } catch (e) {
    console.error('fetchDraftWorkout e', e)
  }
}

export const getProgramWorkouts = async ({ state, orgId, programId, wktIdsToDayIdcs }) => {
  let workouts, error

  try {
    const allStateWkts = state.program.workouts

    const programStateWkts = {}
    let numWktsFetched = 0
    each(wktIdsToDayIdcs, (_, wktId) => {
      const workout = allStateWkts[wktId]
      programStateWkts[wktId] = workout

      if (workout) {
        numWktsFetched++
      }
    })

    const numWktsProg = Object.keys(wktIdsToDayIdcs).length

    let fetchedWktsPrepped = null
    if (numWktsFetched !== numWktsProg) {
      const draftProgramWkts = await fetchDraftProgramWorkouts({ orgId, programId })

      const wktsPrepped = {}
      await asyncForEach(draftProgramWkts, async (_, workoutId) => {
        const workout = await fetchDraftWorkout({ orgId, workoutId })
        const prepped = prepWorkout(workout)
        wktsPrepped[workoutId] = prepped
      })
      fetchedWktsPrepped = wktsPrepped
    }

    workouts = fetchedWktsPrepped || programStateWkts
  } catch (e) {
    error = e
    console.error(e)
  } finally {
    return { workouts, error }
  }
}

export const getWorkoutsByIds = async ({ state, orgId, wktIds }) => {
  let workouts = {}

  each(wktIds, async (wktId) => {
    const wktFromState = state.program.workouts[wktId]

    if (wktFromState) {
      workouts[wktId] = wktFromState
    } else {
      try {
        const workout = await fetchDraftWorkout({ orgId, workoutId: wktId })
        const prepped = prepWorkout(workout)
        workouts[wktId] = prepped
      } catch (e) {
        console.error(e)
      }
    }
  })

  return workouts
}

export const getDuplicatedProgramWkts = ({ workouts }) => {
  const duplicatedWkts = {}
  const updatedWktIdsToDayIdcs = {}

  each(workouts, (wkt) => {
    const updatedWkt = { ...wkt, parts: [], id: createUID() }

    each(wkt.parts, (part) => {
      const updatedPart = { ...part, exercises: [], id: createUID() }

      each(part.exercises, (ex) => {
        updatedPart.exercises.push({ ...ex, dndUID: createUID() })
      })

      updatedWkt.parts.push(updatedPart)
    })

    duplicatedWkts[updatedWkt.id] = updatedWkt
    updatedWktIdsToDayIdcs[updatedWkt.id] = updatedWkt.dayIdx
  })

  return { duplicatedWkts, updatedWktIdsToDayIdcs }
}

export const getDuplicatedProgram = ({ newProgramId, program, updatedWktIdsToDayIdcs }) => {
  const updatedProgram = {
    ...program,
    id: newProgramId,
    name: `${program.name} Copy`,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    publishedAt: null,
    workouts: updatedWktIdsToDayIdcs,
  }

  return updatedProgram
}
