import tw from 'twin.macro'

export const inputClasses = tw`
  font-medium text-sm 
  focus:ring-0 p-1 w-full 
  border-none overflow-hidden 
  whitespace-nowrap placeholder:text-gray-400  
  hover:bg-gray-100 hover:bg-opacity-70 
  focus:bg-gray-100 focus:bg-opacity-70 
  rounded
`
export const actionContainerClasses = tw`
  invisible absolute 
  z-20 right-0 
  top-[1px] flex 
  items-center py-1 
  px-1.5 rounded-md 
  border-[1px] bg-white 
  border-gray-200 shadow-sm
`
