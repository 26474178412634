import tw from 'twin.macro'
import React, { useState } from 'react'
import { CgSearch } from 'react-icons/cg'

import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenCollectionsQuery } from './collectionsApi'

import { useAuth } from 'modules/Auth/hooks/useAuth'

import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { InputGroup } from 'common/components/Input/InputGroup'
import { Input } from 'common/components/Input/Input'
import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { CollectionsTable } from './CollectionsTable/CollectionsTable'
import { CollectionsForm } from './CollectionForm/CollectionsForm'

function Collections() {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: collectionsData } = useListenCollectionsQuery({ coachOrgId })
  const collectionsLoading = collectionsData === undefined || collectionsData?.isLoading
  const collections = collectionsLoading || collectionsData === null ? {} : collectionsData

  const dataLoading = collectionsLoading

  const [searchQuery, setSearchQuery] = useState('')

  const filteredCollectionEntries = Object.entries(collections).filter(
    ([_, collection]) => collection.name && collection.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const collectionsToRender = Object.fromEntries(filteredCollectionEntries)

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  return (
    <div className='flex flex-col min-h-screen bg-offWhite px-16'>
      <div className='w-full max-w-7xl mx-auto mt-12'>
        <div className='flex items-start justify-between'>
          <div className='w-1/3 mr-6'>
            <h1 className='font-bold text-5xl text-tBlack'>Collections</h1>
          </div>
          <div className='flex-1 flex justify-center w-1/3'>
            <InputGroup css={[tw`max-w-md ring-offWhite`, searchQuery.length && tw`ring-tGreen ring-2`]}>
              <CgSearch tw='w-5 h-5' />
              <Input
                type='text'
                placeholder='Search for collection'
                onChange={handleSearchChange}
                value={searchQuery}
                hideRing
              />
              {searchQuery.length && (
                <Button variant='secondary' size='md' onClick={() => setSearchQuery('')}>
                  Clear
                </Button>
              )}
            </InputGroup>
          </div>
          <div className='flex justify-end ml-6 w-1/3'>
            <Dialog>
              <DialogTrigger css={[buttonBase, buttonVariants.primary, buttonSizes.lg]}>
                Create collection
              </DialogTrigger>
              <DialogContent header='Create collection'>
                <FormRefsControlProvider>
                  <CollectionsForm coachOrgId={coachOrgId} />
                </FormRefsControlProvider>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <p className='text-gray-500 max-w-md mt-8'>
          Create collections to group and organize various types of content for your users. You can add collections in
          App Layout.
        </p>
        <div className='flex flex-col my-20'>
          <CollectionsTable coachOrgId={coachOrgId} filteredCollections={collectionsToRender} loading={dataLoading} />
        </div>
      </div>
    </div>
  )
}

export default Collections
