import tw, { styled } from 'twin.macro'
import React, { useEffect } from 'react'
import { Button } from 'common/components/Button/Button'

export function Pagination({ currentPage, setCurrentPage, pageLimit, itemCount, totalPages, counterContainerClasses }) {
  useEffect(() => {
    setCurrentPage(1)
  }, [itemCount, setCurrentPage])

  const isLastPage = currentPage === totalPages
  const isFirstPage = currentPage === 1
  const noPages = totalPages <= 1

  return (
    <div className='flex justify-between items-center mb-4' data-testid='pagination-container'>
      <div
        className={counterContainerClasses ? counterContainerClasses : 'place-self-end'}
        data-testid='pagination-info'
      >
        {itemCount > 0 ? (
          <span className='text-sm md:text-base'>
            Showing <strong>{(currentPage - 1) * pageLimit + 1}</strong> to{' '}
            <strong>{Math.min(currentPage * pageLimit - (pageLimit - 1) + pageLimit - 1, itemCount)}</strong> of{' '}
            <strong>{itemCount}</strong> items
          </span>
        ) : null}
      </div>
      <PaginationContainer noPages={noPages} data-testid='pagination-btns'>
        <Button
          variant='secondary'
          size='md'
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={isFirstPage}
          data-testid='pagination-btn-prev'
        >
          Previous
        </Button>
        <Button
          variant='secondary'
          size='md'
          css={tw`ml-2`}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={isLastPage}
          data-testid='pagination-btn-next'
        >
          Next
        </Button>
      </PaginationContainer>
    </div>
  )
}

const PaginationContainer = styled.div(({ noPages }) => [tw`flex items-center`, noPages && tw`invisible`])
