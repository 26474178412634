import tw from 'twin.macro'

export const InputWrapper = tw`
  flex 
  flex-col 
  px-2.5 
  mb-5 
  w-full
  max-w-full
`

export const Divider = tw.div`
  mt-2.5 
  mb-8 
  mx-2.5 
  w-full 
  border-b 
  border-solid 
  border-tGray-light
`
