import React from 'react'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import {
  useListenDraftQuizQuery,
  useListenQuizPublishedAtQuery,
  usePublishQuizMutation,
  useUpdateQuestionsMutation,
} from './appOnboardingQuizApi'
import { useListenUserProfileQuery } from 'modules/Users/userApi'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { createUID } from 'common/utils/createUID'

import LastUpdatedAt from 'common/components/LastUpdatedAt/LastUpdatedAt'
import { Button } from 'common/components/Button/Button'
import { Spinner } from 'common/components/Spinner/Spinner'
import QuizFlowView from './components/QuizFlowView'
import { updateQuizErrors } from './quizFlowSlice'

function AppOnboardingQuiz() {
  const dispatch = useDispatch()
  const { createAlert } = useAlert()
  const { userId } = useAuth()

  const [updateQuestions] = useUpdateQuestionsMutation()
  const [publishQuiz, { isLoading }] = usePublishQuizMutation()

  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''
  const { data: draftQuiz, isLoading: isLoadingQuestions } = useListenDraftQuizQuery({ coachOrgId })
  const { data: publishedAt, isLoading: isLoadingPublishedAt } = useListenQuizPublishedAtQuery({ coachOrgId })
  const dataLoading =
    draftQuiz === undefined ||
    draftQuiz?.isLoading ||
    isLoadingQuestions ||
    publishedAt === undefined ||
    publishedAt?.isLoading ||
    isLoadingPublishedAt

  if (dataLoading) {
    return (
      <div className='flex flex-col bg-offWhite px-4'>
        <div className='flex justify-between items-start my-12'>
          <div className='flex flex-col max-w-2xl mr-6'>
            <h1 className='text-5xl font-bold text-tBlack mb-4'>Quiz</h1>
          </div>
        </div>
        <div className='flex items-center justify-center w-[calc(100vw-32px)] h-[calc(100vh-262px)] rounded-lg bg-white mb-5'>
          <div className='flex items-center'>
            <Spinner className='w-6 h-6 text-gray-100 mr-2' fillClassName='fill-gray-400' />
            <p className='text-gray-400'>Loading..</p>
          </div>
        </div>
      </div>
    )
  }

  const addFirstQuestion = () => {
    const firstQuestionId = createUID()
    const secondQuestionId = createUID()

    updateQuestions({
      coachOrgId,
      update: {
        [firstQuestionId]: {
          previousQuestionId: null,
          id: firstQuestionId,
          answers: {
            [secondQuestionId]: {
              index: 0,
              nextQuestionId: secondQuestionId,
            },
          },
        },
        [secondQuestionId]: {
          previousQuestionId: firstQuestionId,
          id: secondQuestionId,
        },
      },
    })
  }

  if (!draftQuiz?.questions) {
    return (
      <div className='flex flex-col bg-offWhite px-4'>
        <div className='flex justify-between items-start mt-12 mb-4'>
          <div className='flex flex-col max-w-2xl mr-6'>
            <h1 className='text-5xl font-bold text-tBlack mb-4'>New User Quiz</h1>
          </div>
          <Button
            onClick={async () => {
              await publishQuiz({ coachOrgId })
              createAlert({ text: 'Quiz published!', type: 'success' })
            }}
            loading={isLoading}
          >
            Publish quiz
          </Button>
        </div>
        <LastUpdatedAt leadupText='Last published' updatedAt={publishedAt} />
        <div className='flex flex-col items-center justify-center w-[calc(100vw-32px)] h-[calc(100vh-262px)] rounded-lg bg-white mb-5'>
          <h3 className='font-bold text-3xl text-tBlack'>No questions</h3>
          <div className='mt-4'>
            <Button size='md' onClick={addFirstQuestion}>
              Add first question
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const handlePublishQuiz = async () => {
    let errors = {}
    Object.values(draftQuiz.questions).forEach((question) => {
      if (isEmpty(question?.answers)) {
        errors = { ...errors, [question?.id]: { nodeError: 'Please select question or result' } }
        return
      }

      let resultsNotSetErrors = {}
      let questionErrors = {}
      Object.entries(question?.answers || []).forEach(([answerId, answer]) => {
        if ((answer?.title || '').trim().length === 0) {
          questionErrors[question.id] = {
            ...(questionErrors[question.id] || {}),
            [answerId]: 'Please enter answer text',
          }
        }
      })

      if ((question?.title || '').trim().length === 0) {
        questionErrors[question.id] = {
          ...(questionErrors[question.id] || {}),
          questionTitle: 'Please enter question text',
        }
      }

      if (!isEmpty(resultsNotSetErrors) || !isEmpty(questionErrors)) {
        errors = { ...errors, ...resultsNotSetErrors, ...questionErrors }
      }
    })

    if (!isEmpty(errors)) {
      dispatch(updateQuizErrors(errors))
      createAlert({ text: 'Please fix quiz errors before publishing.', type: 'danger' })
    } else {
      dispatch(updateQuizErrors({}))
      await publishQuiz({ coachOrgId })
      createAlert({ text: 'Quiz published!', type: 'success' })
    }
  }

  return (
    <div className='flex flex-col bg-offWhite px-4'>
      <div className='flex justify-between items-start mt-12 mb-4'>
        <div className='flex flex-col max-w-2xl mr-6'>
          <h1 className='text-5xl font-bold text-tBlack mb-4'>New User Quiz</h1>
        </div>
        <Button onClick={handlePublishQuiz} loading={isLoading}>
          Publish quiz
        </Button>
      </div>
      <LastUpdatedAt leadupText='Last published' updatedAt={publishedAt} />
      <div className='w-[calc(100vw-32px)] h-[calc(100vh-262px)] mb-5'>
        <QuizFlowView questions={draftQuiz.questions} coachOrgId={coachOrgId} />
      </div>
    </div>
  )
}

export default AppOnboardingQuiz
