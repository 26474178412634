import { isEmpty, sortBy } from 'lodash'

export const getDescendantIds = ({ initialIdToCheck, nodes, forDeletion }) => {
  let descendantQuestionIds = {}
  let descendantResultIds = {}
  let descendantEdgeIds = {}
  let descendantResultsUpdate = {}

  let descendantNodeIdsToCheck = [initialIdToCheck]
  while (descendantNodeIdsToCheck?.length > 0) {
    const idToCheck = descendantNodeIdsToCheck.pop()
    const foundNode = nodes.find((node) => node?.id === idToCheck)

    // Skip if no answers data is available
    if (!foundNode || isEmpty(foundNode.data?.answers)) continue

    // Sort answers by index and process each answer
    const sortedAnswers = sortBy(Object.values(foundNode?.data?.answers), (answer) => answer?.index)
    sortedAnswers.forEach((a) => {
      if (a?.resultId) {
        //Show children nodes if a result is chosen
        descendantResultIds[a.resultId] = true
        descendantEdgeIds[`${idToCheck}-${a.resultId}`] = true

        if (forDeletion) {
          descendantResultsUpdate[`${a.result.id}/${foundNode.id}/${a?.resultId}`] = null
        }
      } else {
        //Show children nodes if is established as a question
        const nextNode = nodes.find((node) => node?.id === a.nextQuestionId)
        if (forDeletion) {
          //When finding nodes to delete, need to add both question & selector type nodes
          descendantQuestionIds[a?.nextQuestionId] = true
          if (nextNode?.type !== 'selector') {
            descendantNodeIdsToCheck.push(a?.nextQuestionId)
          }
        } else if (!forDeletion && nextNode.type !== 'selector') {
          //When getting descendeants for layout, do not add selector type nodes
          descendantQuestionIds[a?.nextQuestionId] = true
          descendantEdgeIds[`${idToCheck}-${a.nextQuestionId}`] = true
          descendantNodeIdsToCheck.push(a?.nextQuestionId)
        }
      }
    })
  }

  return { descendantQuestionIds, descendantResultIds, descendantEdgeIds, descendantResultsUpdate }
}

const getAncestorQuestionItemIds = ({ currQuestionId, initAncestorQuestionIdToCheck, nodes }) => {
  let ancestorQuestionIds = {}
  let ancestorEdgeIds = {}

  ancestorQuestionIds[initAncestorQuestionIdToCheck] = true
  ancestorEdgeIds[`${initAncestorQuestionIdToCheck}-${currQuestionId}`] = true
  let ancestorQuestionId = initAncestorQuestionIdToCheck
  do {
    /* eslint-disable no-loop-func */
    const parentNode = nodes.find((node) => node?.id === ancestorQuestionId)
    const parentPrevQuestionId = parentNode?.data?.previousQuestionId
    if (parentPrevQuestionId) {
      ancestorQuestionIds[parentPrevQuestionId] = true
      ancestorEdgeIds[`${parentPrevQuestionId}-${ancestorQuestionId}`] = true
      ancestorQuestionId = parentPrevQuestionId
    } else {
      ancestorQuestionId = null
    }
  } while (ancestorQuestionId)

  return { ancestorQuestionIds, ancestorEdgeIds }
}

export const getSortedNodesAndEdges = ({ firstNode, nodes, edges }) => {
  let nodesById = {}
  nodes.forEach((node) => {
    nodesById[node.id] = node
  })
  let edgesById = {}
  edges.forEach((edge) => {
    edgesById[edge.id] = edge
  })

  let sortedNodes = [firstNode]
  let sortedEdges = []

  let descendantNodeIdsToCheck = [firstNode?.id]
  do {
    const idToCheck = descendantNodeIdsToCheck[descendantNodeIdsToCheck.length - 1]
    const foundNode = nodesById[idToCheck]
    descendantNodeIdsToCheck.pop()
    if (!isEmpty(foundNode?.data?.answers)) {
      const sortedAnswers = sortBy(Object.values(foundNode?.data?.answers), (answer) => answer?.index)
      sortedAnswers.forEach((a) => {
        if (a?.resultId) {
          const foundResNode = nodesById[a.resultId]
          sortedNodes.push(foundResNode)
          const foundResEdge = edgesById[`${idToCheck}-${a.resultId}`]
          sortedEdges.push(foundResEdge)
        } else {
          const foundQuestNode = nodesById[a.nextQuestionId]
          sortedNodes.push(foundQuestNode)
          const foundQuestEdge = edgesById[`${idToCheck}-${a.nextQuestionId}`]
          sortedEdges.push(foundQuestEdge)
          descendantNodeIdsToCheck.push(a?.nextQuestionId)
        }
      })
    }
  } while (descendantNodeIdsToCheck?.length > 0)

  return { sortedNodes, sortedEdges }
}

const createNodesFromQuestions = ({
  questions,
  coachOrgId,
  selectedNodeId,
  lastSelectedNodeId,
  idsOfResultsToDelete,
}) => {
  let nodes = []
  questions.forEach((question, idx) => {
    let node = {
      id: question?.id,
      type: 'question',
      position: { x: 0, y: idx * 100 },
      data: {
        ...question,
        showHandle: Boolean(question?.previousQuestionId),
        coachOrgId,
        noNodesSelected: selectedNodeId === null,
        wasSelected: lastSelectedNodeId === question.id,
      },
      draggable: false,
      selected: question.id === selectedNodeId,
    }

    if (isEmpty(question?.answers)) {
      node.type = 'selector'
    }

    if (!isEmpty(question?.answers)) {
      const sortedAnswers = sortBy(Object.values(question.answers), (answer) => answer?.index)
      sortedAnswers.forEach((answer) => {
        if (answer?.resultId) {
          const resultNode = {
            id: answer?.resultId,
            type: 'result',
            data: {
              parentQuestionId: question?.id,
              ...answer,
              coachOrgId,
              markedForDelete: idsOfResultsToDelete?.[answer?.result?.id],
              wasSelected: lastSelectedNodeId === answer?.resultId,
            },
            position: { x: 0, y: idx * 100 },
            draggable: false,
            selected: answer.resultId === selectedNodeId,
          }
          nodes.push(resultNode)
        }
      })
    }

    nodes.push(node)
  })

  return nodes
}

const getIsNodeHidden = ({ node, selectedNodeId, questionIdsToShow, resultIdsToShow, typeSelectorIdsHidden = {} }) => {
  const isDirectChildQuestionOfSelectedNode =
    node?.data?.previousQuestionId && node?.data?.previousQuestionId === selectedNodeId
  const isDirectChildResultOfSelectedNode =
    node?.data?.parentQuestionId && node?.data?.parentQuestionId === selectedNodeId
  if (
    selectedNodeId === node.id ||
    (isDirectChildQuestionOfSelectedNode && !typeSelectorIdsHidden[node.id]) ||
    isDirectChildResultOfSelectedNode
  ) {
    return false
  } else if (questionIdsToShow[node.id] || resultIdsToShow[node.id]) {
    if (node.type === 'selector') {
      return true
    }
  } else {
    //general fallback?
    return true
  }
}

export const getNodes = ({ questions, coachOrgId, selectedNodeId, lastSelectedNodeId, idsOfResultsToDelete }) => {
  const nodes = createNodesFromQuestions({
    questions,
    coachOrgId,
    selectedNodeId,
    lastSelectedNodeId,
    idsOfResultsToDelete,
  })
  if (selectedNodeId) {
    let questionIdsToShow = {}
    let resultIdsToShow = {}
    let edgeIdsToShow = {}
    let typeSelectorIds = {}
    let typeSelectorIdsHidden = {}
    let selectedNode

    nodes.forEach((n) => {
      // get selected node
      if (n.id === selectedNodeId) {
        selectedNode = n
      }

      // get type selector nodes
      if (n?.type === 'selector') {
        typeSelectorIds[n.id] = true
      }
    })

    // Get this question id and ids of questions, results that this question answers lead to
    questionIdsToShow[selectedNodeId] = true
    Object.values(selectedNode?.data?.answers || {}).forEach((a) => {
      if (a?.resultId) {
        resultIdsToShow[a.resultId] = true
        edgeIdsToShow[`${selectedNodeId}-${a.resultId}`] = true
      } else {
        // hide type selector nodes if answer leading to it has no title
        if (!a?.title?.length && typeSelectorIds[a?.nextQuestionId]) {
          typeSelectorIdsHidden[a?.nextQuestionId] = true
          return
        }

        questionIdsToShow[a?.nextQuestionId] = true
        edgeIdsToShow[`${selectedNodeId}-${a.nextQuestionId}`] = true
      }
    })

    // Get all ancestor ids of this question
    const { ancestorQuestionIds, ancestorEdgeIds } = getAncestorQuestionItemIds({
      currQuestionId: selectedNodeId,
      initAncestorQuestionIdToCheck: selectedNode?.data?.previousQuestionId || selectedNode?.data?.parentQuestionId,
      nodes,
    })
    questionIdsToShow = { ...questionIdsToShow, ...ancestorQuestionIds }
    edgeIdsToShow = { ...edgeIdsToShow, ...ancestorEdgeIds }

    // Get all descendant nodes branching out from this question answers
    const { descendantQuestionIds, descendantResultIds, descendantEdgeIds } = getDescendantIds({
      initialIdToCheck: selectedNodeId,
      nodes,
    })
    questionIdsToShow = { ...questionIdsToShow, ...descendantQuestionIds }
    resultIdsToShow = { ...resultIdsToShow, ...descendantResultIds }
    edgeIdsToShow = { ...edgeIdsToShow, ...descendantEdgeIds }

    const updatedNodes = nodes.map((node) => {
      if (!isEmpty(node?.data?.answers)) {
        //Tells previous node to display the answer that leads to the selected result
        const selectedQuestionParentAnswer = Object.values(node.data.answers).find(
          (answer) =>
            answer?.nextQuestionId === selectedNodeId ||
            answer?.resultId === selectedNodeId ||
            ancestorQuestionIds[answer?.nextQuestionId || answer?.resultId]
        )
        let updatedNodeData = { ...node.data, selectedQuestionParentAnswer }
        return {
          ...node,
          data: updatedNodeData,
          hidden: getIsNodeHidden({ node, selectedNodeId, questionIdsToShow, resultIdsToShow, typeSelectorIdsHidden }),
        }
      } else {
        return {
          ...node,
          hidden: getIsNodeHidden({
            node,
            selectedNodeId,
            questionIdsToShow,
            resultIdsToShow,
            typeSelectorIdsHidden,
          }),
        }
      }
    })
    return { nodes: updatedNodes, edgeIdsToShow }
  } else {
    return {
      nodes: nodes.map((node) => ({
        ...node,
        hidden: node.type === 'selector',
      })),
    }
  }
}

const getIsEdgeHidden = ({ edgeTarget, nodes }) => {
  const edgeIsHidden =
    Boolean(nodes.find((n) => n.id === edgeTarget && n?.hidden)) || !Boolean(nodes.find((n) => n.id === edgeTarget))

  return edgeIsHidden
}

export const getEdges = ({ questions, edgeIdsToShow, nodes }) => {
  let edges = []
  questions.forEach((question) => {
    if (!isEmpty(question?.answers)) {
      const sortedAnswers = sortBy(Object.values(question.answers), (answer) => answer?.index)
      sortedAnswers.forEach((answer) => {
        const edgeId = `${question.id}-${answer?.resultId || answer?.nextQuestionId}`
        edges.push({
          id: edgeId,
          source: question.id,
          target: answer?.resultId || answer?.nextQuestionId,
          sourceHandle: answer?.resultId || answer?.nextQuestionId,
          focusable: false,
          deletable: false,
          selectable: false,
          hidden: isEmpty(edgeIdsToShow)
            ? getIsEdgeHidden({ edgeTarget: answer?.resultId || answer?.nextQuestionId, nodes })
            : !edgeIdsToShow[edgeId],
        })
      })
    }
  })

  return edges
}

export function getQuizResultExists({ itemIds, quizResults }) {
  let resultExists = false
  Object.keys(itemIds).forEach((itemId) => {
    if (quizResults?.[itemId]) {
      resultExists = true
    }
  })

  return resultExists
}

export const getAllAnswersRequireSelection = ({ answers, getNode }) => {
  const allAnswersRequireSelection = Object.values(answers || {}).every(
    (answer) => Boolean(answer?.nextQuestionId) && isEmpty(getNode(answer?.nextQuestionId)?.data?.answers)
  )
  return allAnswersRequireSelection
}

export const getAnswersRequiringAction = ({ answers, getNode }) => {
  const answersRequiringAction = Object.values(answers || {}).filter((answer) => {
    if ((answer?.title || '')?.trim()?.length === 0) {
      return true
    } else if (Boolean(answer?.nextQuestionId) && isEmpty(getNode(answer?.nextQuestionId)?.data?.answers)) {
      return true
    } else {
      return false
    }
  })
  return answersRequiringAction
}

export const getOneOfAnswersRequireTitle = ({ answers }) => {
  const oneOfAnswersRequireTitle = Object.values(answers || {}).find(
    (answer) => (answer?.title || '')?.trim()?.length === 0
  )
  return Boolean(oneOfAnswersRequireTitle)
}

/**
 * Acts as a fallback to find nodes that cannot be reached from start node, but ideally never finds dead nodes
 * If a dead node is found, root cause should be identified and fixed
 */
export const checkForDeadNodes = (questions) => {
  // Step 1: Identify the starting node
  let startNode = null
  for (const nodeId in questions) {
    if (!questions[nodeId].previousQuestionId) {
      startNode = nodeId
      break
    }
  }

  // Step 2: Traverse from startNode to mark reachable nodes
  const reachableNodes = new Set()

  function traverse(nodeId) {
    if (!nodeId || reachableNodes.has(nodeId) || !questions[nodeId]) return
    reachableNodes.add(nodeId)
    const answers = questions[nodeId].answers || {}
    for (const answerKey in answers) {
      const nextNodeId = answers[answerKey].nextQuestionId
      if (nextNodeId) traverse(nextNodeId)
    }
  }

  // Start traversal
  if (startNode) {
    traverse(startNode)
  }

  // Step 3: Setup deadNodes for deletion
  const deadNodes = {}
  for (const nodeId in questions) {
    if (!reachableNodes.has(nodeId)) {
      deadNodes[nodeId] = null
      // delete questions[nodeId]
    }
  }

  return deadNodes
}
