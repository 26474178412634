import React from 'react'
import { useLocation } from 'react-router-dom'

import { useListenUserProfileQuery } from 'modules/Users/userApi'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { exIdQueryParam, videoQueryParam } from './constants/queryParamConstants'
import { EmptyStateContainer } from 'common/components/EmptyStateContainer/EmptyStateContainer'
import { CoachVideo } from './components/CoachVideo'
import { ExerciseVideo } from './components/ExerciseVideo'

function VideoViewer() {
  const query = useQuery()
  const videoId = query.get(videoQueryParam)
  const exId = query.get(exIdQueryParam)

  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  if (videoId) {
    return <CoachVideo coachOrgId={coachOrgId} videoId={videoId} />
  }

  if (exId) {
    return <ExerciseVideo coachOrgId={coachOrgId} exerciseId={exId} />
  }

  return (
    <div className='min-h-[calc(100vh-80px)] bg-offWhite px-4 md:px-16 py-12'>
      <div className='w-full max-w-7xl m-auto rounded-xl overflow-hidden'>
        <EmptyStateContainer text='No video found' />
      </div>
    </div>
  )
}

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default VideoViewer
