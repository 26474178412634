import React from 'react'
import { CgCopy, CgTrash } from 'react-icons/cg'

import { useListenWorkoutExercisesQuery, useSavePartExercisesMutation } from 'modules/Programs/programApi'

import { insertOneToArray } from 'common/utils/arrayUtils'
import { createUID } from 'common/utils/createUID'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { getNewExercise } from 'modules/Programs/programModels'

export function ExerciseActions(props) {
  const { exercise, workoutId, partIdx, orgId, actions = ['duplicate', 'delete'] } = props

  const { data: exercises } = useListenWorkoutExercisesQuery({ orgId, workoutId, partIdx })
  const [savePartExercises] = useSavePartExercisesMutation()
  return (
    <>
      {actions.map((action) => {
        if (action === 'duplicate') {
          return (
            <Tooltip key={action} content='Duplicate'>
              <CgCopy
                data-testid='copyPartIcon'
                className='cursor-pointer ml-[2px] w-4 h-4 text-gray-500 hover:text-tGreen'
                onClick={(e) =>
                  handleDuplicateExercise({
                    e,
                    exercises,
                    exerciseToDuplicate: exercise,
                    workoutId,
                    partIdx,
                    savePartExercises,
                    orgId,
                  })
                }
              />
            </Tooltip>
          )
        }

        if (action === 'delete') {
          return (
            <Tooltip key={action} content='Delete'>
              <CgTrash
                className={`cursor-pointer w-[18px] h-[18px] text-gray-500 hover:text-tRed ${
                  actions?.length === 1 ? '' : 'ml-1.5'
                }`}
                onClick={(e) => {
                  handleDeleteExercise({
                    e,
                    exercises,
                    exerciseToDelete: exercise,
                    workoutId,
                    partIdx,
                    savePartExercises,
                    orgId,
                  })
                }}
              />
            </Tooltip>
          )
        }

        return null
      })}
    </>
  )
}

const handleDuplicateExercise = async ({
  e,
  exercises,
  exerciseToDuplicate,
  workoutId,
  partIdx,
  savePartExercises,
  orgId,
}) => {
  e.stopPropagation()

  const exerciseIndex = exercises.findIndex((exercise) => exercise.dndUID === exerciseToDuplicate.dndUID)
  const exerciseToInsert = exercises[exerciseIndex]
  const exerciseWithUID = { ...exerciseToInsert, dndUID: createUID() }

  const updatedExercises = insertOneToArray(exercises, exerciseIndex + 1, exerciseWithUID)

  await savePartExercises({
    orgId,
    workoutId,
    partIndex: partIdx,
    exercises: updatedExercises,
  })
}

const handleDeleteExercise = async ({
  e,
  exercises,
  workoutId,
  partIdx,
  exerciseToDelete,
  savePartExercises,
  orgId,
}) => {
  e.stopPropagation()
  if (exercises?.length > 1) {
    const updatedExercises = exercises.filter((exercise) => exercise.dndUID !== exerciseToDelete.dndUID)

    await savePartExercises({
      orgId,
      workoutId,
      partIndex: partIdx,
      exercises: updatedExercises,
    })
  } else {
    // When deleting last exercise in a part,
    // replace it with a new empty exercise
    const newExercise = getNewExercise()
    await savePartExercises({
      orgId,
      workoutId,
      partIndex: partIdx,
      exercises: [newExercise],
    })
  }
}
