export async function asyncForEach(collection, callback) {
  if (!collection || !Object.keys(collection).length) {
    return
  }

  if (Array.isArray(collection)) {
    for (let index = 0; index < collection.length; index++) {
      await callback(collection[index], index, collection)
    }
  } else if (typeof collection === 'object') {
    for (let key in collection) {
      await callback(collection[key], key, collection)
    }
  }
}
