import tw from 'twin.macro'
import React from 'react'

import { useListenProgramQuery } from 'modules/Layout/layoutApi'

import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { LayoutCard } from './LayoutCard'

export const ProgramCard = React.memo(({ item, handleDelete }) => {
  const { data: program } = useListenProgramQuery({ programId: item.id })
  const programLoading = program === undefined || program?.isLoading

  if (programLoading) {
    return <Skeleton css={tw`w-60 h-44 rounded-xl`} />
  }

  if (program === null) {
    return (
      <LayoutCard
        id={item.id}
        type={item.type}
        name='This program has been deleted'
        handleDelete={() => handleDelete(item)}
      />
    )
  }

  return (
    <LayoutCard
      id={item.id}
      type={item.type}
      name={program.name}
      image={program.previewImg}
      subtitle={program.subtitle}
      handleDelete={() => handleDelete(item)}
    />
  )
})
