import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { findIndex, each, find } from 'lodash'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Sortable } from 'common/components/Sortable/Sortable'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useRefsControl } from 'common/components/RefsControl/WorkoutRefsControl/useRefsControl'
import { EditPart } from 'modules/Programs/components/EditPartView'
import { useHotkey } from 'common/hooks/useHotkey'
import { useDebounce } from 'common/hooks/useDebounce'
import { useEventListener } from 'common/hooks/useEventListener'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'
import {
  useUpdateWorkoutMutation,
  useSavePartsMutation,
  useAddPartMutation,
  useSetWorkoutExerciseMutation,
} from '../programApi'
import { PastePartButton } from './styles'
import { getStandardizedName, maybePluralize } from 'common/utils/stringUtils'
import { handlePartBtnMouseEnter, handlePartBtnMouseLeave, handlePastePart } from '../handlers/commonPartHandlers'
import { useDispatch, useSelector } from 'react-redux'
import { getIsWorkoutSelected, getPartsCopied, workoutSelected, workoutsMultiSelected } from '../programSlice'
import { useMonitorAnyWktDragging } from '../hooks/useMonitorAnyWktDragging'
import { onClickCombine, onClickUncombine, changeWorkoutSets, onClickAddPart } from '../utils/editPartUtils'
import { IS_MAC_OS } from 'common/utils/detectOS'
import { getNewExercise } from '../programModels'
import { IoIosWater } from 'react-icons/io'
import { RestPart } from './RestPart'
import SplitDropdownAddBlock from './SplitDropdownAddBlock'
import { WorkoutVideo } from './WorkoutVideo'

function Workout(props) {
  const { isEditing, setEditIdx, workout, orgId, titleRef, dayIdx } = props
  const { id: programId, workoutId: isLargeView } = useParams()
  const { createAlert } = useAlert()
  const dispatch = useDispatch()

  const isAnyWktDragging = useMonitorAnyWktDragging()

  const [addPart] = useAddPartMutation()
  const [saveParts] = useSavePartsMutation()
  const [setExercise] = useSetWorkoutExerciseMutation()
  const [updateWorkout] = useUpdateWorkoutMutation({
    fixedCacheKey: isLargeView ? 'largeview-shared-update-workout' : null,
  })
  const [title, setTitle] = useState(workout.title || '')
  const [instructions, setInstructions] = useState(workout.instructions || '')
  const [activePartIdx, setActivePartIdx] = useState(false) //Used for hotkey conditionals
  const [activeExIdx, setActiveExIdx] = useState(false) //Used for hotkey conditionals

  const [flattenedExerciseRefs, setFlattenedExerciseRefs] = useState([])

  const isWktSelected = useSelector((state) => getIsWorkoutSelected(state, workout.id))
  const partsCopied = useSelector(getPartsCopied)

  useEffect(() => {
    setTitle(workout.title)
    setInstructions(workout.instructions)
  }, [workout.title, workout.instructions])

  const debouncedTitle = useDebounce(title, 500)
  useDebouncedTextMutation({
    stateText: title,
    dbText: workout.title,
    mutation: updateWorkout,
    debouncedStateText: debouncedTitle,
    mutationArgs: {
      orgId,
      programId,
      workoutId: workout.id,
      workout: { ...workout, title: debouncedTitle },
    },
  })

  const debouncedInstr = useDebounce(instructions, 500)
  useDebouncedTextMutation({
    stateText: instructions,
    dbText: workout.instructions,
    mutation: updateWorkout,
    debouncedStateText: debouncedInstr,
    mutationArgs: {
      orgId,
      programId,
      workoutId: workout.id,
      workout: { ...workout, instructions: debouncedInstr },
    },
  })

  const workoutPartIds = workout.parts ? workout.parts.map((part) => part.id) : []

  const showPastePartBtn = partsCopied?.length > 0 && workout.parts?.length === 0 && workout.type !== 'rest'
  const handleCheckboxClick = (e) => {
    e.stopPropagation()

    if (!e.shiftKey) {
      handleSelectWorkout({
        workout,
        dispatch,
      })
    } else if (e.shiftKey) {
      dispatch(workoutsMultiSelected({ workout }))
    }
  }

  // handle jump to the next input on the Enter key press
  const {
    workoutHeaderRefs,
    exerciseRefs,
    addWorkoutHeaderRefs,
    clearControlledRefs,
    focusOnLastInput,
    setActiveInput,
    activeInputRef,
    focusOnRef,
    setNewExParams,
  } = useRefsControl()
  const descriptionRef = useRef()
  const addBlockRef = useRef()

  const combinedRefs = [...workoutHeaderRefs, ...flattenedExerciseRefs]

  useEffect(() => {
    if (isEditing) {
      addWorkoutHeaderRefs([titleRef, descriptionRef])
      if (!activeInputRef && titleRef) {
        focusOnRef(titleRef)
      }
    } else {
      clearControlledRefs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  useEffect(() => {
    const exerciseRefValues = Object.values(exerciseRefs)
    exerciseRefValues.sort((a, b) => {
      if (a.partIdx > b.partIdx) {
        return 1
      } else if (a.partIdx === b.partIdx) {
        if (a.exIdx > b.exIdx) {
          return 1
        } else {
          return -1
        }
      } else {
        return -1
      }
    })

    const newFlatExerciseRefs = []
    each(exerciseRefValues, (refData, refsId) => {
      newFlatExerciseRefs.push(refData?.refs.name)
      newFlatExerciseRefs.push(refData?.refs.instr)
    })
    setFlattenedExerciseRefs(newFlatExerciseRefs)
  }, [exerciseRefs])

  const moveFocusOnKeyPress = (e) => {
    if (isEditing && e.key === 'Enter') {
      e.preventDefault()
      const filteredRefs = combinedRefs.filter((ref) => Boolean(ref.current))
      const activeExRef = find(
        exerciseRefs,
        (input) =>
          input.refs.name.current === document.activeElement ||
          input.refs.instr.current === document.activeElement ||
          input.refs.addEx?.current === document.activeElement
      )
      const activeIndex = findIndex(filteredRefs, (input) => input.current === document.activeElement)

      if (activeIndex === -1 && document?.activeElement?.tagName === 'INPUT') {
        //This is if they press "Enter" while not on one of the registered inputs
        document.activeElement.blur() //E.g., for editing part name
      } else if (activeExRef?.refs?.instr.current === document.activeElement && activeExRef?.refs?.addEx) {
        activeExRef.refs.addEx.current.focus()
      } else if (activeExRef?.refs?.addEx?.current === document.activeElement) {
        setExercise({
          orgId,
          workoutId: workout.id,
          partIdx: activeExRef.partIdx,
          exIdx: workout.parts[activeExRef.partIdx].exercises.length,
          exercise: getNewExercise(),
        })
        setNewExParams({ partIdx: activeExRef.partIdx, exIdx: workout.parts[activeExRef.partIdx].exercises.length })
      } else if (activeIndex === filteredRefs.length - 1) {
        addBlockRef.current.focus()
      } else if (document.activeElement === addBlockRef.current) {
        handleAddBlock()
      } else {
        filteredRefs[activeIndex + 1].current.focus()
      }
    } else if (isEditing && e.key === 'Tab') {
      if (document.activeElement === addBlockRef.current) {
        //Conditional should represent last ref before looping to top
        e.preventDefault()
        combinedRefs[0].current.focus()
      }
    }
  }

  useEventListener('keydown', moveFocusOnKeyPress)

  const handleAddExercise = () => {
    console.log('1')
    if (isEditing && (activePartIdx || activePartIdx === 0)) {
      console.log('2')

      setExercise({
        orgId,
        workoutId: workout.id,
        partIdx: activePartIdx,
        exIdx: workout.parts[activePartIdx].exercises.length,
        exercise: getNewExercise(),
      })
      setNewExParams({
        partIdx: activePartIdx,
        exIdx: workout.parts[activePartIdx].exercises.length,
      })
    }
  }

  //Set up hotkeys
  const handleAddBlock = () => {
    if (isEditing) {
      focusOnLastInput()
      onClickAddPart(
        {
          orgId,
          workout: workout,
          workoutId: workout.id,
        },
        addPart
      )
    }
  }

  const handleCombineBlocks = () => {
    const earlierPartIdx = activePartIdx - 1
    const isEarlierPartRest = workout.parts[earlierPartIdx]?.type === 'rest'

    if (isEditing && activePartIdx && activePartIdx > 0 && !isEarlierPartRest) {
      onClickCombine({ workout, partIdx: earlierPartIdx, orgId }, updateWorkout)
    }
  }

  const handleUncombineBlocks = () => {
    if (isEditing && (activePartIdx || activePartIdx === 0) && activeExIdx && activeExIdx > 0) {
      onClickUncombine({ workout, partIdx: activePartIdx, exIdx: activeExIdx - 1, orgId }, updateWorkout)
      setActiveExIdx(false)
    }
  }

  const modKey = IS_MAC_OS ? 'meta' : 'ctrl'

  useHotkey(modKey, 'e', handleAddExercise, { isActive: isEditing })
  useHotkey(modKey, 'b', handleAddBlock, { isActive: isEditing })
  useHotkey(modKey, 'i', handleCombineBlocks, { isActive: isEditing })
  useHotkey(modKey, 'u', handleUncombineBlocks, { isActive: isEditing })

  useHotkey(
    modKey,
    'k',
    () =>
      changeWorkoutSets({
        command: 'add',
        orgId,
        workout,
        activePartIdx,
        updateWorkout,
      }),
    {
      isActive: isEditing,
    }
  )

  useHotkey(
    modKey,
    'j',
    () =>
      changeWorkoutSets({
        command: 'sub',
        orgId,
        workout,
        activePartIdx,
        updateWorkout,
      }),
    {
      isActive: isEditing,
    }
  )

  return (
    <div
      className='relative flex flex-col flex-1'
      onClick={() => {
        if (!isLargeView) {
          setEditIdx(dayIdx)
        }
      }}
    >
      <WorkoutSelectedOverlay
        data-testid='workoutSelectedOverlay'
        isEditing={isEditing}
        isWktSelected={isWktSelected}
      />
      <div className='relative border-b border-gray-200 min-h-[141px]'>
        <div className='flex items-center px-3 pt-4'>
          {!isLargeView && (
            <input
              data-testid='workoutCheckbox'
              onClick={(e) => handleCheckboxClick(e)}
              checked={isWktSelected ? true : false}
              value={isWktSelected ? 'on' : 'off'}
              type='checkbox'
              className='cursor-pointer rounded-sm w-4.5 h-4.5 border-gray-300 text-tGreen focus:ring-0 hover:border-tGreen ml-[5px]'
              readOnly
              tabIndex={-1}
            />
          )}
          {workout.type === 'rest' ? (
            <RestWktTitle isLargeView={isLargeView}>{title}</RestWktTitle>
          ) : (
            <WktTitleInput
              isLargeView={isLargeView}
              maxLength={22}
              type='text'
              value={title ? title : ''}
              placeholder='Untitled Workout'
              onChange={(e) => setTitle(e.target.value)}
              ref={titleRef}
              onFocus={() => {
                if (!isEditing) setEditIdx(props.dayIdx)
                setActiveInput(titleRef)
              }}
            />
          )}
        </div>
        <WorkoutDescription
          isEditing={isEditing}
          instructions={instructions}
          setInstructions={setInstructions}
          dispatch={props.dispatch}
          weekIdx={props.weekIdx}
          dayIdx={props.dayIdx}
          ref={descriptionRef}
          onFocus={() => {
            setActiveInput(descriptionRef)
          }}
        />
        {showPastePartBtn && (
          <PastePartButton
            className='bottom-0 translate-y-1/2 -translate-x-1/2 group-hover:flex'
            onClick={(e) => {
              e.stopPropagation()

              handlePastePart({
                workout,
                partIdx: 0,
                partsCopied,
                saveParts,
                createAlert,
                orgId,
              })
            }}
            onMouseEnter={handlePartBtnMouseEnter}
            onMouseLeave={handlePartBtnMouseLeave}
          >
            paste {maybePluralize({ text: 'block', count: partsCopied.length })}
          </PastePartButton>
        )}
      </div>
      {workout.type === 'workout' && (
        <>
          <SortableContext items={workoutPartIds} strategy={verticalListSortingStrategy}>
            {workout &&
              workout.parts &&
              workout.parts.map((val, idx) => {
                if (!val) return null
                const isLegacyRestPart =
                  val?.exercises?.length === 1 &&
                  getStandardizedName(val?.exercises?.[0].name) === 'rest' &&
                  val?.type !== 'rest'
                const isRestPart = val?.type === 'rest' || isLegacyRestPart

                return (
                  <div key={val.id}>
                    <Sortable
                      id={val.id}
                      draggingClasses='opacity-50 border-2 border-tGreen z-10'
                      customData={{ workout, part: val, partIdx: idx, itemType: 'part' }}
                      withHandle={true}
                      variableSize={true}
                      disabled={{ droppable: isAnyWktDragging }}
                    >
                      {isRestPart ? (
                        <RestPart
                          part={val}
                          partIdx={idx}
                          isEditing={isEditing}
                          partsCopied={partsCopied}
                          workout={workout}
                          orgId={orgId}
                          isLastPart={idx === workout?.parts?.length - 1}
                          setActivePartIdx={setActivePartIdx}
                          setActiveExIdx={setActiveExIdx}
                          isLegacyRestPart={isLegacyRestPart}
                        />
                      ) : (
                        <EditPart
                          part={val}
                          partIdx={idx}
                          workout={workout}
                          weekIdx={props.weekIdx}
                          dayIdx={props.dayIdx}
                          setEditIdx={setEditIdx}
                          isEditing={isEditing}
                          partsCopied={partsCopied}
                          orgId={orgId}
                          activePartIdx={activePartIdx}
                          setActivePartIdx={setActivePartIdx}
                          setActiveExIdx={setActiveExIdx}
                          isLastPart={idx === workout?.parts?.length - 1}
                        />
                      )}
                    </Sortable>
                  </div>
                )
              })}
            {isEditing ? (
              <div className='flex justify-center py-8'>
                <SplitDropdownAddBlock orgId={orgId} workout={workout} addBlockRef={addBlockRef} />
              </div>
            ) : null}
          </SortableContext>
        </>
      )}
      {workout.type === 'rest' && (
        <div className='flex mx-auto my-8'>
          <div className='flex flex-col items-center justify-center bg-gray-100 ring-2 ring-gray-100 rounded-lg w-16 h-16'>
            <IoIosWater className='w-[22px] h-[22px] text-gray-400' />
            <span className='text-xs text-gray-400 font-semibold mt-1'>Rest</span>
          </div>
        </div>
      )}
      {workout.type === 'single_video' && <WorkoutVideo orgId={orgId} workout={workout} isEditing={isEditing} />}
    </div>
  )
}

const WktTitleInput = styled.input(({ isLargeView }) => [
  tw`font-semibold focus:ring-0 border-0 w-full placeholder:text-gray-400
  p-1.5 ml-1.5 rounded-md hover:bg-gray-100 hover:bg-opacity-70 focus:bg-gray-100 focus:bg-opacity-70`,
  isLargeView && tw`ml-0`,
])

const RestWktTitle = styled.p(({ isLargeView }) => [tw`font-semibold py-1.5 px-3`, isLargeView && tw`px-1.5`])

const handleSelectWorkout = ({ workout, dispatch }) => {
  dispatch(
    workoutSelected({
      workout,
    })
  )
}

const WorkoutSelectedOverlay = styled.div(({ isWktSelected, isEditing }) => [
  tw`hidden`,
  isWktSelected && !isEditing && tw`block absolute inset-0 bg-tGreen bg-opacity-5 z-10 pointer-events-none`,
])

const WorkoutDescription = React.forwardRef((props, ref) => {
  const { instructions, setInstructions, onFocus } = props

  return (
    <div css={[tw`flex flex-1`]}>
      <DescriptionTextarea
        maxLength={125}
        rows={3}
        value={instructions}
        placeholder='Workout description (Optional)'
        onChange={(e) => setInstructions(e.target.value)}
        ref={ref}
        onFocus={onFocus}
      />
    </div>
  )
})

const DescriptionTextarea = tw.textarea`text-sm w-full resize-none focus:ring-0 border-0 text-gray-500 p-1.5
my-2 mx-3 placeholder:text-gray-400 overflow-hidden rounded-md
hover:bg-gray-100 hover:bg-opacity-70 focus:bg-gray-100 focus:bg-opacity-70
hover:text-black focus:text-black
`

function areEqual(prevProps, nextProps) {
  const propKeys = Object.keys(nextProps)
  const propsChanged = []

  propKeys.forEach((key) => {
    const isSame = prevProps[key] === nextProps[key]
    if (!isSame) {
      propsChanged.push(key)
    }
  })

  if (propsChanged.length) {
    // console.log('======== Workout.js dayIdx:', nextProps.dayIdx, '========')
    // console.log('propsChanged', propsChanged)

    return false
  } else {
    return true
  }
}

export default React.memo(Workout, areEqual)
