import tw from 'twin.macro'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import * as Accordion from '@radix-ui/react-accordion'
import { FiCopy } from 'react-icons/fi'
import { CgChevronDown, CgAdd } from 'react-icons/cg'
import { isEqual, pick } from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import TagInput from 'common/components/Tag/TagInput'

import {
  useUpdateExerciseMutation,
  useRemoveExerciseMutation,
  useListenExerciseVideoQuery,
  useListenExerciseThumbnailQuery,
  useListenExercisesQuery,
  useListenExLibVisibilityQuery,
} from '../exerciseLibraryApi'
import { useListenShowProgressionFilterQuery } from 'modules/ContentTags/contentTagsApi'
import { useSaveProgressionMutation } from 'modules/Progressions/progressionsApi'
import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useCustomization } from 'common/contexts/Customization/useCustomization'

import { schema } from 'modules/ExerciseLibrary/ExerciseForm/schema'
import { Input } from 'common/components/Input/Input'
import { CueInputList } from 'common/components/CueInput/CueInputList'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { DeleteConfirmationBanner } from 'common/components/DeleteConfirmationBanner/DeleteConfirmationBanner'
import { ExerciseProgressionAccordion } from './ExerciseProgressionAccordion'
import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { createUID } from 'common/utils/createUID'
import { removeFileExtension } from 'common/utils/fileUploading/nameAndIdUtils'
import { getCustomPreviewUrl, trimAndRemoveEmptyValues, capsFirstLetter } from './utils'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import AddUnitButton, { getAdjustedUnit } from 'common/components/AddUnitButton/AddUnitButton'
import Tag from 'common/components/Tag/Tag'
import { exerciseSelected } from '../exerciseLibrarySlice'
import { exerciseNameChanged, uploadingExerciseCancelled } from '../uploadingExercisesSlice'
import { useEventListener } from 'common/hooks/useEventListener'
import { getLandOrPortExThumb, getLandOrPortExVid } from 'common/utils/exerciseUtils'
import { exLibDisplayValues } from 'common/constants/exLibConstants'

function ExerciseForm({ coachOrgId, exercise = {}, exerciseKey, progressions, exerciseList }) {
  const { exVidOrientation } = useCustomization()
  const [updateExercise] = useUpdateExerciseMutation()
  const [removeExercise] = useRemoveExerciseMutation()
  const [saveProgression] = useSaveProgressionMutation() //Need to update progerssion if exercise is deleted
  const [accordionValue, setAccordionValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [, setDialogOpen] = useDialog()
  const { createAlert } = useAlert()
  const dispatch = useDispatch()

  const isNewExercise = !exerciseKey
  const [exerciseId, setExerciseId] = useState(null)

  const { data: video } = useListenExerciseVideoQuery({ coachOrgId, exerciseId, exVidOrientation })
  const { data: thumbnail } = useListenExerciseThumbnailQuery({ coachOrgId, exerciseId, exVidOrientation })
  // const { data: audio } = useListenExerciseAudioQuery({ coachOrgId, exerciseId })

  const { data: showProgressionFilter } = useListenShowProgressionFilterQuery({ coachOrgId })
  const { data: exLibVisibilityData } = useListenExLibVisibilityQuery({ coachOrgId })
  const showProgressionFilterLoading = showProgressionFilter === undefined || showProgressionFilter?.isLoading
  const exLibEnabledLoading = exLibVisibilityData === undefined || exLibVisibilityData?.isLoading
  const exLibEnabled = Boolean(exLibVisibilityData) && exLibVisibilityData !== exLibDisplayValues.hidden
  const showLevelAndTags =
    !showProgressionFilterLoading && !exLibEnabledLoading && (showProgressionFilter === true || exLibEnabled)

  const currentExerciseProgression =
    progressions !== null ? Object.entries(progressions).find(([_key, value]) => value.includes(exerciseKey)) : []
  const [progressionKey, progressionExercises] = currentExerciseProgression || []
  const progressionExercisesRef = useRef(progressionExercises || []) // Store in ref to have stable default value through component lifecycle

  const [isCreated, setIsCreated] = useState(false) //set if exercise is created by uploading asset

  const { data: exData } = useListenExercisesQuery({ coachOrgId, exVidOrientation })
  const tagsList = exData?.isFiller || exData === undefined ? [] : exData.tagsList

  useEffect(() => {
    if (!isNewExercise) {
      setExerciseId(exerciseKey)
    } else {
      const newId = createUID()
      const lowercaseNewId = newId.toLowerCase() //Temporary, mobile app cannot intrepet if not lowercase
      setExerciseId(`${lowercaseNewId}-`) //Arbitary "-" added as the letter "s" cannot be interpretted properly by app
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultValues = {
    name: exercise.name || '',
    video: getLandOrPortExVid(exVidOrientation, exercise) || '',
    thumbnail: getLandOrPortExThumb(exVidOrientation, exercise) || '',
    // audio: exercise.audio || '',
    level: exercise.level ? String(exercise.level) : '', //Need to parse to string in order for isDirty to work properly
    cues: exercise.cues || [],
    tags: exercise.tags || [],
    exercises: progressionExercisesRef.current,
    units: exercise?.units || [],
  }
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    context: {
      progressions,
      progressionKey: progressionKey,
      exerciseList,
    },
  })

  const {
    watch,
    setValue,
    setFocus,
    setError,
    clearErrors,
    register,
    formState: { errors },
    handleSubmit,
  } = methods

  const onSubmit = async (data) => {
    const exerciseData = pick(data, ['name', 'level', 'cues', 'tags', 'units']) //only include data that can be altered by ExerciseForm

    const alertText = isNewExercise && !isCreated ? 'Exercise created!' : 'Exercise updated!'
    setLoading(true)
    // const firebaseValidName = removeInvalidFirebaseChars(exerciseData.name)
    // const uploadExKey = isNewExercise ? (firebaseValidName + exerciseId).toLowerCase() + '-' : exerciseId //Have to lowercase entries because mobile-app does not properly match to capital letters right now
    const uploadExKey = exerciseId
    //Arbitrary "-" added to upload key save due to potential issues with mobile app parsing name function

    exerciseData.cues = trimAndRemoveEmptyValues(exerciseData.cues)
    exerciseData.cues = capsFirstLetter(exerciseData.cues)
    const progressionExercises = trimAndRemoveEmptyValues(data.exercises)

    await updateExercise({
      coachOrgId,
      exerciseKey: uploadExKey,
      exercise: { ...exerciseData, updatedAt: Date.now() },
    })

    dispatch(exerciseNameChanged({ exerciseId: exerciseKey, name: exerciseData.name }))

    if (progressionKey) {
      await saveProgression({ coachOrgId, progressionKey, exercises: progressionExercises })
    }
    setLoading(false)
    createAlert({ text: alertText, type: 'success' })
    setDialogOpen(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    await removeExercise({ coachOrgId, exerciseKey })
    if (progressionKey) {
      const updatedExercises = progressionExercises.filter((exercise) => exercise !== exerciseId)
      await saveProgression({
        coachOrgId,
        progressionKey: progressionKey,
        exercises: updatedExercises,
      })
    }
    dispatch(uploadingExerciseCancelled({ exerciseId: exerciseKey }))
    dispatch(exerciseSelected({ exId: exerciseKey }))
    setLoading(false)
    createAlert({ text: 'Exercise deleted!', type: 'success' })
    setDialogOpen(false)
  }

  const formState = watch()
  const isFormDisabled = isEqual(formState, defaultValues)
  const isInProgression = !isNewExercise && progressionKey

  const createExerciseOnUpload = (event) => {
    if (isNewExercise) {
      const file = event.target.files[0]
      if (file && file.name) {
        const exName = formState.name || removeFileExtension(file.name)
        const uploadExKey = exerciseId
        const exData = {
          name: exName,
        }

        updateExercise({
          coachOrgId,
          exerciseKey: uploadExKey,
          exercise: { ...exData, updatedAt: Date.now() },
        })
        const alertText = 'Creating exercise...'
        createAlert({ text: alertText, type: 'success' })
        setIsCreated(true)
      }
    }
  }

  // refs control
  const { moveFocusedInputTo, moveFocusedInputBy, moveFocusOnKeyPress, addInputRef } = useFormRefsControl()
  const nameRef = useRef()
  const tagRef = useRef()
  const levelRef = useRef()
  const submitRef = useRef()
  const inputRefsSortMethod = ['name', 'cue', 'exercise', 'progression', 'tag', 'level', 'submit']

  useEffect(() => {
    if (!showProgressionFilterLoading && !exLibEnabledLoading) {
      addInputRef({ ref: nameRef, posIdx: 0, name: 'name', sortMethod: inputRefsSortMethod })
      if (showLevelAndTags) {
        addInputRef({
          ref: tagRef,
          posIdx: formState.cues.length + formState.exercises.length + 1,
          name: 'tag',
          sortMethod: inputRefsSortMethod,
        })
        addInputRef({
          ref: levelRef,
          posIdx: formState.cues.length + formState.exercises.length + 2,
          name: 'level',
          sortMethod: inputRefsSortMethod,
        })
      }
      addInputRef({
        ref: submitRef,
        posIdx: formState.cues.length + formState.exercises.length + showLevelAndTags ? 3 : 1,
        name: 'submit',
        sortMethod: inputRefsSortMethod,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showProgressionFilterLoading, exLibEnabledLoading])

  const maybeOpenAndFocusProgressions = () => {
    if (accordionValue !== 'Progressions') {
      setAccordionValue('Progressions')

      const exercises = formState.exercises
      if (exercises.length > 0) {
        const isLastExEmptyStr = exercises[exercises.length - 1]?.trim() === ''
        // Need to make sure second to last progression exercise input is selected
        // in case the last input value is an empty string that was previously added by tabbing or button
        // because empty string remains in form state while closing/reopening accordion, but input itself is removed
        const lastExInputIdx = isLastExEmptyStr && exercises.length > 1 ? exercises.length - 2 : exercises.length - 1
        // Timeout is needed so that input is focused after progressions accordion is opened
        setTimeout(() => {
          setFocus(`exercises.${lastExInputIdx}`)
        }, 50)
      }
    }
  }

  const moveNameFocusOnKeyPress = (e) => {
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()
      if (e.shiftKey) {
        if (isFormDisabled && !exLibEnabled) {
          maybeOpenAndFocusProgressions()
        } else {
          moveFocusedInputBy(-1)
        }
      } else if (!e.shiftKey) {
        if (accordionValue !== 'formCues') {
          setAccordionValue('formCues')
        } else {
          moveFocusedInputBy(1)
        }
      }
    }
  }

  const moveTagFocusOnKeyPress = (e) => {
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()

      const isTagInputEmpty = e.target.value.trim() === ''
      if (e.code === 'Enter' || (!isTagInputEmpty && e.code === 'Tab')) {
        return
      }

      if (e.shiftKey) {
        maybeOpenAndFocusProgressions()
        moveFocusedInputBy(-1)
      } else {
        moveFocusedInputBy(1)
      }
    }
  }

  const copyCues = async (e) => {
    e.preventDefault()
    const toCopy = formState.cues.join('\n')
    await navigator.clipboard.writeText(toCopy || '')
    createAlert({ text: 'Cues copied!', type: 'success' })
  }

  useEventListener('keydown', (e) => {
    if (e.code === 'Tab') e.preventDefault()
  })

  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id='exerciseForm'
          name='Exercise Form'
          onKeyDown={(e) => {
            if (e.code === 'Enter' || e.code === 'Tab') {
              e.preventDefault() //Otherwise form autosubmits on each enter press
            }
          }}
        >
          <div className='flex flex-col px-4 md:px-10 py-4 border-b border-gray-200'>
            <ExerciseNameInput
              register={register}
              inputRef={nameRef}
              errors={errors}
              onKeyDown={(e) => {
                // When form is disabled and trying to use Tab to open formCues accordion onKeyUp is not firing
                // Opening accordion onKeyDown works
                if (
                  e.code === 'Tab' &&
                  !e.shiftKey &&
                  accordionValue !== 'formCues' &&
                  isFormDisabled &&
                  !exLibEnabled
                ) {
                  setAccordionValue('formCues')
                  if (formState.cues.length === 0) {
                    setTimeout(() => {
                      moveFocusedInputBy(1)
                    }, 50)
                  }
                }
              }}
              onKeyUp={moveNameFocusOnKeyPress}
              autoFocus={true}
            />
          </div>
          <div className='flex flex-col px-4 md:px-10 py-4 border-b border-gray-200'>
            <UploadInput
              name='video'
              liveUrl={video || getLandOrPortExVid(exVidOrientation, exercise)}
              previewUrl={getCustomPreviewUrl(exerciseId)}
              id={exerciseId}
              coachOrgId={coachOrgId}
              register={register}
              setValue={setValue}
              label='Video'
              uploadType='update-exercise-video'
              setError={setError}
              clearErrors={clearErrors}
              onUpload={isNewExercise ? createExerciseOnUpload : null}
              uploadDisabled={!formState.name || formState?.name?.length < 3}
              fileType='video'
              orientation={exVidOrientation}
            />
            <UploadInput
              name='thumbnail'
              liveUrl={thumbnail || getLandOrPortExThumb(exVidOrientation, exercise)}
              id={exerciseId}
              coachOrgId={coachOrgId}
              register={register}
              setValue={setValue}
              hidden
              uploadType='update-exercise-thumbnail'
              setError={setError}
              clearErrors={clearErrors}
              uploadDisabled={!formState.name}
              fileType='image'
            />
            {/* <UploadInput
              name='audio'
              liveUrl={audio || exercise?.audio}
              id={exerciseId}
              coachOrgId={coachOrgId}
              register={register}
              setValue={setValue}
              uploadType='update-exercise-audio'
              setError={setError}
              clearErrors={clearErrors}
              uploadDisabled={!formState.name}
              fileType='audio'
            /> */}
            {errors?.video ? <InputError>{errors.video.message}</InputError> : null}
            {/* {errors?.audio ? <InputError>{errors.audio.message}</InputError> : null} */}
          </div>
          <CueInputAccordion
            accordionValue={accordionValue}
            setAccordionValue={setAccordionValue}
            copyCues={copyCues}
            haveCues={Boolean(formState.cues.length)}
            inputRefsSortMethod={inputRefsSortMethod}
          />
          {!isNewExercise && (
            <ExerciseProgressionAccordion
              coachOrgId={coachOrgId}
              exerciseKey={exerciseKey}
              isInProgression={isInProgression}
              progressions={progressions}
              progressionKey={progressionKey}
              exerciseList={exerciseList}
              accordionValue={accordionValue}
              setAccordionValue={setAccordionValue}
              inputRefsSortMethod={inputRefsSortMethod}
              autoFocus={true}
              setValue={setValue}
            />
          )}
          {showLevelAndTags && (
            <div className='flex flex-col px-4 md:px-10 py-4 border-t border-gray-200'>
              <TagInput
                activeTags={formState.tags}
                tagsList={tagsList}
                setActiveTags={(value) => setValue('tags', value)}
                register={register}
                controlledInputRef={tagRef}
                onKeyUp={moveTagFocusOnKeyPress}
              />
              <LevelInput register={register} inputRef={levelRef} errors={errors} onKeyUp={moveFocusOnKeyPress} />
            </div>
          )}
          <div className='flex flex-col px-10 py-4 border-t border-gray-200'>
            <label className='capitalize inline-flex font-semibold text-tBlack mb-1'>Measurement Units</label>
            <div className='flex items-center'>
              {formState.units.map((unit) => (
                <Tag
                  key={getAdjustedUnit(unit)}
                  text={getAdjustedUnit(unit)}
                  remove={() =>
                    setValue(
                      'units',
                      formState.units.filter((u) => getAdjustedUnit(u) !== getAdjustedUnit(unit))
                    )
                  }
                  css={tw`mb-0 bg-black text-white px-2.5 py-1.5`}
                />
              ))}
              {formState.units.length < 2 && (
                <AddUnitButton
                  units={formState.units}
                  handleSelect={(value) => {
                    setValue('units', [...formState.units, value])
                  }}
                  isPortalled={false}
                />
              )}
            </div>
          </div>
        </form>
      </div>
      {!isNewExercise && deleteConfirmation ? (
        <DeleteConfirmationBanner
          text='Are you sure?'
          handleDelete={handleDelete}
          handleGoBack={() => setDeleteConfirmation(false)}
          loading={loading}
        />
      ) : (
        <UpdateActions
          itemKey={exerciseId}
          handleSubmit={handleSubmit}
          handleDelete={() => setDeleteConfirmation(true)}
          loading={loading}
          hideDelete={isNewExercise}
          disabled={isFormDisabled}
          actionText={'Save'}
          form='exerciseForm'
          ref={submitRef}
          onKeyUp={(e) => {
            if (e.code === 'Tab' && e.shiftKey && !exLibEnabled && accordionValue !== 'Progressions') {
              maybeOpenAndFocusProgressions()
            } else {
              moveFocusOnKeyPress(e)
            }
          }}
          onKeyDown={(e) => {
            if (e.code === 'Tab' && !e.shiftKey) {
              // Timeout is needed because keyUp event is triggered in progressionName input
              // and progressionName input is skipped immediately after tabbing to it from submitBtn
              // e.stopPropagation and e.preventDefault does not prevent event from firing
              e.stopPropagation()
              setTimeout(() => {
                moveFocusedInputTo(0)
              }, 200)
            }
          }}
        />
      )}
    </FormProvider>
  )
}

const Label = tw.label`inline-flex cursor-pointer font-semibold text-tBlack`

const InputError = tw.p`flex text-xs mt-1 text-tRed`

const ExerciseNameInput = ({ register, inputRef, errors, onKeyDown, onKeyUp, onFocus, autoFocus }) => (
  <div className='mb-2'>
    <Label htmlFor='name' tw='mb-1'>
      Exercise name
    </Label>
    <Input
      name='name'
      type='text'
      placeholder='Enter exercise name'
      register={register}
      inputRef={inputRef}
      error={errors?.name?.message}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onFocus={onFocus}
      autoFocus={autoFocus}
    />
    {errors.name && <InputError>{errors.name.message}</InputError>}
  </div>
)

const CueInputAccordion = ({ accordionValue, setAccordionValue, copyCues, haveCues, inputRefsSortMethod }) => {
  useEffect(() => {
    if (!haveCues) {
      setAccordionValue('')
    }
  }, [haveCues, setAccordionValue])

  return (
    <Accordion.Root
      type='single'
      collapsible='true'
      value={accordionValue}
      onValueChange={setAccordionValue}
      className='border-b border-gray-200'
    >
      <Accordion.Item value='formCues'>
        <Accordion.Header>
          <Accordion.Trigger
            type='button'
            className='flex items-center w-full group px-4 md:px-10 py-4'
            tabIndex={-1}
            aria-label='Form cues'
          >
            <Label htmlFor='cue1' tw='my-2'>
              Form cues
            </Label>
            {haveCues ? (
              <>
                <Tooltip content='Copy all cues' triggerClasses='mr-2 ml-auto text-tBlack hover:text-tGreen'>
                  <FiCopy className='w-[18px] h-[18px]' onClick={copyCues} aria-label='copy-cues' />
                </Tooltip>
                <CgChevronDown className='w-6 h-6 group-radix-state-open:-rotate-180 transition-all' />
              </>
            ) : (
              <CgAdd className='w-[22px] h-[22px] group-radix-state-open:-rotate-180 transition-all text-tBlack ml-auto' />
            )}
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <div className='pb-4'>
            <CueInputList
              setAccordionValue={setAccordionValue}
              inputRefsSortMethod={inputRefsSortMethod}
              onEmptyInputKeyPress={() => setAccordionValue('Progressions')}
            />
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}

const LevelInput = ({ register, inputRef, errors, onKeyUp, onFocus }) => (
  <>
    <div className='flex items-center mt-3'>
      <Label htmlFor='level'>Level</Label>
      <Input
        name='level'
        type='number'
        register={register}
        inputRef={inputRef}
        placeholder='1-100'
        min={1}
        max={100}
        tw='h-10 w-[90px] ml-4'
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        error={errors?.level?.message}
      />
    </div>
    {errors.level && <InputError>{errors.level.message}</InputError>}
  </>
)

export default ExerciseForm
