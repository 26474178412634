import React, { useState } from 'react'
import { sortBy } from 'lodash'

import { Dialog, DialogContent } from 'common/components/Dialog/Dialog'
import { Pagination } from 'common/components/Pagination/Pagination'
import { usePagination } from 'common/components/Pagination/hooks/usePagination'
import { handleSortOfDates } from 'common/utils/sortUtils'

import { TableHead } from './TableHead'
import { TableBody } from './TableBody'
import { EmptyTable } from './EmptyTable'
import { VideoForm } from '../VideoForm/VideoForm'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'

export function VideoTable({ coachOrgId, filteredVideos, loading, limit = 20 }) {
  const videosEntries = filteredVideos ? Object.entries(filteredVideos) : []
  const totalVideos = filteredVideos ? Object.keys(filteredVideos).length : 0
  const [sortMethod, setSortMethod] = useState('updated')
  const [sortOrder, setSortOrder] = useState('asc')
  const { setCurrentPage, currentPage, pageLimit, totalPages, offset, itemCount } = usePagination({
    itemCount: totalVideos,
    initialLimit: limit,
  })
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [modalVideo, setModalVideo] = useState({})

  const tableCols = [
    { title: 'title', sortOrder, setSortOrder, sortMethod, setSortMethod, hideOnMobile: false },
    { title: 'created', sortOrder, setSortOrder, sortMethod, setSortMethod, hideOnMobile: true },
    { title: 'updated', sortOrder, setSortOrder, sortMethod, setSortMethod, hideOnMobile: true },
  ]

  const sortedVideos = sortVideos(videosEntries, sortOrder, sortMethod)

  return (
    <>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        itemCount={totalVideos}
        totalPages={totalPages}
      />
      <div className='overflow-x-auto'>
        <div className='align-middle inline-block min-w-full'>
          <div className='overflow-hidden border-b border-gray-200 rounded-xl' data-testid='table-wrapper'>
            {!loading && itemCount === 0 ? (
              <EmptyTable />
            ) : (
              <table className='min-w-full divide-y divide-gray-200'>
                <TableHead columns={tableCols} />
                <TableBody
                  coachOrgId={coachOrgId}
                  loading={loading}
                  filteredVideos={sortedVideos}
                  offset={offset}
                  currentPage={currentPage}
                  pageLimit={pageLimit}
                  setModalVideo={setModalVideo}
                  setEditDialogOpen={setEditDialogOpen}
                />
              </table>
            )}
          </div>
        </div>
      </div>
      <Dialog open={editDialogOpen} setOpen={setEditDialogOpen}>
        <DialogContent header='Edit Video'>
          <FormRefsControlProvider>
            <VideoForm
              coachOrgId={coachOrgId}
              videoKey={modalVideo.videoKey}
              videoData={modalVideo.video}
              deleteVideo={modalVideo.handleDelete}
            />
          </FormRefsControlProvider>
        </DialogContent>
      </Dialog>
      <div className='mt-4'>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageLimit={pageLimit}
          itemCount={totalVideos}
          totalPages={totalPages}
          counterContainerClasses='place-self-start'
        />
      </div>
    </>
  )
}

export function sortVideos(videos, sortOrder, sortMethod) {
  if (sortMethod === 'updated') {
    return handleSortOfDates(videos, sortOrder, 'updatedAt')
  } else if (sortMethod === 'created') {
    return handleSortOfDates(videos, sortOrder, 'createdAt')
  } else {
    return sortOrder === 'asc'
      ? sortBy(videos, ([key, pg]) => {
          return pg.name && pg.name.toLowerCase()
        }).reverse()
      : sortBy(videos, ([key, pg]) => {
          return pg.name && pg.name.toLowerCase()
        })
  }
}
