import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CgClose, CgCheckO, CgChevronUp } from 'react-icons/cg'
import { each } from 'lodash'

import { CircularProgress } from 'common/components/CircularProgress/CircularProgress'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { cancelAWSUpload } from 'common/utils/fileUploading/uploadUtils'

import { getTotalUploadingProgress, getUploadedCount, uploadingExercisesCleared } from '../uploadingExercisesSlice'

export function UploadingProgressSnack({
  setUploadDialogOpen,
  uploadingExercises,
  allExFinishedUploading,
  setUploadErrors,
}) {
  const uploadingExValues = Object.values(uploadingExercises)
  const uploadedExCount = useSelector((state) => getUploadedCount(state))

  const dispatch = useDispatch()
  const { createAlert } = useAlert()

  const [cancelUploadDialogOpen, setCancelUploadDialogOpen] = useState(false)
  const totalPercent = useSelector((state) => getTotalUploadingProgress(state))

  return (
    <div className='flex px-2 py-1 items-center fixed left-2 right-2 md:right-auto md:left-8 bottom-5 bg-white rounded-md shadow-xl z-30 border-2 border-gray-300 text-tBlack'>
      {totalPercent < 100 ? (
        <CircularProgress radius='21' strokeWidth='2.5' percent={totalPercent} textClassnames='text-xs' />
      ) : (
        <CgCheckO className='text-tGreen w-8 h-8 m-[5px]' />
      )}
      <h4 className='ml-2 font-semibold mr-2 md:mr-6 text-sm md:text-base'>
        {totalPercent < 100 ? 'Uploading exercises...' : 'Exercises uploaded!'}
      </h4>
      <div className='ml-auto flex items-center'>
        <div
          className='border-l-2 pl-2 md:pl-6 border-gray-300 mr-2 md:mr-6 text-gray-500 font-semibold text-sm md:text-base'
          data-testid='uploadedExCount'
        >{`${uploadedExCount}/${uploadingExValues.length} uploaded`}</div>
        <CgChevronUp
          className='w-5 h-5 ml-auto mr-2 hover:text-tGreen cursor-pointer'
          onClick={() => setUploadDialogOpen(true)}
        />
        {!allExFinishedUploading ? (
          <Dialog open={cancelUploadDialogOpen} setOpen={setCancelUploadDialogOpen}>
            <DialogTrigger>
              <CgClose className='w-4 h-4 hover:text-tRed cursor-pointer mr-2' />
            </DialogTrigger>
            <DialogContent>
              <DeleteDialogBanner
                text='This will cancel uploading exercises'
                handleDelete={async () => {
                  await handleCancelAllUploads({ uploadingExercises })
                  dispatch(uploadingExercisesCleared())
                  setUploadErrors({})
                  createAlert({ text: 'Uploads canceled!', type: 'success' })
                }}
              />
            </DialogContent>
          </Dialog>
        ) : (
          <CgClose
            className='w-4 h-4 hover:text-tRed cursor-pointer mr-2'
            onClick={() => {
              dispatch(uploadingExercisesCleared())
              setUploadErrors({})
            }}
          />
        )}
      </div>
    </div>
  )
}

async function handleCancelAllUploads({ uploadingExercises }) {
  each(uploadingExercises, async (ex) => {
    const storageKey = ex.storageKey
    await cancelAWSUpload(storageKey)
  })
}
