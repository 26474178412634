import { isEmpty } from 'lodash'
import { useListenDraftResultQuery, useListenPublishedResultQuery } from '../appOnboardingQuizApi'

function useQuizResultExists({ coachOrgId, itemId }) {
  const { data: publishedQuizResults } = useListenPublishedResultQuery({ coachOrgId, itemId })
  const { data: draftQuizResults } = useListenDraftResultQuery({ coachOrgId, itemId })
  const isQuizResultsLoading =
    publishedQuizResults === undefined ||
    publishedQuizResults?.isLoading ||
    draftQuizResults === undefined ||
    draftQuizResults?.isLoading
  const quizResultExists = !isQuizResultsLoading && (!isEmpty(publishedQuizResults) || !isEmpty(draftQuizResults))

  return { quizResultExists, isQuizResultsLoading }
}

export default useQuizResultExists
