import * as yup from 'yup'

const trimString = (_, originalValue) => String(originalValue).trim()

export const profileSchema = yup
  .object({
    firstname: yup
      .string()
      .required('First name is required')
      .max(30, 'First name is too long - should be at most 30 characters')
      .transform(trimString),
    lastname: yup
      .string()
      .required('Last name is required')
      .max(30, 'Last name is too long - should be at most 30 characters')
      .transform(trimString),
  })
  .required()

export const brandingSchema = yup
  .object({
    coachName: yup
      .string()
      .required('Coach name is required')
      .max(30, 'Coach name is too long - should be at most 30 characters')
      .transform(trimString),
    orgName: yup
      .string()
      .required('Mobile app title is required')
      .max(30, 'Mobile app title is too long - should be at most 30 characters')
      .transform(trimString),
  })
  .required()
