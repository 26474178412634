import tw from 'twin.macro'
import React, { forwardRef } from 'react'
import * as RadixScrollArea from '@radix-ui/react-scroll-area'

export const ScrollArea = forwardRef(({ children, className, ...otherProps }, ref) => (
  <RadixScrollArea.Root type='auto'>
    <RadixScrollArea.Viewport {...otherProps} tw='max-h-40' className={className} ref={ref}>
      {children}
    </RadixScrollArea.Viewport>
    <RadixScrollArea.Scrollbar orientation='vertical' css={scrollbarBase} className='radix-orientation-vertical:w-2'>
      <RadixScrollArea.Thumb className={scrollThumbClasses} />
    </RadixScrollArea.Scrollbar>
    <RadixScrollArea.Scrollbar
      orientation='horizontal'
      css={scrollbarBase}
      className='radix-orientation-horizontal:flex-col radix-orientation-horizontal:h-2'
    >
      <RadixScrollArea.Thumb className={scrollThumbClasses} />
    </RadixScrollArea.Scrollbar>
    <RadixScrollArea.Corner />
  </RadixScrollArea.Root>
))

const scrollbarBase = tw`flex select-none p-0.5 bg-gray-300 transition-all hover:bg-gray-200`

const scrollThumbClasses =
  'flex-1 bg-gray-400 rounded-xl relative before:content-[""] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]'
