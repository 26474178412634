import tw from 'twin.macro'
import React, { useState } from 'react'

import { useListenCoachVideoQuery } from 'modules/VideoLibrary/videoLibraryApi'

import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { EmptyStateContainer } from 'common/components/EmptyStateContainer/EmptyStateContainer'

export function CoachVideo({ coachOrgId, videoId }) {
  const { data: videoData, isLoading } = useListenCoachVideoQuery({ coachOrgId, videoKey: videoId })
  const videosLoading = videoData?.isLoading || isLoading

  const [error, setError] = useState(null)

  if (videosLoading) {
    return (
      <div className='h-[calc(100vh-80px)] bg-offWhite px-4 md:px-16 py-12'>
        <div className='w-full max-w-7xl m-auto'>
          <Skeleton css={tw`max-w-2xl my-4 h-3`} />
          <Skeleton css={tw`max-w-7xl rounded-xl w-full h-[calc(100vw/2)] max-h-[720px]`} />
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col min-h-[calc(100vh-80px)] bg-offWhite px-4 md:px-16 py-12'>
      <div className='w-full max-w-7xl m-auto'>
        {!error && videoData?.video ? (
          <>
            <h1 className='text-3xl md:text-5xl font-bold text-tBlack mb-4 max-w-2xl capitalize'>{videoData?.title}</h1>
            <div className='w-full rounded-xl overflow-hidden'>
              <video autoPlay controls className='object-cover w-full h-full'>
                <source onError={() => setError('No video found')} src={videoData?.video} />
              </video>
            </div>
          </>
        ) : (
          <EmptyStateContainer text={error} />
        )}
      </div>
    </div>
  )
}
