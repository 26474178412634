import tw, { styled, css } from 'twin.macro'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Handle, Position, useReactFlow, useViewport } from '@xyflow/react'
import { isEmpty, sortBy } from 'lodash'
import { CgAdd } from 'react-icons/cg'
import { FiAlertTriangle } from 'react-icons/fi'

import { closestCenter, DndContext, MouseSensor, useSensors, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Sortable } from 'common/components/Sortable/Sortable'

import { useUpdateQuestionMutation, useUpdateQuestionsMutation } from '../appOnboardingQuizApi'

import { useDebounce } from 'common/hooks/useDebounce'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'
import { createUID } from 'common/utils/createUID'
import { getStandardizedName } from 'common/utils/stringUtils'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogClose } from 'common/components/Dialog/Dialog'

import Answer from './Answer'
import { getAnswerHandleTopOffset } from '../utils/flowLayoutingUtils'
import { updateQuizError } from '../quizFlowSlice'
import QuestionMoreActions from './QuestionMoreActions'
import { getMissingDataWarningText } from '../utils/warningUtils'

export const ANSWER_LIMIT = 5
export const NODE_LIMIT = 200

const QuestionNode = memo(({ id, data, selected }) => {
  const dispatch = useDispatch()

  const { zoom } = useViewport()
  const { getNode, getNodes } = useReactFlow()

  const [updateQuestion] = useUpdateQuestionMutation()
  const [updateQuestions] = useUpdateQuestionsMutation()
  const [showLimitReachedDialog, setShowLimitReachedDialog] = useState(false)

  const errors = useSelector((state) => state.quizFlow.quizErrors?.[id])
  const copiedQuestion = useSelector((state) => state.quizFlow.copiedQuestion)

  const [questionTitle, setQuestionTitle] = useState(data.title)

  const debouncedQuestionTitle = useDebounce(questionTitle, 500)
  useDebouncedTextMutation({
    stateText: questionTitle,
    dbText: data.title,
    mutation: updateQuestion,
    debouncedStateText: debouncedQuestionTitle,
    mutationArgs: {
      coachOrgId: data?.coachOrgId,
      question: {
        title: debouncedQuestionTitle,
      },
      questionId: id,
    },
  })

  useEffect(() => {
    if (data.title !== questionTitle) {
      setQuestionTitle(data.title)
    }
    // eslint-disable-next-line
  }, [data.title])

  const [questionDescription, setQuestionDescription] = useState(data.description)
  const debouncedQuestionDescription = useDebounce(questionDescription, 500)
  useDebouncedTextMutation({
    stateText: questionDescription,
    dbText: data.description,
    mutation: updateQuestion,
    debouncedStateText: debouncedQuestionDescription,
    mutationArgs: {
      coachOrgId: data?.coachOrgId,
      question: {
        description: debouncedQuestionDescription,
      },
      questionId: id,
    },
  })

  useEffect(() => {
    if (data.description !== questionDescription) {
      setQuestionDescription(data.description)
    }
    // eslint-disable-next-line
  }, [data.description])

  const onAddAnswer = () => {
    const getNodeCount = getNodes().length
    if (getNodeCount >= NODE_LIMIT) {
      setShowLimitReachedDialog(true)
      return
    }

    const newQuestionId = createUID()
    const newQuestion = {
      id: newQuestionId,
      previousQuestionId: id,
    }
    const answers = data?.answers || {}
    updateQuestions({
      coachOrgId: data?.coachOrgId,
      update: {
        [`${id}/answers/${newQuestionId}`]: {
          index: Object.values(answers).length,
          nextQuestionId: newQuestionId,
        },
        [newQuestionId]: newQuestion,
      },
    })
  }

  const sortedAnswers = sortBy(Object.values(data?.answers || {}), (answer) => answer?.index)
  const answerIds = sortedAnswers.map((answer) => answer?.resultId || answer?.nextQuestionId)

  // Drag and drop - START
  const sensors = useSensors(useSensor(MouseSensor))
  const [isDragging, setIsDragging] = useState(false)
  const handleDragStart = () => {
    setIsDragging(true)
  }

  const handleDragEnd = async ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = data?.answers?.[active.id]?.index
      const newIndex = data?.answers?.[over.id]?.index
      const reorderedAnswers = arrayMove(sortedAnswers, oldIndex, newIndex)

      let answersUpdate = {}
      reorderedAnswers.forEach((a, index) => {
        answersUpdate[a?.resultId || a?.nextQuestionId] = { ...a, index }
      })
      await updateQuestion({
        coachOrgId: data?.coachOrgId,
        question: {
          answers: answersUpdate,
        },
        questionId: id,
      })
    }
    setIsDragging(false)
  }
  // Drag and drop - END

  const isNodeCollapsed = !selected
  const missingDataWarningText = getMissingDataWarningText({ questionTitle, answers: data?.answers, getNode })
  const showOneOfAnswersRequireSelection = !selected && Boolean(missingDataWarningText)
  return (
    <Container
      errors={Boolean(errors)}
      selected={selected}
      wasSelected={data?.wasSelected}
      isDragging={isDragging}
      showOneOfAnswersRequireSelection={showOneOfAnswersRequireSelection}
    >
      <InnerContainer isNodeCollapsed={isNodeCollapsed}>
        {/*Target Handle for Question (max one per node, on the left)*/}
        {data?.showHandle && <Handle type='target' position={Position.Left} isConnectable={false} />}
        <QuestionMoreActions
          questionData={data}
          copiedQuestion={copiedQuestion}
          coachOrgId={data?.coachOrgId}
          isNodeCollapsed={isNodeCollapsed}
        />
        <div className='relative mb-1' css={[isNodeCollapsed && tw`mb-0`]}>
          <input
            type='text'
            maxLength={70}
            css={[
              inputClasses,
              !isNodeCollapsed && inputActiveClasses,
              !isNodeCollapsed &&
                !getStandardizedName(questionTitle)?.length &&
                tw`ring-1 ring-orange-300 focus:ring-1 focus:ring-orange-300`,
              !isNodeCollapsed && errors?.questionTitle && tw`ring-1 ring-red-500 focus:ring-1 focus:ring-red-500`,
            ]}
            className='text-ellipsis bg-inherit nodrag nopan'
            placeholder={isNodeCollapsed ? 'Untitled question' : 'Question title'}
            onChange={(e) => {
              setQuestionTitle(e.target.value)
              if (errors?.questionTitle) {
                let updatedErrors = { ...errors }
                delete updatedErrors.questionTitle
                dispatch(updateQuizError({ nodeId: id, errors: isEmpty(updatedErrors) ? null : updatedErrors }))
              }
            }}
            value={questionTitle}
          />
          {!isNodeCollapsed && errors?.questionTitle && (
            <p className='absolute bottom-0 right-1 text-xxs text-red-500'>{errors.questionTitle}</p>
          )}
        </div>
        {!isNodeCollapsed && (
          <input
            type='text'
            maxLength={180}
            value={questionDescription}
            placeholder='Question description (Optional)'
            onChange={(e) => {
              setQuestionDescription(e.target.value)
            }}
            css={[
              inputClasses,
              !isNodeCollapsed && inputActiveClasses,
              tw`font-normal text-gray-500 hover:text-black focus:text-black`,
              isNodeCollapsed && tw`mb-0`,
            ]}
            className='text-ellipsis bg-inherit nodrag nopan mb-2'
          />
        )}
        {!isEmpty(data?.answers) && !isNodeCollapsed ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={answerIds} strategy={verticalListSortingStrategy}>
              {sortedAnswers.map((answer) => {
                const showSelectionRequired =
                  !selected &&
                  Boolean(answer?.nextQuestionId) &&
                  isEmpty(getNode(answer?.nextQuestionId)?.data?.answers)
                return (
                  <div key={answer?.resultId || answer?.nextQuestionId} className='group'>
                    {/*Source Handle for each Question, on the right*/}
                    <Handle
                      type='source'
                      id={answer?.resultId || answer?.nextQuestionId}
                      position={Position.Right}
                      style={{
                        top: getAnswerHandleTopOffset({
                          index: answer?.index,
                          isNodeCollapsed,
                        }),
                        right: 17,
                        zIndex: 10,
                        opacity: showSelectionRequired ? 0 : 1,
                      }}
                      className='group-hover:!opacity-0'
                      isConnectable={false}
                    />
                    {showSelectionRequired && (
                      <Tooltip
                        content={missingDataWarningText}
                        triggerClasses='bg-white z-10 rounded-full'
                        triggerStyles={{
                          position: 'absolute',
                          top:
                            getAnswerHandleTopOffset({
                              index: answer?.index,
                              isNodeCollapsed,
                            }) - 11,
                          right: 8,
                        }}
                      >
                        <FiAlertTriangle className='w-5 h-5 text-orange-300' />
                      </Tooltip>
                    )}
                    <Sortable
                      id={answer?.resultId || answer?.nextQuestionId}
                      draggingClasses='relative ring-1 ring-tGreen rounded-md z-50 bg-white shadow-lg cursor-move'
                      className='mb-2 bg-white'
                      withHandle={true}
                      variableSize={true}
                      zoom={zoom}
                    >
                      <Answer
                        id={answer?.resultId || answer?.nextQuestionId}
                        answer={answer}
                        answers={data.answers}
                        questionId={id}
                        previousQuestionId={data?.previousQuestionId}
                        coachOrgId={data?.coachOrgId}
                        errors={errors}
                        setQuestionTitle={setQuestionTitle}
                        setQuestionDescription={setQuestionDescription}
                        isNodeCollapsed={isNodeCollapsed}
                      />
                    </Sortable>
                  </div>
                )
              })}
            </SortableContext>
          </DndContext>
        ) : (
          <div>
            {sortedAnswers.map((answer) => {
              return (
                <div key={answer?.resultId || answer?.nextQuestionId} className='group'>
                  <CollapsedAnswer
                    answer={answer}
                    noNodesSelected={data?.noNodesSelected || false}
                    selectedQuestionParentAnswer={data?.selectedQuestionParentAnswer || null}
                  />
                  <Handle
                    type='source'
                    id={answer?.resultId || answer?.nextQuestionId}
                    position={Position.Right}
                    style={{
                      top: getAnswerHandleTopOffset({
                        index: answer?.index,
                        isNodeCollapsed,
                        answerCount: sortedAnswers.length,
                        noNodesSelected: data?.noNodesSelected || false,
                        selectedQuestionParentAnswerExist: Boolean(data?.selectedQuestionParentAnswer),
                      }),
                      right: 1,
                      zIndex: 10,
                      opacity: showOneOfAnswersRequireSelection ? 0 : 1,
                    }}
                    className='group-hover:!opacity-0'
                    isConnectable={false}
                  />
                </div>
              )
            })}
            {showOneOfAnswersRequireSelection && (
              <Tooltip
                content={missingDataWarningText}
                triggerClasses='bg-white z-10 rounded-full'
                triggerStyles={{
                  position: 'absolute',
                  top:
                    getAnswerHandleTopOffset({
                      isNodeCollapsed,
                      answerCount: sortedAnswers.length,
                      noNodesSelected: data?.noNodesSelected || false,
                      selectedQuestionParentAnswerExist: Boolean(data?.selectedQuestionParentAnswer),
                    }) - 10,
                  right: -8,
                }}
              >
                <FiAlertTriangle className='w-5 h-5 text-orange-300' />
              </Tooltip>
            )}
          </div>
        )}
        {!isNodeCollapsed && sortedAnswers.length < ANSWER_LIMIT && (
          <Button
            variant='secondary'
            size='sm'
            css={tw`hover:bg-gray-100 transition-all h-14`}
            className='nodrag nopan'
            onClick={onAddAnswer}
          >
            <CgAdd className='w-4 h-4 mr-2' /> Add answer
          </Button>
        )}
        <Dialog open={showLimitReachedDialog} setOpen={setShowLimitReachedDialog}>
          <DialogContent
            dialogCloseCb={(e) => {
              e.stopPropagation()
            }}
            header='Limit reached'
          >
            <div className='flex px-10 pt-10 pb-8 flex-col items-center justify-center'>
              <span className='block max-w-xs mx-auto text-center text-tBlack mb-6'>
                Question limit reached. Please delete or modify existing questions.
              </span>
              <div className='flex w-full items-center justify-center'>
                <DialogClose
                  dialogCloseCb={(e) => e.stopPropagation()}
                  css={[buttonBase, buttonVariants.secondary, buttonSizes.lg, tw`w-1/2`]}
                >
                  Okay
                </DialogClose>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </InnerContainer>
    </Container>
  )
})

export default QuestionNode

function CollapsedAnswer({ answer, noNodesSelected, selectedQuestionParentAnswer }) {
  if (noNodesSelected) {
    return (
      <div className='text-xs px-1 truncate text-gray-500 h-5'>
        <span className='w-4 inline-block font-semibold'>{answer?.index + 1}.</span>
        <span className='font-medium'>{answer?.title || 'Untitled answer'}</span>
      </div>
    )
  }

  if (selectedQuestionParentAnswer && answer?.index === selectedQuestionParentAnswer?.index) {
    return (
      <div className='text-xs px-1 truncate text-gray-500 h-5'>
        <span className='w-4 inline-block font-semibold'>{selectedQuestionParentAnswer?.index + 1}.</span>
        <span className='font-medium'>{selectedQuestionParentAnswer?.title || 'Untitled answer'}</span>
      </div>
    )
  }

  return null
}

export const inputClasses = tw`
  h-8
  font-medium text-sm 
  focus:ring-0 p-1 py-1.5
  w-full border-none overflow-hidden 
  whitespace-nowrap rounded placeholder:text-black
  cursor-pointer
`

export const inputActiveClasses = tw`
  hover:bg-gray-100 hover:bg-opacity-70 
  focus:bg-gray-100 focus:bg-opacity-70 
  placeholder:text-gray-400
`

const Container = styled.div(({ errors, selected, wasSelected, isDragging, showOneOfAnswersRequireSelection }) => [
  tw`border rounded-md border-gray-300`,
  wasSelected && tw`border-black border-2`,
  errors && tw`border-red-500 border`,
  showOneOfAnswersRequireSelection && tw`border-red-500 border`,
  selected && tw`border-tGreen border`,
  isDragging && tw`pointer-events-none`,
])

const InnerContainer = styled.div(({ isNodeCollapsed }) => [
  tw`relative p-4 w-96`,
  isNodeCollapsed && tw`p-2`,
  css`
    &:hover #more-actions {
      display: flex;
    }
  `,
])
