import { linkImgErrKey, linkTitleErrKey, linkUrlErrKey, urlSchema } from './LinkInputCard'

export const getLinkResourceErrors = (resource) => {
  let linkResourceErrors = {}
  if (resource.title.trim().length === 0) {
    linkResourceErrors[linkTitleErrKey] = 'Title is required'
  }

  try {
    urlSchema.validateSync(resource.url)
  } catch (error) {
    linkResourceErrors[linkUrlErrKey] = error.message
  }

  if (resource.previewImg && resource.previewImg === 'uploading') {
    linkResourceErrors[linkImgErrKey] = 'Image is uploading'
  } else if (resource.previewImg) {
    delete linkResourceErrors[linkImgErrKey]
  }

  return linkResourceErrors
}
