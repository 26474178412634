import React, { useState, useEffect, useRef } from 'react'
import tw, { styled, css } from 'twin.macro'
import EditExercise from 'modules/Programs/components/exercise/EditExerciseView'
import Sets from 'modules/Programs/components/Sets'
import { useListenBenchmarksQuery, useSavePartsMutation } from '../programApi'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Sortable } from 'common/components/Sortable/Sortable'
import { Droppable } from 'common/components/Droppable/Droppable'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { PastePartButton } from './styles'
import { maybePluralize } from 'common/utils/stringUtils'
import { handlePartBtnMouseEnter, handlePartBtnMouseLeave, handlePastePart } from '../handlers/commonPartHandlers'
import { getIsPartSelected } from 'modules/Programs/programSlice'
import { useSelector } from 'react-redux'
import { PartHeader } from './PartHeader'
import { useMonitorAnyWktDragging } from '../hooks/useMonitorAnyWktDragging'
import { useMonitorAnyPartDragging } from '../hooks/useMonitorAnyPartDragging'
import { useMonitorExDragOver } from '../hooks/useMonitorExDragOver'
import { EditBenchmark } from './Benchmarks/EditBenchmark'
import SplitDropdownAddExercise from './SplitDropdownAddExercise'

const PastePartBtnPlaceholder = styled.div(css`
  &:hover #partcontainer-actions {
    display: flex;
  }
`)

const PartContainer = styled.div(({ isPartSelected, isEditing, isLastPart }) => [
  tw`flex flex-col relative border-b bg-white`,
  isPartSelected && !isEditing && tw`bg-white hover:bg-white`,
  !isEditing && isLastPart && tw`border-b-0`,
  css`
    &:hover #partcontainer-actions {
      display: flex;
      align-items: center;
    }
  `,
])

export const EditPart = React.memo((props) => {
  const {
    part,
    partIdx,
    weekIdx,
    dayIdx,
    isEditing,
    setEditIdx,
    partsCopied,
    workout,
    attributes,
    listeners,
    orgId,
    setActivePartIdx, //For hotkeys
    setActiveExIdx,
    isLastPart,
  } = props
  const addExRef = useRef()
  const exercises = part?.exercises

  const [saveParts] = useSavePartsMutation()
  const { createAlert } = useAlert()

  const isAnyWktDragging = useMonitorAnyWktDragging()
  const isAnyPartDragging = useMonitorAnyPartDragging()

  const dragOverEx = useMonitorExDragOver({ monitorPartId: part.id })

  const { data: benchmarks } = useListenBenchmarksQuery({ orgId })
  const isPartSelected = useSelector((state) => getIsPartSelected(state, part.id))
  const [isPartSelectedState, setIsPartSelectedState] = useState(isPartSelected)
  const [benchmarkDialogInfo, setBenchmarkDialogInfo] = useState(null)
  useEffect(() => {
    setIsPartSelectedState(isPartSelected)
  }, [isPartSelected])

  const exerciseIds = exercises.map((exercise) => exercise.dndUID)

  return (
    <PartContainer isPartSelected={isPartSelected} isEditing={isEditing} isLastPart={isLastPart}>
      <PartSelectedOverlay
        data-testid='partSelectedOverlay'
        isEditing={isEditing}
        isPartSelected={isPartSelectedState}
      />
      {partsCopied.length > 0 && (
        <PastePartButton
          id='partcontainer-actions'
          className='top-0 -translate-y-1/2 -translate-x-1/2'
          onClick={(e) => {
            e.stopPropagation()

            handlePastePart({
              workout,
              partIdx,
              partsCopied,
              saveParts,
              createAlert,
              orgId,
            })
          }}
          onMouseEnter={handlePartBtnMouseEnter}
          onMouseLeave={handlePartBtnMouseLeave}
        >
          paste {maybePluralize({ text: 'block', count: partsCopied.length })}
        </PastePartButton>
      )}
      <PastePartBtnPlaceholder className='absolute left-1/2 top-0 -translate-y-1/2 -translate-x-1/2 w-[83px] h-[22px]' />
      <div className='p-4'>
        <Droppable
          id={`${part.id}-top`}
          disabled={isAnyWktDragging || isAnyPartDragging}
          customData={{ itemType: 'partTop', workout, part, partIdx }}
        >
          <PartHeader
            part={part}
            partIdx={partIdx}
            isPartSelected={isPartSelectedState}
            setIsPartSelectedState={setIsPartSelectedState}
            workout={workout}
            createAlert={createAlert}
            attributes={attributes}
            listeners={listeners}
            orgId={orgId}
            setActivePartIdx={setActivePartIdx}
            isEditing={isEditing}
            disableDroppable={isAnyWktDragging || isAnyPartDragging}
          />
        </Droppable>
        <Sets partIdx={partIdx} weekIdx={weekIdx} dayIdx={dayIdx} part={part} workout={workout} isEditing={isEditing} />
      </div>
      <SortableContext items={exerciseIds} strategy={verticalListSortingStrategy}>
        {exercises.map((exercise, idx) => (
          <div key={exercise.dndUID} className='relative'>
            <ExDragOverIndicator
              dragOverEx={dragOverEx}
              partId={part.id}
              exIdx={idx}
              isLastExercise={exercises.length - 1 === idx}
              workoutId={workout.id}
            />
            <Sortable
              id={exercise.dndUID}
              css={[tw`mb-2.5 mx-2.5 rounded-md`, idx === exercises?.length - 1 && tw`mb-0`]}
              draggingClasses='opacity-50 ring-2 ring-tGreen z-10'
              withHandle={true}
              variableSize={true}
              customData={{ itemType: 'exercise', exercise, exIdx: idx, workout, part, partIdx }}
              disabled={{ droppable: isAnyWktDragging || isAnyPartDragging }}
            >
              {exercise?.type === 'benchmark' ? (
                <EditBenchmark
                  index={idx}
                  benchmark={benchmarks?.[exercise?.id]}
                  exercise={exercise}
                  workoutId={workout.id}
                  partIdx={partIdx}
                  isEditing={isEditing}
                  orgId={orgId}
                  setBenchmarkDialogInfo={setBenchmarkDialogInfo}
                />
              ) : (
                <EditExercise
                  exercise={exercise}
                  exIdx={idx}
                  partIdx={partIdx}
                  weekIdx={weekIdx}
                  dayIdx={dayIdx}
                  workoutId={workout.id}
                  isEditingWkt={isEditing}
                  setEditIdx={setEditIdx}
                  setActivePartIdx={setActivePartIdx}
                  setActiveExIdx={setActiveExIdx}
                  addExRef={addExRef}
                  isLastExInPart={idx === exercises?.length - 1}
                  isLastExercise={idx === exercises?.length - 1 && isLastPart}
                  isPartSelected={isPartSelected}
                />
              )}
            </Sortable>
          </div>
        ))}
      </SortableContext>
      {partsCopied.length > 0 && isLastPart && (
        <PastePartButton
          id='partcontainer-actions'
          className='bottom-0 translate-y-1/2 -translate-x-1/2'
          onClick={(e) => {
            e.stopPropagation()

            handlePastePart({
              workout,
              partIdx: partIdx + 1,
              partsCopied,
              saveParts,
              createAlert,
              orgId,
            })
          }}
          onMouseEnter={handlePartBtnMouseEnter}
          onMouseLeave={handlePartBtnMouseLeave}
        >
          paste {maybePluralize({ text: 'block', count: partsCopied.length })}
        </PastePartButton>
      )}
      <Droppable
        id={`${part.id}-bottom`}
        customData={{ itemType: 'partBottom', workout, part, partIdx }}
        disabled={isAnyWktDragging || isAnyPartDragging}
      >
        <SplitDropdownAddExercise
          workout={workout}
          orgId={orgId}
          partIdx={partIdx}
          addExRef={addExRef}
          isEditingWkt={isEditing}
          benchmarkDialogInfo={benchmarkDialogInfo}
          setBenchmarkDialogInfo={setBenchmarkDialogInfo}
        />
      </Droppable>
    </PartContainer>
  )
})

const ExDragOverIndicator = ({ dragOverEx, partId, exIdx, isLastExercise, workoutId }) => {
  const sameWkt = dragOverEx?.wktId === workoutId
  const samePart = dragOverEx?.partId === partId
  const sameEx = dragOverEx?.exIdx === exIdx

  const showAboveOverExIndicator = sameWkt && sameEx && samePart
  const showAboveOverPartIndicator = samePart && dragOverEx?.insertTop === true && exIdx === 0
  const showBelowOverPartIndicator = samePart && dragOverEx?.insertBottom === true && isLastExercise

  if (showAboveOverExIndicator || showAboveOverPartIndicator) {
    return <div className='absolute -top-1 bg-tGreen bg-opacity-50 h-[3px] inset-x-3' />
  }

  if (showBelowOverPartIndicator) {
    return <div className='absolute -bottom-1 bg-tGreen bg-opacity-50 h-[3px] inset-x-3' />
  }

  return null
}

const PartSelectedOverlay = styled.div(({ isPartSelected, isEditing }) => [
  tw`hidden`,
  isPartSelected && !isEditing && tw`block absolute inset-0 bg-tGreen bg-opacity-[0.15] z-10 pointer-events-none`,
])
