import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showAllCards: false,
  autoCenterScreen: true,
  shouldRelayout: false,
  quizErrors: {},
  idsOfResultsToDelete: {},
  titleOfContentToDelete: '',
  copiedQuestion: null,
  selectedNodeId: null,
  lastSelectedNodeId: null,
}

const quizFlowSlice = createSlice({
  name: 'quizFlow',
  initialState,
  reducers: {
    updateShowAllCards: (state, action) => {
      state.showAllCards = Boolean(action?.payload)
    },
    updateAutoCenterScreen: (state, action) => {
      state.autoCenterScreen = Boolean(action?.payload)
    },
    updateShouldRelayout: (state, action) => {
      state.shouldRelayout = Boolean(action.payload)
    },
    updateQuizErrors: (state, action) => {
      state.quizErrors = action.payload
    },
    updateQuizError: (state, action) => {
      const { nodeId, errors } = action.payload
      if (state.quizErrors[nodeId]) {
        state.quizErrors[nodeId] = errors
      }
    },
    setIdsOfResultsToDelete: (state, action) => {
      const { itemIds } = action.payload
      state.idsOfResultsToDelete = itemIds
    },
    setTitleOfContentToDelete: (state, action) => {
      state.titleOfContentToDelete = action.payload
    },
    setCopiedQuestion: (state, action) => {
      state.copiedQuestion = action.payload
    },
    setSelectedNodeId: (state, action) => {
      state.selectedNodeId = action.payload
    },
    setLastSelectedNodeId: (state, action) => {
      state.lastSelectedNodeId = action.payload
    },
  },
})

export const {
  updateShowAllCards,
  updateAutoCenterScreen,
  updateShouldRelayout,
  updateQuizErrors,
  updateQuizError,
  setIdsOfResultsToDelete,
  setTitleOfContentToDelete,
  setCopiedQuestion,
  setSelectedNodeId,
  setLastSelectedNodeId,
} = quizFlowSlice.actions
export default quizFlowSlice.reducer
