import React, { useRef } from 'react'
import { createPortal } from 'react-dom'
import { times } from 'lodash'
import { useParams } from 'react-router-dom'
import { useProgramDnd } from '../hooks/useProgramDnd'
import { DndContext, DragOverlay } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { Week } from './Week'
import { ActionPanel } from './ActionPanel'
import { PartDragOverlay } from './PartDragOverlay'
import { useSelector } from 'react-redux'
import {
  getPartsSelected,
  getWorkoutsSelected,
  getPartsCopied,
  getWorkoutsCopied,
  getWeeksCopied,
} from '../programSlice'
import { WorkoutDragOverlay } from './WorkoutDragOverlay'
import { dropAnimation } from 'common/utils/dndUtils'
import { appRouterContainerId } from 'modules/App/constants/cssSelectorConstants'
import { weekCardContainerId } from '../constants/cssSelectorConstants'
import { ExerciseDragOverlay } from './exercise/ExerciseDragOverlay'

export function Weeks(props) {
  const {
    hasWorkouts,
    wktsByWeek,
    editIdx,
    setEditIdx,
    numWksToShow,
    wktIdsToDayIdcs,
    wktIdsSorted,
    orgId,
    setModalWorkout,
    setEditWorkoutInfoModalOpen,
  } = props

  const { id: programId } = useParams()
  const weeksRef = useRef()

  const partsSelected = useSelector(getPartsSelected)
  const workoutsSelected = useSelector(getWorkoutsSelected)

  const partsCopied = useSelector(getPartsCopied)
  const workoutsCopied = useSelector(getWorkoutsCopied)
  const weeksCopied = useSelector(getWeeksCopied)

  const { wktDragging, partDragging, exDragging, sensors, handleDragStart, handleDragEnd, handleDragCancel } =
    useProgramDnd({
      wktIdsToDayIdcs,
      wktIdsSorted,
      setEditIdx,
      programId,
      orgId,
    })

  if (!hasWorkouts) {
    return null
  }

  const weekCards = []
  times(numWksToShow, (weekIdx) => {
    weekCards.push(
      <div id={weekCardContainerId + weekIdx} key={'progWk' + weekIdx} className='mt-5'>
        <Week
          weekIdx={weekIdx}
          weekWorkouts={wktsByWeek[weekIdx]}
          editIdx={editIdx}
          setEditIdx={setEditIdx}
          orgId={orgId}
          setModalWorkout={setModalWorkout}
          setEditWorkoutInfoModalOpen={setEditWorkoutInfoModalOpen}
        />
      </div>
    )
  })

  const showActionPanel =
    partsCopied.length > 0 ||
    workoutsCopied.length > 0 ||
    partsSelected.length > 0 ||
    workoutsSelected.length > 0 ||
    weeksCopied.length > 0

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={wktIdsSorted}>
        <div className='flex flex-col' ref={weeksRef}>
          {weekCards}
        </div>
      </SortableContext>
      {createPortal(
        <DragOverlay zIndex={70} dropAnimation={dropAnimation} className='cursor-move'>
          {wktDragging && <WorkoutDragOverlay workoutIds={[wktDragging.id]} />}
          {partDragging && <PartDragOverlay partDragging={partDragging} />}
          {exDragging && <ExerciseDragOverlay exerciseDragging={exDragging} />}
        </DragOverlay>,
        document.body
      )}
      {showActionPanel &&
        createPortal(
          <ActionPanel
            workoutsSelected={workoutsSelected}
            workoutsCopied={workoutsCopied}
            partsSelected={partsSelected}
            partsCopied={partsCopied}
            weeksCopied={weeksCopied}
          />,
          process.env.NODE_ENV !== 'test' ? document.getElementById(appRouterContainerId) : document.body
        )}
    </DndContext>
  )
}
