import tw from 'twin.macro'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'common/components/Button/Button'
import useAuth from 'modules/Auth/AuthContext'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginSchema } from '../schema'
import { Input } from 'common/components/Input/Input'
import AuthErrorBar from './AuthErrorBar'

//Vectors for background
import mountain from 'assets/images/mountain.svg'
import line1 from 'assets/images/line-vector-1.svg'
import line2 from 'assets/images/line-vector-2.svg'
// import line3 from 'assets/images/line-vector-3.svg'
import line4 from 'assets/images/line-vector-4.svg'

const InputError = tw.p`flex text-xs mt-1 text-tRed`

function Login(props) {
  const { login, authError } = useAuth()

  const defaultValues = {
    email: '',
    password: '',
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(loginSchema),
  })

  const {
    watch,
    setFocus,
    register,
    formState: { errors },
    handleSubmit,
  } = methods

  const formState = watch()

  const onSubmit = async (data) => {
    const { email, password } = data

    if (email && password) {
      login(email, password)
    }
  }

  useEffect(() => {
    setFocus('email')
  }, [setFocus])

  return (
    <FormProvider {...methods}>
      <div className='relative flex flex-col w-screen h-screen items-center bg-gradient-to-r from-tPurple to-tGreen overflow-hidden'>
        {/* <Link to='/' className='flex flex-row items-center my-6 z-10'>
          <img src={logo} height='36px' width='36px' />
          <div className='text-4xl font-bold text-tGreen ml-2'>TRYBE</div>
        </Link> */}
        <div className='flex flex-col bg-white rounded-md px-4 md:px-8 py-6 shadow max-w-[460px] mt-4 md:mt-24 items-center z-10 mx-4'>
          <div className='flex flex-row items-center justify-center py-2'>
            <h3 className='flex font-bold text-2xl items-center justify-center'>Log in</h3>
          </div>
          <AuthErrorBar authError={authError} />
          <form
            onSubmit={handleSubmit(onSubmit)}
            id='loginForm'
            className='w-full'
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault() //Otherwise form autosubmits on each enter press
              }
            }}
          >
            <div className='w-full my-3'>
              <div className='text-base font-bold mb-1 text-tBlack'>Email</div>
              <Input
                name='email'
                className='my-2'
                placeholder='Email'
                type='text'
                register={register}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    setFocus('password')
                  }
                }}
              />
              {errors?.email ? <InputError>{errors.email.message}</InputError> : null}
            </div>
            <div className='w-full my-3'>
              <div className='text-base font-bold mb-1 text-tBlack'>Password</div>
              <Input
                name='password'
                register={register}
                className='my-2'
                placeholder='Password'
                type='password'
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    handleSubmit(onSubmit)()
                  }
                }}
              />
              {errors?.password ? <InputError>{errors.password.message}</InputError> : null}
            </div>
            <div className='flex flex-row'>
              <a href='https://trybe.do/forgotpassword' className='text-tGreen text-sm'>
                Forgot password?
              </a>
            </div>
          </form>
          <Button
            className='mt-6 w-full'
            type='submit'
            onClick={handleSubmit(onSubmit)}
            disabled={!formState?.email || !formState?.password}
          >
            Log In
          </Button>

          <div className='flex flex-row text-sm mt-2'>
            <div>Not signed up?</div>
            <Link to='/signup' className='text-tGreen font-semibold ml-1'>
              Sign up
            </Link>
          </div>

          <p className='italic text-sm mt-5'>
            Note: you may need to disable any popup blockers if you are having login issues.
          </p>
        </div>
        <div className='text-white text-xs absolute bottom-0 mb-4 z-10'>
          &#169;2022 Trybe, all rights reserved. Made in SF, LA and Chicago
        </div>
        <img className='absolute top-[10%] right-[15%]' src={mountain} alt='' />
        <img className='absolute top-[20%] right-[30%]' src={mountain} alt='' />
        <img className='absolute top-[30%] right-[45%]' src={mountain} alt='' />
        <img className='absolute top-[40%] right-[60%]' src={mountain} alt='' />
        <img className='absolute left-0 top-0' src={line1} alt='' />
        <img className='absolute right-0 bottom-0' src={line2} alt='' />
        <img className='absolute left-0 bottom-[20%]' src={line4} alt='' />
        <img className='absolute right-0 top-0' src={line4} alt='' />
      </div>
    </FormProvider>
  )
}

export default Login
