import { isEmpty } from 'lodash'
import { createSelector } from '@reduxjs/toolkit'

export const getIsAnyMutationPending = createSelector(
  (state) => state.globalApi.mutations,
  (mutations) => {
    if (isEmpty(mutations)) {
      return false
    }

    const ignoreEndpoints = {
      setDraftProgramUpdatedAt: true,
    }

    const mutationValues = Object.values(mutations)

    let isAnyMutationPending,
      lastFulfilled = 0

    mutationValues.forEach((mutation) => {
      if (isAnyMutationPending) return

      const { status, fulfilledTimeStamp, endpointName } = mutation

      //ignore endpoints used for saving updatedAt
      if (status === 'pending' && !ignoreEndpoints[endpointName]) {
        isAnyMutationPending = true
      }

      if (fulfilledTimeStamp > lastFulfilled) {
        lastFulfilled = fulfilledTimeStamp
      }
    })

    return {
      isAnyMutationPending,
      lastFulfilled,
    }
  }
)
