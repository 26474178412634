export const getSuggestedProgressions = ({ availableProgressions, inputValue }) => {
  if (!availableProgressions) {
    return []
  }

  const filteredProgressions = Object.entries(availableProgressions).filter(([progressionKey]) =>
    progressionKey.toLowerCase().includes(inputValue.toLowerCase())
  )

  return Object.fromEntries(filteredProgressions)
}
