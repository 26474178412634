import 'twin.macro'
import React, { useState, useRef } from 'react'
import { CgMoreAlt, CgPen, CgTrash } from 'react-icons/cg'
import { format } from 'date-fns'

import { useRemoveProgramGroupMutation } from '../programGroupApi'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'

import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'

import { PreviewImgTableCell } from './PreviewImgTableCell'
import { TableCell } from './styles'

export function TableRow({ coachOrgId, programGroupKey, programGroup, setModalPg, setEditDialogOpen }) {
  const [removeProgramGroup] = useRemoveProgramGroupMutation()
  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: programGroupKey })

  const rowActionContainerRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const { createAlert } = useAlert()

  const handleDelete = async () => {
    setLoading(true)
    await removeProgramGroup({ coachOrgId, programGroupKey, programGroup })
    setLoading(false)
    createAlert({ text: 'Series deleted!', type: 'success' })
  }

  const handleRowClick = (e) => {
    // Check that event did not happen in modal
    const occuredOutsideModal = e.currentTarget.contains(e.target)

    // Check that event did not happen in action row (edit/delete/more)
    const occuredOutsideActionRow = !rowActionContainerRef.current.contains(e.target)
    if (occuredOutsideModal && occuredOutsideActionRow) {
      handleOpenEditDialog()
    }
  }

  const handleOpenEditDialog = () => {
    setModalPg({
      programGroup,
      programGroupKey,
      handleDelete,
    })
    setEditDialogOpen(true)
  }

  const isUploading = isUploadingAssets(assetDrafts)

  const programsText = programGroup.programs
    ? `${programGroup.programs.length} ${programGroup.programs.length === 1 ? 'program' : 'programs'}`
    : 'No programs'

  return (
    <tr className='cursor-pointer group h-24 hover:bg-gray-50' onClick={handleRowClick} aria-label='table-row'>
      <PreviewImgTableCell
        isUploading={isUploading}
        previewImg={programGroup?.previewImg}
        altText={programGroup.name}
      />
      <TableCell tw='w-7/12'>
        <div className='w-96 flex flex-col'>
          <div className='font-bold text-tBlack capitalize truncate' data-testid='table-series-title'>
            {programGroup.name}
          </div>
          <div className='text-sm text-tGray-dark truncate'>
            {programGroup.description ? programGroup.description : 'No description'}
          </div>
          <div className='flex items-center text-xs text-gray-500'>
            <span>{programGroup.subtitle ? programGroup.subtitle : 'No subtitle'}</span>
            <span className='text-lg mx-1'>&#8226;</span>
            <span>{programsText}</span>
          </div>
        </div>
      </TableCell>
      <TableCell tw='w-2/12'>
        <div className='text-xs text-gray-500'>
          {programGroup.createdAt ? format(programGroup.createdAt, 'PP') : 'No date'}
        </div>
      </TableCell>
      <TableCell tw='w-2/12'>
        <div className='text-xs text-gray-500'>
          {programGroup.updatedAt ? format(programGroup.updatedAt, 'PP') : 'No date'}
        </div>
      </TableCell>
      <td className='relative'>
        <div
          ref={rowActionContainerRef}
          className='absolute top-1/2 -translate-y-1/2 right-3 inline-flex items-center justify-end bg-white group-hover:border group-hover:border-tBlack group-hover:border-opacity-20 rounded-lg group-hover:shadow-sm'
        >
          <button
            aria-label='Edit Row'
            className='hidden group-hover:inline-block hover:text-tGreen py-1 px-2 border-r border-tBlack border-opacity-20 transition-all'
            onClick={handleOpenEditDialog}
          >
            <CgPen className='w-5 h-5' />
          </button>
          <Dialog>
            <DialogTrigger
              disabled={isUploading}
              className='hidden group-hover:inline-block hover:text-tRed py-1 px-2 border-r border-tBlack border-opacity-20 transition-all disabled:cursor-not-allowed'
              aria-label='Delete Row'
            >
              <CgTrash className='w-5 h-5' />
            </DialogTrigger>
            <DialogContent>
              <DeleteDialogBanner
                text={`This will delete ${programGroup.name}`}
                loading={loading}
                handleDelete={handleDelete}
                typeToConfirm={true}
              />
            </DialogContent>
          </Dialog>
          <button className='inline-block py-1 px-2' aria-label='More'>
            <CgMoreAlt className='w-5 h-5' />
          </button>
        </div>
      </td>
    </tr>
  )
}
