import tw from 'twin.macro'
import React from 'react'
import { CgChevronDown } from 'react-icons/cg'
import * as Accordion from '@radix-ui/react-accordion'
import { Textarea } from 'common/components/Textarea/Textarea'

export const TextareaInputsAccordion = ({
  accordionValue,
  setAccordionValue,
  register,
  descriptionRef,
  frequencyRef,
  prerequisitesRef,
  errors,
  moveFocusedInputBy,
}) => {
  return (
    <Accordion.Root
      type='single'
      collapsible='true'
      value={accordionValue}
      onValueChange={setAccordionValue}
      className='border-b border-gray-200'
    >
      <Accordion.Item value='textAreaInputs'>
        <Accordion.Header>
          <Accordion.Trigger
            type='button'
            className='flex items-center w-full group px-10 py-4 min-h-[63px]'
            tabIndex={-1}
          >
            <label className='inline-flex items-center cursor-pointer font-semibold text-tBlack group-radix-state-open:hidden'>
              Description, Frequency, & Prerequisites <span className='ml-2 text-xs text-gray-500'>(Optional)</span>
            </label>
            <CgChevronDown className='w-6 h-6 group-radix-state-open:-rotate-180 transition-all ml-auto' />
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <div className='flex flex-col px-10 pb-4 border-b border-gray-200'>
            <div className='mb-2'>
              <label htmlFor='description' className='inline-flex cursor-pointer font-semibold text-tBlack mb-1'>
                Description
              </label>
              <Textarea
                name='description'
                placeholder='Enter program description'
                register={register}
                inputRef={descriptionRef}
                error={errors?.description?.message}
                onKeyDown={(e) => {
                  if (e.code === 'Tab' && e.shiftKey) {
                    e.preventDefault()
                    e.stopPropagation()
                    moveFocusedInputBy(-1)
                  }
                }}
              />
              {errors.description && <p className='flex text-xs mt-1 text-tRed'>{errors.description.message}</p>}
            </div>
          </div>
          <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
            <div className='mb-2'>
              <label htmlFor='frequency' className='inline-flex cursor-pointer font-semibold text-tBlack mb-1'>
                Frequency
              </label>
              <Textarea
                name='frequency'
                placeholder='Enter program frequency'
                register={register}
                inputRef={frequencyRef}
                error={errors?.frequency?.message}
                css={tw`h-20`}
              />
              {errors.frequency && <p className='flex text-xs mt-1 text-tRed'>{errors.frequency.message}</p>}
            </div>
          </div>
          <div className='flex flex-col px-10 py-4'>
            <div className='mb-2'>
              <label htmlFor='prerequisites' className='inline-flex cursor-pointer font-semibold text-tBlack mb-1'>
                Prerequisites
              </label>
              <Textarea
                name='prerequisites'
                placeholder='Enter program prerequisites'
                register={register}
                inputRef={prerequisitesRef}
                error={errors?.prerequisites?.message}
                onKeyDown={(e) => {
                  if (e.code === 'Tab' && !e.shiftKey) {
                    e.preventDefault()
                    e.stopPropagation()
                    setAccordionValue('tagInputs')
                    const prereqInputIdx = 4
                    setTimeout(() => {
                      moveFocusedInputBy(1, prereqInputIdx)
                    }, 50)
                  }
                }}
                css={tw`h-20`}
              />
              {errors.prerequisites && <p className='flex text-xs mt-1 text-tRed'>{errors.prerequisites.message}</p>}
            </div>
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}
