import React from 'react'

import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'
import { useListenProgramWorkoutsQuery } from 'modules/Layout/layoutApi'

import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'

import { ItemCard } from './ItemCard'
import { ItemLoadingCard } from './ItemLoadingCard'

export const WorkoutItem = React.memo(({ coachOrgId, item, isDragging, attributes, listeners }) => {
  const { data: workouts } = useListenProgramWorkoutsQuery({ programId: item.programId })
  const workoutsLoading = workouts === undefined || workouts?.isLoading
  const { data: videoData } = useListenCoachVideosQuery({ coachOrgId })
  const videosLoading = videoData === undefined || videoData?.isLoading
  const videos = videoData || {}

  if (workoutsLoading || videosLoading) {
    return <ItemLoadingCard />
  }

  const workout = workouts?.[item.id]
  if (!workout) {
    return null
  }

  if (workout.type !== 'single_video') {
    return (
      <ItemCard
        id={item.id}
        name={workout.title}
        description={workout.subtitle}
        type={availableItemTypes.WORKOUT}
        previewImg={workout.previewImg}
        isDragging={isDragging}
        attributes={attributes}
        listeners={listeners}
      />
    )
  }

  const videoImage = videos?.[workout.videoId]?.previewImg
  const wktImage = workout.previewImg
  return (
    <ItemCard
      id={item.id}
      name={workout.title}
      description={workout.subtitle}
      type={availableItemTypes.WORKOUT}
      previewImg={wktImage || videoImage}
      isDragging={isDragging}
      attributes={attributes}
      listeners={listeners}
    />
  )
})
