import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CgCheckO } from 'react-icons/cg'
import { FiAlertTriangle } from 'react-icons/fi'
import { IoIosCloseCircle } from 'react-icons/io'

import { useListenDraftResultsQuery, useListenPublishedResultsQuery } from '../appOnboardingQuizApi'

import { capitalizeFirstLetterOfWords } from 'common/utils/stringUtils'
import { Spinner } from 'common/components/Spinner/Spinner'

import { getQuizResultExists } from '../utils/flowDataUtils'
import { setIdsOfResultsToDelete, setTitleOfContentToDelete } from '../quizFlowSlice'

function DeleteResultsChecklist({ idsOfResultsToDelete, coachOrgId }) {
  const dispatch = useDispatch()

  const titleOfContentToDelete = useSelector((state) => state.quizFlow.titleOfContentToDelete)
  const [checklistDone, setChecklistDone] = useState(false)

  const { data: draftQuizResults } = useListenDraftResultsQuery({ coachOrgId })
  const { data: publishedQuizResults } = useListenPublishedResultsQuery({ coachOrgId })
  const isQuizResultsLoading =
    publishedQuizResults === undefined ||
    publishedQuizResults?.isLoading ||
    draftQuizResults === undefined ||
    draftQuizResults?.isLoading
  const draftResultsExist =
    isQuizResultsLoading ||
    getQuizResultExists({
      itemIds: idsOfResultsToDelete,
      quizResults: draftQuizResults,
    })
  const publishedResultsExist =
    isQuizResultsLoading ||
    getQuizResultExists({
      itemIds: idsOfResultsToDelete,
      quizResults: publishedQuizResults,
    })

  useEffect(() => {
    if (!isQuizResultsLoading && !draftResultsExist && !publishedResultsExist) {
      setChecklistDone(true)
      setTimeout(() => {
        dispatch(setIdsOfResultsToDelete({ itemIds: {} }))
        dispatch(setTitleOfContentToDelete(''))
      }, 3000)
    }
    // eslint-disable-next-line
  }, [isQuizResultsLoading, draftResultsExist, publishedResultsExist])

  if (checklistDone) {
    return (
      <div className='relative group bg-white border-gray-300 border shadow-md rounded-md p-3 animate-drawAttention w-[304px]'>
        <h4 className='font-medium mb-3'>Steps completed!</h4>
        <div className='flex items-center'>
          <div className='flex items-center w-[26px]'>
            <ChecklistIcon checked={true} />
          </div>
          <div className='text-black ml-2 text-sm'>
            You can now delete {capitalizeFirstLetterOfWords(titleOfContentToDelete)}
          </div>
        </div>
        <button
          className='group-hover:visible hover:visible invisible absolute -top-2.5 -right-2.5 text-gray-500 hover:text-gray-600 bg-white transition-colors z-20'
          onClick={() => {
            dispatch(setIdsOfResultsToDelete({ itemIds: {} }))
            dispatch(setTitleOfContentToDelete(''))
          }}
          tabIndex={-1}
        >
          <IoIosCloseCircle className='cursor-pointer w-5 h-5' />
        </button>
      </div>
    )
  }

  return (
    <div className='relative group bg-white border-gray-300 border shadow-md rounded-md p-3 animate-drawAttention w-[304px]'>
      <h4 className='font-medium mb-3'>To delete {titleOfContentToDelete}</h4>
      <div className='flex items-center'>
        <div className='flex items-center w-[26px]'>
          <ChecklistIcon isLoading={isQuizResultsLoading} checked={!draftResultsExist} />
        </div>
        <div className='text-black ml-2 text-sm'>Delete all cards with a red outline</div>
      </div>
      {publishedResultsExist && (
        <div className='flex items-center mt-2'>
          <div className='flex items-center w-[26px]'>
            <ChecklistIcon isLoading={isQuizResultsLoading} checked={!draftResultsExist && !publishedResultsExist} />
          </div>
          <div className='text-black ml-2 text-sm'>Publish quiz</div>
        </div>
      )}
      <button
        className='group-hover:visible hover:visible invisible absolute -top-2.5 -right-2.5 text-gray-500 hover:text-gray-600 transition-colors z-20'
        onClick={() => {
          dispatch(setIdsOfResultsToDelete({ itemIds: {} }))
          dispatch(setTitleOfContentToDelete(''))
        }}
        tabIndex={-1}
      >
        <IoIosCloseCircle className='cursor-pointer w-5 h-5' />
      </button>
    </div>
  )
}

export default DeleteResultsChecklist

function ChecklistIcon({ isLoading, checked }) {
  if (isLoading) {
    return <Spinner className='mr-0 w-5 h-5 text-gray-100' />
  }

  if (checked) {
    return <CgCheckO className='w-5 h-5 text-tGreen' />
  }

  return <FiAlertTriangle className='w-5 h-5 text-orange-300 ml-[1px]' />
}
