import { NEW_REST_PART } from '../programModels'

export const updateLegacyRestPart = (legacyRestPart) => {
  let newRestPart = { ...NEW_REST_PART }
  newRestPart.id = legacyRestPart.id
  newRestPart.exercises = legacyRestPart.exercises
  newRestPart.time = legacyRestPart.exercises[0].time

  return newRestPart
}
