export function Progress({ percentComplete }) {
  return (
    <div className='absolute z-30 top-0 inset-x-0 overflow-hidden'>
      <div className='relative w-full bg-tGreen h-1 bg-opacity-30'>
        <div
          style={{ width: `${percentComplete}%` }}
          className='absolute top-0 bg-tGreen h-1 transition-width duration-500'
        />
      </div>
    </div>
  )
}
