export const EMPTY_TIME_STRING = '00:00:00.00'

export const getPrettyExerciseTime = (time) => {
  if (!time) return ''

  const minVal = time.slice(3, 5)
  const minPrepped = maybeRemoveFrontZero(minVal)
  const secVal = time.slice(6, 8)

  return `${minPrepped}:${secVal}`
}

const maybeRemoveFrontZero = (input) => {
  //For relevant units, remove zeroes
  if (input.charAt(0) === '0') input = input.slice(1)
  return input
}
