import tw from 'twin.macro'
import React from 'react'

export function TableHead({ columns }) {
  return (
    <thead className='bg-gray-50'>
      <tr>
        {columns.map((col) => {
          const hideOnMobile = col === 'Exercises'
          return (
            <th
              key={col}
              scope='col'
              className='px-4 md:px-6 py-3 text-left font-bold text-gray-500 capitalize'
              css={[hideOnMobile && tw`hidden md:table-cell`]}
            >
              {col}
            </th>
          )
        })}
        <th scope='col' className='relative px-4 md:px-6 py-3'>
          <span className='sr-only'>Edit</span>
        </th>
      </tr>
    </thead>
  )
}
