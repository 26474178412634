import { each, isEmpty } from 'lodash'
import { getStandardizedName } from 'common/utils/stringUtils'
import { getOrdinalSuffix } from 'common/utils/numberUtils'
import { getLandOrPortExVid } from 'common/utils/exerciseUtils'

/**
 * Data example for items that are missing videos
 {
   "tester-n3wzuryyc54y3dilprca": {                         Unique readable item id
       "name": "Tester",
       "foundLocations": {                                  All workout ids where item with missing video is found
           "-N82MUC402NCQQE1J7wK": {                        Workout id where item is found
               "id": "-N82MUC402NCQQE1J7wK",
               "wktWeekDayText": "Week 1, Day 4",
               "parts": [
                   0                                        List of non duplicate part indexes where exercise with missing video is found within workout
               ],
               "partIdxToExIdxs": {                         List of parts and exercise indexes where exercises with missing video that has a potential exercise with video match
                   [partIdx]: [exIdx],
                },
           }
       }
   },
   ...other items by unique ids
*/

export const getMissingVidsAndExercises = ({ allWorkouts, exData, benchmarkData, exVidOrientation }) => {
  const { exIdHasVideo = {} } = exData
  let missingVidExercises = {}
  let missingVidWkts = {}

  each(allWorkouts, (wkt) => {
    if ((wkt.type === 'single_video' && wkt.videoId) || wkt.type === 'rest') {
      return
    }

    if (wkt.type === 'single_video' && !wkt.videoId) {
      const wktWeekDayText = `Week ${Math.floor(wkt.dayIdx / 7) + 1}, Day ${(wkt.dayIdx % 7) + 1}`
      const untitledVideoKey = 'untitled video'
      const videoTitle = wkt.videoTitle ? getStandardizedName(wkt.videoTitle) : getStandardizedName(untitledVideoKey)
      const alreadyInMissingVideos = missingVidWkts[videoTitle]

      if (!alreadyInMissingVideos) {
        missingVidWkts = {
          ...missingVidWkts,
          [videoTitle]: {
            name: videoTitle,
            foundLocations: {
              [wkt.id]: {
                id: wkt.id,
                wktWeekDayText,
              },
            },
          },
        }
      }

      if (alreadyInMissingVideos) {
        alreadyInMissingVideos.foundLocations = {
          ...alreadyInMissingVideos.foundLocations,
          [wkt.id]: { id: wkt.id, wktWeekDayText: wktWeekDayText },
        }
      }
    }

    if (wkt.parts && wkt.parts.length > 0 && wkt.type !== 'single_video') {
      each(wkt.parts, (part, partIdx) => {
        if (part.type !== 'rest') {
          each(part.exercises, (ex, exIdx) => {
            const isBenchmark = ex?.type === 'benchmark'
            const exName = isBenchmark ? `${getStandardizedName(ex.name)} (Benchmark)` : getStandardizedName(ex.name)
            // Do not include exercises named 'rest' in missing video list
            if (exName === 'rest') {
              return
            }

            const exHasNoVideo = isBenchmark
              ? !getLandOrPortExVid(exVidOrientation, benchmarkData?.[ex?.id])
              : !ex.id || !exIdHasVideo[ex.id]
            const wktWeekDayText = `Week ${Math.floor(wkt.dayIdx / 7) + 1}, Day ${(wkt.dayIdx % 7) + 1}`
            const exKey = ex.id ? ex.id : exName
            const partName = part.name ? part.name : partIdx
            const alreadyInMissingVideos = missingVidExercises[exKey]
            const alreadyHasWktLocation = alreadyInMissingVideos?.foundLocations[wkt.id]
            const alreadyHasPart = alreadyHasWktLocation?.parts.find(
              (p) => getStandardizedName(p) === getStandardizedName(partName)
            )

            if (exHasNoVideo && !alreadyInMissingVideos) {
              missingVidExercises = {
                ...missingVidExercises,
                [exKey]: {
                  name: exName,
                  foundLocations: {
                    [wkt.id]: {
                      id: wkt.id,
                      wktWeekDayText,
                      parts: [partName],
                      partIdxToExIdxs: {
                        [partIdx]: [exIdx],
                      },
                    },
                  },
                },
              }
            }

            if (exHasNoVideo && alreadyInMissingVideos && alreadyHasWktLocation) {
              if (alreadyHasPart === undefined) {
                alreadyHasWktLocation.parts.push(partName)
                alreadyHasWktLocation.partIdxToExIdxs[partIdx] = [exIdx]
              }

              const partIdxToExIdxs = alreadyHasWktLocation.partIdxToExIdxs[partIdx]
              if (alreadyHasPart !== undefined && partIdxToExIdxs) {
                partIdxToExIdxs.push(exIdx)
              }

              const hasDuplicatePartName = alreadyHasWktLocation.parts.find(
                (p, idx) => getStandardizedName(p) === getStandardizedName(partName) && idx !== partIdx
              )
              if (!partIdxToExIdxs && hasDuplicatePartName) {
                const duplicatePartSuffix = ` (${getOrdinalSuffix(partIdx + 1)} block)`
                alreadyHasWktLocation.parts.push(`${partName}${duplicatePartSuffix}`)
                alreadyHasWktLocation.partIdxToExIdxs[partIdx] = [exIdx]
              }
            }

            if (exHasNoVideo && alreadyInMissingVideos && !alreadyHasWktLocation) {
              alreadyInMissingVideos.foundLocations = {
                ...alreadyInMissingVideos.foundLocations,
                [wkt.id]: {
                  id: wkt.id,
                  wktWeekDayText: wktWeekDayText,
                  parts: [partName],
                  partIdxToExIdxs: {
                    [partIdx]: [exIdx],
                  },
                },
              }
            }
          })
        }
      })
    }
  })

  return isEmpty(missingVidExercises) && isEmpty(missingVidWkts)
    ? null
    : { exercises: missingVidExercises, workoutVids: missingVidWkts }
}

const getExercisesWithVidsByName = ({ exercises, exIdHasVideo }) => {
  const exercisesWithVidsByName = {}

  each(exIdHasVideo, (hasVid, exId) => {
    if (hasVid) {
      const exercise = exercises[exId]
      const exNameStd = getStandardizedName(exercise?.name)

      if (!exercisesWithVidsByName[exNameStd]) {
        exercisesWithVidsByName[exNameStd] = [{ ...exercise, id: exId }]
      } else {
        exercisesWithVidsByName[exNameStd].push({ ...exercise, id: exId })
      }
    }
  })

  return exercisesWithVidsByName
}

export const getMatchesForMissingVidsAndEx = ({ missingVids, exData, videoData }) => {
  const { exercises = {}, exIdHasVideo = {} } = exData
  const videos = videoData
  const exercisesWithVidsByName = getExercisesWithVidsByName({ exercises, exIdHasVideo })

  const missingVidExercises = missingVids?.exercises ? missingVids.exercises : {}
  const missingVidWkts = missingVids?.workoutVids ? missingVids.workoutVids : {}

  const foundExMatches = {}

  if (!isEmpty(missingVidExercises)) {
    each(missingVidExercises, (exMissingVid) => {
      const missingExNameStd = getStandardizedName(exMissingVid.name)
      const exercisesWithVids = exercisesWithVidsByName[missingExNameStd]

      if (exercisesWithVids) {
        foundExMatches[missingExNameStd] = exercisesWithVids
      }
    })
  }

  const foundWktVidMatches = {}

  if (!isEmpty(missingVidWkts)) {
    each(videos, (video, videoKey) => {
      const missingVidWktKeys = Object.keys(missingVidWkts).map((key) => getStandardizedName(key))
      const matchingMissingVidIdx = missingVidWktKeys.indexOf(getStandardizedName(video.title))
      const matchExists = matchingMissingVidIdx !== -1

      if (matchExists && video.video) {
        const itemMissingVidKey = missingVidWktKeys[matchingMissingVidIdx]
        if (foundWktVidMatches[itemMissingVidKey]) {
          foundWktVidMatches[itemMissingVidKey].push({ ...video, id: videoKey })
        } else {
          foundWktVidMatches[itemMissingVidKey] = [{ ...video, id: videoKey }]
        }
      }
    })
  }

  return { foundExMatches, foundWktVidMatches }
}

export const handleUpdateMatchedMissingIds = async ({
  exercisesToMatch,
  vidsToMatch,
  missingVids,
  updateWorkouts,
  orgId,
  createAlert,
  showAlert = true,
}) => {
  const wktsUpdate = {}
  const missingVidExercises = missingVids?.exercises ? missingVids.exercises : {}
  const missingVidWkts = missingVids?.workoutVids ? missingVids.workoutVids : {}

  if (!isEmpty(exercisesToMatch)) {
    each(missingVidExercises, (exMissingVid) => {
      const exMissingName = getStandardizedName(exMissingVid.name)
      if (exercisesToMatch[exMissingName]) {
        each(exMissingVid.foundLocations, (foundLoc, wktId) => {
          each(foundLoc.partIdxToExIdxs, (exIdxs, partIdx) => {
            each(exIdxs, (exIdx) => {
              const updatePath = `${wktId}/parts/${partIdx}/exercises/${exIdx}/id`
              wktsUpdate[updatePath] = exercisesToMatch[exMissingName]
            })
          })
        })
      }
    })
  }

  if (!isEmpty(vidsToMatch)) {
    each(missingVidWkts, (video, videoKey) => {
      if (vidsToMatch[videoKey]) {
        each(video.foundLocations, (_, wktId) => {
          const updatePath = `${wktId}/videoId`
          wktsUpdate[updatePath] = vidsToMatch[videoKey]
        })
      }
    })
  }

  if (!isEmpty(wktsUpdate)) {
    await updateWorkouts({ orgId, workouts: wktsUpdate })

    if (showAlert) {
      createAlert({ text: 'Items matched!', type: 'success' })
    }
  }
}

export const getOneMatchItems = (missingExOrVids, foundMatches) => {
  const exOrVidsWithOneMatch = {}

  if (missingExOrVids) {
    each(missingExOrVids, (itemMissingVid) => {
      const matchingItems = foundMatches[getStandardizedName(itemMissingVid.name)]
      const hasOneMatchingItem = matchingItems?.length === 1

      if (hasOneMatchingItem) {
        exOrVidsWithOneMatch[getStandardizedName(itemMissingVid.name)] = matchingItems[0].id
      }
    })
  }

  return exOrVidsWithOneMatch
}
