import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userId: 0,
  email: 'example@gmail.com',
  first: 'John',
  last: 'Doe',
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFirst(state, action) {
      state.first = action.payload.first
    },
  },
})

export const { setFirst } = userSlice.actions
export default userSlice.reducer
