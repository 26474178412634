export const handlePublishProgram = async ({
  publishDraftProgram,
  orgId,
  programId,
  wktIdsToDayIdcs,
  name,
  createAlert,
  updateProgram,
}) => {
  await publishDraftProgram({
    orgId,
    programId,
    wktIdsToDayIdcs,
    name,
  })

  await updateProgram({ coachOrgId: orgId, programId, program: { publishedAt: Date.now() } })
  createAlert({ text: 'Program published!', type: 'success' })
}
