import { each, isEmpty } from 'lodash'
import { updateFirebase } from 'modules/App/globalApi'
import { getUpdatedContent } from 'modules/ContentTags/contentTagUtils'

/**
 * @param {{updateType: ('add'|'remove')}}
 */
export const updateManyWorkoutContentTags = async ({ orgId, workouts, programId, updateType }) => {
  let errors = {
    instrErr: null,
    difficultyErr: null,
    equipmentErr: null,
    tagsErr: null,
  }

  let allWktInstructorsUpdate = {}
  let allWktDifficultyUpdate = {}
  let allWktEquipmentUpdate = {}
  let allWktTagsUpdate = {}

  each(workouts, (wkt) => {
    if (wkt.type === 'rest') {
      return
    }

    if (wkt?.instructors?.length > 0) {
      const instructorsUpdate = getUpdatedContent({
        prevItems: updateType === 'add' ? [] : wkt?.instructors,
        currItems: updateType === 'remove' ? [] : wkt?.instructors,
        contentId: wkt.id,
        programId,
        contentType: 'workout',
      })
      allWktInstructorsUpdate = { ...allWktInstructorsUpdate, ...instructorsUpdate }
    }

    if (wkt?.difficulty?.length > 0) {
      const difficultyUpdate = getUpdatedContent({
        prevItems: updateType === 'add' ? [] : wkt?.difficulty,
        currItems: updateType === 'remove' ? [] : wkt?.difficulty,
        contentId: wkt.id,
        programId,
        contentType: 'workout',
      })
      allWktDifficultyUpdate = { ...allWktDifficultyUpdate, ...difficultyUpdate }
    }

    if (wkt?.equipment?.length > 0) {
      const equipmentUpdate = getUpdatedContent({
        prevItems: updateType === 'add' ? [] : wkt?.equipment,
        currItems: updateType === 'remove' ? [] : wkt?.equipment,
        contentId: wkt.id,
        programId,
        contentType: 'workout',
      })
      allWktEquipmentUpdate = { ...allWktEquipmentUpdate, ...equipmentUpdate }
    }

    if (wkt?.tags?.length > 0) {
      const tagsUpdate = getUpdatedContent({
        prevItems: updateType === 'add' ? [] : wkt?.tags,
        currItems: updateType === 'remove' ? [] : wkt?.tags,
        contentId: wkt.id,
        programId,
        contentType: 'workout',
      })
      allWktTagsUpdate = { ...allWktTagsUpdate, ...tagsUpdate }
    }
  })

  const instructorsPath = `coachOrgs/${orgId}/contentTags/instructors`
  if (!isEmpty(allWktInstructorsUpdate)) {
    const { error: instrErr } = await updateFirebase(instructorsPath, allWktInstructorsUpdate)
    errors.instrErr = instrErr
  }

  const difficultyPath = `coachOrgs/${orgId}/contentTags/difficulty`
  if (!isEmpty(allWktDifficultyUpdate)) {
    const { error: difficultyErr } = await updateFirebase(difficultyPath, allWktDifficultyUpdate)
    errors.difficultyErr = difficultyErr
  }

  const equipmentPath = `coachOrgs/${orgId}/contentTags/equipment`
  if (!isEmpty(allWktEquipmentUpdate)) {
    const { error: equipmentErr } = await updateFirebase(equipmentPath, allWktEquipmentUpdate)
    errors.equipmentErr = equipmentErr
  }

  const tagsPath = `coachOrgs/${orgId}/contentTags/tags`
  if (!isEmpty(allWktTagsUpdate)) {
    const { error: tagsErr } = await updateFirebase(tagsPath, allWktTagsUpdate)
    errors.tagsErr = tagsErr
  }

  return errors
}
