import React from 'react'
import tw, { styled } from 'twin.macro'

export function Textarea({
  name,
  placeholder,
  register,
  inputRef,
  onChange,
  value,
  className,
  error,
  onKeyUp,
  onFocus,
  onKeyDown,
  ...otherProps
}) {
  if (register) {
    const { ref, ...restInputRegister } = register(name)

    return (
      <StyledTextarea
        {...otherProps}
        ref={(e) => {
          ref(e)
          if (inputRef) inputRef.current = e
        }}
        {...restInputRegister}
        id={name}
        placeholder={placeholder}
        className={className}
        error={error}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      />
    )
  }

  return (
    <StyledTextarea
      {...otherProps}
      id={name}
      name={name}
      placeholder={placeholder}
      className={className}
      onChange={onChange}
      error={error}
    >
      {value}
    </StyledTextarea>
  )
}

export const textareaBase = tw`flex resize-none h-32 w-full rounded-xl bg-white ring-1 border-0 ring-gray-300 text-tBlack placeholder:text-tBlack placeholder:text-opacity-50 focus:border-0 focus:ring-tGreen px-4 py-4`

const StyledTextarea = styled.textarea(({ error }) => [textareaBase, error && tw`ring-tRed focus:ring-tRed`])
