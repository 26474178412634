import { difference, each, isEmpty, size } from 'lodash'
import { removeInvalidFirebaseChars } from 'common/utils/fileUploading/nameAndIdUtils'
import { updateFirebase } from 'modules/App/globalApi'

export const getTagsList = (tags) => {
  if (!tags || size(tags) === 0) return []

  let tagsList = []
  each(tags, (_, tag) => {
    tagsList.push(tag)
  })

  return tagsList
}

export const getUpdatedContent = ({ prevItems, currItems, contentId, contentType, programId }) => {
  const removedItems = difference(prevItems, currItems)
  const addedItems = difference(currItems, prevItems)

  let itemsUpdate = {}
  each(addedItems, (item) => {
    const validItem = removeInvalidFirebaseChars(item)
    if (contentType === 'workout' && programId) {
      itemsUpdate[`${validItem}/${contentId}`] = { type: contentType, programId }
    } else {
      itemsUpdate[`${validItem}/${contentId}`] = { type: contentType }
    }
  })
  each(removedItems, (item) => {
    itemsUpdate[`${item}/${contentId}`] = null
  })

  return itemsUpdate
}

/**
 * @param {{updateType: ('add'|'remove')}}
 */
export const updateItemContentTags = async ({ orgId, item, contentId, contentType, programId, updateType }) => {
  let errors = {
    difficultyErr: null,
    equipmentErr: null,
    instrErr: null,
    tagsErr: null,
  }

  const difficultyPath = `coachOrgs/${orgId}/contentTags/difficulty`
  const difficultyUpdate = getUpdatedContent({
    prevItems: updateType === 'add' ? [] : item?.difficulty,
    currItems: updateType === 'remove' ? [] : item?.difficulty,
    contentId,
    programId,
    contentType,
  })
  if (!isEmpty(difficultyUpdate)) {
    const { error: difficultyErr } = await updateFirebase(difficultyPath, difficultyUpdate)
    errors.difficultyErr = difficultyErr
  }

  const equipmentPath = `coachOrgs/${orgId}/contentTags/equipment`
  const equipmentUpdate = getUpdatedContent({
    prevItems: updateType === 'add' ? [] : item?.equipment,
    currItems: updateType === 'remove' ? [] : item?.equipment,
    contentId,
    programId,
    contentType,
  })
  if (!isEmpty(equipmentUpdate)) {
    const { error: equipmentErr } = await updateFirebase(equipmentPath, equipmentUpdate)
    errors.equipmentErr = equipmentErr
  }

  const instructorsPath = `coachOrgs/${orgId}/contentTags/instructors`
  const instructorsUpdate = getUpdatedContent({
    prevItems: updateType === 'add' ? [] : item?.instructors,
    currItems: updateType === 'remove' ? [] : item?.instructors,
    contentId,
    programId,
    contentType,
  })
  if (!isEmpty(instructorsUpdate)) {
    const { error: instrErr } = await updateFirebase(instructorsPath, instructorsUpdate)
    errors.instrErr = instrErr
  }

  return errors
}
