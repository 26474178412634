import React from 'react'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenHomepageCardsQuery } from './homepageApi'

import Loading from 'modules/App/components/Loading'
import GreetingsHeader from './components/GreetingsHeader'
import HomepageCard from './components/HomepageCard'

function Home() {
  const { userId } = useAuth()

  const { data: homepageCardsData } = useListenHomepageCardsQuery(userId)
  const homepageCardsLoading = homepageCardsData === undefined || homepageCardsData?.isLoading
  const homepageCards = homepageCardsLoading || homepageCardsData === null ? {} : homepageCardsData

  return (
    <div className='flex flex-col items-center w-screen min-h-screen bg-offWhite px-16'>
      {homepageCardsLoading ? (
        <Loading />
      ) : (
        <div className='max-w-5xl w-full mt-12'>
          <GreetingsHeader userId={userId} />
          <h3 className='text-2xl font-bold mb-4'>Things to do</h3>
          {homepageCards.map((card) => (
            <HomepageCard key={card.id} card={card} userId={userId} />
          ))}
        </div>
      )}
    </div>
  )
}

export default Home
