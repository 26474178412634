import tw from 'twin.macro'
import React, { memo } from 'react'
import { Handle, Position } from '@xyflow/react'
import { IoIosCloseCircle } from 'react-icons/io'

import { useUpdateQuestionsMutation, useUpdateResultsMutation } from '../appOnboardingQuizApi'

import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'

import WorkoutResult from './WorkoutResult'
import VideoResult from './VideoResult'
import ProgramResult from './ProgramResult'

const ResultNode = memo(({ id, data, selected }) => {
  const [updateQuestions] = useUpdateQuestionsMutation()
  const [updateResults] = useUpdateResultsMutation()

  const handleRemove = () => {
    updateResults({
      coachOrgId: data?.coachOrgId,
      update: {
        [`${data?.result?.id}/${data?.parentQuestionId}/${id}`]: null,
      },
    })
    updateQuestions({
      coachOrgId: data?.coachOrgId,
      update: {
        [`${data?.parentQuestionId}/answers/${id}`]: {
          index: data?.index,
          title: data?.title || null,
          description: data?.description || null,
          result: null,
          resultId: null,
          nextQuestionId: id,
        },
        [id]: {
          id,
          previousQuestionId: data?.parentQuestionId,
        },
      },
    })
  }

  return (
    <div
      className='relative group flex items-center w-96 rounded-md border border-gray-300 p-2'
      css={[selected && tw`border-tGreen`, data?.markedForDelete && tw`border-red-500 border-2`]}
    >
      <Handle type='target' position={Position.Left} isConnectable={false} />
      <Result coachOrgId={data?.coachOrgId} item={data.result} selected={selected} />
      <Dialog>
        <DialogTrigger
          onOpenCb={(e) => e.stopPropagation()}
          className='group-hover:visible hover:visible invisible absolute -top-2.5 -right-2.5 text-gray-500 hover:text-gray-600 bg-white transition-colors z-20 nodrag nopan'
        >
          <IoIosCloseCircle className='cursor-pointer w-5 h-5' />
        </DialogTrigger>
        <DialogContent dialogCloseCb={(e) => e.stopPropagation()}>
          <DeleteDialogBanner
            text='This will remove the result.'
            handleDelete={() => handleRemove()}
            deleteText='Remove'
          />
        </DialogContent>
      </Dialog>
    </div>
  )
})

export default ResultNode

function Result({ coachOrgId, item, selected }) {
  if (item.type === availableItemTypes.VIDEO) {
    return <VideoResult coachOrgId={coachOrgId} item={item} selected={selected} />
  }

  if (item.type === availableItemTypes.WORKOUT) {
    return <WorkoutResult coachOrgId={coachOrgId} item={item} selected={selected} />
  }

  if (item.type === availableItemTypes.PROGRAM) {
    return <ProgramResult item={item} selected={selected} />
  }

  return null
}
