import 'twin.macro'
import React from 'react'
import { CgChevronRight } from 'react-icons/cg'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenProgramGroupsQuery } from 'modules/Layout/layoutApi'
import { useListenUserProfileQuery } from 'modules/Users/userApi'

import { Button } from 'common/components/Button/Button'
import { selectButtonActiveStyles, selectButtonBaseStyles } from '../styles'
import { getItemExists, includesSearchQuery } from '../utils'
import { availableItemTypes } from '../constants/constants'

export function ProgramGroupSelection({ existingItems, currentItem, handleSelection, searchQuery, handleNext }) {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''
  const { data: programGroups } = useListenProgramGroupsQuery({ coachOrgId })
  const programGroupsLoading = programGroups === undefined || programGroups?.isLoading

  if (programGroupsLoading) {
    return <div className='text-sm'>Loading...</div>
  }

  const availableProgramGroups = programGroups
    ? Object.values(programGroups).filter((programGroup) => includesSearchQuery(programGroup.name, searchQuery))
    : []

  if (!availableProgramGroups?.length) {
    return <div className='text-sm'>No available Series.</div>
  }

  return availableProgramGroups.map((programGroup) => (
    <Button
      key={programGroup.id}
      type='button'
      onClick={() => handleSelection({ id: programGroup.id })}
      variant='secondary'
      size='md'
      css={[selectButtonBaseStyles, currentItem.id === programGroup.id && selectButtonActiveStyles]}
    >
      <div className='flex-1 flex items-center'>
        <span>{programGroup.name}</span>
        {getItemExists({
          existingItems,
          itemId: programGroup.id,
          itemType: availableItemTypes.PROGRAMGROUP,
        }) && (
          <span className='flex whitespace-nowrap py-0 px-2 ml-2 text-xxs bg-gray-500 text-white rounded-md'>
            in use
          </span>
        )}
      </div>
      <div
        role='button'
        onClick={(e) => {
          e.stopPropagation()
          handleNext({ type: availableItemTypes.PROGRAMGROUP, id: programGroup.id })
        }}
        aria-label={`go-to-${availableItemTypes.PROGRAM}-from-${programGroup.id}`}
      >
        <CgChevronRight className='w-[18px] h-[18px] hover:text-tGreen ml-2' />
      </div>
    </Button>
  ))
}
