import tw from 'twin.macro'
import React from 'react'
import { isEmpty } from 'lodash'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenCollectionsQuery } from 'modules/Collections/collectionsApi'

import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { LayoutCard } from './LayoutCard'

export const CollectionCard = React.memo(({ item, handleDelete }) => {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: collectionsData } = useListenCollectionsQuery({ coachOrgId })

  if (collectionsData === undefined || collectionsData?.isLoading) {
    return <Skeleton css={tw`w-60 h-44 rounded-xl`} />
  }

  if (isEmpty(collectionsData)) {
    return null
  }

  const collection = collectionsData[item.id]
  if (collection) {
    return (
      <LayoutCard
        id={item.id}
        type={item.type}
        name={collection.name}
        image={collection.previewImg}
        subtitle={collection.subtitle}
        handleDelete={() => handleDelete(item)}
      />
    )
  }

  return (
    <LayoutCard
      id={item.id}
      type={item.type}
      name='This collection has been deleted'
      handleDelete={() => handleDelete(item)}
    />
  )
})
