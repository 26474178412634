import { globalApi, listenFirebase, getFirebase } from 'modules/App/globalApi'

export const userApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenUserMetadata: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ userId }, api) => {
        listenFirebase(`users/${userId}/metadata`, api)
      },
    }),
    listenUserProfile: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ userId }, api) => {
        listenFirebase(`users/${userId}/profile`, api)
      },
    }),
    listenUserCoachOrgId: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ userId }, api) => {
        listenFirebase(`users/${userId}/profile/coachOrgId`, api)
      },
    }),
    getIsUserAdmin: build.query({
      queryFn: async ({ userId }) => {
        const { data, error } = await getFirebase(`internal/admins/${userId}`)
        return { data, error }
      },
    }),
  }),
})

export const {
  useListenUserMetadataQuery,
  useListenUserProfileQuery,
  useListenUserCoachOrgIdQuery,
  useGetIsUserAdminQuery,
} = userApi
