export const initialFilterState = {
  difficulty: null,
  equipment: null,
  instructors: null,
  tags: null,
}

export const initialActiveFilterState = {
  difficulty: [],
  equipment: [],
  instructors: [],
  tags: [],
}
