import { each, isEmpty } from 'lodash'
import { getFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'

/**
 * Possibly deprecate after moving to UIDs. No longer require unique names, so generating unique new row names is unneeded
 * and adds logic overhead. Leaving until confirmed no longer needed.
 */
// export const getMaxNewRowIndex = (visibleLayouts) => {
//   let maxIndex = 0

//   visibleLayouts.forEach((layoutItem) => {
//     const hasNewRowName = layoutItem.name.toLowerCase().includes('new row')
//     const layoutItemNameIndex = hasNewRowName ? layoutItem.name.replace(/\D/g, '') : 0
//     maxIndex = Math.max(maxIndex, layoutItemNameIndex)
//   })

//   return maxIndex
// }

const getRowsWithOneOfItems = (rows, itemIds) => {
  const rowsWithItem = {}

  each(rows, (row, rowIdx) => {
    if (!row.items) return

    each(row.items, (rowItem) => {
      const containsItemId = itemIds.some((itemId) => rowItem.id === itemId)
      if (containsItemId) {
        rowsWithItem[rowIdx] = row
      }
    })
  })

  return rowsWithItem
}

export const updateTabRowsOnDelete = async ({ coachOrgId, itemIds }) => {
  const allTabContentPath = `coachOrgs/${coachOrgId}/explore/tabContent`
  const { data: allTabContent } = await getFirebase(allTabContentPath)

  let allTabContentUpdate = {}
  each(allTabContent, (rows, tabId) => {
    const rowsAffected = getRowsWithOneOfItems(rows, itemIds)
    const updatedRows = getUpdatedTabRows(tabId, rowsAffected, itemIds)
    allTabContentUpdate = { ...allTabContentUpdate, ...updatedRows }
  })

  if (!isEmpty(allTabContentUpdate)) {
    await updateFirebase(allTabContentPath, allTabContentUpdate)
  }
}

const getUpdatedTabRows = (tabId, rowsAffected, itemIds) => {
  const rowsUpdates = {}

  each(rowsAffected, (row, rowIdx) => {
    const rowWithoutRemovedItems = row.items.filter((item) => !itemIds.some((itemId) => itemId === item.id))
    rowsUpdates[`${tabId}/${rowIdx}/items`] = rowWithoutRemovedItems
  })

  return rowsUpdates
}

export const moveRowToDiffTab = async ({ coachOrgId, moveToTabId, moveFromTabId, tab, row, createAlert }) => {
  const { data: moveToTabContent } = await getFirebase(`coachOrgs/${coachOrgId}/explore/tabContent/${moveToTabId}`)
  const updatedMoveToTabContent = moveToTabContent ? [...moveToTabContent, row] : [row]

  const { data: moveFromTabContent } = await getFirebase(`coachOrgs/${coachOrgId}/explore/tabContent/${moveFromTabId}`)
  const updatedMoveFromTabContent = moveFromTabContent.filter((tabContent) => tabContent.id !== row.id)

  await setFirebase(`coachOrgs/${coachOrgId}/explore/tabContent/${moveToTabId}`, updatedMoveToTabContent)
  await setFirebase(`coachOrgs/${coachOrgId}/explore/tabContent/${moveFromTabId}`, updatedMoveFromTabContent)
  createAlert({ text: `Row moved to ${tab.name} tab!`, type: 'success' })
}
