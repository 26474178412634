import { globalApi, listenFirebase, updateFirebase, removeFirebase } from 'modules/App/globalApi'

export const uploadApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenExistingItemDrafts: build.query({
      //For point-edits of existing exercises, programs, or programGroups
      queryFn: () => ({ data: { isFiller: true } }),
      onCacheEntryAdded: async ({ coachOrgId, id }, api) => {
        listenFirebase(`drafts/${coachOrgId}/existingItems/${id}`, api)
      },
    }),
    listenAllExistingItemDrafts: build.query({
      //For point-edits of existing exercises, programs, or programGroups
      queryFn: () => ({ data: { isFiller: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/existingItems`, api)
      },
    }),
    updateExistingItemDrafts: build.mutation({
      queryFn: async ({ coachOrgId, id, drafts }) => {
        const { data, error } = await updateFirebase(`drafts/${coachOrgId}/existingItems/${id}`, drafts)
        return { data, error }
      },
    }),
    removeExistingItemDrafts: build.mutation({
      queryFn: async ({ coachOrgId, id }) => {
        const { data, error } = await removeFirebase(`drafts/${coachOrgId}/existingItems/${id}`)
        return { data, error }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenExistingItemDraftsQuery,
  useListenAllExistingItemDraftsQuery,
  useUpdateExistingItemDraftsMutation,
  useRemoveExistingItemDraftsMutation,
} = uploadApi
