import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import tw from 'twin.macro'
import { useParams, useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty, isEqual, pick } from 'lodash'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useUpdateProgramMutation, useCreateProgramMutation, useRemoveProgramMutation } from '../programApi'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'
import {
  useUpdateDifficultyContentMutation,
  useUpdateInstructorsContentMutation,
  useUpdateEquipmentContentMutation,
  useUpdateTagsContentMutation,
} from 'modules/ContentTags/contentTagsApi'
import useQuizResultsExists from 'modules/AppOnboardingQuiz/hooks/useQuizResultsExists'

import { schema } from 'modules/Programs/ProgramForm/schema'
import { NEW_USER_QUIZ } from 'modules/App/components/Header'
import { setIdsOfResultsToDelete } from 'modules/AppOnboardingQuiz/quizFlowSlice'
import { sortBy } from 'lodash'

import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { DeleteConfirmationBanner } from 'common/components/DeleteConfirmationBanner/DeleteConfirmationBanner'
import { createUID } from 'common/utils/createUID'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'
import { removeFileExtension } from 'common/utils/fileUploading/nameAndIdUtils'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { DisplayForm } from './DisplayForm'
import { SettingsForm } from './SettingsForm'
import { ResourcesForm } from './ResourcesForm/ResourcesForm'
import { getInvalidResources } from './ResourcesForm/utils'

export function ProgramForm({ programId: initProgramId, program }) {
  const dispatch = useDispatch()
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const [createProgram, { isLoading: isCreatingProgram }] = useCreateProgramMutation()
  const [updateProgram, { isLoading: isUpdatingProgram }] = useUpdateProgramMutation()
  const [removeProgram, { isLoading: isRemovingProgram }] = useRemoveProgramMutation()
  const [updateInstructors, { isLoading: isUpdatingInstructors }] = useUpdateInstructorsContentMutation()
  const [updateDifficulty, { isLoading: isUpdatingDifficulty }] = useUpdateDifficultyContentMutation()
  const [updateEquipment, { isLoading: isUpdatingEquipment }] = useUpdateEquipmentContentMutation()
  const [updateTags, { isLoading: isUpdatingTags }] = useUpdateTagsContentMutation()

  const isUpdating =
    isCreatingProgram ||
    isUpdatingProgram ||
    isRemovingProgram ||
    isUpdatingInstructors ||
    isUpdatingDifficulty ||
    isUpdatingEquipment ||
    isUpdatingTags

  const [, setDialogOpen] = useDialog()
  const { createAlert } = useAlert()

  const [selectedTab, setSelectedTab] = useState('display')

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [programId, setProgramId] = useState('')

  const [isCreated, setIsCreated] = useState(false) //set if program is created by uploading image

  const { id: urlProgramId } = useParams()
  const navigate = useNavigate()

  const isNewProgram = !initProgramId

  useEffect(() => {
    if (!isNewProgram) {
      setProgramId(initProgramId)
    } else {
      //need to set the state in order to maintain the newly created ID
      const newId = createUID()
      setProgramId(newId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: programId })

  let programAndWorkoutIds = { [programId]: true }
  Object.keys(program?.workouts || {}).forEach((wktId) => (programAndWorkoutIds[wktId] = true))
  const { quizResultExists, isQuizResultsLoading } = useQuizResultsExists({ coachOrgId, itemIds: programAndWorkoutIds })

  // keep initial previewImg / introVideo stable between renders
  // so that submit button is reset from disabled to active after new asset is uploaded
  const initialPreviewImgRef = useRef(program?.previewImg || '')
  const initialIntroVideoRef = useRef(program?.introVideo || '')

  const cleanedProgramResources = getCleanedProgramResources(program?.programResources)
  const cleanedProgramResourcesRef = useRef(cleanedProgramResources)

  const defaultValues = {
    name: program?.name || '',
    subtitle: program?.subtitle || '',
    previewImg: initialPreviewImgRef.current,
    introVideo: initialIntroVideoRef.current,
    reps: program?.reps ? String(program?.reps) : '1',
    description: program?.description || '',
    frequency: program?.frequency || '',
    prerequisites: program?.prerequisites || '',
    difficulty: program?.difficulty || [],
    equipment: program?.equipment || [],
    instructors: program?.instructors || [],
    tags: program?.tags || [],
    isPaid: program?.isPaid === false ? false : true,
    displayByDays: program?.displayByDays || false,
    programResources: cleanedProgramResourcesRef.current || {},
  }

  const methods = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(schema),
    context: { program },
  })

  const {
    watch,
    handleSubmit,
    formState: { errors: displayErrors },
  } = methods
  const [resourceErrors, setResourceErrors] = useState({})

  const formState = watch()

  const isFormSubmitDisabled = isEqual(defaultValues, formState)

  const onSubmit = async (data) => {
    const invalidResources = getInvalidResources(data.programResources)

    if (!isEmpty(invalidResources)) {
      setResourceErrors(invalidResources)
      return
    } else {
      setResourceErrors({})
    }

    const programData = pick(data, [
      'name',
      'subtitle',
      'reps',
      'description',
      'frequency',
      'prerequisites',
      'isPaid',
      'displayByDays',
      'previewImg',
      'difficulty',
      'equipment',
      'instructors',
      'tags',
      'programResources',
    ])

    programData.coachOrgId = coachOrgId

    try {
      await updateInstructors({
        coachOrgId,
        prevInstructors: defaultValues.instructors,
        currInstructors: programData.instructors,
        contentId: programId,
        contentType: 'program',
      })
      await updateDifficulty({
        coachOrgId,
        prevDifficulty: defaultValues.difficulty,
        currDifficulty: programData.difficulty,
        contentId: programId,
        contentType: 'program',
      })
      await updateEquipment({
        coachOrgId,
        prevEquipment: defaultValues.equipment,
        currEquipment: programData.equipment,
        contentId: programId,
        contentType: 'program',
      })
      await updateTags({
        coachOrgId,
        prevTags: defaultValues.tags,
        currTags: programData.tags,
        contentId: programId,
        contentType: 'program',
      })
      if (isNewProgram && !isCreated) {
        console.log('ProgramForm will createProgram')
        await createProgram({
          orgId: coachOrgId,
          programMeta: { ...programData, updatedAt: Date.now() },
          optionalProgId: programId,
        })
        createAlert({ text: 'Program is created!', type: 'success' })
      } else {
        console.log('ProgramForm will updateProgram')
        await updateProgram({ coachOrgId, programId, program: { ...programData, updatedAt: Date.now() } })
        createAlert({ text: 'Program is updated!', type: 'success' })
      }
      setDialogOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async () => {
    try {
      const isViewingProgram = urlProgramId ? true : false
      if (isViewingProgram) {
        navigate('/programs')
      }
      await removeProgram({ coachOrgId, programId })
      createAlert({ text: 'Program deleted!', type: 'success' })
      setDialogOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  const createProgramOnUpload = (event) => {
    if (isNewProgram) {
      const file = event.target.files[0]
      if (file && file.name) {
        const progName = formState.name || removeFileExtension(file.name)
        createProgram({ orgId: coachOrgId, programMeta: { ...formState, name: progName }, optionalProgId: programId })
        setIsCreated(true)
        const alertText = 'Creating program...'
        createAlert({ text: alertText, type: 'success' })
      }
    } else {
      const file = event.target.files[0]
      if (file && file.name) {
        const programData = pick(formState, [
          'name',
          'subtitle',
          'reps',
          'description',
          'frequency',
          'prerequisites',
          'isPaid',
          'displayByDays',
          'previewImg',
          'difficulty',
          'equipment',
          'instructors',
          'tags',
          'programResources',
        ])
        updateProgram({ coachOrgId, programId, program: { ...programData, updatedAt: Date.now() } })
        createAlert({ text: 'Program is updated!', type: 'success' })
      }
    }
  }

  const submitRef = useRef()
  return (
    <>
      <div className='flex items-center'>
        <button
          tabIndex={-1}
          onClick={() => setSelectedTab('display')}
          css={[
            tw`flex-1 py-3 border-b-2 border-b-gray-200 font-semibold text-gray-500 hover:text-black transition-all`,
            selectedTab === 'display' && tw`border-b-tGreen text-black`,
          ]}
        >
          <span className='relative'>
            Display
            {!isEmpty(displayErrors) && <ErrorNotice>!</ErrorNotice>}
          </span>
        </button>
        <button
          tabIndex={-1}
          onClick={() => setSelectedTab('resources')}
          css={[
            tw`flex-1 py-3 border-b-2 border-b-gray-200 font-semibold text-gray-500 hover:text-black transition-all`,
            selectedTab === 'resources' && tw`border-b-tGreen text-black`,
          ]}
        >
          <span className='relative'>
            Resources
            {!isEmpty(resourceErrors) && <ErrorNotice>!</ErrorNotice>}
          </span>
        </button>
        <button
          tabIndex={-1}
          onClick={() => setSelectedTab('settings')}
          css={[
            tw`flex-1 py-3 border-b-2 border-b-gray-200 font-semibold text-gray-500 hover:text-black transition-all`,
            selectedTab === 'settings' && tw`border-b-tGreen text-black`,
          ]}
        >
          Settings
        </button>
      </div>
      <FormProvider {...methods}>
        <div className='divide-y divide-gray-200 max-h-[580px] overflow-auto'>
          <form onSubmit={handleSubmit(onSubmit)} id='programForm'>
            {selectedTab === 'display' && (
              <FormRefsControlProvider>
                <DisplayForm
                  programId={programId}
                  program={program}
                  isNewProgram={isNewProgram}
                  onSubmit={onSubmit}
                  createProgramOnUpload={createProgramOnUpload}
                  submitRef={submitRef}
                  resourceErrors={resourceErrors}
                />
              </FormRefsControlProvider>
            )}
            {selectedTab === 'resources' && (
              <FormRefsControlProvider>
                <ResourcesForm
                  onSubmit={onSubmit}
                  submitRef={submitRef}
                  resourceErrors={resourceErrors}
                  setResourceErrors={setResourceErrors}
                  programId={programId}
                  createProgramOnUpload={createProgramOnUpload}
                />
              </FormRefsControlProvider>
            )}
            {selectedTab === 'settings' && (
              <FormRefsControlProvider>
                <SettingsForm programId={programId} program={program} submitRef={submitRef} />
              </FormRefsControlProvider>
            )}
          </form>
        </div>
        {deleteConfirmation ? (
          <DeleteConfirmationBanner
            text={
              quizResultExists
                ? 'This program cannot be deleted because it or its workouts are used as a result in the new user quiz. To delete the program, please remove it and its workouts from the quiz first.'
                : 'Delete program?'
            }
            handleDelete={() => {
              if (quizResultExists) {
                dispatch(setIdsOfResultsToDelete({ itemIds: programAndWorkoutIds }))
                navigate(NEW_USER_QUIZ)
              } else {
                handleDelete()
              }
            }}
            handleGoBack={() => setDeleteConfirmation(false)}
            loading={isQuizResultsLoading}
            textCss={quizResultExists ? tw`text-xs` : null}
            buttonVariant={quizResultExists ? 'primary' : 'danger'}
            deleteText={quizResultExists ? 'Go to quiz' : 'Delete'}
          />
        ) : (
          <UpdateActions
            itemKey={programId}
            handleSubmit={handleSubmit}
            handleDelete={() => setDeleteConfirmation(true)}
            actionText={'Save'}
            disabled={isFormSubmitDisabled}
            loading={isUpdating}
            hideDelete={!program}
            deleteDisabled={isUploadingAssets(assetDrafts)}
            disabledReason='Please wait for media upload to complete'
            form='programForm'
            ref={submitRef}
          />
        )}
      </FormProvider>
    </>
  )
}

const getCleanedProgramResources = (programResources) => {
  const usedResources = programResources || {}
  const validResources = sortBy(Object.entries(usedResources), ([_, resource]) => resource.idx).filter(
    ([_, resource]) => {
      if (resource.type === 'video') {
        return resource.videoId && resource.id
      } else if (resource.type === 'link') {
        return resource.url && resource.title && resource.id
      } else {
        return false
      }
    }
  )

  const cleanedResources = {}
  validResources.forEach(([resourceId, resource], idx) => {
    cleanedResources[resourceId] = { ...resource, idx }
  })
  return cleanedResources
}

const ErrorNotice = tw.span`
  inline-flex rounded-full
  items-center justify-center
  font-medium leading-none
  text-sm text-white
  w-5 h-5 bg-red-500
  absolute -right-7
`
