import tw from 'twin.macro'
import React, { useState } from 'react'
import { CgSearch } from 'react-icons/cg'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenOrgProgramsListQuery } from '../programApi'
import {
  useListenDifficultyQuery,
  useListenEquipmentQuery,
  useListenInstructorsQuery,
  useListenTagsQuery,
} from 'modules/ContentTags/contentTagsApi'
import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { each } from 'lodash'
import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { InputGroup } from 'common/components/Input/InputGroup'
import { Input } from 'common/components/Input/Input'
import { ProgramForm } from '../ProgramForm/ProgramForm'
import { ProgramTable } from '../ProgramTable/ProgramTable'
import { useDebounce } from 'common/hooks/useDebounce'
import { Filter } from 'common/components/ContentTagFilter/Filter'
import { useFilter } from 'common/components/ContentTagFilter/hooks/useFilter'

function AllProgramsLibrary() {
  const [searchQuery, setSearchQuery] = useState('')

  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: programsData } = useListenOrgProgramsListQuery({ orgId: coachOrgId })
  const programsLoading = programsData === undefined || programsData?.stillLoading
  const allPrograms = programsData || {}

  const { data: videoData, isLoading } = useListenCoachVideosQuery({ coachOrgId })
  const videosLoading = isLoading || videoData?.isLoading

  const { data: difficultyData } = useListenDifficultyQuery({ coachOrgId })
  const difficulty = difficultyData || {}
  const { data: equipmentData } = useListenEquipmentQuery({ coachOrgId })
  const equipment = equipmentData || {}
  const { data: instructorsData } = useListenInstructorsQuery({ coachOrgId })
  const instructors = instructorsData || {}
  const { data: tagsData } = useListenTagsQuery({ coachOrgId })
  const tags = tagsData || {}

  const tagsLoading =
    difficulty === undefined ||
    difficulty?.isLoading ||
    equipment === undefined ||
    equipment?.isLoading ||
    instructors === undefined ||
    instructors?.isLoading ||
    tags === undefined ||
    tags?.isLoading

  const { filters, setFilters, result: filteredPrograms } = useFilter({ items: allPrograms })

  const debouncedQuery = useDebounce(searchQuery, 200)

  const programsToRender = getFilteredPrograms({
    searchQuery: debouncedQuery,
    programs: filteredPrograms,
  })

  return (
    <div className='flex flex-col min-h-screen bg-offWhite px-16'>
      <div className='w-full max-w-7xl mx-auto mt-12'>
        <div className='flex items-center justify-between'>
          <h1 className='font-bold text-5xl text-tBlack mr-6'>Programs</h1>
          <div className='flex-1 flex justify-center'>
            <InputGroup css={[tw`max-w-md ring-offWhite`, searchQuery.length && tw`ring-tGreen ring-2`]}>
              <CgSearch tw='w-5 h-5' />
              <Input
                type='text'
                placeholder='Search for a program'
                onChange={handleSearchChange}
                value={searchQuery}
                hideRing
              />
              {searchQuery.length && (
                <Button variant='secondary' size='md' onClick={() => setSearchQuery('')}>
                  Clear
                </Button>
              )}
            </InputGroup>
            <Filter
              filters={filters}
              setFilters={setFilters}
              difficulty={difficulty}
              equipment={equipment}
              instructors={instructors}
              tags={tags}
            />
          </div>
          <div className='flex justify-end ml-6'>
            <Dialog>
              <DialogTrigger css={[buttonBase, buttonVariants.primary, buttonSizes.lg]}>Create program</DialogTrigger>
              <DialogContent
                header='Create program'
                contentClassNames='!absolute !top-12'
                titleClassNames='!shadow-none'
              >
                <ProgramForm />
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div className='flex flex-col my-20'>
          <ProgramTable filteredPrograms={programsToRender} loading={programsLoading || tagsLoading || videosLoading} />
        </div>
      </div>
    </div>
  )

  function getFilteredPrograms({ searchQuery, programs = {} }) {
    const nameIncludesQuery = (name, query) => {
      if (!name || typeof name !== 'string') {
        return false
      } else if (name.toLowerCase().includes(query)) {
        return true
      } else {
        return false
      }
    }

    if (searchQuery.length > 0) {
      const toRender = {}

      each(programs, (program, programId) => {
        const nameMatches = nameIncludesQuery(program.name, debouncedQuery)
        if (nameMatches) {
          toRender[programId] = program
        }
      })

      return toRender
    } else {
      return programs
    }
  }

  function handleSearchChange(e) {
    setSearchQuery(e.target.value)
  }
}

export default AllProgramsLibrary
