import { createUID } from 'common/utils/createUID'
import { times } from 'lodash'

export const getNewProgram = (programMeta, programId) => {
  const { newWorkouts, idToDayIdx: programWkts } = getNewEmptyWeek(0)

  const newProgram = {
    ...programMeta,
    id: programId || createUID(), //can feed a specified programId. Used for AWS asset tracking
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
    workouts: programWkts,
  }

  return {
    newProgram,
    newWorkouts,
  }
}

export const getNewPart = () => {
  const newPart = { ...NEW_PART }
  newPart.id = createUID()
  newPart.exercises[0].dndUID = createUID()
  return newPart
}

export const getNewRestPart = () => {
  const newRestPart = { ...NEW_REST_PART }
  newRestPart.id = createUID()
  return newRestPart
}

export const getNewWorkout = (dayIdx, uid) => {
  if (!dayIdx && dayIdx !== 0) {
    console.error('dayIdx is required for every workout')
    return
  }

  const newWorkout = { ...WORKOUT_TEMPLATE }
  newWorkout.id = uid || createUID()
  newWorkout.parts[0] = getNewPart()
  newWorkout.dayIdx = dayIdx

  return newWorkout
}

export const getNewExercise = () => {
  const newExercise = { ...EXERCISE }
  newExercise.dndUID = createUID()
  return newExercise
}

export const getNewRestDay = (dayIdx, uid) => {
  if (!dayIdx && dayIdx !== 0) {
    console.error('dayIdx is required for every workout')
    return
  }

  const newRestDay = { ...REST_DAY }
  newRestDay.id = uid || createUID()
  newRestDay.dayIdx = dayIdx

  return newRestDay
}

export const getNewVideoDay = (dayIdx, uid) => {
  if (!dayIdx && dayIdx !== 0) {
    console.error('dayIdx is required for every workout')
    return
  }

  const newVideoDay = { ...VIDEO_DAY, parts: [PLACEHOLDER_VIDEO_DAY_PART] }
  newVideoDay.id = uid || createUID()
  newVideoDay.dayIdx = dayIdx

  return newVideoDay
}

export const getNewEmptyWkt = (dayIdx, workoutId) => {
  const id = workoutId || createUID()

  return {
    title: '',
    type: 'empty',
    id,
    dayIdx,
  }
}

export const getNewEmptyWeek = (weekIdx) => {
  const newWorkouts = {}
  const idToDayIdx = {}

  times(7, (index) => {
    const dayIdx = weekIdx * 7 + index
    const emptyWkt = getNewEmptyWkt(dayIdx)

    newWorkouts[emptyWkt.id] = emptyWkt
    idToDayIdx[emptyWkt.id] = dayIdx
  })

  return {
    newWorkouts,
    idToDayIdx,
  }
}

export const getFilledWeek = (weekIdx, numDaysFilled) => {
  const newWorkouts = {}
  const idToDayIdx = {}

  times(7, (index) => {
    if (index >= numDaysFilled) {
      const dayIdx = weekIdx * 7 + index
      const emptyWkt = getNewEmptyWkt(dayIdx)

      newWorkouts[emptyWkt.id] = emptyWkt
      idToDayIdx[emptyWkt.id] = dayIdx
    }
  })

  return {
    newWorkouts,
    idToDayIdx,
  }
}

export const changeSavedPartIds = (part) => {
  const partUID = createUID()
  const newPart = { ...part, id: partUID, savedAt: new Date().getTime() }
  return { partId: partUID, partData: newPart }
}

export const EMPTY_DAY = {
  title: '',
  type: 'empty',
  id: null,
  dayIdx: null,
}

const EXERCISE = {
  name: '',
  instructions: '',
  time: '',
  rest: '',
}

const NEW_PART = {
  exercises: [{ ...EXERCISE }],
  id: null, //to be set in getNewPart
  sets: 1,
  instructions: '1 set',
}

//for backwards compatibility; safe to remove workout.parts of a videoDay by 12/31/22
const PLACEHOLDER_VIDEO_DAY_PART = {
  id: createUID(),
  instructions: '1 set',
  sets: 1,
  name: 'Full Length Video',
  exercises: [
    {
      id: '',
      name: 'Full Length Video',
      instructions: 'Please upgrade to the latest version of the app to use this full-length video.',
    },
  ],
}

export const PLACEHOLDER_REST_PART_EXERCISE = {
  id: 'rest', //determines if exercise is rest
  name: 'rest',
  instructions: '',
  time: '00:01:00.00', //same as part-level time prop
}

export const NEW_REST_PART = {
  exercises: [{ ...PLACEHOLDER_REST_PART_EXERCISE }], //Need to have an exercise with "rest"
  sets: 1, //for backwards compatibility
  instructions: '1 set', //for backwards compatibility
  name: 'Rest',
  id: null, //to be set in getNewRestPart
  type: 'rest',
  time: '00:01:00.00',
}

const WORKOUT_TEMPLATE = {
  id: null, //to be set in getNewWorkout
  title: null,
  type: 'workout',
  timeEst: null,
  instructions: null,
  previewImg: null,
  parts: [], //1st part will be set in getNewWorkout
  dayIdx: null, //required; to be set in getNewWorkout
  isPaid: true,
}

const REST_DAY = {
  title: 'Rest day',
  type: 'rest',
  instructions: 'Rest day',
  id: null, //to be set in getNewRestDay
  dayIdx: null, //required; to be set in getNewRestDay
}

const VIDEO_DAY = {
  title: '',
  type: 'single_video',
  instructions: '',
  id: null, //to be set in getNewRestDay
  dayIdx: null, //required; to be set in getNewRestDay
}
