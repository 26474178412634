import React from 'react'

function VideoFilter({ setFilters, filters }) {
  const toggleVidFilter = (e) => {
    setFilters({
      ...filters,
      video: e.target.value,
    })
  }

  return (
    <div className='ml-6'>
      <div className='py-1'>
        <input
          onChange={toggleVidFilter}
          name='video'
          id='videoOff'
          type='radio'
          value='off'
          checked={filters.video === 'off'}
          className='cursor-pointer rounded-full border-tBlack border-opacity-20 text-tGreen focus:ring-0'
          aria-label='videoOff'
        />
        <label htmlFor='videoOff' className='cursor-pointer text-tBlack text-sm ml-2'>
          Missing
        </label>
      </div>
      <div>
        <input
          onChange={toggleVidFilter}
          name='video'
          id='videoOn'
          type='radio'
          value='on'
          checked={filters.video === 'on'}
          className='cursor-pointer rounded-full border-tBlack border-opacity-20 text-tGreen focus:ring-0'
          aria-label='videoOn'
        />
        <label htmlFor='videoOn' className='cursor-pointer text-tBlack text-sm ml-2'>
          Uploaded
        </label>
      </div>
    </div>
  )
}

export default VideoFilter
