/**
 * This is a generalized Input that accepts a number of properties used to:
 * 1) Preview the live image/video/audio file
 * 2) Upload a new asset to AWS S3
 * 3) Initiate a draft node within that exercise/program/programGroup to manage upload/compresssion status
 * 4) ... which triggers custom AWS Lambda function (this logic is based on filetype + the 'uploadType' variable which is passed as metadata)
 * 5) Show the new draft asset and await form submission
 *
 * Built assuming react-hook-form with a register property is passed in.
 * Only allows single file upload
 *
 * @param {string} name name passed to register in react-hook-form (e.g., video, previewImg, thumbnail)
 * @param {string} label (optional) label used to ovewrite name. E.g., if name is previewImg label can be 'Preview Image'
 * @param {function} register returned register func from useForm() of react-hook-form
 * @param {function} setValue returned setValue func from useForm() of react-hook-form. Programmatically sets the value of a registered input based on 'name'
 * @param {function} setError returned setValue func from useForm() of react-hook-form. Programmatically sets the errors of a registered input based on 'name'
 * @param {function} clearErrors returned setValue func from useForm() of react-hook-form. Clears the errors of a registered input based on 'name'
 * @param {string} coachOrgId
 * @param {string} id unique identifier that will be appended to the S3 asset upload. Used to create webhook path and start a draft node.
 * @param {string} uploadType unique string that tells AWS Lambda what logic path to use. REQUIRES AWS LAMBDA TO ACCOUNT FOR IT EXPLICITLY
 * @param {string} fileType "video", "audio", or "image". Used to lookup a number of parameters like default filesize limits or accepted file extensions
 * @param {string} liveUrl active url for the asset that would be visible in the mobile app
 * @param {bool} hidden (optional) if you want the JSX returned to be hidden. Used for thumbnails which is autogenerated
 */

import tw from 'twin.macro'
import React, { useState, useRef, useEffect } from 'react'
import { AWSupload } from 'common/utils/fileUploading/uploadUtils'
import {
  acceptedFileTypes,
  validateFile,
  errorCodes,
  formatBytes,
  defaultSizeLimits,
  VID_FILESIZE_LIMIT,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
} from 'common/utils/fileUploading/validators'
import { CgSoftwareUpload, CgPlayButtonO } from 'react-icons/cg'

import { useListenExistingItemDraftsQuery, useUpdateExistingItemDraftsMutation } from 'modules/Uploads/uploadApi'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import useAuth from 'modules/Auth/AuthContext'
import { useWriteAWSErrorMutation } from 'modules/ExerciseLibrary/exerciseLibraryApi'
import { useCustomization } from 'common/contexts/Customization/useCustomization'

const Label = tw.label`inline-flex font-semibold`
const IconButton = tw.button`flex items-center justify-center 
rounded-lg bg-white border border-tGray-light shadow-sm text-tGray-med
disabled:text-tGray-light enabled:hover:text-tGreen`
const iconClasses = 'w-6 h-6'

export function UploadInput(props) {
  const {
    name,
    register,
    setValue,
    setError,
    coachOrgId,
    id,
    uploadType,
    liveUrl,
    previewUrl,
    hidden,
    label,
    clearErrors,
    onUpload,
    showPreview = true,
    showUpload = true,
    previewIcon = <CgPlayButtonO />,
    uploadDisabled,
    uploadDisabledReason,
    fileType,
    customFileSizeLimit,
    orientation = 'landscape',
    programId, //optional. Used for programResources
    collectionId, //optional. Used for collectionLinks
    labelStyle,
    buttonStyle,
    statusStyle,
  } = props

  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id })
  const [updateAssetDrafts] = useUpdateExistingItemDraftsMutation()

  const [writeAWSError] = useWriteAWSErrorMutation()
  const { awsAuthError, awsAuthed, userId } = useAuth()

  const { enableLandscapeExAudio } = useCustomization()

  const hiddenInputRef = useRef()

  const [progress, setProgress] = useState(false)

  const draftUrl = assetDrafts?.[name]

  useEffect(() => {
    if (liveUrl) {
      setValue(name, liveUrl) //set the text input value for react-hook-form when URL is written by AWS webhook
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftUrl])

  useEffect(() => {
    //Temp code to track AWS auth errors
    if (awsAuthError) {
      writeAWSError({ userId, awsError: awsAuthError })
    }
  }, [awsAuthError, userId, writeAWSError])

  const defaultSizeLimit = fileType ? defaultSizeLimits[fileType] : VID_FILESIZE_LIMIT
  const usedSizeLimit = customFileSizeLimit || defaultSizeLimit

  const allowedFileTypes = acceptedFileTypes?.[fileType] ? acceptedFileTypes[fileType].join(',') : '.mp4'

  const setProgressCb = createProgressCb({ id, coachOrgId, name, updateAssetDrafts, setProgress })
  const assetStatus = getAssetStatus({ liveUrl, draftUrl, progress })

  const previewIconClasses = previewIcon.props?.className || ''
  const previewBtnDisabled = !liveUrl || assetStatus !== 'live'
  const uploadBtnDisabled = uploadDisabled || name === 'audio' //Temporarily disable for audio

  if (!name || !register || !setValue || !coachOrgId || !id) {
    return <div>There is an issue in input setup. Check that you are passing in all props</div>
  }

  if (!hidden && (awsAuthError || !awsAuthed)) {
    return <div>Unable to upload content. Error code: AWS1. Please contact support@trybe.do</div>
  }

  return (
    <div className={`${hidden ? 'hidden' : ''}`}>
      <div className={`flex flex-row w-full place-content-between items-center my-1`}>
        <Label htmlFor={name} className={`mb-1 text-tBlack ${labelStyle || ''}`}>
          {label || name}
        </Label>
        <div className='flex flex-row space-x-3 items-center'>
          <AssetStatus assetStatus={assetStatus} progress={progress} statusStyle={statusStyle} />
          {showPreview && (
            <Tooltip content={`View uploaded ${fileType}`} open={previewBtnDisabled ? false : undefined}>
              <IconButton
                data-testid='previewMediaBtn'
                type='button'
                disabled={previewBtnDisabled}
                onClick={() => {
                  previewAsset(previewUrl ? previewUrl : liveUrl)
                }}
                tabIndex={-1}
                className={`w-10 h-10 ${buttonStyle || ''}`}
              >
                {React.cloneElement(previewIcon, {
                  className: `${iconClasses} ${previewIconClasses}`,
                })}
              </IconButton>
            </Tooltip>
          )}
          {showUpload && (
            <Tooltip
              content={getUploadTooltipText({ uploadDisabled, uploadDisabledReason, previewBtnDisabled, fileType })}
              open={uploadBtnDisabled && !uploadDisabledReason ? false : undefined}
            >
              <IconButton
                data-testid='uploadMediaBtn'
                type='button'
                disabled={uploadBtnDisabled}
                onClick={() => {
                  hiddenInputRef.current.click()
                }}
                tabIndex={-1}
                className={`w-10 h-10 ${buttonStyle || ''}`}
              >
                <CgSoftwareUpload className={iconClasses} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <div className='hidden'>
        <input
          type='file'
          id={`${name}-file-input-hidden`}
          className='hidden'
          ref={hiddenInputRef}
          accept={allowedFileTypes}
          onChange={(e) => {
            uploadFiles({
              e,
              hiddenInputRef,
              name,
              coachOrgId,
              id,
              setProgressCb,
              updateAssetDrafts,
              setError,
              clearErrors,
              uploadType,
              onUpload,
              allowedFileTypes,
              fileType,
              usedSizeLimit,
              orientation,
              enableLandscapeExAudio,
              programId,
              collectionId,
            })
          }}
        />
        <input type='text' id={`${name}-text-url-hidden`} className='hidden' {...register(name)} />
      </div>
    </div>
  )
}

function getUploadTooltipText({ uploadDisabled, uploadDisabledReason, previewBtnDisabled, fileType }) {
  if (uploadDisabled && uploadDisabledReason) {
    return uploadDisabledReason
  }

  return `${previewBtnDisabled ? 'Upload' : 'Replace'} ${fileType}`
}

/**
 * Move to reducer in the future will allow for progress to be tracked even
 * after creator closes window
 */
function getAssetStatus({ liveUrl, draftUrl, progress }) {
  const isLive = liveUrl && !draftUrl
  const isMidUpload = draftUrl === true && progress && progress < 100
  const isStartingUpload = draftUrl === true && !progress
  const isWaitingForAWS = draftUrl === 'compressing' || (draftUrl === true && progress === 100)

  if (isLive) {
    return 'live'
  } else if (isMidUpload) {
    return 'uploading'
  } else if (isStartingUpload) {
    return 'starting'
  } else if (isWaitingForAWS) {
    return 'compressing'
  } else {
    return 'none'
  }
}

function AssetStatus({ assetStatus, progress, statusStyle }) {
  const style = `text-tGray-dark text-base ${statusStyle || ''}`
  if (assetStatus === 'live') {
    return null
  } else if (assetStatus === 'uploading') {
    return <p className={style}>{`${progress}%`}</p>
  } else if (assetStatus === 'starting') {
    return <p className={style}>{`Uploading...`}</p>
  } else if (assetStatus === 'compressing') {
    return <p className={style}>{`Compressing...`}</p>
  } else {
    return null
  }
}

function previewAsset(url) {
  if (url) {
    //add URL validation?
    window.open(url)
  }
}

async function uploadFiles({
  e,
  name,
  hiddenInputRef,
  coachOrgId,
  id,
  setProgressCb,
  setError,
  updateAssetDrafts,
  clearErrors,
  uploadType,
  onUpload,
  allowedFileTypes,
  fileType,
  usedSizeLimit,
  orientation,
  enableLandscapeExAudio,
  programId,
  collectionId,
}) {
  const files = hiddenInputRef.current?.files
  const fileForUpload = files[0]
  const videoWithThumbDraft = {
    video: true,
    thumbnail: true, //Uploading a video creates a thumbnail draft as well
  }
  const standardDraft = {
    [name]: true,
  }
  const drafts = name === 'video' ? videoWithThumbDraft : standardDraft

  if (clearErrors) {
    clearErrors(name)
  }

  const { errorCode, valid } = await validateFile({
    file: fileForUpload,
    fileType,
    customFileSizeLimit: usedSizeLimit,
    orientation,
  })

  if (valid) {
    if (onUpload) {
      //Typically used to generate a new exercise/video/program, etc. upon upload
      onUpload(e)
    }

    await updateAssetDrafts({ coachOrgId, id, drafts }) //Lock buttons/uploads if is updating in the draft stage

    const result = await AWSupload({
      file: fileForUpload,
      uniqueId: id,
      coachOrgId,
      uploadType,
      assetType: name,
      setProgressCb,
      orientation,
      enableLandscapeExAudio,
      programId,
      collectionId,
    })

    if (result) {
      onUploadComplete({ id, coachOrgId, name, updateAssetDrafts })
    }
  } else if (errorCode && setError) {
    const errorMessage = getErrorMessage(errorCode, allowedFileTypes, usedSizeLimit, orientation)
    setError(name, { type: errorCode, message: errorMessage })
  }
}

function getErrorMessage(errorCode, allowedFileTypes, usedSizeLimit, orientation) {
  if (!errorCode) return ''
  switch (errorCode) {
    case errorCodes.UPLOAD_ERROR:
      return 'An error occured. Please try again or contact support@trybe.do.'
    case errorCodes.INVALID_TYPE:
      return `Invalid file type. Allowed file types are: ${allowedFileTypes}.`
    case errorCodes.TOO_LARGE:
      const sizeText = formatBytes(usedSizeLimit)
      const message = `The file size exceeds ${sizeText}. Please compress the file before uploading.`
      return message
    case errorCodes.INVALID_VID_DIM:
      const aspectRatio = orientation === 'portrait' ? '9:16' : '16:9'
      return `Please use dimensions for ${orientation} mode with ${aspectRatio} aspect ratio.`
    case errorCodes.IMG_TOO_NARROW:
      return `Image too narrow. For optimal experience, please use 16:9 aspect ratio or closer to it.`
    case errorCodes.IMG_TOO_WIDE:
      return `Image too wide. For optimal experience, please use 16:9 aspect ratio or closer to it.`
    case errorCodes.INVALID_NAME:
      return 'File name includes disallowed special characters.'
    case errorCodes.LONG_NAME:
      return `File name exceeds ${NAME_MAX_LENGTH} characters.`
    case errorCodes.SHORT_NAME:
      return `File name should be at least ${NAME_MIN_LENGTH} characters long.`
    default:
      return 'An error occured. Please try again or contact support@trybe.do.'
  }
}

function createProgressCb({ id, coachOrgId, setProgress }) {
  return (progress) => {
    if (id && coachOrgId) {
      if (progress) {
        setProgress(progress)
      }
    }
  }
}

function onUploadComplete({ id, coachOrgId, name, updateAssetDrafts }) {
  if (id && coachOrgId) {
    updateAssetDrafts({ coachOrgId, id, drafts: { [name]: 'compressing' } })
  }
}
