import React from 'react'
import tw from 'twin.macro'

import { Dialog, DialogContent } from '../Dialog/Dialog'
import { IS_MAC_OS } from 'common/utils/detectOS'

export default function HotkeyMenu({ hotkeyMenuIsOpen, setHotkeyMenuIsOpen }) {
  return (
    <Dialog open={hotkeyMenuIsOpen} setOpen={setHotkeyMenuIsOpen}>
      <DialogContent header='Hotkeys'>
        <div className='flex flex-col border-b border-gray-200 divide-y overflow-auto'>
          <HotkeySection>
            <KeyRow>
              <Keys keys={[IS_MAC_OS ? '⌘' : 'Ctrl', 'H']} />
              <div>Show hotkeys</div>
            </KeyRow>
          </HotkeySection>
          <HotkeySection>
            <KeyRow>
              <Keys keys={[IS_MAC_OS ? '⌘' : 'Ctrl', 'E']} />
              <div>Add exercise</div>
            </KeyRow>
          </HotkeySection>
          <HotkeySection>
            <KeyRow>
              <Keys keys={[IS_MAC_OS ? '⌘' : 'Ctrl', 'B']} />
              <div>Add block</div>
            </KeyRow>
          </HotkeySection>
          <HotkeySection>
            <KeyRow>
              <Keys keys={[IS_MAC_OS ? '⌘' : 'Ctrl', 'I']} />
              <div>Combine block</div>
            </KeyRow>
            <KeyRow>
              <Keys keys={[IS_MAC_OS ? '⌘' : 'Ctrl', 'U']} />
              <div>Uncombine blocks</div>
            </KeyRow>
          </HotkeySection>
          <HotkeySection>
            <KeyRow>
              <Keys keys={[IS_MAC_OS ? '⌘' : 'Ctrl', 'K']} />
              <div>Increase sets</div>
            </KeyRow>
            <KeyRow>
              <Keys keys={[IS_MAC_OS ? '⌘' : 'Ctrl', 'J']} />
              <div>Decrease sets</div>
            </KeyRow>
          </HotkeySection>
          <HotkeySection>
            <KeyRow>
              <Keys keys={[IS_MAC_OS ? '⌘' : 'Ctrl', 'L']} />
              <div>Clear clipboard</div>
            </KeyRow>
          </HotkeySection>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function Keys({ keys }) {
  return (
    <div className='flex items-center'>
      {keys.map((key, idx) => (
        <div key={key} className='flex items-center justify-center'>
          <div className='w-6 h-6 mx-1 rounded bg-gray-200 text-gray-500 font-bold items-center justify-center text-center align-middle'>
            {key}
          </div>
          {/* {idx < keys.length - 1 ? '+' : null} */}
        </div>
      ))}
    </div>
  )
}

const HotkeySection = tw.div`
  flex
  flex-col
  w-full
  items-center
  justify-center
  py-2
`

const KeyRow = tw.div`
  flex
  w-full
  items-center
  justify-between
  px-5
  py-3
`
