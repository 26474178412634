import { useState } from 'react'
import { useDndMonitor } from '@dnd-kit/core'

export const useMonitorCurrWktDragging = ({ workoutId }) => {
  const [isCurrWktDragging, setIsCurrWktDragging] = useState(false)

  const onDragStart = ({ active }) => {
    const { itemType } = active.data.current
    const draggingWktId = active.id

    if (itemType === 'workout' && draggingWktId === workoutId) {
      setIsCurrWktDragging(true)
    }
  }

  const onDragEnd = ({ active }) => {
    const { itemType } = active.data.current
    const draggingWktId = active.id

    if (itemType === 'workout' && draggingWktId === workoutId) {
      setIsCurrWktDragging(false)
    }
  }

  useDndMonitor({ onDragStart, onDragEnd })

  return isCurrWktDragging
}
