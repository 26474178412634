import * as yup from 'yup'
import { emptyStringToNull, trimString } from 'common/utils/yupSchemaUtils'

export const schema = yup
  .object({
    name: yup
      .string()
      .required('Name required')
      .max(55, `Name should be no longer than 55 characters`)
      .transform(trimString),
    instructions: yup
      .string()
      .required('Instructions required')
      .max(120, `Instructions should be no longer than 120 characters`)
      .transform(trimString),
    portraitVid: yup.string().nullable().transform(emptyStringToNull),
    portraitThumbnail: yup.string().nullable().transform(emptyStringToNull),
    video: yup.string().nullable().transform(emptyStringToNull),
    thumbnail: yup.string().nullable().transform(emptyStringToNull),
    units: yup.array().of(yup.string()).min(1, 'Measurement unit is required'),
    cues: yup.array().of(yup.string()),
  })
  .required()
