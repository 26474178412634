import * as yup from 'yup'

const trimString = (_, originalValue) => String(originalValue).trim()

export const schema = yup
  .object({
    name: yup
      .string()
      .required('Tab name required')
      .max(18, 'Tab name must be 18 characters maximum')
      .transform(trimString),
  })
  .required()
