import React from 'react'

import { ItemMissingVideo } from './ItemMissingVideo'
import { ItemsMissingVideoContainer } from './ItemsMissingVideoContainer'

export function ItemsMissingVideoList({ missingVids }) {
  return (
    <>
      {Object.values(missingVids.exercises).length > 0 && (
        <ItemsMissingVideoContainer title='Exercises'>
          {Object.values(missingVids.exercises).map((exMissingVid, exMissingVidIdx) => (
            <ItemMissingVideo
              key={exMissingVidIdx}
              itemMissingVid={exMissingVid}
              itemMissingVidIdx={exMissingVidIdx}
              itemType='exercise'
            />
          ))}
        </ItemsMissingVideoContainer>
      )}
      {Object.values(missingVids.workoutVids).length > 0 && (
        <ItemsMissingVideoContainer title='Full-length videos'>
          {Object.values(missingVids.workoutVids).map((wktVidMissingVid, wktVidMissingVidIdx) => (
            <ItemMissingVideo
              key={wktVidMissingVidIdx}
              itemMissingVid={wktVidMissingVid}
              itemMissingVidIdx={wktVidMissingVidIdx}
              itemType='Video workout'
            />
          ))}
        </ItemsMissingVideoContainer>
      )}
    </>
  )
}
