import React from 'react'

export function PartDragOverlay(props) {
  const { partDragging } = props

  const partIdx = partDragging.index
  const partLetter = String.fromCharCode(65 + partIdx)
  const partTitle = partDragging.part.name ? partDragging.part.name : `Block ${partLetter}`

  return (
    <div className='flex flex-col ring-1 p-4 ring-gray-200 bg-white shadow-xl rounded-lg overflow-hidden'>
      <h4 className='font-medium text-sm mb-2 capitalize'>{partTitle}</h4>
      <ul>
        {partDragging.part.exercises.map((exercise, exIdx) => (
          <li key={exercise.dndUID} className='flex capitalize text-sm text-gray-500 font-medium mb-1 last:mb-0'>
            <span className='mr-1 whitespace-nowrap'>{exIdx + 1}.</span>
            <span className='truncate'>{exercise.name ? exercise.name : 'Untitled exercise'}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}
