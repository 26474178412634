import React, { useState, useRef } from 'react'
import 'twin.macro'
import { CgMoreAlt, CgPen, CgTrash } from 'react-icons/cg'

import { useRemoveProgressionMutation } from '../progressionsApi'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'

import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { TableCell } from 'modules/Progressions/ProgressionTable/styles'
import { ProgressionForm } from 'modules/Progressions/ProgressionForm/ProgressionForm'
import { ExerciseCell } from 'modules/Progressions/ProgressionTable/Cells/ExerciseCell'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { capitalizeFirstLetterOfWords } from 'common/utils/stringUtils'

function TableRow({ progressionKey, exercises, progressions, exerciseList }) {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const [removeProgression] = useRemoveProgressionMutation()

  const rowActionContainerRef = useRef(null)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { createAlert } = useAlert()

  const handleDelete = async () => {
    setLoading(true)
    await removeProgression({ coachOrgId, progressionKey })
    setLoading(false)
    createAlert({ text: 'Progression deleted!', type: 'success' })
  }

  const handleRowClick = (e) => {
    // Check that event did not happen in modal
    const occuredOutsideModal = e.currentTarget.contains(e.target)

    // Check that event did not happen in action row (edit/delete/more)
    const occuredOutsideActionRow = !rowActionContainerRef.current.contains(e.target)
    if (occuredOutsideModal && occuredOutsideActionRow) {
      setEditDialogOpen(true)
    }
  }

  const progressionKeyPretty = capitalizeFirstLetterOfWords(progressionKey)

  return (
    <tr className='cursor-pointer group h-24 hover:bg-gray-50' onClick={handleRowClick} aria-label='table-row'>
      <TableCell tw='w-3/12'>
        <div className='flex flex-col'>
          <div className='max-w-[250px] font-bold text-tBlack capitalize truncate' data-testid='table-progression-key'>
            {progressionKey}
          </div>
        </div>
      </TableCell>
      <ExerciseCell exercises={exercises} exerciseList={exerciseList} hideOnMobile={true} />
      <td className='relative'>
        <div
          ref={rowActionContainerRef}
          className='absolute top-1/2 -translate-y-1/2 right-3 inline-flex items-center justify-end bg-white group-hover:border group-hover:border-tBlack group-hover:border-opacity-20 rounded-lg group-hover:shadow-sm'
        >
          <Dialog open={editDialogOpen} setOpen={setEditDialogOpen}>
            <DialogTrigger
              className='hidden group-hover:inline-block hover:text-tGreen py-1 px-2 border-r border-tBlack border-opacity-20 transition-all'
              aria-label='Edit Row'
            >
              <CgPen className='w-5 h-5' />
            </DialogTrigger>
            <DialogContent header='Edit progression'>
              <ProgressionForm
                coachOrgId={coachOrgId}
                progressionKey={progressionKey}
                exercises={exercises}
                progressions={progressions}
                exerciseList={exerciseList}
              />
            </DialogContent>
          </Dialog>
          <Dialog>
            <DialogTrigger
              className='hidden group-hover:inline-block hover:text-tRed py-1 px-2 border-r border-tBlack border-opacity-20 transition-all'
              aria-label='Delete Row'
            >
              <CgTrash className='w-5 h-5' />
            </DialogTrigger>
            <DialogContent>
              <DeleteDialogBanner
                text={`This will delete the ${progressionKeyPretty} progression`}
                handleDelete={() => handleDelete()}
                loading={loading}
              />
            </DialogContent>
          </Dialog>
          <button className='inline-block py-1 px-2' aria-label='More'>
            <CgMoreAlt className='w-5 h-5' />
          </button>
        </div>
      </td>
    </tr>
  )
}

export default TableRow
