import { each, uniqBy } from 'lodash'
import { getFirebase, globalApi } from 'modules/App/globalApi'

export const helpCenterApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenHelpCenterCategories: build.query({
      queryFn: async () => {
        const { data, error } = await getFirebase(`/helpCenter/categories`)
        return { data, error }
      },
    }),
    listenHelpCenterContent: build.query({
      queryFn: async (contentId) => {
        const { data, error } = await getFirebase(`/helpCenter/content/${contentId}`)
        return { data, error }
      },
    }),
    searchContent: build.query({
      queryFn: async (searchQuery) => {
        const { data: categoriesData } = await getFirebase('/helpCenter/categories')
        const searchResult = []

        each(categoriesData, (category) => {
          each(category.content, (content) => {
            if (searchQuery && content.navbarTitle?.toLowerCase().includes(searchQuery.toLowerCase())) {
              searchResult.push({ contentId: content.contentId, title: content.navbarTitle })
            }
          })
        })

        return { data: uniqBy(searchResult, 'contentId') }
      },
    }),
  }),
  overrideExisting: false,
})

export const { useListenHelpCenterCategoriesQuery, useListenHelpCenterContentQuery, useSearchContentQuery } =
  helpCenterApi
