import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import tw from 'twin.macro'
import { CgClose } from 'react-icons/cg'
import { FaChevronRight } from 'react-icons/fa'
import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai'

import { useRemoveHomepageCardMutation } from '../homepageApi'

function HomepageCard({ card, userId }) {
  const [listIsOpen, setListIsOpen] = useState(false)

  const [removeHomepageCard] = useRemoveHomepageCardMutation()

  const handleRemoveCard = async (cardId) => {
    await removeHomepageCard({ userId, cardId })
  }

  return (
    <CardWrapper>
      {card.tags ? (
        <div className='flex items-center'>
          {card.tags.map((tag) => (
            <Tag key={`${card.id}-${tag}`}>{tag}</Tag>
          ))}
        </div>
      ) : null}
      <div className='flex items-center justify-between mb-2.5'>
        <h5 className='text-xl font-bold'>{card.title}</h5>
        {card.dismissable ? (
          <button onClick={() => handleRemoveCard(card.id)} className='p-0.5 rounded-full hover:bg-gray-200'>
            <CgClose />
          </button>
        ) : null}
      </div>
      <p className='mb-2.5 text-base'>{card.description}</p>
      {card.type === 'link' ? (
        <Link to={card.url} className='flex items-center text-tGreen font-bold underline hover:no-underline'>
          Let's do it <FaChevronRight size='0.75rem' className='ml-1.5' />
        </Link>
      ) : (
        <div>
          <button
            className='flex items-center text-tGreen font-bold hover:underline'
            onClick={() => setListIsOpen(!listIsOpen)}
          >
            {listIsOpen ? (
              <AiFillCaretDown size='0.75rem' className='mr-1.5' />
            ) : (
              <AiFillCaretRight size='0.75rem' className='mr-1.5' />
            )}{' '}
            Show all
          </button>
          <ul css={[tw`pl-4 mt-1 hidden`, listIsOpen && tw`block`]}>
            {card.list.map((item, idx) => (
              <li key={item}>
                {idx + 1}. {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </CardWrapper>
  )
}

const Tag = tw.span`
  text-xs 
  bg-gray-100 
  rounded-full 
  mr-1.5 
  mb-2.5 
  px-1.5 
  py-0.5
  border
  border-gray-300
`

const CardWrapper = tw.div`
  relative 
  px-6 
  py-4 
  mb-5
  bg-white 
  rounded-xl 
  before:absolute 
  before:top-3
  before:bottom-3
  before:left-1.5 
  before:w-1
  before:rounded
  before:bg-tGreen
`

export default HomepageCard
