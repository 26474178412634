import { AddResourcePopover } from './AddResourcePopover'
import { CardContainer } from './styles'

export function NoResourcesCard({ addResourceRef, setValue, programResourcesEntries }) {
  return (
    <CardContainer>
      <h3 className='text-center font-bold text-lg text-tBlack mt-4'>No program resources yet</h3>
      <p className='text-xs text-center text-tBlack mb-2'>Click Add Resource to add your first</p>
      <AddResourcePopover
        addResourceRef={addResourceRef}
        setValue={setValue}
        programResourcesEntries={programResourcesEntries}
      />
    </CardContainer>
  )
}
