import tw, { styled } from 'twin.macro'
import React, { useState } from 'react'
import { CgPen } from 'react-icons/cg'
import { useParams } from 'react-router-dom'

import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'

import { TabVectorRight } from '../vectors/TabVectorRight'
import { TabVectorLeft } from '../vectors/TabVectorLeft'
import { TabForm } from '../../TabForm/TabForm'

import { getIsTabSelected } from '../utils'
import { MAX_TAB_COUNT } from '../../../constants/constants'

export const Tab = React.memo(
  ({
    tabId,
    tab,
    showTabSeparator,
    toggleSeparatorVisibility,
    isOnlyTab,
    handleDelete,
    coachOrgId,
    attributes,
    listeners,
    isDragging,
  }) => {
    const { tabId: tabIdParam } = useParams()
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)

    return (
      <TabContainer
        id={`tabContainerId-${tabId}`}
        key={tabId}
        isTabSelected={getIsTabSelected({ tab, tabId, tabIdParam })}
        showTabSeparator={showTabSeparator}
        className='group cursor-grab'
        {...attributes}
        {...listeners}
      >
        <TabVectorLeft
          className={`absolute invisible bottom-0 left-0 -translate-x-full ${
            getIsTabSelected({ tab, tabId, tabIdParam }) && tab.tabIdx !== 0 && !isDragging
              ? 'fill-white group-hover:fill-white !visible group-hover:!opacity-100 z-[2] '
              : ''
          }
        ${tab.tabIdx !== 0 ? 'group-hover:visible group-hover:fill-white group-hover:opacity-50 z-[1]' : ''}
        `}
        />
        <TabVectorRight
          className={`absolute invisible bottom-0 right-0 translate-x-full ${
            getIsTabSelected({ tab, tabId, tabIdParam }) && tab.tabIdx + 1 !== MAX_TAB_COUNT && !isDragging
              ? 'fill-white group-hover:fill-white !visible group-hover:!opacity-100 z-[2] '
              : ''
          }
        ${
          tab.tabIdx + 1 !== MAX_TAB_COUNT
            ? 'group-hover:visible group-hover:fill-white group-hover:opacity-50 z-[1]'
            : ''
        }
        `}
        />
        <h4 className='font-semibold text-gray-500 py-1 outline-none truncate w-full'>{tab.name}</h4>
        <div
          className='group-hover:visible invisible flex items-center ml-4'
          css={[getIsTabSelected({ tab, tabId, tabIdParam }) && tw`visible md:invisible`]}
        >
          <Dialog open={editModalIsOpen} setOpen={setEditModalIsOpen}>
            <DialogTrigger
              className='flex items-center text-gray-500 font-bold hover:underline decoration-2 decoration-gray-500 underline-offset-2 transition-all'
              onOpenCb={() => toggleSeparatorVisibility('show')}
            >
              <CgPen
                data-no-dnd='true'
                className='cursor-pointer text-gray-500 hover:text-tGreen w-[18px] h-[18px]'
                data-testid='editTab'
              />
            </DialogTrigger>
            <DialogContent header='Rename tab' overlayProps={{ 'data-no-dnd': true }}>
              <FormRefsControlProvider>
                <TabForm
                  coachOrgId={coachOrgId}
                  tab={tab}
                  tabId={tabId}
                  isOnlyTab={isOnlyTab}
                  handleDelete={handleDelete}
                />
              </FormRefsControlProvider>
            </DialogContent>
          </Dialog>
        </div>
      </TabContainer>
    )
  }
)

const TabContainer = styled.div(({ isTabSelected, showTabSeparator }) => [
  tw`relative flex items-center px-4 py-2 hover:bg-white hover:bg-opacity-50 rounded-t-lg w-full transition-all`,
  isTabSelected && tw`bg-white hover:bg-white z-[2]`,
  showTabSeparator &&
    tw`after:content-[''] after:absolute after:right-0 after:top-[12px] after:bottom-[10px] after:w-[1px] after:bg-gray-300 hover:after:invisible`,
])
