import { isEmpty, sortBy } from 'lodash'
import { getFirebase, updateFirebase } from 'modules/App/globalApi'

export const removeItemsInCollections = async ({ coachOrgId, itemIds }) => {
  const { data: collections } = await getFirebase(`coachOrgs/${coachOrgId}/collections`)

  if (!collections) return

  let collectionsUpdate = {}
  Object.entries(collections).forEach(([collectionId, collection]) => {
    const items = collection?.items || {}

    let itemIdsToDelete = {}
    itemIds.forEach((id) => {
      if (items[id]) {
        itemIdsToDelete[id] = true
      }
    })

    if (!isEmpty(itemIdsToDelete)) {
      const sortedItems = sortBy(items, (item) => item.index)
      const updatedItems = sortedItems
        .filter((item) => !itemIdsToDelete[item.id])
        .map((item, index) => [item.id, { ...item, index }])
      collectionsUpdate[`${collectionId}/items`] = Object.fromEntries(updatedItems)
    }
  })

  if (!isEmpty(collectionsUpdate)) {
    await updateFirebase(`coachOrgs/${coachOrgId}/collections`, collectionsUpdate)
  }
}
