import React, { useCallback, useState, useEffect } from 'react'
import { ReactFlow, Controls } from '@xyflow/react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import QuestionNode from './QuestionNode'
import ResultNode from './ResultNode'
import TypeSelectorNode from './TypeSelectorNode'
import UpdateQuizLayout from './UpdateQuizLayout'
import DeleteResultsChecklist from './DeleteResultsChecklist'

import { useUpdateQuestionsMutation } from '../appOnboardingQuizApi'
import { checkForDeadNodes } from '../utils/flowDataUtils'

import { getLayoutedElements } from '../utils/flowLayoutingUtils'
import { getEdges, getNodes } from '../utils/flowDataUtils'

import '@xyflow/react/dist/style.css'

const nodeTypes = { question: QuestionNode, result: ResultNode, selector: TypeSelectorNode }

function QuizFlowView({ questions, coachOrgId }) {
  const idsOfResultsToDelete = useSelector((state) => state.quizFlow.idsOfResultsToDelete)
  const [updateQuestions] = useUpdateQuestionsMutation()
  const [lastSelectedNodeId, setLastSelectedNodeId] = useState(null)
  const [selectedNodeId, setSelectedNodeId] = useState(null)

  const [elkLayoutNodes, setElkLayoutNodes] = useState([])
  const [elkLayoutEdges, setElkLayoutEdges] = useState([])

  const onNodesChange = useCallback((changes) => {
    const selectChangeExists = changes.find((c) => c?.type === 'select')
    if (selectChangeExists) {
      const selectedNode = changes.find((c) => c?.type === 'select' && Boolean(c?.selected))
      const deselectedNode = changes.find((c) => c?.type === 'select' && !c?.selected)

      if (selectedNode) {
        setLastSelectedNodeId(null)
        setSelectedNodeId(selectedNode?.id || null)
      } else {
        setLastSelectedNodeId(deselectedNode?.id)
        setSelectedNodeId(null)
      }
    }
  }, [])

  useEffect(() => {
    const deadNodes = checkForDeadNodes(questions)
    if (!isEmpty(deadNodes)) {
      console.log('Dead nodes found, cleaning up', deadNodes)
    }
    updateQuestions({
      coachOrgId,
      update: deadNodes,
    })
  }, [questions, coachOrgId, updateQuestions])

  useEffect(() => {
    const setLayout = async () => {
      const { nodes: initialNodes, edgeIdsToShow } = getNodes({
        questions: Object.values(questions),
        coachOrgId,
        selectedNodeId,
        lastSelectedNodeId,
        idsOfResultsToDelete,
      })
      const initialEdges = getEdges({
        questions: Object.values(questions),
        edgeIdsToShow,
        nodes: initialNodes,
      })
      const { nodes: layoutedNodes, edges: layoutedEdges } = await getLayoutedElements({
        nodes: initialNodes,
        edges: initialEdges,
        noNodesSelected: selectedNodeId === null,
      })

      setElkLayoutNodes(layoutedNodes)
      setElkLayoutEdges(layoutedEdges)
    }

    setLayout()
  }, [selectedNodeId, questions, lastSelectedNodeId, idsOfResultsToDelete, coachOrgId])

  return (
    <ReactFlow
      nodes={elkLayoutNodes}
      edges={elkLayoutEdges}
      onNodesChange={onNodesChange}
      style={{ backgroundColor: '#fff', borderRadius: 8 }}
      nodeTypes={nodeTypes}
      fitView
      // onInit={(reactFlow) => {
      //   const firstQuestion = reactFlow.getNodes().find((node) => !node?.data?.previousQuestionId)
      //   setSelectedNodeId(firstQuestion?.id || null)
      // }}
      proOptions={{
        hideAttribution: true,
      }}
      panOnScroll
      maxZoom={1.2}
      minZoom={0.1}
      deleteKeyCode={null}
      selectionKeyCode={null}
      multiSelectionKeyCode={null}
      onlyRenderVisibleElements={true}
      nodesDraggable={false}
      nodesConnectable={false}
    >
      <Controls position='top-right' className='bg-white border-gray-300 border shadow-md rounded-md overflow-hidden' />
      <UpdateQuizLayout selectedNodeId={selectedNodeId} layoutedNodes={elkLayoutNodes} />
      {!isEmpty(idsOfResultsToDelete) && (
        <DeleteResultsChecklist idsOfResultsToDelete={idsOfResultsToDelete} coachOrgId={coachOrgId} />
      )}
      {/* <QuizSettings /> */}
    </ReactFlow>
  )
}

export default QuizFlowView
