import tw from 'twin.macro'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Popover from '@radix-ui/react-popover'
import { useReactFlow } from '@xyflow/react'
import { isEmpty } from 'lodash'
import { CgMoreAlt } from 'react-icons/cg'

import { useUpdateQuestionsMutation, useUpdateResultsMutation } from '../appOnboardingQuizApi'
import { useAlert } from 'common/components/Alert/hooks/useAlert'

import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { CreateDialogBanner } from 'common/components/CreateDialogBanner/CreateDialogBanner'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'

import { setCopiedQuestion } from '../quizFlowSlice'
import { copyQuestionTypes } from '../constants/copyQuestionConstants'
import {
  getFbUpdateOfPasteEverything,
  getFbUpdateOfPasteQuestionOnly,
  getFetchedQuestionsState,
  getFbUpdateOfDeleteQuestion,
} from '../utils/updateUtils'
import { getAllAnswersRequireSelection } from '../utils/flowDataUtils'

function QuestionMoreActions({ questionData, copiedQuestion, pasteOnly = false, coachOrgId, isNodeCollapsed = false }) {
  const dispatch = useDispatch()
  const { createAlert } = useAlert()

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const handleCopyEverythingClick = () => {
    dispatch(setCopiedQuestion({ id: questionData?.id, type: copyQuestionTypes.EVERYTHING }))
    createAlert({ text: 'Question copied!', type: 'success' })
  }

  return (
    <div
      id='more-actions'
      className='hidden absolute z-20 right-[6px] top-1 items-center px-1.5 rounded border-[1px] bg-white border-gray-200 shadow-sm nopan nodrag'
      css={[isPopoverOpen && tw`!flex`, isNodeCollapsed && tw`right-[10px]`]}
    >
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger
          tabIndex={-1}
          onClick={(e) => {
            e.stopPropagation()
            setIsPopoverOpen(true)
          }}
        >
          <CgMoreAlt className='cursor-pointer w-5 h-5 text-gray-500 hover:text-tGreen' />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className='bg-white px-2 py-2 rounded-md shadow-xl border-2 border-gray-300 z-50'
            align='end'
            alignOffset={-7}
            sideOffset={5}
            arrowPadding={12}
          >
            <Popover.Arrow className='fill-gray-300' />
            {!pasteOnly && (
              <>
                <Popover.Close asChild={true}>
                  <button
                    css={popoverBtnCss}
                    onClick={(e) => {
                      e.stopPropagation()
                      dispatch(setCopiedQuestion({ id: questionData?.id, type: copyQuestionTypes.QUESTION_ONLY }))
                      createAlert({ text: 'Question copied!', type: 'success' })
                    }}
                  >
                    Copy this question only
                  </button>
                </Popover.Close>
                {isPopoverOpen && (
                  <CopyEverythingButton onClick={handleCopyEverythingClick} answers={questionData?.answers} />
                )}
              </>
            )}
            {copiedQuestion && questionData?.id !== copiedQuestion?.id && (
              <PasteButton
                questionId={questionData?.id}
                copiedQuestion={copiedQuestion}
                isQuestionEmpty={isEmpty(questionData?.answers)}
                setIsPopoverOpen={setIsPopoverOpen}
                createAlert={createAlert}
                coachOrgId={coachOrgId}
              />
            )}
            {!isEmpty(questionData?.answers) && (
              <DeleteButton
                questionId={questionData?.id}
                setIsPopoverOpen={setIsPopoverOpen}
                createAlert={createAlert}
                coachOrgId={coachOrgId}
              />
            )}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  )
}

function CopyEverythingButton({ onClick, answers }) {
  const { getNode } = useReactFlow()
  const allAnswersRequireSelection = getAllAnswersRequireSelection({ answers, getNode })

  if (allAnswersRequireSelection) {
    return null
  }

  return (
    <Popover.Close asChild={true}>
      <button
        css={popoverBtnCss}
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
      >
        Copy this question and everything after it
      </button>
    </Popover.Close>
  )
}

function PasteButton({ questionId, copiedQuestion, isQuestionEmpty, setIsPopoverOpen, createAlert, coachOrgId }) {
  const dispatch = useDispatch()
  const [updateQuestions, { isLoading: isUpdatingQuestions }] = useUpdateQuestionsMutation()
  const [updateResults, { isLoading: isUpdatingResults }] = useUpdateResultsMutation()

  const handlePaste = async () => {
    const pasteAtQuestion = getFetchedQuestionsState({ coachOrgId })?.[questionId] || {}
    const copiedQuestionInfo = getFetchedQuestionsState({ coachOrgId })?.[copiedQuestion.id] || {}

    if (isEmpty(copiedQuestionInfo) || isEmpty(copiedQuestionInfo?.answers)) {
      setIsPopoverOpen(false)
      createAlert({ text: 'Copied question does not exist!', type: 'danger' })
      dispatch(setCopiedQuestion(null))
      return
    }

    if (copiedQuestion?.type === copyQuestionTypes.QUESTION_ONLY) {
      const { questionsUpdate, resultsUpdate } = getFbUpdateOfPasteQuestionOnly({
        pasteAtQuestion,
        copiedQuestion: copiedQuestionInfo,
        coachOrgId,
      })
      await updateQuestions({
        coachOrgId,
        update: questionsUpdate,
      })
      if (!isEmpty(resultsUpdate)) {
        await updateResults({
          coachOrgId,
          update: resultsUpdate,
        })
      }
    } else {
      const { questionsUpdate, resultsUpdate } = getFbUpdateOfPasteEverything({
        pasteAtQuestion,
        copiedQuestion: copiedQuestionInfo,
        coachOrgId,
      })
      await updateQuestions({
        coachOrgId,
        update: questionsUpdate,
      })
      if (!isEmpty(resultsUpdate)) {
        await updateResults({
          coachOrgId,
          update: resultsUpdate,
        })
      }
    }
    setIsPopoverOpen(false)
    createAlert({ text: 'Question pasted!', type: 'success' })
  }

  if (!isQuestionEmpty) {
    return (
      <Dialog>
        <DialogTrigger
          css={popoverBtnCss}
          onOpenCb={(e) => {
            e.stopPropagation()
          }}
        >
          Paste copied question
        </DialogTrigger>
        <DialogContent>
          <CreateDialogBanner
            titleText='Are you sure?'
            createBtnText='Paste'
            text={
              copyQuestionTypes.QUESTION_ONLY
                ? 'Pasting will overwrite this question.'
                : 'Pasting will overwrite this question. Paths branching out from this question will be replaced by copied question paths.'
            }
            handleCreate={() => handlePaste()}
            loading={isUpdatingQuestions || isUpdatingResults}
          />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Popover.Close asChild={true}>
      <button
        css={popoverBtnCss}
        onClick={(e) => {
          e.stopPropagation()
          handlePaste()
        }}
      >
        Paste copied question
      </button>
    </Popover.Close>
  )
}

function DeleteButton({ questionId, setIsPopoverOpen, createAlert, coachOrgId }) {
  const [updateQuestions, { isLoading: isUpdatingQuestions }] = useUpdateQuestionsMutation()
  const [updateResults, { isLoading: isUpdatingResults }] = useUpdateResultsMutation()

  const handleDelete = async () => {
    const deleteQuestion = getFetchedQuestionsState({ coachOrgId })?.[questionId] || {}
    const { questionsUpdate, resultsUpdate } = getFbUpdateOfDeleteQuestion({ deleteQuestion, coachOrgId })

    await updateQuestions({
      coachOrgId,
      update: questionsUpdate,
    })
    if (!isEmpty(resultsUpdate)) {
      await updateResults({
        coachOrgId,
        update: resultsUpdate,
      })
    }

    setIsPopoverOpen(false)
    createAlert({ text: 'Question deleted!', type: 'success' })
  }

  return (
    <Dialog>
      <DialogTrigger
        css={popoverBtnCss}
        onOpenCb={(e) => {
          e.stopPropagation()
        }}
      >
        Delete question
      </DialogTrigger>
      <DialogContent
        dialogCloseCb={(e) => {
          e.stopPropagation()
        }}
      >
        <DeleteDialogBanner
          text='This will delete the question and all of the paths that branch out from it.'
          handleDelete={handleDelete}
          typeToConfirm={getShouldTypeToConfirm({ questionId, coachOrgId })}
          loading={isUpdatingQuestions || isUpdatingResults}
        />
      </DialogContent>
    </Dialog>
  )
}

function getShouldTypeToConfirm({ questionId, coachOrgId }) {
  const questions = getFetchedQuestionsState({ coachOrgId }) || {}
  const deleteQuestionAnswers = Object.values(questions?.[questionId]?.answers || {})
  const allAnswerQuestionsEmpty = deleteQuestionAnswers.every(
    (answer) => Boolean(answer?.nextQuestionId) && isEmpty(questions?.[answer?.nextQuestionId]?.answers)
  )
  return !allAnswerQuestionsEmpty
}

export default QuestionMoreActions

const popoverBtnCss = tw`
  flex items-center bg-white hover:bg-gray-300 hover:bg-opacity-30
  text-sm text-tBlack transition-all rounded-md justify-start
  font-medium px-2 py-2 w-full`
