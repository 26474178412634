import React from 'react'

import { TableRow } from './TableRow'
import { LoadingRow } from './LoadingRow'

export function TableBody({
  coachOrgId,
  loading,
  filteredVideos,
  offset,
  currentPage,
  pageLimit,
  setModalVideo,
  setEditDialogOpen,
}) {
  const nextPageStartIdx = currentPage * pageLimit
  return (
    <tbody className='bg-white divide-y divide-gray-200'>
      {loading ? (
        <Loading />
      ) : (
        <TableRows
          coachOrgId={coachOrgId}
          pageStartIdx={offset}
          nextPageStartIdx={nextPageStartIdx}
          filteredVideos={filteredVideos}
          setModalVideo={setModalVideo}
          setEditDialogOpen={setEditDialogOpen}
        />
      )}
    </tbody>
  )
}

function Loading() {
  const numRows = 10
  return [...Array(numRows).keys()].map((key) => <LoadingRow key={key} />)
}

function TableRows({ coachOrgId, pageStartIdx, nextPageStartIdx, filteredVideos, setModalVideo, setEditDialogOpen }) {
  return filteredVideos.map(([videoKey, video], index) => {
    const isOnCurrPage = index >= pageStartIdx && index < nextPageStartIdx

    if (isOnCurrPage) {
      return (
        <TableRow
          key={videoKey}
          coachOrgId={coachOrgId}
          videoKey={videoKey}
          video={video}
          setModalVideo={setModalVideo}
          setEditDialogOpen={setEditDialogOpen}
        />
      )
    } else {
      return null
    }
  })
}
