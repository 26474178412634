import { each } from 'lodash'

import {
  globalApi,
  listenFirebase,
  setFirebase,
  updateFirebase,
  removeFirebase,
  getFirebase,
} from 'modules/App/globalApi'

import {
  prepDraftProgram,
  cleanDraftProgramForPublishing,
  cleanWorkout,
  prepWorkout,
  getProgramWorkouts,
  getWorkoutsByIds,
  getDuplicatedProgramWkts,
  getDuplicatedProgram,
} from './utils/programUtils'

import { removeProgramInProgramGroups, deleteDraftWktsOfProgram } from './utils/removeProgram'

import {
  getNewEmptyWeek,
  getNewWorkout,
  getNewRestDay,
  getNewPart,
  getNewProgram,
  getNewEmptyWkt,
  getNewRestPart,
  changeSavedPartIds,
  getNewVideoDay,
} from 'modules/Programs/programModels'

import { initDraftProgram } from './utils/initPrograms'
import { createUID } from 'common/utils/createUID'

import { copiedWeeksCleared, draftWorkoutRetrieved, draftWorkoutsDeleted } from 'modules/Programs/programSlice'
import { removeItemsInCollections } from 'modules/Collections/collectionUtils'
import { updateTabRowsOnDelete } from 'modules/Layout/utils/layoutUtils'
import { updateItemContentTags } from 'modules/ContentTags/contentTagUtils'
import { updateManyWorkoutContentTags } from './utils/contentTagUtils'

export const programApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenDraftProgram: build.query({
      queryFn: () => {
        return { data: { isFiller: true } }
      },
      onCacheEntryAdded: async ({ orgId, programId }, api) => {
        const path = `drafts/${orgId}/programs/${programId}`
        listenFirebase(path, api, async (draftProg) => {
          if (!draftProg) {
            api.updateCachedData(() => ({ shouldRedirect: true }))
          } else if (!draftProg.workouts) {
            //notify component to initialize program
            api.updateCachedData(() => ({ shouldInitDraft: true }))
          } else {
            const { wktsByWeek, numWeeks, hasWorkouts, wktIdsToDayIdcs, wktIdsSorted } = prepDraftProgram(draftProg)

            const newCache = {
              program: draftProg,
              wktsByWeek,
              numWeeks,
              hasWorkouts,
              wktIdsToDayIdcs,
              wktIdsSorted,
            }

            api.updateCachedData(() => newCache)
          }
        })
      },
    }),
    initDraftProgram: build.mutation({
      queryFn: async ({ orgId, programId }) => {
        console.log('API initDraftProgram')
        const { status, error } = await initDraftProgram({ programId, orgId })
        return { status, error }
      },
    }),
    listenOrgProgramsList: build.query({
      queryFn: () => ({ data: { stillLoading: true } }),
      onCacheEntryAdded: async ({ orgId }, api) => {
        listenFirebase(`drafts/${orgId}/programs`, api, (programs) => {
          if (!programs) {
            api.updateCachedData(() => ({}))
          } else {
            //PATCH: for some reason, programs from a diff org sometimes show
            //when we switch orgs, causing draft program data to be
            //written into another org's drafts
            const programsWithoutWrongOrgId = {}
            each(programs, (program, programId) => {
              //legacy programs (pre-dashboard) have no program.coachOrgId
              if (program && program.coachOrgId && program.coachOrgId !== orgId) {
                console.log('Clue to err - program with diff orgId', program)
              } else {
                programsWithoutWrongOrgId[programId] = program
              }
            })

            api.updateCachedData(() => programsWithoutWrongOrgId)
          }
        })
      },
    }),
    createProgram: build.mutation({
      queryFn: async ({ orgId, programMeta, optionalProgId }) => {
        try {
          const { newProgram, newWorkouts } = getNewProgram(programMeta, optionalProgId)
          const programId = newProgram.id

          const { error: draftErr } = await updateFirebase(`drafts/${orgId}/programs/${programId}`, newProgram)

          const { error: wktsErr } = await updateFirebase(`drafts/${orgId}/workouts`, newWorkouts)

          const { error: publishedErr } = await updateFirebase(`programs/${programId}`, {
            ...newProgram,
            workouts: null, // program shouldn't have workouts until published
            coachOrgId: orgId, //needed for backend & other logic
          })

          if (draftErr || wktsErr || publishedErr) {
            throw draftErr || wktsErr || publishedErr
          }
        } catch (e) {
          console.error('createProgram error', e)
          return { error: e }
        } finally {
          return {
            status: 'success',
          }
        }
      },
    }),
    duplicateProgram: build.mutation({
      queryFn: async ({ orgId, program }, api) => {
        try {
          const { workouts } = await getProgramWorkouts({
            state: api.getState(),
            orgId,
            programId: program.id,
            wktIdsToDayIdcs: program.workouts,
          })

          const newProgramId = createUID()
          const { duplicatedWkts, updatedWktIdsToDayIdcs } = getDuplicatedProgramWkts({ workouts })

          const duplicatedProgram = getDuplicatedProgram({
            newProgramId,
            program,
            updatedWktIdsToDayIdcs,
          })

          const { error: draftErr } = await updateFirebase(
            `drafts/${orgId}/programs/${newProgramId}`,
            duplicatedProgram
          )

          // Add program contentTags
          const updateProgContentTagsErr = await updateItemContentTags({
            orgId,
            item: duplicatedProgram,
            contentId: newProgramId,
            contentType: 'program',
            updateType: 'add',
          })

          const { error: wktsErr } = await updateFirebase(`drafts/${orgId}/workouts`, duplicatedWkts)

          // Add workout contentTags
          const updateWktContentTagsErr = await updateManyWorkoutContentTags({
            orgId,
            workouts: duplicatedWkts,
            programId: newProgramId,
            updateType: 'add',
          })

          const { error: publishedErr } = await updateFirebase(`programs/${newProgramId}`, {
            ...duplicatedProgram,
            workouts: null, // program shouldn't have workouts until published
            coachOrgId: orgId, //needed for backend & other logic
          })

          if (
            draftErr ||
            wktsErr ||
            publishedErr ||
            updateWktContentTagsErr?.instrErr ||
            updateWktContentTagsErr?.equipmentErr ||
            updateWktContentTagsErr?.difficultyErr ||
            updateWktContentTagsErr?.tagsErr ||
            updateProgContentTagsErr.instrErr ||
            updateProgContentTagsErr.equipmentErr ||
            updateProgContentTagsErr.difficultyErr ||
            updateProgContentTagsErr.tagsErr
          ) {
            throw (
              draftErr ||
              wktsErr ||
              publishedErr ||
              updateWktContentTagsErr?.instrErr ||
              updateWktContentTagsErr?.equipmentErr ||
              updateWktContentTagsErr?.difficultyErr ||
              updateWktContentTagsErr?.tagsErr ||
              updateProgContentTagsErr?.instrErr ||
              updateProgContentTagsErr?.equipmentErr ||
              updateProgContentTagsErr?.difficultyErr ||
              updateProgContentTagsErr?.tagsErr
            )
          }
        } catch (e) {
          console.error('duplicateProgram error', e)
          return { error: e }
        } finally {
          return {
            status: 'success',
          }
        }
      },
    }),
    listenWorkout: build.query({
      queryFn: () => ({ data: { stillLoading: true } }),
      onCacheEntryAdded: async ({ orgId, workoutId }, api) => {
        const path = `drafts/${orgId}/workouts/${workoutId}`
        listenFirebase(path, api, (wkt) => {
          const firebaseWkt = { ...wkt, id: workoutId }
          const prepped = prepWorkout(firebaseWkt)

          api.updateCachedData(() => prepped)

          api.dispatch(
            draftWorkoutRetrieved({
              workoutId,
              workout: prepped,
            })
          )
        })
      },
    }),
    getAllProgramWorkouts: build.query({
      queryFn: async ({ orgId, programId, wktIdsToDayIdcs }, api) => {
        const { workouts: data } = await getProgramWorkouts({
          state: api.getState(),
          orgId,
          programId,
          wktIdsToDayIdcs,
        })
        return { data }
      },
    }),
    getWorkoutsByIds: build.query({
      queryFn: async ({ orgId, wktIds }, api) => {
        const workouts = await getWorkoutsByIds({ state: api.getState(), orgId, wktIds })
        return { data: workouts }
      },
    }),
    publishDraftProgram: build.mutation({
      //use onQueryStarted only because we need getState.
      //fetching workouts separately would be too slow
      //or passing state.program.workouts to ProgramView would cause too many re-renders
      onQueryStarted: async ({ orgId, programId, wktIdsToDayIdcs, name }, { getState }) => {
        try {
          const { workouts, error: getWktsErr } = await getProgramWorkouts({
            state: getState(),
            orgId,
            programId,
            wktIdsToDayIdcs,
          })

          if (getWktsErr) throw getWktsErr

          const cleanedWkts = cleanDraftProgramForPublishing(workouts)

          const publishPath = `programs/${programId}/workoutsV2`
          const { error: publishErr } = await setFirebase(publishPath, cleanedWkts)
          if (publishErr) throw publishErr

          const orgPath = `coachOrgs/${orgId}/programs/${programId}`
          const { error: orgErr } = await setFirebase(orgPath, name || true)
          if (orgErr) throw orgErr
        } catch (e) {
          console.error('publishDraftProgram error', e)
          return { error: e }
        } finally {
          return { status: 'success' }
        }
      },
      queryFn: () => ({}),
    }),
    addWeek: build.mutation({
      queryFn: async ({ orgId, programId, weekIdx }) => {
        console.log('API addWeek')
        const { newWorkouts, idToDayIdx } = getNewEmptyWeek(weekIdx)

        const wktPath = `drafts/${orgId}/workouts`
        const { error: updateWktsErr } = await updateFirebase(wktPath, newWorkouts)

        if (updateWktsErr) {
          console.error('addWeek updateWktsErr:', updateWktsErr)
          return
        }

        const progPath = `drafts/${orgId}/programs/${programId}/workouts`
        const { status, error: updateProgErr } = await updateFirebase(progPath, idToDayIdx)

        if (updateProgErr) {
          console.error('addWeek updateProgErr:', updateProgErr)
          return
        }

        return { status, error: { updateWktsErr, updateProgErr } }
      },
    }),
    deleteWeek: build.mutation({
      queryFn: async ({ orgId, programId, weekIdx, weekWktIds, wktIdsSorted }, { dispatch, getState }) => {
        const reorderedProgWkts = {}
        const firebaseWktsUpdate = {}

        const weekWorkouts = await getWorkoutsByIds({ state: getState(), orgId, wktIds: weekWktIds })
        const updateContentTagsErr = await updateManyWorkoutContentTags({
          orgId,
          workouts: weekWorkouts,
          programId,
          updateType: 'remove',
        })

        if (
          updateContentTagsErr?.instrErr ||
          updateContentTagsErr?.equipmentErr ||
          updateContentTagsErr?.difficultyErr ||
          updateContentTagsErr?.tagsErr
        ) {
          console.error('deleteWeek updateContentTagsErr:', updateContentTagsErr)
          return
        }

        const isLastRemainingWeek = wktIdsSorted.length === 7
        if (!isLastRemainingWeek) {
          each(wktIdsSorted, (wktId, dayIdx) => {
            if (weekWktIds.includes(wktId)) {
              reorderedProgWkts[wktId] = null
              firebaseWktsUpdate[wktId] = null
            }

            const afterCurrWeekRange = dayIdx + 1 > (weekIdx + 1) * 7
            if (afterCurrWeekRange) {
              const updatedDayIdx = dayIdx - 7
              reorderedProgWkts[wktId] = updatedDayIdx

              const fbKey = `${wktId}/dayIdx`
              firebaseWktsUpdate[fbKey] = updatedDayIdx
            }
          })
        } else {
          each(wktIdsSorted, (wktId, dayIdx) => {
            firebaseWktsUpdate[wktId] = getNewEmptyWkt(dayIdx, wktId)
          })
        }

        let updateErrors = {}
        const wktPath = `drafts/${orgId}/workouts`
        const { error: updateWktsErr } = await updateFirebase(wktPath, firebaseWktsUpdate)
        updateErrors = { ...updateErrors, updateWktsErr }

        if (updateWktsErr) {
          console.error('deleteWeek updateWktsErr:', updateWktsErr)
          return
        }

        if (!isLastRemainingWeek) {
          const progPath = `drafts/${orgId}/programs/${programId}/workouts`
          const { error: updateProgErr } = await updateFirebase(progPath, reorderedProgWkts)
          updateErrors = { ...updateErrors, updateProgErr }

          if (updateProgErr) {
            console.error('deleteWeek updateProgErr:', updateProgErr)
            return
          }
        }

        dispatch(draftWorkoutsDeleted({ workoutIds: weekWktIds }))

        const state = getState()
        const isDeletingCopiedWeek = state.program.weeksCopied.find(
          (week) => week.programId === programId && week.weekIdx === weekIdx
        )

        if (isDeletingCopiedWeek) {
          dispatch(copiedWeeksCleared())
        }

        await updateTabRowsOnDelete({ coachOrgId: orgId, itemIds: weekWktIds })
        await removeItemsInCollections({ coachOrgId: orgId, itemIds: weekWktIds })

        return { error: updateErrors }
      },
    }),
    createWorkout: build.mutation({
      queryFn: async ({ orgId, programId, workoutId, workout }) => {
        console.log('API createWorkout')
        const newWkt = getNewWorkout(workout.dayIdx, workoutId)
        const wktPath = `drafts/${orgId}/workouts/${workoutId}`
        const { error: setWktErr } = await setFirebase(wktPath, newWkt)
        if (setWktErr) {
          console.error('createWorkout setWktErr:', setWktErr)
          return
        }

        const progPath = `drafts/${orgId}/programs/${programId}/workouts/${workoutId}`
        const { error: setProgWktErr } = await setFirebase(progPath, newWkt.dayIdx)
        if (setProgWktErr) {
          console.error('createWorkout setProgWktErr:', setProgWktErr)
          return
        }

        return { error: { setWktErr, setProgWktErr } }
      },
    }),
    updateProgram: build.mutation({
      queryFn: async ({ coachOrgId, programId, program }) => {
        console.log('API updateProgram, programId:', programId, 'program:', program)
        const draftPath = `/drafts/${coachOrgId}/programs/${programId}`
        const { error: draftErr } = await updateFirebase(draftPath, program)

        const publishedPath = `/programs/${programId}`
        const { error: publishedErr } = await updateFirebase(publishedPath, program)

        return {
          status: !draftErr && !publishedErr ? 'success' : 'error',
          error: { draftErr, publishedErr },
        }
      },
    }),
    removeProgram: build.mutation({
      queryFn: async ({ coachOrgId, programId }, { getState }) => {
        try {
          const progPath = `drafts/${coachOrgId}/programs/${programId}`
          const { data: program } = await getFirebase(progPath)
          const programWkts = program?.workouts
          const programWktIds = programWkts ? Object.keys(programWkts) : []
          const weekWorkouts = await getWorkoutsByIds({ state: getState(), orgId: coachOrgId, wktIds: programWktIds })
          // Delete program contentTags
          await updateItemContentTags({
            orgId: coachOrgId,
            item: program,
            contentId: programId,
            contentType: 'program',
            updateType: 'remove',
          })
          // Delete workout contentTags
          await updateManyWorkoutContentTags({
            orgId: coachOrgId,
            workouts: weekWorkouts,
            programId,
            updateType: 'remove',
          })
          // Delete draft workouts of program
          await deleteDraftWktsOfProgram({ coachOrgId, programWkts })
          // Remove program and draft workouts from app layout draft, published and private rows
          await updateTabRowsOnDelete({ coachOrgId, itemIds: [programId, ...programWktIds] })
          await removeItemsInCollections({ coachOrgId, itemIds: [programId, ...programWktIds] })
          await removeProgramInProgramGroups({ coachOrgId, programId })
          await removeFirebase(`/drafts/${coachOrgId}/programs/${programId}`)
          await removeFirebase(`programs/${programId}`)
          // Remove from published programs
          await removeFirebase(`coachOrgs/${coachOrgId}/programs/${programId}`)
        } catch (e) {
          console.error('removeProgram error', e)
          return { error: e }
        } finally {
          return { status: 'success' }
        }
      },
    }),
    updateWorkouts: build.mutation({
      queryFn: async ({ orgId, workouts }) => {
        const path = `drafts/${orgId}/workouts`
        const { data, error } = await updateFirebase(path, workouts)
        return { data, error }
      },
    }),
    updateWorkout: build.mutation({
      queryFn: async ({ orgId, workoutId, workout }) => {
        console.log('API updateWorkout. dayIdx:', workout.dayIdx)
        const path = `drafts/${orgId}/workouts/${workoutId}`

        const { data, error } = await updateFirebase(path, workout)
        return { data, error }
      },
    }),
    cleanWorkout: build.mutation({
      queryFn: async ({ orgId, workoutId, workout }) => {
        console.log('API cleanWorkout. dayIdx:', workout.dayIdx)

        const cleanedWorkout = cleanWorkout(workout, false)
        const path = `drafts/${orgId}/workouts/${workoutId}`

        const { data, error } = await updateFirebase(path, cleanedWorkout)
        return { data, error }
      },
    }),
    deleteWorkout: build.mutation({
      queryFn: async ({ orgId, programId, workoutId, workout, dayIdx }, { dispatch }) => {
        console.log('API deleteWorkout, dayIdx:', dayIdx)
        console.log('API deleteWorkout, old workoutId:', workoutId)

        dispatch(draftWorkoutsDeleted({ workoutIds: [workoutId] }))

        //create new empty day
        const emptyDay = getNewEmptyWkt(dayIdx)
        const newWktId = emptyDay.id

        //save new day to drafts/orgId/workouts
        const newWktPath = `drafts/${orgId}/workouts/${newWktId}`
        const { error: newWktErr } = await setFirebase(newWktPath, emptyDay)

        //update program in one call so ProgramView doesn't render with 6 days in a week
        const fbProgramUpdate = {
          [workoutId]: null,
          [newWktId]: dayIdx,
        }

        const programWktsPath = `drafts/${orgId}/programs/${programId}/workouts`
        const { error: progWktsErr } = await updateFirebase(programWktsPath, fbProgramUpdate)

        //delete old draft workout
        const prevWktPath = `drafts/${orgId}/workouts/${workoutId}`
        const { error: prevWktErr } = await removeFirebase(prevWktPath)

        //delete workout content tags
        const { instrErr, equipmentErr, difficultyErr, tagsErr } = await updateItemContentTags({
          orgId,
          item: workout,
          contentId: workoutId,
          contentType: 'workout',
          programId,
          updateType: 'remove',
        })

        if (newWktErr || progWktsErr || prevWktErr || instrErr || equipmentErr || difficultyErr || tagsErr) {
          return {
            error: newWktErr || progWktsErr || prevWktErr || instrErr || equipmentErr || difficultyErr || tagsErr,
          }
        } else {
          return { status: 'success' }
        }
      },
    }),
    listenWorkoutExercises: build.query({
      queryFn: ({ orgId, workoutId, partIdx }) => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ orgId, workoutId, partIdx }, api) => {
        const path = `drafts/${orgId}/workouts/${workoutId}/parts/${partIdx}/exercises`
        listenFirebase(path, api)
      },
    }),
    setWorkoutExercise: build.mutation({
      queryFn: async ({ orgId, workoutId, partIdx, exIdx, exercise }) => {
        console.log('ProgramAPI setWorkoutExercise. exercise:', exercise)
        const path = `drafts/${orgId}/workouts/${workoutId}/parts/${partIdx}/exercises/${exIdx}`
        const { data, error } = await setFirebase(path, exercise)
        return { data, error }
      },
    }),
    updateWorkoutExercise: build.mutation({
      queryFn: async ({ orgId, workoutId, partIdx, exIdx, exercise }) => {
        console.log('ProgramAPI updateWorkoutExercise with', exercise)
        const path = `drafts/${orgId}/workouts/${workoutId}/parts/${partIdx}/exercises/${exIdx}`
        const { data, error } = await updateFirebase(path, exercise)
        return { data, error }
      },
    }),
    addPart: build.mutation({
      queryFn: async ({ orgId, workoutId, workout }) => {
        console.log('API addPart')
        const newPart = getNewPart()
        const newWkt = JSON.parse(JSON.stringify(workout))
        newWkt.parts.push(newPart)

        const path = `drafts/${orgId}/workouts/${workoutId}`
        const { data, error } = await updateFirebase(path, newWkt)
        return { data, error }
      },
    }),
    addRestPart: build.mutation({
      queryFn: async ({ orgId, workoutId, workout }) => {
        console.log('API addRestPart')
        const newPart = getNewRestPart()
        const newWkt = JSON.parse(JSON.stringify(workout))
        newWkt.parts.push(newPart)

        const path = `drafts/${orgId}/workouts/${workoutId}`
        const { data, error } = await updateFirebase(path, newWkt)
        return { data, error }
      },
    }),
    addRestDay: build.mutation({
      queryFn: async ({ orgId, workoutId, workout }) => {
        console.log('API addRestDay')

        const restDay = getNewRestDay(workout.dayIdx, workoutId)
        const path = `drafts/${orgId}/workouts/${workoutId}`
        const { data, error } = await updateFirebase(path, restDay)
        return { data, error }
      },
    }),
    addVideoDay: build.mutation({
      queryFn: async ({ orgId, workoutId, workout }) => {
        console.log('API addVideoDay')

        const videoDay = getNewVideoDay(workout.dayIdx, workoutId)
        const path = `drafts/${orgId}/workouts/${workoutId}`
        const { data, error } = await updateFirebase(path, videoDay)
        return { data, error }
      },
    }),
    reorderProgram: build.mutation({
      queryFn: async ({ orgId, programId, reorderedProgramWkts, fbUpdateWkts }) => {
        console.log('API reorderProgram with', reorderedProgramWkts)

        const progPath = `drafts/${orgId}/programs/${programId}/workouts`
        const { error: setProgramErr } = await setFirebase(progPath, reorderedProgramWkts)

        const wktsPath = `drafts/${orgId}/workouts`
        const { error: updateWktsErr } = await updateFirebase(wktsPath, fbUpdateWkts)

        return { status: 'success', error: { setProgramErr, updateWktsErr } }
      },
    }),
    savePartExercises: build.mutation({
      queryFn: async ({ orgId, workoutId, partIndex, exercises }) => {
        console.log('API savePartExercises')
        const path = `drafts/${orgId}/workouts/${workoutId}/parts/${partIndex}/exercises`
        const { data, error } = await setFirebase(path, exercises)
        return { status: data, error }
      },
    }),
    listenStandaloneWorkoutsId: build.query({
      queryFn: ({ coachOrgId }) => {
        return { data: false }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/soloWorkoutsProgramId`, api)
      },
    }),
    listenProgramResourceImg: build.query({
      queryFn: ({ coachOrgId, programId, resourceId }) => {
        return { data: false }
      },
      onCacheEntryAdded: async ({ coachOrgId, programId, resourceId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/programs/${programId}/programResources/${resourceId}/previewImg`, api)
      },
    }),
    listenProgramImg: build.query({
      queryFn: ({ programId }) => {
        return { data: false }
      },
      onCacheEntryAdded: async ({ programId }, api) => {
        listenFirebase(`programs/${programId}/previewImg`, api)
      },
    }),
    listenProgramIntroVideo: build.query({
      queryFn: ({ programId }) => {
        return { data: false }
      },
      onCacheEntryAdded: async ({ programId }, api) => {
        listenFirebase(`programs/${programId}/introVideo`, api)
      },
    }),
    listenDraftWorkoutImg: build.query({
      queryFn: ({ coachOrgId, workoutId }) => {
        return { data: false }
      },
      onCacheEntryAdded: async ({ coachOrgId, workoutId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/workouts/${workoutId}/previewImg`, api)
      },
    }),
    updatePart: build.mutation({
      queryFn: async ({ orgId, workoutId, partIdx, part }) => {
        console.log('API updatePart')
        const path = `drafts/${orgId}/workouts/${workoutId}/parts/${partIdx}`
        const { data, error } = await updateFirebase(path, part)
        return { status: data, error }
      },
    }),
    saveParts: build.mutation({
      queryFn: async ({ orgId, workoutId, parts }) => {
        console.log('API saveParts')
        const path = `drafts/${orgId}/workouts/${workoutId}/parts`
        const { data, error } = await setFirebase(path, parts)
        return { status: data, error }
      },
    }),
    updateWorkoutMetadata: build.mutation({
      queryFn: async ({ coachOrgId, workoutId, metadata }) => {
        console.log('API updateWorkoutMetadata')
        const { data, error } = await updateFirebase(`drafts/${coachOrgId}/workouts/${workoutId}`, metadata)
        return { status: data, error }
      },
    }),
    setDraftProgramUpdatedAt: build.mutation({
      queryFn: async ({ coachOrgId, programId, timestamp }) => {
        const path = `drafts/${coachOrgId}/programs/${programId}/updatedAt`
        const { data, error } = await setFirebase(path, timestamp)
        return { status: data, error }
      },
    }),
    listenDraftProgramUpdatedAt: build.query({
      queryFn: () => {
        return { data: { isFiller: true } }
      },
      onCacheEntryAdded: async ({ orgId, programId }, api) => {
        const path = `drafts/${orgId}/programs/${programId}/updatedAt`
        listenFirebase(path, api)
      },
    }),
    listenSavedBlocks: build.query({
      queryFn: () => ({ data: { isFiller: true } }),
      onCacheEntryAdded: async ({ orgId }, api) => {
        const path = `drafts/${orgId}/savedBlocks`
        listenFirebase(path, api)
      },
    }),
    saveBlock: build.mutation({
      queryFn: async ({ orgId, part }) => {
        const savedBlocksPath = `drafts/${orgId}/savedBlocks`
        const { partId, partData } = changeSavedPartIds(part)
        const { data: savedBlocks } = await getFirebase(savedBlocksPath)

        if (!savedBlocks || Object.keys(savedBlocks).length <= 100) {
          const { data, error } = await setFirebase(`${savedBlocksPath}/${partId}`, partData)
          return { status: data, error }
        } else {
          return { error: { message: 'You hit the limit. 100 saved blocks at max' } }
        }
      },
    }),
    removeSavedBlock: build.mutation({
      queryFn: async ({ orgId, blockId }) => {
        const path = `drafts/${orgId}/savedBlocks/${blockId}`
        const { data, error } = await removeFirebase(path)
        return { status: data, error }
      },
    }),
    listenBenchmarks: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ orgId }, api) => {
        const path = `coachOrgs/${orgId}/benchmarks`
        listenFirebase(path, api)
      },
    }),
    listenBenchmarkVideo: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ orgId, benchmarkId, exVidOrientation }, api) => {
        const vidKey = exVidOrientation === 'landscape' ? 'video' : 'portraitVid'
        const path = `coachOrgs/${orgId}/benchmarks/${benchmarkId}/${vidKey}`
        listenFirebase(path, api)
      },
    }),
    listenBenchmarkThumbnail: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ orgId, benchmarkId, exVidOrientation }, api) => {
        const thumbKey = exVidOrientation === 'landscape' ? 'thumbnail' : 'portraitThumbnail'
        const path = `coachOrgs/${orgId}/benchmarks/${benchmarkId}/${thumbKey}`
        listenFirebase(path, api)
      },
    }),
    createBenchmark: build.mutation({
      queryFn: async ({ orgId, benchmarkId, benchmark }) => {
        const path = `coachOrgs/${orgId}/benchmarks/${benchmarkId}`
        const { data, error } = await setFirebase(path, benchmark)
        return { status: data, error }
      },
    }),
    archiveBenchmark: build.mutation({
      queryFn: async ({ orgId, benchmarkId }) => {
        const path = `coachOrgs/${orgId}/benchmarks/${benchmarkId}/isArchived`
        const { data, error } = await setFirebase(path, true)
        return { status: data, error }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenOrgProgramsListQuery,
  useListenDraftProgramQuery,
  useInitDraftProgramMutation,
  useCreateProgramMutation,
  useDuplicateProgramMutation,
  useFetchDraftProgramQuery,
  useListenWorkoutQuery,
  useGetAllProgramWorkoutsQuery,
  useGetWorkoutsByIdsQuery,
  useLazyGetWorkoutsByIdsQuery,
  useListenWorkoutExercisesQuery,
  usePublishDraftProgramMutation,
  useUpdateProgramMutation,
  useRemoveProgramMutation,
  useCreateWorkoutMutation,
  useUpdateWorkoutsMutation,
  useUpdateWorkoutMutation,
  useCleanWorkoutMutation,
  useDeleteWorkoutMutation,
  useSetWorkoutExerciseMutation,
  useUpdateWorkoutExerciseMutation,
  useAddWeekMutation,
  useDeleteWeekMutation,
  useAddPartMutation,
  useAddRestPartMutation,
  useAddRestDayMutation,
  useAddVideoDayMutation,
  useCopyPasteWorkoutsMutation,
  useSavePartExercisesMutation,
  useUpdatePartMutation,
  useSavePartsMutation,
  useReorderProgramMutation,
  useListenStandaloneWorkoutsIdQuery,
  useListenProgramResourceImgQuery,
  useListenProgramImgQuery,
  useListenProgramIntroVideoQuery,
  useListenDraftWorkoutImgQuery,
  useUpdateWorkoutMetadataMutation,
  useSetDraftProgramUpdatedAtMutation,
  useListenDraftProgramUpdatedAtQuery,
  useListenSavedBlocksQuery,
  useSaveBlockMutation,
  useRemoveSavedBlockMutation,
  useListenBenchmarksQuery,
  useListenBenchmarkVideoQuery,
  useListenBenchmarkThumbnailQuery,
  useCreateBenchmarkMutation,
  useArchiveBenchmarkMutation,
} = programApi
