export const getNewLink = (id) => ({
  id,
  type: 'link',
  title: '',
  description: '',
  url: '',
  previewImg: '',
  openInDefaultBrowser: true,
  isPaid: false,
})
