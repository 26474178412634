import { useContext } from 'react'

import { AlertContext } from 'common/components/Alert/context'

export function useAlert() {
  const context = useContext(AlertContext)
  if (!context) {
    throw new Error('useAlert must be used within a AlertContext')
  }

  return context
}
