import React from 'react'
import 'twin.macro'

import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { TableCell } from './styles'

export function LoadingRow() {
  return (
    <tr className='h-24'>
      <TableCell tw='w-1/12'>
        <Skeleton tw='w-20 h-20 rounded-xl' />
      </TableCell>
      <TableCell tw='w-4/12'>
        <Skeleton tw='w-96' />
      </TableCell>
      <TableCell tw='w-4/12'>
        <Skeleton />
      </TableCell>
      <TableCell tw='w-2/12'>
        <Skeleton />
      </TableCell>
      <td className='relative'>
        <div />
      </td>
    </tr>
  )
}
