import React, { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import * as Accordion from '@radix-ui/react-accordion'
import { CgAdd, CgChevronDown, CgCloseO } from 'react-icons/cg'
import { findIndex } from 'lodash'

import { ExerciseInputList } from 'common/components/ExerciseInputList/ExerciseInputList'

import { ProgressionInput } from 'common/components/ProgressionInput/ProgressionInput'
import { useListenProgressionsQuery, useSaveProgressionMutation } from 'modules/Progressions/progressionsApi'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { Tooltip } from 'common/components/Tooltip/Tooltip'

export function ExerciseProgressionAccordion({
  coachOrgId,
  exerciseKey,
  isInProgression,
  progressions,
  progressionKey,
  exerciseList,
  accordionValue,
  setAccordionValue,
  inputRefsSortMethod,
  autoFocus,
}) {
  const progerssionNameRef = useRef()
  const { createAlert } = useAlert()
  const { setValue } = useFormContext()
  const { inputRefs } = useFormRefsControl()

  const { data: availableProgressions } = useListenProgressionsQuery({ coachOrgId })
  const [saveProgression] = useSaveProgressionMutation()

  useEffect(() => {
    if (accordionValue === 'Progressions' && isInProgression) {
      progerssionNameRef?.current?.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordionValue])

  const simpleExerciseList = {} //Remove unneeded data from the exercise list like form cues
  Object.entries(exerciseList).forEach(([exId, exercise], index) => {
    simpleExerciseList[exId] = { name: exercise?.name || '', date: exercise?.updatedAt || exercise?.createdAt }
  })

  const handleAddToProgression = async (progressionKey) => {
    if (!progressionKey) return

    const newExercises = [...(availableProgressions?.[progressionKey] || []), exerciseKey]
    await saveProgression({
      coachOrgId,
      progressionKey,
      exercises: newExercises,
    })
    setValue('exercises', newExercises)
    createAlert({ text: `Added to "${progressionKey}" progression!`, type: 'success' })
  }

  const handleMoveFocus = (e) => {
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()
      const activeIndex = findIndex(inputRefs, (input) => input.ref.current === document.activeElement)
      if (!e.shiftKey) {
        inputRefs[activeIndex + 1].ref.current.focus()
      } else if (e.shiftKey) {
        setAccordionValue('formCues')
      }
    }
  }

  const handleRemoveProgression = async () => {
    const progressions = [...availableProgressions[progressionKey]]
    const progressionsWithoutActiveEx = progressions.filter((val, idx) => {
      return val !== exerciseKey
    })
    await saveProgression({
      coachOrgId,
      progressionKey,
      exercises: progressionsWithoutActiveEx,
    })
    setValue('exercises', [])
    createAlert({ text: `Removed from "${progressionKey}" progression`, type: 'success' })
  }

  return (
    <Accordion.Root type='single' collapsible='true' value={accordionValue} onValueChange={setAccordionValue}>
      <Accordion.Item value='Progressions'>
        <Accordion.Header>
          <Accordion.Trigger type='button' className='flex items-center w-full group px-4 md:px-10 py-4' tabIndex={-1}>
            <h3 className='inline-flex cursor-pointer font-semibold text-tBlack my-2 text-left'>
              Progression{isInProgression ? `: ${progressionKey}` : null}
            </h3>
            {isInProgression ? (
              <div className='ml-auto flex items-center'>
                <Tooltip content='Remove progression' triggerClasses='ml-4 mr-2'>
                  <CgCloseO className='w-5 h-5 text-tBlack' onClick={handleRemoveProgression} />
                </Tooltip>
                <CgChevronDown className='w-6 h-6 group-radix-state-open:-rotate-180 transition-all' />
              </div>
            ) : (
              <>
                <CgAdd className='w-[22px] h-[22px] text-tBlack ml-auto group-radix-state-open:-rotate-180 group-radix-state-open:hidden' />
                <CgChevronDown className='w-6 h-6 ml-auto rotate-180 group-radix-state-closed:hidden' />
              </>
            )}
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          {isInProgression ? (
            <div className='pb-4'>
              <ExerciseInputList
                exerciseKey={exerciseKey}
                handleRemoveProgression={handleRemoveProgression}
                exerciseList={simpleExerciseList}
                progressionKey={progressionKey}
                progressions={progressions}
                inputRefsSortMethod={inputRefsSortMethod}
                autoFocus={autoFocus}
                setAccordionValue={setAccordionValue}
              />
            </div>
          ) : (
            <div className='pb-4'>
              <ProgressionInput
                value=''
                onSelect={handleAddToProgression}
                inputRefsSortMethod={inputRefsSortMethod}
                availableProgressions={availableProgressions}
                onKeyUp={handleMoveFocus}
                inputRef={progerssionNameRef}
                autoFocus={autoFocus}
              />
            </div>
          )}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}
