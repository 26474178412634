import { maybePluralize } from 'common/utils/stringUtils'

const setsRegex = /(\d+) *(set|round)(s)?/i

export const getInstrHasSets = (instructions) => {
  return setsRegex.test(instructions)
}

export const getNumSets = (instructions) => {
  let numSets = 1

  if (getInstrHasSets(instructions)) {
    const match = setsRegex.exec(instructions)
    numSets = Number(match[1]) || 1
  }

  return numSets
}

export const updateInstrForSets = ({ instr, numSets }) => {
  let instructions

  const instrHasSets = getInstrHasSets(instr)
  if (instrHasSets) {
    const match = setsRegex.exec(instr)
    const fullSetsText = match[0]
    const setOrRound = match[2]
    const isPlural = Number(numSets) > 1
    const replacement = `${numSets} ${setOrRound}${isPlural ? 's' : ''}`
    instructions = instr.replace(fullSetsText, replacement)
  } else {
    const unitsEnd = maybePluralize({
      count: numSets,
      text: 'set',
    })

    instructions = `${numSets} ${unitsEnd}`
  }

  console.log('instructions', instructions)

  return instructions
}
