import React from 'react'
import tw, { styled } from 'twin.macro'

export function Input({
  name,
  placeholder,
  type = 'text',
  register,
  onChange,
  value,
  inputRef,
  className,
  error,
  onKeyDown,
  onKeyUp,
  onFocus,
  hideRing,
  ...otherProps
}) {
  if (register) {
    const { ref, ...restInputRegister } = register(name)
    return (
      <StyledInput
        {...otherProps}
        ref={(e) => {
          ref(e)
          if (inputRef) inputRef.current = e
        }}
        {...restInputRegister}
        id={name}
        type={type}
        placeholder={placeholder}
        className={className}
        error={error}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        hideRing={hideRing}
      />
    )
  }

  return (
    <StyledInput
      {...otherProps}
      id={name}
      name={name}
      type={type}
      placeholder={placeholder}
      className={className}
      onChange={onChange}
      onFocus={onFocus}
      value={value}
      error={error}
      onKeyDown={onKeyDown}
      hideRing={hideRing}
    />
  )
}

export const inputBase = tw`flex h-14 w-full rounded-xl bg-white ring-1 border-0 ring-gray-300 
text-tBlack placeholder:text-tBlack placeholder:text-opacity-50 focus:border-0 focus:ring-tGreen focus-visible:outline-none px-4 py-4`

const StyledInput = styled.input(({ error, hideRing, disabled }) => [
  inputBase,
  hideRing && tw`ring-0 focus:ring-0`,
  error && tw`ring-tRed focus:ring-tRed`,
  disabled && tw`bg-gray-50`,
])
