import tw from 'twin.macro'
import React, { useState, useEffect, useRef } from 'react'
import { transform, isEqual, pick } from 'lodash'
import * as Accordion from '@radix-ui/react-accordion'
import { CgChevronDown, CgImage } from 'react-icons/cg'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useUpdateProgramGroupMutation, useListenProgramGroupImgQuery } from '../programGroupApi'

import { createUID } from 'common/utils/createUID'
import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { Input } from 'common/components/Input/Input'
import { Textarea } from 'common/components/Textarea/Textarea'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { DeleteConfirmationBanner } from 'common/components/DeleteConfirmationBanner/DeleteConfirmationBanner'
import { ProgramInputList } from './ProgramInputList/ProgramInputList'
import { schema } from './schema'
import { checkProgramGroupNameExists, removeEmptyPrograms } from './utils'
import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { ColorInput } from 'common/components/Color/ColorInput'
import { useEventListener } from 'common/hooks/useEventListener'
import { removeFileExtension } from 'common/utils/fileUploading/nameAndIdUtils'
import { TagInputsAccordion } from 'common/components/TagInputsAccordion/TagInputsAccordion'
import {
  useListenDifficultyQuery,
  useListenEquipmentQuery,
  useListenInstructorsQuery,
  useListenTagsQuery,
  useUpdateDifficultyContentMutation,
  useUpdateEquipmentContentMutation,
  useUpdateInstructorsContentMutation,
  useUpdateTagsContentMutation,
} from 'modules/ContentTags/contentTagsApi'
import { getTagsList } from 'modules/ContentTags/contentTagUtils'

const DIFFICULTY_LIMIT = 10
const EQUIPMENT_LIMIT = 10
const INSTRUCTORS_LIMIT = 4
const TAGS_LIMIT = 10

const Label = tw.label`inline-flex cursor-pointer font-semibold text-tBlack`
const InputError = tw.p`flex text-xs mt-1 text-tRed`

export function ProgramGroupForm({
  coachOrgId,
  programGroupKey,
  programGroup = {},
  availableProgramGroups,
  coachPrograms,
  deleteProgramGroup,
  primaryUIColorData,
}) {
  const [updateProgramGroup] = useUpdateProgramGroupMutation()
  const [updateInstructors] = useUpdateInstructorsContentMutation()
  const [updateDifficulty] = useUpdateDifficultyContentMutation()
  const [updateEquipment] = useUpdateEquipmentContentMutation()
  const [updateTags] = useUpdateTagsContentMutation()

  const noPrimaryUIColorData = !primaryUIColorData || primaryUIColorData?.isLoading
  const primaryUIColor = noPrimaryUIColorData ? '#000000' : primaryUIColorData

  const [, setDialogOpen] = useDialog()
  const { createAlert } = useAlert()

  const { data: difficultyData } = useListenDifficultyQuery({ coachOrgId })
  const difficultyTags = getTagsList(difficultyData)

  const { data: equipmentData } = useListenEquipmentQuery({ coachOrgId })
  const equipmentTags = getTagsList(equipmentData)

  const { data: instructorsData } = useListenInstructorsQuery({ coachOrgId })
  const instructorTags = getTagsList(instructorsData)

  const { data: tagsData } = useListenTagsQuery({ coachOrgId })
  const tags = getTagsList(tagsData)

  const [loading, setLoading] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [accordionValue, setAccordionValue] = useState(null)

  const isNewProgramGroup = !programGroupKey
  const [programGroupId, setProgramGroupId] = useState('')

  const { data: previewImg } = useListenProgramGroupImgQuery({ coachOrgId, programGroupId })
  const [isCreated, setIsCreated] = useState(false) //set if program group is created by uploading image

  useEffect(() => {
    if (programGroupKey) {
      setProgramGroupId(programGroupKey)
    } else {
      //need to set the state in order to maintain the newly created ID
      const newId = createUID()
      setProgramGroupId(newId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: programGroupId })

  const defaultValues = {
    name: programGroup.name || '',
    programs: programGroup.programs || [],
    previewImg: programGroup.previewImg || '',
    subtitle: programGroup.subtitle || '',
    description: programGroup.description || '',
    difficulty: programGroup?.difficulty || [],
    equipment: programGroup?.equipment || [],
    instructors: programGroup?.instructors || [],
    tags: programGroup?.tags || [],
    color: programGroup.color || primaryUIColor,
  }
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    context: { availableProgramIds: Object.keys(coachPrograms) },
  })
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    register,
    formState: { errors },
    handleSubmit,
  } = methods

  const formState = watch()
  const isFormDisabled = isEqual(formState, defaultValues)

  const isDifficultyLimitReached = formState.difficulty.length >= DIFFICULTY_LIMIT
  const isEquipmentLimitReached = formState.equipment.length >= EQUIPMENT_LIMIT
  const isInstructorsLimitReached = formState.instructors.length >= INSTRUCTORS_LIMIT
  const isTagsLimitReached = formState.tags.length >= TAGS_LIMIT

  const onSubmit = async (data) => {
    const seriesData = pick(data, [
      'name',
      'programs',
      'subtitle',
      'description',
      'difficulty',
      'equipment',
      'instructors',
      'tags',
      'color',
    ])
    const trimmedFormData = transform(
      seriesData,
      (acc, value, key) => {
        // remove empty strings from programs array
        if (key === 'programs') {
          return (acc[key] = value.filter((program) => program.trim() !== ''))
        }
        return (acc[key] = value)
      },
      {}
    )

    const programGroupNameExists = checkProgramGroupNameExists({
      programGroups: availableProgramGroups,
      programGroupName: trimmedFormData.name,
      programGroupKey: programGroupId,
    })

    if (programGroupNameExists) {
      setError('name', { type: 'manual', message: 'Series with this name already exists' }, { shouldFocus: true })
      return
    }

    trimmedFormData.programs = removeEmptyPrograms(trimmedFormData.programs)

    const alertText = isNewProgramGroup && !isCreated ? 'Series created!' : 'Series updated!'

    setLoading(true)
    try {
      await updateInstructors({
        coachOrgId,
        prevInstructors: defaultValues.instructors,
        currInstructors: seriesData.instructors,
        contentId: programGroupId,
        contentType: 'programGroup',
      })
      await updateDifficulty({
        coachOrgId,
        prevDifficulty: defaultValues.difficulty,
        currDifficulty: seriesData.difficulty,
        contentId: programGroupId,
        contentType: 'programGroup',
      })
      await updateEquipment({
        coachOrgId,
        prevEquipment: defaultValues.equipment,
        currEquipment: seriesData.equipment,
        contentId: programGroupId,
        contentType: 'programGroup',
      })
      await updateTags({
        coachOrgId,
        prevTags: defaultValues.tags,
        currTags: seriesData.tags,
        contentId: programGroupId,
        contentType: 'programGroup',
      })
      await updateProgramGroup({
        coachOrgId,
        programGroupKey: programGroupId,
        programGroup: { ...trimmedFormData, updatedAt: Date.now(), id: programGroupId },
      })
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    createAlert({ text: alertText, type: 'success' })
    setDialogOpen(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await deleteProgramGroup()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    setDialogOpen(false)
  }

  const createProgramGroupOnUpload = (event) => {
    if (isNewProgramGroup) {
      const file = event.target.files[0]
      if (file && file.name) {
        const pgName = formState.name || removeFileExtension(file.name)
        const progGroupData = {
          name: pgName,
        }

        updateProgramGroup({
          coachOrgId,
          programGroupKey: programGroupId,
          programGroup: { ...progGroupData, id: programGroupId },
        })

        const alertText = 'Creating Series...'
        createAlert({ text: alertText, type: 'success' })
        setIsCreated(true)
      }
    }
  }

  // refs control
  const { addInputRef, removeInputRef, moveFocusedInputBy, moveFocusOnKeyPress } = useFormRefsControl()
  const nameRef = useRef()
  const subtitleRef = useRef()
  const descriptionRef = useRef()
  const difficultyRef = useRef()
  const equipmentRef = useRef()
  const instructorsRef = useRef()
  const tagsRef = useRef()
  const submitRef = useRef()
  const inputRefsSortMethod = [
    'name',
    'program',
    'subtitle',
    'description',
    'difficulty',
    'equipment',
    'instructors',
    'tags',
    'submit',
  ]

  useEffect(() => {
    addInputRef({ ref: nameRef, posIdx: 0, name: 'name', sortMethod: inputRefsSortMethod })
    addInputRef({
      ref: subtitleRef,
      posIdx: formState.programs.length + 1,
      name: 'subtitle',
      sortMethod: inputRefsSortMethod,
    })
    addInputRef({
      ref: descriptionRef,
      posIdx: formState.programs.length + 2,
      name: 'description',
      sortMethod: inputRefsSortMethod,
    })
    addInputRef({
      ref: submitRef,
      posIdx: formState.programs.length + 4,
      name: 'submit',
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const moveNameFocusOnKeyPress = (e) => {
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()
      if (e.shiftKey && isFormDisabled) {
        setAccordionValue('tagInputs')
        setTimeout(() => {
          tagsRef.current.focus()
        }, 50)
      } else if (e.shiftKey) {
        moveFocusedInputBy(-1)
      } else if (!e.shiftKey && accordionValue !== 'formPrograms') {
        setAccordionValue('formPrograms')
      } else if (!e.shiftKey) {
        moveFocusedInputBy(1)
      }
    }
  }

  const moveSubtitleFocusOnKeyPress = (e) => {
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()
      if (e.shiftKey && accordionValue !== 'formPrograms') {
        setAccordionValue('formPrograms')
      } else if (e.shiftKey) {
        moveFocusedInputBy(-1)
      } else if (!e.shiftKey) {
        moveFocusedInputBy(1)
      }
    }
  }

  const moveTagFocusOnKeyPress = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const isTagInputEmpty = e.target.value.trim() === ''
    if (e.code === 'Enter' || (!isTagInputEmpty && e.code === 'Tab')) {
      return
    }

    if (e.shiftKey) {
      moveFocusedInputBy(-1)
    } else if (!e.shiftKey) {
      moveFocusedInputBy(1)
    }
  }

  useEventListener('keydown', (e) => {
    if (
      e.target.name === 'instructors' ||
      e.target.name === 'equipment' ||
      e.target.name === 'tags' ||
      e.target.name === 'difficulty'
    ) {
      if (e.code === 'Enter' || e.code === 'Tab') {
        moveTagFocusOnKeyPress(e)
      } else {
        if (e.target.name === 'difficulty' && isDifficultyLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
        if (e.target.name === 'equipment' && isEquipmentLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
        if (e.target.name === 'instructors' && isInstructorsLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
        if (e.target.name === 'tags' && isTagsLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
      }
    } else {
      moveFocusOnKeyPress(e, handleSubmit(onSubmit))
    }
  })

  useEffect(() => {
    if (accordionValue === 'tagInputs') {
      addInputRef({
        ref: difficultyRef,
        posIdx: formState.programs.length + 3,
        name: 'difficulty',
        sortMethod: inputRefsSortMethod,
      })
      addInputRef({
        ref: equipmentRef,
        posIdx: formState.programs.length + 4,
        name: 'equipment',
        sortMethod: inputRefsSortMethod,
      })
      addInputRef({
        ref: instructorsRef,
        posIdx: formState.programs.length + 5,
        name: 'instructors',
        sortMethod: inputRefsSortMethod,
      })
      addInputRef({
        ref: tagsRef,
        posIdx: formState.programs.length + 6,
        name: 'tags',
        sortMethod: inputRefsSortMethod,
      })
    } else {
      removeInputRef(difficultyRef, 'difficulty')
      removeInputRef(equipmentRef, 'equipment')
      removeInputRef(instructorsRef, 'instructors')
      removeInputRef(tagsRef, 'tags')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordionValue])

  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        {/* <DialogPrimitive.Description className='flex items-center text-tBlack text-opacity-70 py-3 px-10'>
          <CgInfo className='w-5 h-5 mr-2' /> [Some tips will go here]
        </DialogPrimitive.Description> */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          id='programGroupForm'
          name='Series form'
          onKeyDown={(e) => {
            if (e.code === 'Enter' && e.target.type !== 'textarea') {
              e.preventDefault() //Otherwise form autosubmits on each enter press
            }
          }}
        >
          <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
            <TextInput
              name='name'
              label='Name'
              placeholder='Enter series name'
              register={register}
              inputRef={nameRef}
              errors={errors}
              autoFocus={true}
              onKeyDown={moveNameFocusOnKeyPress}
            />
          </div>
          <ProgramInputAccordion
            accordionValue={accordionValue}
            setAccordionValue={setAccordionValue}
            coachPrograms={coachPrograms}
            programGroupKey={programGroupId}
            inputRefsSortMethod={inputRefsSortMethod}
          />
          <div className='flex flex-col px-10 py-4 border-t border-gray-200'>
            <TextInput
              name='subtitle'
              label='Subtitle'
              placeholder='Enter subtitle'
              type='text'
              register={register}
              inputRef={subtitleRef}
              onKeyDown={moveSubtitleFocusOnKeyPress}
            />
            {errors?.subtitle ? <InputError>{errors.subtitle.message}</InputError> : null}
            <TextInput
              name='description'
              label='Description'
              placeholder='Enter description'
              type='textarea'
              onKeyDown={(e) => {
                if (e.code === 'Tab' && !e.shiftKey && accordionValue !== 'tagInputs') {
                  setAccordionValue('tagInputs')
                  setTimeout(() => {
                    equipmentRef.current.focus()
                  }, 50)
                }
              }}
              register={register}
              inputRef={descriptionRef}
            />
            {errors?.description ? <InputError>{errors.description.message}</InputError> : null}
          </div>
          <TagInputsAccordion
            accordionValue={accordionValue}
            setAccordionValue={setAccordionValue}
            formState={formState}
            register={register}
            difficultyRef={difficultyRef}
            equipmentRef={equipmentRef}
            instructorsRef={instructorsRef}
            tagsRef={tagsRef}
            difficultyTags={difficultyTags}
            equipmentTags={equipmentTags}
            instructorTags={instructorTags}
            tags={tags}
            setValue={setValue}
            isDifficultyLimitReached={isDifficultyLimitReached}
            isEquipmentLimitReached={isEquipmentLimitReached}
            isInstructorsLimitReached={isInstructorsLimitReached}
            isTagsLimitReached={isTagsLimitReached}
            rootClasses='border-t'
          />
          <div className='flex flex-col px-10 py-4'>
            <UploadInput
              name='previewImg'
              label='Cover image'
              register={register}
              setValue={setValue}
              coachOrgId={coachOrgId}
              id={programGroupId}
              uploadType='program-group-image'
              liveUrl={previewImg || programGroup?.previewImg}
              setError={setError}
              onUpload={isNewProgramGroup ? createProgramGroupOnUpload : null}
              uploadDisabled={!formState.name}
              previewIcon={<CgImage className='!w-5 !h-5' />}
              clearErrors={clearErrors}
              fileType='image'
            />
            {errors?.previewImg ? <InputError>{errors.previewImg.message}</InputError> : null}
            <ColorInput watchColor={formState.color} setValue={setValue} label='Color' />
          </div>
        </form>
      </div>
      {!isNewProgramGroup && deleteConfirmation ? (
        <DeleteConfirmationBanner
          text='Are you sure?'
          handleDelete={handleDelete}
          handleGoBack={() => setDeleteConfirmation(false)}
          loading={loading}
        />
      ) : (
        <UpdateActions
          itemKey={programGroupId}
          loading={loading}
          disabled={isEqual(formState, defaultValues)}
          handleDelete={() => setDeleteConfirmation(true)}
          hideDelete={!programGroupKey}
          deleteDisabled={isUploadingAssets(assetDrafts)}
          disabledReason='Please wait for media upload to complete'
          actionText={'Save'}
          form='programGroupForm'
          ref={submitRef}
          onKeyDown={(e) => {
            if (
              (e.code === 'Enter' || e.code === 'Tab') &&
              e.shiftKey &&
              accordionValue !== 'tagInputs' &&
              !isFormDisabled
            ) {
              setAccordionValue('tagInputs')
              setTimeout(() => {
                tagsRef.current.focus()
              }, 50)
            }
          }}
        />
      )}
    </FormProvider>
  )
}

const TextInput = ({
  name,
  label,
  placeholder,
  type,
  register,
  inputRef,
  errors,
  onKeyDown,
  onFocus,
  autoFocus = false,
}) => (
  <div className='mb-2'>
    <Label htmlFor={name} tw='mb-1'>
      {label}
    </Label>
    {type === 'textarea' ? (
      <Textarea
        name={name}
        placeholder={placeholder}
        register={register}
        inputRef={inputRef}
        error={errors?.[name]?.message}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
    ) : (
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        register={register}
        inputRef={inputRef}
        error={errors?.[name]?.message}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
    )}

    {errors && errors[name] && <InputError>{errors[name].message}</InputError>}
  </div>
)

const ProgramInputAccordion = ({
  accordionValue,
  setAccordionValue,
  coachPrograms,
  programGroupId,
  inputRefsSortMethod,
}) => (
  <Accordion.Root type='single' value={accordionValue} onValueChange={setAccordionValue} collapsible='true'>
    <Accordion.Item value='formPrograms'>
      <Accordion.Header>
        <Accordion.Trigger type='button' className='flex items-center w-full group px-10 py-4' tabIndex={-1}>
          <label htmlFor='programs' className='inline-flex cursor-pointer font-semibold text-tBlack my-2 text-left'>
            Programs
          </label>
          <CgChevronDown className='w-6 h-6 ml-auto group-radix-state-open:-rotate-180 transition-all' />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <div className='pb-4'>
          <ProgramInputList
            availablePrograms={coachPrograms}
            programGroupKey={programGroupId}
            inputRefsSortMethod={inputRefsSortMethod}
          />
        </div>
      </Accordion.Content>
    </Accordion.Item>
  </Accordion.Root>
)
