import { useState, useEffect } from 'react'

function getContainerWidthById(containerId) {
  const element = document.getElementById(containerId)
  if (!element) return null

  const { width } = element.getBoundingClientRect()

  return width
}

export function useContainerWidth({ containerId }) {
  const [containerWidth, setContainerWidth] = useState(0)

  const handleResize = () => {
    const width = getContainerWidthById(containerId)

    if (width !== containerWidth) {
      setContainerWidth(width)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize() //run once at start to set containerWidth

    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return containerWidth
}
