export function CardDragOverlay({ activeDragItem }) {
  return (
    <div className='flex flex-col ring-1 p-4 ring-gray-200 bg-white shadow-xl rounded-lg overflow-hidden'>
      <h4 className='capitalize font-medium text-sm truncate'>
        <span className='mr-1 whitespace-nowrap'>{activeDragItem.index + 1}.</span>
        <span className='truncate ml-1'>{activeDragItem.title}</span>
      </h4>
    </div>
  )
}
