import { globalApi, listenFirebase, removeFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'

export const progressionsApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenProgressions: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        await listenFirebase(`coachOrgs/${coachOrgId}/exSubs`, api)
      },
    }),
    createProgression: build.mutation({
      queryFn: async ({ coachOrgId, progressionKey, exercises }) => {
        const { data, error } = await setFirebase(`coachOrgs/${coachOrgId}/exSubs/${progressionKey}`, exercises)
        return { data, error }
      },
    }),
    saveProgression: build.mutation({
      queryFn: async ({ coachOrgId, progressionKey, exercises }) => {
        const { data, error } = await setFirebase(`coachOrgs/${coachOrgId}/exSubs/${progressionKey}`, exercises)
        return { data, error }
      },
    }),
    updateProgressions: build.mutation({
      queryFn: async ({ coachOrgId, progressions }) => {
        const { data, error } = await updateFirebase(`coachOrgs/${coachOrgId}/exSubs`, progressions)
        return { data, error }
      },
    }),
    removeProgression: build.mutation({
      queryFn: async ({ coachOrgId, progressionKey }) => {
        const { data, error } = await removeFirebase(`coachOrgs/${coachOrgId}/exSubs/${progressionKey}`)
        return { data, error }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenProgressionsQuery,
  useCreateProgressionMutation,
  useSaveProgressionMutation,
  useUpdateProgressionsMutation,
  useRemoveProgressionMutation,
} = progressionsApi
