import { each } from 'lodash'

import { createUID } from 'common/utils/createUID'
import { cleanDraftProgramForPublishing } from 'modules/Programs/utils/programUtils'

export const getSampleProgramWkts = (sampleWktsData, newSampleExIdByOrigExId) => {
  const sampleWorkouts = {}
  const newWktIdsToDayIdcs = {}

  each(sampleWktsData, (wkt) => {
    const updatedWkt = { ...wkt, parts: [], id: createUID() }

    each(wkt.parts, (part) => {
      const updatedPart = { ...part, exercises: [], id: createUID() }

      each(part.exercises, (ex) => {
        const updatedExId = ex.id === 'rest' ? 'rest' : newSampleExIdByOrigExId[ex.id]
        updatedPart.exercises.push({ ...ex, dndUID: createUID(), id: updatedExId })
      })

      updatedWkt.parts.push(updatedPart)
    })

    sampleWorkouts[updatedWkt.id] = updatedWkt
    newWktIdsToDayIdcs[updatedWkt.id] = updatedWkt.dayIdx
  })

  return { sampleWorkouts, newWktIdsToDayIdcs }
}

export const getSampleProgram = (sampleProgramData, wktIdsToDayIdcs, programWkts) => {
  const updatedProgram = {
    ...sampleProgramData,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    publishedAt: null,
    id: createUID(),
    workouts: wktIdsToDayIdcs,
    workoutsV2: cleanDraftProgramForPublishing(programWkts),
  }

  return updatedProgram
}
