import tw from 'twin.macro'
import React, { useRef, useState } from 'react'

import { closestCenter, DndContext, DragOverlay, useSensors, useSensor } from '@dnd-kit/core'
import { dropAnimation, MouseSensor, TouchSensor } from 'common/utils/dndUtils'
import { SortableContext, arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToHorizontalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'

import { useSetTabContentMutation } from 'modules/Layout/layoutApi'

import { ScrollArea } from 'common/components/ScrollArea/ScrollArea'
import { Sortable } from 'common/components/Sortable/Sortable'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { AddItemButton } from 'common/components/AddItemButton/AddItemButton'
import { WorkoutCard } from '../LayoutCards/WorkoutCard'
import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'
import { ProgramCard } from '../LayoutCards/ProgramCard'
import { ProgramGroupCard } from '../LayoutCards/ProgramGroupCard'
import { VideoCard } from '../LayoutCards/VideoCard'
import { CollectionCard } from '../LayoutCards/CollectionCard'
import { LayoutRowHeader } from './LayoutRowHeader'
import { RowScrollActions } from './RowScrollActions'
import { cardContainerBaseStyles, cardHoverStyles, CardRowContainer } from '../styles'

const ROW_ITEMS_CAP = 50

export const LayoutRow = React.memo(
  ({ id, currentRow, currentTabContent, coachOrgId, tabId, isDragging, attributes, listeners }) => {
    const { createAlert } = useAlert()
    const scrollAreaRef = useRef(null)
    const [saveTabContent] = useSetTabContentMutation()

    const itemCapExceeded = currentRow.items ? currentRow.items.length >= ROW_ITEMS_CAP : false

    const handleDeleteItem = async (item) => {
      const updatedCurrentRowItems = currentRow.items.filter((rowItem) => rowItem.id !== item.id)

      const updatedCurrentTabContent = currentTabContent.map((row) => {
        if (row.id === currentRow.id) {
          return { ...currentRow, items: updatedCurrentRowItems }
        }
        return row
      })

      await saveTabContent({ coachOrgId, tabContent: updatedCurrentTabContent, tabId })

      let itemTypeReadable = item.type.toLowerCase()
      if (itemTypeReadable === 'programgroup') {
        itemTypeReadable = 'series'
      }

      createAlert({ text: `${itemTypeReadable} removed`, type: 'success' })
    }

    // Drag and drop
    const sensors = useSensors(
      useSensor(MouseSensor),
      useSensor(TouchSensor, {
        activationConstraint: {
          delay: 250,
          tolerance: 5,
        },
      })
    )
    const [activeDragCard, setActiveDragCard] = useState(null)
    const handleDragStart = ({ active }) => {
      setActiveDragCard(currentRow.items.find((item) => active.id === item.id))
    }

    const handleDragEnd = async (event) => {
      const { active, over } = event
      if (active.id !== over.id) {
        const oldIndex = currentRow.items.findIndex((item) => item.id === active.id)
        const newIndex = currentRow.items.findIndex((item) => item.id === over.id)

        const updatedCurrentRowItems = arrayMove(currentRow.items, oldIndex, newIndex)
        const updatedCurrentTabContent = currentTabContent.map((row) => {
          if (row.id === currentRow.id) {
            return { ...currentRow, items: updatedCurrentRowItems }
          }
          return row
        })
        await saveTabContent({ coachOrgId, tabContent: updatedCurrentTabContent, tabId })
        setActiveDragCard(null)
      }
    }

    const handleAdd = async (currentItem) => {
      const updatedTabContent = currentTabContent.map((item) => {
        if (item.id === currentRow.id) {
          const items = currentRow.items ? [currentItem, ...currentRow.items] : [currentItem]
          const updateditem = { ...currentRow, items }

          return updateditem
        }

        return item
      })

      let itemTypeReadable = currentItem.type.toLowerCase()
      if (itemTypeReadable === 'programgroup') {
        itemTypeReadable = 'series'
      }

      await saveTabContent({ coachOrgId, tabContent: updatedTabContent, tabId })
      createAlert({ text: `${itemTypeReadable} added`, type: 'success' })
    }

    return (
      <div css={[tw`rounded-xl bg-white`, isDragging && tw`border border-gray-200`]} data-testid='layout-row'>
        <LayoutRowHeader
          currentTabContent={currentTabContent}
          currentRow={currentRow}
          dragHandleProps={{ attributes, listeners }}
          coachOrgId={coachOrgId}
          tabId={tabId}
        />
        <RowScrollActions id={id} scrollAreaRef={scrollAreaRef} currentRow={currentRow} />
        <ScrollArea css={tw`max-h-full`} id={id} ref={scrollAreaRef}>
          <CardRowContainer>
            {itemCapExceeded ? (
              <div
                css={[
                  cardContainerBaseStyles,
                  tw`font-bold text-sm text-tBlack text-opacity-70 items-center justify-center bg-offWhite`,
                ]}
              >
                {`${ROW_ITEMS_CAP} item limit reached`}
              </div>
            ) : (
              <div css={[cardContainerBaseStyles, tw`items-center justify-center bg-offWhite`]} data-testid='add-card'>
                <AddItemButton coachOrgId={coachOrgId} existingItems={currentRow?.items} handleAdd={handleAdd} />
              </div>
            )}
            {currentRow.items && (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                <SortableContext items={currentRow.items} strategy={horizontalListSortingStrategy}>
                  {currentRow.items.map((item) => (
                    <Sortable
                      key={item.id}
                      id={item.id}
                      css={cardHoverStyles}
                      className='cursor-grab mr-6 last:mr-0'
                      draggingClasses='opacity-50 ring-2 ring-tGreen ring-offset-2 rounded-xl'
                    >
                      <CardRender item={item} handleDelete={handleDeleteItem} />
                    </Sortable>
                  ))}
                </SortableContext>
                <DragOverlay
                  zIndex={10}
                  className='cursor-grabbing'
                  dropAnimation={dropAnimation}
                  modifiers={[restrictToHorizontalAxis, restrictToFirstScrollableAncestor]}
                >
                  {activeDragCard && <CardRender item={activeDragCard} />}
                </DragOverlay>
              </DndContext>
            )}
          </CardRowContainer>
        </ScrollArea>
      </div>
    )
  }
)

export function CardRender(props) {
  if (props.item.type === availableItemTypes.PROGRAMGROUP) {
    return <ProgramGroupCard {...props} />
  }

  if (props.item.type === availableItemTypes.PROGRAM) {
    return <ProgramCard {...props} />
  }

  if (props.item.type === availableItemTypes.WORKOUT) {
    return <WorkoutCard {...props} />
  }

  if (props.item.type === availableItemTypes.VIDEO) {
    return <VideoCard {...props} />
  }

  if (props.item.type === availableItemTypes.COLLECTION) {
    return <CollectionCard {...props} />
  }

  return null
}
