import React, { useEffect, useRef } from 'react'

const DetectOutsideClick = ({ isOpen, setIsOpen, children, className }) => {
  const ref = useRef()

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpen, setIsOpen])

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  )
}

export default DetectOutsideClick
