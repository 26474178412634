import tw from 'twin.macro'

import React, { useEffect, useRef } from 'react'
import { CgArrowsVAlt, CgClose } from 'react-icons/cg'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { getSentenceCaseText } from 'common/utils/stringUtils'

export const CueInput = React.memo(
  ({
    register,
    setValue,
    index,
    value,
    remove,
    formCueValues,
    attributes,
    listeners,
    onKeyUp,
    onKeyDown,
    onFocus,
    onBlur,
    inputRefsSortMethod,
    autoFocus,
  }) => {
    const inputRef = useRef(null)
    const { addInputRef, removeInputRef } = useFormRefsControl()

    const { ref, ...restInputRegister } = register ? register(`cues.${index}`) : {}

    useEffect(() => {
      addInputRef({
        ref: inputRef,
        posIdx: index + 1,
        name: 'cue',
        cueIdx: index,
        sortMethod: inputRefsSortMethod,
      })
      if (autoFocus && index === 0) inputRef.current?.focus()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <div className='relative flex items-center'>
        {formCueValues.length > 1 && value.length !== 0 && (
          <div
            className='group-hover:visible group-hover:text-opacity-50 hover:!text-opacity-80 -left-3 md:-left-5 -translate-y-1/2
            invisible text-opacity-0 absolute top-1/2 text-tBlack transition-all duration-200 cursor-move'
            {...attributes}
            {...listeners}
            tabIndex={-1}
          >
            <CgArrowsVAlt className='w-5 h-5' />
          </div>
        )}
        {/* If input is not used as dnd overlay, register it, otherwise do not to avoid conflicts*/}
        <div className='text-gray-400 font-semibold ml-3 w-6'>{index + 1}.</div>
        {register ? (
          <input
            ref={(e) => {
              ref(e)
              inputRef.current = e
            }}
            {...restInputRegister}
            onChange={(e) => {
              const sentenceCaseText = getSentenceCaseText(e.target.value)
              setValue(`cues.${index}`, sentenceCaseText)
            }}
            type='text'
            autoComplete='off'
            aria-label={`cues.${index}`}
            placeholder={`Enter cue ${index + 1}`}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            css={[exInputClasses]}
            autoFocus={true}
          />
        ) : (
          <input
            type='text'
            autoComplete='off'
            placeholder={`Enter cue ${index + 1}`}
            defaultValue={value}
            className='text-ellipsis bg-inherit cursor-pointer'
            css={[exInputClasses]}
          />
        )}
        <button
          type='button'
          aria-label='remove cue'
          onClick={() => {
            remove(index)
            removeInputRef(inputRef, 'cue')
          }}
          className='group-hover:visible group-hover:text-opacity-50 hover:!text-opacity-80 -right-1 md:-right-6 -translate-y-1/2
          invisible text-opacity-0 absolute top-1/2 ml-2 text-tBlack transition-all duration-200 cursor-pointer'
          tabIndex={-1}
        >
          <CgClose />
        </button>
      </div>
    )
  }
)

const exInputClasses = tw`font-medium focus:ring-0 p-2 w-full mr-4 md:mr-0 
border-none overflow-hidden whitespace-nowrap placeholder:text-gray-400
hover:bg-gray-100 hover:bg-opacity-70 focus:bg-gray-100 focus:bg-opacity-70 rounded`
