import React, { useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensors, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { find, findIndex, trim } from 'lodash'

import { Sortable } from 'common/components/Sortable/Sortable'
import { ProgramInput } from './components/ProgramInput'
import { dropAnimation } from 'common/utils/dndUtils'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'

export function ProgramInputList({ availablePrograms, programGroupKey, inputRefsSortMethod }) {
  const [newEx, setNewEx] = useState(false)
  const [addingWithSelect, setAddingWithSelect] = useState({})
  const { watch, setValue, setFocus } = useFormContext()
  const { moveFocusedInputBy, inputRefs, moveInputRefs } = useFormRefsControl()

  const { fields, append, update, remove } = useFieldArray({
    name: 'programs',
  })
  const watchPrograms = watch('programs')
  const controlledFields = fields.map((field, index) => {
    return {
      id: field.id,
      value: watchPrograms[index],
    }
  })
  useEffect(() => {
    if (!programGroupKey && !watchPrograms.length) {
      append('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Drag and drop
  const [activeDragInput, setActiveDragInput] = useState(null)

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const handleDragStart = ({ active }) => {
    const activeFieldIndex = controlledFields.findIndex((field) => field.id === active.id)
    const activeField = controlledFields[activeFieldIndex]
    setActiveDragInput({ index: activeFieldIndex, ...activeField })
  }

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = controlledFields.findIndex((field) => field.id === active.id)
      const newIndex = controlledFields.findIndex((field) => field.id === over.id)

      const newProgramList = arrayMove(
        controlledFields.map((field) => field.value),
        oldIndex,
        newIndex
      )
      setValue('programs', newProgramList)
      moveInputRefs('program', oldIndex, newIndex)

      setActiveDragInput(null)
    }
  }

  const addNewItem = () => {
    append('')
    setNewEx(true)
  }

  const moveFocusOnKeyPress = (e) => {
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()
      const activeIndex = findIndex(inputRefs, (input) => input.ref.current === document.activeElement)
      const programIdx = inputRefs[activeIndex].programIdx

      if (!e.shiftKey) {
        if (!addingWithSelect[programIdx - 1]) {
          const nextExInput = find(inputRefs, (input) => input.programIdx === programIdx + 1)
          const activeExInputEmpty = trim(find(inputRefs, (input) => input.programIdx === programIdx).ref.current.value)
          if (!activeExInputEmpty || nextExInput) {
            moveFocusedInputBy(1)
          } else {
            addNewItem()
          }
        }
        setAddingWithSelect((state) => ({
          ...state,
          [programIdx === 0 ? programIdx : programIdx - 1]: false,
        }))
      } else if (e.shiftKey) {
        moveFocusedInputBy(-1)
      }
    }
  }

  useEffect(() => {
    if (newEx) {
      setFocus(`programs.${controlledFields.length - 1}`)
      setNewEx(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEx])

  useEffect(() => {
    const newAddingWithSelect = watchPrograms.reduce((acc, _, index) => ({ ...acc, [index]: false }), {})
    setAddingWithSelect(newAddingWithSelect)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={controlledFields} strategy={verticalListSortingStrategy}>
          {controlledFields.map((field, index) => (
            <Sortable
              key={field.id}
              id={field.id}
              withHandle={true}
              className='group relative flex flex-col mb-3 px-10'
              draggingClasses='ring-2 ring-tGreen ring-offset-4 opacity-50'
            >
              <ProgramInput
                index={index}
                value={field.value}
                setValue={(value) => setValue(`programs.${index}`, value)}
                remove={remove}
                availablePrograms={availablePrograms}
                onKeyDown={moveFocusOnKeyPress}
                onFocus={() => {
                  setAddingWithSelect((state) => ({ ...state, [index]: false }))
                }}
                addNewItem={addNewItem}
                setAddingWithSelect={setAddingWithSelect}
                isLastProgram={index === watchPrograms.length - 1}
                inputRefsSortMethod={inputRefsSortMethod}
              />
            </Sortable>
          ))}
        </SortableContext>
        <DragOverlay
          zIndex={10}
          className='cursor-move'
          dropAnimation={dropAnimation}
          modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}
        >
          {activeDragInput && (
            <div className='relative flex flex-col group px-10 bg-gray-100'>
              <ProgramInput
                isDragOverlay={true}
                index={activeDragInput.index}
                value={activeDragInput.value}
                update={update}
                remove={remove}
                availablePrograms={availablePrograms}
              />
            </div>
          )}
        </DragOverlay>
      </DndContext>
      <button
        type='button'
        className='text-tBlack text-opacity-50 hover:text-opacity-80 transition-all ml-11 hover:bg-gray-100 p-2 rounded-md'
        onClick={() => append('')}
      >
        &#43; Add program
      </button>
    </>
  )
}
