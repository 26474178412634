import tw from 'twin.macro'
import React from 'react'

import Tag from 'common/components/Tag/Tag'
import { ScrollArea } from 'common/components/ScrollArea/ScrollArea'

export function ProgramRowList({ rowsWithProgram, programGroups }) {
  return (
    <div className='mb-6 rounded-lg overflow-hidden border-gray-200 border-opacity-75 border-2'>
      <ScrollArea css={tw`max-h-72 py-2 px-3`}>
        <ul className='text-sm font-semibold'>
          {rowsWithProgram.map((row, idx) => (
            <li key={idx} className='flex flex-col mb-2 last:mb-0' data-testid='programRowListItem'>
              <div className='flex items-end'>
                <div className='flex mr-4'>
                  <span className='min-w-[20px]'>{`${idx + 1}.`}</span>
                  {row.name}
                </div>
                <Tag
                  text={row.tabName}
                  tw='self-start m-0 text-xs rounded-md capitalize whitespace-nowrap'
                  data-testid='listItemTag'
                />
              </div>
              {row.programGroupId && (
                <div className='text-gray-500 text-xs font-normal ml-5'>
                  Part of the <strong className='capitalize'>{programGroups[row.programGroupId].name}</strong> Series
                </div>
              )}
            </li>
          ))}
        </ul>
      </ScrollArea>
    </div>
  )
}
