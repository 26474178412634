import { capitalizeFirstLetter } from 'common/utils/stringUtils'
import { getAnswersRequiringAction } from './flowDataUtils'

export const getMissingDataWarningText = ({ questionTitle, answers, getNode }) => {
  let warnings = []

  const answersRequiringAction = getAnswersRequiringAction({ answers, getNode })
  const questionRequiresTitle = (questionTitle || '').trim().length === 0

  if (questionRequiresTitle) {
    warnings.push('question title needed')
  }

  if (answersRequiringAction.length > 1) {
    warnings.push('answers not completed')
  }

  if (answersRequiringAction.length === 1) {
    warnings.push('answer not completed')
  }

  const warningText = warnings.join(', ')
  return capitalizeFirstLetter(warningText)
}
