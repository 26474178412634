import { useContext } from 'react'

import { CustomizationContext } from './context'

export function useCustomization() {
  const context = useContext(CustomizationContext)
  if (!context) {
    throw new Error('useCustomization must be used within a CustomizationContext')
  }

  return context
}
