import { map } from 'lodash'

const transfPrefixes = {
  width: 'w_',
  height: 'h_',
  crop: 'c_',
  gravity: 'g_',
  radius: 'r_',
  quality: 'q_',
  bitrate: 'br_',
  dpr: 'dpr_',
}

export const getCloudinaryVidPreviewImg = (url, transformations) => {
  if (!url || !url.includes('cloudinary') || !url.includes('.mp4')) {
    return ''
  }

  let imgUrl = url.replace('.mp4', '.jpg')

  const insertIdx = imgUrl.indexOf('upload/') + 'upload/'.length
  const first = imgUrl.slice(0, insertIdx)
  const transfs = getTransformations(transformations)
  const end = imgUrl.slice(insertIdx - 1)

  return first + transfs + end
}

const getTransformations = (transformations) => {
  const transfs = map(transformations, (prefixVal, prefixType) => {
    const prefixKey = transfPrefixes[prefixType]

    let prefixValPrepped
    if (isNaN(prefixVal)) {
      //if prefixVal is a string or not number, do not rund
      prefixValPrepped = prefixVal
    } else {
      prefixValPrepped = Math.round(prefixVal)
    }

    const transf = prefixKey + prefixValPrepped

    return transf
  })

  return transfs.join(',')
}
