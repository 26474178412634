import { useState } from 'react'

export function usePagination({ initialPage = 1, initialLimit = 20, itemCount }) {
  const [currentPage, setCurrentPage] = useState(initialPage)
  const [pageLimit, setPageLimit] = useState(initialLimit)

  const totalPages = Math.ceil(itemCount / pageLimit)
  const offset = (currentPage - 1) * pageLimit

  return { pageLimit, setPageLimit, currentPage, setCurrentPage, totalPages, offset, itemCount }
}
