import React, { useState } from 'react'
import tw from 'twin.macro'
import { BiChevronDown } from 'react-icons/bi'
import * as Popover from '@radix-ui/react-popover'

import { useRefsControl } from 'common/components/RefsControl/WorkoutRefsControl/useRefsControl'
import { onClickAddPart, onClickAddRestPart } from '../utils/editPartUtils'
import { useAddPartMutation, useAddRestPartMutation } from '../programApi'
import SavedBlocksList from './SavedBlocks/SavedBlocksList'

export default function SplitDropdownAddBlock({ orgId, workout, addBlockRef }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSavedBlocksOpen, setIsSavedBlocksOpen] = useState(false)

  const [addPart] = useAddPartMutation()
  const [addRestPart] = useAddRestPartMutation()

  const { focusOnLastInput } = useRefsControl()

  return (
    <div className='flex items-center'>
      <PrimaryButton
        ref={addBlockRef}
        size='md'
        variant='secondary'
        onClick={() => {
          focusOnLastInput()
          onClickAddPart(
            {
              orgId,
              workout: workout,
              workoutId: workout.id,
            },
            addPart
          )
        }}
      >
        Add block
      </PrimaryButton>
      <Popover.Root
        open={isMenuOpen}
        onOpenChange={(isOpen) => {
          setIsMenuOpen(isOpen)
          if (!isOpen) setIsSavedBlocksOpen(isOpen)
        }}
        onClse
      >
        <Popover.Trigger
          onClick={(e) => {
            e.stopPropagation()
            setIsMenuOpen(true)
          }}
          tabIndex={-1}
          className='cursor-pointer bg-gray-200 rounded-tr-md rounded-br-md hover:bg-gray-200 hover:z-10 text-gray-600 hover:ring-2 hover:ring-tGray-ml px-1 py-2 text-xs font-medium'
        >
          <BiChevronDown size='1rem' />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className='bg-white px-2 py-2 rounded-md shadow-xl border-2 border-gray-300 z-50'
            align='end'
            alignOffset={-7}
            arrowPadding={12}
          >
            <Popover.Arrow className='fill-gray-300' />
            {isSavedBlocksOpen ? (
              <SavedBlocksList orgId={orgId} workoutId={workout.id} parts={workout.parts} />
            ) : (
              <>
                <Popover.Close asChild={true}>
                  <PopoverBtn
                    disabled={false}
                    onClick={() => {
                      onClickAddRestPart(
                        {
                          orgId,
                          workout: workout,
                          workoutId: workout.id,
                        },
                        addRestPart
                      )
                    }}
                  >
                    Add Rest
                  </PopoverBtn>
                </Popover.Close>
                <PopoverBtn disabled={false} onClick={() => setIsSavedBlocksOpen(true)}>
                  Add Saved Block
                </PopoverBtn>
              </>
            )}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  )
}

const PrimaryButton = tw.button`bg-gray-200 rounded-tl-md rounded-bl-md hover:bg-gray-200 hover:z-10 text-gray-600 hover:ring-2 hover:ring-tGray-ml px-2.5 py-2 text-xs font-medium`
const PopoverBtn = tw.button`flex items-center bg-white hover:bg-gray-300 hover:bg-opacity-30 text-sm text-tBlack transition-all rounded-md justify-start font-medium px-2 py-2 w-full`
