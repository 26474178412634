import { getFirebase, globalApi, listenFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'

export const appOnboardingQuizApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenDraftQuiz: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/quiz`, api)
      },
    }),
    listenQuizPublishedAt: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/quiz/publishedAt`, api)
      },
    }),
    deleteQuiz: build.mutation({
      queryFn: async ({ coachOrgId }) => {
        const path = `drafts/${coachOrgId}/quiz`
        const { data, error } = await setFirebase(path, null)
        return { data, error }
      },
    }),
    setQuestion: build.mutation({
      queryFn: async ({ coachOrgId, question, questionId }) => {
        const path = `drafts/${coachOrgId}/quiz/questions/${questionId}`
        const { data, error } = await setFirebase(path, question)
        return { data, error }
      },
    }),
    updateQuestion: build.mutation({
      queryFn: async ({ coachOrgId, question, questionId }) => {
        const path = `drafts/${coachOrgId}/quiz/questions/${questionId}`
        const { data, error } = await updateFirebase(path, question)
        return { data, error }
      },
    }),
    updateQuestions: build.mutation({
      queryFn: async ({ coachOrgId, update }) => {
        const path = `drafts/${coachOrgId}/quiz/questions`
        const { data, error } = await updateFirebase(path, update)
        return { data, error }
      },
    }),
    listenPublishedResult: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId, itemId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/quiz/results/${itemId}`, api)
      },
    }),
    listenPublishedResults: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/quiz/results`, api)
      },
    }),
    listenDraftResult: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId, itemId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/quiz/results/${itemId}`, api)
      },
    }),
    listenDraftResults: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/quiz/results`, api)
      },
    }),
    updateResult: build.mutation({
      queryFn: async ({ coachOrgId, questionId, itemId, resultId }) => {
        const path = `drafts/${coachOrgId}/quiz/results/${itemId}`
        const { data, error } = await updateFirebase(path, {
          [`${questionId}/${resultId}`]: true,
        })
        return { data, error }
      },
    }),
    updateResults: build.mutation({
      queryFn: async ({ coachOrgId, update }) => {
        const path = `drafts/${coachOrgId}/quiz/results`
        const { data, error } = await updateFirebase(path, update)
        return { data, error }
      },
    }),
    publishQuiz: build.mutation({
      queryFn: async ({ coachOrgId }) => {
        const draftQuizPath = `drafts/${coachOrgId}/quiz`
        const { data: quizData = {} } = await getFirebase(draftQuizPath)
        const publishedQuizPath = `coachOrgs/${coachOrgId}/quiz`
        const { data, error } = await setFirebase(publishedQuizPath, { ...quizData, publishedAt: Date.now() })
        return { data, error }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenDraftQuizQuery,
  useListenQuizPublishedAtQuery,
  useDeleteQuizMutation,
  useSetQuestionMutation,
  useUpdateQuestionMutation,
  useUpdateQuestionsMutation,
  useListenPublishedResultsQuery,
  useListenPublishedResultQuery,
  useListenDraftResultsQuery,
  useListenDraftResultQuery,
  useUpdateResultMutation,
  useUpdateResultsMutation,
  usePublishQuizMutation,
} = appOnboardingQuizApi
