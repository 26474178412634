import * as yup from 'yup'
import { trimString } from 'common/utils/yupSchemaUtils'
import { validHttpsUrlRegex } from 'common/utils/fileUploading/validators'

export const schema = yup
  .object({
    type: yup.string().required(),
    title: yup.string().when('type', ([type], schema) => {
      if (type !== 'video') {
        return schema
          .required('Title required')
          .max(30, `Title should be no longer than 30 characters`)
          .transform(trimString)
      } else {
        return schema
      }
    }),
    description: yup.string().max(60, `Description should be no longer than 60 characters`).transform(trimString),
    link: yup.string().when('type', ([type], schema) => {
      if (type === 'link') {
        return schema
          .matches(validHttpsUrlRegex, {
            excludeEmptyString: true,
            message: 'Link must include https and be a valid URL ',
          })
          .required('Link required')
      } else {
        return schema
      }
    }),
    scene: yup.string().when('type', ([type], schema) => {
      if (type === 'scene') {
        return schema.required('Scene required')
      } else {
        return schema
      }
    }),
    previewImg: yup.string().when('type', ([type], schema) => {
      if (type !== 'video') {
        return schema.required('Image required').transform(trimString)
      } else {
        return schema
      }
    }),
    videoId: yup.string().when('type', ([type], schema) => {
      if (type === 'video') {
        return schema.required('Video required').transform(trimString)
      } else {
        return schema
      }
    }),
    isPaid: yup.boolean(),
  })
  .required()
