import { useState } from 'react'
const defaultShowWkByWeekIdx = {
  0: true,
}

export const getTopAndBottom = ({ containerId }) => {
  const element = document.getElementById(containerId)

  if (!element) {
    //for some reason unable to find element until some time
    return { top: null, bottom: null, status: 'has_not_init' }
  }

  const { top, bottom } = element.getBoundingClientRect()
  return { top, bottom }
}

export const useGetIsInView = ({ containerId, weekIdx }) => {
  const defaultIsInView = defaultShowWkByWeekIdx[weekIdx] || false
  const [isInView, setIsInView] = useState(defaultIsInView)

  const { top, bottom, status } = getTopAndBottom({
    containerId,
  })

  if (status === 'has_not_init') {
    return isInView
  } else {
    const windowH = window.innerHeight

    const isTopIn = top >= 0 && top <= windowH
    const isBottomIn = bottom >= 0 && bottom <= windowH

    const isInViewAndLargerThanScreen = top < 0 && bottom > windowH
    const isNowInView = isTopIn || isBottomIn || isInViewAndLargerThanScreen

    if (isNowInView !== isInView) {
      setIsInView(isNowInView)
    }
  }

  return isInView
}
