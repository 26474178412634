import React from 'react'
import tw, { styled } from 'twin.macro'
import { Spinner } from 'common/components/Spinner/Spinner'

export const Button = React.forwardRef(
  (
    {
      variant = 'primary',
      size = 'lg',
      loading = false,
      type,
      disabled,
      onClick,
      className,
      children,
      spinnerClasses,
      ...otherProps
    },
    ref
  ) => {
    return (
      <StyledButton
        {...otherProps}
        variant={variant}
        size={size}
        type={type}
        disabled={disabled || loading}
        onClick={onClick}
        className={className}
        ref={ref}
      >
        {loading && <Spinner className={spinnerClasses} />}
        {children}
      </StyledButton>
    )
  }
)

export const buttonBase = tw`flex justify-center items-center font-bold transition-all`

export const buttonVariants = {
  primary: tw`bg-tGreen hover:bg-tGreen-dark disabled:bg-tGreen disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-50 text-white`,
  secondary: tw`bg-offWhite hover:bg-tGray-light disabled:bg-offWhite disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-50 text-tBlack text-opacity-70`,
  tertiary: tw`bg-white ring-2 ring-inset ring-tBlack ring-opacity-20 hover:ring-tGreen hover:ring-opacity-100 disabled:ring-tBlack disabled:ring-opacity-20 disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-50 text-tGreen`,
  danger: tw`bg-tRed hover:bg-tRed-dark disabled:bg-tRed disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-50 text-white`,
}

export const buttonSizes = {
  lg: tw`px-6 py-4 text-base rounded-xl`,
  md: tw`px-5 py-2.5 text-sm rounded-lg`,
  sm: tw`px-2 py-1.5 text-sm rounded-lg`,
}

const StyledButton = styled.button(({ variant, size }) => [buttonBase, buttonVariants[variant], buttonSizes[size]])
