import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: { userId: null, fbAccessToken: null, oauthToken: null },
  reducers: {
    setCredentials: (state, { payload: { userId, fbAccessToken } }) => {
      state.userId = userId
      state.fbAccessToken = fbAccessToken
    },
    auth0Succeeded: (state, { payload: { oauthToken } }) => {
      state.oauthToken = oauthToken
    },
  },
})

export const { setCredentials, auth0Succeeded } = authSlice.actions

export const selectCurrentUser = (state) => state.auth.userId
export const selectFbAT = (state) => state.auth.fbAccessToken
export const selectCurrentToken = (state) => state.auth.oauthToken

export default authSlice.reducer
