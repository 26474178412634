import { concat, difference, filter, sortBy } from 'lodash'

export const handleSortOfDates = (entries, sortOrder, sortField) => {
  const withDate = filter(entries, ([key, item]) => Boolean(item[sortField]))
  const withoutDate = difference(entries, withDate)
  const result = concat(
    sortOrder === 'asc'
      ? sortBy(withDate, ([key, item]) => {
          return item[sortField]
        }).reverse()
      : sortBy(withDate, ([key, item]) => {
          return item[sortField]
        }),
    withoutDate
  )
  return result
}

export const handleSortNames = (entries, sortOrder, sortField) => {
  return sortOrder === 'asc'
    ? sortBy(entries, ([key, item]) => {
        return item[sortField] && item[sortField].toLowerCase()
      }).reverse()
    : sortBy(entries, ([key, item]) => {
        return item[sortField] && item[sortField].toLowerCase()
      })
}
