import React, { useState, useEffect } from 'react'
import { timeAgo } from 'common/utils/timeAgoUtils'

export default function LastUpdatedAt({ leadupText, updatedAt, isLoading }) {
  const [, setTime] = useState(Date.now())

  useEffect(() => {
    const MS_INTERVAL = 1000 * 30

    const interval = setInterval(() => setTime(Date.now()), MS_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [])

  if (isLoading || !updatedAt || updatedAt.isFiller) {
    return <div className='h-6 mb-2' />
  }

  return (
    <div className='h-6 flex row justify-end mb-2 mr-[2px] text-gray-500 text-sm'>
      {leadupText || 'Last updated'} {timeAgo(updatedAt)}
    </div>
  )
}
