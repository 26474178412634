import React from 'react'
import { useDroppable } from '@dnd-kit/core'

export const Droppable = ({ id, disabled, customData, className, children }) => {
  const { setNodeRef } = useDroppable({
    id,
    data: customData,
    disabled,
  })

  const classNames = `${className ? className : ''}`

  return (
    <div ref={setNodeRef} className={classNames} tabIndex={-1}>
      {children}
    </div>
  )
}
