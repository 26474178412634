import tw from 'twin.macro'
import React from 'react'
import { CgTrash } from 'react-icons/cg'

import { Button, buttonBase, buttonSizes } from 'common/components/Button/Button'
import { Tooltip } from '../Tooltip/Tooltip'

export const UpdateActions = React.forwardRef(
  (
    {
      itemKey,
      form,
      handleSubmit,
      handleDelete,
      loading,
      actionText,
      hideDelete,
      disabled,
      deleteDisabled = false,
      disabledReason,
      containerClasses,
      onKeyUp,
      onKeyDown,
    },
    ref
  ) => {
    const defaultActionText = itemKey ? 'Edit' : 'Create'
    const text = actionText ? actionText : defaultActionText
    const defaultContainerStyle = `
      flex
      flex-row
      justify-between
      items-center
      px-4
      md:px-10
      py-4
      bg-white
      shadow-t
    `

    const containerStyles = `
      ${defaultContainerStyle}
      ${containerClasses ? containerClasses : ''}
    `

    return (
      <div className={containerStyles}>
        {itemKey && (
          <DeleteButton
            handleDelete={handleDelete}
            hideDelete={hideDelete}
            disabled={deleteDisabled}
            disabledReason={disabledReason}
          />
        )}
        <Button
          form={form}
          type='submit'
          loading={loading}
          onClick={handleSubmit}
          disabled={disabled}
          size='lg'
          ref={ref}
          onKeyUp={(e) => {
            if (onKeyUp) {
              onKeyUp(e)
            }
          }}
          onKeyDown={(e) => {
            if (onKeyDown) {
              onKeyDown(e)
            }
          }}
        >
          {text}
        </Button>
      </div>
    )
  }
)

function DeleteButton({ handleDelete, hideDelete, disabled, loading, disabledReason }) {
  if (hideDelete) {
    return null
  }

  const deleteButtonMarkup = (
    <button
      onClick={handleDelete}
      disabled={disabled || loading}
      css={[
        buttonBase,
        buttonSizes.lg,
        tw`hover:bg-tRed hover:bg-opacity-10 text-gray-500 hover:text-tRed-dark p-4 disabled:cursor-not-allowed`,
      ]}
      tabIndex={-1}
      aria-label='Delete'
    >
      <CgTrash className='w-6 h-6' />
    </button>
  )

  if (disabled && disabledReason) {
    return <Tooltip content={disabledReason}>{deleteButtonMarkup}</Tooltip>
  } else {
    return deleteButtonMarkup
  }
}
