import { Spinner } from 'common/components/Spinner/Spinner'

export function QuestionnaireLoader({ text }) {
  return (
    <div className='flex items-center justify-center px-10 py-10 text-tBlack min-h-[290px]'>
      <div className='flex items-center'>
        <Spinner className='w-6 h-6 text-gray-100' />
        <span className='font-semibold ml-3'>{text}</span>
      </div>
    </div>
  )
}
