import { setFirebase, updateFirebase } from 'modules/App/globalApi'
import {
  initProgramWkts,
  initCoach,
  sampleProgram1,
  sampleProgram2,
  getInitCoachOrg,
} from '../templates/initDataTemplates'

export const initFirebaseData = async ({ coachOrgId, userId, profile, exVidOrientation }) => {
  const { error: setAdminErr } = await setFirebase(`coachOrgs/${coachOrgId}/admins/${userId}`, profile.email)

  if (setAdminErr) {
    console.error(setAdminErr)
    return { error: true }
  }

  const { error: setCoachOrgErr } = await updateFirebase(
    `coachOrgs/${coachOrgId}`,
    getInitCoachOrg(coachOrgId, exVidOrientation)
  )

  // Workouts
  const { error: setWorkoutsErr } = await updateFirebase(`drafts/${coachOrgId}/workouts`, initProgramWkts)
  // Draft Programs
  const { error: setProgramsDraftErr } = await updateFirebase(`drafts/${coachOrgId}/programs`, {
    [sampleProgram1.id]: { ...sampleProgram1, workoutsV2: null, coachOrgId },
    [sampleProgram2.id]: { ...sampleProgram2, workoutsV2: null, coachOrgId },
  })
  // Published Programs
  const { error: setProgram1PublishedErr } = await setFirebase(`programs/${sampleProgram1.id}`, {
    ...sampleProgram1,
    workouts: null,
    coachOrgId,
  })
  const { error: setProgram2PublishedErr } = await setFirebase(`programs/${sampleProgram2.id}`, {
    ...sampleProgram2,
    workouts: null,
    coachOrgId,
  })

  if (setCoachOrgErr || setWorkoutsErr || setProgramsDraftErr || setProgram1PublishedErr || setProgram2PublishedErr) {
    return { error: true }
  }
}

export const initFirebaseCoach = async ({ userId, coachOrgId, profile }) => {
  // Coach
  const { error: setCoachErr } = await updateFirebase(`users/${userId}`, initCoach(coachOrgId, profile))

  if (setCoachErr) {
    return { error: true }
  }
}
