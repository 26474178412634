/**
 * Component to render errors like incorrect username/password,
 * no account found, etc.
 */

import { CgCloseO } from 'react-icons/cg'
import { getErrorDisplayMessage } from '../utils/errorHandling'

function AuthErrorBar(props) {
  const { authError } = props

  const message = getErrorDisplayMessage(authError)

  if (!authError || !authError.code || authError.code === 'login_required') {
    return null
  }

  return (
    <div className='flex flex-row items-center w-full my-2 px-2 py-2 rounded-md border border-red-300 bg-red-50'>
      <CgCloseO className='text-red-400 w-6 h-6' />
      <div className='text-sm ml-2'>{message}</div>
    </div>
  )
}

export default AuthErrorBar
