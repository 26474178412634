import { globalApi, listenFirebase, setFirebase, getFirebase } from 'modules/App/globalApi'
import { map } from 'lodash'

export const trybeAdminApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenCoachOrgStrings: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async (_, api) => {
        await listenFirebase(`internal/coachOrgsLight`, api, async (orgs) => {
          const coachOrgStrings = map(orgs, (orgName, orgId) => `${orgName} || ${orgId}`)
          api.updateCachedData(() => coachOrgStrings)
        })
      },
    }),
    listenUserPaidAccess: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ userId, coachOrgId }, api) => {
        listenFirebase(`users/${userId}/paidAccess/coachOrgs/${coachOrgId}`, api)
      },
    }),
    listenIsOrgAdmin: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ userId, coachOrgId }, api) => {
        listenFirebase(`/coachOrgs/${coachOrgId}/admins/${userId}`, api)
      },
    }),
    getCoachOrgName: build.query({
      queryFn: async ({ coachOrgId }) => {
        const { data, error } = await getFirebase(`coachOrgs/${coachOrgId}/orgName`)
        return { data, error }
      },
    }),
    getCoachOrgCoachName: build.mutation({
      queryFn: async ({ coachOrgId }) => {
        const { data, error } = await getFirebase(`coachOrgs/${coachOrgId}/coachName`)
        return { data, error }
      },
    }),
    setActiveCoachOrg: build.mutation({
      queryFn: async ({ coachOrgId, userId }) => {
        const { data: profileData, error: profileError } = await setFirebase(
          `users/${userId}/profile/coachOrgId`,
          coachOrgId
        )
        const { data: metadataData, error: metadataError } = await setFirebase(
          `users/${userId}/metadata/coachOrgId`,
          coachOrgId
        )
        return { data: { profileData, metadataData }, error: { profileError, metadataError } }
      },
    }),
    setPaidAccess: build.mutation({
      queryFn: async ({ coachOrgId, userId, date }) => {
        const { data, error } = await setFirebase(`users/${userId}/paidAccess/coachOrgs/${coachOrgId}/accessEnd`, date)
        return { data, error }
      },
    }),
    setAdminAccess: build.mutation({
      queryFn: async ({ coachOrgId, userId, email }) => {
        const { data, error } = await setFirebase(`/coachOrgs/${coachOrgId}/admins/${userId}`, email)
        return { data, error }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenCoachOrgStringsQuery,
  useCoachOrgQuery,
  useSetActiveCoachOrgMutation,
  useListenUserPaidAccessQuery,
  useListenIsOrgAdminQuery,
  useSetPaidAccessMutation,
  useSetAdminAccessMutation,
  useGetCoachOrgNameMutation,
  useGetCoachOrgCoachNameQuery,
} = trybeAdminApi
