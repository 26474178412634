import tw from 'twin.macro'
import React from 'react'
import { Input } from 'common/components/Input/Input'

function LevelFilter({ filters, setFilters }) {
  const comparison = filters.level.comparison
  const handleComparisonChange = (e) => {
    setFilters({
      ...filters,
      level: {
        ...filters.level,
        comparison: e.target.value,
      },
    })
  }

  const handleLevelChange = (e) => {
    const levelKey = e.target.name
    let inputValue = parseInt(e.target.value)
    if (inputValue < 0 || isNaN(inputValue)) {
      inputValue = 0
    }

    if (inputValue > 100) {
      inputValue = 100
    }

    setFilters({
      ...filters,
      level: {
        ...filters.level,
        [levelKey]: inputValue,
      },
    })
  }

  return (
    <div className='ml-6'>
      <div className='py-2'>
        <select
          name='comparison'
          aria-label='comparison'
          onChange={handleComparisonChange}
          value={filters.level.comparison}
          className='bg-white ring-1 border-0 ring-gray-300 text-tBlack text-sm focus:border-0 focus:ring-tGreen rounded-md'
        >
          <option value='greater'>is greater than</option>
          <option value='equal'>is equal to</option>
          <option value='less'>is less than</option>
          <option value='between'>is between</option>
        </select>
      </div>
      <div className='flex items-center'>
        <Input
          type='text'
          name='value'
          aria-label='value'
          placeholder='0-100'
          css={tw`h-5 w-10 px-0 text-center text-sm rounded-md`}
          value={filters.level.value}
          onChange={handleLevelChange}
          onFocus={onFocusSelectText}
        />
        {comparison === 'between' && (
          <>
            <span className='px-2 text-tBlack'>/</span>
            <Input
              type='text'
              name='upperBound'
              aria-label='upperBound'
              placeholder='0-100'
              css={tw`h-5 w-10 px-0 text-center text-sm rounded-md`}
              value={filters.level.upperBound}
              onChange={handleLevelChange}
              onFocus={onFocusSelectText}
            />
          </>
        )}
      </div>
    </div>
  )

  function onFocusSelectText(e) {
    e.target.select()
  }
}

export default LevelFilter
