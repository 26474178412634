import React, { memo } from 'react'
import tw from 'twin.macro'

import useAuth from 'modules/Auth/AuthContext'
// import { deleteAccount } from 'modules/Auth/utils/delete'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useUpdateProfileMutation } from './accountApi'

import AccountLayout from './AccountLayout'
import ProfileForm from './components/accountForms/ProfileForm'
import { Spinner } from 'common/components/Spinner/Spinner'
import { Button } from 'common/components/Button/Button'

import { AWSupload } from 'common/utils/fileUploading/uploadUtils'
import { createUID } from 'common/utils/createUID'
import { acceptedFileTypes } from 'common/utils/fileUploading/validators'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'

const NoAvatar = tw.div`
  flex 
  justify-center 
  items-center 
  text-white 
  text-4xl 
  w-28 
  h-28 
  rounded-full 
  bg-tPurple
`

const UploadImgBtn = tw.label`
  text-sm 
  font-bold 
  text-white 
  bg-tGreen 
  rounded-lg 
  py-2.5 
  px-4 
  mr-2.5 
  cursor-pointer
  mb-2
  md:mb-0
`

function Account() {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const [updateProfile] = useUpdateProfileMutation({ userId })

  const allowedFileTypes = acceptedFileTypes['image'].join(',')

  const handleUploadImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const uid = createUID()

      updateProfile({
        userId,
        profile: {
          picture: 'loading',
        },
      })

      AWSupload({
        file: e.target.files[0],
        uniqueId: uid,
        userId,
        coachOrgId,
        uploadType: 'profile-image',
      })
    }
  }

  const handleRemoveImage = () => {
    updateProfile({
      userId,
      profile: {
        picture: '',
      },
    })
  }

  return (
    <AccountLayout>
      {!profile?.userId ? (
        <Spinner className={'w-10 h-10 text-gray-100 m-auto'} />
      ) : (
        <>
          <div className='flex items-center relative px-2.5 mb-10' data-testid='content' aria-label='profile'>
            <ProfileImage profile={profile} />
            <div className='flex flex-col ml-5'>
              <h3 className='text-xl font-semibold text-tBlack mb-2.5'>Profile Picture</h3>
              <div className='flex items-start md:items-center flex-col md:flex-row'>
                <UploadImgBtn htmlFor='avatar' disabled={profile?.picture === 'loading'}>
                  Upload new picture
                </UploadImgBtn>
                <input
                  className='hidden'
                  type='file'
                  id='avatar'
                  accept={allowedFileTypes}
                  onChange={handleUploadImage}
                />
                <Button
                  disabled={profile?.picture === 'loading'}
                  className='rounded-xl'
                  variant='secondary'
                  size='md'
                  onClick={handleRemoveImage}
                >
                  Remove
                </Button>
              </div>
            </div>
          </div>
          <FormRefsControlProvider>
            <ProfileForm profile={profile} userId={userId} />
          </FormRefsControlProvider>
        </>
      )}
    </AccountLayout>
  )
}

const ProfileImage = memo(({ profile }) => {
  if (profile?.picture === 'loading') {
    return (
      <div className='flex items-center justify-center w-28 h-28 rounded-full bg-gray-50'>
        <Spinner className={'w-8 h-8 text-gray-200'} />
      </div>
    )
  } else if (profile?.picture) {
    return <img className='w-28 h-28 rounded-full' src={profile.picture} alt='avatar' />
  } else {
    return <NoAvatar>{profile.firstname?.slice(0, 1)}</NoAvatar>
  }
})

export default Account
