import React from 'react'

/**
 * Data example for items that are missing videos
 {
   "tester-n3wzuryyc54y3dilprca": {                         Unique readable item id or item name if id not available
       "name": "Tester",
       "foundLocations": {                                  All workout ids where item with missing video is found
           "-N82MUC402NCQQE1J7wK": {                        Workout id where item is found
               "id": "-N82MUC402NCQQE1J7wK",
               "wktWeekDayText": "Week 1, Day 4",
               "parts": [
                   0                                        List of non duplicate part indexes where exercise with missing video is found within workout
               ],
               "partIdxToExIdxs": {                         List of parts and exercise indexes where exercises with missing video that has a potential exercise with video match
                   [partIdx]: [exIdx],
                },
           }
       }
   },
   ...other items by unique ids
*/

/**
 * Data example for foundExMatches - exercise name to array of matched exercise keys
  {
   "exercise name": [matchingEx1, matchingEx2, ...other matching exercises]
   ...other items by exercise names
  }
*/

export function ItemMissingVideo({ itemMissingVid, itemMissingVidIdx, itemType, children }) {
  const getUntitledName = (type) => {
    switch (type) {
      case 'video':
        return 'Untitled video'
      case 'exercise':
        return 'Untitled exercise'
      default:
        return 'Untitled'
    }
  }

  return (
    <div className='flex mb-3 last:mb-0'>
      <div className='only:w-full w-3/5 only:pr-0 pr-3'>
        <div className='font-semibold capitalize truncate max-w-sm' data-testid='missingVidExTitle'>
          {`${itemMissingVidIdx + 1}. `}
          {itemMissingVid.name ? itemMissingVid.name : getUntitledName(itemType)}
        </div>
        <ul>
          {Object.values(itemMissingVid.foundLocations).map((loc, locIdx) => (
            <li
              key={`${loc.id}-${itemMissingVidIdx}-${locIdx}`}
              className='text-gray-500 text-sm'
              data-testid={`foundLocList-${itemMissingVid.name}`}
            >
              {loc.wktWeekDayText} {loc.parts ? String.fromCharCode(8594) : null}
              {loc.parts ? getPartLocText(loc.parts) : null}
            </li>
          ))}
        </ul>
      </div>
      {children}
    </div>
  )
}

const getPartLocText = (parts) =>
  parts.map((part, partIdx, partsArr) => {
    const suffix = partsArr.length - 1 === partIdx ? '. ' : ', '
    const partText =
      typeof part === 'number' ? ` Block ${String.fromCharCode(65 + part)}${suffix}` : ` ${part}${suffix}`
    return partText
  })
