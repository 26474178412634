import { useState } from 'react'
import { each } from 'lodash'
import { initialFilterState } from '../constants'

export function useFilter({ items }) {
  const [filters, setFilters] = useState(initialFilterState)

  const result = filterItemsByAllFilters(items, filters)

  return { filters, setFilters, result }
}

const filterByTags = (item, filters, filterName) => {
  if (filters[filterName] === null || filters[filterName]?.length === 0) return true

  return item[filterName] && filters[filterName].every((tag) => item[filterName].includes(tag))
}

export const filterItemsByAllFilters = (items, filters) => {
  let filteredItems = {}

  each(items, (item, itemId) => {
    const itemMatchesFilters =
      filterByTags(item, filters, 'difficulty') &&
      filterByTags(item, filters, 'equipment') &&
      filterByTags(item, filters, 'instructors') &&
      filterByTags(item, filters, 'tags')

    if (itemMatchesFilters) {
      filteredItems[itemId] = item
    }
  })

  return filteredItems
}
