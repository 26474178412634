import React, { useState } from 'react'
import { CgMoreAlt, CgPen, CgTrash } from 'react-icons/cg'
import { BiMoveVertical } from 'react-icons/bi'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useUpdateResourcesMutation } from './featuredResourcesApi'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'

import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'
import ResourceForm from './ResourceForm/ResourceForm'
import { PreviewImg } from './PreviewImg'
import Tag from 'common/components/Tag/Tag'
import { availableTypes } from './ResourceForm/TypeInput'

export default function Resource({ resource, resourceEntries, videos, coachOrgId, attributes, listeners }) {
  const { createAlert } = useAlert()

  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: resource.id })
  const [updateResources] = useUpdateResourcesMutation()

  const title = resource.type === 'video' ? videos?.[resource?.videoId]?.title : resource?.title
  const description = resource?.description

  const handleDelete = async () => {
    const remainingResources = resourceEntries.filter(([resId]) => resId !== resource.id)
    let resourceUpdate = {
      [resource.id]: null,
    }
    remainingResources.forEach(([resId, res], index) => {
      resourceUpdate[resId] = { ...res, resourceIdx: index }
    })
    setLoading(true)
    await updateResources({ coachOrgId, resources: resourceUpdate })
    setLoading(false)
    createAlert({ text: 'Resource deleted!', type: 'success' })
  }

  const isUploading = isUploadingAssets(assetDrafts)
  const previewImg = resource?.type === 'video' ? videos?.[resource?.videoId]?.previewImg : resource?.previewImg

  return (
    <div className='relative flex rounded-2xl bg-white w-auto mb-3'>
      <div
        className='flex flex-col border-r justify-center border-tGray border-opacity-20 text-gray-500 hover:text-tGreen cursor-move'
        {...attributes}
        {...listeners}
      >
        <BiMoveVertical className='w-4 h-4 mx-2' />
      </div>
      <div className='flex flex-1 cursor-pointer' onClick={() => setEditDialogOpen(true)}>
        <PreviewImg isUploading={isUploading} previewImg={previewImg} />
        <div className='py-4 flex justify-center flex-col'>
          <div className='font-bold text-tBlack capitalize mb-1 flex items-center'>
            {title}{' '}
            <Tag
              text={availableTypes.find(({ value }) => value === resource.type).displayValue}
              className='!mb-0 !text-xs !rounded-md ml-3 lowercase'
            />
          </div>
          <div className='text-sm text-tGray-dark mb-1'>{description}</div>
        </div>
      </div>
      <div className='absolute top-1/2 -translate-y-1/2 right-3 inline-flex items-center justify-end bg-white group-hover:border group-hover:border-tBlack group-hover:border-opacity-20 rounded-lg group-hover:shadow-sm'>
        <Dialog open={editDialogOpen} setOpen={setEditDialogOpen}>
          <DialogTrigger className='hidden group-hover:inline-block hover:text-tGreen py-1 px-2 border-r border-tBlack border-opacity-20 transition-all'>
            <CgPen className='w-5 h-5' />
          </DialogTrigger>
          <DialogContent header='Edit resource'>
            <FormRefsControlProvider>
              <ResourceForm resource={resource} resourceEntries={resourceEntries} coachOrgId={coachOrgId} />
            </FormRefsControlProvider>
          </DialogContent>
        </Dialog>
        <Dialog>
          <DialogTrigger className='hidden group-hover:inline-block hover:text-tRed py-1 px-2 border-r border-tBlack border-opacity-20 transition-all'>
            <CgTrash className='w-5 h-5' />
          </DialogTrigger>
          <DialogContent>
            <DeleteDialogBanner
              text={`This will delete the ${title} resource`}
              handleDelete={() => handleDelete()}
              loading={loading}
            />
          </DialogContent>
        </Dialog>
        <button className='inline-block py-1 px-2'>
          <CgMoreAlt className='w-5 h-5' />
        </button>
      </div>
    </div>
  )
}
