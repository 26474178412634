import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import Home from 'modules/Home'
import CollectionsLibrary from 'modules/Collections/index'
import AllProgramsLibrary from 'modules/Programs/components/AllProgramsLibrary'
import EditProgram from 'modules/Programs/components/ProgramView'
import DayLargeView from 'modules/Programs/components/DayLargeView'
import Library from 'modules/ExerciseLibrary'
import Account from 'modules/Account/Profile'
import Branding from 'modules/Account/Branding'
import Advanced from 'modules/Account/Advanced'
import Layout from 'modules/Layout/index'
import Progressions from 'modules/Progressions'
import Header from 'modules/App/components/Header'
import ProgramGroups from 'modules/ProgramGroups/index'
import LinkHub from 'modules/LinkHub'
import FeaturedResources from 'modules/FeaturedResources'
import TrybeAdmin from 'modules/Account/TrybeAdmin'
import HelpCenter from 'modules/HelpCenter/index'
import HelpCenterPage from 'modules/HelpCenter/helpCenterPage'
import VideoLibrary from 'modules/VideoLibrary/index'
import VideoViewer from 'modules/VideoViewer/index'
import AppOnboardingQuiz from 'modules/AppOnboardingQuiz/index'
import PageNotFound from './components/PageNotFound'
import { appRouterContainerId } from './constants/cssSelectorConstants'
import Login from 'modules/Auth/components/Login'
import Signup from 'modules/Auth/components/Signup'
import useAuth from 'modules/Auth/AuthContext'
import NewCoachOrgQuestionnaire from 'modules/NewCoachOrgQuestionnaire/index'
import Loading from 'modules/App/components/Loading'
import { useCustomization } from 'common/contexts/Customization/useCustomization'
import { maybeInitContentTags } from './utils/initContentTags'

function ProtectedRoute() {
  const { userId, fbAuthed, coachOrgId } = useAuth()
  const { exVidOrientation, enableLandscapeExAudio } = useCustomization()

  const isAuthed = userId && fbAuthed
  const coachOrgIdLoading = coachOrgId === undefined || coachOrgId?.isLoading

  // Temp code needed to convert standalone workout tags to content tags
  // TEMP CODE START
  const [initializingTags, setInitializingTags] = useState(false)

  useEffect(() => {
    if (isAuthed && !coachOrgIdLoading && coachOrgId) {
      setInitializingTags(true)
      maybeInitContentTags(coachOrgId).then(() => {
        setInitializingTags(false)
      })
    }
  }, [isAuthed, coachOrgIdLoading, coachOrgId])
  // TEMP CODE END

  if (!isAuthed) {
    return <Navigate to='/login' replace />
  }

  if (!exVidOrientation || coachOrgIdLoading || enableLandscapeExAudio === null || initializingTags) {
    return <Loading />
  }

  if (!coachOrgId) {
    return <NewCoachOrgQuestionnaire />
  }

  return (
    <div>
      <Header />
      <Outlet />
    </div>
  )
}

function UnprotectedRoute() {
  const { userId, fbAuthed } = useAuth()

  return userId && fbAuthed ? (
    <Navigate to='/' replace />
  ) : (
    <div>
      <Outlet />
    </div>
  )
}

function AppRouter() {
  return (
    <div className='flex flex-col h-screen' id={appRouterContainerId}>
      <div className='flex-1'>
        <Routes>
          <Route element={<UnprotectedRoute />}>
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
          </Route>

          <Route element={<ProtectedRoute />}>
            <Route path='/' element={<Navigate to='/layout' replace />} />
            <Route path='/home' element={<Home />} />

            {/*PROGRAMS*/}
            <Route path='programs' element={<AllProgramsLibrary />} />
            <Route path='programs/:id' element={<EditProgram />} />
            <Route path='programs/:id/workouts/:workoutId' element={<DayLargeView />} />
            <Route path='/series' element={<ProgramGroups />} />

            {/*COLLECTIONS*/}
            <Route path='/collections' element={<CollectionsLibrary />} />

            {/*EXERCISES*/}
            <Route path='/exercises' element={<Library />} />
            <Route path='/progressions' element={<Progressions />} />
            <Route path='/videos' element={<VideoLibrary />} />

            {/*SETTINGS*/}
            <Route path='/account' element={<Account />} />
            <Route path='/branding' element={<Branding />} />
            <Route path='/advanced' element={<Advanced />} />
            <Route path='/trybeadmin' element={<TrybeAdmin />} />

            {/*LAYOUT*/}
            <Route path='/layout' element={<Layout />} />
            <Route path='/layout/:tabId' element={<Layout />} />
            <Route path='/resources' element={<LinkHub />} />
            <Route path='/featured' element={<FeaturedResources />} />
            <Route path='/quiz' element={<AppOnboardingQuiz />} />

            {/*OTHER*/}
            <Route path='/videoviewer' element={<VideoViewer />} />
            <Route path='/help' element={<HelpCenter />} />
            <Route path='/help/:contentId' element={<HelpCenterPage />} />

            {/*PAGE NOT FOUND*/}
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Routes>
      </div>
    </div>
  )
}

export default AppRouter
