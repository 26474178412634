import { useState } from 'react'
import { each } from 'lodash'
import { initialFilterState } from 'modules/ExerciseLibrary/Filter/constants'
import { isCreatedInLast24h } from '../utils/dateUtils'

export function useFilter({ exercises, exVidOrientation }) {
  const [filters, setFilters] = useState(initialFilterState)

  const result = filterExercisesByAllFilters(exercises, filters, exVidOrientation)

  return { filters, setFilters, result }
}

const filterByVideo = (exercise, videoFilter, exVidOrientation) => {
  if (videoFilter === null) return true

  if (videoFilter === 'on') {
    return Boolean(exercise['portraitVid'] || exercise['video'])
  }

  if (videoFilter === 'off') {
    return !Boolean(exercise['portraitVid'] || exercise['video'])
  }
}

const filterByLevel = (exercise, levelFilter) => {
  if (levelFilter === null) return true
  if (!exercise.level) return false

  switch (levelFilter.comparison) {
    case 'greater':
      return exercise.level >= levelFilter.value
    case 'equal':
      return exercise.level === levelFilter.value
    case 'less':
      return exercise.level <= levelFilter.value
    case 'between':
      return exercise.level >= levelFilter.value && exercise.level <= levelFilter.upperBound
    default:
      return true
  }
}
//Using a nested object also prevents the object from refreshing

const filterByCues = (exercise, cuesFilter) => {
  if (cuesFilter === null) return true

  if (cuesFilter === 'complete') {
    return exercise.cues
  }

  if (cuesFilter === 'incomplete') {
    return !exercise.cues
  }
}

const filterByTags = (exercise, tagsFilter) => {
  if (tagsFilter === null || tagsFilter?.length === 0) return true

  return exercise.tags && tagsFilter.every((tag) => exercise.tags.includes(tag))
}

const filterByRecentlyCreated = (exercise, recentlyCreatedFilter) => {
  if (recentlyCreatedFilter === null) return true

  return exercise.createdAt && isCreatedInLast24h(exercise.createdAt)
}

export const filterExercisesByAllFilters = (exercises, filters, exVidOrientation) => {
  const filteredExercises = {}

  each(exercises, (ex, exId) => {
    const exMatchesFilters =
      filterByVideo(ex, filters.video) && // used for portrait exVidOrientation
      filterByLevel(ex, filters.level) &&
      filterByCues(ex, filters.cues) &&
      filterByTags(ex, filters.tags) &&
      filterByRecentlyCreated(ex, filters['recently-created'])

    if (exMatchesFilters) {
      filteredExercises[exId] = ex
    }
  })

  return filteredExercises
}
