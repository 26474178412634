export function ContentContainer({ children }) {
  return (
    <div className='flex flex-col min-h-screen bg-offWhite px-4 md:px-16 pb-48'>
      <div className='w-full max-w-[90rem] mx-auto mt-6 md:mt-12'>
        <div className='flex items-center justify-between mb-10'>
          <h1 className='font-bold text-3xl md:text-5xl text-tBlack mr-6'>App Layout</h1>
        </div>
        {children}
      </div>
    </div>
  )
}
