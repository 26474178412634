import { Button } from 'common/components/Button/Button'
import { useSetHasReadVidGuidelinesMutation } from '../exerciseLibraryApi'
import { CgInfo } from 'react-icons/cg'

export function ConfirmGuidelines({ coachOrgId, exVidGuidelines, setCurrentStep }) {
  const [setHasReadVidGuidelines] = useSetHasReadVidGuidelinesMutation()

  return (
    <div className='flex flex-col w-96 items-center'>
      <div className='border-2 rounded-xl px-6 mb-24'>
        <span className='flex flex-row items-center justify-center mt-4 mb-6'>
          <CgInfo className='w-6 h-6 mr-3' />
          <h3 className='text-xl text-center font-bold'>Video Requirements</h3>
        </span>
        <p className='text-center mb-6'>
          {`Before uploading your first exercises, please `}
          <a
            href={exVidGuidelines}
            target='_blank'
            rel='noreferrer nofollow'
            className='underline underline-offset-1 hover:text-tGreen font-semibold'
          >
            read these video requirements
          </a>{' '}
          to give users the best app experience.
        </p>
      </div>
      <Button
        variant='primary'
        size='lg'
        onClick={() => {
          setHasReadVidGuidelines({ coachOrgId })
          setCurrentStep(1)
        }}
      >
        Confirm
      </Button>
    </div>
  )
}
