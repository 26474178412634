import { useState } from 'react'
import { useDndMonitor } from '@dnd-kit/core'

export const useMonitorAnyPartDragging = () => {
  const [isAnyPartDragging, setIsAnyPartDragging] = useState(false)

  const onDragStart = ({ active }) => {
    const { itemType } = active.data.current

    if (itemType === 'part') {
      setIsAnyPartDragging(true)
    }
  }

  const onDragEnd = ({ active }) => {
    const { itemType } = active.data.current

    if (itemType === 'part') {
      setIsAnyPartDragging(false)
    }
  }

  useDndMonitor({ onDragStart, onDragEnd })

  return isAnyPartDragging
}
