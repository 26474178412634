import React, { useEffect, useRef } from 'react'
import { SuggestionContainer, SuggestionItem } from '../Suggestions/Suggestions'

export function SuggestionBox({
  suggestions,
  handleSelect,
  selectedIndex,
  setSelectedIndex,
  containerClasses,
  scrollAreaClasses,
}) {
  const suggestionContainerRef = useRef()

  useEffect(() => {
    if (!setSelectedIndex) {
      return
    }
    return () => setSelectedIndex(-1)
  }, [setSelectedIndex])

  useEffect(() => {
    const suggestionEl = suggestionContainerRef.current.querySelector(`[data-focused='true']`)

    if (selectedIndex !== -1) {
      const containerHeight = suggestionContainerRef.current.offsetHeight
      const suggestionHeight = suggestionEl.offsetHeight
      const relativeSuggestionTop = suggestionEl.offsetTop - suggestionContainerRef.current.scrollTop

      if (relativeSuggestionTop + suggestionHeight >= containerHeight) {
        suggestionContainerRef.current.scrollTop += relativeSuggestionTop - containerHeight + suggestionHeight
      }

      if (relativeSuggestionTop < 0) {
        suggestionContainerRef.current.scrollTop += relativeSuggestionTop
      }
    }

    if (selectedIndex === -1) {
      suggestionContainerRef.current.scrollTop = 0
    }
  }, [selectedIndex])

  return (
    <SuggestionContainer
      scrollAreaRef={suggestionContainerRef}
      containerClasses={containerClasses}
      scrollAreaClasses={scrollAreaClasses}
    >
      {suggestions.map((suggestion, index) => (
        <SuggestionItem
          key={suggestion.key || suggestion}
          active={selectedIndex === index}
          onClick={() => handleSelect(suggestion, index)}
          data-focused={selectedIndex === index}
        >
          {suggestion}
        </SuggestionItem>
      ))}
    </SuggestionContainer>
  )
}
