import { Skeleton } from 'common/components/Skeleton/Skeleton'

export function ItemLoadingCard() {
  return (
    <div className='flex items-center relative p-3 rounded-lg border shadow-sm h-[66px] mb-3 bg-white'>
      <div>
        <Skeleton className='!h-10 w-12 rounded' />
      </div>
      <div className='ml-3 w-[84%]'>
        <Skeleton className='w-2/5' />
        <Skeleton className='w-4/5 mt-2' />
      </div>
    </div>
  )
}
