import { videoQueryParam } from 'modules/VideoViewer/constants/queryParamConstants'

// Check if video title exists in all videos and is not video that is currently being edited
export const checkVideoTitleExists = ({ coachVideos, title, videoKey }) => {
  return Object.entries(coachVideos).find(
    ([key, video]) => video.title?.trim().toLowerCase() === title.toLowerCase() && videoKey !== key
  )
}

export const getCustomPreviewUrl = (videoId) => `/videoviewer?${videoQueryParam}=${encodeURIComponent(videoId)}`
