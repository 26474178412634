import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { inputBase } from '../Input/Input'

export default function Time({
  name,
  value,
  setValue,
  inputClassNames,
  onKeyDown,
  autoFocus,
  hrsRef,
  minsRef,
  secsRef,
  timeTypes = ['hrs', 'mins', 'secs'],
}) {
  const timeValue = value || '00:00:00.00'
  const [hours, minutes, secondsWithMs] = timeValue.split(':')
  const [seconds] = secondsWithMs.split('.')
  const [hourState, setHours] = useState(hours)
  const [minuteState, setMinutes] = useState(minutes)
  const [secondState, setSeconds] = useState(seconds)

  const changeHours = (e) => {
    // const hoursRegExp = /^([0-9]|0[0-9]|1[0-9]|2[0-3])$/
    if (!e.target.value) {
      setHours('00')
    }

    if (e.target.value < 60 && e.target.value >= 0) {
      const hrsNoLeadZero = parseInt(e.target.value).toString()
      const formattedHrs = hrsNoLeadZero.length < 2 ? hrsNoLeadZero.padStart(2, '0') : hrsNoLeadZero
      setHours(formattedHrs)
    }
  }

  const changeMinutes = (e) => {
    if (!e.target.value) {
      setMinutes('00')
    }

    if (Number(e.target.value) < 60 && Number(e.target.value) >= 0) {
      const minsNoLeadZero = parseInt(e.target.value).toString()
      const formattedMins =
        minsNoLeadZero.length < 2 ? minsNoLeadZero.padStart(2, '0') : parseInt(e.target.value).toString()
      setMinutes(formattedMins)
    } else {
      setMinutes('59')
    }
  }

  const changeSeconds = (e) => {
    if (!e.target.value) {
      setSeconds('00')
    }

    if (Number(e.target.value) < 60 && Number(e.target.value) >= 0) {
      const secsNoLeadZero = parseInt(e.target.value).toString()
      const formattedSecs =
        secsNoLeadZero.length < 2 ? secsNoLeadZero.padStart(2, '0') : parseInt(e.target.value).toString()
      setSeconds(formattedSecs)
    } else {
      setSeconds('59')
    }
  }

  useEffect(() => {
    const hrString = hourState ? String(hourState).padStart(2, '0') : '00'
    const minString = minuteState ? String(minuteState).padStart(2, '0') : '00'
    const secString = secondState ? String(secondState).padStart(2, '0') : '00'
    const combinedTimeString = `${hrString}:${minString}:${secString}.00`
    setValue(combinedTimeString)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourState, minuteState, secondState])

  return (
    <div className='flex flex-row items-center'>
      {timeTypes.includes('hrs') && (
        <>
          <div className='flex flex-col items-center justify-center'>
            <TimeSection
              name='hrs'
              id={name}
              type='number'
              placeholder='00'
              min={0}
              max={59}
              value={hourState}
              onChange={changeHours}
              onKeyDown={onKeyDown}
              ref={hrsRef}
              className={inputClassNames ? inputClassNames : ''}
              autoFocus={autoFocus && autoFocus === 'hrs'}
            />
            <div className='text-tGray-ml'>hr</div>
          </div>
          <div className='text-3xl text-tBlack mx-2 pb-6'>:</div>
        </>
      )}
      {timeTypes.includes('mins') && (
        <div className='flex flex-col items-center justify-center'>
          <TimeSection
            name='mins'
            type='number'
            placeholder='00'
            value={minuteState}
            min={0}
            max={59}
            onChange={changeMinutes}
            onKeyDown={onKeyDown}
            ref={minsRef}
            className={inputClassNames ? inputClassNames : ''}
            autoFocus={autoFocus && autoFocus === 'mins'}
          />
          <div className='text-tGray-ml'>min</div>
        </div>
      )}
      {timeTypes.includes('mins') && timeTypes.includes('secs') && (
        <div className='text-3xl text-tBlack mx-2 pb-6'>:</div>
      )}
      {timeTypes.includes('secs') && (
        <div className='flex flex-col items-center justify-center'>
          <TimeSection
            name='secs'
            type='number'
            placeholder='00'
            value={secondState}
            min={0}
            max={59}
            onChange={changeSeconds}
            onKeyDown={onKeyDown}
            ref={secsRef}
            className={inputClassNames ? inputClassNames : ''}
            autoFocus={autoFocus && autoFocus === 'secs'}
          />
          <div className='text-tGray-ml'>sec</div>
        </div>
      )}
    </div>
  )
}

const TimeSection = styled.input(() => [inputBase, tw`text-center w-16 px-2`])
