import tw from 'twin.macro'
import React from 'react'

import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { CreateDialogBanner } from 'common/components/CreateDialogBanner/CreateDialogBanner'
import { Button } from 'common/components/Button/Button'

import { useUpdateWorkoutMutation } from '../programApi'
import { maybePluralize } from 'common/utils/stringUtils'

export function CombineSelectedPartsDialog(props) {
  const { orgId, workout, partsSelected, clearSelectedParts } = props
  const [updateWorkout] = useUpdateWorkoutMutation()

  const selectedPartIds = partsSelected.map((selectedPart) => selectedPart.id)
  const modifiedWktParts = workout.parts.map((part, idx) => ({
    ...part,
    partIdx: idx,
    isSelected: selectedPartIds.includes(part.id),
  }))

  //right after deleting multiple parts, firstSelectedPart can become undefined
  //since all parts were deleted
  const firstSelectedPart = modifiedWktParts.find((part) => part.isSelected) || {}

  const firstPartLetter = String.fromCharCode(65 + firstSelectedPart.partIdx)
  const fistPartName = firstSelectedPart.name || firstPartLetter

  const allPartsSets = partsSelected.map((selectedPart) => selectedPart.sets)
  const allSetsAreEqual = allPartsSets.every((sets) => sets === allPartsSets[0])

  return (
    <Dialog>
      <DialogTrigger asChild={true}>
        <Button
          data-testid='combineAllPartsBtn'
          size='sm'
          css={tw`bg-transparent font-medium text-gray-600 text-xs hover:bg-opacity-40`}
          variant='secondary'
        >
          Combine All
        </Button>
      </DialogTrigger>
      <DialogContent>
        <CreateDialogBanner
          createBtnText='Combine'
          text={
            <DialogText
              allSetsAreEqual={allSetsAreEqual}
              fistPartName={fistPartName}
              firstSelectedPartSets={firstSelectedPart.sets}
            />
          }
          handleCreate={() =>
            onClickCombine({ orgId, workout, modifiedWktParts, firstSelectedPart, updateWorkout, clearSelectedParts })
          }
        />
      </DialogContent>
    </Dialog>
  )
}

const onClickCombine = async ({
  orgId,
  workout,
  modifiedWktParts,
  firstSelectedPart,
  updateWorkout,
  clearSelectedParts,
}) => {
  let combinedExercises = []
  let combinedParts = []

  modifiedWktParts.forEach((part) => {
    if (part.isSelected) {
      combinedExercises = [...combinedExercises, ...part.exercises]
    }

    if (!part.isSelected || part.id === firstSelectedPart.id) {
      const partToSave = { ...part, partIdx: null, isSelected: null }
      combinedParts.push(partToSave)
    }
  })

  const combineIntoPartIdx = combinedParts.findIndex((part) => part.id === firstSelectedPart.id)
  combinedParts[combineIntoPartIdx].exercises = combinedExercises

  const updatedWorkout = { ...workout, parts: combinedParts }
  await updateWorkout({
    orgId,
    workoutId: workout.id,
    workout: updatedWorkout,
  })

  clearSelectedParts()
}

function DialogText(props) {
  const { allSetsAreEqual, fistPartName, firstSelectedPartSets } = props

  if (allSetsAreEqual) {
    return (
      <>
        All selected blocks will be <strong className='font-medium'>combined into Block {fistPartName}</strong>.
      </>
    )
  }

  return (
    <>
      All selected blocks will be <strong className='font-medium'>combined into Block {fistPartName}</strong>. All
      blocks will have{' '}
      <strong className='font-medium'>
        {firstSelectedPartSets} {maybePluralize({ count: firstSelectedPartSets, text: 'set' })}
      </strong>
      .
    </>
  )
}
