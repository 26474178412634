// Temp code needed to convert standalone workout tags to content tags

import { asyncForEach } from 'common/utils/asyncForEach'
import { getFirebase, setFirebase } from '../globalApi'
import { removeInvalidFirebaseChars } from 'common/utils/fileUploading/nameAndIdUtils'

const convertStandaloneWktTags = async (coachOrgId) => {
  const standaloneWktsProgramIdPath = `coachOrgs/${coachOrgId}/soloWorkoutsProgramId`
  const { data: standaloneWktsProgramId } = await getFirebase(standaloneWktsProgramIdPath)

  if (!standaloneWktsProgramId) {
    return
  }

  const { data: standaloneWkts } = await getFirebase(
    `drafts/${coachOrgId}/programs/${standaloneWktsProgramId}/workouts`
  )

  if (!standaloneWkts) {
    return
  }

  let contentTags = {}
  await asyncForEach(standaloneWkts, async (_, workoutId) => {
    const path = `drafts/${coachOrgId}/workouts/${workoutId}/tags`
    const { data: wktTags } = await getFirebase(path)

    if (!wktTags) {
      return
    }

    wktTags.forEach((wktTag) => {
      if (!wktTag || (typeof wktTag === 'string' && wktTag.trim().length === 0)) {
        return
      }

      const cleanWktTag = removeInvalidFirebaseChars(wktTag)

      const payload = {
        [workoutId]: {
          type: 'workout',
          programId: standaloneWktsProgramId,
        },
      }

      if (!contentTags[cleanWktTag]) {
        contentTags[cleanWktTag] = { ...payload }
      } else {
        contentTags[cleanWktTag] = { ...contentTags[cleanWktTag], ...payload }
      }
    })
  })

  return contentTags
}

export const maybeInitContentTags = async (coachOrgId) => {
  const contentTagsPath = `coachOrgs/${coachOrgId}/contentTags/tags`
  const { data: contentTagsData } = await getFirebase(contentTagsPath)

  if (contentTagsData) {
    return
  }

  const contentTags = await convertStandaloneWktTags(coachOrgId)
  if (!contentTags) {
    return
  }

  const { error: setContentTagsErr } = await setFirebase(contentTagsPath, contentTags)

  if (setContentTagsErr) {
    console.error(setContentTagsErr)
    return { error: true }
  }
}
