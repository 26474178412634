import tw from 'twin.macro'
import React from 'react'

import { useListenProgramWorkoutsQuery } from 'modules/Layout/layoutApi'
import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'
import { useListenUserProfileQuery } from 'modules/Users/userApi'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { LayoutCard } from './LayoutCard'

export const WorkoutCard = React.memo(({ item, handleDelete }) => {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: workouts } = useListenProgramWorkoutsQuery({ programId: item.programId })
  const workoutsLoading = workouts === undefined || workouts?.isLoading
  const { data: videoData } = useListenCoachVideosQuery({ coachOrgId })
  const videos = videoData?.isLoading || videoData === null || videoData === undefined ? {} : videoData

  if (workoutsLoading || videoData?.isLoading) {
    return <Skeleton css={tw`w-60 h-44 rounded-xl`} />
  }

  if (!workouts) {
    return null
  }

  const workout = workouts[item.id]

  if (workout && workout.type !== 'single_video') {
    return (
      <LayoutCard
        id={item.id}
        type={item.type}
        name={workout.title || 'Untitled'}
        image={workout.previewImg}
        subtitle={workout.subtitle}
        handleDelete={() => handleDelete(item)}
      />
    )
  }

  if (workout && workout.type === 'single_video' && workout.videoId) {
    const videoImage = videos?.[workout.videoId]?.previewImg
    const wktImage = workout.previewImg
    const videoDuration = videos?.[workout.videoId]?.duration
    return (
      <LayoutCard
        id={item.id}
        type={'video'}
        name={workout.title || 'Untitled'}
        subtitle={workout.subtitle}
        image={wktImage ? wktImage : videoImage}
        time={videoDuration ? videoDuration : '00:01'}
        handleDelete={() => handleDelete(item)}
      />
    )
  }

  return (
    <LayoutCard
      id={item.id}
      type={item.type}
      name='This workout has been deleted'
      handleDelete={() => handleDelete(item)}
    />
  )
})
