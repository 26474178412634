import React, { Fragment } from 'react'
import * as Portal from '@radix-ui/react-portal'
import { union } from 'lodash'
import { format } from 'date-fns'
import { CgCheck } from 'react-icons/cg'
import { Combobox } from '@headlessui/react'

import { SuggestionContainer, SuggestionItem } from 'common/components/Suggestions/Suggestions'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { getSuggestedExercises } from '../utils'

export function ExerciseInputOptions({
  exerciseList,
  progressionKey,
  value,
  formExerciseValues,
  progressions,
  inputRef,
  popperContainer,
}) {
  const suggestedExercises = getSuggestedExercises(exerciseList, value) || []
  const inUseExercises = Object.entries(progressions).map(([key, value]) => (key === progressionKey ? [] : value))
  const usedExercises = union(
    ...inUseExercises,
    formExerciseValues.map((exId) => (exId || '').toLowerCase())
  )
  const suggestedExStart = 0
  const suggestedExEnd = 30

  const inputWidth = inputRef?.current?.getBoundingClientRect()?.width

  return (
    <Portal.Root>
      <Combobox.Options
        className='z-[999]'
        ref={popperContainer}
        modal={false}
        static={process.env.NODE_ENV === 'test'}
      >
        <SuggestionContainer containerStyle={{ width: inputWidth }}>
          {!suggestedExercises || suggestedExercises?.length === 0 ? (
            <li className='cursor-default select-none py-2 px-2'>No suggestions found.</li>
          ) : (
            suggestedExercises.slice(suggestedExStart, suggestedExEnd).map(([exKey, exercise]) => (
              <Combobox.Option as={Fragment} key={exKey} value={exKey}>
                {({ selected, active }) => (
                  <SuggestionItem active={active}>
                    <div className='flex items-center w-[250px] md:w-[336px]'>
                      {selected && (
                        <div className='flex items-center w-[10%]'>
                          <CgCheck className='w-6 h-6 text-tGreen' aria-hidden='true' />
                        </div>
                      )}
                      <Tooltip
                        content={exercise.name}
                        triggerClasses={`w-[70%] whitespace-nowrap overflow-hidden text-ellipsis pr-2 ${
                          selected ? '!w-[60%]' : ''
                        }`}
                      >
                        {exercise.name}
                      </Tooltip>
                      <div className='flex items-center justify-end ml-auto'>
                        {exercise.date && (
                          <span className='flex items-center py-1 px-2 ml-2 text-xxs bg-gray-500 text-white rounded-md whitespace-nowrap'>
                            {format(exercise.date, 'MM-yy')}
                          </span>
                        )}
                        {usedExercises.includes(exKey) && (
                          <span className='py-1 px-2 ml-2 text-xxs bg-gray-500 text-white rounded-md whitespace-nowrap'>
                            in use
                          </span>
                        )}
                      </div>
                    </div>
                  </SuggestionItem>
                )}
              </Combobox.Option>
            ))
          )}
        </SuggestionContainer>
      </Combobox.Options>
    </Portal.Root>
  )
}
