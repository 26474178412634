import React, { useState } from 'react'
import { sortBy, times } from 'lodash'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensors, useSensor } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'

import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenIntroPagesQuery, useListenResourcesQuery, useUpdateResourcesMutation } from './featuredResourcesApi'
import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'

import { buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { EmptyStateContainer } from 'common/components/EmptyStateContainer/EmptyStateContainer'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { Sortable } from 'common/components/Sortable/Sortable'
import { dropAnimation } from 'common/utils/dndUtils'
import Resource from './Resource'
import ResourceForm from './ResourceForm/ResourceForm'
import { ResourceSkeleton } from './ResourceSkeleton'

function FeaturedResources() {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: resourcesData } = useListenResourcesQuery({ coachOrgId })
  const resourcesLoading = resourcesData === undefined || resourcesData?.isLoading
  const resources = resourcesData || {}
  const resourceEntries = sortBy(Object.entries(resources), ([_, resource]) => resource.resourceIdx)

  const { data: videoData } = useListenCoachVideosQuery({ coachOrgId })
  const videosLoading = videoData === undefined || videoData?.isLoading
  const videos = videoData || {}

  const { data: introPagesData } = useListenIntroPagesQuery({ coachOrgId })
  const introPagesLoading = introPagesData === undefined || introPagesData?.isLoading

  const [updateResources] = useUpdateResourcesMutation()

  const [activeDrag, setActiveDrag] = useState(null)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const handleDragStart = ({ active }) => {
    setActiveDrag(resources[active.id])
  }

  const handleDragEnd = async ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = resourceEntries.findIndex(([resourceId]) => resourceId === active.id)
      const newIndex = resourceEntries.findIndex(([resourceId]) => resourceId === over.id)
      const updatedResourceEntries = arrayMove(resourceEntries, oldIndex, newIndex)

      let resourceUpdate = {}
      updatedResourceEntries.forEach(([resourceId], idx) => {
        resourceUpdate[`${resourceId}/resourceIdx`] = idx
      })
      await updateResources({ coachOrgId, resources: resourceUpdate })
      setActiveDrag(null)
    }
  }

  const isLoading = resourcesLoading || videosLoading || introPagesLoading
  const resourceIds = resourceEntries.map(([resourceId]) => resourceId)

  return (
    <div className='flex flex-col min-h-screen bg-offWhite px-16'>
      <div className='w-full max-w-7xl mx-auto mt-12'>
        <div className='flex items-start justify-between mb-10'>
          <div className='mr-6'>
            <h1 className='font-bold text-5xl text-tBlack'>Featured Resources</h1>
            <p className='text-gray-500 mt-4'>Resources to immediately showcase in the app’s home page.</p>
          </div>
          <div className='ml-6'>
            <Dialog>
              <DialogTrigger css={[buttonBase, buttonVariants.primary, buttonSizes.lg]}>Add resource</DialogTrigger>
              <DialogContent header='Add resource'>
                <FormRefsControlProvider>
                  <ResourceForm coachOrgId={coachOrgId} resourceEntries={resourceEntries} />
                </FormRefsControlProvider>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        {isLoading ? (
          <div className='mb-20'>
            {times(3, (index) => (
              <ResourceSkeleton key={index} />
            ))}
          </div>
        ) : (
          <div className='mb-20'>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={resourceIds} strategy={verticalListSortingStrategy}>
                {resourceEntries.map(([resourceId, resource]) => (
                  <Sortable
                    key={resourceId}
                    id={resourceId}
                    withHandle={true}
                    className='group'
                    draggingClasses='ring-2 ring-tGreen rounded-2xl opacity-0'
                  >
                    <Resource
                      resource={resource}
                      resourceEntries={resourceEntries}
                      videos={videos}
                      coachOrgId={coachOrgId}
                    />
                  </Sortable>
                ))}
              </SortableContext>
              <DragOverlay
                zIndex={10}
                className='cursor-move'
                dropAnimation={dropAnimation}
                modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}
              >
                {activeDrag && (
                  <div className='group mb-5 ring-2 ring-tGreen rounded-2xl'>
                    <Resource resource={activeDrag} videos={videos} coachOrgId={coachOrgId} />
                  </div>
                )}
              </DragOverlay>
            </DndContext>
          </div>
        )}
        {!isLoading && !resourceEntries?.length && <EmptyStateContainer text='No featured resources' />}
      </div>
    </div>
  )
}

export default FeaturedResources
