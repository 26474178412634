import { useEffect, useState } from 'react'
import { useReactFlow, getNodesBounds } from '@xyflow/react'
import { QUIZ_FLOW_TOP_OFFSET } from '../index'

const DEFAULT_SELECT_ZOOM = 1.2
const ZOOM_DURATION = 850

function UpdateQuizLayout({ selectedNodeId, layoutedNodes }) {
  const { setCenter, getViewport, setViewport } = useReactFlow()
  const [prevViewport, setPrevViewport] = useState(null)
  const nodeCount = layoutedNodes?.length

  useEffect(() => {
    if (selectedNodeId) {
      const viewport = getViewport()
      if (!prevViewport) {
        setPrevViewport(viewport)
      }
      const layoutedSelectedNode = layoutedNodes.find((n) => n?.id === selectedNodeId)
      const isFirstQuestion =
        layoutedSelectedNode?.type === 'question' && !layoutedSelectedNode?.data?.previousQuestionId
      const selectedNodeBounds = getNodesBounds([layoutedSelectedNode])
      const quizHeight = document.documentElement.clientHeight - QUIZ_FLOW_TOP_OFFSET
      const zoom = viewport.zoom > DEFAULT_SELECT_ZOOM ? viewport.zoom : DEFAULT_SELECT_ZOOM
      const extraVerticalSpace = 48
      const extraZoom = 0.05
      // adjust the zoom for small screen heights when node height cannot fit the screen height
      const adjustedZoom =
        quizHeight <= selectedNodeBounds?.height * zoom + extraVerticalSpace
          ? Math.round((quizHeight / selectedNodeBounds?.height) * 100) / 100 - extraZoom
          : zoom

      if (isFirstQuestion) {
        const horizontalSpacing = 32
        const quizWidth = document.documentElement.clientWidth - horizontalSpacing
        const offsetLeft = quizWidth / 2.2 / adjustedZoom
        setCenter(selectedNodeBounds?.x + offsetLeft, selectedNodeBounds?.y + selectedNodeBounds?.height / 2, {
          duration: ZOOM_DURATION,
          zoom: adjustedZoom,
        })
      } else {
        setCenter(
          selectedNodeBounds?.x + selectedNodeBounds?.width / 2,
          selectedNodeBounds?.y + selectedNodeBounds?.height / 2,
          {
            duration: ZOOM_DURATION,
            zoom: adjustedZoom,
          }
        )
      }
    } else {
      if (prevViewport) {
        setViewport(prevViewport, { duration: ZOOM_DURATION })
        setPrevViewport(null)
      }
    }
    // eslint-disable-next-line
  }, [selectedNodeId, layoutedNodes, nodeCount])

  return null
}

export default UpdateQuizLayout
