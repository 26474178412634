import React from 'react'
import 'twin.macro'

import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { TableCell } from './styles'

export function LoadingRow() {
  return (
    <tr className='h-24' data-testid='loading-row'>
      <TableCell tw='w-1/12'>
        <Skeleton tw='w-14 md:w-20 h-14 md:h-20 rounded-lg md:rounded-xl ml-2 md:ml-0' />
      </TableCell>
      <TableCell tw='w-7/12'>
        <Skeleton tw='w-full md:w-96' />
      </TableCell>
      <TableCell tw='w-2/12' hideOnMobile>
        <Skeleton />
      </TableCell>
      <TableCell tw='w-2/12' hideOnMobile>
        <Skeleton />
      </TableCell>
      <td className='relative'>
        <div />
      </td>
    </tr>
  )
}
