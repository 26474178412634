import tw from 'twin.macro'
import React from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'

import { DialogClose } from 'common/components/Dialog/Dialog'
import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { useDialog } from '../Dialog/hooks/useDialog'

const defaultTitleText = 'Are you sure?'
const defaultCreateBtnText = 'Create'

export function CreateDialogBanner({
  titleText = defaultTitleText,
  createBtnText = defaultCreateBtnText,
  text,
  subtext,
  handleCreate,
  loading = false,
  children,
}) {
  const [, setDialogOpen] = useDialog()

  const onCreate = async () => {
    await handleCreate()
    setDialogOpen(false)
  }

  return (
    <div className='px-10 pt-14 pb-8'>
      {titleText && (
        <DialogPrimitive.Title className='font-bold text-2xl text-tBlack text-center mb-4'>
          {titleText}
        </DialogPrimitive.Title>
      )}
      <DialogPrimitive.Description className='max-w-xs mx-auto text-center text-tBlack mb-6'>
        <span className='block'>{text}</span>
        {subtext && <span className='block'>{subtext}</span>}
      </DialogPrimitive.Description>
      {children}
      <div className='flex-1 flex items-center'>
        <DialogClose css={[buttonBase, buttonVariants.secondary, buttonSizes.lg, tw`flex-1`]}>Cancel</DialogClose>
        <Button onClick={onCreate} loading={loading} css={tw`flex-1 ml-4`}>
          {createBtnText}
        </Button>
      </div>
    </div>
  )
}
