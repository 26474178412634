import tw from 'twin.macro'
import React from 'react'

function Tag({ text, remove, className, ...otherProps }) {
  const handleClick = (e) => {
    e.stopPropagation()
    remove(text)
  }

  return (
    <TagContainer {...otherProps} className={className}>
      <span className='break-all' data-testid='tag'>
        {text}
      </span>{' '}
      {remove && (
        <button className='ml-2' type='button' aria-label={`remove ${text} tag`} onClick={handleClick} tabIndex={-1}>
          &#10005;
        </button>
      )}
    </TagContainer>
  )
}

const TagContainer = tw.span`mr-2 mb-2 py-1 px-2 inline-flex text-sm leading-5 font-semibold rounded-lg bg-offWhite text-tBlack text-opacity-70`

export default Tag
