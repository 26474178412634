// import { difference, filter } from 'lodash'
import { getStandardizedName } from 'common/utils/stringUtils'
import { each } from 'lodash'

export const getSuggestedExercises = (exerciseList, exerciseInputValue) => {
  if (!exerciseList || exerciseInputValue.length === 0) {
    return []
  }

  //factors for ordering suggestions ranking
  //1. matches - if not, just exclude
  //2. startsWith - if so, show above all others
  //3. length - if startsWith AND length, then show above

  const exInputStd = getStandardizedName(exerciseInputValue)

  //filter for exercises that match
  const filtered = {}
  each(exerciseList, (ex, exId) => {
    const stdExName = getStandardizedName(ex.name)
    const matches = stdExName?.includes(exInputStd)
    if (matches) {
      filtered[exId] = ex
    }
  })

  const ranked = {}
  each(filtered, (ex, exId) => {
    if (!ex || !ex.name || !exId || exId === 'undefined' || exId === 'null') return

    //if exercise name starts with input, then rank it strongly
    const exStdName = getStandardizedName(ex.name)
    const exStartsWithInput = exStdName.startsWith(exInputStd)
    if (exStartsWithInput) {
      //use 100 to denote a greater weight than the next rank factor of length
      ranked[exId] = 100
    } else {
      ranked[exId] = 0
    }

    //if exercise name is shorter, increase rank but not strong
    const currRankVal = ranked[exId]
    ranked[exId] = currRankVal + ex.name.length * -1
  })

  //convert to Object.entries format to match previous setup
  //then sort by rankVal
  const rankedEntries = []
  each(ranked, (rankVal, exId) => {
    const exercise = exerciseList[exId]
    rankedEntries.push([exId, exercise, rankVal])
  })

  rankedEntries.sort(([exIdA, exA, rankA], [exIdB, exB, rankB]) => {
    return rankB - rankA
  })

  return rankedEntries
}
