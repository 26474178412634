import { useState } from 'react'
import { useDndMonitor } from '@dnd-kit/core'

export const useMonitorExDragOver = ({ monitorPartId }) => {
  const [overEx, setOverEx] = useState(null)
  const onDragOver = ({ over, active }) => {
    if (!over || !active) {
      return
    }

    const { itemType: dragItemType, part: draggingPart, workout: draggingWkt } = active.data.current
    const { itemType: overItemType, part: overPart, workout: overWkt } = over.data.current

    if (draggingPart?.id === overPart?.id && overEx !== null) {
      setOverEx(null)
    }

    const overPartId = over.data?.current?.part?.id

    if (
      dragItemType !== 'exercise' ||
      overItemType === 'workout' ||
      draggingWkt.id !== overWkt.id ||
      draggingPart.id === overPart.id ||
      overPart.type === 'rest' ||
      overPartId !== monitorPartId
    ) {
      setOverEx(null)
      return
    }

    const overExIdx = over.data.current.exIdx

    // Dragged over part
    // Check for undefined because can be 0
    if (overExIdx !== undefined) {
      setOverEx({ wktId: overWkt.id, exIdx: overExIdx, partId: overPartId, insertTop: null, insertBottom: null })
    } else if (overExIdx === undefined && overItemType === 'partTop') {
      setOverEx({ wktId: overWkt.id, exIdx: null, partId: overPartId, insertTop: true, insertBottom: null })
    } else if (overExIdx === undefined && overItemType === 'partBottom') {
      setOverEx({ wktId: overWkt.id, exIdx: null, partId: overPartId, insertTop: null, insertBottom: true })
    }
  }

  const onDragEnd = () => {
    setOverEx(null)
  }

  useDndMonitor({ onDragOver, onDragEnd })

  return overEx
}
