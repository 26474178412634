import tw from 'twin.macro'
import { CgImage, CgCloseO } from 'react-icons/cg'
import { maybePluralize } from 'common/utils/stringUtils'
import { useCustomization } from 'common/contexts/Customization/useCustomization'

export function UploadErrors({ uploadErrors }) {
  const { exVidOrientation } = useCustomization()

  return (
    <>
      <h4 className='font-semibold text-lg mb-4'>
        <Counter>{uploadErrors?.length}</Counter> {maybePluralize({ count: uploadErrors.length, text: 'Upload' })}{' '}
        failed
      </h4>
      {uploadErrors?.map((error) => (
        <UploadErrorItem key={error.id} error={error} exVidOrientation={exVidOrientation} />
      ))}
    </>
  )
}

function UploadErrorItem({ error, exVidOrientation }) {
  return (
    <div className='relative flex items-center mb-4 last:mb-0 group'>
      <div className='pr-3'>
        <div
          css={[
            tw`relative w-14 h-14 rounded-md overflow-hidden bg-tBlack`,
            exVidOrientation === 'portrait' && tw`w-10 h-16`,
          ]}
        >
          <div className='w-full h-full flex items-center justify-center'>
            <CgImage className='text-white opacity-50 w-5 h-5' />
          </div>
        </div>
      </div>
      <div className='flex-col pr-3'>
        <h4 className='font-semibold capitalize max-w-[450px] truncate'>{error.videoName}</h4>
        <p className='text-xs text-tRed mt-1 first-letter:uppercase'>{error.errors.join(',')}.</p>
      </div>
      <div className='ml-auto'>
        <CgCloseO className='text-tRed w-8 h-8 m-1 mx-[5px]' />
      </div>
    </div>
  )
}

const Counter = tw.span`inline-flex items-center justify-center font-medium
  leading-none text-sm text-white w-7 h-7 bg-tRed rounded-full mr-1`
