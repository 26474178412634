import { getCloudinaryVidPreviewImg } from './cloudinaryUtils'

export const getThumbnail = ({ thumbnailUrl, videoUrl, options }) => {
  if (!thumbnailUrl && !videoUrl) {
    return ''
  } else if (thumbnailUrl) {
    return thumbnailUrl
  } else {
    return getCloudinaryVidPreviewImg(videoUrl, {
      crop: 'thumb',
      ...options,
    })
  }
}
