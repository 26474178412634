import { each, trim } from 'lodash'

export const maybePluralize = ({ count, text, suffix = 's' }) =>
  count > 1 ? pluralize({ text, suffix }) : `${trim(text)}`

export const pluralize = ({ text, suffix = 's' }) => {
  const trimmedText = trim(text)
  const lastCharMatchesSuffix = trimmedText[trimmedText.length - 1].toLowerCase() === suffix
  return `${trimmedText}${lastCharMatchesSuffix ? '' : suffix}`
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const capitalizeFirstLetterOfWords = (string) => {
  const split = string.split(' ')

  const firstLetterCapitalized = split.map((word) => {
    if (!word) return ''

    return word[0].toUpperCase() + word.substr(1)
  })

  const joined = firstLetterCapitalized.join(' ')
  return joined
}

export const getStandardizedName = (name) => {
  if (typeof name !== 'string' || (!name && name !== '')) {
    return name
  }

  return trim(name).toLowerCase()
}

export const getSentenceCaseText = (text) => {
  let sentences = text.split('.')
  let finalText = ''

  each(sentences, (_, idx) => {
    let spaceCount = sentences[idx].replace(/^(\s*).*$/, '$1').length
    sentences[idx] = sentences[idx].replace(/^\s+/, '')
    let spaceput = spaceCount >= 1 ? ' ' : ''

    let updatedSentence = ''
    const sentenceStartsWithNumber = /^\d+$/.test(sentences[idx])
    if (!sentenceStartsWithNumber) {
      updatedSentence = sentences[idx].charAt(sentences[idx].toString()).toUpperCase() + sentences[idx].slice(1)
    } else {
      updatedSentence = sentences[idx]
    }

    finalText = finalText + spaceput + updatedSentence + '.'
  })

  finalText = finalText.substring(0, finalText.length - 1)
  return finalText
}
