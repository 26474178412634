import tw from 'twin.macro'
import React from 'react'
import { CgChevronRight } from 'react-icons/cg'

import { Button } from 'common/components/Button/Button'
import { availableItemTypes } from '../constants/constants'

const getItemTypeSelectors = (hideItems) => {
  let itemTypeSelectors = {
    PROGRAMGROUP: {
      type: availableItemTypes.PROGRAMGROUP,
      readable: 'series',
    },
    PROGRAM: {
      type: availableItemTypes.PROGRAM,
      readable: 'Programs / Workouts',
    },
    VIDEO: {
      type: availableItemTypes.VIDEO,
      readable: 'videos',
    },
    COLLECTION: {
      type: availableItemTypes.COLLECTION,
      readable: 'collections',
    },
  }

  Object.entries(itemTypeSelectors).forEach(([itemKey, item]) => {
    if (hideItems.includes(item.type)) {
      delete itemTypeSelectors[itemKey]
    }
  })

  return itemTypeSelectors
}

export function ItemTypeSelectors({ setCurrentItem, hideItems, children }) {
  const handleTypeSelection = (type) => {
    setCurrentItem({ type, id: null })
  }

  return (
    <div className='flex flex-col'>
      {Object.values(getItemTypeSelectors(hideItems)).map(({ type, readable }) => (
        <Button
          key={type}
          type='button'
          onClick={() => handleTypeSelection(type)}
          variant='secondary'
          size='md'
          css={tw`flex-1 flex px-4 mb-2 last:mb-0`}
        >
          <span className='first-letter:capitalize text-left flex-1'>{readable}</span>
          <CgChevronRight className='w-[18px] h-[18px] hover:text-tGreen' />
        </Button>
      ))}
      {children}
    </div>
  )
}
