import { globalApi, listenFirebase, updateFirebase } from 'modules/App/globalApi'

export const featuredResourcesApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenResources: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        await listenFirebase(`coachOrgs/${coachOrgId}/featuredResources`, api)
      },
    }),
    updateResources: build.mutation({
      queryFn: async ({ coachOrgId, resources }) => {
        const { data, error } = await updateFirebase(`coachOrgs/${coachOrgId}/featuredResources`, resources)
        return { data, error }
      },
    }),
    listenResourceImg: build.query({
      queryFn: () => ({ data: false }),
      onCacheEntryAdded: async ({ coachOrgId, id }, api) => {
        await listenFirebase(`coachOrgs/${coachOrgId}/featuredResources/${id}/previewImg`, api)
      },
    }),
    listenIntroPages: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        await listenFirebase(`coachOrgs/${coachOrgId}/introPages`, api)
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenResourcesQuery,
  useUpdateResourcesMutation,
  useListenResourceImgQuery,
  useListenIntroPagesQuery,
} = featuredResourcesApi
