import { isEmpty } from 'lodash'
import { getSortedNodesAndEdges } from './flowDataUtils'
import ELK from 'elkjs/lib/elk.bundled.js'
const elk = new ELK()

const NODE_WIDTH = 384

export const getLayoutedElements = async ({ nodes, edges, noNodesSelected }) => {
  const firstNode = nodes.find((n) => n?.type === 'question' && !n?.data?.previousQuestionId)
  const { sortedNodes, sortedEdges } = getSortedNodesAndEdges({ firstNode, nodes, edges })

  const layoutOptions = {
    'elk.algorithm': 'layered',
    'elk.direction': 'RIGHT',
    'elk.layered.spacing.nodeNodeBetweenLayers': '60', //Node to node across layers (like horizontal spacing)
    'elk.spacing.nodeNode': '40', //Node to Node within a layer (like vertical spacing)
    'elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX',
  }
  // Set up the Elk graph structure
  const elkGraph = {
    id: 'root',
    layoutOptions,
    children: sortedNodes
      .filter((node) => !node.hidden)
      .map((node) => {
        const heightToUse = getNodeHeight({ node, isExpanded: node?.selected, noNodesSelected })

        return {
          id: node.id,
          width: NODE_WIDTH,
          height: heightToUse,
          properties: {
            'org.eclipse.elk.portConstraints': 'FIXED_ORDER', //Prevents edges from overlapping
          },
        }
      }),
    edges: sortedEdges
      .filter((edge) => !edge.hidden)
      .map((edge) => ({
        id: `${edge.source}-${edge.target}`,
        sources: [edge.source],
        targets: [edge.target],
      })),
  }

  // Run the Elk layout algorithm
  const layoutedGraph = await elk.layout(elkGraph)

  // Update node positions based on the layout results
  layoutedGraph.children.forEach((layoutedNode) => {
    const node = sortedNodes.find((n) => n.id === layoutedNode.id)
    if (node && !node.hidden) {
      node.position = {
        x: layoutedNode.x - NODE_WIDTH / 2,
        y: layoutedNode.y,
      }
      node.width = NODE_WIDTH
      node.height = getNodeHeight({ node, isExpanded: node?.selected, noNodesSelected })
      node.targetPosition = 'left'
      node.sourcePosition = 'right'
    }
  })

  return { nodes: sortedNodes, edges: sortedEdges }
}

export function getNodeHeight({ node, isExpanded, noNodesSelected }) {
  if (node?.hidden) {
    return 0
  }

  const questionNode = node?.type === 'question' && !isEmpty(node?.data?.answers)
  if (questionNode) {
    if (noNodesSelected) {
      const answerCount = Object.values(node.data.answers).length
      const containerVertPadding = 16
      const inputSize = 32
      const collapsedAnswerSize = 20
      return containerVertPadding + inputSize + answerCount * collapsedAnswerSize
    } else if (isExpanded) {
      const answerCount = Object.values(node.data.answers).length
      const containerVertPadding = 32
      const inputSize = 32
      const titleMarginBottom = 4
      const descMarginBottom = 8
      const addAnswerBtnHeight = answerCount < 5 ? 32 : 0

      //Single Answer Card Size
      const inputSpacing = 4
      const answerBorder = 2
      const cardBottomMargin = 8 //mb-2 in Sortable className of QuestionNode.js
      const cardVertPadding = 12 //6px * 2
      const expandedAnswerSize = inputSize * 2 + inputSpacing + cardVertPadding + cardBottomMargin + answerBorder

      const total =
        containerVertPadding +
        inputSize * 2 +
        titleMarginBottom +
        descMarginBottom +
        answerCount * expandedAnswerSize +
        addAnswerBtnHeight
      return total
    } else {
      //Prior question is falling back to this value
      const containerVertPadding = 16
      const inputSize = 32
      const collapsedAnswerSize = Boolean(node?.data?.selectedQuestionParentAnswer) ? 20 : 0

      return containerVertPadding + inputSize + collapsedAnswerSize
    }
  }

  const selectQuestionOrResultNode = node?.type === 'selector'
  if (selectQuestionOrResultNode) {
    return 180 //Two buttons - 56px each, header 20px, header margin 8px, node padding 32 px, button spacing 8px
  }

  const resultNode = node?.type === 'result' && !isEmpty(node?.data?.result)
  if (resultNode) {
    const resultCard = 32
    const nodePaddingCollapsed = 16
    const nodePaddingExpanded = 32
    if (isExpanded) {
      return resultCard + nodePaddingExpanded
    } else {
      return resultCard + nodePaddingCollapsed
    }
  }

  return 142
}

export function getAnswerHandleTopOffset({
  index,
  isNodeCollapsed,
  answerCount,
  noNodesSelected,
  selectedQuestionParentAnswerExist,
}) {
  const containerVertPadding = 32
  const containerVertPaddingCollapsed = 16

  const questionTitleInputHeight = 32
  const titleMarginBottom = 4
  const questionDescrInputHeight = 32
  const descMarginBottom = 8

  const answerCardVertPadding = 12
  const answerCardMarginSpacing = 4
  const answerCardHeight =
    questionDescrInputHeight + questionDescrInputHeight + answerCardVertPadding + answerCardMarginSpacing
  const answerCardMarginBottom = 8
  const connectorDotHeightOffset = 1

  const collapsedAnswerHeight = 20
  if (isNodeCollapsed) {
    if (noNodesSelected) {
      return (questionTitleInputHeight + containerVertPaddingCollapsed + answerCount * collapsedAnswerHeight) / 2
    } else if (selectedQuestionParentAnswerExist) {
      return (containerVertPaddingCollapsed + questionTitleInputHeight + collapsedAnswerHeight) / 2
    } else {
      return (containerVertPaddingCollapsed + questionTitleInputHeight) / 2
    }
  } else {
    return (
      containerVertPadding / 2 +
      questionTitleInputHeight +
      titleMarginBottom +
      questionDescrInputHeight +
      descMarginBottom +
      (answerCardHeight + connectorDotHeightOffset) / 2 +
      index * (answerCardHeight + answerCardMarginBottom)
    )
  }
}
