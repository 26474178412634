import { useEventListener } from './useEventListener'
import { debounce } from 'lodash'

/**
 *
 * @param {*} modifierKey - Ctrl or Cmd (Meta) key. Checks against ctrlKey or metaKey
 * @param {*} letterKey - Single letter paired with letter to activate hotkey
 * @param {*} callback - Occurs upon pressing the hotkey combination
 * @param {*} options
 */

export function useHotkey(modifierKey, letterKey, callback, options = {}) {
  const { isActive } = options

  const debouncedCallback = debounce(callback, 25) //very minor debounce to catch if user is holding keys

  const handleKeyPress = (e) => {
    const letterMatches = e.key === letterKey
    const otherModifiersNotPressed =
      (modifierKey === 'ctrl' && !e.shiftKey && !e.metaKey && !e.altKey) ||
      (modifierKey === 'meta' && !e.shiftKey && !e.ctrlKey && !e.altKey)
    const modifierMatches = (modifierKey === 'ctrl' && e.ctrlKey) || (modifierKey === 'meta' && e.metaKey)

    if (isActive && letterMatches && modifierMatches && otherModifiersNotPressed) {
      e.preventDefault()
      e.stopPropagation()
      debouncedCallback()
    }
  }

  useEventListener('keydown', handleKeyPress)
}
