import tw, { styled } from 'twin.macro'
import React from 'react'
import { CgImage, CgCloseO, CgTime } from 'react-icons/cg'

import Tag from 'common/components/Tag/Tag'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'

import { cardContainerBaseStyles, cardHoverStyles } from '../styles'

export const LayoutCard = ({ id, type, name, image, subtitle, time, handleDelete }) => {
  let prettyType = type
  if (type === 'programGroup') prettyType = 'series'

  let timeInMinutes
  if (time) {
    const [hours, minutes, secondsWithMs] = time.split(':')
    const roundedSecToMin = parseInt(secondsWithMs) > 0 ? 1 : 0
    timeInMinutes = parseInt(hours) * 60 + parseInt(minutes) + roundedSecToMin
  }

  return (
    <div css={[cardContainerBaseStyles]} role='layout-card' aria-label={`${type}-${id}`}>
      <CardContent hasImage={image} css={[cardHoverStyles]}>
        <div className='flex justify-between'>
          <div className='flex items-center'>
            <Tag text={prettyType.toLowerCase()} css={tw`text-xs rounded-md m-0`} />
            {time && <Tag text={<Time timeInMinutes={timeInMinutes} />} css={tw`text-xs rounded-md m-0 ml-2`} />}
          </div>
          <Dialog>
            <DialogTrigger id='dialog-button' className='visible md:invisible' aria-label='Layout card delete'>
              <CgCloseO
                data-no-dnd='true'
                className='cursor-pointer w-5 h-5 text-gray-50 hover:text-tRed transition-colors'
              />
            </DialogTrigger>
            <DialogContent overlayProps={{ 'data-no-dnd': true }}>
              <DeleteDialogBanner
                text={`This will remove ${name} from being displayed in the row`}
                handleDelete={() => handleDelete()}
              />
            </DialogContent>
          </Dialog>
        </div>
        <div className='mt-auto'>
          <div className='text-white font-medium' data-testid='layout-card-title'>
            {name}
          </div>
          <div className='text-gray-200 text-sm' data-testid='layout-card-subtitle'>
            {subtitle}
          </div>
        </div>
      </CardContent>
      {Boolean(image) && <img alt={name} src={image} className='w-full h-full object-cover' />}
      {!Boolean(image) && (
        <CgImage className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-50 w-7 h-7' />
      )}
    </div>
  )
}

const Time = ({ timeInMinutes }) => (
  <span className='flex items-center'>
    <CgTime className='w-4 h-4 text-gray-500 mr-1' />
    {timeInMinutes} min
  </span>
)

const CardContent = styled.div(({ hasImage }) => [
  tw`flex flex-col p-4 absolute inset-0 bg-tBlack`,
  hasImage && tw`bg-opacity-30`,
])
