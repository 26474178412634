import tw from 'twin.macro'
import { CgArrowRight, CgCheckO } from 'react-icons/cg'
import { motion } from 'framer-motion'

import { ColorInput } from 'common/components/Color/ColorInput'
import { Button } from 'common/components/Button/Button'

export const variants = {
  enter: (isStepForwards) => {
    return {
      x: isStepForwards ? 500 : -500,
      opacity: 0,
    }
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (isStepForwards) => {
    return {
      x: isStepForwards ? -500 : 500,
      opacity: 0,
    }
  },
}

export const transition = {
  duration: 0.3,
  type: 'tween',
}

export function Question({
  questionData,
  answers,
  setAnswers,
  error,
  setError,
  inputRef,
  handleNext,
  isLastQuestion,
  loading,
  isStepForwards,
}) {
  const handleInputChange = (e) => {
    const value = e.target.value
    const alphanumericalRegex = new RegExp(/^(?!\s)[0-9a-zA-Z ]+$/)

    const prevTypedChar = value[value.length - 2]
    const currTypedChar = e.nativeEvent.data

    const isDoublySpaced = prevTypedChar === ' ' && currTypedChar === ' '
    const isAlphanumericalOrEmpty = alphanumericalRegex.test(value) || !value

    if (isAlphanumericalOrEmpty && !isDoublySpaced) {
      setError(null)
      setAnswers({ ...answers, [questionData.questionKey]: value })
    }
  }

  return (
    <motion.div
      variants={variants}
      custom={isStepForwards}
      initial='enter'
      animate='center'
      exit='exit'
      transition={transition}
      className='max-w-xl m-auto w-full'
    >
      <div className='mb-6'>
        <label htmlFor={questionData.questionKey} className='flex font-bold text-xl'>
          {questionData.question}
        </label>
        {questionData?.note && (
          <div className='mt-2'>
            <small className='text-base'>{questionData.note}</small>
          </div>
        )}
      </div>
      <div className='flex flex-col relative'>
        <div className='relative'>
          {questionData.inputType === 'color' && (
            <ColorInput
              containerClasses='!my-0'
              watchColor={answers[questionData.questionKey]}
              onChange={(answer) => {
                setError(null)
                setAnswers({ ...answers, [questionData.questionKey]: answer })
              }}
              inputCSS={[inputBase, tw`px-1 ml-4 w-20`, error && tw`border-b-tRed focus:border-b-tRed`]}
            />
          )}
          {questionData.inputType === 'text' && (
            <input
              id={questionData.questionKey}
              name={questionData.questionKey}
              autoFocus={true}
              value={answers[questionData.questionKey]}
              onChange={handleInputChange}
              ref={inputRef}
              placeholder='Type your answer here...'
              type='text'
              maxLength={getInputMaxLength(questionData.questionKey)}
              css={[inputBase, tw`w-full px-0`, error && tw`border-b-tRed focus:border-b-tRed`]}
              pattern='[a-zA-Z0-9]'
            />
          )}
          {questionData.inputType === 'radio' &&
            questionData.options.map((option) => (
              <div className='flex items-center mb-2 last:mb-0' key={option}>
                <input
                  id={option}
                  name={questionData.questionKey}
                  value={option}
                  onChange={() => setAnswers({ ...answers, [questionData.questionKey]: option })}
                  checked={answers[questionData.questionKey] === option}
                  type='radio'
                  className='cursor-pointer rounded-full border-tBlack border-opacity-20 text-tGreen focus:ring-0 w-[18px] h-[18px]'
                />
                <label htmlFor={option} className='cursor-pointer text-tBlack text-xl ml-3 capitalize'>
                  {option}
                </label>
              </div>
            ))}
          {error && <div className='absolute -bottom-6 left-0 text-sm text-tRed'>{error}</div>}
        </div>
        <div className='flex items-center mt-10'>
          {isLastQuestion ? (
            <Button size='md' onClick={handleNext} loading={loading} data-testid='nextBtn'>
              Submit <CgCheckO className='ml-2 w-[18px] h-[18px] ' />
            </Button>
          ) : (
            <Button size='md' onClick={handleNext} loading={loading} data-testid='nextBtn'>
              Next <CgArrowRight className='ml-2 w-5 h-5' />
            </Button>
          )}
          <div className='ml-2 text-sm text-gray-400'>
            or press <strong>Enter</strong>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

const getInputMaxLength = (questionKey) => {
  switch (questionKey) {
    case 'coachName':
      return 40
    case 'orgName':
      return 30
    default:
      return 40
  }
}

const inputBase = tw`flex bg-transparent text-xl text-tBlack placeholder:text-gray-400
border-0 border-b border-gray-400 focus:border-0 focus:border-b focus:border-b-tGreen focus:ring-0 py-2`
