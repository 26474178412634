import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CgAdd, CgRemove, CgRepeat } from 'react-icons/cg'
import { useUpdateWorkoutMutation } from '../programApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { updateInstrForSets } from '../utils/instructionsSetsUtils'

export default function Sets(props) {
  const numSets = props.part.sets || 1
  const setsDescrEnd = numSets === 1 ? 'SET' : 'SETS'
  const setsDescr = numSets + ' ' + setsDescrEnd

  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  return (
    <div>
      {props.isEditing ? (
        <EditSets
          partIdx={props.partIdx}
          weekIdx={props.weekIdx}
          dayIdx={props.dayIdx}
          workout={props.workout}
          part={props.part}
          isEditing={props.isEditing}
          dispatch={props.dispatch}
          orgId={coachOrgId}
        />
      ) : (
        <div className='flex flex-row items-center'>
          <div className='text-xs font-semibold'>{setsDescr}</div>
          <CgRepeat className='w-5 h-5 text-tGreen ml-1' />
        </div>
      )}
    </div>
  )
}

function EditSets(props) {
  const [sets, updateSets] = useState(props.part.sets || 1)
  const [updateWorkout] = useUpdateWorkoutMutation()
  const { id: programId } = useParams()

  useEffect(() => {
    updateSets(props.part.sets || 1)
  }, [props.part.sets])

  return (
    <div className='flex flex-row items-center'>
      <div className='text-xs font-semibold'>SETS:</div>
      <div className='flex flex-row items-center ml-2'>
        <button
          onClick={() => sets > 1 && onClickIncr('subtract', sets, updateSets, props, programId, updateWorkout)}
          tabIndex={-1}
        >
          <CgRemove className='w-4 h-4 text-gray-500 hover:text-tGreen' />
        </button>
        <div className='text-sm font-semibold text-tGray-dark px-1'>{sets}</div>
        <button onClick={() => onClickIncr('add', sets, updateSets, props, programId, updateWorkout)} tabIndex={-1}>
          <CgAdd className='w-4 h-4 text-gray-500 hover:text-tGreen' />
        </button>
      </div>
      <CgRepeat className='w-5 h-5 text-tGreen ml-1' />
    </div>
  )
}

const onClickIncr = (command, sets, updateSets, props, programId, updateWorkout) => {
  const newSets = command === 'add' ? sets + 1 : sets - 1
  updateSets(newSets)

  const wrktUpdated = JSON.parse(JSON.stringify(props.workout))
  const currPart = wrktUpdated.parts[props.partIdx]

  currPart.sets = newSets

  currPart.instructions = updateInstrForSets({
    instr: currPart.instructions,
    numSets: newSets,
  })

  updateWorkout({
    orgId: props.orgId,
    workoutId: props.workout.id,
    workout: wrktUpdated,
  })
}
