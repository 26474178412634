import React from 'react'
import { Link } from 'react-router-dom'
import { IoRocketOutline } from 'react-icons/io5'

import { useListenHelpCenterCategoriesQuery } from './helpCenterApi'

import Loading from 'modules/App/components/Loading'
import SearchInput from './components/searchInput'

function HelpCenter() {
  const { data: categories } = useListenHelpCenterCategoriesQuery()

  return (
    <div className='flex flex-col items-center w-screen bg-offWhite min-h-full'>
      <div className='p-12 mb-10 w-screen bg-tGreen'>
        <h1 className='text-white text-4xl font-bold text-center mb-5'>Help Center</h1>
        <SearchInput />
      </div>
      {!categories ? (
        <Loading />
      ) : (
        <div className='px-10 pb-10 grid grid-cols-3 gap-4 max-w-6xl w-full'>
          {Object.entries(categories).map(([id, category]) => (
            <Card key={id} category={category} />
          ))}
        </div>
      )}
    </div>
  )
}

const Card = ({ category: { title, description, image, content } }) => (
  <div className='bg-white rounded hover:shadow-st'>
    <Link to={`/help/${content[0].contentId}`} className='flex flex-col items-center p-10'>
      {image ? <img src={image} className='mb-1.5' alt={title} /> : <IoRocketOutline size='4rem' />}
      <h4 className='text-base my-2.5'>{title}</h4>
      <p className='text-xs text-zinc-600 text-center'>{description}</p>
    </Link>
  </div>
)

export default HelpCenter
