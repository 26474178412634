import tw, { styled, css } from 'twin.macro'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Handle, Position, useReactFlow } from '@xyflow/react'
import { FiAlertTriangle } from 'react-icons/fi'
import { useUpdateQuestionsMutation, useUpdateResultMutation } from '../appOnboardingQuizApi'

import { createUID } from 'common/utils/createUID'

import { AddItemButton } from 'common/components/AddItemButton/AddItemButton'
import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { Button } from 'common/components/Button/Button'

import { updateQuizError } from '../quizFlowSlice'
import QuestionMoreActions from './QuestionMoreActions'

const TypeSelectorNode = memo(({ id, data, selected }) => {
  const dispatch = useDispatch()
  const { getNode } = useReactFlow()

  const [updateQuestions] = useUpdateQuestionsMutation()
  const [updateResult] = useUpdateResultMutation()

  const errors = useSelector((state) => state.quizFlow.quizErrors?.[id])
  const copiedQuestion = useSelector((state) => state.quizFlow.copiedQuestion)

  const onPickQuestion = (e) => {
    e.stopPropagation()
    const newQuestionId = createUID()
    const newQuestion = {
      id: newQuestionId,
      previousQuestionId: id,
    }
    updateQuestions({
      coachOrgId: data?.coachOrgId,
      update: {
        [`${id}/answers/${newQuestionId}`]: {
          index: 0,
          nextQuestionId: newQuestionId,
        },
        [newQuestionId]: newQuestion,
      },
    })
  }

  const handleAddResult = (item) => {
    const prevQuestionNode = getNode(data?.previousQuestionId)
    const prevQuestionAnswers = prevQuestionNode?.data?.answers || {}
    updateQuestions({
      coachOrgId: data?.coachOrgId,
      update: {
        [`${prevQuestionNode?.id}/answers/${id}`]: {
          index: prevQuestionAnswers?.[id]?.index,
          title: prevQuestionAnswers?.[id]?.title || '',
          description: prevQuestionAnswers?.[id]?.description || '',
          resultId: id,
          result: item,
        },
        [id]: null,
      },
    })
    updateResult({
      coachOrgId: data?.coachOrgId,
      questionId: prevQuestionNode?.id,
      resultId: id,
      itemId: item.id,
    })
    dispatch(updateQuizError({ nodeId: id, errors: null }))
  }

  return (
    <Container errors={Boolean(errors)} selected={selected} wasSelected={data?.wasSelected}>
      <InnerContainer>
        <Handle type='target' position={Position.Left} isConnectable={false} />
        {copiedQuestion && (
          <QuestionMoreActions
            questionData={data}
            copiedQuestion={copiedQuestion}
            pasteOnly={true}
            coachOrgId={data?.coachOrgId}
          />
        )}
        <h1 className='font-medium text-sm mb-2 h-5'>Please select</h1>
        <Button
          variant='secondary'
          size='md'
          css={tw`bg-gray-100 hover:bg-gray-100 hover:ring-2 hover:ring-gray-400 transition-all w-full mb-2 flex-col h-14`}
          className='nodrag nopan'
          onClick={onPickQuestion}
        >
          <div>Question</div>
          <div className='text-xxs font-normal leading-tight'>Ask another question</div>
        </Button>
        <AddItemButton
          coachOrgId={data?.coachOrgId}
          handleAdd={handleAddResult}
          btnCSS={tw`bg-gray-100 hover:bg-gray-100 hover:ring-2 hover:ring-gray-400 transition-all w-full flex-col h-14`}
          btnClasses='nopan nodrag'
          customBtnContent={
            <>
              <div>Result</div>
              <div className='text-xxs font-normal leading-tight'>Select resulting workout, video, or program</div>
            </>
          }
          popoverContentClasses='nopan nodrag'
          hideItems={[availableItemTypes.PROGRAMGROUP, availableItemTypes.COLLECTION]}
        />
        {errors?.nodeError && <p className='absolute bottom-0 text-xxs text-red-500'>{errors.nodeError}</p>}
        <Tooltip
          content='Select either question or result'
          triggerClasses='absolute bg-white z-10 rounded-full -right-2 top-1/2 -translate-y-1/2'
        >
          <FiAlertTriangle className='w-5 h-5 text-orange-300' />
        </Tooltip>
      </InnerContainer>
    </Container>
  )
  //   }
})

export default TypeSelectorNode

const Container = styled.div(({ errors, selected, wasSelected, isDragging }) => [
  tw`border rounded-md border-gray-300`,
  wasSelected && tw`border-black border-2`,
  errors && tw`border-red-500 border`,
  selected && tw`border-tGreen border`,
  isDragging && tw`pointer-events-none`,
])

const InnerContainer = styled.div(({ isNodeCollapsed }) => [
  tw`relative p-4 w-96`,
  isNodeCollapsed && tw`p-2`,
  css`
    &:hover #more-actions {
      display: flex;
    }
  `,
])
