// NOTE - type order matters here
export const availableItemTypes = {
  PROGRAMGROUP: 'programGroup',
  PROGRAM: 'program',
  WORKOUT: 'workout',
  VIDEO: 'video',
  COLLECTION: 'collection',
}

export const MAX_TAB_COUNT = 10
