import tw from 'twin.macro'
import React from 'react'

import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { CardRowContainer, rowContainerBaseStyles, RowHeaderContainer, cardContainerBaseStyles } from '../styles'

export function LoadingLayoutRow({ className = '' }) {
  return (
    <div css={rowContainerBaseStyles} className={className}>
      <RowHeaderContainer>
        <Skeleton css={tw`w-40`} />
      </RowHeaderContainer>
      <CardRowContainer>
        {[...Array(1).keys()].map((key) => (
          <Skeleton key={key} css={cardContainerBaseStyles} />
        ))}
      </CardRowContainer>
    </div>
  )
}
