import tw from 'twin.macro'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { multiSelectReferencesCleared, selectedExercisesCleared } from '../exerciseLibrarySlice'
import { useUpdateExercisesMutation } from '../exerciseLibraryApi'
import { useUpdateProgressionsMutation } from 'modules/Progressions/progressionsApi'

export function ActionPanel({ coachOrgId, selectedExercises, progressions }) {
  const dispatch = useDispatch()
  const { createAlert } = useAlert()
  const [updateExercises, { isLoading: isDeletingEx }] = useUpdateExercisesMutation()
  const [updateProgressions, { isLoading: isDeletingProg }] = useUpdateProgressionsMutation()
  const isDeleting = isDeletingEx || isDeletingProg

  const handleDeleteExercises = async () => {
    let exercisesUpdate = {}
    let progressionsUpdate = {}

    selectedExercises.forEach((selectedExId) => {
      // Progressions update
      if (progressions) {
        const exProgression = Object.entries(progressions).find(([_key, value]) => value.includes(selectedExId))

        if (exProgression) {
          const [progressionKey, progressionExercises] = exProgression

          if (progressionsUpdate[progressionKey]) {
            progressionsUpdate[progressionKey] = progressionsUpdate[progressionKey].filter(
              (exId) => exId !== selectedExId
            )
          } else {
            progressionsUpdate[progressionKey] = progressionExercises.filter((exId) => exId !== selectedExId)
          }
        }
      }

      // Exercises update
      exercisesUpdate[selectedExId] = null
    })

    if (!isEmpty(progressionsUpdate)) {
      await updateProgressions({ coachOrgId, progressions: progressionsUpdate })
    }
    await updateExercises({ coachOrgId, exercises: exercisesUpdate })

    dispatch(selectedExercisesCleared())
    dispatch(multiSelectReferencesCleared())
    createAlert({ text: 'Exercises deleted!', type: 'success' })
  }

  return (
    <div className='sticky bottom-0 inset-x-0 bg-white px-4 py-4 z-50 border-b border-gray-300 shadow-t-md'>
      <div className='flex items-center max-w-7xl mx-auto justify-between border-gray-100'>
        <div className='flex items-center'>
          <Counter>{selectedExercises.length}</Counter>
          <Text>selected exercises</Text>
        </div>
        <div className='flex items-center'>
          <Button
            isLoading={isDeleting}
            size='md'
            variant='secondary'
            css={tw`mr-3 bg-transparent`}
            onClick={() => {
              dispatch(selectedExercisesCleared())
              dispatch(multiSelectReferencesCleared())
            }}
          >
            Clear selection
          </Button>
          <Dialog>
            <DialogTrigger css={[buttonBase, buttonVariants.danger, buttonSizes.md]}>Delete</DialogTrigger>
            <DialogContent>
              <DeleteDialogBanner
                text={`This will delete ${selectedExercises.length} exercises`}
                loading={isDeleting}
                handleDelete={() => handleDeleteExercises()}
              />
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

const Counter = tw.span`inline-flex items-center justify-center font-medium
  leading-none text-sm text-gray-500 w-7 h-7 bg-gray-300 rounded-full`

const Text = tw.div`ml-3 mr-10 text-tBlack font-semibold lowercase`
