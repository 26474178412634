import { createSlice } from '@reduxjs/toolkit'

export const weekRenderTypes = {
  show: 'show',
  preview: 'preview',
}

const initialState = {
  lastScrolled: 0,
  weeksToRender: {
    0: weekRenderTypes.show,
    1: weekRenderTypes.show,
    2: weekRenderTypes.preview,
    3: weekRenderTypes.preview,
    //undefined means week will be hidden
  },
}

const programRenderingSlice = createSlice({
  name: 'programRendering',
  initialState,
  reducers: {
    programViewed: (state) => {
      //reset weeksToRender
      state.weeksToRender = initialState.weeksToRender
    },
    programScrolled: (state) => {
      state.lastScrolled = new Date().getTime()
    },
    scrolledToPreviewWeek: (state, action) => {
      const { weekIdx } = action.payload

      //always show weeks in batches of 2, ie. 0 & 1, 2 & 3, etc
      //so take the even number. ie. if weekIdx is 5, use 4
      const decrement = weekIdx % 2
      const weekIdxToUse = weekIdx - decrement

      //let week render workouts
      state.weeksToRender[weekIdxToUse] = weekRenderTypes.show
      state.weeksToRender[weekIdxToUse + 1] = weekRenderTypes.show
    },
    fullWeekRendered: (state, action) => {
      const { weekIdx } = action.payload

      //always show weeks in batches of 2, ie. 0 & 1, 2 & 3, etc
      //so take the even number. ie. if weekIdx is 5, use 4
      const decrement = weekIdx % 2
      const weekIdxToUse = weekIdx - decrement

      //show previews for next 2 weeks
      const firstWkIdxToPreview = weekIdxToUse + 2
      const secondWkIdxToPreview = weekIdxToUse + 3

      //if currently hiding, show; else if already showing, do not change back to previews
      if (!state.weeksToRender[firstWkIdxToPreview]) {
        state.weeksToRender[firstWkIdxToPreview] = weekRenderTypes.preview
        state.weeksToRender[secondWkIdxToPreview] = weekRenderTypes.preview
      }
    },
  },
})

export const { programViewed, programScrolled, scrolledToPreviewWeek, fullWeekRendered } = programRenderingSlice.actions

export default programRenderingSlice.reducer
