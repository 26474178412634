import React, { useEffect, useRef, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensors, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { find, findIndex, trim } from 'lodash'

import { CueInput } from './CueInput'
import { Sortable } from 'common/components/Sortable/Sortable'
import { dropAnimation } from 'common/utils/dndUtils'
import { useEventListener } from 'common/hooks/useEventListener'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useHotkey } from 'common/hooks/useHotkey'
import { IS_MAC_OS } from 'common/utils/detectOS'

export function CueInputList({
  setAccordionValue,
  inputRefsSortMethod,
  onEmptyInputKeyPress,
  moveFocusOnKeyUp = true,
}) {
  const addCueRef = useRef()
  const { watch, setFocus, setValue, register } = useFormContext()
  const { moveFocusedInputBy, inputRefs, moveInputRefs } = useFormRefsControl()

  const { fields, append, remove, update } = useFieldArray({
    name: 'cues',
  })

  const watchCues = watch('cues')
  const controlledFields = fields.map((field, index) => {
    return {
      id: field.id,
      value: watchCues[index],
    }
  })
  const controlledFieldsLength = controlledFields?.length

  const [isCueInputFocused, setIsCueInputFocused] = useState(false)

  useEffect(() => {
    //Add empty cue iput when rendered if no cues exist
    if (!fields?.length) {
      append('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Drag and drop
  const [activeDragInput, setActiveDragInput] = useState(null)

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const handleDragStart = ({ active }) => {
    const activeFieldIndex = controlledFields.findIndex((field) => field.id === active.id)
    const activeField = controlledFields[activeFieldIndex]
    setActiveDragInput({ index: activeFieldIndex, ...activeField })
  }

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = controlledFields.findIndex((field) => field.id === active.id)
      const newIndex = controlledFields.findIndex((field) => field.id === over.id)

      const newCueList = arrayMove(
        controlledFields.map((field) => field.value),
        oldIndex,
        newIndex
      )
      setValue('cues', newCueList)
      moveInputRefs('cue', oldIndex, newIndex)

      setActiveDragInput(null)
    }
  }

  const moveFocusOnKeyPress = (e) => {
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()
      const activeIndex = findIndex(inputRefs, (input) => input.ref.current === document.activeElement)
      const cueIdx = inputRefs[activeIndex].cueIdx

      if (!e.shiftKey) {
        const nextCueInput = find(inputRefs, (input) => input.cueIdx === cueIdx + 1)
        const activeCueInputEmpty = trim(find(inputRefs, (input) => input.cueIdx === cueIdx).ref.current.value)
        if (!activeCueInputEmpty) {
          if (onEmptyInputKeyPress) {
            onEmptyInputKeyPress(e)
          }
        } else if (!nextCueInput) {
          append('')
        } else {
          moveFocusedInputBy(1)
        }
      } else if (e.shiftKey) {
        moveFocusedInputBy(-1)
      }
    }
  }

  const handleAddCueOnEnter = (e) => {
    if (document.activeElement === addCueRef.current && e.code === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      append('')
    }
  }

  const handleDeleteCue = (cueIdx) => {
    const newCues = watchCues.filter((cue, idx) => idx !== cueIdx)
    setValue('cues', newCues)
  }

  useEventListener('keyup', handleAddCueOnEnter)

  const pasteCues = async () => {
    const focusedCueInput = inputRefs
      .filter((ref) => ref.name === 'cue')
      .find((inp) => inp.ref.current === document.activeElement)

    if (!focusedCueInput) {
      return
    }

    if (!focusedCueInput?.ref.current.value) {
      const clipboardText = await navigator.clipboard.readText()
      const cues = clipboardText.split('\n')
      if (focusedCueInput.ref.current.value === '') {
        const newCues = [
          ...watchCues.slice(0, focusedCueInput.cueIdx),
          ...cues,
          ...watchCues.slice(focusedCueInput.cueIdx + 1),
        ]

        update(`cues.${focusedCueInput.cueIdx}`, newCues[focusedCueInput.cueIdx])
        setValue('cues', newCues)
        setAccordionValue('formCues')
      }
    } else if (focusedCueInput?.ref.current.value) {
      const clipboardText = await navigator.clipboard.readText()
      setValue(`cues.${focusedCueInput.cueIdx}`, `${watchCues[focusedCueInput.cueIdx]} ${clipboardText}`)
    }
  }

  useEffect(() => {
    if (controlledFieldsLength) {
      setFocus(`cues.${controlledFieldsLength - 1}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledFieldsLength])

  const modKey = IS_MAC_OS ? 'meta' : 'ctrl'
  useHotkey(modKey, 'v', pasteCues, { isActive: isCueInputFocused })

  const onFocus = () => {
    //Need to watch focus state to prevent hotkey from firing when pasting and a CueInput is not focused
    setIsCueInputFocused(true)
  }

  const onBlur = () => {
    setIsCueInputFocused(false)
  }

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={controlledFields} strategy={verticalListSortingStrategy}>
          {controlledFields.map((field, index) => (
            <Sortable
              key={field.id}
              id={field.id}
              withHandle={true}
              className='group relative flex flex-col mb-3 px-4 md:px-10'
              draggingClasses='ring-2 ring-tGreen ring-offset-4 opacity-50'
            >
              <CueInput
                register={register}
                setValue={setValue}
                index={index}
                value={field.value}
                remove={(cueIdx) => handleDeleteCue(cueIdx)}
                formCueValues={watchCues}
                inputRefsSortMethod={inputRefsSortMethod}
                onKeyUp={moveFocusOnKeyUp ? moveFocusOnKeyPress : undefined}
                onKeyDown={moveFocusOnKeyUp ? undefined : moveFocusOnKeyPress}
                autoFocus={true}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            </Sortable>
          ))}
        </SortableContext>
        <DragOverlay
          zIndex={10}
          className='cursor-move'
          dropAnimation={dropAnimation}
          modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}
        >
          {activeDragInput && (
            <div className='relative flex flex-col group px-10 bg-gray-100'>
              <CueInput
                index={activeDragInput.index}
                value={activeDragInput.value}
                remove={remove}
                formCueValues={watchCues}
              />
            </div>
          )}
        </DragOverlay>
      </DndContext>
      <div className='flex items-center justify-between'>
        <button
          type='button'
          className='text-tBlack text-opacity-50 hover:text-opacity-80 transition-all ml-11 hover:bg-gray-100 p-2 rounded-md'
          onClick={() => {
            append('')
            setFocus(`cues.${controlledFieldsLength - 1}`)
          }}
          ref={addCueRef}
          tabIndex={-1}
        >
          &#43; Add cue
        </button>
      </div>
    </>
  )
}
