import 'twin.macro'
import React from 'react'
import { CgChevronRight } from 'react-icons/cg'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenCoachProgramsQuery, useListenProgramGroupsQuery } from 'modules/Layout/layoutApi'

import { Button } from 'common/components/Button/Button'
import { selectButtonActiveStyles, selectButtonBaseStyles } from '../styles'
import { getItemExists, includesSearchQuery } from '../utils'
import { availableItemTypes } from '../constants/constants'
import { TextHint } from 'common/components/TextHint/TextHint'

export function ProgramSelection({
  existingItems,
  currentItem,
  previousItem,
  handleSelection,
  searchQuery,
  handleNext,
}) {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''
  const { data: programGroups } = useListenProgramGroupsQuery({ coachOrgId })
  const { data: coachPrograms } = useListenCoachProgramsQuery({ coachOrgId })
  const coachProgramEntries = coachPrograms ? Object.entries(coachPrograms) : []
  const dataLoading =
    programGroups === undefined || programGroups?.isLoading || programGroups === undefined || coachPrograms?.isLoading

  const programGroupId = previousItem
    ? previousItem.find((item) => item.type === availableItemTypes.PROGRAMGROUP).id
    : null

  if (dataLoading) {
    return <div className='text-sm'>Loading...</div>
  }

  const availablePrograms = getProgramsToShow()

  if (!availablePrograms?.length) {
    return (
      <div>
        <div className='text-sm'>No program results.</div>
        <div className='pt-2'>
          <TextHint
            hintText="Can't find a program?"
            expandedText='Programs need to be published before they can be added to App Layout.'
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <ProgramSelectionList
        availablePrograms={availablePrograms}
        handleSelection={handleSelection}
        currentItem={currentItem}
        existingItems={existingItems}
        handleNext={handleNext}
      />
      <div className='sticky bottom-0 bg-white pt-2 pr-2'>
        <div>
          <TextHint
            hintText="Can't find a workout?"
            expandedText='You can find workouts by pressing right chevron icon on one of the program buttons.'
          />
        </div>
        <TextHint
          hintText="Can't find a program?"
          expandedText='Programs need to be published before they can be added to App Layout.'
        />
      </div>
    </>
  )

  function getProgramsToShow() {
    let programsToShow

    if (programGroupId) {
      //only show programs that match search query and belong to programGroup
      programsToShow = coachProgramEntries.filter(
        ([programId, programName]) =>
          includesSearchQuery(programName, searchQuery) && programGroups[programGroupId].programs.includes(programId)
      )
    } else {
      programsToShow = coachProgramEntries.filter(([_, programName]) => includesSearchQuery(programName, searchQuery))
    }

    return programsToShow
  }
}

const ProgramSelectionList = ({ availablePrograms, handleSelection, currentItem, existingItems, handleNext }) => {
  return availablePrograms.map(([programId, programName]) => (
    <Button
      key={programId}
      type='button'
      onClick={() => handleSelection({ id: programId })}
      variant='secondary'
      size='md'
      css={[selectButtonBaseStyles, currentItem.id === programId && selectButtonActiveStyles]}
    >
      <div className='flex-1 flex items-center'>
        <span>{programName}</span>
        {getItemExists({
          existingItems,
          itemId: programId,
          itemType: availableItemTypes.PROGRAM,
        }) && (
          <span className='flex whitespace-nowrap py-0 px-2 ml-2 text-xxs bg-gray-500 text-white rounded-md'>
            in use
          </span>
        )}
      </div>
      <div
        role='button'
        onClick={(e) => {
          e.stopPropagation()
          handleNext({ type: availableItemTypes.PROGRAM, id: programId })
        }}
        aria-label={`go-to-${availableItemTypes.WORKOUT}-from-${programId}`}
      >
        <CgChevronRight className='w-[18px] h-[18px] hover:text-tGreen ml-2' />
      </div>
    </Button>
  ))
}
