import tw from 'twin.macro'
import React, { Fragment, useEffect, useRef } from 'react'
import { CgChevronDown } from 'react-icons/cg'
import { Combobox } from '@headlessui/react'
import * as Portal from '@radix-ui/react-portal'

import { SuggestionContainer, SuggestionItem } from 'common/components/Suggestions/Suggestions'
import { inputBase } from 'common/components/Input/Input'
import { usePopper } from 'common/hooks/usePopper'
import { useListenIntroPagesQuery } from '../featuredResourcesApi'
import { useListenExLibEnabledQuery } from 'modules/ExerciseLibrary/exerciseLibraryApi'
import { useListenQuizPublishedAtQuery } from 'modules/AppOnboardingQuiz/appOnboardingQuizApi'

export const availableScenes = [
  {
    value: 'LinkHub',
    displayName: 'Additional resources',
    defaultImg: 'https://d3ts90okmyjsrp.cloudfront.net/trybe/addl-resources-480.svg',
  },
  {
    value: 'ExerciseLibrary',
    displayName: 'Exercise library',
    defaultImg: 'https://d3ts90okmyjsrp.cloudfront.net/trybe/ex-library-480.svg',
  },
  {
    value: 'Intro',
    displayName: 'App quickstart guide',
    defaultImg: 'https://d3ts90okmyjsrp.cloudfront.net/trybe/quick-start-guide-480.svg',
  },
  {
    value: 'OnboardingQuiz',
    displayName: 'Onboarding quiz',
    defaultImg: 'https://d3ts90okmyjsrp.cloudfront.net/trybe/quick-start-guide-480.svg',
  },
]

export default function SceneInput({ inputRef, value, setValue, coachOrgId, previewImg }) {
  const { data: introPages } = useListenIntroPagesQuery({ coachOrgId })
  const { data: exLibEnabled } = useListenExLibEnabledQuery({ coachOrgId })
  const { data: onboardingQuizPublishedAt } = useListenQuizPublishedAtQuery({ coachOrgId })
  const isOnboardingQuizPublished =
    onboardingQuizPublishedAt !== undefined &&
    !onboardingQuizPublishedAt?.isLoading &&
    Boolean(onboardingQuizPublishedAt)

  const comboboxBtnRef = useRef()

  const handleInputKeyDown = (e) => {
    const acceptedCodes = ['Enter', 'Tab', 'ArrowUp', 'ArrowDown']
    if (!acceptedCodes.includes(e.code)) {
      e.preventDefault()
    } else if (e.code === 'Enter' && !e.shiftKey) {
      comboboxBtnRef.current.click()
    }
  }

  useEffect(() => {
    const hasDefaultImg = availableScenes.some((scene) => scene.defaultImg === previewImg)
    if (!previewImg || hasDefaultImg) {
      const defaultImg = availableScenes.find((scene) => scene.value === value).defaultImg
      setValue('previewImg', defaultImg)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let [popperTrigger, popperContainer] = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  const inputWidth = inputRef?.current?.getBoundingClientRect()?.width
  const filteredScenes = availableScenes.filter((scene) => {
    if (scene.value === 'ExerciseLibrary') {
      return exLibEnabled === true
    } else if (scene.value === 'Intro') {
      return Boolean(introPages)
    } else if (scene.value === 'OnboardingQuiz') {
      return isOnboardingQuizPublished
    } else {
      return true
    }
  })

  return (
    <div className='mb-4'>
      <label className='capitalize inline-flex cursor-pointer font-semibold text-tBlack' tw='mb-1'>
        Scene
      </label>
      <Combobox
        value={value}
        onChange={(val) => {
          setValue('scene', val)
          const hasDefaultImg = availableScenes.some((scene) => scene.defaultImg === previewImg)
          if (!previewImg || hasDefaultImg) {
            const defaultImg = availableScenes.find(({ value }) => value === val).defaultImg
            setValue('previewImg', defaultImg)
          }
        }}
      >
        <div ref={popperTrigger} className='w-full'>
          <Combobox.Button ref={comboboxBtnRef} as='div' className='relative w-full'>
            <Combobox.Input
              ref={(e) => {
                inputRef.current = e
              }}
              placeholder='Enter scene'
              autoComplete='off'
              displayValue={(value) => availableScenes.find((scene) => scene.value === value)?.displayName}
              onKeyDown={handleInputKeyDown}
              className='text-ellipsis bg-inherit cursor-pointer'
              css={[inputBase, tw`caret-transparent`]}
              autoFocus={true}
            />
            <CgChevronDown className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500 w-4 h-4' />
          </Combobox.Button>
        </div>
        <Portal.Root>
          <Combobox.Options className='z-[999]' ref={popperContainer} modal={false}>
            <SuggestionContainer containerStyle={{ width: inputWidth }}>
              {filteredScenes.map((scene) => (
                <Combobox.Option as={Fragment} key={scene.value} value={scene.value}>
                  {({ active }) => (
                    <SuggestionItem active={active}>
                      <div className='flex items-center'>{scene.displayName}</div>
                    </SuggestionItem>
                  )}
                </Combobox.Option>
              ))}
            </SuggestionContainer>
          </Combobox.Options>
        </Portal.Root>
      </Combobox>
    </div>
  )
}
