import React from 'react'
import { Link, useParams } from 'react-router-dom'
import tw, { styled } from 'twin.macro'

import Loading from 'modules/App/components/Loading'
import { useListenHelpCenterCategoriesQuery, useListenHelpCenterContentQuery } from './helpCenterApi'

const ListItem = styled.span(({ isActive }) => [
  tw`
  block 
  relative 
  mb-6 
  pl-6 
  before:absolute 
  before:left-0 
  before:top-0
  before:w-0.5
  before:h-full
  before:bg-black
  before:opacity-0
  hover:before:opacity-100
`,
  isActive && tw`before:opacity-100`,
])

const TitleLarge = tw.h1`
  text-3xl
  font-bold
  mb-5
`

const TitleSmall = tw.h3`
  text-xl
  font-bold
  mb-2.5
`

function HelpCenterPage() {
  const { contentId } = useParams()

  const { data: content } = useListenHelpCenterContentQuery(contentId)

  const { data: categories } = useListenHelpCenterCategoriesQuery()
  const currentCategory =
    categories && Object.values(categories).find((cat) => cat.content.find((cnt) => cnt.contentId === contentId))

  return (
    <div className='flex justify-center w-screen p-10 bg-offWhite min-h-screen'>
      <div className='flex justify-center max-w-5xl w-full'>
        {!currentCategory || !content ? (
          <Loading />
        ) : (
          <>
            <div className='flex flex-col max-w-[280px] w-full'>
              <h3 className='text-lg font-bold my-4'>{currentCategory.title}</h3>
              <ul>
                {currentCategory.content.map((item) => (
                  <li key={item.contentId}>
                    <Link to={`/help/${item.contentId}`}>
                      <ListItem isActive={contentId === item.contentId}>{item.navbarTitle}</ListItem>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className='flex flex-col px-8 w-full bg-offWhite min-h-screen'>
              <div className='flex flex-col'>
                {content.map((item, idx) => (
                  <DecideContent key={`content-${idx}`} content={item} />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const DecideContent = ({ content }) => {
  const { type, image, title, titleSize, paragraphs } = content

  if (type === 'image') {
    return <img className='mb-10' src={image} alt='' />
  }

  return (
    <div className='flex flex-col mb-10'>
      {title ? titleSize === 'large' ? <TitleLarge>{title}</TitleLarge> : <TitleSmall>{title}</TitleSmall> : null}
      {paragraphs
        ? paragraphs.map((paragraph, idx) => <ParagraphContent key={`paragraph-${idx}`} paragraph={paragraph} />)
        : null}
    </div>
  )
}

const ParagraphContent = ({ paragraph }) => {
  if (!paragraph.includes('<')) return <p className='mb-1.5'>{paragraph}</p>
  const words = paragraph.split(' ')
  const newWords = words.map((part) => {
    // var regex = /(?<=\<)(.*?)(?=\>)/ //doesnt work on safari
    var regex = /([A-Z])\w+/
    var matched = regex.exec(part)
    if (matched) {
      const bracketsText = matched[1]
      const [tag, value] = bracketsText.split('|')
      const textAfterLink = part.split('>')[1]
      const textWithoutLink = part.slice(0, matched['index'] - 1)
      if (!value) return `<${tag}>${textWithoutLink}</${tag}>${textAfterLink}`
      // we can customize it as much  as we want
      return `<a class='text-tGreen underline' href='${value}' target='_blank'>${textWithoutLink}</a>${textAfterLink}`
    }

    return part
  })
  return <p className='mb-1.5' dangerouslySetInnerHTML={{ __html: newWords.join(' ') }}></p>
}

export default HelpCenterPage
