import React from 'react'

import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'

import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'

import ResultCard from './ResultCard'
import ResultLoadingCard from './ResultLoadingCard'

function VideoResult({ coachOrgId, item, selected }) {
  const { data: videoData } = useListenCoachVideosQuery({ coachOrgId })
  const videos = videoData || {}
  const videosLoading = videoData === undefined || videoData?.isLoading
  const video = videos?.[item?.id]

  if (videosLoading) {
    return <ResultLoadingCard selected={selected} />
  }

  if (!video) {
    return null
  }

  return (
    <ResultCard
      name={video?.title}
      description={video?.subtitle}
      type={availableItemTypes.VIDEO}
      previewImg={video?.previewImg}
      selected={selected}
    />
  )
}

export default VideoResult
