import React from 'react'

import { getDayZone } from '../utils/dayTimeUtils'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenCoachOrgNameQuery } from 'modules/Account/accountApi'

import Loading from 'modules/App/components/Loading'

function GreetingsHeader({ userId }) {
  const { data: profileData } = useListenUserProfileQuery({ userId })
  const profileLoading = profileData === undefined || profileData?.isLoading
  const profile = profileLoading || profileData === null ? {} : profileData

  const { data: coachOrgName } = useListenCoachOrgNameQuery(profile.coachOrgId)

  const dayZone = getDayZone()

  return (
    <div className='mb-6'>
      {profileLoading ? (
        <Loading />
      ) : (
        <>
          <h2 className='text-4xl font-bold mb-2.5'>
            Good {dayZone}, {profile.firstname}
          </h2>
          <p>Here's what's going on with your org {coachOrgName}</p>
        </>
      )}
    </div>
  )
}

export default GreetingsHeader
