import tw from 'twin.macro'

import { buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { maybePluralize } from 'common/utils/stringUtils'

export function UploadDialogHeader({ uploadErrors }) {
  const uploadErrorValues = Object.values(uploadErrors)

  return (
    <div className='relative'>
      <div>Upload exercises</div>
      {uploadErrorValues.length > 0 && (
        <a
          href='#failedUploads'
          css={[
            buttonBase,
            buttonSizes.sm,
            buttonVariants.danger,
            tw`absolute -right-6 -top-6 md:right-0 md:top-0 pointer-events-auto !text-xs`,
          ]}
        >
          {uploadErrorValues.length} {maybePluralize({ count: uploadErrorValues.length, text: 'failed upload' })}
        </a>
      )}
    </div>
  )
}
