import React, { useState } from 'react'
import { sortBy } from 'lodash'

import { Dialog, DialogContent } from 'common/components/Dialog/Dialog'
import { Pagination } from 'common/components/Pagination/Pagination'
import { usePagination } from 'common/components/Pagination/hooks/usePagination'
import { handleSortOfDates } from 'common/utils/sortUtils'

import { TableHead } from './TableHead'
import { TableBody } from './TableBody'
import { EmptyTable } from './EmptyTable'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { CollectionsForm } from '../CollectionForm/CollectionsForm'

export function CollectionsTable({ coachOrgId, filteredCollections, loading, limit = 20 }) {
  const collectionEntries = Object.entries(filteredCollections)
  const totalCollections = Object.keys(filteredCollections).length || 0
  const [sortMethod, setSortMethod] = useState('updated')
  const [sortOrder, setSortOrder] = useState('asc')
  const { setCurrentPage, currentPage, pageLimit, totalPages, offset, itemCount } = usePagination({
    itemCount: totalCollections,
    initialLimit: limit,
  })
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [modalCol, setModalCol] = useState({})
  const tableCols = [
    { title: 'name', sortOrder, setSortOrder, sortMethod, setSortMethod },
    { title: 'created', sortOrder, setSortOrder, sortMethod, setSortMethod },
    { title: 'updated', sortOrder, setSortOrder, sortMethod, setSortMethod },
  ]

  const sortedCollections = sortCollections(collectionEntries, sortOrder, sortMethod)

  return (
    <>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        itemCount={totalCollections}
        totalPages={totalPages}
      />
      <div className='overflow-x-auto'>
        <div className='align-middle inline-block min-w-full'>
          <div className='overflow-hidden border-b border-gray-200 rounded-xl' data-testid='table-wrapper'>
            {!loading && itemCount === 0 ? (
              <EmptyTable />
            ) : (
              <table className='min-w-full divide-y divide-gray-200'>
                <TableHead columns={tableCols} />
                <TableBody
                  coachOrgId={coachOrgId}
                  loading={loading}
                  filteredCollections={sortedCollections}
                  offset={offset}
                  currentPage={currentPage}
                  pageLimit={pageLimit}
                  setModalCol={setModalCol}
                  setEditDialogOpen={setEditDialogOpen}
                />
              </table>
            )}
          </div>
        </div>
      </div>
      <Dialog open={editDialogOpen} setOpen={setEditDialogOpen}>
        <DialogContent header='Edit collection'>
          <FormRefsControlProvider>
            <CollectionsForm
              coachOrgId={coachOrgId}
              collectionKey={modalCol?.collectionKey}
              collection={modalCol?.collection}
              deleteCollection={modalCol?.handleDelete}
            />
          </FormRefsControlProvider>
        </DialogContent>
      </Dialog>
      <div className='mt-4'>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageLimit={pageLimit}
          itemCount={totalCollections}
          totalPages={totalPages}
          counterContainerClasses='place-self-start'
        />
      </div>
    </>
  )
}

export function sortCollections(collections, sortOrder, sortMethod) {
  if (sortMethod === 'updated') {
    return handleSortOfDates(collections, sortOrder, 'updatedAt')
  } else if (sortMethod === 'created') {
    return handleSortOfDates(collections, sortOrder, 'createdAt')
  } else {
    return sortOrder === 'asc'
      ? sortBy(collections, ([key, collection]) => {
          return collection.name && collection.name.toLowerCase()
        }).reverse()
      : sortBy(collections, ([key, collection]) => {
          return collection.name && collection.name.toLowerCase()
        })
  }
}
