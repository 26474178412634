import * as yup from 'yup'
import { emptyStringToNull, trimString } from 'common/utils/yupSchemaUtils'

export const schema = yup
  .object({
    timeEst: yup.string().transform(trimString),
    bottomText: yup.string().max(35, 'Program subtitle must be fewer than 35 characters').transform(trimString),
    previewImg: yup.string().nullable().transform(emptyStringToNull),
    isPaid: yup.boolean(),
  })
  .required()
