import React, { useState } from 'react'
import { CgTrash } from 'react-icons/cg'
import * as Popover from '@radix-ui/react-popover'
import tw from 'twin.macro'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useRemoveSavedBlockMutation } from 'modules/Programs/programApi'
import { Button } from 'common/components/Button/Button'

export default function SavedBlocksListHeader({ orgId, selectedBlockId }) {
  const { createAlert } = useAlert()
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const [removeBlock] = useRemoveSavedBlockMutation()

  if (deleteConfirm) {
    return (
      <div className='flex items-center justify-between py-0.5 mb-2'>
        <div className='text-tBlack leading-none text-sm font-medium mr-2.5'>Confirm</div>
        <div className='flex items-center'>
          <Button
            size='sm'
            variant='secondary'
            css={tw`text-xs bg-transparent ml-2 py-1 whitespace-nowrap`}
            onClick={(e) => {
              e.stopPropagation()
              setDeleteConfirm(false)
            }}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            size='sm'
            variant='danger'
            css={tw`text-xs ml-2 py-1`}
            onClick={(e) => {
              e.stopPropagation()
              handleDeleteBlock({
                orgId,
                blockId: selectedBlockId,
                removeBlock,
                setDeleteConfirm,
                createAlert,
              })
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className='flex justify-between items-center mb-2'>
      <Popover.Close
        css={tw`text-tGreen text-sm rounded-lg px-2 py-1 mr-1 hover:bg-tGreen hover:bg-opacity-10 transition-colors`}
      >
        Close
      </Popover.Close>
      <h4 className='font-bold text-sm text-tBlack first-letter:uppercase'>Saved Blocks</h4>
      <div className='w-12 flex items-center justify-end'>
        <button
          tabIndex={-1}
          onClick={() => setDeleteConfirm(true)}
          disabled={!selectedBlockId}
          className='cursor-pointer'
        >
          <CgTrash className='w-5 h-5 hover:text-tRed' />
        </button>
      </div>
    </div>
  )
}

const handleDeleteBlock = async ({ orgId, blockId, removeBlock, setDeleteConfirm, createAlert }) => {
  await removeBlock({ orgId, blockId })
  setDeleteConfirm(false)
  createAlert({ text: 'Block removed!', type: 'success' })
}
