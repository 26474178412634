import tw from 'twin.macro'
import React from 'react'
import SortableCell from './Cells/SortableCell'

export function TableHead({ columns }) {
  return (
    <thead className='bg-gray-50'>
      <tr>
        <th scope='col' className='px-2 md:px-6 py-3'>
          <span className='sr-only'>Image</span>
        </th>
        {columns.map(({ title, sortOrder, setSortOrder, sortMethod, setSortMethod, hideOnMobile }) => (
          <React.Fragment key={title}>
            {sortMethod ? (
              <SortableCell
                title={title}
                setSortOrder={setSortOrder}
                sortOrder={sortOrder}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
                hideOnMobile={hideOnMobile}
                removeSpacing={title === 'name'}
              />
            ) : (
              <th
                key={title}
                scope='col'
                className='px-2 md:px-6 py-3 text-left font-bold text-gray-500 capitalize'
                css={[hideOnMobile && tw`hidden md:table-cell`]}
              >
                {title}
              </th>
            )}
          </React.Fragment>
        ))}
        <th scope='col' className='relative px-2 md:px-6 py-3'>
          <span className='sr-only'>Edit</span>
        </th>
      </tr>
    </thead>
  )
}
