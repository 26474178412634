import tw from 'twin.macro'
import React, { useState } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'

import { DialogClose } from 'common/components/Dialog/Dialog'
import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { useDialog } from '../Dialog/hooks/useDialog'
import { Input } from '../Input/Input'

const defaultTitleText = 'Are you sure?'

export function DeleteDialogBanner({
  titleText = defaultTitleText,
  text,
  subtext,
  deleteText = 'Delete',
  handleDelete,
  typeToConfirm = false,
  loading = false,
}) {
  const [, setDialogOpen] = useDialog()
  const [deleteConfrimText, setDeleteConfirmText] = useState('')
  const isDeleteConfrimTextValid = typeToConfirm ? deleteConfrimText === 'delete' : true

  const onDelete = async (e) => {
    e.stopPropagation()
    await handleDelete()
    setDialogOpen(false)
  }

  return (
    <div className='px-10 pt-14 pb-8'>
      <DialogPrimitive.Title className='font-bold text-2xl text-tBlack text-center mb-4'>
        {titleText}
      </DialogPrimitive.Title>
      <DialogPrimitive.Description className='max-w-xs mx-auto text-center text-tBlack mb-6'>
        <span className='block'>{text}</span>
        {subtext && <span className='block'>{subtext}</span>}
        {typeToConfirm && (
          <Input
            type='text'
            placeholder='Type "delete" to confirm'
            value={deleteConfrimText}
            onChange={(e) => setDeleteConfirmText(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && isDeleteConfrimTextValid) {
                onDelete(e)
              }
            }}
            autoFocus={true}
            css={tw`mt-4`}
          />
        )}
      </DialogPrimitive.Description>
      <div className='flex-1 flex items-center'>
        <DialogClose
          dialogCloseCb={(e) => e.stopPropagation()}
          css={[buttonBase, buttonVariants.secondary, buttonSizes.lg, tw`flex-1`]}
        >
          Cancel
        </DialogClose>
        <Button
          onClick={onDelete}
          loading={loading}
          disabled={loading || !isDeleteConfrimTextValid}
          variant='danger'
          css={tw`flex-1 ml-4`}
        >
          {deleteText}
        </Button>
      </div>
    </div>
  )
}
