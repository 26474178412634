import tw from 'twin.macro'
import React, { useState } from 'react'
import { CgAdd, CgSearch } from 'react-icons/cg'
import * as Popover from '@radix-ui/react-popover'

import { buttonBase, buttonVariants, buttonSizes } from 'common/components/Button/Button'
import { ScrollArea } from 'common/components/ScrollArea/ScrollArea'
import { NavigationActions } from './components/NavigationActions'
import { ItemTypeSelectors } from './components/ItemTypeSelectors'
import { ProgramGroupSelection } from './components/ProgramGroupSelection'
import { WorkoutSelection } from './components/WorkoutSelection'
import { ProgramSelection } from './components/ProgramSelection'
import { VideoSelection } from './components/VideoSelection'
import { CollectionSelection } from './components/CollectionSelection'
import { availableItemTypes } from './constants/constants'
import { getItemExists } from './utils'

const reactFlowClasses = 'nopan nodrag nowheel'

export function AddItemButton({
  coachOrgId,
  existingItems = [],
  handleAdd,
  btnCSS,
  btnClasses,
  customBtnContent,
  iconClasses,
  popoverContentClasses,
  hideItems = [],
  isPortalled = true,
  children,
}) {
  const [previousItem, setPreviousItem] = useState(null)
  const [currentItem, setCurrentItem] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')

  const cleanup = () => {
    setCurrentItem(null)
    setPreviousItem(null)
    setSearchQuery('')
  }

  return (
    <Popover.Root onOpenChange={() => cleanup()}>
      <Popover.Trigger
        onClick={(e) => e.stopPropagation()}
        css={[buttonBase, buttonVariants.secondary, buttonSizes.md, btnCSS]}
        className={btnClasses}
      >
        {customBtnContent ? (
          customBtnContent
        ) : (
          <>
            <CgAdd className={iconClasses ? iconClasses : 'w-5 h-5 mr-2'} /> Add item
          </>
        )}
      </Popover.Trigger>
      <Popover.Anchor className='absolute top-[-10px] left-6 inset-x-0' />
      {isPortalled ? (
        <Popover.Portal>
          <Content
            coachOrgId={coachOrgId}
            existingItems={existingItems}
            handleAdd={handleAdd}
            popoverContentClasses={popoverContentClasses}
            hideItems={hideItems}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            setPreviousItem={setPreviousItem}
            previousItem={previousItem}
            cleanup={cleanup}
          >
            {children}
          </Content>
        </Popover.Portal>
      ) : (
        <Content
          coachOrgId={coachOrgId}
          existingItems={existingItems}
          handleAdd={handleAdd}
          popoverContentClasses={popoverContentClasses}
          hideItems={hideItems}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          setPreviousItem={setPreviousItem}
          previousItem={previousItem}
          cleanup={cleanup}
        >
          {children}
        </Content>
      )}
    </Popover.Root>
  )
}

const Content = ({
  coachOrgId,
  existingItems,
  handleAdd,
  popoverContentClasses,
  hideItems,
  searchQuery,
  setSearchQuery,
  currentItem,
  setCurrentItem,
  setPreviousItem,
  previousItem,
  cleanup,
  children,
}) => {
  const handleBack = () => {
    if (!previousItem) {
      cleanup()
      return
    }

    setSearchQuery('')
    setCurrentItem(previousItem[previousItem.length - 1])
    const removeLastItem = (arr) => arr.filter((_, index) => index !== arr.length - 1)
    setPreviousItem((state) => (state.length === 1 ? null : removeLastItem(state)))
  }

  const handleNext = (item) => {
    setSearchQuery('')
    setPreviousItem((state) => (state ? [...state, item] : [item]))

    const availableTypeValues = Object.values(availableItemTypes)
    const newTypeIndex = availableTypeValues.indexOf(item.type) + 1
    const newType = availableTypeValues[newTypeIndex]
    setCurrentItem({ type: newType, id: null })
  }

  const handleSelection = ({ id, programId = null, type = null }) => {
    setCurrentItem({ id, programId, type: type ? type : currentItem.type })
  }

  return (
    <Popover.Content
      onClick={(e) => {
        e.stopPropagation()
      }}
      className={`bg-white px-3 py-3 rounded-xl w-80 shadow-2xl z-[999] border-2 border-gray-300 ${reactFlowClasses} ${
        popoverContentClasses ? popoverContentClasses : ''
      }`}
      aria-label='add-card-content'
    >
      <NavigationActions
        previousItem={previousItem}
        currentItem={currentItem}
        handleBack={handleBack}
        coachOrgId={coachOrgId}
      />
      {!currentItem ? (
        <ItemTypeSelectors setCurrentItem={setCurrentItem} hideItems={hideItems}>
          {children}
        </ItemTypeSelectors>
      ) : (
        <div className='flex flex-col'>
          <ScrollArea css={tw`max-h-80`} id='addcard-scrollarea'>
            <div className='flex flex-col'>
              <div className='sticky top-0 bg-white pr-2 pb-2 flex items-center text-tBlack'>
                <CgSearch className='w-4 h-4' />
                <input
                  type='text'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder='Search...'
                  className='flex-1 border-0 text-sm ml-2 bg-transparent placeholder:text-tBlack placeholder:text-opacity-50 focus:ring-0 p-0'
                  autoComplete='off'
                  aria-label='search'
                />
              </div>
              <ItemSelectionRender
                existingItems={existingItems}
                currentItem={currentItem}
                previousItem={previousItem}
                handleSelection={handleSelection}
                searchQuery={searchQuery}
                handleNext={handleNext}
              />
            </div>
          </ScrollArea>
          {currentItem.id && (
            <Popover.Close
              css={[buttonBase, buttonVariants.primary, buttonSizes.md, tw`mt-3`]}
              disabled={getItemExists({
                existingItems,
                itemId: currentItem.id,
                itemType: currentItem.type,
              })}
              onClick={() => handleAdd(currentItem)}
              aria-label='Add selected item'
            >
              Add item <CgAdd className='w-5 h-5 ml-2' />
            </Popover.Close>
          )}
        </div>
      )}
    </Popover.Content>
  )
}

const ItemSelectionRender = (props) => {
  const isSingleVideoWorkout = props.currentItem.type === availableItemTypes.VIDEO && props.currentItem.programId

  if (props.currentItem.type === availableItemTypes.PROGRAMGROUP) {
    return <ProgramGroupSelection {...props} />
  }

  if (props.currentItem.type === availableItemTypes.PROGRAM) {
    return <ProgramSelection {...props} />
  }

  if (props.currentItem.type === availableItemTypes.WORKOUT || isSingleVideoWorkout) {
    return <WorkoutSelection {...props} />
  }

  if (props.currentItem.type === availableItemTypes.VIDEO) {
    return <VideoSelection {...props} />
  }

  if (props.currentItem.type === availableItemTypes.COLLECTION) {
    return <CollectionSelection {...props} />
  }

  return null
}
