import React from 'react'
import TableRow from 'modules/Progressions/ProgressionTable/TableRow'
import { LoadingRow } from 'modules/Progressions/ProgressionTable/LoadingRow'

export function TableBody({ loading, progressions, offset, currentPage, pageLimit, exerciseList }) {
  return (
    <tbody className='bg-white divide-y divide-gray-200'>
      {loading
        ? [...Array(10).keys()].map((key) => <LoadingRow key={key} />)
        : Object.entries(progressions).map(([key, value], index) =>
            index >= offset && index < currentPage * pageLimit ? (
              <TableRow
                key={key}
                progressionKey={key}
                exercises={value}
                progressions={progressions}
                exerciseList={exerciseList}
              />
            ) : null
          )}
    </tbody>
  )
}
