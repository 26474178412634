import React from 'react'

import { useListenProgramGroupsQuery } from 'modules/ProgramGroups/programGroupApi'

import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'

import { ItemCard } from './ItemCard'
import { ItemLoadingCard } from './ItemLoadingCard'

export const ProgramGroupItem = React.memo(({ coachOrgId, item, isDragging, attributes, listeners }) => {
  const { data: programGroupData } = useListenProgramGroupsQuery({ coachOrgId })
  const programGroupsLoading = programGroupData === undefined || programGroupData?.isLoading
  const programGroup = programGroupData?.[item?.id]

  if (programGroupsLoading) {
    return <ItemLoadingCard />
  }

  if (!programGroup) {
    return null
  }

  return (
    <ItemCard
      id={item.id}
      name={programGroup?.name}
      description={programGroup?.description}
      type={availableItemTypes.PROGRAMGROUP}
      previewImg={programGroup?.previewImg}
      isDragging={isDragging}
      attributes={attributes}
      listeners={listeners}
    />
  )
})
