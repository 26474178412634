import React from 'react'
import { selectButtonBaseStyles, selectButtonActiveStyles } from 'common/components/AddItemButton/styles'
import { Button } from 'common/components/Button/Button'
import { getAdjustedUnit } from 'common/components/AddUnitButton/AddUnitButton'

export default function BenchmarksListItem({ benchmark, selectedBenchmarkId, setSelectedBenchmarkId }) {
  const units = benchmark?.units || []

  return (
    <Button
      onClick={() => setSelectedBenchmarkId(benchmark.id)}
      variant='secondary'
      size='md'
      css={[selectButtonBaseStyles, benchmark.id === selectedBenchmarkId && selectButtonActiveStyles]}
    >
      <div className='flex-1'>
        <span className='flex leading-tight line-clamp-2'>{benchmark.name}</span>
        <span className='flex text-xxs text-gray-500 font-normal line-clamp-2 leading-tight my-1'>
          {benchmark.instructions}
        </span>
        <div className='flex items-center'>
          {units.map((unit) => (
            <div
              key={getAdjustedUnit(unit)}
              className='p-1 leading-none rounded bg-gray-50 text-xxs text-gray-500 font-semibold mr-1'
            >
              {getAdjustedUnit(unit)}
            </div>
          ))}
        </div>
      </div>
    </Button>
  )
}
