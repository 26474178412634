import tw, { styled } from 'twin.macro'
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { isEmpty, each } from 'lodash'
import { format, isSameDay } from 'date-fns'
import * as DialogPrimitive from '@radix-ui/react-dialog'

import {
  useGetAllProgramWorkoutsQuery,
  usePublishDraftProgramMutation,
  useUpdateProgramMutation,
  useUpdateWorkoutsMutation,
} from '../../programApi'
import { useListenProgramGroupsQuery } from 'modules/ProgramGroups/programGroupApi'

import { useCustomization } from 'common/contexts/Customization/useCustomization'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useDebouncedEffect } from 'common/hooks/useDebouncedEffect'
import { Dialog, DialogContent } from 'common/components/Dialog/Dialog'
import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { createUID } from 'common/utils/createUID'

import {
  getMissingVidsAndExercises,
  getMatchesForMissingVidsAndEx,
  getOneMatchItems,
  handleUpdateMatchedMissingIds,
} from './utils/missingVideoUtils'
import { getRowsWithProgram } from './utils/rowUtils'
import { getProgramGroupIdsWithProgram } from './utils/programGroupUtils'
import { getQuestionnaire } from './utils/questionnaireUtils'
import { handlePublishProgram } from './utils/publishProgramUtils'
import { QuestionnaireLoader } from './QuestionnaireLoader'
import { useListenAllTabContentQuery, useListenTabsQuery, useSetTabContentMutation } from 'modules/Layout/layoutApi'

export function PublishQuestionnaireDialog({
  questModalIsOpen,
  setQuestModalIsOpen,
  coachOrgId,
  exData,
  benchmarkData,
  videoData,
  wktIdsToDayIdcs,
  program,
}) {
  const { exVidOrientation } = useCustomization()
  const { createAlert } = useAlert()
  const { id: programId } = useParams()
  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = useState(0)
  const [isSecondAnswerDecline, setIsSecondAnswerDecline] = useState(false)
  const [rowsWithProgram, setRowsWithProgram] = useState([])
  const [previewRow, setPreviewRow] = useState(null)
  const [selectedExMatches, setSelectedExMatches] = useState({})
  const [selectedWktVidMatches, setSelectedWktVidMatches] = useState({})

  const [publishDraftProgram, { isLoading: isPublishing }] = usePublishDraftProgramMutation()
  const [saveTabContent, { isLoading: isTabContent }] = useSetTabContentMutation()
  const [updateWorkouts, { isLoading: isUpdatingWkts }] = useUpdateWorkoutsMutation()
  const [updateProgram, { isLoading: isUpdatingProgram }] = useUpdateProgramMutation()
  const isUpdating = isTabContent || isPublishing || isUpdatingWkts || isUpdatingProgram

  // isFetching is needed instead of isLoading to catch a refetch
  const {
    data: allWorkouts,
    isFetching: isFetchingWkts,
    refetch,
  } = useGetAllProgramWorkoutsQuery({ orgId: coachOrgId, programId, wktIdsToDayIdcs })

  const wktsLoading = allWorkouts === undefined || isFetchingWkts
  const exLoading = exData === undefined || exData?.isFiller
  const videoLoading = videoData === undefined || videoData?.isLoading

  let missingVids, foundMatches
  if (!wktsLoading && !exLoading && !videoLoading) {
    missingVids = getMissingVidsAndExercises({ allWorkouts, exData, benchmarkData, exVidOrientation })

    foundMatches = getMatchesForMissingVidsAndEx({ missingVids, exData, videoData })
  }

  const { data: programGroupData, isLoading: isLoadingProgramGroupsData } = useListenProgramGroupsQuery({ coachOrgId })
  const isLoadingProgramGroups = programGroupData?.isLoading || isLoadingProgramGroupsData

  const { data: allTabContent, isLoading: isLoadingAllTabContent } = useListenAllTabContentQuery({ coachOrgId })
  const { data: tabs, isLoading: isTabsLoading } = useListenTabsQuery({ coachOrgId })
  const tabEntries = tabs ? Object.entries(tabs) : []
  const firstTabEntry = tabEntries.find(([, tab]) => tab.tabIdx === 0)

  const isLoadingTabData = allTabContent?.isLoading || isLoadingAllTabContent || tabs?.isLoading || isTabsLoading

  // Refetch up to date workouts on dialog open
  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isLoadingTabData && !isLoadingProgramGroups) {
      // Find program instances in program groups
      const programGroupIdsWithProg = getProgramGroupIdsWithProgram({ programGroups: programGroupData, programId })

      let allTabRowsWithProgram = []
      each(tabs, (tab, tabId) => {
        const rowsWithProgram = getRowsWithProgram({
          tabName: tab.name,
          rowsToCheck: allTabContent?.[tabId],
          programId,
          programGroupIdsWithProg,
        })
        allTabRowsWithProgram = [...allTabRowsWithProgram, ...rowsWithProgram]
      })
      setRowsWithProgram(allTabRowsWithProgram)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingTabData, isLoadingProgramGroups])

  const questionnaireLoading = isLoadingTabData || wktsLoading || exLoading || isLoadingProgramGroups || videoLoading

  const [oneMatchExercisesState, setOneMatchExercises] = useState({})
  const [oneMatchVideosState, setOneMatchVideos] = useState({})

  useEffect(() => {
    if (!questionnaireLoading) {
      const oneMatchExercises = getOneMatchItems(missingVids?.exercises, foundMatches.foundExMatches)
      setOneMatchExercises(oneMatchExercises)
      const oneMatchVids = getOneMatchItems(missingVids?.workoutVids, foundMatches.foundWktVidMatches)
      setOneMatchVideos(oneMatchVids)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireLoading])

  const someItemsHaveOneMatch = !isEmpty(oneMatchVideosState) || !isEmpty(oneMatchExercisesState)

  useDebouncedEffect(
    () => {
      handleUpdateMatchedMissingIds({
        exercisesToMatch: oneMatchExercisesState,
        vidsToMatch: oneMatchVideosState,
        missingVids,
        updateWorkouts,
        orgId: coachOrgId,
        createAlert,
        showAlert: false,
      }).then(() => {
        setOneMatchExercises({})
        setOneMatchVideos({})
        refetch()
      })
    },
    [oneMatchVideosState, oneMatchExercisesState],
    500,
    someItemsHaveOneMatch && !questionnaireLoading
  )

  const questionnaire = getQuestionnaire({
    program,
    programGroups: programGroupData,
    rowsWithProgram,
    missingVids,
    foundMatches,
    setCurrentStep,
    setSelectedExMatches,
    setSelectedWktVidMatches,
    isSecondAnswerDecline,
    setQuestModalIsOpen,
    navigate,
    refetch,
    previewRow,
    handleSaveTabContent,
    handleUpdateMatchedMissingIds: async () => {
      await handleUpdateMatchedMissingIds({
        exercisesToMatch: selectedExMatches,
        vidsToMatch: selectedWktVidMatches,
        missingVids,
        updateWorkouts,
        orgId: coachOrgId,
        createAlert,
      })
    },
    handlePublishProgram: async () => {
      await handlePublishProgram({
        publishDraftProgram,
        orgId: coachOrgId,
        programId,
        wktIdsToDayIdcs,
        name: program?.name,
        createAlert,
        updateProgram,
      })
    },
  })

  if (questionnaireLoading) {
    return (
      <Dialog open={questModalIsOpen} setOpen={setQuestModalIsOpen}>
        <DialogContent contentClassNames='!w-[560px]'>
          <QuestionnaireLoader text='Checking for missing videos...' />
        </DialogContent>
      </Dialog>
    )
  }

  if (someItemsHaveOneMatch) {
    return (
      <Dialog open={questModalIsOpen} setOpen={setQuestModalIsOpen}>
        <DialogContent contentClassNames='!w-[560px]'>
          <QuestionnaireLoader text='Matching videos...' />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={questModalIsOpen} setOpen={setQuestModalIsOpen}>
      <DialogContent contentClassNames='!w-[560px]'>
        <div className='px-10 pt-12 pb-4 text-tBlack'>
          <DialogPrimitive.Title className='font-bold text-lg text-center mb-4'>
            {questionnaire[currentStep].title}
          </DialogPrimitive.Title>
          <DialogPrimitive.Description className='text-base mb-6'>
            <span className='block' dangerouslySetInnerHTML={{ __html: questionnaire[currentStep].description }} />
          </DialogPrimitive.Description>
          {questionnaire[currentStep].renderList()}
          <div className='flex-1 flex items-center'>
            {questionnaire[currentStep].declineText && (
              <Button
                onClick={() => handleDeclineAction()}
                loading={isUpdating}
                css={[buttonBase, buttonVariants.secondary, buttonSizes.lg, tw`flex-1`]}
              >
                {questionnaire[currentStep].declineText}
              </Button>
            )}
            <Button
              data-testid='confirmationBtn'
              onClick={() => handleConfirmAction()}
              loading={isUpdating}
              css={tw`flex-1 not-first-of-type:ml-4`}
            >
              {questionnaire[currentStep].confirmationText}
            </Button>
          </div>
          {questionnaire.length > 1 && (
            <div className='flex items-center justify-center mt-4'>
              {questionnaire.map((_, idx) => (
                <StepDot key={idx} isCompleted={currentStep >= idx} />
              ))}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )

  async function handleSaveTabContent() {
    const rowProgramItem = {
      id: programId,
      type: 'program',
    }
    const newRow = {
      id: createUID(),
      name: `${format(Date.now(), 'MM/dd/yyyy')} - For Review`,
      visibilityLevel: 'coaches',
      items: [rowProgramItem],
    }

    const [firstTabId, firstTab] = firstTabEntry
    const firstTabRows = allTabContent?.[firstTabId]

    const todaysReviewRow = firstTabRows?.find((row) => {
      const isReviewRow = row.name.toLowerCase().includes('- for review')
      const splitRowName = row.name.split(' - ')
      const createdAt = splitRowName?.[0]
      const mmddyyyyRegex = /^\d{2}\/\d{2}\/\d{4}$/
      const hasCreatedAtDate = mmddyyyyRegex.test(createdAt)

      if (!isReviewRow || !hasCreatedAtDate) {
        return false
      }

      const createdAtDate = new Date(createdAt)
      const todayDate = new Date(Date.now())
      return isSameDay(createdAtDate, todayDate)
    })

    let updatedFirstTabRows = []
    if (todaysReviewRow) {
      updatedFirstTabRows = firstTabRows.map((row) => {
        if (row.id === todaysReviewRow.id) {
          if (row?.items) {
            return { ...row, items: [rowProgramItem, ...row.items] }
          } else {
            return { ...row, items: [rowProgramItem] }
          }
        } else {
          return row
        }
      })
      setPreviewRow({ ...todaysReviewRow, tabName: firstTab.name })
    } else {
      updatedFirstTabRows = firstTabRows ? [newRow, ...firstTabRows] : [newRow]
      setPreviewRow({ ...newRow, tabName: firstTab.name })
    }

    await saveTabContent({
      tabId: firstTabId,
      coachOrgId,
      tabContent: updatedFirstTabRows,
    })

    createAlert({ text: 'You can now test program on your phone!', type: 'success' })
  }

  function handleQuestionStep() {
    if (currentStep + 1 < questionnaire.length) {
      setCurrentStep(currentStep + 1)
    } else {
      setQuestModalIsOpen(false)
    }
  }

  function handleDeclineAction() {
    questionnaire[currentStep].declineAction()
    if (currentStep === 1) {
      setIsSecondAnswerDecline(true)
    }
    handleQuestionStep()
  }

  async function handleConfirmAction() {
    const res = await questionnaire[currentStep].confirmAction()
    if (res?.skipStepMove) {
      return
    }
    handleQuestionStep()
  }
}

const StepDot = styled.div(({ isCompleted }) => [
  tw`w-2 h-2 bg-gray-300 rounded-full not-first:ml-2`,
  isCompleted && tw`bg-tGreen`,
])
