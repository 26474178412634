import { useEffect } from 'react'

export function useDebouncedTextMutation(args) {
  const { debouncedStateText, dbText, mutation, mutationArgs } = args

  useEffect(() => {
    const hasVal = debouncedStateText ? true : false
    const changed = debouncedStateText !== dbText
    const wasDeleted = !debouncedStateText && dbText

    if ((hasVal && changed) || wasDeleted) {
      mutation(mutationArgs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStateText])
}
