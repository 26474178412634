import React, { useState } from 'react'
import { CgTrash, CgPen } from 'react-icons/cg'
import * as Popover from '@radix-ui/react-popover'
import tw from 'twin.macro'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useArchiveBenchmarkMutation } from 'modules/Programs/programApi'

import { Button } from 'common/components/Button/Button'

export default function BenchmarksListHeader({
  orgId,
  setSelectedBenchmarkId,
  selectedBenchmark,
  setBenchmarkDialogInfo,
}) {
  const { createAlert } = useAlert()
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const [archiveBenchmark] = useArchiveBenchmarkMutation()

  const handleArchiveBenchmark = async (e) => {
    e.stopPropagation()
    await archiveBenchmark({
      orgId,
      benchmarkId: selectedBenchmark?.id,
    })
    setDeleteConfirm(false)
    setSelectedBenchmarkId(null)
    createAlert({ text: 'Benchmark archived!', type: 'success' })
  }

  if (deleteConfirm) {
    return (
      <div className='flex items-center justify-between py-0.5 mb-2'>
        <div className='text-tBlack leading-none text-sm font-medium mr-2.5'>Confirm</div>
        <div className='flex items-center'>
          <Button
            size='sm'
            variant='secondary'
            css={tw`text-xs bg-transparent ml-2 py-1 whitespace-nowrap`}
            onClick={(e) => {
              e.stopPropagation()
              setDeleteConfirm(false)
            }}
          >
            Cancel
          </Button>
          <Button autoFocus size='sm' variant='danger' css={tw`text-xs ml-2 py-1`} onClick={handleArchiveBenchmark}>
            Archive
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className='flex justify-between items-center mb-2'>
      <Popover.Close
        css={tw`text-tGreen text-sm rounded-lg px-2 py-1 mr-1 hover:bg-tGreen hover:bg-opacity-10 transition-colors`}
      >
        Close
      </Popover.Close>
      <h4 className='font-bold text-sm text-tBlack first-letter:uppercase'>Benchmarks</h4>
      <div className='w-12 flex items-center justify-end'>
        {selectedBenchmark?.id && (
          <>
            <button
              tabIndex={-1}
              onClick={() => setBenchmarkDialogInfo({ open: true, benchmark: selectedBenchmark })}
              className='cursor-pointer'
            >
              <CgPen className='w-[18px] h-[18px] hover:text-tGreen text-gray-500' />
            </button>
            <button tabIndex={-1} onClick={() => setDeleteConfirm(true)} className='cursor-pointer ml-2'>
              <CgTrash className='w-5 h-5 hover:text-tRed text-gray-500' />
            </button>
          </>
        )}
      </div>
    </div>
  )
}
