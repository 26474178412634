import { createUID } from 'common/utils/createUID'
import { MAX_TAB_COUNT } from '../../constants/constants'

export const getIsTabSelected = ({ tab, tabId, tabIdParam }) => {
  const isFirstTab = tab.tabIdx === 0 && tabIdParam === undefined
  return isFirstTab || tabIdParam === tabId
}

export const getIsPreceedingSelectedTab = ({ tabId, tabEntries, tabIdParam }) => {
  if (tabIdParam === undefined) {
    return false
  }

  const selectedTabEntry = tabEntries.find(([id, _]) => id === tabIdParam)
  if (selectedTabEntry[1].tabIdx === 0 || tabIdParam === undefined) {
    return false
  }

  const [selectedPreceedingTabId] = tabEntries.find(([_, tab]) => tab.tabIdx === selectedTabEntry[1].tabIdx - 1)
  return selectedPreceedingTabId === tabId
}

export const getShowTabSeparator = ({ tab, tabId, tabIdParam, tabEntries }) => {
  return (
    tab.tabIdx + 1 !== MAX_TAB_COUNT &&
    !getIsTabSelected({ tab, tabId, tabIdParam }) &&
    !getIsPreceedingSelectedTab({ tabId, tabEntries, tabIdParam })
  )
}

export const getNewTab = (tabIdx) => {
  const tabId = createUID()
  const newTab = { tabIdx, name: 'New page', visibilityLevel: 'coaches' }

  return {
    tabId,
    newTab,
  }
}

export const togglePreceedingTabSeparatorVisibility = ({ tabEntries, idx, toggle }) => {
  const preceedingTab = tabEntries[idx - 1]
  if (preceedingTab) {
    const tabContainer = document.getElementById(`tabContainerId-${preceedingTab[0]}`)

    if (toggle === 'hide') {
      tabContainer.classList.add('after:invisible')
    }

    if (toggle === 'show') {
      tabContainer.classList.remove('after:invisible')
    }
  }
}
