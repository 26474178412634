import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { ref, onValue, off, get, set, update, remove } from 'firebase/database'
import db from 'domains/db'
import config from 'config/config'
const { firebaseConfig } = config

// initialize an empty api service that we'll inject endpoints into later as needed
export const globalApi = createApi({
  reducerPath: 'globalApi',
  baseQuery: fakeBaseQuery(),
  endpoints: () => ({}),
})

/**
 * Set up the RTK Query Streaming Update listener to update reducer based on Firebase onValue changes
 * @param {*} path Firebase path starting from the base ref
 * @param {*} api Includes RTK query cache handlers. Destructured but has further functions
 */
export const listenFirebase = async (path, api, onValueCb) => {
  const fbRef = ref(db, path)
  const { updateCachedData, cacheDataLoaded, cacheEntryRemoved } = api

  try {
    // wait for the initial query to resolve before proceeding
    await cacheDataLoaded

    onValue(fbRef, (snapshot) => {
      const response = snapshot.val()

      if (onValueCb) {
        return onValueCb(response)
      } else {
        updateCachedData(() => {
          return response //Not IMMER-like but Object.assign won't handle deletions properly
        })
      }
    })
  } catch (e) {
    console.error('listenFirebase error', e)
  }
  // perform cleanup
  await cacheEntryRemoved
  off(fbRef)
}

//Single fetch. Can be put into queryFn of RTK query endpoint to do a one-time data fetch
//Optional: RTK query streaming updates starts with a single fetch to the endpoint before setting up the listener. However, this is against Firebase docs of just using onValue, so just use empty data?
export const getFirebase = async (path) => {
  let data, error

  try {
    const fbRef = ref(db, path)
    let resp = await get(fbRef)
    data = resp.val()
  } catch (e) {
    error = { code: e.code, message: e.message, name: e.name }
    console.error('getFirebase error', error)
  } finally {
    return { data, error }
  }
}

export const setFirebase = async (path, value) => {
  let status, data, error

  try {
    const fbRef = ref(db, path)
    await set(fbRef, value)
    status = 'success'
    data = 'success'
  } catch (e) {
    error = { code: e.code, message: e.message, name: e.name }
    status = 'error'
    console.error('setFirebase error', error)
  } finally {
    return { status, data, error }
  }
}

export const updateFirebase = async (path, value) => {
  let status, data, error

  try {
    const fbRef = ref(db, path)
    await update(fbRef, value)
    status = 'success'
    data = 'success'
  } catch (e) {
    error = { code: e.code, message: e.message, name: e.name }
    status = 'error'
    console.error('updateFirebase error', error)
  } finally {
    return { status, data, error }
  }
}

export const removeFirebase = async (path) => {
  let data, error

  try {
    const fbRef = ref(db, path)
    await remove(fbRef)
    data = 'success'
  } catch (e) {
    error = { code: e.code, message: e.message, name: e.name }
    console.error('removeFirebase error', error)
  } finally {
    return { data, error }
  }
}

export const shallowQueryFirebase = async ({ path, fbAccessToken }) => {
  const options = {
    method: 'GET',
  }

  const reqURL = `${firebaseConfig.databaseURL}/${path}.json?auth=${fbAccessToken}&shallow=true&print=pretty`

  const result = await fetch(reqURL, options)
    .then((response) => response.json())
    .then((res) => res)

  return result
}
