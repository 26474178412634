import tw, { styled } from 'twin.macro'
import React from 'react'
import { IoIosWater } from 'react-icons/io'
import { CgTime, CgStopwatch } from 'react-icons/cg'

export function TimerHelper({
  setShowHelper,
  selectedHelper,
  setSelectedHelper,
  setInstructions,
  setRest,
  setTime,
  setCmdDropdown,
  handleStopWatchSet,
}) {
  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          handleTimerHelperSelect({
            selectedHelper,
            setInstructions,
            setRest,
            setCmdDropdown,
            setTime,
            setShowHelper,
            setSelectedHelper,
          })
        }}
        tabIndex={-1}
        isSelected={selectedHelper === 'rest'}
        onMouseEnter={() => setSelectedHelper('rest')}
      >
        <IoIosWater className='w-5 h-5 text-gray-500' />
        <div className='ml-2 flex flex-col items-start'>
          <div>Rest</div>
          <div className='font-normal text-xs text-gray-500'>Set a rest timer for users.</div>
        </div>
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          handleTimerHelperSelect({
            selectedHelper,
            setInstructions,
            setRest,
            setCmdDropdown,
            setTime,
            setShowHelper,
            setSelectedHelper,
          })
        }}
        tabIndex={-1}
        isSelected={selectedHelper === 'time'}
        onMouseEnter={() => setSelectedHelper('time')}
      >
        <CgTime className='w-5 h-5 text-gray-500' />
        <div className='ml-2 flex flex-col items-start'>
          <div>Timer</div>
          <div className='font-normal text-xs text-gray-500'>Set a timer for users.</div>
        </div>
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          handleTimerHelperSelect({
            selectedHelper,
            setInstructions,
            setRest,
            setCmdDropdown,
            setTime,
            setShowHelper,
            setSelectedHelper,
            handleStopWatchSet,
          })
        }}
        tabIndex={-1}
        isSelected={selectedHelper === 'stopwatch'}
        onMouseEnter={() => setSelectedHelper('stopwatch')}
      >
        <CgStopwatch className='w-[22px] h-[22px] text-gray-500' />
        <div className='ml-2 flex flex-col items-start'>
          <div>Stopwatch</div>
          <div className='font-normal text-xs text-gray-500'>Show stopwatch for users.</div>
        </div>
      </Button>
    </>
  )
}

const Button = styled.button(({ isSelected }) => [
  tw`flex items-center bg-white hover:bg-gray-300 hover:bg-opacity-30
  text-sm text-tBlack transition-all rounded-md justify-start
  font-medium p-2 w-full`,
  isSelected && tw`bg-gray-300 bg-opacity-30`,
])

export const handleTimerHelperSelect = ({
  selectedHelper,
  setInstructions,
  setRest,
  setCmdDropdown,
  setTime,
  setShowHelper,
  setSelectedHelper,
  handleStopWatchSet,
}) => {
  const defaultTime = '1:00'

  if (selectedHelper === 'rest') {
    setInstructions((text) => text + `r${defaultTime}`)
    setRest('00:01:00.00')
    setCmdDropdown({ show: true, type: 'rest', cmdText: `/r${defaultTime}` })
  } else if (selectedHelper === 'time') {
    setInstructions((text) => text + defaultTime)
    setTime('00:01:00.00')
    setCmdDropdown({ show: true, type: 'time', cmdText: `/${defaultTime}` })
  } else if (selectedHelper === 'stopwatch') {
    handleStopWatchSet(true)
  }
  setShowHelper(false)
  setSelectedHelper('rest')
}
