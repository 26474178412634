import { getFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'
import { prepWorkout } from './programUtils'
import { getNewEmptyWkt } from '../programModels'
import { createUID } from 'common/utils/createUID'
import { each, times } from 'lodash'
import { legacyGetExerciseId, fetchOrgExercises } from '../singleUseConversions/addExIdToProgramEx'

export const initDraftProgram = async ({ programId, orgId }) => {
  try {
    const publishedProgram = await fetchPublishedProgram(programId)

    if (!publishedProgram) {
      throw new Error(`No published program with programId ${programId}`)
    }

    const { updatedProgram, fullWkts } = convertLegacyProgram(publishedProgram)

    const orgExercises = await fetchOrgExercises(orgId)
    const fullWktsWithExIDs = addPartAndExIDs(fullWkts, orgExercises)

    const draftWorkoutsPath = `drafts/${orgId}/workouts/`
    const { error: updateDraftWktsErr } = await updateFirebase(draftWorkoutsPath, fullWktsWithExIDs)

    if (updateDraftWktsErr) {
      throw new Error(`updateDraftWktsErr ${updateDraftWktsErr}`)
    }

    const draftProgramPath = `drafts/${orgId}/programs/${programId}`
    const { error: setDraftProgramErr } = setFirebase(draftProgramPath, updatedProgram)

    if (setDraftProgramErr) {
      throw new Error(`setDraftProgramErr ${setDraftProgramErr}`)
    }
  } catch (e) {
    return { error: e }
  } finally {
    return { status: 'success' }
  }
}

const fetchPublishedProgram = async (programId) => {
  const { data: publishedProgram, error } = await getFirebase(`programs/${programId}`)

  if (error) {
    console.error(error)
  }

  return publishedProgram
}

const convertLegacyProgram = (program) => {
  const updatedProgram = { ...program }
  const wktsSrc = program.workoutsV2

  const fullWkts = {}
  const wktIdToDayIdx = {}
  const dayIdxToId = {}
  let maxWkIdx = 0

  each(wktsSrc, (wkt, idx) => {
    if (!wkt) return
    const workoutUpdated = { ...wkt }
    const wktPrepped = prepWorkout(workoutUpdated)
    const id = wktPrepped.id

    if (wktPrepped.title && wktPrepped.title.toLowerCase().includes('rest')) {
      wkt.type = 'rest'
    } else {
      wkt.type = 'workout'
    }

    const noVal = !wkt.dayIdx && wkt.dayIdx !== 0
    const isNull = wkt.dayIdx === 'null'
    const isUndefined = wkt.dayIdx === 'undefined'

    if (noVal || isNull || isUndefined) {
      return
    }

    const dayIdx = Number(wkt.dayIdx)

    fullWkts[id] = wktPrepped
    wktIdToDayIdx[id] = dayIdx
    dayIdxToId[dayIdx] = id

    const wkIdx = Math.floor(dayIdx / 7)
    maxWkIdx = Math.max(wkIdx, maxWkIdx)
  })

  const { fullWktsFilled, wktIdToDayIdxFilled } = fillProgramToMaxWeek({
    fullWkts,
    dayIdxToId,
    wktIdToDayIdx,
    maxWkIdx,
  })

  updatedProgram.workouts = wktIdToDayIdxFilled
  updatedProgram.workoutsV2 = null
  updatedProgram.exerciseFreq = null

  return {
    updatedProgram,
    fullWkts: fullWktsFilled,
  }
}

const addPartAndExIDs = (workouts, orgExercises) => {
  const updatedWorkouts = { ...workouts }

  each(updatedWorkouts, (wkt) => {
    if (!wkt || !wkt.parts) return

    each(wkt.parts, (part) => {
      if (!part || !part.exercises) return

      part.id = createUID()

      each(part.exercises, (ex) => {
        if (!ex) return

        //legacy programs (before the dashboard was released) relied on exercise.name isntead of
        //exercise.id; so if no exercise.id, set it
        if (!ex.id && ex.name) {
          const exId = legacyGetExerciseId(ex.name)
          const idExistsInExLibrary = orgExercises[exId]

          //in case some exercises purposely had no video,
          //be less aggressive only set if id exists in library
          if (idExistsInExLibrary) {
            ex.id = exId
          }
        }

        ex.dndUID = createUID()
      })
    })
  })

  return updatedWorkouts
}

const fillProgramToMaxWeek = ({ fullWkts, dayIdxToId, wktIdToDayIdx, maxWkIdx }) => {
  const fullWktsFilled = { ...fullWkts }
  const wktIdToDayIdxFilled = { ...wktIdToDayIdx }

  const numWks = maxWkIdx + 1
  const numDaysOfProg = numWks * 7

  times(numDaysOfProg, (dayIdx) => {
    const noWktAtDayIdx = !dayIdxToId[dayIdx]
    if (noWktAtDayIdx) {
      const emptyWkt = getNewEmptyWkt(dayIdx)

      fullWktsFilled[emptyWkt.id] = emptyWkt
      wktIdToDayIdxFilled[emptyWkt.id] = dayIdx
    }
  })

  return { fullWktsFilled, wktIdToDayIdxFilled }
}
