export const insertOneToArray = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
]

export const insertManyToArray = (arr, index, newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // spread insert new items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
]
