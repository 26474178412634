import React from 'react'

import { TableRow } from './TableRow'
import { LoadingRow } from './LoadingRow'

export function TableBody({ loading, programs, offset, currentPage, pageLimit, setModalProgram, setEditDialogOpen }) {
  const nextPageStartIdx = currentPage * pageLimit
  return (
    <tbody className='bg-white divide-y divide-gray-200'>
      {loading ? (
        <LoadingRow />
      ) : (
        <TableRows
          pageStartIdx={offset}
          nextPageStartIdx={nextPageStartIdx}
          programs={programs}
          setModalProgram={setModalProgram}
          setEditDialogOpen={setEditDialogOpen}
        />
      )}
    </tbody>
  )
}

function TableRows({ pageStartIdx, nextPageStartIdx, programs, setModalProgram, setEditDialogOpen }) {
  return getRowsMarkup(programs)

  function getRowsMarkup(programs) {
    return programs.map(([programId, program], index) => {
      const isOnCurrPage = index >= pageStartIdx && index < nextPageStartIdx

      if (isOnCurrPage) {
        return (
          <TableRow
            key={programId}
            programId={programId}
            program={program}
            setModalProgram={setModalProgram}
            setEditDialogOpen={setEditDialogOpen}
          />
        )
      } else {
        return null
      }
    })
  }
}
