import React, { useEffect, useRef, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensors, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { find, findIndex, trim } from 'lodash'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { Sortable } from 'common/components/Sortable/Sortable'
import { ExerciseInput } from './components/ExerciseInput'
import { dropAnimation } from 'common/utils/dndUtils'
import { useEventListener } from 'common/hooks/useEventListener'

export function ExerciseInputList({
  exerciseKey,
  handleRemoveProgression,
  exerciseList,
  progressions,
  progressionKey,
  autoFocus = false,
  inputRefsSortMethod,
  setAccordionValue,
}) {
  const addExRef = useRef()
  const [newEx, setNewEx] = useState(false)
  const [addingWithSelect, setAddingWithSelect] = useState({})
  const { watch, setValue, setFocus } = useFormContext()
  const { moveFocusedInputBy, inputRefs, moveInputRefs } = useFormRefsControl()

  const watchCues = watch('cues')

  const { fields, append, remove } = useFieldArray({
    name: 'exercises',
  })
  const watchExercises = watch('exercises')
  const controlledFields = fields.map((field, index) => {
    return {
      id: field.id,
      value: watchExercises[index],
    }
  })

  useEffect(() => {
    if (!progressionKey) {
      append('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Drag and drop
  const [activeDragInput, setActiveDragInput] = useState(null)

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const handleDragStart = ({ active }) => {
    const activeFieldIndex = controlledFields.findIndex((field) => field.id === active.id)
    const activeField = controlledFields[activeFieldIndex]
    setActiveDragInput({ index: activeFieldIndex, ...activeField })
  }

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = controlledFields.findIndex((field) => field.id === active.id)
      const newIndex = controlledFields.findIndex((field) => field.id === over.id)

      const newExList = arrayMove(
        controlledFields.map((field) => field.value),
        oldIndex,
        newIndex
      )
      setValue('exercises', newExList)
      moveInputRefs('exercise', oldIndex, newIndex)

      setActiveDragInput(null)
    }
  }

  const addNewItem = () => {
    append('')
    setNewEx(true)
  }

  const moveFocusOnKeyPress = (e) => {
    //Custom moveFocusOnKeyPress used to account for needing to add new inputs dynamically as tabbing through form
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()
      const activeIndex = findIndex(inputRefs, (input) => input.ref.current === document.activeElement)
      const exerciseIdx = inputRefs?.[activeIndex]?.exerciseIdx

      if (!e.shiftKey) {
        if (!addingWithSelect[exerciseIdx - 1]) {
          const nextExInput = find(inputRefs, (input) => input.exerciseIdx === exerciseIdx + 1)
          const activeExInputEmpty = trim(
            find(inputRefs, (input) => input.exerciseIdx === exerciseIdx).ref.current.value
          )
          if (!activeExInputEmpty || nextExInput) {
            moveFocusedInputBy(1)
          } else {
            addNewItem()
          }
        }
        setAddingWithSelect((state) => ({
          ...state,
          [exerciseIdx === 0 ? exerciseIdx : exerciseIdx - 1]: false,
        }))
      } else if (e.shiftKey) {
        if (setAccordionValue && exerciseIdx === 0) {
          setAccordionValue('formCues')

          const isLastCueEmptyStr = watchCues[watchCues.length - 1]?.trim() === ''
          // Need to make sure second to last cue input is selected
          // in case the last input value is an empty string that was previously added by tabbing or button
          // because empty string remains in form state while closing/reopening accordion, but input itself is removed
          const lastCueInputIdx =
            isLastCueEmptyStr && watchCues.length > 1 ? watchCues.length - 2 : watchCues.length - 1
          // Timeout is needed so that input is focused after cue accordion is opened
          setTimeout(() => {
            setFocus(`cues.${lastCueInputIdx}`)
          }, 50)
        } else {
          moveFocusedInputBy(-1)
        }
      }
    }
  }

  useEffect(() => {
    if (newEx) {
      setFocus(`exercises.${controlledFields.length - 1}`)
      setNewEx(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEx])

  const handleAddExOnEnter = (e) => {
    if (document.activeElement === addExRef.current && e.code === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      append('')
    }
  }

  useEventListener('keyup', handleAddExOnEnter)

  useEffect(() => {
    const newAddingWithSelect = watchExercises.reduce((acc, _, index) => ({ ...acc, [index]: false }), {})
    setAddingWithSelect(newAddingWithSelect)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={controlledFields} strategy={verticalListSortingStrategy}>
          {controlledFields.map((field, index) => (
            <Sortable
              key={field.id}
              id={field.id}
              withHandle={true}
              className='group relative flex flex-col mb-3 px-4 md:px-10'
              draggingClasses='ring-2 ring-tGreen ring-offset-4 opacity-50'
            >
              <ExerciseInput
                handleRemoveProgression={handleRemoveProgression}
                exerciseKey={exerciseKey}
                index={index}
                setValue={(value) => setValue(`exercises.${index}`, value)}
                remove={remove}
                selectedExId={field.value}
                exerciseList={exerciseList}
                progressionKey={progressionKey}
                progressions={progressions}
                addNewItem={addNewItem}
                onKeyUp={moveFocusOnKeyPress}
                onFocus={() => {
                  setAddingWithSelect((state) => ({ ...state, [index]: false }))
                }}
                autoFocus={autoFocus}
                setAddingWithSelect={setAddingWithSelect}
                isLastExercise={index === watchExercises.length - 1}
                inputRefsSortMethod={inputRefsSortMethod}
              />
            </Sortable>
          ))}
        </SortableContext>
        <DragOverlay
          zIndex={10}
          className='cursor-move'
          dropAnimation={dropAnimation}
          modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}
        >
          {activeDragInput && (
            <div className='relative flex flex-col group px-4 md:px-10 bg-gray-100'>
              <ExerciseInput
                isDragOverlay={true}
                index={activeDragInput.index}
                selectedExId={activeDragInput.value}
                exerciseList={exerciseList}
                progressionKey={progressionKey}
                progressions={progressions}
              />
            </div>
          )}
        </DragOverlay>
      </DndContext>
      <div className='flex items-center justify-between'>
        <button
          type='button'
          className='text-tBlack text-opacity-50 hover:text-opacity-80 transition-all ml-11 hover:bg-gray-100 p-2 rounded-md'
          onClick={() => append('')}
          ref={addExRef}
        >
          &#43; Add exercise
        </button>
      </div>
    </>
  )
}
