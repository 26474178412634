import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CgSearch } from 'react-icons/cg'
import tw from 'twin.macro'
import { isEmpty, each } from 'lodash'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  useListenExLibEnabledQuery,
  useListenExercisesQuery,
  useListenHasReadVidGuidelinesQuery,
} from './exerciseLibraryApi'
import { useListenProgressionsQuery } from 'modules/Progressions/progressionsApi'
import { useFilter } from 'modules/ExerciseLibrary/Filter/hooks/useFilter'
import { useDebounce } from 'common/hooks/useDebounce'
import { useCustomization } from 'common/contexts/Customization/useCustomization'

import ExerciseTable from 'modules/ExerciseLibrary/ExerciseTable/ExerciseTable'
import { Filter } from 'modules/ExerciseLibrary/Filter/Filter'
import { Input } from 'common/components/Input/Input'
import { InputGroup } from 'common/components/Input/InputGroup'
import { filterExercises } from 'common/utils/filterUtils'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { ActionPanel } from './ActionPanel/ActionPanel'
import { ExerciseUpload } from './ExerciseUpload/ExerciseUpload'
import { UploadingProgressSnack } from './ExerciseUpload/UploadingProgressSnack'
import { UploadingList } from './ExerciseUpload/UploadingList'
import { exerciseUploadCompleted, getAllExerciseUploadingCompleted } from './uploadingExercisesSlice'
import { UploadDialogActions } from './ExerciseUpload/UploadDialogActions'
import { UploadDialogHeader } from './ExerciseUpload/UploadDialogHeader'
import { getLandOrPortExVid } from 'common/utils/exerciseUtils'

function ExerciseLibrary() {
  const { userId } = useAuth()
  const { exVidOrientation } = useCustomization()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { createAlert } = useAlert()
  const dispatch = useDispatch()

  const { data: exData = {} } = useListenExercisesQuery({ coachOrgId, exVidOrientation })
  const { exercises = {} } = exData
  const exercisesLoading = exercises === undefined || exData?.isFiller
  const exerciseList = useMemo(
    () => (exercisesLoading || exercises === null ? {} : exercises),
    [exercises, exercisesLoading]
  )
  const { data: progressions } = useListenProgressionsQuery({ coachOrgId })
  const { data: hasReadVidGuidelines } = useListenHasReadVidGuidelinesQuery({ coachOrgId })
  const { data: exLibEnabledData } = useListenExLibEnabledQuery({ coachOrgId })
  const dataLoading =
    exercisesLoading ||
    progressions === undefined ||
    progressions?.isLoading ||
    hasReadVidGuidelines === undefined ||
    hasReadVidGuidelines?.isLoading ||
    exLibEnabledData === undefined ||
    exLibEnabledData?.isLoading

  const [searchQuery, setSearchQuery] = useState('')
  const { filters, setFilters, result: filteredExercises } = useFilter({ exercises: exerciseList, exVidOrientation })

  const debouncedSearchQ = useDebounce(searchQuery, 200)

  const uploadingExercises = useSelector((state) => state.uploadingExercises)
  const [uploadErrors, setUploadErrors] = useState({})

  const hasUploadingExercises = !isEmpty(uploadingExercises)
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)

  const filteredAgainBySearchQ = filterExercises(filteredExercises, debouncedSearchQ)

  const exercisesToRender = searchQuery.length < 0 ? filteredExercises : filteredAgainBySearchQ

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const selectedExercises = useSelector((state) => state.exercises.selectedExercises)

  const allExFinishedUploading = useSelector((state) => getAllExerciseUploadingCompleted(state))
  useEffect(() => {
    if (allExFinishedUploading) {
      createAlert({ text: 'Exercises uploaded!', type: 'success' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExFinishedUploading])

  useEffect(() => {
    if (!isEmpty(uploadingExercises) && !isEmpty(exerciseList)) {
      each(uploadingExercises, (_, exId) => {
        if (getLandOrPortExVid(exVidOrientation, exerciseList[exId])) {
          dispatch(exerciseUploadCompleted({ exerciseId: exId }))
        }
      })
    }
  }, [exerciseList, uploadingExercises, exVidOrientation, dispatch])

  return (
    <>
      <div className='flex flex-col min-h-screen bg-offWhite px-4 md:px-16'>
        {!uploadDialogOpen && hasUploadingExercises && (
          <UploadingProgressSnack
            setUploadDialogOpen={setUploadDialogOpen}
            uploadingExercises={uploadingExercises}
            allExFinishedUploading={allExFinishedUploading}
            setUploadErrors={setUploadErrors}
          />
        )}
        <div className='w-full max-w-7xl mx-auto mt-6 md:mt-12'>
          <div className='flex items-stretch md:items-start justify-between flex-col md:flex-row'>
            <div className='flex-col'>
              <h1 className='font-bold text-3xl md:text-5xl text-tBlack mr-6'>Exercises</h1>
              <p className='text-gray-500 max-w-sm mt-4'>Short exercise videos used in workouts</p>
            </div>
            <div className='flex-1 flex justify-center my-4 md:my-0'>
              <InputGroup
                css={[tw`max-w-full md:max-w-md ring-offWhite`, searchQuery.length && tw`ring-tGreen ring-2`]}
              >
                <CgSearch tw='w-5 h-5' />
                <Input
                  type='text'
                  placeholder='Search for an exercise'
                  onChange={handleSearchChange}
                  value={searchQuery}
                  hideRing
                />
                {searchQuery.length && (
                  <Button variant='secondary' size='md' onClick={() => setSearchQuery('')}>
                    Clear
                  </Button>
                )}
              </InputGroup>
              <Filter coachOrgId={coachOrgId} filters={filters} setFilters={setFilters} />
            </div>
            <div className='ml-0 md:ml-6 w-full md:w-auto'>
              <Dialog open={uploadDialogOpen} setOpen={setUploadDialogOpen}>
                <DialogTrigger
                  css={[buttonBase, buttonVariants.primary, buttonSizes.lg, tw`w-full md:w-auto`]}
                  disabled={dataLoading}
                >
                  Upload exercises
                </DialogTrigger>
                <DialogContent
                  header={<UploadDialogHeader uploadErrors={uploadErrors} />}
                  contentClassNames='!w-[700px] !h-[600px]'
                >
                  {!hasUploadingExercises ? (
                    <ExerciseUpload
                      coachOrgId={coachOrgId}
                      setUploadErrors={setUploadErrors}
                      uploadErrors={uploadErrors}
                      hasReadVidGuidelines={hasReadVidGuidelines}
                    />
                  ) : (
                    <UploadingList
                      uploadingExercises={uploadingExercises}
                      coachOrgId={coachOrgId}
                      exerciseList={exerciseList}
                      uploadErrors={uploadErrors}
                    />
                  )}
                  <UploadDialogActions
                    uploadingExercises={uploadingExercises}
                    coachOrgId={coachOrgId}
                    allExFinishedUploading={allExFinishedUploading}
                    setFilters={setFilters}
                    setUploadDialogOpen={setUploadDialogOpen}
                    setUploadErrors={setUploadErrors}
                    uploadErrors={uploadErrors}
                  />
                </DialogContent>
              </Dialog>
            </div>
          </div>
          <div className='flex flex-col my-12 md:my-20'>
            <ExerciseTable
              coachOrgId={coachOrgId}
              exercisesToRender={exercisesToRender}
              loading={dataLoading}
              progressions={progressions}
              exerciseList={exerciseList}
            />
          </div>
        </div>
      </div>
      {selectedExercises?.length > 0 && (
        <ActionPanel coachOrgId={coachOrgId} selectedExercises={selectedExercises} progressions={progressions} />
      )}
    </>
  )
}

export default ExerciseLibrary
