import { createSlice, createSelector } from '@reduxjs/toolkit'
import { each } from 'lodash'

const initialState = {}

const uploadingExercisesSlice = createSlice({
  name: 'uploadingExercises',
  initialState,
  reducers: {
    exerciseNameChanged: (state, action) => {
      const { exerciseId, name } = action.payload
      if (state[exerciseId]) {
        state[exerciseId].name = name
      }
    },
    startExerciseUpload: (state, action) => {
      const { exerciseId, exercise } = action.payload
      state[exerciseId] = { ...exercise }
    },
    updateExerciseProgress: (state, action) => {
      const { exerciseId, progress, storageKey } = action.payload

      if (state[exerciseId]) {
        if (!state[exerciseId].storageKey) {
          state[exerciseId].storageKey = storageKey
        }

        if (parseInt(progress) < 80) {
          state[exerciseId].progress = parseInt(progress)
        } else {
          state[exerciseId].progress = 80
        }
      }
    },
    exerciseUploadCompleted: (state, action) => {
      const { exerciseId } = action.payload
      state[exerciseId].progress = 100
    },
    uploadingExerciseCancelled: (state, action) => {
      const { exerciseId } = action.payload
      delete state[exerciseId]
    },
    uploadingExercisesCleared: (state, _) => {
      each(state, (_, exId) => {
        delete state[exId]
      })
    },
  },
})

export const {
  startExerciseUpload,
  updateExerciseProgress,
  exerciseUploadCompleted,
  uploadingExerciseCancelled,
  uploadingExercisesCleared,
  exerciseNameChanged,
} = uploadingExercisesSlice.actions
export default uploadingExercisesSlice.reducer

export const getTotalUploadingProgress = createSelector(
  (state) => state.uploadingExercises,
  (uploadingExercises) => {
    let uploadingExCount = 0
    let totalProgress = 0

    each(uploadingExercises, (ex) => {
      uploadingExCount++
      totalProgress = totalProgress + ex.progress
    })

    if (uploadingExCount === 0 || totalProgress === 0) {
      return 0
    } else {
      return Math.round(totalProgress / uploadingExCount)
    }
  }
)

export const getUploadedCount = createSelector(
  (state) => state.uploadingExercises,
  (uploadingExercises) => {
    let uploadedExCount = 0

    each(uploadingExercises, (ex) => {
      if (ex.progress === 100) {
        uploadedExCount = uploadedExCount + 1
      }
    })

    return uploadedExCount
  }
)

export const getAllExerciseUploadingCompleted = createSelector(
  (state) => state.uploadingExercises,
  (uploadingExercises) => {
    const exercises = Object.values(uploadingExercises || {})
    if (exercises.length === 0) {
      return false
    } else {
      const allExUploadCompleted = exercises.every((ex) => ex.progress === 100)
      return allExUploadCompleted
    }
  }
)
