import tw, { styled } from 'twin.macro'
import React, { forwardRef } from 'react'
import { ScrollArea } from '../ScrollArea/ScrollArea'

export const SuggestionContainer = forwardRef(
  ({ children, containerClasses, containerStyle, scrollAreaClasses, scrollAreaRef, outerContent }, ref) => {
    return (
      <div
        className={`${defaultContainerClasses} ${containerClasses ? containerClasses : ''}`}
        style={containerStyle}
        ref={ref}
        role='listbox'
        aria-label='options'
      >
        <ScrollArea
          css={tw`rounded-xl max-h-64`}
          ref={scrollAreaRef}
          className={scrollAreaClasses ? scrollAreaClasses : ''}
        >
          <ul className='p-2 pr-4'>{children}</ul>
        </ScrollArea>
        {outerContent ? outerContent : null}
      </div>
    )
  }
)

const defaultContainerClasses =
  'absolute w-full pointer-events-auto top-2 left-0 bg-white rounded-md overflow-hidden shadow-xl z-30 border-2 border-gray-300'

export const SuggestionItem = styled.li(({ active }) => [
  tw`cursor-pointer hover:bg-gray-300 hover:bg-opacity-30 py-2.5 px-2 rounded break-all`,
  active && tw`bg-gray-300 bg-opacity-30`,
])
