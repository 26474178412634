import React from 'react'
import { Alert } from 'common/components/Alert/Alert'

export const AlertContainer = ({ alerts, setAlerts }) => {
  if (!alerts.length) {
    return null
  }

  return (
    <div className='fixed right-2 md:right-8 bottom-24 md:bottom-5 flex flex-col z-[110]'>
      {alerts.map((alert) => (
        <Alert key={alert.id} id={alert.id} text={alert.text} type={alert.type} setAlerts={setAlerts} />
      ))}
    </div>
  )
}
