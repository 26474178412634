import { each, isEmpty } from 'lodash'
import { getLinkResourceErrors } from 'common/components/LinkInputCard/utils'

export const removeEmptyItems = (items) => {
  const cleaned = []

  each(items, (program) => {
    if (program) cleaned.push(program)
  })

  return cleaned
}

export const getLinkItemErrors = (items) => {
  let linkItemErrors = {}
  Object.values(items || {}).forEach((item) => {
    if (item.type === 'link') {
      const linkResourceErrors = getLinkResourceErrors(item)
      if (!isEmpty(linkResourceErrors)) {
        linkItemErrors[item?.id] = linkResourceErrors
      } else {
        delete linkItemErrors[item?.id]
      }
    }
  })
  return linkItemErrors
}
