import React from 'react'

function CueFilter({ filters, setFilters }) {
  const handleCueChange = (e) => {
    setFilters({ ...filters, cues: e.target.value })
  }
  return (
    <div className='ml-6'>
      <div className='py-1'>
        <input
          name='cueCompletion'
          id='incomplete'
          type='radio'
          value='incomplete'
          className='cursor-pointer rounded-full border-tBlack border-opacity-20 text-tGreen focus:ring-0'
          checked={filters.cues === 'incomplete' ? true : false}
          onChange={handleCueChange}
        />
        <label htmlFor='incomplete' className='cursor-pointer text-tBlack text-sm ml-2'>
          Incomplete
        </label>
      </div>
      <div>
        <input
          name='cueCompletion'
          id='complete'
          type='radio'
          value='complete'
          className='cursor-pointer rounded-full border-tBlack border-opacity-20 text-tGreen focus:ring-0'
          checked={filters.cues === 'complete' ? true : false}
          onChange={handleCueChange}
        />
        <label htmlFor='complete' className='cursor-pointer text-tBlack text-sm ml-2'>
          Complete
        </label>
      </div>
    </div>
  )
}

export default CueFilter
