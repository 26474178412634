import tw from 'twin.macro'
import React, { useState } from 'react'
import { CgAdd, CgChevronRightO } from 'react-icons/cg'
import * as Popover from '@radix-ui/react-popover'

import { buttonBase, buttonVariants, buttonSizes, Button } from 'common/components/Button/Button'

export const availableUnits = [
  { value: 'lb/kg', displayValue: 'Weight (pounds/kilograms)' },
  { value: 'distance', displayValue: 'Distance' },
  { value: 'reps', displayValue: 'Reps' },
  { value: 'time', displayValue: 'Time' },
]

export const availableDistances = [
  { value: 'in/cm', displayValue: 'inches/centimeters' },
  { value: 'ft/m', displayValue: 'feet/meters' },
  { value: 'mi/km', displayValue: 'miles/kilometers' },
]
const availableDistanceValues = availableDistances.map((distance) => distance.value)

export default function AddUnitButton({ units, setValue, clearErrors }) {
  const [selectedUnit, setSelectedUnit] = useState(null)
  const unusedUnits = availableUnits.filter((unit) => {
    if (unit.value === 'distance') {
      return !units.find((unitValue) => availableDistanceValues.includes(unitValue)) //since distance has multiple values, we need to check if any of the distance values are already in the units array
    } else {
      return !units.find((unitValue) => unitValue === unit.value)
    }
  })

  return (
    <div className='relative'>
      <Popover.Root onOpenChange={() => setSelectedUnit(null)}>
        <Popover.Trigger css={[buttonBase, buttonVariants.secondary, buttonSizes.sm]}>
          Add <CgAdd className='w-4 h-4 ml-2' />
        </Popover.Trigger>
        <Popover.Content
          className='bg-white px-3 py-3 rounded-xl w-72 shadow-2xl z-30 border-2 border-gray-300'
          aria-label='add-card-content'
        >
          <Popover.Arrow offset={12} className='fill-gray-300' />
          <NavigationActions handleBack={() => setSelectedUnit(null)} canGoBack={selectedUnit === 'distance'} />
          {selectedUnit !== 'distance' ? (
            <UnitOptions
              options={unusedUnits}
              onSelect={(value) => {
                if (value !== 'distance') {
                  setValue('units', [...units, value])
                  clearErrors('units')
                } else {
                  setSelectedUnit('distance')
                }
              }}
            />
          ) : (
            <UnitOptions
              options={availableDistances}
              onSelect={(value) => {
                if (value !== 'distance') {
                  clearErrors('units')
                  setValue('units', [...units, value])
                }
              }}
            />
          )}
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}

function UnitOptions({ options, onSelect }) {
  return (
    <div className='flex flex-col'>
      {options.map(({ value, displayValue }) =>
        value === 'distance' ? (
          <Button
            key={value}
            onClick={() => onSelect(value)}
            variant='secondary'
            size='md'
            css={tw`flex-1 flex px-4 mb-2 last:mb-0`}
          >
            <span className='first-letter:capitalize text-left flex-1'>{displayValue}</span>
            <CgChevronRightO className='w-[18px] h-[18px]' />
          </Button>
        ) : (
          <Popover.Close
            key={value}
            onClick={() => onSelect(value)}
            css={[buttonBase, buttonSizes.md, buttonVariants.secondary, tw`flex-1 flex px-4 mb-2 last:mb-0`]}
          >
            <span className='first-letter:capitalize text-left flex-1'>{displayValue}</span>
          </Popover.Close>
        )
      )}
    </div>
  )
}

const baseButtonClasses = tw`text-tGreen rounded-lg px-2 py-1 hover:bg-tGreen hover:bg-opacity-10 transition-colors`

function NavigationActions({ handleBack, canGoBack }) {
  if (canGoBack) {
    return (
      <div className='flex justify-between items-center mb-2'>
        <button onClick={() => handleBack()} css={baseButtonClasses}>
          Back
        </button>
        <div className='flex flex-col items-center mx-2'>
          <h4 className='font-bold text-tBlack first-letter:uppercase'>Distance</h4>
        </div>
        <div className='w-12' />
      </div>
    )
  }

  return (
    <div className='flex justify-between items-center mb-2'>
      <Popover.Close css={baseButtonClasses}>Cancel</Popover.Close>
    </div>
  )
}
