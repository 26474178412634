import { globalApi } from 'modules/App/globalApi'

export const updateWorkoutCache = ({ orgId, workoutId, workoutKey, newVal, dispatch }) => {
  dispatch(
    globalApi.util.updateQueryData('listenWorkout', { orgId, workoutId }, (draftWorkout) => {
      draftWorkout[workoutKey] = newVal
    })
  )
}

export const getUpdateWktMutationState = globalApi.endpoints.updateWorkout?.select('largeview-shared-update-workout')

export const getSetExerciseMutationState = globalApi.endpoints.setWorkoutExercise?.select(
  'largeview-shared-set-exercise'
)

export const getRemoveProgramMutationState = globalApi.endpoints.removeProgram?.select(
  'programtable-shared-remove-program'
)
