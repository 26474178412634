export const getRowsWithProgram = ({ tabName, rowsToCheck, programId, programGroupIdsWithProg }) => {
  let rowsWithProgram = []

  if (!rowsToCheck) {
    return []
  }

  rowsToCheck.forEach((row) => {
    row.items?.forEach((rowItem) => {
      const containsProgram = rowItem.id === programId
      if (containsProgram) {
        rowsWithProgram.push({ name: row.name, tabName, programGroupId: null })
      }

      const programGroupId = programGroupIdsWithProg.find((progGroupId) => progGroupId === rowItem.id)
      if (programGroupId) {
        rowsWithProgram.push({ name: row.name, tabName, programGroupId })
      }
    })
  })

  return rowsWithProgram
}
