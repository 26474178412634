import React from 'react'

import { TableRow } from './TableRow'
import { LoadingRow } from './LoadingRow'

export function TableBody({
  coachOrgId,
  loading,
  filteredProgramGroups,
  offset,
  currentPage,
  pageLimit,
  setModalPg,
  setEditDialogOpen,
}) {
  const nextPageStartIdx = currentPage * pageLimit
  return (
    <tbody className='bg-white divide-y divide-gray-200'>
      {loading ? (
        <Loading />
      ) : (
        <TableRows
          coachOrgId={coachOrgId}
          pageStartIdx={offset}
          nextPageStartIdx={nextPageStartIdx}
          filteredProgramGroups={filteredProgramGroups}
          setModalPg={setModalPg}
          setEditDialogOpen={setEditDialogOpen}
        />
      )}
    </tbody>
  )
}

function Loading() {
  const numRows = 10
  return [...Array(numRows).keys()].map((key) => <LoadingRow key={key} />)
}

function TableRows({
  coachOrgId,
  pageStartIdx,
  nextPageStartIdx,
  filteredProgramGroups,
  setModalPg,
  setEditDialogOpen,
}) {
  return filteredProgramGroups.map(([programGroupKey, programGroup], index) => {
    const isOnCurrPage = index >= pageStartIdx && index < nextPageStartIdx

    if (isOnCurrPage) {
      return (
        <TableRow
          key={programGroupKey}
          coachOrgId={coachOrgId}
          programGroupKey={programGroupKey}
          programGroup={programGroup}
          setModalPg={setModalPg}
          setEditDialogOpen={setEditDialogOpen}
        />
      )
    } else {
      return null
    }
  })
}
