import tw, { styled } from 'twin.macro'
import React, { useEffect } from 'react'
import { CgCheckR, CgCloseR, CgClose } from 'react-icons/cg'

export function Alert({ id, text = 'Action successful', type = 'success', setAlerts }) {
  const removeAlert = (id) => {
    setAlerts((alerts) => alerts.filter((alert) => alert.id !== id))
  }

  useEffect(() => {
    const timeout = setTimeout(() => removeAlert(id), 4000)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (type !== 'success' && type !== 'danger') {
    throw new Error(`Alert must use type 'success' or 'danger'`)
  }

  return (
    <StyledAlert type={type}>
      {type === 'success' ? <CgCheckR className='w-5 h-5 text-tGreen' /> : <CgCloseR className='w-5 h-5 text-tRed' />}
      <p className='first-letter:capitalize flex-1 font-semibold ml-2 text-tBlack'>{text}</p>
      <button className='text-gray-400 hover:text-gray-600 transition-colors' onClick={() => removeAlert(id)}>
        <CgClose className='ml-6 w-4 h-4' />
      </button>
    </StyledAlert>
  )
}

const alertBase = tw`flex items-center px-4 py-3 mb-3 last:mb-0 bg-white border-2 rounded-xl shadow-md`

const alertVariants = {
  success: tw`border-tGreen`,
  danger: tw`border-tRed`,
}

const StyledAlert = styled.div(({ type }) => [alertBase, alertVariants[type]])
