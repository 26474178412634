import React from 'react'
import TableRow from 'modules/ExerciseLibrary/ExerciseTable/TableRow'
import { LoadingRow } from 'modules/ExerciseLibrary/ExerciseTable/LoadingRow'
import { useCustomization } from 'common/contexts/Customization/useCustomization'

export function TableBody({
  isAnyExSelected,
  loading,
  currPageExercises,
  progressions,
  setModalEx,
  setEditDialogOpen,
}) {
  const { exVidOrientation } = useCustomization()

  return (
    <tbody className='bg-white divide-y divide-gray-200 z-50'>
      {loading ? (
        <Loading exVidOrientation={exVidOrientation} />
      ) : (
        <TableRows
          isAnyExSelected={isAnyExSelected}
          currPageExercises={currPageExercises}
          progressions={progressions}
          setModalEx={setModalEx}
          setEditDialogOpen={setEditDialogOpen}
        />
      )}
    </tbody>
  )
}

function Loading({ exVidOrientation }) {
  const numRows = 10
  return [...Array(numRows).keys()].map((key) => <LoadingRow key={key} exVidOrientation={exVidOrientation} />)
}

function TableRows({ isAnyExSelected, currPageExercises, progressions, setModalEx, setEditDialogOpen }) {
  return currPageExercises.map(([key, exercise]) => (
    <TableRow
      key={key}
      exerciseKey={key}
      exercise={exercise}
      currPageExercises={currPageExercises}
      isAnyExSelected={isAnyExSelected}
      progressions={progressions}
      setModalEx={setModalEx}
      setEditDialogOpen={setEditDialogOpen}
    />
  ))
}
