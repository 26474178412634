import tw, { styled } from 'twin.macro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { differenceWith, each, groupBy } from 'lodash'

import { CgCopy, CgCloseO, CgTrash } from 'react-icons/cg'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { updateTabRowsOnDelete } from 'modules/Layout/utils/layoutUtils'
import { removeItemsInCollections } from 'modules/Collections/collectionUtils'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { maybePluralize } from 'common/utils/stringUtils'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'

import { CombineSelectedPartsDialog } from './CombineSelectedPartsDialog'

import { clipboardCounterId, clipboardTextId } from '../constants/cssSelectorConstants'
import { useUpdateWorkoutsMutation } from '../programApi'
import { getNewEmptyWkt } from '../programModels'
import {
  selectedWorkoutsDeleted,
  selectedPartsCleared,
  selectedWorkoutsCleared,
  partsCopied,
  copiedPartsCleared,
  workoutsCopied,
  copiedWorkoutsCleared,
  copiedWeeksCleared,
} from '../programSlice'
import { updateManyWorkoutContentTags } from '../utils/contentTagUtils'

export function ActionPanel(props) {
  const { workoutsSelected, workoutsCopied, partsSelected, partsCopied, weeksCopied } = props

  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const dispatch = useDispatch()
  const workouts = useSelector((state) => state.program.workouts)
  const { createAlert } = useAlert()

  const [updateWorkouts] = useUpdateWorkoutsMutation()

  const partWktIdToCheck = partsSelected[0]?.workoutId
  const allPartsSelectedSameWkt = partWktIdToCheck
    ? partsSelected.every((selectedPart) => selectedPart.workoutId === partWktIdToCheck)
    : false

  const isSelectedWkts = workoutsSelected && workoutsSelected.length > 0
  const isSelectedParts = partsSelected && partsSelected.length > 0
  const noSelectedItems = !isSelectedWkts && !isSelectedParts

  const isCopiedWkts = workoutsCopied && workoutsCopied.length > 0
  const isCopiedParts = partsCopied && partsCopied.length > 0
  const isCopiedWeeks = weeksCopied && weeksCopied.length > 0
  const noCopiedItems = !isCopiedWkts && !isCopiedParts && !isCopiedWeeks

  return (
    <div className='sticky bottom-0 inset-x-0 bg-white px-4 py-4 z-50 border-b border-gray-300 shadow-t-md'>
      <div className='flex items-stretch max-w-[90rem] mx-auto justify-between divide-x-2 border-gray-100'>
        <div className='flex flex-col w-1/2'>
          <h4 className='text-center text-tBlack font-semibold'>
            {noSelectedItems ? 'No selected items' : 'Selected items'}
          </h4>
          {!noSelectedItems && (
            <div className='bg-gray-100 rounded-xl min-w-80 mx-auto px-4 py-2 mt-2'>
              {isSelectedWkts && (
                <SelectionActions
                  selectedItems={workoutsSelected}
                  itemText='workout'
                  clearSelectedItems={() => dispatch(selectedWorkoutsCleared())}
                  handleDeleteSelection={() =>
                    handleDeleteWktSelection({
                      workoutsSelected,
                      updateWorkouts,
                      dispatch,
                      createAlert,
                      coachOrgId,
                    })
                  }
                  handleCopySelection={() =>
                    handleCopyWktSelection({
                      workoutsSelected,
                      workouts,
                      dispatch,
                      createAlert,
                    })
                  }
                />
              )}
              {isSelectedParts && (
                <SelectionActions
                  selectedItems={partsSelected}
                  itemText='block'
                  clearSelectedItems={() => dispatch(selectedPartsCleared())}
                  handleDeleteSelection={() =>
                    handleDeletePartSelection({
                      selectedPartsState: partsSelected,
                      updateWorkouts,
                      workouts,
                      dispatch,
                      createAlert,
                      coachOrgId,
                    })
                  }
                  handleCopySelection={() =>
                    handleCopyPartSelection({
                      partsSelected,
                      workouts,
                      dispatch,
                      createAlert,
                    })
                  }
                >
                  {allPartsSelectedSameWkt && partsSelected.length > 1 && (
                    <CombineSelectedPartsDialog
                      orgId={coachOrgId}
                      workout={workouts[partWktIdToCheck]}
                      partsSelected={partsSelected}
                      clearSelectedParts={() => dispatch(selectedPartsCleared())}
                    />
                  )}
                </SelectionActions>
              )}
            </div>
          )}
        </div>
        <div className='flex flex-col w-1/2'>
          <h4 className='text-center text-tBlack font-semibold'>
            {noCopiedItems ? 'No copied items' : 'Copied items'}
          </h4>
          {!noCopiedItems && (
            <div className='bg-gray-100 rounded-xl w-80 mx-auto px-4 py-2 mt-2'>
              {isCopiedWeeks && (
                <ClipboardActions
                  copiedItems={weeksCopied}
                  setCopiedItems={() => dispatch(copiedWeeksCleared())}
                  itemText='week'
                  itemType='week'
                />
              )}
              {isCopiedWkts && (
                <ClipboardActions
                  copiedItems={workoutsCopied}
                  setCopiedItems={() => dispatch(copiedWorkoutsCleared())}
                  itemText='workout'
                  itemType='workout'
                />
              )}
              {isCopiedParts && (
                <ClipboardActions
                  copiedItems={partsCopied}
                  setCopiedItems={() => dispatch(copiedPartsCleared())}
                  itemText='block'
                  itemType='part'
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function SelectionActions(props) {
  const {
    selectedItems,
    clearSelectedItems,
    itemText,
    handleDeleteSelection,
    handleCopySelection,
    children,
    deleteLoading = false,
  } = props

  const itemsExist = selectedItems.length > 0

  const itemTextPluralized = maybePluralize({ text: itemText, count: selectedItems.length })
  const text = itemsExist ? `selected ${itemTextPluralized}` : `no selected ${itemText}s`

  return (
    <div data-testid={`selection-${itemText}`} className='flex items-center'>
      <Counter visible={itemsExist}>{selectedItems.length}</Counter>
      <Text itemsExist={itemsExist}>{text}</Text>
      <div css={[tw`flex ml-auto`, !itemsExist ? tw`invisible` : tw`visible`]}>
        {children}
        <Dialog>
          <Tooltip content={`Delete selected ${itemTextPluralized}`}>
            <DialogTrigger
              css={[
                buttonBase,
                buttonSizes.sm,
                buttonVariants.primary,
                tw`bg-transparent text-tRed hover:bg-tRed hover:bg-opacity-10`,
              ]}
            >
              <CgTrash className='w-4 h-4' />
            </DialogTrigger>
          </Tooltip>
          <DialogContent>
            <DeleteDialogBanner
              text={`This will delete ${selectedItems.length} selected ${itemTextPluralized}`}
              loading={deleteLoading}
              handleDelete={handleDeleteSelection}
            />
          </DialogContent>
        </Dialog>
        <Tooltip content={`Copy selected ${itemTextPluralized}`}>
          <Button
            data-testid={`selection-copy-${itemText}`}
            size='sm'
            css={tw`bg-transparent text-tGreen hover:bg-tGreen hover:bg-opacity-10`}
            onClick={() => handleCopySelection()}
          >
            <CgCopy className='w-4 h-4' />
          </Button>
        </Tooltip>
        <Tooltip content={`Clear selected ${itemTextPluralized}`}>
          <Button
            data-testid={`selection-clear-${itemText}`}
            size='sm'
            css={tw`bg-transparent font-medium text-gray-500 text-opacity-100 hover:bg-opacity-40`}
            variant='secondary'
            onClick={() => clearSelectedItems()}
          >
            <CgCloseO className='w-4 h-4' />
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

const handleCopyWktSelection = ({ workoutsSelected, workouts, dispatch, createAlert }) => {
  const text = `${maybePluralize({ text: 'Workout', count: workoutsSelected.length })} copied!`

  const uptoDateWorkouts = workoutsSelected.map((selectedWkt) => ({ ...workouts[selectedWkt.id] }))

  dispatch(workoutsCopied({ workouts: uptoDateWorkouts }))
  dispatch(selectedWorkoutsCleared())

  createAlert({ text, type: 'success' })
}

const handleCopyPartSelection = ({ partsSelected, workouts, dispatch, createAlert }) => {
  const text = `${maybePluralize({ text: 'Block', count: partsSelected.length })} copied!`

  const parts = partsSelected.map((selectedPart) => {
    const wktParts = workouts[selectedPart.workoutId].parts
    const uptoDatePart = wktParts.find((wktPart) => wktPart.id === selectedPart.id)
    return uptoDatePart
  })

  dispatch(partsCopied({ parts }))
  dispatch(selectedPartsCleared())

  createAlert({ text, type: 'success' })
}

const handleDeleteWktSelection = async ({ workoutsSelected, createAlert, dispatch, updateWorkouts, coachOrgId }) => {
  const text = `${maybePluralize({ text: 'Workout', count: workoutsSelected.length })} deleted!`

  const firebaseWktsUpdate = {}

  each(workoutsSelected, (wkt) => {
    const emptyWkt = getNewEmptyWkt(wkt.dayIdx, wkt.id)
    firebaseWktsUpdate[wkt.id] = emptyWkt
  })

  await updateWorkouts({
    orgId: coachOrgId,
    workouts: firebaseWktsUpdate,
  })
  await updateManyWorkoutContentTags({
    orgId: coachOrgId,
    workouts: workoutsSelected,
    // programId, not needed when deleting
    updateType: 'remove',
  })

  const workoutIds = Object.keys(firebaseWktsUpdate)

  await updateTabRowsOnDelete({ coachOrgId, itemIds: workoutIds })
  await removeItemsInCollections({ coachOrgId, itemIds: workoutIds })

  dispatch(selectedWorkoutsDeleted({ workoutIds }))

  createAlert({ text, type: 'success' })
}

const handleDeletePartSelection = async ({
  selectedPartsState,
  createAlert,
  workouts,
  dispatch,
  updateWorkouts,
  coachOrgId,
}) => {
  const text = `${maybePluralize({ text: 'Block', count: selectedPartsState.length })} deleted!`

  const firebaseWktsUpdate = {}

  const selectedPartsByWktId = groupBy(selectedPartsState, (selectedPart) => selectedPart.workoutId)
  const selectedPartsWktIds = Object.keys(selectedPartsByWktId)

  each(selectedPartsWktIds, async (wktId) => {
    const wktSelectedParts = selectedPartsByWktId[wktId]
    const updatedWktSelectedParts = wktSelectedParts.map((part) => ({ ...part, workoutId: null }))

    // Up to date workout parts from global state
    const origWktParts = workouts[wktId].parts

    // Remove selected parts from original up to date workout parts
    const remainingParts = differenceWith(
      origWktParts,
      updatedWktSelectedParts,
      (first, second) => first.id === second.id
    )

    firebaseWktsUpdate[`${wktId}/parts`] = remainingParts
  })

  await updateWorkouts({
    orgId: coachOrgId,
    workouts: firebaseWktsUpdate,
  })

  dispatch(selectedPartsCleared())
  createAlert({ text, type: 'success' })
}

function ClipboardActions(props) {
  const { copiedItems, setCopiedItems, itemText, itemType } = props

  const itemsExist = copiedItems.length > 0

  const itemTextPluralized = maybePluralize({ text: itemText, count: copiedItems.length })
  const text = itemsExist ? `copied ${itemTextPluralized}` : `no copied ${itemText}s`

  return (
    <div data-testid={`clipboard-${itemText}`} className='flex items-center'>
      <Counter id={clipboardCounterId[itemType]} visible={itemsExist}>
        {copiedItems.length}
      </Counter>
      <Text id={clipboardTextId[itemType]} itemsExist={itemsExist}>
        {text}
      </Text>
      <div css={[tw`ml-auto`, !itemsExist ? tw`invisible` : tw`visible`]}>
        <Tooltip content={`Clear copied ${itemTextPluralized}`}>
          <Button
            data-testid={`clipboard-clear-${itemText}`}
            size='sm'
            css={tw`bg-transparent font-medium text-gray-500 text-opacity-100 hover:bg-opacity-40`}
            variant='secondary'
            onClick={() => setCopiedItems()}
          >
            <CgCloseO className='w-4 h-4' />
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

const Counter = styled.span(({ visible }) => [
  tw`hidden items-center justify-center font-medium
  leading-none text-xs text-gray-500 w-5 h-5 bg-gray-300 rounded-full`,
  visible && tw`inline-flex`,
])

const Text = styled.div(({ itemsExist }) => [
  tw`ml-2 mr-10 text-tBlack text-sm font-semibold lowercase`,
  !itemsExist && tw`text-gray-400 ml-0`,
])
