import React from 'react'
import { initialActiveFilterState } from 'modules/ExerciseLibrary/Filter/constants'

function FilterCheckbox({ name, filters, setFilters, children }) {
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.checked ? initialActiveFilterState[e.target.name] : null })
  }

  return (
    <div className='py-2'>
      <div className='flex items-center'>
        <input
          name={name}
          id={name}
          type='checkbox'
          value={filters[name] ? 'on' : 'off'}
          className='cursor-pointer rounded-sm border-tBlack border-opacity-20 text-tGreen focus:ring-0'
          checked={filters[name] ? true : false}
          onChange={handleFilterChange}
        />
        <label htmlFor={name} className='cursor-pointer text-tBlack ml-2 first-letter:uppercase'>
          {name.split('-').length === 0 ? name : name.split('-').join(' ')}
        </label>
      </div>
      {children}
    </div>
  )
}

export default FilterCheckbox
