import { each } from 'lodash'

// Check if program group name exists in all program groups and is not program group that is currently being edited
export const checkProgramGroupNameExists = ({ programGroups, programGroupName, programGroupKey }) => {
  return Object.entries(programGroups).find(
    ([key, programGroup]) =>
      programGroup?.name?.trim().toLowerCase() === programGroupName.toLowerCase() && programGroupKey !== key
  )
}

export const removeEmptyPrograms = (programs) => {
  const cleaned = []

  each(programs, (program) => {
    if (program) cleaned.push(program)
  })

  return cleaned
}
