import { useState } from 'react'
import { useSensors, useSensor, MouseSensor } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { each } from 'lodash'
import { insertOneToArray } from 'common/utils/arrayUtils'
import { useReorderProgramMutation, useSavePartExercisesMutation, useSavePartsMutation } from '../programApi'

/**
    Keep in mind.
    exDragging, partDragging, wktDragging are set on drag start
    and are stable throughout dragging, until draggable item is dropped.

    Data added to active.data.current or over.data.current are not stable and 
    can change when app state changes. 
    i.e. when exercise is dragged to a new part that data will change
 */

export const useProgramDnd = (args) => {
  const { wktIdsToDayIdcs, wktIdsSorted, setEditIdx, programId, orgId } = args

  const [wktDragging, setWktDragging] = useState(null)
  const [partDragging, setPartDragging] = useState(null)
  const [exDragging, setExDragging] = useState(null)

  const [reorderProgram] = useReorderProgramMutation()
  const [saveParts] = useSavePartsMutation()
  const [savePartExercises] = useSavePartExercisesMutation()

  const sensors = useSensors(useSensor(MouseSensor))

  const handleDragStart = ({ active }) => {
    const { itemType } = active.data.current

    if (itemType === 'workout') {
      setEditIdx(null)
    }

    if (itemType === 'part') {
      const { workout, part, partIdx } = active.data.current
      setPartDragging({ part, index: partIdx, workout })
    } else if (itemType === 'workout') {
      setWktDragging({ id: active.id, dayIdx: wktIdsToDayIdcs[active.id] })
    } else if (itemType === 'exercise') {
      const { workout, part, partIdx, exercise, exIdx } = active.data.current
      setExDragging({ exercise, index: exIdx, part, partIdx, workout })
    }
  }

  const handleDragEnd = async ({ active, over }) => {
    const { itemType: dragItemType } = active.data.current
    const draggedElsewhere = over && active.id !== over.id

    // When exercise is dragged
    if (dragItemType === 'exercise') {
      const { index: exDraggingIdx, workout: wktDragging, part: partDragging, partIdx: partDraggingIdx } = exDragging
      const { itemType: overItemType, workout: overWkt, part: overPart, partIdx: overPartIdx } = over.data.current

      const draggedWithinSamePart = partDragging.id === overPart?.id
      const draggedToDiffEx = draggedElsewhere && draggedWithinSamePart

      // Dragged within same part
      if (draggedToDiffEx && overItemType === 'exercise') {
        const partDraggingExercises = partDragging.exercises

        const newIndex = over.data.current.exIdx
        const updatedExercises = arrayMove(partDraggingExercises, exDraggingIdx, newIndex)

        await savePartExercises({
          orgId,
          workoutId: wktDragging.id,
          partIndex: partDraggingIdx,
          exercises: updatedExercises,
        })
      }

      const overDiffPartTypes =
        overItemType === 'part' ||
        overItemType === 'partTop' ||
        overItemType === 'partBottom' ||
        overItemType === 'exercise'
      const draggedToDiffPart =
        overDiffPartTypes && wktDragging.id === overWkt.id && !draggedWithinSamePart && overPart?.type !== 'rest'

      // Dragged to different part
      if (draggedToDiffPart) {
        const { workout: wktDragging, partIdx: draggedFromPartIdx, part: draggedFromPart } = exDragging

        // Remove exercise where it was initially dragged from
        const partExDraggedFromUpdated = draggedFromPart.exercises.filter(
          (ex) => ex.dndUID !== exDragging.exercise.dndUID
        )
        await savePartExercises({
          orgId,
          workoutId: wktDragging.id,
          partIndex: draggedFromPartIdx,
          exercises: partExDraggedFromUpdated,
        })

        let landedExIdx = over.data.current?.exIdx
        // Exercise can be over part
        // Check for undefined because can be 0
        if (landedExIdx === undefined && overItemType === 'partTop') {
          landedExIdx = 0
        } else if (landedExIdx === undefined && overItemType === 'partBottom') {
          landedExIdx = overPart.exercises.length
          // Fallback to idx 0 in case no exercise landIdx is found
        } else if (landedExIdx === undefined) {
          landedExIdx = 0
        }

        // Add exercise where it was finnaly dropped
        const partExDraggedToUpdated = insertOneToArray(overPart.exercises, landedExIdx, exDragging.exercise)
        await savePartExercises({
          orgId,
          workoutId: wktDragging.id,
          partIndex: overPartIdx,
          exercises: partExDraggedToUpdated,
        })
      }
    }

    // When part is dragged
    if (dragItemType === 'part' && draggedElsewhere) {
      const wktDragging = partDragging.workout
      const overWkt = over.data.current.workout
      const draggedWithinSameWkt = wktDragging.id === overWkt?.id
      const draggedToDiffPart = draggedElsewhere && draggedWithinSameWkt

      if (draggedToDiffPart) {
        const wktDraggingParts = wktDragging.parts

        const previousIndex = partDragging.index
        const newIndex = over.data.current.partIdx
        const updatedParts = arrayMove(wktDraggingParts, previousIndex, newIndex)

        await saveParts({
          orgId,
          programId,
          workoutId: wktDragging.id,
          parts: updatedParts,
        })
      }
    }

    // When workout is dragged
    if (dragItemType === 'workout' && draggedElsewhere) {
      const wktIdxDragging = wktIdsToDayIdcs[active.id]
      const wktIdxLanded = wktIdsToDayIdcs[over.id]
      const wktIdsReordered = arrayMove(wktIdsSorted, wktIdxDragging, wktIdxLanded)

      const reorderedProgramWkts = {}
      const fbUpdateWkts = {}

      each(wktIdsReordered, (id, idx) => {
        reorderedProgramWkts[id] = idx

        const fbUpdateKey = `${id}/dayIdx`
        fbUpdateWkts[fbUpdateKey] = idx
      })

      await reorderProgram({
        orgId,
        programId,
        reorderedProgramWkts,
        fbUpdateWkts,
      })
    }

    setWktDragging(null)
    setPartDragging(null)
    setExDragging(null)
  }

  const handleDragCancel = () => {
    setWktDragging(null)
    setPartDragging(null)
    setExDragging(null)
  }

  return {
    wktDragging,
    partDragging,
    exDragging,
    sensors,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
  }
}
