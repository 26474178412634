import React, { useState } from 'react'
import { sortBy } from 'lodash'

import { closestCenter, DndContext, DragOverlay, useSensors, useSensor } from '@dnd-kit/core'
import { dropAnimation, MouseSensor, TouchSensor } from 'common/utils/dndUtils'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'

import { Sortable } from 'common/components/Sortable/Sortable'
import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'

import { LinkCard } from './LinkCard'
import { WorkoutItem } from './WorkoutItem'
import { ProgramGroupItem } from './ProgramGroupItem'
import { ProgramItem } from './ProgramItem'
import { VideoItem } from './VideoItem'

export function ItemsList({ coachOrgId, collectionId, items, setValue, linkErrors, setLinkErrors, setIsCreated }) {
  const sortedItems = sortBy(items, (item) => item.index)

  // Drag and drop
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  )
  const [activeDragItem, setActiveDragItem] = useState(null)
  const handleDragStart = ({ active }) => {
    const item = sortedItems.find((item) => active.id === item.id)
    setActiveDragItem(item)
  }

  const handleDragEnd = async (event) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = sortedItems.findIndex((item) => item.id === active.id)
      const newIndex = sortedItems.findIndex((item) => item.id === over.id)

      const movedItems = arrayMove(sortedItems, oldIndex, newIndex)
      let updatedItems = {}
      movedItems.forEach((item, index) => {
        updatedItems[item.id] = { ...item, index }
      })
      setValue('items', updatedItems)
      setActiveDragItem(null)
    }
  }

  const itemIds = sortedItems.map((item) => item.id)
  const linkItems = sortedItems.filter((item) => item.type === 'link')
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
        {sortedItems.map((item, idx) => (
          <Sortable
            key={item.id}
            id={item.id}
            withHandle={true}
            draggingClasses='opacity-50 ring-2 ring-tGreen ring-offset-2 rounded-lg'
          >
            {item.type === 'link' ? (
              <LinkCard
                item={item}
                linkItems={linkItems}
                coachOrgId={coachOrgId}
                collectionId={collectionId}
                linkErrors={linkErrors}
                setLinkErrors={setLinkErrors}
                setIsCreated={setIsCreated}
              />
            ) : (
              <Item coachOrgId={coachOrgId} item={item} linkItems={linkItems} />
            )}
          </Sortable>
        ))}
      </SortableContext>
      <DragOverlay
        zIndex={10}
        className='cursor-move'
        dropAnimation={dropAnimation}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        {activeDragItem && activeDragItem?.type !== 'link' && (
          <Item coachOrgId={coachOrgId} item={activeDragItem} isDragging={true} />
        )}
        {activeDragItem && activeDragItem?.type === 'link' && (
          <div className='flex flex-col ring-1 p-4 ring-gray-200 bg-white shadow-xl rounded-lg overflow-hidden'>
            <h4 className='capitalize font-medium text-sm truncate'>
              <span className='truncate'>{activeDragItem.title || 'Link'}</span>
            </h4>
          </div>
        )}
      </DragOverlay>
    </DndContext>
  )
}

function Item({ coachOrgId, item, isDragging, attributes, listeners }) {
  if (item.type === availableItemTypes.VIDEO) {
    return (
      <VideoItem
        coachOrgId={coachOrgId}
        item={item}
        isDragging={isDragging}
        attributes={attributes}
        listeners={listeners}
      />
    )
  }

  if (item.type === availableItemTypes.WORKOUT) {
    return (
      <WorkoutItem
        coachOrgId={coachOrgId}
        item={item}
        isDragging={isDragging}
        attributes={attributes}
        listeners={listeners}
      />
    )
  }

  if (item.type === availableItemTypes.PROGRAM) {
    return <ProgramItem item={item} attributes={attributes} isDragging={isDragging} listeners={listeners} />
  }

  if (item.type === availableItemTypes.PROGRAMGROUP) {
    return (
      <ProgramGroupItem
        coachOrgId={coachOrgId}
        item={item}
        isDragging={isDragging}
        attributes={attributes}
        listeners={listeners}
      />
    )
  }

  return null
}
