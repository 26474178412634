import React, { memo } from 'react'
import { FiCopy } from 'react-icons/fi'

import { useAlert } from 'common/components/Alert/hooks/useAlert'

const CopyToCllipboardButton = ({ data }) => {
  const { createAlert } = useAlert()

  return (
    <FiCopy
      onClick={() =>
        navigator.clipboard.writeText(data).then(() => {
          createAlert({ text: 'Copied to clipboard', type: 'success' })
        })
      }
      className='ml-[10px] opacity-30 cursor-pointer'
    />
  )
}

export default memo(CopyToCllipboardButton)
