import React from 'react'

import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'

import { useUpdateTabsMutation } from '../../layoutApi'

import { Button } from 'common/components/Button/Button'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { useAlert } from 'common/components/Alert/hooks/useAlert'

export function TabActionRow({ coachOrgId, selectedTabEntry, handleAddRow, isLoading }) {
  const selectedTabId = selectedTabEntry?.[0]
  const selectedTab = selectedTabEntry?.[1]

  const { createAlert } = useAlert()

  const [updateTabs] = useUpdateTabsMutation()

  const handleTabVisibilityToggle = async (tabId, tab) => {
    const visibilityLevel = tab.visibilityLevel === 'coaches' ? 'anyone' : 'coaches'
    let tabsUpdate = {
      [tabId]: { ...tab, visibilityLevel },
    }
    await updateTabs({ coachOrgId, tabs: tabsUpdate })

    if (selectedTab.visibilityLevel === 'anyone') {
      createAlert({ text: 'Tab published for coaches only', type: 'success' })
    } else {
      createAlert({ text: 'Tab published for everyone', type: 'success' })
    }
  }

  return (
    <div className='flex flex-row justify-between mb-8'>
      <div className='flex items-center min-h-[28px]'>
        {selectedTabEntry &&
          (selectedTab.visibilityLevel === 'anyone' ? (
            <Tooltip content='Change to for coaches' triggerClasses='flex'>
              <Button
                size='sm'
                variant='secondary'
                onClick={(e) => {
                  e.stopPropagation()
                  handleTabVisibilityToggle(selectedTabId, selectedTab)
                }}
                aria-label='Layout visibility toggle'
              >
                Visible to everyone
                <HiOutlineEye className='cursor-pointer text-gray-500 w-5 h-5 ml-2' />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip content='Change to for everyone' triggerClasses='flex'>
              <Button
                size='sm'
                variant='secondary'
                onClick={(e) => {
                  e.stopPropagation()
                  handleTabVisibilityToggle(selectedTabId, selectedTab)
                }}
                aria-label='Layout visibility toggle'
              >
                Visible to coaches
                <HiOutlineEyeOff className='cursor-pointer text-gray-500 w-5 h-5 ml-2' />
              </Button>
            </Tooltip>
          ))}
      </div>
      <Button onClick={handleAddRow} size='md' disabled={isLoading}>
        Add row
      </Button>
    </div>
  )
}
