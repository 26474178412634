import * as yup from 'yup'
import { trimString } from './utils'
import { emptyStringToZero } from 'common/utils/yupSchemaUtils'

export const schema = yup
  .object({
    name: yup
      .string()
      .required('Program name required')
      .max(35, 'Program name must be 35 characters maximum')
      .transform(trimString),
    subtitle: yup.string().max(30, 'Program subtitle must be fewer than 30 characters').transform(trimString),
    previewImg: yup.string().transform(trimString),
    introVideo: yup.string().transform(trimString),
    reps: yup.number().min(1, 'At least 1 repeat required').max(16, 'Maximum 16 repeats').transform(emptyStringToZero),
    description: yup.string().max(400, 'Program description must be fewer than 400 characters').transform(trimString),
    frequency: yup.string().max(150, 'Program frequency must be fewer than 150 characters').transform(trimString),
    prerequisites: yup
      .string()
      .max(150, 'Program prerequisites must be fewer than 150 characters')
      .transform(trimString),
    isPaid: yup.boolean(),
    displayByDays: yup.boolean(),
  })
  .required()
