import React from 'react'

import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'

import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'

import { ItemCard } from './ItemCard'
import { ItemLoadingCard } from './ItemLoadingCard'

export const VideoItem = React.memo(({ coachOrgId, item, isDragging, attributes, listeners }) => {
  const { data: videoData } = useListenCoachVideosQuery({ coachOrgId })
  const videos = videoData || {}
  const videosLoading = videoData === undefined || videoData?.isLoading
  const video = videos?.[item?.id]

  if (videosLoading) {
    return <ItemLoadingCard />
  }

  if (!video) {
    return null
  }

  return (
    <ItemCard
      id={item.id}
      name={video?.title}
      description={video?.subtitle}
      type={availableItemTypes.VIDEO}
      previewImg={video?.previewImg}
      isDragging={isDragging}
      attributes={attributes}
      listeners={listeners}
    />
  )
})
