import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useCreateProgressionMutation } from 'modules/Progressions/progressionsApi'
import { useListenUserProfileQuery } from 'modules/Users/userApi'

export function ExerciseInputErrorWithDelete({ message, index, value, progressions, containerClasses = '' }) {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''
  const [createProgression] = useCreateProgressionMutation()

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const { clearErrors } = useFormContext()

  const handleRemoveProgressionExercise = async () => {
    // Get progression name from error message
    const progressionName = message.split('"')[1]

    const updatedExercises = progressions[progressionName].filter(
      (progressionExercise) => progressionExercise !== value
    )
    await createProgression({ coachOrgId, progressionKey: progressionName, exercises: updatedExercises })
    setDeleteConfirmation(false)
    clearErrors(`exercises.${index}`)
  }

  if (!deleteConfirmation) {
    return (
      <div className={`flex flex-col text-xs mt-1 ${containerClasses}`}>
        <span className='text-tRed'>{message}</span>
        <span>
          Would you like to remove this exercise from that progression?
          <button
            type='button'
            className='ml-2 underline hover:text-tRed transition-colors'
            onClick={() => setDeleteConfirmation(true)}
          >
            Yes
          </button>
        </span>
      </div>
    )
  }

  return (
    <div className={`flex flex-col text-xs mt-1 ${containerClasses}`}>
      <span className='text-tRed'>{message}</span>
      <span>
        Are you sure?
        <button
          type='button'
          className='ml-2 underline hover:text-tGreen transition-colors'
          onClick={() => setDeleteConfirmation(false)}
        >
          Go back
        </button>
        <button
          type='button'
          className='ml-2 underline hover:text-tRed transition-colors'
          onClick={() => handleRemoveProgressionExercise()}
        >
          Confirm
        </button>
      </span>
    </div>
  )
}
