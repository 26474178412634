import React, { memo } from 'react'

import LogoutButton from './LogoutButton'
import { Button } from 'common/components/Button/Button'

function AccoutFormActions({ handleCancel, disabled, submitRef, showLogoutBtn = false }) {
  return (
    <div className='flex justify-start items-center w-full px-2.5'>
      {showLogoutBtn && <LogoutButton />}
      <Button variant='secondary' className='py-4 px-6 text-base ml-auto' onClick={handleCancel} disabled={disabled}>
        Cancel
      </Button>
      <Button type='submit' className='py-4 px-6 ml-2.5 text-base' disabled={disabled} ref={submitRef}>
        Save
      </Button>
    </div>
  )
}

export default memo(AccoutFormActions)
