/**
 * Changing Email is fundamentally difficult for Auth0 so removed that ability for now.
 * Change password was not implemented correctly. Need to just REST api trigger an email for change password for now
 */
import React, { useEffect, useRef, memo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual } from 'lodash'

import { profileSchema } from 'modules/Account/schema'
import { useUpdateProfileMutation } from 'modules/Account/accountApi'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { Divider } from './styles'
import AccoutFormActions from './AccoutFormActions'
import InputBlock from 'common/components/InputBlock/InputBlock'

function ProfileForm({ profile, userId }) {
  const { createAlert } = useAlert()

  const [updateProfile] = useUpdateProfileMutation()

  const defaultValues = {
    firstname: profile.firstname || '',
    lastname: profile.lastname || '',
    // email: profile.email,
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm({ mode: 'onChange', defaultValues, resolver: yupResolver(profileSchema) })

  const formState = watch()

  const onSubmit = async (submitData) => {
    try {
      await updateProfile({ userId, profile: submitData })
      createAlert({ text: 'Profile updated!', type: 'success' })
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleCancel = () => {
    reset(defaultValues)
  }

  const { addManyInputRefs, moveFocusOnKeyPress } = useFormRefsControl()
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    addManyInputRefs([
      { ref: firstNameRef, name: 'firstName' },
      { ref: lastNameRef, name: 'lastName' },
      { ref: submitRef, name: 'submit' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', (e) => moveFocusOnKeyPress(e, handleSubmit(onSubmit)))

  return (
    <form id='profileForm' onSubmit={handleSubmit(onSubmit)} className='flex items-start flex-wrap pb-2.5'>
      <InputBlock
        name='firstname'
        label='First name'
        placeholder='Enter first name'
        register={register}
        inputRef={firstNameRef}
        error={errors?.firstname?.message}
        fullWidth={false}
      />
      <InputBlock
        name='lastname'
        label='Last name'
        placeholder='Enter last name'
        register={register}
        inputRef={lastNameRef}
        error={errors?.lastname?.message}
        fullWidth={false}
      />
      {/* <FormInput
        type='email'
        label='Email'
        name='email'
        placeholder='Enter email'
        register={register}
        error={errors?.email?.message}
        onFocus={() => setFocusedField('email')}
        fullWidth
      />
      <FormInput
        label='New password'
        name='newPassword'
        register={register}
        error={errors?.newPassword?.message}
        onFocus={() => setFocusedField('newPassword')}
      />
      <FormInput
        label='Confirm new password'
        name='confirmPassword'
        register={register}
        error={errors?.confirmPassword?.message}
        onFocus={() => setFocusedField('confirmPassword')}
      /> */}
      <Divider />
      <AccoutFormActions
        handleCancel={handleCancel}
        disabled={isEqual(defaultValues, formState)}
        submitRef={submitRef}
        showLogoutBtn={true}
      />
    </form>
  )
}

export default memo(ProfileForm)
