export const filterExercises = (exercises, searchQry) => {
  const exIds = Object.keys(exercises)

  const filteredExIds = exIds.filter((exId) => {
    const exercise = exercises[exId]

    if (!exercise?.name) return false

    const exNameLowered = exercise.name.toLowerCase()
    const qryLowered = searchQry.toLowerCase()
    const matches = exNameLowered.includes(qryLowered)
    return matches
  })

  const filtered = {}
  filteredExIds.forEach((exId) => {
    filtered[exId] = exercises[exId]
  })

  return filtered
}

export const filterProgressions = (progressions, searchQry) => {
  const categories = Object.keys(progressions)

  const filteredCategories = categories.filter((catgry) => {
    const catgryLowered = catgry.toLowerCase()
    const qryLowered = searchQry.toLowerCase()
    const matches = catgryLowered.includes(qryLowered)
    return matches
  })

  const filtered = {}
  filteredCategories.forEach((catId) => (filtered[catId] = progressions[catId]))
  return filtered
}
