import { createSlice, createSelector } from '@reduxjs/toolkit'
import { createUID } from 'common/utils/createUID'
import { each, inRange, sortBy } from 'lodash'
import { EMPTY_DAY } from './programModels'

export const initialState = {
  workouts: {},
  partsMultiSelected: [],
  partSelectFrom: null,
  partSelectTo: null,
  partsSelected: [],
  workoutsMultiSelected: [],
  workoutSelectFrom: null,
  workoutSelectTo: null,
  workoutsSelected: [],
  partsCopied: [],
  workoutsCopied: [],
  weeksCopied: [],
  maxDayIdx: null,
  wktIdsSorted: {},
}

//to create same references and limit re-renders
const selectorVals = {
  dayIdxIsEmpty: {},
}

const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    draftProgramListened: (state, action) => {
      const { wktIdsSorted } = action.payload
      if (!wktIdsSorted) return

      const maxDayIdx = wktIdsSorted.length - 1

      state.maxDayIdx = maxDayIdx

      state.wktIdsSorted = wktIdsSorted

      selectorVals.dayIdxIsEmpty = {}
    },
    draftWorkoutRetrieved: (state, action) => {
      const { workout } = action.payload
      state.workouts[workout.id] = workout
    },
    draftWorkoutsDeleted: (state, action) => {
      const { workoutIds } = action.payload

      if (state.partsSelected.length > 0 || state.workoutsSelected.length > 0) {
        const remainingParts = state.partsSelected.filter(
          (selectedPart) => !workoutIds.includes(selectedPart.workoutId)
        )
        const remainingWkts = state.workoutsSelected.filter((selectedWkt) => !workoutIds.includes(selectedWkt.id))

        state.partsSelected = remainingParts
        state.workoutsSelected = remainingWkts
      }
    },
    partSelected: (state, action) => {
      const { part, workoutId } = action.payload

      const partToSave = { ...part, workoutId }
      const alreadyIncluded = state.partsSelected.find((selectedPart) => selectedPart.id === part.id)

      state.partSelectFrom = partToSave
      state.partSelectTo = initialState.partSelectTo
      state.partsMultiSelected = initialState.partsMultiSelected

      if (!alreadyIncluded) {
        state.partsSelected.push(partToSave)
      } else {
        const selectedRemoved = state.partsSelected.filter((selectedPart) => selectedPart.id !== part.id)
        state.partsSelected = selectedRemoved
      }
    },
    partsMultiSelected: (state, action) => {
      const { workoutId, partIdx: selectToIdx, part: selectToPart } = action.payload

      if (
        state.partSelectTo !== null &&
        selectToPart.id !== state.partSelectTo.id &&
        workoutId === state.partSelectTo.workoutId
      ) {
        const updatedPartsSelected = state.partsSelected.filter(
          (selectedPart) =>
            !state.partsMultiSelected.some((multiSelectedPart) => multiSelectedPart.id === selectedPart.id)
        )
        state.partsSelected = updatedPartsSelected
      }

      if (state.partSelectFrom !== null && state.partSelectFrom.workoutId === workoutId) {
        const wktSelectedParts = state.partsSelected.filter((selectedPart) => selectedPart.workoutId === workoutId)
        const wktParts = state.workouts[workoutId].parts

        const selectFromPartIdx = wktParts.findIndex((part) => part.id === state.partSelectFrom.id)
        const isUpwardSelection = selectToIdx > selectFromPartIdx

        let partsMultiSelected = []
        wktParts.forEach((wktPart, idx) => {
          const partAlreadyIncluded = wktSelectedParts.find((selectedPart) => selectedPart.id === wktPart.id)
          const isPartInRange = isUpwardSelection
            ? inRange(idx, selectFromPartIdx, selectToIdx + 1)
            : inRange(idx, selectToIdx, selectFromPartIdx + 1)

          if (isPartInRange && !partAlreadyIncluded && wktPart.id !== state.partSelectFrom.id) {
            const partToSave = { ...wktPart, workoutId }
            partsMultiSelected.push(partToSave)
          } else if (isPartInRange && partAlreadyIncluded && wktPart.id !== state.partSelectFrom.id) {
            state.partsSelected = state.partsSelected.filter((selectedPart) => selectedPart.id !== wktPart.id)
          }
        })

        const partsSelected = [...state.partsSelected, ...partsMultiSelected]

        state.partsSelected = partsSelected
        state.partSelectTo = { ...selectToPart, workoutId }
        state.partsMultiSelected = partsMultiSelected
      }
    },
    selectedWorkoutsDeleted: (state, action) => {
      const { workoutIds } = action.payload

      const partsRemaining = state.partsSelected.filter((selectedPart) => !workoutIds.includes(selectedPart.workoutId))

      state.partsSelected = partsRemaining
      state.workoutsSelected = initialState.workoutsSelected
    },
    selectedPartsCleared: (state, _) => {
      state.partsSelected = initialState.partsSelected
    },
    workoutSelected: (state, action) => {
      const { workout } = action.payload

      const alreadyIncluded = state.workoutsSelected.find((selectedWkt) => selectedWkt.id === workout.id)

      state.workoutSelectFrom = workout
      state.workoutSelectTo = initialState.workoutSelectTo
      state.workoutsMultiSelected = initialState.workoutsMultiSelected

      if (!alreadyIncluded) {
        state.workoutsSelected.push(workout)
      } else {
        const selectedRemoved = state.workoutsSelected.filter((selectedWkt) => selectedWkt.id !== workout.id)
        state.workoutsSelected = selectedRemoved
      }
    },
    workoutsMultiSelected: (state, action) => {
      const { workout: selectToWkt } = action.payload
      const currProgramWktIds = state.wktIdsSorted
      const allCurrProgramWktsEntries = Object.entries(state.workouts).filter(([wktId, _]) =>
        currProgramWktIds.includes(wktId)
      )
      const allCurrProgramWkts = Object.fromEntries(allCurrProgramWktsEntries)

      // Workout multi select TO anchor was set and is different than current selectToWkt
      if (state.workoutSelectTo !== null && selectToWkt.id !== state.workoutSelectTo.id) {
        // Remove previously multi selected workouts
        const updatedWktsSelected = state.workoutsSelected.filter(
          (selectedWkt) =>
            !state.workoutsMultiSelected.some((multiSelectedWkt) => multiSelectedWkt.id === selectedWkt.id)
        )
        state.workoutsSelected = updatedWktsSelected
      }

      if (state.workoutSelectFrom !== null) {
        const selectFromWkt = state.workoutSelectFrom
        const isUpwardSelection = selectToWkt.dayIdx > selectFromWkt.dayIdx

        let wktsMultiSelected = []
        each(allCurrProgramWkts, (wkt) => {
          const isWktSelectable = wkt.type !== EMPTY_DAY.type
          const wktAlreadyIncluded = state.workoutsSelected.find((selectedWkt) => selectedWkt.id === wkt.id)
          const isWktInRange = isUpwardSelection
            ? inRange(wkt.dayIdx, selectFromWkt.dayIdx, selectToWkt.dayIdx + 1)
            : inRange(wkt.dayIdx, selectToWkt.dayIdx, selectFromWkt.dayIdx + 1)

          if (isWktInRange && isWktSelectable && !wktAlreadyIncluded && wkt.id !== selectFromWkt.id) {
            wktsMultiSelected.push(wkt)
          } else if (isWktInRange && isWktSelectable && wktAlreadyIncluded && wkt.id !== selectFromWkt.id) {
            state.workoutsSelected = state.workoutsSelected.filter((selectedWkt) => selectedWkt.id !== wkt.id)
          }
        })

        const wktsSelected = [...state.workoutsSelected, ...wktsMultiSelected]
        const sortedWktsSelected = sortBy(wktsSelected, 'dayIdx')

        state.workoutsSelected = sortedWktsSelected
        state.workoutSelectTo = selectToWkt
        state.workoutsMultiSelected = wktsMultiSelected
      }
    },
    selectedWorkoutsCleared: (state, _) => {
      state.workoutsSelected = initialState.workoutsSelected
    },
    partsCopied: (state, action) => {
      const { parts } = action.payload
      const updatedParts = parts.map((part) => ({ ...part, id: createUID() }))

      state.partsCopied = updatedParts
    },
    copiedPartsCleared: (state, _) => {
      state.partsCopied = initialState.partsCopied
    },
    workoutsCopied: (state, action) => {
      const { workouts } = action.payload

      state.workoutsCopied = workouts
    },
    copiedWorkoutsCleared: (state, _) => {
      state.workoutsCopied = initialState.workoutsCopied
    },
    multiSelectReferencesCleared: (state, _) => {
      state.partsSelected = initialState.partsSelected
      state.workoutsSelected = initialState.workoutsSelected
      state.partsMultiSelected = initialState.partsMultiSelected
      state.partSelectFrom = initialState.partSelectFrom
      state.partSelectTo = initialState.partSelectTo
      state.workoutsMultiSelected = initialState.workoutsMultiSelected
      state.workoutSelectFrom = initialState.workoutSelectFrom
      state.workoutSelectTo = initialState.workoutSelectTo
    },
    weeksCopied: (state, action) => {
      const { week } = action.payload
      state.weeksCopied = [week]
    },
    copiedWeeksCleared: (state, _) => {
      state.weeksCopied = initialState.weeksCopied
    },
  },
})

export const {
  draftProgramListened,
  draftWorkoutRetrieved,
  draftWorkoutsDeleted,
  partSelected,
  partsMultiSelected,
  selectedWorkoutsDeleted,
  selectedPartsCleared,
  workoutsMultiSelected,
  workoutSelected,
  selectedWorkoutsCleared,
  partsCopied,
  copiedPartsCleared,
  workoutsCopied,
  copiedWorkoutsCleared,
  multiSelectReferencesCleared,
  weeksCopied,
  copiedWeeksCleared,
} = programSlice.actions

export const getPartsSelected = createSelector(
  (state) => state.program.partsSelected,
  (partsSelected) => partsSelected,
  {
    devModeChecks: { identityFunctionCheck: 'never' },
  }
)

export const getIsPartSelected = createSelector(
  (state) => state.program.partsSelected,
  (_, partId) => partId,
  (partsSelected, partId) => (partsSelected.find((selectedPart) => selectedPart.id === partId) ? true : false)
)

export const getWorkoutsSelected = createSelector(
  (state) => state.program.workoutsSelected,
  (workoutsSelected) => workoutsSelected,
  {
    devModeChecks: { identityFunctionCheck: 'never' },
  }
)

export const getIsWorkoutSelected = createSelector(
  (state) => state.program.workoutsSelected,
  (_, workoutId) => workoutId,
  (workoutsSelected, workoutId) => (workoutsSelected.find((selectedWkt) => selectedWkt.id === workoutId) ? true : false)
)

export const getDayIdxIsEmpty = createSelector(
  (state) => state.program.workouts,
  (state) => state.program.wktIdsSorted,
  (workouts, wktIdsSorted) => {
    let dayIdxIsEmpty = selectorVals.dayIdxIsEmpty

    const programWkts = {}
    each(wktIdsSorted, (wktId) => {
      programWkts[wktId] = workouts[wktId]
    })

    each(programWkts, (wkt) => {
      if (!wkt) return
      const { type, dayIdx } = wkt
      const isEmptyDay = type === 'empty' ? true : false
      dayIdxIsEmpty[dayIdx] = isEmptyDay
    })

    return dayIdxIsEmpty
  }
)

export const getWktsByIds = createSelector(
  (state) => state.program.workouts,
  (_, workoutIds) => workoutIds,
  (workouts, workoutIds) => {
    const workoutValues = Object.values(workouts)
    const workoutsDragged = workoutValues.filter((workout) => workoutIds.includes(workout.id))
    return workoutsDragged
  }
)

export const getPartsCopied = createSelector(
  (state) => state.program.partsCopied,
  (partsCopied) => partsCopied,
  {
    devModeChecks: { identityFunctionCheck: 'never' },
  }
)

export const getWorkoutsCopied = createSelector(
  (state) => state.program.workoutsCopied,
  (workoutsCopied) => workoutsCopied,
  {
    devModeChecks: { identityFunctionCheck: 'never' },
  }
)

export const getWeeksCopied = createSelector(
  (state) => state.program.weeksCopied,
  (weeksCopied) => weeksCopied,
  {
    devModeChecks: { identityFunctionCheck: 'never' },
  }
)

export default programSlice.reducer
