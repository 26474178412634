import React, { useState, useEffect } from 'react'
import tw, { styled, css } from 'twin.macro'
import { useSelector } from 'react-redux'
import { IoIosWater } from 'react-icons/io'
import { CgPen, CgCloseO, CgCheckO } from 'react-icons/cg'

import { useSavePartsMutation, useUpdatePartMutation } from '../programApi'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { maybePluralize } from 'common/utils/stringUtils'
import { getIsPartSelected } from 'modules/Programs/programSlice'
import Time from 'common/components/Time/Time'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { Spinner } from 'common/components/Spinner/Spinner'
import { PartHeader } from './PartHeader'
import { PastePartButton } from './styles'
import { handlePartBtnMouseEnter, handlePartBtnMouseLeave, handlePastePart } from '../handlers/commonPartHandlers'
import { getPrettyExerciseTime } from '../utils/timeUtils'
import { updateLegacyRestPart } from '../utils/legacyRestPartUtils'

const partContainerStyles = css`
  &:hover #partcontainer-actions {
    display: flex;
  }

  &:hover #restpart-timer-edit {
    visibility: visible;
  }
`

const PastePartBtnPlaceholder = styled.div(
  css`
    &:hover #partcontainer-actions {
      display: flex;
    }

    &:hover #restpart-timer-edit {
      visibility: visible;
    }
  `
)

const PartContainer = styled.div(() => [tw`flex flex-col relative border-b bg-white`])

export const RestPart = React.memo((props) => {
  const {
    part,
    partIdx,
    isEditing,
    partsCopied,
    workout,
    attributes,
    listeners,
    orgId,
    isLastPart,
    setActivePartIdx, //For hotkeys
    setActiveExIdx, //For hotkeys
    isLegacyRestPart,
  } = props
  const [saveParts] = useSavePartsMutation()
  const [updatePart] = useUpdatePartMutation()
  const { createAlert } = useAlert()

  const isPartSelected = useSelector((state) => getIsPartSelected(state, part.id))
  const [isPartSelectedState, setIsPartSelectedState] = useState(isPartSelected)
  useEffect(() => {
    setIsPartSelectedState(isPartSelected)
  }, [isPartSelected])

  useEffect(() => {
    if (isLegacyRestPart) {
      const updatedRestPart = updateLegacyRestPart(part)
      updatePart({ orgId, workoutId: workout.id, partIdx, part: updatedRestPart })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PartContainer
      onMouseEnter={() => {
        if (isEditing) {
          setActivePartIdx(false)
          setActiveExIdx(false)
        }
      }}
      css={[partContainerStyles, isPartSelected && !isEditing && tw`bg-white hover:bg-white`]}
    >
      <PartSelectedOverlay isEditing={isEditing} isPartSelected={isPartSelectedState} />
      {partsCopied.length > 0 && (
        <PastePartButton
          id='partcontainer-actions'
          className='top-0 -translate-y-1/2 -translate-x-1/2'
          onClick={(e) => {
            e.stopPropagation()

            handlePastePart({
              workout,
              partIdx,
              partsCopied,
              saveParts,
              createAlert,
              orgId,
            })
          }}
          onMouseEnter={handlePartBtnMouseEnter}
          onMouseLeave={handlePartBtnMouseLeave}
        >
          paste {maybePluralize({ text: 'block', count: partsCopied.length })}
        </PastePartButton>
      )}
      <PastePartBtnPlaceholder className='absolute left-1/2 top-0 -translate-y-1/2 -translate-x-1/2 w-[83px] h-[22px]' />
      <div className='px-4 pt-4'>
        <PartHeader
          part={part}
          partIdx={partIdx}
          isPartSelected={isPartSelectedState}
          setIsPartSelectedState={setIsPartSelectedState}
          workout={workout}
          createAlert={createAlert}
          attributes={attributes}
          listeners={listeners}
          orgId={orgId}
        />
      </div>
      <RestPartTimer isEditing={isEditing} part={part} orgId={orgId} workoutId={workout.id} partIdx={partIdx} />
      {partsCopied.length > 0 && isLastPart && (
        <PastePartButton
          id='partcontainer-actions'
          className='bottom-0 translate-y-1/2 -translate-x-1/2'
          onClick={(e) => {
            e.stopPropagation()

            handlePastePart({
              workout,
              partIdx: partIdx + 1,
              partsCopied,
              saveParts,
              createAlert,
              orgId,
            })
          }}
          onMouseEnter={handlePartBtnMouseEnter}
          onMouseLeave={handlePartBtnMouseLeave}
        >
          paste {maybePluralize({ text: 'block', count: partsCopied.length })}
        </PastePartButton>
      )}
    </PartContainer>
  )
})

const PartSelectedOverlay = styled.div(({ isPartSelected, isEditing }) => [
  tw`hidden`,
  isPartSelected && !isEditing && tw`block absolute inset-0 bg-tGreen bg-opacity-[0.15] z-10 pointer-events-none`,
])

function RestPartTimer({ isEditing, part, orgId, workoutId, partIdx }) {
  const [isTimerEditing, setIsTimerEditing] = useState(false)
  const [timerValue, setTimerValue] = useState(part.time)

  const [updatePart, { isLoading: isUpdating }] = useUpdatePartMutation()

  const handleTimerUpdate = async () => {
    const partUpdate = {
      'exercises/0/time': timerValue,
      time: timerValue,
    }
    await updatePart({ orgId, workoutId, partIdx, part: partUpdate })

    setIsTimerEditing(false)
  }

  useEffect(() => {
    if (!isEditing) {
      setIsTimerEditing(false)
      setTimerValue(part.time)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  if (isTimerEditing && isEditing) {
    return (
      <div className='flex items-center justify-center mx-2.5 rounded-md mb-6 px-4 pb-2 pt-4 text-sm font-medium text-gray-400 border shadow-sm'>
        <FormRefsControlProvider>
          <Time
            name={'restTime'}
            value={timerValue}
            setValue={setTimerValue}
            inputClassNames='!py-2 !h-auto !text-sm !rounded-lg'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleTimerUpdate()
              }
            }}
            autoFocus='mins'
            timeTypes={['mins', 'secs']}
          />
        </FormRefsControlProvider>
        <div className='self-start mt-3 flex items-center'>
          {isUpdating ? (
            <div className='ml-3 mb-1'>
              <Spinner className='w-4 h-4 text-gray-100' />
            </div>
          ) : (
            <div className='ml-3'>
              <CgCheckO
                onClick={() => handleTimerUpdate()}
                className='cursor-pointer w-4 h-4 text-gray-500 hover:text-tGreen'
              />
            </div>
          )}
          <div className='ml-1'>
            <CgCloseO
              onClick={() => {
                setTimerValue(part.time)
                setIsTimerEditing(false)
              }}
              className='cursor-pointer w-4 h-4 text-gray-500 hover:text-tRed'
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      css={[
        tw`flex items-center justify-center mb-4 mx-2.5 rounded-md px-4 py-8 text-sm font-medium text-gray-400 border shadow-sm`,
        isEditing && tw`mb-6`,
      ]}
    >
      <IoIosWater className='w-4 h-4' />
      <span className='mx-1' onClick={() => setIsTimerEditing(true)}>
        Rest Timer: {getPrettyExerciseTime(part.time)}
      </span>
      {isEditing && (
        <CgPen
          onClick={() => setIsTimerEditing(true)}
          className='cursor-pointer invisible w-3.5 h-3.5 text-gray-500 hover:text-tGreen'
          id='restpart-timer-edit'
        />
      )}
    </div>
  )
}
