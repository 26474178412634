import tw, { styled } from 'twin.macro'
import React, { useState, useEffect } from 'react'
import { CgArrowLongLeft, CgArrowLongRight, CgPlayTrackNext, CgPlayTrackPrev } from 'react-icons/cg'
import { animateScroll } from 'react-scroll'
import { Tooltip } from 'common/components/Tooltip/Tooltip'

import { Button } from 'common/components/Button/Button'

const checkIsOverflowX = (element) => element.scrollWidth > element.clientWidth

export function RowScrollActions({ id, scrollAreaRef, currentRow }) {
  const [isOverflowX, setIsOverflowX] = useState(false)

  useEffect(() => {
    if (scrollAreaRef.current) {
      setIsOverflowX(checkIsOverflowX(scrollAreaRef.current))
    }
  }, [scrollAreaRef, currentRow])

  const scrollOptions = {
    containerId: id,
    horizontal: true,
    duration: 200,
    smooth: true,
  }

  const handleScrollMore = (scrollX) => animateScroll.scrollMore(scrollX, scrollOptions)
  const handleScrollToTop = () => animateScroll.scrollToTop(scrollOptions)
  const handleScrollToEnd = () => animateScroll.scrollToBottom(scrollOptions)

  return (
    <Container visible={isOverflowX} data-testid='row-scroll-actions'>
      <div className='flex'>
        <Tooltip content='Scroll to start'>
          <Button
            variant='secondary'
            size='sm'
            css={tw`bg-white w-8 h-8 px-0 py-0`}
            onClick={() => handleScrollToTop()}
          >
            <CgPlayTrackPrev className='w-5 h-5' />
          </Button>
        </Tooltip>
        <Tooltip content='Scroll left'>
          <Button
            variant='secondary'
            size='sm'
            css={tw`bg-white w-8 h-8 px-0 py-0`}
            onClick={() => handleScrollMore(-700)}
          >
            <CgArrowLongLeft className='w-4 h-4' />
          </Button>
        </Tooltip>
        <Tooltip content='Scroll right'>
          <Button
            variant='secondary'
            size='sm'
            css={tw`bg-white w-8 h-8 px-0 py-0`}
            onClick={() => handleScrollMore(700)}
          >
            <CgArrowLongRight className='cursor-pointer w-4 h-4' />
          </Button>
        </Tooltip>
        <Tooltip content='Scroll to end'>
          <Button
            variant='secondary'
            size='sm'
            css={tw`bg-white w-8 h-8 px-0 py-0`}
            onClick={() => handleScrollToEnd()}
          >
            <CgPlayTrackNext className='cursor-pointer w-5 h-5' />
          </Button>
        </Tooltip>
      </div>
    </Container>
  )
}

const Container = styled.div(({ visible }) => [tw`hidden justify-end px-4 pt-2`, visible && tw`flex`])
