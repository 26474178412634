import React, { useState } from 'react'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensors, useSensor } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { each, times } from 'lodash'

import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenLinkHubQuery, useUpdateLinkHubMutation } from './linkHubApi'

import { buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { EmptyStateContainer } from 'common/components/EmptyStateContainer/EmptyStateContainer'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { createUID } from 'common/utils/createUID'
import { Sortable } from 'common/components/Sortable/Sortable'
import { dropAnimation } from 'common/utils/dndUtils'
import LinkView from './LinkView'
import CreateLinkForm from './CreateLinkForm/CreateLinkForm'
import ResourcesDescription from './ResourcesDescription'
import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { LinkViewSkeleton } from './LinkViewSkeleton'

function LinkHub() {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: linkHubData } = useListenLinkHubQuery({ coachOrgId })
  const linkHubLoading = linkHubData === undefined || linkHubData?.isLoading
  const linkHub = linkHubLoading || linkHubData === null ? {} : linkHubData

  const { description, resources: initResources } = linkHub
  const resources = addResourceIds(initResources) //Add IDs if missing
  const [updateLinkHub] = useUpdateLinkHubMutation()

  const [activeDrag, setActiveDrag] = useState(null)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const handleDragStart = ({ active }) => {
    setActiveDrag(resources.find((resource) => resource.id === active.id))
  }

  const handleDragEnd = async ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = resources.findIndex((resource) => resource.id === active.id)
      const newIndex = resources.findIndex((resource) => resource.id === over.id)
      const updatedResources = arrayMove(resources, oldIndex, newIndex)

      updateLinkHub({ coachOrgId, updatedLinkHub: { resources: updatedResources } })
      setActiveDrag(null)
    }
  }

  return (
    <div className='flex flex-col min-h-screen bg-offWhite px-16'>
      <div className='w-full max-w-7xl mx-auto mt-12'>
        <div className='flex items-center justify-between'>
          <h1 className='font-bold text-5xl text-tBlack mr-6'>Resources</h1>
          <div className='ml-6'>
            <Dialog>
              <DialogTrigger css={[buttonBase, buttonVariants.primary, buttonSizes.lg]}>Add new link</DialogTrigger>
              <DialogContent header='Add new link'>
                <FormRefsControlProvider>
                  <CreateLinkForm
                    coachOrgId={coachOrgId}
                    length={resources?.length || 0}
                    resources={resources}
                    hideDelete
                  />
                </FormRefsControlProvider>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        {linkHubLoading ? (
          <div className='mt-4 mb-6 h-10 flex items-center'>
            <Skeleton className='!w-80' />
          </div>
        ) : (
          <ResourcesDescription coachOrgId={coachOrgId} description={description} />
        )}
        {linkHubLoading ? (
          <div className='mb-20'>
            {times(3, (index) => (
              <LinkViewSkeleton key={index} />
            ))}
          </div>
        ) : (
          <div className='mb-20'>
            {resources.length ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={resources.map((resource) => resource.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {resources.map((resource, index) => (
                    <Sortable
                      key={resource.id}
                      id={resource.id}
                      withHandle={true}
                      className='group'
                      draggingClasses='ring-2 ring-tGreen rounded-2xl opacity-0'
                    >
                      <LinkView
                        {...resource}
                        resources={resources}
                        length={resources?.length || 0}
                        coachOrgId={coachOrgId}
                        index={index}
                      />
                    </Sortable>
                  ))}
                </SortableContext>
                <DragOverlay
                  zIndex={10}
                  className='cursor-move'
                  dropAnimation={dropAnimation}
                  modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}
                >
                  {activeDrag && (
                    <div className='group mb-5 ring-2 ring-tGreen rounded-2xl'>
                      <LinkView coachOrgId={coachOrgId} {...activeDrag} />
                    </div>
                  )}
                </DragOverlay>
              </DndContext>
            ) : (
              <EmptyStateContainer text='No resources' />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

//Takes resources and mints them with unique IDs if they do not exist for drag and drop
function addResourceIds(resources) {
  if (!resources) return []
  const updatedResources = []
  each(resources, (resource, index) => {
    if (resource && !resource.id) {
      const uid = createUID()
      updatedResources.push({ ...resource, id: uid })
    } else if (resource) {
      updatedResources.push({ ...resource })
    }
  })
  return updatedResources
}

export default LinkHub
