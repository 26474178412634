import React from 'react'
import { useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const animateLayoutChanges = (args) => (args.isSorting || args.wasDragging ? defaultAnimateLayoutChanges(args) : true)

const defaultDisabledProps = {
  draggable: false,
  droppable: false,
}

export const Sortable = ({
  id,
  disabled = defaultDisabledProps,
  withHandle = false,
  variableSize = false,
  customData,
  className,
  style: customStyle = {},
  draggingClasses,
  zoom = 1,
  children,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { setNodeRef, isDragging, attributes, listeners, transform, transition } = useSortable({
    id,
    data: customData,
    animateLayoutChanges,
    disabled,
  })

  const CSSTransform = variableSize ? CSS.Translate.toString(transform) : CSS.Transform.toString(transform)

  const style = {
    transition,
    transform: CSSTransform,
    ...customStyle,
  }

  const classNames = `${className ? className : ''} ${isDragging ? draggingClasses : ''} touch-manipulation'`

  if (withHandle) {
    return (
      <div
        ref={setNodeRef}
        className={classNames}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        tabIndex={-1}
      >
        {React.cloneElement(children, { attributes, listeners, isDragging })}
      </div>
    )
  }

  return (
    <div
      ref={setNodeRef}
      className={classNames}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...attributes}
      {...listeners}
      tabIndex={-1}
    >
      {children}
    </div>
  )
}
