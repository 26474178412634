import React from 'react'

import { useListenProgramQuery } from 'modules/Layout/layoutApi'

import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'

import ResultCard from './ResultCard'
import ResultLoadingCard from './ResultLoadingCard'

function ProgramResult({ item, selected }) {
  const { data: program } = useListenProgramQuery({ programId: item.id })
  const programLoading = program === undefined || program?.isLoading

  if (programLoading) {
    return <ResultLoadingCard selected={selected} />
  }

  if (!program) {
    return null
  }

  return (
    <ResultCard
      name={program?.name}
      description={program?.subtitle}
      type={availableItemTypes.PROGRAM}
      previewImg={program?.previewImg}
      selected={selected}
    />
  )
}

export default ProgramResult
