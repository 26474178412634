import tw, { styled } from 'twin.macro'
import { useRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { map, isEmpty, times } from 'lodash'

import { store } from 'store'

import { useCustomization } from 'common/contexts/Customization/useCustomization'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { getReadableExId } from 'modules/Programs/utils/exerciseDomain'
import { validateVideoFile } from 'common/utils/fileUploading/validators'
import { prepAsNameString } from 'common/utils/fileUploading/nameAndIdUtils'
import { AWSupload } from 'common/utils/fileUploading/uploadUtils'
import { Button } from 'common/components/Button/Button'
import { startExerciseUpload, updateExerciseProgress } from '../uploadingExercisesSlice'
import { useUpdateExistingItemDraftsMutation } from 'modules/Uploads/uploadApi'
import { useUpdateExerciseMutation } from '../exerciseLibraryApi'
import { UploadErrors } from './UploadErrors'
import useAuth from 'modules/Auth/AuthContext'
import { useWriteAWSErrorMutation } from 'modules/ExerciseLibrary/exerciseLibraryApi'
import { uploadingExerciseCancelled } from '../uploadingExercisesSlice'
import { ConfirmGuidelines } from './ConfirmGuidelines'
import { EX_NAME_MAX_LENGTH, EX_NAME_MIN_LENGTH } from '../ExerciseForm/schema'

export function ExerciseUpload({ coachOrgId, setUploadErrors, uploadErrors, hasReadVidGuidelines }) {
  const dispatch = useDispatch()
  const { createAlert } = useAlert()
  const { exVidOrientation, enableLandscapeExAudio } = useCustomization()
  const hiddenInputRef = useRef()
  const hasReadVidGuidelinesRef = useRef(hasReadVidGuidelines)
  const [currentStep, setCurrentStep] = useState(0)

  const [updateExercise] = useUpdateExerciseMutation()
  const [updateAssetDrafts] = useUpdateExistingItemDraftsMutation()

  const uploadErrorValues = Object.values(uploadErrors)

  const { awsAuthError, awsAuthed, userId } = useAuth()
  const [writeAWSError] = useWriteAWSErrorMutation()
  useEffect(() => {
    //Temp code to track AWS auth errors
    if (awsAuthError) {
      writeAWSError({ userId, awsError: awsAuthError })
    }
  }, [awsAuthError, userId, writeAWSError])

  if (awsAuthError || !awsAuthed) {
    return (
      <div className='px-10 my-10'>Unable to upload content. Error code: AWS1. Please contact support@trybe.do</div>
    )
  }

  if (uploadErrorValues.length > 0) {
    return (
      <div className='flex flex-col py-6 px-4 md:px-10 overflow-y-auto h-full'>
        <UploadErrors uploadErrors={uploadErrorValues} />
      </div>
    )
  }

  const exVidGuidelines =
    exVidOrientation === 'portrait'
      ? 'https://trybefitness.notion.site/Trybe-Portrait-Exercise-Video-Guidelines-be0c1d07e72e44638f2b33ea255d0bd2'
      : 'https://trybefitness.notion.site/Trybe-Landscape-Exercise-Video-Guidelines-4f3c4c4cdf0840a2b410fc60d559ec6b'

  return (
    <UploadContainer
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={(e) => {
        fileDrop({
          e,
          setUploadErrors,
          updateAssetDrafts,
          updateExercise,
          coachOrgId,
          dispatch,
          createAlert,
          exVidOrientation,
          enableLandscapeExAudio,
        })
      }}
    >
      {hasReadVidGuidelinesRef?.current !== false && (
        <p className='absolute top-6 text-sm text-black text-center border border-gray-400 py-3 px-5 rounded-lg'>
          Before uploading exercises, please read our <br />
          <a
            href={exVidGuidelines}
            target='_blank'
            rel='noreferrer nofollow'
            className='underline underline-offset-1 hover:text-tGreen'
          >
            exercise video requirements
          </a>
          <br />
          to give users the best app experience.
        </p>
      )}
      {hasReadVidGuidelinesRef?.current === false && currentStep === 0 ? (
        <ConfirmGuidelines coachOrgId={coachOrgId} exVidGuidelines={exVidGuidelines} setCurrentStep={setCurrentStep} />
      ) : (
        <>
          <p className='text-xl font-bold text-tBlack'>Drag and drop videos here</p>
          <p className='my-6 text-sm text-gray-500'>OR</p>
          <input
            type='file'
            id='myFile'
            className='hidden'
            ref={hiddenInputRef}
            accept='video/*'
            onChange={(e) => {
              uploadFiles({
                hiddenInputRef,
                setUploadErrors,
                updateExercise,
                updateAssetDrafts,
                coachOrgId,
                dispatch,
                createAlert,
                exVidOrientation,
                enableLandscapeExAudio,
              })
            }}
            multiple
          />
          <Button
            variant='primary'
            size='md'
            onClick={() => {
              hiddenInputRef.current.click()
            }}
          >
            Choose files
          </Button>
        </>
      )}
      {hasReadVidGuidelinesRef?.current === false && (
        <div className='absolute bottom-4 flex items-center justify-center'>
          {times(2, (num) => {
            return <StepDot key={num} isCompleted={num <= currentStep} />
          })}
        </div>
      )}
    </UploadContainer>
  )
}

const UploadContainer = tw.div`
  relative
  flex
  flex-col
  w-full
  h-full
  bg-white
  p-4
  rounded-xl
  justify-center
  items-center
  `

const StepDot = styled.div(({ isCompleted }) => [
  tw`w-2 h-2 bg-gray-300 rounded-full not-first:ml-2`,
  isCompleted && tw`bg-tGreen`,
])

/**
 * Generates a function that updates an exercises progress in upload reducer (local)
 * And upon completion, pushes exercise to firebase node
 */
function createProgressCb({ exerciseId, coachOrgId, dispatch }) {
  return (progress, storageKey) => {
    if (exerciseId && coachOrgId) {
      if (progress) {
        dispatch(updateExerciseProgress({ exerciseId, progress, storageKey }))
      }
    }
  }
}

async function onUploadComplete({ exerciseId, updateExercise, updateAssetDrafts, exercise, coachOrgId }) {
  const uploadingExState = store.getState().uploadingExercises[exerciseId]

  if (uploadingExState && exerciseId && coachOrgId) {
    const exName = uploadingExState.name.length >= 3 ? uploadingExState.name : exercise.name

    const exToSave = {
      ...exercise,
      name: exName,
      id: exerciseId,
      progress: null,
      storageKey: null,
    }

    await updateExercise({
      coachOrgId,
      exerciseKey: exerciseId,
      exercise: exToSave,
    })
    await updateAssetDrafts({ coachOrgId, id: exerciseId, drafts: { video: 'compressing', thumbnail: true } })
  }
}

const dragOver = (e) => {
  e.preventDefault()
}

const dragEnter = (e) => {
  e.preventDefault()
}

const dragLeave = (e) => {
  e.preventDefault()
}

const fileDrop = ({
  e,
  setUploadErrors,
  updateExercise,
  updateAssetDrafts,
  coachOrgId,
  dispatch,
  createAlert,
  exVidOrientation,
  enableLandscapeExAudio,
}) => {
  e.preventDefault()
  const files = e.dataTransfer.files
  if (files.length) {
    uploadFiles({
      files,
      setUploadErrors,
      updateExercise,
      updateAssetDrafts,
      coachOrgId,
      dispatch,
      createAlert,
      exVidOrientation,
      enableLandscapeExAudio,
    })
  }
}

async function uploadFiles({
  files,
  hiddenInputRef,
  setUploadErrors,
  updateExercise,
  updateAssetDrafts,
  coachOrgId,
  dispatch,
  createAlert,
  exVidOrientation,
  enableLandscapeExAudio,
}) {
  const vidsForUpload = files || hiddenInputRef.current?.files
  let errors = {}

  if (!vidsForUpload || vidsForUpload.length === -1) {
    return null
  }

  await Promise.all(
    map(vidsForUpload, async (video) => {
      const { errorCode, valid } = await validateVideoFile({ file: video, orientation: exVidOrientation })
      const videoId = getReadableExId(video?.name)
      const preppedVideoName = prepAsNameString(video?.name)

      if (valid) {
        const exercise = createExercise(video)
        const exerciseId = getReadableExId(exercise.name)
        dispatch(startExerciseUpload({ exerciseId, exercise: { ...exercise, id: exerciseId } }))

        const setProgressCb = createProgressCb({
          exerciseId,
          coachOrgId,
          dispatch,
        })

        const setErrorCb = (error) => {
          const errorMessage = error?.message || 'Unknown upload error'
          errors[videoId] = {
            videoName: preppedVideoName,
            id: videoId,
            errors: [errorMessage],
          }
          setUploadErrors((prevState) => ({ ...prevState, ...errors }))
          dispatch(uploadingExerciseCancelled({ exerciseId }))
        }

        const result = await AWSupload({
          file: video,
          uniqueId: exerciseId,
          uploadType: 'update-exercise-video',
          coachOrgId,
          setProgressCb,
          orientation: exVidOrientation,
          enableLandscapeExAudio,
          setErrorCb,
        })
        if (result) {
          onUploadComplete({
            exerciseId,
            updateExercise,
            updateAssetDrafts,
            exercise: { ...exercise, id: exerciseId },
            coachOrgId,
          })
        }
      } else if (errorCode) {
        errors[videoId] = {
          videoName: preppedVideoName,
          id: videoId,
          errors: [],
        }

        if (errorCode === 'TOO_LARGE') {
          errors[videoId].errors.push('file exceeds 250MB')
        } else if (errorCode === 'INVALID_TYPE') {
          errors[videoId].errors.push('invalid file type')
        } else if (errorCode === 'INVALID_NAME') {
          errors[videoId].errors.push('invalid file name')
        } else if (errorCode === 'LONG_NAME') {
          errors[videoId].errors.push(`file name too long (over ${EX_NAME_MAX_LENGTH} chars)`)
        } else if (errorCode === 'SHORT_NAME') {
          errors[videoId].errors.push(`file name too short (less than ${EX_NAME_MIN_LENGTH} chars)`)
        } else if (errorCode === 'INVALID_VID_DIM') {
          const aspectRatio = exVidOrientation === 'portrait' ? '9:16' : '16:9'
          errors[videoId].errors.push(
            `Please use dimensions for ${exVidOrientation} mode with ${aspectRatio} aspect ratio`
          )
        }
        setUploadErrors((prevState) => ({ ...prevState, ...errors }))
      }
    })
  )

  if (!isEmpty(errors)) {
    createAlert({ text: 'Some uploads failed!', type: 'danger' })
  }

  if (hiddenInputRef?.current?.value) {
    hiddenInputRef.current.value = '' //Clear input
  }
}

function createExercise(file) {
  const newExercise = {
    name: prepAsNameString(file.name),
    progress: 0,
  }

  return newExercise
}
