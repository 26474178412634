import { isEmpty } from 'lodash'

import { ItemsMissingVideoList } from '../ItemsMissingVideoList'
import { ItemsMissingVideoListWithMatchSelect } from '../ItemsMissingVideoListWithMatchSelect'
import { ProgramRowList } from '../ProgramRowList'

export const getQuestionnaire = ({
  program,
  programGroups,
  rowsWithProgram,
  missingVids,
  foundMatches,
  setCurrentStep,
  setSelectedExMatches,
  setSelectedWktVidMatches,
  setQuestModalIsOpen,
  isSecondAnswerDecline,
  previewRow,
  handleSaveTabContent,
  handleUpdateMatchedMissingIds,
  handlePublishProgram,
  navigate,
  refetch,
}) => {
  const firstStepDefaults = {
    title: '',
    description: '',
    confirmationText: 'Confirm',
    confirmAction: async () => {
      await handlePublishProgram()
    },
    declineText: 'Cancel',
    declineAction: () => setQuestModalIsOpen(false),
    renderList: () => <ItemsMissingVideoList missingVids={missingVids} />,
  }

  const getMissingVidSteps = () => {
    if (!isEmpty(foundMatches.foundExMatches) || !isEmpty(foundMatches.foundWktVidMatches)) {
      const firstStep = {
        ...firstStepDefaults,
        title: 'Please confirm matches found',
        description:
          'Some videos were missing when this program was written, but we found newer videos with matching names to help you show the proper video. However, there are multiple names that match. <strong>Please select the video you’d like to use.</strong>',
        confirmationText: 'Accept matches',
        declineText: 'Decline matches',
        confirmAction: async () => {
          await handleUpdateMatchedMissingIds()
          await refetch()
          return { skipStepMove: true }
        },
        declineAction: () => setCurrentStep((step) => step + 1),
        renderList: () => (
          <ItemsMissingVideoListWithMatchSelect
            missingVids={missingVids}
            foundMatches={foundMatches}
            setSelectedExMatches={setSelectedExMatches}
            setSelectedWktVidMatches={setSelectedWktVidMatches}
          />
        ),
      }

      const secondStep = {
        ...firstStepDefaults,
        title: 'These items are missing videos',
        confirmationText: 'Publish anyway',
      }

      return [firstStep, secondStep]
    } else {
      const firstStep = {
        ...firstStepDefaults,
        title: 'These items are missing videos',
        confirmationText: 'Publish anyway',
      }
      return [firstStep]
    }
  }

  const noMissingVidStep = {
    ...firstStepDefaults,
    title: 'Good job! There are no missing videos.',
    description: `Please confirm to publish changes to <strong>${program.name}</strong>.`,
    renderList: () => null,
  }

  const firstSteps = !missingVids ? [noMissingVidStep] : getMissingVidSteps()

  const notInRowsQuestFlow = [
    ...firstSteps,
    {
      title: 'Preview program?',
      description: 'Selecting yes will let you preview the program on the Trybe app before others can see it.',
      confirmationText: 'Yes',
      confirmAction: handleSaveTabContent,
      declineText: 'No',
      declineAction: () => null,
      renderList: () => null,
    },
    {
      title: isSecondAnswerDecline ? 'Your program is not yet in your Trybe app' : 'Program ready to preview',
      description: isSecondAnswerDecline
        ? 'In the App Layout page, add this program to a row to make it visible in your app.'
        : `To preview, open the mobile app and go to the: <br/><strong>${previewRow?.name}</strong> row in <strong>${previewRow?.tabName}</strong> tab. </br></br>When done reviewing, you may delete this row from the App Layout page. To release this program, go to the App Layout page and add this program to a row visible to everyone.`,
      confirmationText: isSecondAnswerDecline ? 'Go to App Layout' : 'Go to App Layout',
      confirmAction: () => navigate('/layout/private'),
      declineText: isSecondAnswerDecline ? 'Stay here' : 'Stay here',
      declineAction: () => null,
      renderList: () => null,
    },
  ]

  const inRowsQuestFlow = [
    ...firstSteps,
    {
      title: 'Program published',
      description: 'This program is located in the following rows:',
      confirmationText: 'Done',
      confirmAction: () => null,
      declineText: null,
      declineAction: () => null,
      renderList:
        rowsWithProgram.length < 1
          ? () => null
          : () => <ProgramRowList rowsWithProgram={rowsWithProgram} programGroups={programGroups} />,
    },
  ]

  if (rowsWithProgram.length) {
    return inRowsQuestFlow
  } else {
    return notInRowsQuestFlow
  }
}
