import React, { memo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { BiUser } from 'react-icons/bi'
import { IoMdBrush, IoIosBuild } from 'react-icons/io'
import tw from 'twin.macro'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useGetIsUserAdminQuery } from 'modules/Users/userApi'

export default memo(function AccountNavbar() {
  const { pathname } = useLocation()
  const { userId } = useAuth()
  const { data: isTrybeAdmin, isLoading } = useGetIsUserAdminQuery({ userId })
  const isTrybeAdminLoading = isTrybeAdmin === undefined || isLoading

  const menuItems = [
    { id: 0, title: 'Profile', link: '/account', img: <BiUser css={[NavIcon]} /> },
    { id: 1, title: 'Branding', link: '/branding', img: <IoMdBrush css={[NavIcon]} /> },
    { id: 2, title: 'Advanced', link: '/advanced', img: <IoIosBuild css={[NavIcon]} /> },
  ]

  if (!isTrybeAdminLoading && isTrybeAdmin)
    menuItems.push({ id: 3, title: 'Trybe Admin', link: '/trybeadmin', img: <BiUser css={[NavIcon]} /> })

  return (
    <ul className='w-full max-w-full md:max-w-[293px] mb-4 md:mb-0 mr-0 md:mr-7' aria-label='account-navbar'>
      {menuItems.map((item) => (
        <li key={item.id} css={[NavItem]}>
          <Link
            to={item.link}
            className='flex w-full py-5 px-5 hover:text-tGreen hover:transition-all transition-all'
            css={[pathname === item.link ? tw`text-tGreen` : tw`text-tBlack`]}
          >
            {item.img} {item.title}
          </Link>
        </li>
      ))}
    </ul>
  )
})

const NavItem = tw`flex items-center bg-white rounded-2xl mb-2 font-bold text-base`
const NavIcon = tw`text-2xl mr-3 hover:text-tGreen hover:transition-all`
