import { CgImage } from 'react-icons/cg'

import { Spinner } from 'common/components/Spinner/Spinner'
import { TableCell } from './styles'

export function PreviewImgTableCell({ isUploading, previewImg, altText }) {
  if (isUploading) {
    return (
      <CellContainer>
        <div className='w-full h-full flex items-center justify-center'>
          <Spinner className='w-5 h-5 text-gray-100 opacity-50' fillClassName='fill-tBlack opacity-50' />
        </div>
      </CellContainer>
    )
  }

  if (previewImg) {
    return (
      <CellContainer>
        <img src={previewImg} alt={altText} className='w-full h-full object-cover' />
      </CellContainer>
    )
  }

  return (
    <CellContainer>
      <CgImage className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-50 w-7 h-7' />
    </CellContainer>
  )
}

function CellContainer({ children }) {
  return (
    <TableCell tw='w-1/12'>
      <div className='relative w-20 h-20 rounded-xl overflow-hidden bg-tBlack'>{children}</div>
    </TableCell>
  )
}
