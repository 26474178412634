import tw from 'twin.macro'
import React, { useState } from 'react'
import { CgSearch } from 'react-icons/cg'

import { useListenProgressionsQuery } from './progressionsApi'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'

import { Input } from 'common/components/Input/Input'
import { InputGroup } from 'common/components/Input/InputGroup'
import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { ProgressionForm } from 'modules/Progressions/ProgressionForm/ProgressionForm'
import ProgressionTable from 'modules/Progressions/ProgressionTable/ProgressionTable'
import { filterProgressions } from 'common/utils/filterUtils'
import { useListenExercisesQuery } from 'modules/ExerciseLibrary/exerciseLibraryApi'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { useCustomization } from 'common/contexts/Customization/useCustomization'

function Progressions() {
  const { userId } = useAuth()
  const { exVidOrientation } = useCustomization()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: progressionsData } = useListenProgressionsQuery({ coachOrgId })
  const progressionsLoading = progressionsData === undefined || progressionsData?.isLoading
  const progressions = progressionsLoading || progressionsData === null ? {} : progressionsData

  const [searchQuery, setSearchQuery] = useState('')
  const progressionsToRender = filterProgressions(progressions, searchQuery)

  const { data: exData = {}, isLoading: isLoadingExercises } = useListenExercisesQuery({ coachOrgId, exVidOrientation })
  const { exercises = {} } = exData
  const exercisesLoading = exData?.isFiller || isLoadingExercises
  const noExerciseData = !exercisesLoading && exData.exercises === null
  const exerciseList = noExerciseData ? [] : exercises

  const simpleExerciseList = {}
  Object.entries(exerciseList).forEach(([exId, exercise], index) => {
    simpleExerciseList[exId] = { name: exercise?.name || '', date: exercise?.updatedAt || exercise?.createdAt }
  })

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  return (
    <FormRefsControlProvider>
      <div className='flex flex-col min-h-screen bg-offWhite px-4 md:px-16'>
        <div className='w-full max-w-7xl mx-auto mt-6 md:mt-12'>
          <div className='flex items-start justify-between flex-col md:flex-row'>
            <h1 className='font-bold text-3xl md:text-5xl text-tBlack mr-6'>Progressions</h1>
            <div className='flex-1 flex justify-center w-full my-4 md:my-0'>
              <InputGroup css={[tw`max-w-md ring-offWhite`, searchQuery.length && tw`ring-tGreen ring-2`]}>
                <CgSearch tw='w-5 h-5' />
                <Input
                  type='text'
                  placeholder='Search for progressions'
                  onChange={handleSearchChange}
                  value={searchQuery}
                  hideRing
                />
                {searchQuery.length && (
                  <Button variant='secondary' size='md' onClick={() => setSearchQuery('')}>
                    Clear
                  </Button>
                )}
              </InputGroup>
            </div>
            <div className='ml-0 md:ml-6 w-full md:w-auto'>
              <Dialog>
                <DialogTrigger css={[buttonBase, buttonVariants.primary, buttonSizes.lg, tw`w-full md:w-auto`]}>
                  Create progression
                </DialogTrigger>
                <DialogContent header='Create progression'>
                  <ProgressionForm
                    coachOrgId={coachOrgId}
                    progressions={progressions}
                    exerciseList={simpleExerciseList}
                  />
                </DialogContent>
              </Dialog>
            </div>
          </div>
          <div className='flex flex-col my-12 md:my-20'>
            <ProgressionTable
              progressions={progressionsToRender}
              loading={progressionsLoading || exercisesLoading}
              exerciseList={simpleExerciseList}
            />
          </div>
        </div>
      </div>
    </FormRefsControlProvider>
  )
}

export default Progressions
