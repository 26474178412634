export const questions = [
  {
    question: 'What would you like your display name to be?',
    note: 'Most creators use their actual full name.',
    questionKey: 'coachName',
    inputType: 'text',
  },
  {
    question: 'What is your app or business name?',
    questionKey: 'orgName',
    inputType: 'text',
  },
  {
    question:
      'For your exercise videos, do you want to use portrait or landscape mode? If you’re not sure, we recommend portrait.',
    note: 'Note: all exercise videos will have to use the same orientation for consistency.',
    questionKey: 'exVidOrientation',
    inputType: 'radio',
    options: ['portrait', 'landscape'],
  },
  {
    question: "What is your app or business's primary color?",
    questionKey: 'color',
    inputType: 'color',
  },
]
