import tw from 'twin.macro'
import React, { useState, useEffect, useRef } from 'react'
import { isEmpty, isEqual } from 'lodash'
import * as Accordion from '@radix-ui/react-accordion'
import * as Popover from '@radix-ui/react-popover'
import { CgChevronDown, CgImage } from 'react-icons/cg'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  useListenCollectionImgQuery,
  useListenCollectionIntroVideoQuery,
  useUpdateCollectionMutation,
} from '../collectionsApi'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useEventListener } from 'common/hooks/useEventListener'

import { Input } from 'common/components/Input/Input'
import { Textarea } from 'common/components/Textarea/Textarea'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { DeleteConfirmationBanner } from 'common/components/DeleteConfirmationBanner/DeleteConfirmationBanner'
import { AddItemButton } from 'common/components/AddItemButton/AddItemButton'
import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { NoItemsCard } from './NoItemsCard'
import { ItemsList } from './ItemsList'

import { createUID } from 'common/utils/createUID'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'
import { removeFileExtension } from 'common/utils/fileUploading/nameAndIdUtils'
import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'
import { getLinkItemErrors } from './utils'
import { schema } from './schema'
import { addItemClasses } from './styles'
import { getNewLink } from './constants'

const Label = tw.label`inline-flex cursor-pointer font-semibold text-tBlack`
const InputError = tw.p`flex text-xs mt-1 text-tRed`

export const initialInputRefsSortMethod = ['name', 'subtitle', 'description', 'submit']

export function CollectionsForm({ coachOrgId, collectionKey, collection = {}, deleteCollection }) {
  const [, setDialogOpen] = useDialog()
  const { createAlert } = useAlert()

  const isNewCollection = !collectionKey
  const [collectionId, setCollectionId] = useState('')

  const [updateCollection] = useUpdateCollectionMutation()

  const { data: previewImg } = useListenCollectionImgQuery({ coachOrgId, id: collectionId })
  const { data: introVideo } = useListenCollectionIntroVideoQuery({ coachOrgId, id: collectionId })
  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: collectionId })

  const [loading, setLoading] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [accordionValue, setAccordionValue] = useState(null)
  const [isCreated, setIsCreated] = useState(false) //set if collection is created by uploading image
  const [linkErrors, setLinkErrors] = useState({})

  useEffect(() => {
    if (collectionKey) {
      setCollectionId(collectionKey)
    } else {
      //need to set the state in order to maintain the newly created ID
      const newId = createUID()
      setCollectionId(newId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultValues = {
    name: collection.name || '',
    description: collection.description || '',
    subtitle: collection.subtitle || '',
    previewImg: collection.previewImg || '',
    introVideo: collection.introVideo || '',
    items: collection.items || {},
  }
  const methods = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(schema),
  })
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    register,
    formState: { errors },
    handleSubmit,
  } = methods

  const formState = watch()
  const isFormDisabled = isEqual(formState, defaultValues)

  const onSubmit = async (data) => {
    if (!isEmpty(linkErrors) || isFormDisabled) {
      return
    }

    const linkItemErrors = getLinkItemErrors(formState.items || {})
    if (!isEmpty(linkItemErrors)) {
      setLinkErrors(linkItemErrors)
      return
    } else {
      setLinkErrors({})
    }

    const alertText = isNewCollection && !isCreated ? 'Collection created!' : 'Collection updated!'

    setLoading(true)
    try {
      await updateCollection({
        coachOrgId,
        id: collectionId,
        collection: { ...data, updatedAt: Date.now(), id: collectionId },
      })
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    createAlert({ text: alertText, type: 'success' })
    setDialogOpen(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await deleteCollection()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    setDialogOpen(false)
  }

  const createCollectionOnUpload = (event) => {
    if (isNewCollection) {
      const file = event.target.files[0]
      if (file && file.name) {
        const collectionName = formState.name || removeFileExtension(file.name)
        updateCollection({
          coachOrgId,
          id: collectionId,
          collection: { ...formState, name: collectionName, id: collectionId, updatedAt: Date.now() },
        })

        const alertText = 'Creating Collection...'
        createAlert({ text: alertText, type: 'success' })
        setIsCreated(true)
      }
    }
  }

  // refs control
  const { addInputRef, moveFocusedInputBy, moveFocusOnKeyPress } = useFormRefsControl()
  const nameRef = useRef()
  const subtitleRef = useRef()
  const descriptionRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    addInputRef({ ref: nameRef, posIdx: 0, name: 'name', sortMethod: initialInputRefsSortMethod })
    addInputRef({
      ref: subtitleRef,
      posIdx: Object.values(formState.items || {}).length + 1,
      name: 'subtitle',
      sortMethod: initialInputRefsSortMethod,
    })
    addInputRef({
      ref: descriptionRef,
      posIdx: Object.values(formState.items || {}).length + 2,
      name: 'description',
      sortMethod: initialInputRefsSortMethod,
    })
    addInputRef({
      ref: submitRef,
      posIdx: Object.values(formState.items || {}).length + 4,
      name: 'submit',
      sortMethod: initialInputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const moveNameFocusOnKeyPress = (e) => {
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()
      if (e.shiftKey) {
        moveFocusedInputBy(-1)
      } else if (!e.shiftKey && accordionValue !== 'items') {
        setAccordionValue('items')
      } else if (!e.shiftKey) {
        moveFocusedInputBy(1)
      }
    }
  }

  const moveSubtitleFocusOnKeyPress = (e) => {
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()
      if (e.shiftKey && accordionValue !== 'items') {
        setAccordionValue('items')
      } else if (e.shiftKey) {
        moveFocusedInputBy(-1)
      } else if (!e.shiftKey) {
        moveFocusedInputBy(1)
      }
    }
  }

  useEventListener('keydown', (e) => moveFocusOnKeyPress(e, handleSubmit(onSubmit)))
  const hasOnlyUploadErr = Object.keys(errors || {}).every(
    (errKey) => errKey === 'introVideo' || errKey === 'previewImg'
  )
  const isUploadDisabled =
    !formState.name || (!isEmpty(errors) && !hasOnlyUploadErr) || !isEmpty(getLinkItemErrors(formState.items || {}))
  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id='collectionsForm'
          name='Series form'
          onKeyDown={(e) => {
            if (e.code === 'Enter' && e.target.type !== 'textarea') {
              e.preventDefault() //Otherwise form autosubmits on each enter press
            }
          }}
        >
          <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
            <TextInput
              name='name'
              label='Name'
              placeholder='Enter collection name'
              register={register}
              inputRef={nameRef}
              errors={errors}
              autoFocus={true}
              onKeyDown={moveNameFocusOnKeyPress}
            />
          </div>
          <ItemInputAccordion
            coachOrgId={coachOrgId}
            accordionValue={accordionValue}
            setAccordionValue={setAccordionValue}
            collectionId={collectionId}
            items={formState.items}
            setValue={setValue}
            linkErrors={linkErrors}
            setLinkErrors={setLinkErrors}
            setIsCreated={setIsCreated}
          />
          <div className='flex flex-col px-10 py-4 border-t border-gray-200'>
            <TextInput
              name='subtitle'
              label='Subtitle'
              placeholder='Enter subtitle'
              type='text'
              register={register}
              inputRef={subtitleRef}
              onKeyDown={moveSubtitleFocusOnKeyPress}
              errors={errors}
            />
            <TextInput
              name='description'
              label='Description'
              placeholder='Enter description'
              type='textarea'
              register={register}
              inputRef={descriptionRef}
              errors={errors}
            />
          </div>
          <div className='flex flex-col px-10 py-4 border-t border-gray-200'>
            <UploadInput
              name='previewImg'
              label='Image'
              register={register}
              setValue={setValue}
              coachOrgId={coachOrgId}
              id={collectionId}
              uploadType='collection-image'
              liveUrl={previewImg || collection?.previewImg}
              setError={setError}
              onUpload={isNewCollection ? createCollectionOnUpload : null}
              uploadDisabled={isUploadDisabled}
              uploadDisabledReason='Please fix form errors before uploading'
              previewIcon={<CgImage className='!w-5 !h-5' />}
              clearErrors={clearErrors}
              fileType='image'
            />
            {errors?.previewImg ? <InputError>{errors.previewImg.message}</InputError> : null}
            <UploadInput
              name='introVideo'
              label='Intro video'
              register={register}
              setValue={setValue}
              coachOrgId={coachOrgId}
              id={collectionId}
              uploadType='collection-intro-video'
              liveUrl={introVideo || collection?.introVideo}
              setError={setError}
              onUpload={isNewCollection ? createCollectionOnUpload : null}
              uploadDisabled={isUploadDisabled}
              uploadDisabledReason='Please fix form errors before uploading'
              clearErrors={clearErrors}
              customFileSizeLimit={2500000000} //2.5gb for large video files
              fileType='video'
            />
            {errors.introVideo && <p className='flex text-xs mt-1 text-tRed'>{errors.introVideo.message}</p>}
          </div>
        </form>
      </div>
      {!isNewCollection && deleteConfirmation ? (
        <DeleteConfirmationBanner
          text='Are you sure?'
          handleDelete={handleDelete}
          handleGoBack={() => setDeleteConfirmation(false)}
          loading={loading}
        />
      ) : (
        <UpdateActions
          itemKey={collectionId}
          loading={loading}
          disabled={isFormDisabled}
          handleDelete={() => setDeleteConfirmation(true)}
          hideDelete={!collectionKey}
          deleteDisabled={isUploadingAssets(assetDrafts)}
          disabledReason='Please wait for media upload to complete'
          actionText='Save'
          form='collectionsForm'
          ref={submitRef}
        />
      )}
    </FormProvider>
  )
}

const TextInput = ({
  name,
  label,
  placeholder,
  type,
  register,
  inputRef,
  errors,
  onKeyDown,
  onFocus,
  autoFocus = false,
}) => (
  <div className='mb-2'>
    <Label htmlFor={name} tw='mb-1'>
      {label}
    </Label>
    {type === 'textarea' ? (
      <Textarea
        name={name}
        placeholder={placeholder}
        register={register}
        inputRef={inputRef}
        error={errors?.[name]?.message}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
    ) : (
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        register={register}
        inputRef={inputRef}
        error={errors?.[name]?.message}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
    )}

    {errors && errors[name] && <InputError>{errors[name].message}</InputError>}
  </div>
)

const ItemInputAccordion = ({
  coachOrgId,
  accordionValue,
  setAccordionValue,
  collectionId,
  items,
  setValue,
  linkErrors,
  setLinkErrors,
  setIsCreated,
}) => {
  const handleAdd = (item) => {
    setValue('items', { ...items, [item.id]: { ...item, index: Object.values(items || {}).length } })
  }

  return (
    <Accordion.Root type='single' value={accordionValue} onValueChange={setAccordionValue} collapsible='true'>
      <Accordion.Item value='items'>
        <Accordion.Header>
          <Accordion.Trigger type='button' className='flex items-center w-full group px-10 py-4' tabIndex={-1}>
            <label
              htmlFor='items'
              className='relative inline-flex items-center cursor-pointer font-semibold text-tBlack my-2 text-left'
            >
              Items
              {!isEmpty(linkErrors) && (
                <ErrorNotice className='inline-flex rounded-full items-center justify-center font-medium leading-none text-sm text-white w-5 h-5 bg-red-500 ml-2'>
                  !
                </ErrorNotice>
              )}
            </label>
            <CgChevronDown className='w-6 h-6 ml-auto group-radix-state-open:-rotate-180 transition-all' />
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <div className='flex flex-col px-10 pb-4'>
            {Object.values(items || {}).length === 0 ? (
              <NoItemsCard coachOrgId={coachOrgId} existingItems={Object.values(items || {})} handleAdd={handleAdd} />
            ) : (
              <div>
                <ItemsList
                  coachOrgId={coachOrgId}
                  collectionId={collectionId}
                  items={Object.values(items || {})}
                  setValue={setValue}
                  linkErrors={linkErrors}
                  setLinkErrors={setLinkErrors}
                  setIsCreated={setIsCreated}
                />
                <div className='flex justify-center mt-4 relative'>
                  <AddItemButton
                    coachOrgId={coachOrgId}
                    existingItems={Object.values(items || {})}
                    handleAdd={handleAdd}
                    btnCSS={addItemClasses}
                    iconClasses='w-4 h-4 mr-2'
                    hideItems={[availableItemTypes.COLLECTION]}
                    isPortalled={false}
                  >
                    <Popover.Close
                      css={[buttonBase, buttonVariants.secondary, buttonSizes.md, tw`flex-1 flex px-4`]}
                      onClick={() => handleAdd(getNewLink(createUID()))}
                    >
                      <span className='first-letter:capitalize text-left flex-1'>Link</span>
                    </Popover.Close>
                  </AddItemButton>
                </div>
              </div>
            )}
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}

const ErrorNotice = tw.span`
  inline-flex rounded-full 
  items-center justify-center 
  font-medium leading-none 
  text-sm text-white 
  w-5 h-5 bg-red-500 ml-2
`
