import React, { useState } from 'react'
import tw from 'twin.macro'
import { CgAdd, CgSearch } from 'react-icons/cg'
import * as Popover from '@radix-ui/react-popover'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useListenSavedBlocksQuery, useSavePartsMutation } from 'modules/Programs/programApi'

import { buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { ScrollArea } from 'common/components/ScrollArea/ScrollArea'
import { Spinner } from 'common/components/Spinner/Spinner'
import SavedBlocksListHeader from './SavedBlocksListHeader'
import SavedBlocksListItem from './SavedBlocksListItem'
import { createUID } from 'common/utils/createUID'

export default function SavedBlocksList({ orgId, workoutId, parts }) {
  const { createAlert } = useAlert()
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedBlock, setSelectedBlock] = useState()

  const [addSavedBlock] = useSavePartsMutation()

  const { data: savedBlocks } = useListenSavedBlocksQuery({ orgId })
  const isBlocksLoading = savedBlocks === undefined || savedBlocks?.isFiller
  const blocksEntries = isBlocksLoading || savedBlocks === null ? [] : Object.entries(savedBlocks)

  const filteredBlocks = blocksEntries.filter(([blockKey, block]) =>
    (block?.name || 'Block').toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleAddSavedBlock = async () => {
    const updParts = [...parts, { ...selectedBlock, savedAt: null, id: createUID() }]
    await addSavedBlock({ orgId, workoutId, parts: updParts })
    createAlert({ text: 'Block is added!', type: 'success' })
  }

  return (
    <div className='flex flex-col w-60'>
      <SavedBlocksListHeader orgId={orgId} selectedBlockId={selectedBlock?.id} />
      {isBlocksLoading ? (
        <Spinner className='w-4 h-4 text-gray-100 mx-auto' />
      ) : blocksEntries.length ? (
        <>
          <div className='flex items-center text-tBlack mb-2'>
            <CgSearch className='w-4 h-4' />
            <input
              type='text'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder='Search...'
              className='border-0 text-sm ml-2 bg-transparent placeholder:text-tBlack placeholder:text-opacity-50 focus:ring-0 p-0'
              autoComplete='off'
            />
          </div>
          <ScrollArea id='addcard-scrollarea'>
            <div className='flex flex-col'>
              {filteredBlocks.length ? (
                filteredBlocks.map(([blockKey, block]) => (
                  <SavedBlocksListItem
                    key={blockKey}
                    block={block}
                    selectedBlock={selectedBlock}
                    setSelectedBlock={setSelectedBlock}
                  />
                ))
              ) : (
                <p className='text-center text-sm'>
                  There is no <strong>{searchQuery}</strong> block
                </p>
              )}
            </div>
          </ScrollArea>
          <Popover.Close
            css={[buttonBase, buttonVariants.primary, buttonSizes.md, tw`mt-3`]}
            disabled={isBlocksLoading}
            onClick={handleAddSavedBlock}
          >
            Add block <CgAdd className='w-5 h-5 ml-2' />
          </Popover.Close>
        </>
      ) : (
        <p className='text-center text-sm'>There are no saved blocks</p>
      )}
    </div>
  )
}
