import 'twin.macro'
import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CgMoreAlt, CgPen, CgTrash } from 'react-icons/cg'
import { format } from 'date-fns'

import { useWindowSize } from 'common/hooks/useWindowSize'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useRemoveVideoMutation } from '../videoLibraryApi'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'
import { setIdsOfResultsToDelete } from 'modules/AppOnboardingQuiz/quizFlowSlice'
import useQuizResultExists from 'modules/AppOnboardingQuiz/hooks/useQuizResultExists'

import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { CreateDialogBanner } from 'common/components/CreateDialogBanner/CreateDialogBanner'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'
import { getTimeWithoutFractional } from 'common/utils/timeDuration'
import { NEW_USER_QUIZ } from 'modules/App/components/Header'

import { PreviewImgTableCell } from './PreviewImgTableCell'
import { TableCell } from './styles'

export function TableRow({ coachOrgId, videoKey, video, setModalVideo, setEditDialogOpen }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [width] = useWindowSize()
  const [removeVideo] = useRemoveVideoMutation()
  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: videoKey })
  const { quizResultExists, isQuizResultsLoading } = useQuizResultExists({ coachOrgId, itemId: videoKey })

  const rowActionContainerRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const { createAlert } = useAlert()

  const handleDelete = async () => {
    setLoading(true)
    await removeVideo({ coachOrgId, videoKey, video })
    setLoading(false)
    createAlert({ text: 'Video deleted!', type: 'success' })
  }

  const handleRowClick = (e) => {
    // Check that event did not happen in modal
    const occuredOutsideModal = e.currentTarget.contains(e.target)

    // Check that event did not happen in action row (edit/delete/more)
    const occuredOutsideActionRow = !rowActionContainerRef.current.contains(e.target)
    if (occuredOutsideModal && occuredOutsideActionRow) {
      handleOpenEditDialog()
    }
  }

  const handleOpenEditDialog = () => {
    setModalVideo({
      video,
      videoKey,
      handleDelete,
    })
    setEditDialogOpen(true)
  }

  const isUploading = isUploadingAssets(assetDrafts)

  return (
    <tr className='cursor-pointer group h-24 hover:bg-gray-50' onClick={handleRowClick} aria-label='table-row'>
      <PreviewImgTableCell isUploading={isUploading} video={video} />
      <TableCell tw='w-7/12 pl-0 md:pl-6'>
        <div className='flex flex-col' style={{ width: width && width <= 768 ? parseInt(width / 1.55) : 384 }}>
          <div className='font-bold text-tBlack capitalize truncate' data-testid='table-video-title'>
            {video.title}
          </div>
          <div className='text-sm text-tGray-dark truncate'>
            {video.description ? video.description : 'No description'}
          </div>
          <div className='flex items-center text-xs text-gray-500'>
            <span>{video.subtitle ? video.subtitle : 'No subtitle'}</span>
            <span className='text-lg mx-1'>&#8226;</span>
            <span>{getTimeWithoutFractional(video.duration || '')}</span>
          </div>
        </div>
      </TableCell>
      <TableCell tw='w-2/12' hideOnMobile>
        <div className='text-xs text-gray-500'>{video.createdAt ? format(video.createdAt, 'PP') : 'No date'}</div>
      </TableCell>
      <TableCell tw='w-2/12' hideOnMobile>
        <div className='text-xs text-gray-500'>{video.updatedAt ? format(video.updatedAt, 'PP') : 'No date'}</div>
      </TableCell>
      <td className='relative'>
        <div
          ref={rowActionContainerRef}
          className='absolute top-1/2 -translate-y-1/2 right-3 inline-flex items-center justify-end bg-white group-hover:border group-hover:border-tBlack group-hover:border-opacity-20 rounded-lg group-hover:shadow-sm'
        >
          <button
            aria-label='Edit Row'
            className='hidden group-hover:inline-block hover:text-tGreen py-1 px-2 border-r border-tBlack border-opacity-20 transition-all'
            onClick={handleOpenEditDialog}
          >
            <CgPen className='w-5 h-5' />
          </button>
          <Dialog>
            <DialogTrigger
              disabled={isUploading || isQuizResultsLoading}
              className='hidden group-hover:inline-block hover:text-tRed py-1 px-2 border-r border-tBlack border-opacity-20 transition-all disabled:cursor-not-allowed'
              aria-label='Delete Row'
            >
              <CgTrash className='w-5 h-5' />
            </DialogTrigger>
            <DialogContent>
              {quizResultExists ? (
                <CreateDialogBanner
                  titleText={null}
                  createBtnText='Go to quiz'
                  text='This video cannot be deleted because it is used as a result in the new user quiz. To delete the video, please remove it from the quiz first.'
                  handleCreate={() => {
                    dispatch(setIdsOfResultsToDelete({ itemIds: { [videoKey]: true } }))
                    navigate(NEW_USER_QUIZ)
                  }}
                />
              ) : (
                <DeleteDialogBanner
                  text={`This will delete ${video.title}`}
                  loading={loading}
                  handleDelete={handleDelete}
                />
              )}
            </DialogContent>
          </Dialog>
          <button className='inline-block py-1 px-2' aria-label='More'>
            <CgMoreAlt className='w-5 h-5' />
          </button>
        </div>
      </td>
    </tr>
  )
}
