import tw from 'twin.macro'
import React, { useEffect, useRef, useState } from 'react'
import { CgPen, CgTrash, CgCornerUpRight } from 'react-icons/cg'
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'
import { FiMove } from 'react-icons/fi'
import { sortBy } from 'lodash'
import * as Popover from '@radix-ui/react-popover'

import { useListenTabsQuery, useSetTabContentMutation } from 'modules/Layout/layoutApi'

import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { Button } from 'common/components/Button/Button'
import { Input } from 'common/components/Input/Input'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { RowHeaderContainer } from '../styles'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import Tag from 'common/components/Tag/Tag'

import { moveRowToDiffTab } from 'modules/Layout/utils/layoutUtils'

export function LayoutRowHeader({ currentTabContent, currentRow, coachOrgId, dragHandleProps, tabId }) {
  const { attributes, listeners } = dragHandleProps
  const { createAlert } = useAlert()
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [nameInputValue, setNameInputValue] = useState(currentRow.name)
  const [inputError, setInputError] = useState(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const tagVisibilityTimeoutId = useRef(null)
  const [showVisibilityTag, setShowVisibilityTag] = useState(false)

  useEffect(() => {
    return () => clearTimeout(tagVisibilityTimeoutId?.current)
  }, [])

  const { data: tabs, isLoading: isLoadingTabs } = useListenTabsQuery({ coachOrgId })
  const tabsLoading = tabs === undefined || tabs?.isLoading || isLoadingTabs
  const tabEntries = tabsLoading || tabs === null ? [] : Object.entries(tabs)
  const sortedTabEntries = sortBy(tabEntries, ([_, tab]) => tab.tabIdx)

  const [saveTabContent] = useSetTabContentMutation()

  const handleInputChange = (e) => {
    setNameInputValue(e.target.value)

    if (e.target.value.trim().length < 1) {
      setInputError('name should be at least 1 characters long')
      return
    }

    setInputError(null)
  }

  const cleanup = () => {
    setNameInputValue(nameInputValue)
    setEditing(false)
    setInputError(null)
  }

  const handleEditSave = async () => {
    const updatedCurrentTabContent = currentTabContent.map((row) => {
      if (row.id === currentRow.id) {
        return { ...currentRow, name: nameInputValue.trim() }
      }
      return row
    })

    setLoading(true)

    await saveTabContent({ coachOrgId, tabContent: updatedCurrentTabContent, tabId })
    createAlert({ text: 'Row name changed!', type: 'success' })

    setLoading(false)

    cleanup()
  }

  const removeRow = async () => {
    const updatedCurrentTabContent = currentTabContent.filter((row) => row.id !== currentRow.id)

    setLoading(true)

    await saveTabContent({ coachOrgId, tabContent: updatedCurrentTabContent, tabId })

    setLoading(false)

    createAlert({ text: 'Row removed!', type: 'success' })
  }

  const handleVisibilityToggle = async () => {
    if (currentRow.visibilityLevel === 'anyone') {
      const updatedCurrentTabContent = currentTabContent.map((row) => {
        if (row.id === currentRow.id) {
          return { ...currentRow, visibilityLevel: 'coaches' }
        }
        return row
      })

      setLoading(true)

      await saveTabContent({ coachOrgId, tabContent: updatedCurrentTabContent, tabId })

      setLoading(false)
      createAlert({ text: 'Row published for coaches only!', type: 'success' })
    } else {
      const updatedCurrentTabContent = currentTabContent.map((row) => {
        if (row.id === currentRow.id) {
          return { ...currentRow, visibilityLevel: 'anyone' }
        }
        return row
      })

      setLoading(true)

      await saveTabContent({ coachOrgId, tabContent: updatedCurrentTabContent, tabId })

      setLoading(false)
      createAlert({ text: 'Row published for everyone!', type: 'success' })
    }

    clearTimeout(tagVisibilityTimeoutId?.current)
    setShowVisibilityTag(true)
    const timeoutId = setTimeout(() => {
      setShowVisibilityTag(false)
    }, 2000)
    tagVisibilityTimeoutId.current = timeoutId
  }

  const handleSaveOnEnter = (e) => {
    if (e.code === 'Enter') {
      handleEditSave()
    }
  }

  if (editing) {
    return (
      <RowHeaderContainer>
        <div className='relative'>
          <Input
            value={nameInputValue}
            onChange={handleInputChange}
            autoFocus={editing}
            error={inputError}
            css={tw`w-80 h-8 py-0 rounded-lg`}
            onKeyDown={handleSaveOnEnter}
            aria-label='Row name'
            maxLength={30}
          />
          {inputError && <p className='absolute right-4 bottom-0 text-xxs text-tRed'>{inputError}</p>}
        </div>
        <div className='flex items-center ml-auto'>
          <Button onClick={() => cleanup()} variant='secondary' size='md'>
            Cancel
          </Button>
          <Button
            disabled={inputError}
            loading={loading}
            onClick={() => handleEditSave()}
            variant='primary'
            size='md'
            css={tw`ml-2`}
          >
            Save
          </Button>
        </div>
      </RowHeaderContainer>
    )
  }

  return (
    <RowHeaderContainer>
      <h3 className='flex-1 font-bold text-gray-500'>{currentRow.name}</h3>
      <div
        className='ml-auto flex items-center'
        css={[showVisibilityTag && tw`invisible`]}
        data-testid='layout-row-actions'
      >
        <Tooltip content='Edit row name' triggerClasses='flex items-center mr-2'>
          <button onClick={() => setEditing(true)} aria-label='Layout row edit'>
            <CgPen className='cursor-pointer text-gray-500 hover:text-tGreen w-5 h-5' />
          </button>
        </Tooltip>
        <Dialog>
          <Tooltip content='Delete row' triggerClasses='flex items-center mr-[6px]'>
            <DialogTrigger aria-label='Layout row delete'>
              <CgTrash className='cursor-pointer text-gray-500 hover:text-tRed w-5 h-5' />
            </DialogTrigger>
          </Tooltip>
          <DialogContent>
            <DeleteDialogBanner
              text={`This will remove the ${currentRow.name} row`}
              handleDelete={() => removeRow()}
              loading={loading}
            />
          </DialogContent>
        </Dialog>
        <Tooltip content='Move row' triggerClasses='flex items-center mr-2'>
          <button {...attributes} {...listeners}>
            <FiMove className='cursor-move text-gray-500 hover:text-tGreen w-[18px] h-[18px]' />
          </button>
        </Tooltip>
        {sortedTabEntries.length > 1 && (
          <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
            <Popover.Trigger
              onClick={(e) => {
                e.stopPropagation()
                setIsPopoverOpen(true)
              }}
            >
              <Tooltip content='Move to'>
                <CgCornerUpRight className='cursor-pointer text-gray-500 hover:text-tGreen w-5 h-5 mr-2' />
              </Tooltip>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                className='bg-white px-2 py-2 rounded-md shadow-xl border-2 border-gray-300 w-44 z-[999]'
                align='end'
                alignOffset={0}
                sideOffset={5}
                arrowPadding={12}
              >
                <Popover.Arrow className='fill-gray-300' />
                {sortedTabEntries.map(([moveToTabId, tab]) =>
                  moveToTabId !== tabId ? (
                    <Popover.Close key={moveToTabId} asChild={true}>
                      <PopoverBtn
                        onClick={() =>
                          moveRowToDiffTab({
                            coachOrgId,
                            moveToTabId,
                            moveFromTabId: tabId,
                            tab,
                            row: currentRow,
                            createAlert,
                          })
                        }
                      >
                        {tab.name}
                      </PopoverBtn>
                    </Popover.Close>
                  ) : null
                )}
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
      </div>
      {!isPopoverOpen && showVisibilityTag && (
        <Tag
          text={currentRow.visibilityLevel === 'anyone' ? 'Row visible to everyone' : 'Row visible to coaches'}
          className='!mb-0 !bg-gray-200'
          data-testid='visibilityLevel-tag'
        />
      )}
      <button onClick={() => handleVisibilityToggle()} data-testid='visibility-toggle-btn'>
        {currentRow.visibilityLevel === 'anyone' ? (
          <Tooltip content='Show to coaches only'>
            <HiOutlineEye
              className='cursor-pointer text-gray-500 hover:text-tGreen w-5 h-5'
              data-testid='show-everyone-icon'
            />
          </Tooltip>
        ) : (
          <Tooltip content='Show to everyone'>
            <HiOutlineEyeOff
              className='cursor-pointer text-gray-500 hover:text-tGreen w-5 h-5'
              aria-label='Show to everyone icon'
              data-testid='show-coaches-only-icon'
            />
          </Tooltip>
        )}
      </button>
    </RowHeaderContainer>
  )
}

const PopoverBtn = tw.button`
  flex items-center bg-white hover:bg-gray-300 hover:bg-opacity-30
  text-sm text-tBlack transition-all rounded-md justify-start
  font-medium px-2 py-2 w-full`
