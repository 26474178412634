import * as React from 'react'

function Error(props) {
  return (
    <div className='flex w-screen h-screen bg-gray-200 justify-center'>
      <div className='w-[480px] h-[200px] bg-white rounded-lg mt-[36px] items-center justify-center p-[10px]'>
        <div className='text-center'>Error... {props?.error?.description}</div>
      </div>
    </div>
  )
}

export default Error
