import { each } from 'lodash'
import { getReadableExId } from 'modules/Programs/utils/exerciseDomain'
import { createUID } from 'common/utils/createUID'
import newOrgSampleExercises from '../templates/newOrgSampleExercises.json'

export const getSampleExercises = () => {
  const sampleExercises = {}
  const newSampleExIdByOrigExId = {}

  each(newOrgSampleExercises, (ex, origExId) => {
    const newExId = getReadableExId(ex.name)

    const updatedEx = {
      ...ex,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      dndUID: createUID(),
      id: newExId,
    }

    sampleExercises[newExId] = updatedEx
    newSampleExIdByOrigExId[origExId] = newExId
  })

  return { sampleExercises, newSampleExIdByOrigExId }
}
