import tw, { styled, css } from 'twin.macro'

export const CardContainer = styled.div(() => [
  tw`flex flex-col relative p-2 rounded-md border shadow-sm`,
  css`
    &:hover #editexercise-actions-container {
      border-color: rgb(229 231 235);
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
      background-color: rgb(255, 255, 255);
    }
  `,
])

export const actionContainerClasses = tw`
  invisible absolute 
  z-20 right-0 
  top-[1px] flex 
  items-center py-1 
  px-1.5 rounded-md 
  border-[1px] bg-white 
  border-gray-200 shadow-sm
`

export const inputClasses = tw`
  font-medium text-sm 
  focus:ring-0 p-1 w-full 
  border-none overflow-hidden 
  whitespace-nowrap placeholder:text-gray-400  
  hover:bg-gray-100 hover:bg-opacity-70 
  focus:bg-gray-100 focus:bg-opacity-70 
  rounded
`
