import { sortBy, each } from 'lodash'

import { getNewEmptyWeek } from '../programModels'
import { createPastedWorkouts, updateExistingWktsOrder } from './pasteWorkoutUtils'
import { updateManyWorkoutContentTags } from './contentTagUtils'
import { createPartWithNewIds } from '../handlers/commonPartHandlers'

export const handlePasteWeek = async ({
  copiedWeekWktIds,
  pastedAtWeekWktIds,
  reorderProgram,
  updateWorkouts,
  getWorkoutsByIds,
  programId,
  orgId,
  createAlert,
  wktIdsSorted,
}) => {
  const { data: copiedWeekWkts } = await getWorkoutsByIds({ orgId, wktIds: copiedWeekWktIds })
  const { data: pastedAtWeekWkts } = await getWorkoutsByIds({ orgId, wktIds: pastedAtWeekWktIds })

  const sortedCopiedWeekWkts = sortBy(Object.values(copiedWeekWkts), 'dayIdx')
  const sortedPastedAtWeekWkts = sortBy(Object.values(pastedAtWeekWkts), 'dayIdx')

  const isPastedAtWeekEmpty = sortedPastedAtWeekWkts.every((wkt) => wkt.type === 'empty')

  if (isPastedAtWeekEmpty) {
    const updatedEmptyWeekWkts = {}

    each(sortedPastedAtWeekWkts, (wkt, idx) => {
      const copiedWkt = sortedCopiedWeekWkts[idx]
      const partsWithNewIds = copiedWkt.parts.map((part) => createPartWithNewIds(part))
      updatedEmptyWeekWkts[wkt.id] = { ...copiedWkt, id: wkt.id, dayIdx: wkt.dayIdx, parts: partsWithNewIds }
    })

    await updateWorkouts({ orgId, workouts: updatedEmptyWeekWkts })
    await updateManyWorkoutContentTags({ orgId, workouts: updatedEmptyWeekWkts, programId, updateType: 'add' })
  } else {
    const pastedAtDayIdx = sortedPastedAtWeekWkts[0].dayIdx

    const {
      progWkts: progWktsPasted,
      wkts: newPastedWkts,
      wktsOverwritten,
    } = createPastedWorkouts({
      copiedWorkouts: sortedCopiedWeekWkts,
      shouldOverwriteByCopyIdx: false,
      wktIdsSorted,
      pastedAtDayIdx,
    })

    const { reorderedProgWkts: progWktsForExistingWkts, firebaseWktsUpdate: existingWktsUpdates } =
      updateExistingWktsOrder({
        wktIdsSorted,
        pastedAtDayIdx,
        numNewWkts: 7,
        wktsOverwritten,
        emptyWktsToRemove: {},
      })

    const reorderedProgWkts = {
      ...progWktsPasted,
      ...progWktsForExistingWkts,
    }

    const workoutsUpdate = {
      ...newPastedWkts,
      ...existingWktsUpdates,
    }

    await reorderProgram({
      orgId,
      programId,
      reorderedProgramWkts: reorderedProgWkts,
      fbUpdateWkts: workoutsUpdate,
    })
    await updateManyWorkoutContentTags({ orgId, workouts: newPastedWkts, programId, updateType: 'add' })
  }

  createAlert({ text: 'Week pasted!', type: 'success' })
}

export const handleInsertNewWeek = async ({ weekIdx, reorderProgram, programId, orgId, wktIdsSorted, createAlert }) => {
  const { newWorkouts: newWeekWorkouts, idToDayIdx: newProgWeekWkts } = getNewEmptyWeek(weekIdx)

  const progWktsUpdate = {}
  const workoutsUpdate = {}

  each(wktIdsSorted, (wktId, dayIdx) => {
    const currWeekAndAfterRange = dayIdx + 1 >= (weekIdx + 1) * 7 - 6
    if (currWeekAndAfterRange) {
      const updatedDayIdx = dayIdx + 7

      progWktsUpdate[wktId] = updatedDayIdx

      const fbKey = `${wktId}/dayIdx`
      workoutsUpdate[fbKey] = updatedDayIdx
    } else {
      progWktsUpdate[wktId] = dayIdx
    }
  })

  const reorderedProgramWkts = {
    ...newProgWeekWkts,
    ...progWktsUpdate,
  }

  const fbUpdateWkts = {
    ...newWeekWorkouts,
    ...workoutsUpdate,
  }

  await reorderProgram({
    orgId,
    programId,
    reorderedProgramWkts,
    fbUpdateWkts,
  })

  createAlert({ text: 'Week inserted!', type: 'success' })
}
