import 'twin.macro'
import React from 'react'
import { CgImage } from 'react-icons/cg'

function ResultCard({ name, description, type, previewImg, selected }) {
  if (selected) {
    return (
      <div className='flex items-center w-full bg-white h-12'>
        <div className='relative h-10 w-12 rounded overflow-hidden bg-tBlack'>
          {previewImg ? (
            <img src={previewImg} alt={name} className='w-full h-full object-cover' />
          ) : (
            <CgImage className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-50 w-5 h-5' />
          )}
        </div>
        <div className='ml-3 w-[84%]'>
          <div className='flex items-center justify-between'>
            <h3 className='font-bold text-sm capitalize truncate'>{name}</h3>
            <div className='p-1 rounded bg-gray-100 text-xs text-gray-400 font-semibold ml-3 leading-none'>
              {type.toLowerCase()}
            </div>
          </div>
          <p className='text-sm text-gray-500 truncate mt-[1px]'>{description}</p>
        </div>
      </div>
    )
  }

  return (
    <div className='flex items-center justify-between w-full bg-white h-8 px-1'>
      <h3 className='font-medium text-sm capitalize truncate'>{name}</h3>
      <div className='p-1 rounded bg-gray-100 text-xs text-gray-400 font-semibold leading-none'>
        {type.toLowerCase()}
      </div>
    </div>
  )
}

export default ResultCard
