import React, { useState, useEffect, memo } from 'react'
import { add } from 'date-fns'

import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  useListenIsOrgAdminQuery,
  useListenUserPaidAccessQuery,
  useSetAdminAccessMutation,
  useSetPaidAccessMutation,
} from './trybeAdminApi'

import { Spinner } from 'common/components/Spinner/Spinner'
import { Switch } from 'common/components/Switch/Switch'
import CopyToClipboardButton from './CopyToClipboardButton'
import CoachOrgInputPicker from './CoachOrgInputPicker'

function TrybeAdminForm({ userId }) {
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const [isPaidAccess, setIsPaidAccess] = useState(false)
  const { data: userPaidAccess } = useListenUserPaidAccessQuery({ userId, coachOrgId })
  const [setPaidAccess] = useSetPaidAccessMutation()

  const { data: isOrgAdmin } = useListenIsOrgAdminQuery({ userId, coachOrgId })
  const hasAdminAccess = isOrgAdmin !== undefined && !isOrgAdmin?.isLoading && Boolean(isOrgAdmin)
  const [setAdminAccess] = useSetAdminAccessMutation()

  useEffect(() => {
    if (userPaidAccess && new Date(userPaidAccess.accessEnd) > new Date().getTime()) {
      setIsPaidAccess(true)
    } else {
      setIsPaidAccess(false)
    }
  }, [userPaidAccess])

  const onPaidAccessChanged = ({ target: { checked } }) => {
    if (checked) {
      setPaidAccess({ coachOrgId, userId, date: getPaidAccessTimestamp({ isPaid: true }) })
    } else {
      setPaidAccess({ coachOrgId, userId, date: getPaidAccessTimestamp({ isPaid: false }) })
    }
  }

  const onAdminAccessChanged = ({ target: { checked } }) => {
    if (checked) {
      setAdminAccess({ coachOrgId, userId, email: profile?.email || true })
    } else {
      setAdminAccess({ coachOrgId, userId, email: false })
    }
  }

  if (!coachOrgId) {
    return <Spinner className='w-10 h-10 text-gray-100 m-auto' />
  }

  return (
    <div className='w-full' data-testid='content' aria-label='trybe-admin'>
      <div className='flex flex-row items-center'>
        <div className='font-bold text-md text-tBlack'>User:</div>
        <div className='font-regular text-md text-tBlack ml-[5px]' aria-label='userId'>
          {userId}
        </div>
        <CopyToClipboardButton data={userId} />
      </div>
      <div className='flex flex-row items-center mt-10'>
        <div className='font-bold text-md text-tBlack'>Active Coach Org</div>
        <CopyToClipboardButton data={coachOrgId} />
      </div>
      <CoachOrgInputPicker userId={userId} coachOrgId={coachOrgId} />
      <div className='mt-4'>
        <Switch onChange={onPaidAccessChanged} label='Paid Access' isChecked={isPaidAccess} />
      </div>
      <div className='mt-4'>
        <Switch onChange={onAdminAccessChanged} label='Admin Access' isChecked={hasAdminAccess} />
      </div>
    </div>
  )
}

function getPaidAccessTimestamp({ isPaid }) {
  const currentDate = new Date(Date.now())
  const paidAccessExpiration = add(currentDate, {
    years: 10,
  }).getTime()

  if (isPaid) {
    return Number(paidAccessExpiration.toString().slice(0, -5) + '00000') // make last 5 digits 0
  } else {
    return Number(paidAccessExpiration.toString().slice(0, -5) + '0000') // remove last 0 to make paidAccess expired
  }
}

export default memo(TrybeAdminForm)
