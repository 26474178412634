import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useParams, useNavigate } from 'react-router-dom'
import { CgChevronLeftO, CgChevronRightO, CgTrash, CgChevronDoubleLeftO, CgPen } from 'react-icons/cg'
import tw from 'twin.macro'
import { Button } from 'common/components/Button/Button'
import Workout from './Workout'
import {
  useListenDraftProgramQuery,
  useDeleteWorkoutMutation,
  useCleanWorkoutMutation,
  useListenWorkoutQuery,
} from '../programApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { DayHeaderTitle } from './DayHeader'
import { DayHeaderContainer } from './styles'
import { titleDescMinHeight } from '../constants/dayViewStyleConstants'
import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { RefsControlProvider } from 'common/components/RefsControl/WorkoutRefsControl/context'
import { useProgramDnd } from '../hooks/useProgramDnd'
import { DndContext, DragOverlay } from '@dnd-kit/core'
import { useDispatch, useSelector } from 'react-redux'
import { getPartsSelected, getPartsCopied } from '../programSlice'
import { ActionPanel } from './ActionPanel'
import { EmptyDayCard } from './DayView'
import { PartDragOverlay } from './PartDragOverlay'
import { dropAnimation } from 'common/utils/dndUtils'
import { Spinner } from 'common/components/Spinner/Spinner'
import { getSetExerciseMutationState, getUpdateWktMutationState } from '../programCache'
import { debounce } from 'lodash'
import { WorkoutInfoForm } from '../WorkoutInfoForm/WorkoutInfoForm'
import { Dialog, DialogContent } from 'common/components/Dialog/Dialog'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { useEventListener } from 'common/hooks/useEventListener'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { updateTabRowsOnDelete } from 'modules/Layout/utils/layoutUtils'
import { ExerciseDragOverlay } from './exercise/ExerciseDragOverlay'
import { NEW_USER_QUIZ } from 'modules/App/components/Header'
import { setIdsOfResultsToDelete } from 'modules/AppOnboardingQuiz/quizFlowSlice'
import useQuizResultExists from 'modules/AppOnboardingQuiz/hooks/useQuizResultExists'
import { removeItemsInCollections } from 'modules/Collections/collectionUtils'

function DayLargeView() {
  const navigate = useNavigate()
  const { id: programId, workoutId } = useParams()
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const [editMetaModalIsOpen, setEditWorkoutInfoModalOpen] = useState(false)

  const orgId = coachOrgId

  const { data: programData = {}, isLoading: programLoading } = useListenDraftProgramQuery({
    orgId,
    programId,
  })

  const { data: workout = {}, isLoading: workoutLoading } = useListenWorkoutQuery({
    orgId,
    workoutId,
  })

  const dataLoading = programLoading || programData.isFiller || workoutLoading || workout.stillLoading

  const { program = {}, wktIdsSorted = [], wktIdsToDayIdcs, shouldRedirect } = programData

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/programs')
    }
  }, [shouldRedirect, navigate])

  const { partDragging, exDragging, sensors, handleDragStart, handleDragOver, handleDragEnd } = useProgramDnd({
    wktIdsToDayIdcs,
    wktIdsSorted,
    programId,
    setEditIdx: () => null,
    orgId,
  })

  const partsSelected = useSelector(getPartsSelected)
  const partsCopied = useSelector(getPartsCopied)

  const [cleanWorkout] = useCleanWorkoutMutation()

  const showActionPanel = partsSelected.length > 0 || partsCopied.length > 0
  const titleRef = useRef()

  useEventListener('keydown', handleReturnToProgram)

  return (
    <>
      <div className='flex px-16 pt-12 pb-[40vh] bg-offWhite min-h-screen'>
        <div className='flex flex-col w-full max-w-xl mx-auto'>
          {dataLoading ? (
            <DayLargeViewSkeleton />
          ) : (
            <div>
              <Button
                variant='secondary'
                size='md'
                className='relative group mx-auto'
                onClick={() => handleReturnToProgram(null)}
              >
                <Hint className='translate-x-1/4 group-hover:translate-x-0'>or hit escape key</Hint>
                <CgChevronDoubleLeftO className='w-5 h-5 mr-2' />
                Return to program
              </Button>
              <h1 className='text-center text-4xl font-bold text-tBlack mb-12 mt-4'>
                {program?.name || 'Untitled program'}
              </h1>
              <div className='flex flex-col'>
                <DayNavActions
                  handleChangePage={handleChangePage}
                  wktIdsSorted={wktIdsSorted}
                  currentWktDayIdx={workout.dayIdx}
                  orgId={orgId}
                />
                <div className='relative bg-white rounded-xl'>
                  <SavingIndicator />
                  <DayHeader
                    workout={workout}
                    orgId={orgId}
                    setEditWorkoutInfoModalOpen={setEditWorkoutInfoModalOpen}
                    coachOrgId={coachOrgId}
                  />
                  <DndContext
                    sensors={sensors}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDragEnd}
                  >
                    <div className='min-h-[20rem]'>
                      {workout.type === 'empty' ? (
                        <EmptyDayCard workout={workout} alwaysShowActions={true} orgId={orgId} />
                      ) : (
                        <RefsControlProvider isEditing={true}>
                          <Workout workout={workout} isEditing={true} orgId={orgId} titleRef={titleRef} />
                        </RefsControlProvider>
                      )}
                      {createPortal(
                        <DragOverlay zIndex={70} dropAnimation={dropAnimation} className='cursor-move'>
                          {partDragging && <PartDragOverlay partDragging={partDragging} />}
                          {exDragging && <ExerciseDragOverlay exerciseDragging={exDragging} />}
                        </DragOverlay>,
                        document.body
                      )}
                    </div>
                  </DndContext>
                </div>
              </div>
              <Dialog open={editMetaModalIsOpen} setOpen={setEditWorkoutInfoModalOpen}>
                <DialogContent header='Workout details'>
                  <FormRefsControlProvider>
                    <WorkoutInfoForm coachOrgId={coachOrgId} workout={workout} programId={programId} />
                  </FormRefsControlProvider>
                </DialogContent>
              </Dialog>
            </div>
          )}
        </div>
      </div>
      {showActionPanel && <ActionPanel partsSelected={partsSelected} partsCopied={partsCopied} />}
    </>
  )

  function handleReturnToProgram(e) {
    if (e === null || e.key === 'Escape') {
      if (!partDragging) {
        cleanWorkout({
          orgId,
          workoutId,
          workout,
        })
      }
      navigate(`/programs/${programId}`)
    }
  }

  function handleChangePage(wktId) {
    if (!partDragging) {
      cleanWorkout({
        orgId,
        workoutId,
        workout,
      })
      navigate(`/programs/${programId}/workouts/${wktId}`)
    }
  }
}

const Hint = tw.div`
  absolute
  left-0
  -top-6
  text-xs
  font-normal
  italic
  whitespace-nowrap
  opacity-0
  group-hover:opacity-100
  transition-all
`

function DayLargeViewSkeleton() {
  return (
    <div className='mt-20'>
      <Skeleton css={tw`mb-28 w-1/2 mx-auto`} />
      <DayHeaderContainer css={tw`rounded-t-xl h-[69px]`}>
        <Skeleton css={tw`w-10`} />
      </DayHeaderContainer>
      <div className='h-screen bg-white rounded-b-xl'>
        <div
          className='flex flex-col justify-center p-4 border-b border-gray-200'
          style={{ minHeight: titleDescMinHeight }}
        >
          <Skeleton css={tw`w-3/6 mb-6`} />
          <Skeleton css={tw`w-5/6`} />
        </div>
        <div className='h-full' />
      </div>
    </div>
  )
}

function DayNavActions(props) {
  const { handleChangePage, wktIdsSorted, currentWktDayIdx } = props

  const prevWktId = wktIdsSorted[currentWktDayIdx - 1]
  const nextWktId = wktIdsSorted[currentWktDayIdx + 1]

  return (
    <div className='flex items-center justify-between mb-4'>
      <Button variant='secondary' size='md' disabled={!prevWktId} onClick={() => handleChangePage(prevWktId)}>
        <CgChevronLeftO className='w-5 h-5 mr-2' />
        Previous day
      </Button>
      <Button variant='secondary' size='md' disabled={!nextWktId} onClick={() => handleChangePage(nextWktId)}>
        Next day
        <CgChevronRightO className='w-5 h-5 ml-2' />
      </Button>
    </div>
  )
}

function DayHeader({ workout, orgId, setEditWorkoutInfoModalOpen, coachOrgId }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id: programId, workoutId } = useParams()

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)

  const [deleteWorkout] = useDeleteWorkoutMutation()

  const { quizResultExists, isQuizResultsLoading } = useQuizResultExists({ coachOrgId, itemId: workoutId })

  if (!deleteConfirmation) {
    return (
      <DayHeaderTitle dayIdx={workout.dayIdx} containerClasses='!rounded-t-xl' titleClasses='!text-base'>
        <div className='flex items-center'>
          {workout.type === 'workout' ? (
            <Tooltip content='Edit Details'>
              <Button
                size='md'
                variant='secondary'
                css={tw`bg-gray-50 hover:bg-tGreen hover:bg-opacity-10 hover:text-tGreen px-2 py-1`}
                onClick={() => {
                  setEditWorkoutInfoModalOpen(true)
                }}
              >
                <CgPen className='w-5 h-5' />
              </Button>
            </Tooltip>
          ) : null}
          <Tooltip content='Delete'>
            <Button
              loading={isQuizResultsLoading}
              size='md'
              variant='secondary'
              css={tw`bg-gray-50 hover:bg-tRed hover:bg-opacity-10 hover:text-tRed px-2 py-1`}
              onClick={() => setDeleteConfirmation(true)}
            >
              <CgTrash className='w-5 h-5' />
            </Button>
          </Tooltip>
        </div>
      </DayHeaderTitle>
    )
  }

  if (quizResultExists) {
    return (
      <DayHeaderTitle
        dayIdx={workout.dayIdx}
        containerClasses='!rounded-t-xl'
        titleClasses='!text-xs !font-normal !max-w-[50%]'
        titleText='This workout cannot be deleted because it is used as a result in the new user quiz. To delete the workout, please remove it from the quiz first.'
      >
        <div className='flex items-center'>
          <Button
            size='md'
            variant='secondary'
            css={tw`bg-gray-50 mx-3 py-2`}
            onClick={() => setDeleteConfirmation(false)}
          >
            Go back
          </Button>
          <Button
            size='md'
            variant='primary'
            css={tw`py-2`}
            onClick={() => {
              dispatch(setIdsOfResultsToDelete({ itemIds: { [workoutId]: true } }))
              navigate(NEW_USER_QUIZ)
            }}
            loading={isQuizResultsLoading}
          >
            Go to quiz
          </Button>
        </div>
      </DayHeaderTitle>
    )
  }

  return (
    <DayHeaderTitle
      dayIdx={workout.dayIdx}
      containerClasses='!rounded-t-xl'
      titleClasses='!text-base'
      titleText='Are you sure?'
    >
      <div className='flex items-center'>
        <Button
          size='md'
          variant='secondary'
          css={tw`bg-gray-50 mx-3 py-2`}
          onClick={() => setDeleteConfirmation(false)}
        >
          Go back
        </Button>
        <Button size='md' variant='danger' css={tw`py-2`} onClick={handleWorkoutDelete} loading={loading}>
          Delete
        </Button>
      </div>
    </DayHeaderTitle>
  )

  async function handleWorkoutDelete() {
    setLoading(true)

    await deleteWorkout({ orgId, workoutId, programId, workout, dayIdx: workout.dayIdx })

    await updateTabRowsOnDelete({ coachOrgId: orgId, itemIds: [workoutId] })
    await removeItemsInCollections({ coachOrgId: orgId, itemIds: [workoutId] })

    setLoading(false)

    navigate(`/programs/${programId}`)
  }
}

function SavingIndicator() {
  const [saving, setSaving] = useState(false)

  const { isLoading: isLoadingWktsMutation } = useSelector(getUpdateWktMutationState)
  const { isLoading: isLoadingExercMutation } = useSelector(getSetExerciseMutationState)

  // Needed because mutations can resolve too quickly.
  // Thus, saving indicator would unmount too quickly and user might not see the indicator.
  useEffect(() => {
    const debouncedSetSaving = debounce(setSaving, 1000)

    if (isLoadingWktsMutation || isLoadingExercMutation) {
      setSaving(true)
    } else if (!isLoadingWktsMutation && !isLoadingExercMutation) {
      debouncedSetSaving(false)
    }

    return () => debouncedSetSaving.cancel()
  }, [isLoadingWktsMutation, isLoadingExercMutation])

  if (saving) {
    return (
      <div className='absolute -left-6 top-0 z-50'>
        <div className='fixed flex items-center bg-white px-4 py-2 rounded-lg text-sm text-tBlack -translate-x-full'>
          <Spinner /> Saving...
        </div>
      </div>
    )
  }

  return null
}

export default DayLargeView
