import { each, trim } from 'lodash'
import { exIdQueryParam } from 'modules/VideoViewer/constants/queryParamConstants'
import { capitalizeFirstLetter } from 'common/utils/stringUtils'

// Check if progression name exists in all progressions and is not progression that is currently being edited
export const checkExerciseNameExists = ({ exercises, exerciseName, exerciseKey }) => {
  if (exerciseKey === exerciseName) {
    return false
  }

  return Object.keys(exercises).find((exerciseKey) => exerciseKey.trim().toLowerCase() === exerciseName)
}

export const trimAndRemoveEmptyValues = (fields) => {
  const cleaned = []

  each(fields, (field) => {
    if (field) {
      const trimmed = trim(field)
      if (trimmed) {
        cleaned.push(trimmed)
      }
    }
  })

  return cleaned
}

export const capsFirstLetter = (cues) => {
  const cleaned = []

  each(cues, (cue) => {
    if (cue) {
      const firstLetterCaps = capitalizeFirstLetter(cue)
      cleaned.push(firstLetterCaps)
    }
  })

  return cleaned
}

export const getCustomPreviewUrl = (exId) => `/videoviewer?${exIdQueryParam}=${encodeURIComponent(exId)}`
