import React, { useState } from 'react'
import { FiMove } from 'react-icons/fi'
import { CgPen, CgMoreAlt } from 'react-icons/cg'
import tw, { styled } from 'twin.macro'
import { useParams, useNavigate } from 'react-router-dom'
import * as Popover from '@radix-ui/react-popover'
import { Button } from 'common/components/Button/Button'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { useDeleteWorkoutMutation, useReorderProgramMutation } from '../programApi'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { maybePluralize } from 'common/utils/stringUtils'
import { clipboardCounterId, clipboardTextId } from '../constants/cssSelectorConstants'
import { DayHeaderContainer } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { getWorkoutsCopied, workoutsCopied, getDayIdxIsEmpty } from '../programSlice'
import { Spinner } from 'common/components/Spinner/Spinner'
import { handlePasteWorkout } from '../utils/pasteWorkoutUtils'
import { getNewWorkout } from '../programModels'
import { updateTabRowsOnDelete } from 'modules/Layout/utils/layoutUtils'
import useQuizResultsExists from 'modules/AppOnboardingQuiz/hooks/useQuizResultsExists'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { CreateDialogBanner } from 'common/components/CreateDialogBanner/CreateDialogBanner'
import { NEW_USER_QUIZ } from 'modules/App/components/Header'
import { setIdsOfResultsToDelete, setTitleOfContentToDelete } from 'modules/AppOnboardingQuiz/quizFlowSlice'
import { removeItemsInCollections } from 'modules/Collections/collectionUtils'

const HeaderActions = styled.div(({ isEditing, isPopoverOpen }) => [
  tw`hidden items-center py-1 px-1.5 absolute z-10 right-1.5 
  rounded-md bg-white border-[1px] border-gray-100 shadow group-hover:flex space-x-1.5`,
  (isEditing || isPopoverOpen) && tw`flex`,
])

const popoverBtnCss = tw`
  flex items-center bg-white hover:bg-gray-300 hover:bg-opacity-30
  text-sm text-tBlack transition-all rounded-md justify-start
  font-medium px-2 py-2 w-full`

export function DayHeader(props) {
  const { workout, setEditIdx, dayIdx, isEditing, orgId, setModalWorkout, setEditWorkoutInfoModalOpen } = props
  const { attributes, listeners } = props.dragHandleProps

  const dispatch = useDispatch()
  const workoutsCopiedState = useSelector(getWorkoutsCopied)
  const wktIdsSorted = useSelector((state) => state.program.wktIdsSorted)
  const dayIdxIsEmpty = useSelector(getDayIdxIsEmpty)
  const maxDayIdx = useSelector((state) => state.program.maxDayIdx)
  const [reorderProgram] = useReorderProgramMutation()

  const programIsLoading = false //TO-DO: temp, do later

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { id: programId } = useParams()
  const navigate = useNavigate()
  const { createAlert } = useAlert()

  const { quizResultExists, isQuizResultsLoading } = useQuizResultsExists({
    coachOrgId: orgId,
    itemIds: { [workout.id]: true },
  })

  if (deleteConfirmation) {
    return (
      <DeleteConfirmation
        setDeleteConfirmation={setDeleteConfirmation}
        orgId={orgId}
        workoutId={workout.id}
        programId={programId}
        workout={workout}
        dayIdx={dayIdx}
        setEditIdx={setEditIdx}
      />
    )
  }

  let innerContent
  if (workout && programIsLoading) {
    innerContent = renderSpinner()
  } else if (workout && !programIsLoading && !isQuizResultsLoading) {
    innerContent = renderHeaderActions()
  } else {
    innerContent = <div className='w-0' />
  }

  return <DayHeaderTitle dayIdx={dayIdx}>{innerContent}</DayHeaderTitle>

  function renderSpinner() {
    return (
      <div className='hidden items-center py-0.5 absolute right-2 bg-gray-50 group-hover:flex'>
        <Spinner />
      </div>
    )
  }

  function renderHeaderActions() {
    return (
      <HeaderActions isEditing={isEditing} isPopoverOpen={isPopoverOpen}>
        {workout.type === 'workout' || workout.type === 'single_video' ? (
          <Tooltip content='Edit details'>
            <CgPen
              className='cursor-pointer w-[18px] h-[18px] text-gray-500 hover:text-tGreen opacity-100'
              onClick={() => {
                setModalWorkout(workout)
                setEditWorkoutInfoModalOpen(true)
              }}
            />
          </Tooltip>
        ) : null}
        <Tooltip content='Drag'>
          <FiMove className='cursor-move w-4 h-4 text-gray-500 hover:text-tGreen' {...attributes} {...listeners} />
        </Tooltip>
        <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <Popover.Trigger
            onClick={(e) => {
              e.stopPropagation()
              setIsPopoverOpen(true)
            }}
          >
            <CgMoreAlt className='cursor-pointer w-5 h-5 text-gray-500 hover:text-tGreen' />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className='bg-white px-2 py-2 rounded-md shadow-xl border-2 border-gray-300 z-50'
              align='end'
              alignOffset={-7}
              sideOffset={5}
              arrowPadding={12}
            >
              <Popover.Arrow className='fill-gray-300' />
              {workout.type !== 'empty' && (
                <Popover.Close asChild={true}>
                  <button css={popoverBtnCss} onClick={(e) => handleCopy(e)}>
                    Copy workout
                  </button>
                </Popover.Close>
              )}
              {workoutsCopiedState.length > 0 && (
                <Popover.Close asChild={true}>
                  <button
                    css={popoverBtnCss}
                    onClick={(e) => {
                      handleDuplicateMouseLeave()
                      handlePaste(e)
                    }}
                    onMouseEnter={handleDuplicateMouseEnter}
                    onMouseLeave={handleDuplicateMouseLeave}
                  >
                    {maybePluralize({ text: 'Paste workout', count: workoutsCopiedState.length })}
                  </button>
                </Popover.Close>
              )}
              <Popover.Close asChild={true}>
                <button css={popoverBtnCss} onClick={(e) => handleInsert(e)}>
                  Insert new workout
                </button>
              </Popover.Close>
              <Popover.Close asChild={true}>
                <button css={popoverBtnCss} onClick={() => navigate(`workouts/${props.workout.id}`)}>
                  Expand workout
                </button>
              </Popover.Close>
              <DeleteWktButton
                quizResultExists={quizResultExists}
                workout={workout}
                setDeleteConfirmation={setDeleteConfirmation}
              />
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </HeaderActions>
    )
  }

  function handleCopy(e) {
    e.stopPropagation()

    dispatch(workoutsCopied({ workouts: [workout] }))
    createAlert({ text: 'Workout copied!', type: 'success' })
  }

  function handlePaste(e) {
    e.stopPropagation()
    handlePasteWorkout({
      pastedAtDayIdx: dayIdx,
      workoutsCopied: workoutsCopiedState,
      wktIdsSorted,
      dispatch,
      orgId,
      dayIdxIsEmpty,
      maxDayIdx,
      reorderProgram,
      programId,
      createAlert,
    })
  }

  function handleInsert(e) {
    e.stopPropagation()

    handlePasteWorkout({
      pastedAtDayIdx: dayIdx,
      workoutsCopied: [getNewWorkout(dayIdx)],
      wktIdsSorted,
      dispatch,
      orgId,
      dayIdxIsEmpty,
      maxDayIdx,
      reorderProgram,
      programId,
      createAlert,
      actionText: 'inserted',
    })
  }

  function handleDuplicateMouseEnter() {
    document.getElementById(clipboardTextId['workout']).classList.add('!text-tGreen')
    document.getElementById(clipboardCounterId['workout']).classList.add('!bg-tGreen', '!text-white')
  }

  function handleDuplicateMouseLeave() {
    document.getElementById(clipboardTextId['workout']).classList.remove('!text-tGreen')
    document.getElementById(clipboardCounterId['workout']).classList.remove('!bg-tGreen', '!text-white')
  }
}

function DeleteWktButton({ quizResultExists, workout, setDeleteConfirmation }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  if (quizResultExists) {
    return (
      <Dialog>
        <DialogTrigger css={popoverBtnCss} onOpenCb={(e) => e.stopPropagation()}>
          Delete workout
        </DialogTrigger>
        <DialogContent>
          <CreateDialogBanner
            titleText={null}
            createBtnText='Go to quiz'
            text='This workout cannot be deleted because it is used as a result in the new user quiz. To delete the workout, please remove it from the quiz first.'
            handleCreate={() => {
              dispatch(setIdsOfResultsToDelete({ itemIds: { [workout.id]: true } }))
              dispatch(setTitleOfContentToDelete(workout.title || 'Untitled Workout'))
              navigate(NEW_USER_QUIZ)
            }}
          />
        </DialogContent>
      </Dialog>
    )
  }

  if (workout.type !== 'empty') {
    return (
      <Popover.Close asChild={true}>
        <button
          css={popoverBtnCss}
          onClick={(e) => {
            e.stopPropagation()
            setDeleteConfirmation(true)
          }}
        >
          Delete workout
        </button>
      </Popover.Close>
    )
  }

  return null
}

function DeleteConfirmation(props) {
  const { setDeleteConfirmation, orgId, workoutId, workout, programId, dayIdx, setEditIdx } = props

  const [deleteWorkout] = useDeleteWorkoutMutation()

  return (
    <DayHeaderTitle titleText='Delete?' containerClasses='!px-2' titleClasses='!text-xs'>
      <div className='flex items-center'>
        <Button
          size='sm'
          variant='secondary'
          css={tw`text-xs bg-gray-50 ml-2 py-1 whitespace-nowrap`}
          onClick={(e) => {
            e.stopPropagation()
            setDeleteConfirmation(false)
          }}
        >
          Cancel
        </Button>
        <Button
          size='sm'
          variant='danger'
          css={tw`text-xs ml-2 py-1`}
          onClick={handleDelete}
          spinnerClasses='w-3 h-3 mr-1'
        >
          Delete
        </Button>
      </div>
    </DayHeaderTitle>
  )

  async function handleDelete(e) {
    e.stopPropagation()

    await deleteWorkout({
      orgId,
      workoutId,
      programId,
      workout,
      dayIdx,
    })

    await updateTabRowsOnDelete({ coachOrgId: orgId, itemIds: [workoutId] })
    await removeItemsInCollections({ coachOrgId: orgId, itemIds: [workoutId] })

    setDeleteConfirmation(false)
    setEditIdx(null)
  }
}

const Title = tw.div`
  text-tBlack
  text-sm
  leading-6
  font-bold
`

export function DayHeaderTitle(props) {
  const { dayIdx, containerClasses, titleText, titleClasses, children } = props

  const { workoutId } = useParams()
  const isFromLargeDayView = workoutId ? true : false

  const weekNum = Math.floor(dayIdx / 7) + 1

  let dayHeaderText
  if (titleText) {
    dayHeaderText = titleText
  } else if (isFromLargeDayView) {
    dayHeaderText = `Week ${weekNum}, Day ${(dayIdx % 7) + 1}`
  } else {
    //is DayView and not first day of week
    dayHeaderText = `Day ${(dayIdx % 7) + 1}`
  }

  return (
    <DayHeaderContainer className={containerClasses}>
      <Title data-testid='dayHeaderTitle' className={titleClasses}>
        {dayHeaderText}
      </Title>
      {children}
    </DayHeaderContainer>
  )
}
