import { debounce } from 'lodash'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Spinner } from 'common/components/Spinner/Spinner'
import { getIsAnyMutationPending } from 'modules/App/appSelectors'

export function MutationPendingIndicator(props) {
  const [isPending, setIsPending] = useState(false)
  const { isAnyMutationPending, lastFulfilled } = useSelector((state) => getIsAnyMutationPending(state))

  useEffect(() => {
    const debouncedSetPending = debounce(setIsPending, 500)

    if (isAnyMutationPending) {
      setIsPending(true)
    } else if (!isAnyMutationPending) {
      debouncedSetPending(false)

      if (props.mutationFulfillmentAction && lastFulfilled) {
        props.mutationFulfillmentAction(lastFulfilled)
      }
    }

    return () => debouncedSetPending.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyMutationPending])

  if (isPending) {
    return (
      <div className='fixed left-8 bottom-5 flex items-center bg-white px-4 py-2 rounded-lg text-sm text-tBlack z-[60] border-gray-300 border-2'>
        <Spinner /> Saving...
      </div>
    )
  }

  return null
}
