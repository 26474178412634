import tw from 'twin.macro'
import React, { Fragment } from 'react'
import { Combobox } from '@headlessui/react'
import { CgImage } from 'react-icons/cg'
import { TbVideo, TbVideoOff } from 'react-icons/tb'

import exerciseFallbackImg from 'modules/Programs/assets/exercise-fallback-img.svg'
import { SuggestionItem } from 'common/components/Suggestions/Suggestions'
import { getThumbnail } from 'common/utils/thumbnailUtils'
import { getLandOrPortExThumb, getLandOrPortExVid } from 'common/utils/exerciseUtils'
import { ScrollArea } from 'common/components/ScrollArea/ScrollArea'

export function ExerciseSuggestions({ suggestedExercises, exVidOrientation, containerClasses }) {
  const suggestedExStart = 0
  const suggestedExEnd = 30

  return (
    <Combobox.Options
      modal={false}
      static={process.env.NODE_ENV === 'test'}
      anchor={{ to: 'bottom start', gap: 6 }}
      className={containerClasses}
    >
      <div className='pointer-events-auto bg-white rounded-md overflow-hidden shadow-xl border-2 border-gray-300'>
        <ScrollArea css={tw`rounded-xl max-h-64`}>
          <ul className='p-2 pr-4'>
            {suggestedExercises.slice(suggestedExStart, suggestedExEnd).map(([suggestedExId, suggestedExData]) => {
              const exThumbWidth = exVidOrientation === 'landscape' ? 40 : 32
              const exThumbHeight = exVidOrientation === 'landscape' ? 32 : 48
              const exVidUrl = getLandOrPortExVid(exVidOrientation, suggestedExData)
              const exThumbUrl = getLandOrPortExThumb(exVidOrientation, suggestedExData)
              const thumb = getThumbnail({
                thumbnailUrl: exThumbUrl,
                videoUrl: exVidUrl,
                options: { width: exThumbWidth * 1.5, height: exThumbHeight * 1.5 },
              })
              return (
                <Combobox.Option as={Fragment} key={suggestedExId} value={suggestedExId}>
                  {({ active }) => (
                    <SuggestionItem active={active}>
                      <div className='flex items-center'>
                        <div className='flex items-center flex-1'>
                          <div
                            css={[
                              tw`relative flex h-8 w-10 rounded overflow-hidden bg-tBlack`,
                              exVidOrientation === 'portrait' && tw`h-12 w-8`,
                            ]}
                          >
                            {thumb ? (
                              <object data={thumb} type='image/jpeg' className='w-full h-full object-cover'>
                                <img
                                  src={exerciseFallbackImg}
                                  title='Exercise media not available'
                                  alt='Exercise media not available'
                                  className='w-full h-full object-cover'
                                  style={{
                                    imageRendering: 'pixelated',
                                  }}
                                />
                              </object>
                            ) : (
                              <CgImage className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-50 w-4 h-4' />
                            )}
                          </div>
                          <span className='ml-3 text-tBlack font-medium text-sm capitalize'>
                            {suggestedExData.name}
                          </span>
                        </div>
                        {exVidUrl ? (
                          <TbVideo className='w-5 h-5 ml-3 justify-self-end text-tGreen' title='Has video' />
                        ) : (
                          <TbVideoOff className='w-5 h-5 ml-3 justify-self-end text-gray-400' title='No video' />
                        )}
                      </div>
                    </SuggestionItem>
                  )}
                </Combobox.Option>
              )
            })}
          </ul>
        </ScrollArea>
      </div>
    </Combobox.Options>
  )
}
