import { getNewPart } from '../programModels'
import { updateInstrForSets } from '../utils/instructionsSetsUtils'

export const onClickCombine = async (props, updateWorkout) => {
  const { workout, partIdx, orgId } = props
  const earlierPartIdx = partIdx
  const laterPartIdx = partIdx + 1

  const earlierExercises = workout?.parts[earlierPartIdx]?.exercises || []
  const laterExercises = workout?.parts[laterPartIdx]?.exercises || []
  const mergedExercises = [...earlierExercises, ...laterExercises]

  let updatedWkt = JSON.parse(JSON.stringify(workout))
  updatedWkt.parts[earlierPartIdx].exercises = mergedExercises
  updatedWkt.parts.splice(laterPartIdx, 1)

  await updateWorkout({
    orgId,
    workoutId: workout.id,
    workout: updatedWkt,
  })
}

export const onClickUncombine = async (props, updateWorkout) => {
  const { workout, partIdx, exIdx, orgId } = props

  let updatedWkt = JSON.parse(JSON.stringify(workout))
  const earlierExercises = updatedWkt.parts[partIdx].exercises.slice(0, exIdx + 1)
  const newPartExercises = updatedWkt.parts[partIdx].exercises.slice(exIdx + 1)

  const earlierParts = updatedWkt.parts.slice(0, partIdx + 1)
  earlierParts[partIdx].exercises = earlierExercises
  const laterParts = updatedWkt.parts.slice(partIdx + 1)

  const newPart = getNewPart()
  newPart.exercises = newPartExercises
  newPart.sets = earlierParts[partIdx].sets || 1

  const mergedParts = [...earlierParts, newPart, ...laterParts]
  updatedWkt.parts = mergedParts

  await updateWorkout({
    orgId,
    workoutId: workout.id,
    workout: updatedWkt,
  })
}

export const onClickAddPart = (props, addPart) => {
  addPart({
    orgId: props.orgId,
    workoutId: props.workout.id,
    workout: props.workout,
  })
}

export const onClickAddRestPart = (props, addRestPart) => {
  addRestPart({
    orgId: props.orgId,
    workoutId: props.workout.id,
    workout: props.workout,
  })
}

export const changeWorkoutSets = ({ command, orgId, workout, activePartIdx, updateWorkout }) => {
  if (activePartIdx || activePartIdx === 0) {
    const newSets = command === 'add' ? workout.parts[activePartIdx].sets + 1 : workout.parts[activePartIdx].sets - 1
    const newSetsAtLeastOne = Math.max(newSets, 1)

    const wrktUpdated = JSON.parse(JSON.stringify(workout))
    const currPart = wrktUpdated.parts[activePartIdx]

    currPart.sets = newSetsAtLeastOne
    currPart.instructions = updateInstrForSets({
      instr: currPart.instructions,
      numSets: newSetsAtLeastOne,
    })

    updateWorkout({
      orgId: orgId,
      workoutId: workout.id,
      workout: wrktUpdated,
    })
  }
}
