export function TagAddConfirmation({ type, handleConfirm, handleDecline }) {
  return (
    <div className={`flex flex-col text-xs mt-1`}>
      <span className='first-letter:uppercase text-tRed'>{type} already exists but with different capitalization.</span>
      <span>
        Are you sure you want to create this {type}?
        <button type='button' className='ml-2 underline hover:text-tGreen transition-colors' onClick={handleDecline}>
          No
        </button>
        <button type='button' className='ml-2 underline hover:text-tGreen transition-colors' onClick={handleConfirm}>
          Yes
        </button>
      </span>
    </div>
  )
}
