import React, { useState } from 'react'
import { CgMoreAlt, CgPen, CgTrash } from 'react-icons/cg'
import { BiMoveVertical } from 'react-icons/bi'
import { filter } from 'lodash'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useUpdateLinkHubMutation } from './linkHubApi'

import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import CreateLinkForm from './CreateLinkForm/CreateLinkForm'

export default function LinkView({
  title,
  description,
  link,
  id,
  attributes,
  listeners,
  index,
  length,
  coachOrgId,
  resources,
}) {
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updateLinkHub] = useUpdateLinkHubMutation()
  const { createAlert } = useAlert()

  const handleDelete = async () => {
    const updatedResources = filter(resources, (resource, idx) => {
      return resource.id !== id
    })
    setLoading(true)
    await updateLinkHub({ coachOrgId, updatedLinkHub: { resources: updatedResources } })
    setLoading(false)
    createAlert({ text: 'Link deleted!', type: 'success' })
  }

  return (
    <div className='relative flex rounded-2xl bg-white w-auto mb-3 h-[104px]'>
      <div
        className='flex flex-col border-r justify-center border-tGray border-opacity-20 text-gray-500 hover:text-tGreen cursor-move'
        {...attributes}
        {...listeners}
      >
        <BiMoveVertical className='w-4 h-4 mx-2' />
      </div>
      <div className='px-6 py-4'>
        <div className='font-bold text-tBlack capitalize mb-1'>{title}</div>
        <div className='text-sm text-tGray-dark'>{description}</div>
        <a href={link} className='text-sm text-blue-400 mt-3'>
          {link}
        </a>
      </div>
      <div className='absolute top-1/2 -translate-y-1/2 right-3 inline-flex items-center justify-end bg-white group-hover:border group-hover:border-tBlack group-hover:border-opacity-20 rounded-lg group-hover:shadow-sm'>
        <Dialog open={editDialogOpen} setOpen={setEditDialogOpen}>
          <DialogTrigger className='hidden group-hover:inline-block hover:text-tGreen py-1 px-2 border-r border-tBlack border-opacity-20 transition-all'>
            <CgPen className='w-5 h-5' />
          </DialogTrigger>
          <DialogContent header='Edit link'>
            <FormRefsControlProvider>
              <CreateLinkForm
                linkKey={index}
                resource={{ title, description, link, id }}
                id={id}
                resources={resources}
                length={length}
                coachOrgId={coachOrgId}
              />
            </FormRefsControlProvider>
          </DialogContent>
        </Dialog>
        <Dialog>
          <DialogTrigger className='hidden group-hover:inline-block hover:text-tRed py-1 px-2 border-r border-tBlack border-opacity-20 transition-all'>
            <CgTrash className='w-5 h-5' />
          </DialogTrigger>
          <DialogContent>
            <DeleteDialogBanner
              text={`This will delete the ${title} link`}
              handleDelete={() => handleDelete()}
              loading={loading}
            />
          </DialogContent>
        </Dialog>
        <button className='inline-block py-1 px-2'>
          <CgMoreAlt className='w-5 h-5' />
        </button>
      </div>
    </div>
  )
}
