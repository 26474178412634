import React, { useState } from 'react'
import { sortBy } from 'lodash'

import { Dialog, DialogContent } from 'common/components/Dialog/Dialog'
import { Pagination } from 'common/components/Pagination/Pagination'
import { usePagination } from 'common/components/Pagination/hooks/usePagination'
import { handleSortOfDates } from 'common/utils/sortUtils'

import { TableHead } from './TableHead'
import { TableBody } from './TableBody'
import { EmptyTable } from './EmptyTable'
import { ProgramForm } from '../ProgramForm/ProgramForm'

export function ProgramTable({ filteredPrograms, loading }) {
  const totalPrograms = Object.keys(filteredPrograms).length

  const [sortMethod, setSortMethod] = useState('updated')
  const [sortOrder, setSortOrder] = useState('asc')

  const { setCurrentPage, currentPage, pageLimit, totalPages, offset, itemCount } = usePagination({
    itemCount: totalPrograms,
    initialLimit: 10,
  })
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [modalProgram, setModalProgram] = useState({})

  const tableCols = [
    { title: 'name', sortOrder, setSortOrder, sortMethod, setSortMethod },
    { title: 'equipment' },
    { title: 'updated', sortOrder, setSortOrder, sortMethod, setSortMethod },
  ]

  const sortedAndFilteredPrograms = sortPrograms(filteredPrograms, sortOrder, sortMethod)

  return (
    <>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        itemCount={totalPrograms}
        totalPages={totalPages}
      />
      <div className='overflow-x-auto'>
        <div className='align-middle inline-block min-w-full'>
          <div className='overflow-hidden border-b border-gray-200 rounded-xl'>
            {!loading && itemCount === 0 ? (
              <EmptyTable />
            ) : (
              <table className='min-w-full divide-y divide-gray-200'>
                <TableHead columns={tableCols} />
                <TableBody
                  loading={loading}
                  programs={sortedAndFilteredPrograms}
                  offset={offset}
                  currentPage={currentPage}
                  pageLimit={pageLimit}
                  setModalProgram={setModalProgram}
                  setEditDialogOpen={setEditDialogOpen}
                />
              </table>
            )}
          </div>
        </div>
      </div>
      <Dialog open={editDialogOpen} setOpen={setEditDialogOpen}>
        <DialogContent header='Edit program' contentClassNames='!absolute !top-12' titleClassNames='!shadow-none'>
          <ProgramForm programId={modalProgram.programId} program={modalProgram.program} />
        </DialogContent>
      </Dialog>
      <div className='mt-4'>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageLimit={pageLimit}
          itemCount={totalPrograms}
          totalPages={totalPages}
          counterContainerClasses='place-self-start'
        />
      </div>
    </>
  )
}

function sortPrograms(filteredPrograms, sortOrder, sortMethod) {
  const allProgramEntries = Object.entries(filteredPrograms)

  if (sortMethod === 'updated') {
    return handleSortOfDates(allProgramEntries, sortOrder, 'updatedAt')
  } else if (sortMethod === 'created') {
    return handleSortOfDates(allProgramEntries, sortOrder, 'createdAt')
  } else if (sortMethod === 'name') {
    return sortOrder === 'asc'
      ? sortBy(allProgramEntries, ([key, prog]) => {
          return prog.name && prog.name.toLowerCase()
        }).reverse()
      : sortBy(allProgramEntries, ([key, prog]) => {
          return prog.name && prog.name.toLowerCase()
        })
  } else if (sortMethod === 'id') {
    return sortOrder === 'asc'
      ? sortBy(allProgramEntries, ([key, prog]) => {
          return prog.id
        }).reverse()
      : sortBy(allProgramEntries, ([key, prog]) => {
          return prog.id
        })
  }
}
