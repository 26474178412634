import 'twin.macro'
import React from 'react'
import { trim, each, isEmpty } from 'lodash'

import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenCollectionsQuery } from 'modules/Collections/collectionsApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'

import { Button } from 'common/components/Button/Button'
import { availableItemTypes } from '../constants/constants'
import { selectButtonActiveStyles, selectButtonBaseStyles } from '../styles'
import { getItemExists, includesSearchQuery } from '../utils'

export function CollectionSelection({ existingItems, currentItem, handleSelection, searchQuery }) {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: collectionsData } = useListenCollectionsQuery({ coachOrgId })

  if (collectionsData === undefined || collectionsData?.isLoading) {
    return <div className='text-sm'>Loading...</div>
  }

  if (isEmpty(collectionsData)) {
    return <div className='text-sm'>No collections in your collection library.</div>
  }

  const availableCollections = getAvailableCollections({ collections: collectionsData, searchQuery, existingItems })

  if (isEmpty(availableCollections)) {
    return (
      <div className='text-sm'>
        {!searchQuery?.length ? 'No collection results.' : 'No collection results for this search.'}
      </div>
    )
  }

  return Object.entries(availableCollections).map(([collectionId, collection]) => (
    <Button
      key={collectionId}
      type='button'
      onClick={() => handleSelection({ id: collectionId })}
      variant='secondary'
      size='md'
      css={[selectButtonBaseStyles, currentItem.id === collectionId && selectButtonActiveStyles]}
    >
      <div className='flex-1'>
        <span className='flex'>{trim(collection.name) ? collection.name : 'Untitled'}</span>
      </div>
    </Button>
  ))
}

function getAvailableCollections({ collections, searchQuery, existingItems }) {
  const availableCollections = {}

  each(collections, (collection, collectionId) => {
    const includesQuery = includesSearchQuery(collection.name, searchQuery)

    const itemExists = getItemExists({
      existingItems,
      itemId: collectionId,
      itemType: availableItemTypes.COLLECTION,
    })

    if (includesQuery && !itemExists && collection.name) {
      availableCollections[collectionId] = collection
    }
  })

  return availableCollections
}
