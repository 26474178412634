import React from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import tw, { styled } from 'twin.macro'
import { useDispatch } from 'react-redux'

import { newExercisesResorted } from 'modules/ExerciseLibrary/exerciseLibrarySlice'

const TableHeadCell = styled.th(({ isClickable, removeSpacing }) => [
  tw`px-2 md:px-6 py-3 text-left font-bold text-gray-500 capitalize`,
  isClickable && tw`cursor-pointer`,
  removeSpacing && tw`px-0`,
])

function SortableCell({ title, sortOrder, setSortOrder, sortMethod, setSortMethod, hideOnMobile, removeSpacing }) {
  const dispatch = useDispatch()
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    setSortMethod(title)
    dispatch(newExercisesResorted())
  }

  return (
    <TableHeadCell
      key={title}
      scope='col'
      onClick={toggleSortOrder}
      isClickable={sortMethod ? true : false}
      hideOnMobile={hideOnMobile}
      removeSpacing={removeSpacing}
      className={hideOnMobile ? 'hidden md:table-cell' : ''}
    >
      {title}
      {title === sortMethod ? (
        sortOrder === 'asc' ? (
          <FiChevronDown className={`inline-block w-4 h-4`} data-testid={`th-${title}-asc`} />
        ) : (
          <FiChevronUp className={`inline-block w-4 h-4`} data-testid={`th-${title}-desc`} />
        )
      ) : null}
    </TableHeadCell>
  )
}

export default SortableCell
