import { each } from 'lodash'

// Validation tests
export const checkExerciseInOtherProgressions = (exercise, { options, createError }) => {
  const progressions = options.context.progressions
  const otherProgressions =
    progressions !== null
      ? Object.entries(progressions).find(
          ([key, progresionValue]) =>
            progresionValue && options.context.progressionKey !== key && progresionValue.includes(exercise)
        )
      : []

  const [otherProgressionKey] = otherProgressions || []

  // Exercise does not exist in other progression
  if (!otherProgressions || !exercise) {
    return true
  }

  return createError({
    message: `Exercise is in use by progression "${otherProgressionKey}"`,
  })
}

export const checkDuplicateExercisesInProgression = (exercise, { options, createError }) => {
  const parentProgressionArray = options.parent

  let count = 0
  each(parentProgressionArray, (exId, index) => {
    if (exId === exercise) {
      count += 1
    }
  })

  if (count <= 1) return true

  return createError({
    message: `Exercise appears multiple times in progression`,
  })
}

export const checkExArrHasFilledStr = (_, { createError, parent: arrToCheck }) => {
  const hasAtLeastOneFilledStr = arrToCheck.join('').length > 0

  if (hasAtLeastOneFilledStr) {
    return true
  }

  return createError({
    message: `At least 1 exercise required`,
  })
}

export const checkProgramIsValid = (programId, { options, createError }) => {
  const availableProgramIds = options.context.availableProgramIds
  const validProgram = availableProgramIds.indexOf(programId) !== -1

  if (validProgram) {
    return true
  }

  return createError({
    message: `Program does not exist, please select a valid program`,
  })
}

// Data transforms
export const trimString = (_, originalValue) => String(originalValue).trim()
export const emptyStringToNull = (value, originalValue) => (!originalValue ? null : value)
export const emptyStringToZero = (value, originalValue) => (!originalValue ? 0 : value)
