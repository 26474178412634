import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'
import 'index.css'
import App from 'modules/App'
import { store } from 'store'
import { AuthProvider } from 'modules/Auth/AuthContext'
import { AlertProvider } from 'common/components/Alert/context'
import { CustomizationProvider } from 'common/contexts/Customization/context'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <AuthProvider>
          <CustomizationProvider>
            <AlertProvider>
              <App />
            </AlertProvider>
          </CustomizationProvider>
        </AuthProvider>
      </StyleSheetManager>
    </BrowserRouter>
  </Provider>
)

function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}
