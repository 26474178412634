import * as yup from 'yup'
import { trimString } from 'common/utils/yupSchemaUtils'

export const schema = yup
  .object({
    title: yup
      .string()
      .required('Video title required')
      .min(3, 'Video title must be at least 3 characters')
      .transform(trimString),
    subtitle: yup.string().max(36, 'Subtitle must be under 36 characters'),
    instructions: yup.string().max(600, 'Description must be under 600 characters'),
  })
  .required()
