import { globalApi, listenFirebase, removeFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'
import { updateTabRowsOnDelete } from 'modules/Layout/utils/layoutUtils'

export const collectionsApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenCollections: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/collections`, api)
      },
    }),
    listenCollectionImg: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId, id }, api) => {
        await listenFirebase(`coachOrgs/${coachOrgId}/collections/${id}/previewImg`, api)
      },
    }),
    listenCollectionIntroVideo: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId, id }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/collections/${id}/introVideo`, api)
      },
    }),
    listenCollectionLinkImg: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId, collectionId, itemId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/collections/${collectionId}/items/${itemId}/previewImg`, api)
      },
    }),
    removeCollection: build.mutation({
      queryFn: async ({ coachOrgId, id }) => {
        const { data, error } = await removeFirebase(`coachOrgs/${coachOrgId}/collections/${id}`)
        await updateTabRowsOnDelete({ coachOrgId, itemIds: [id] })
        return { data, error }
      },
    }),
    setCollection: build.mutation({
      queryFn: async ({ coachOrgId, id, collection }) => {
        const { data, error } = await setFirebase(`coachOrgs/${coachOrgId}/collections/${id}`, collection)
        return { data, error }
      },
    }),
    updateCollection: build.mutation({
      queryFn: async ({ coachOrgId, id, collection }) => {
        const { data, error } = await updateFirebase(`coachOrgs/${coachOrgId}/collections/${id}`, collection)
        return { data, error }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenCollectionsQuery,
  useListenCollectionImgQuery,
  useListenCollectionIntroVideoQuery,
  useListenCollectionLinkImgQuery,
  useRemoveCollectionMutation,
  useSetCollectionMutation,
  useUpdateCollectionMutation,
} = collectionsApi
