import 'twin.macro'
import React from 'react'
import { sortBy, trim, each } from 'lodash'

import { useListenProgramWorkoutsQuery } from 'modules/Layout/layoutApi'
import { useListenDraftProgramQuery } from 'modules/Programs/programApi'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'

import { Button } from 'common/components/Button/Button'
import { availableItemTypes } from '../constants/constants'
import { selectButtonActiveStyles, selectButtonBaseStyles } from '../styles'
import { getItemExists, includesSearchQuery } from '../utils'
import { TextHint } from 'common/components/TextHint/TextHint'

export function WorkoutSelection({ existingItems, currentItem, previousItem, handleSelection, searchQuery }) {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  let programId
  if (previousItem) {
    programId = previousItem.find((item) => item.type === availableItemTypes.PROGRAM).id
  }

  const { data: publishedWkts } = useListenProgramWorkoutsQuery({ programId })

  const { data: draftProgramData = {}, isLoading: isLoadingDraft } = useListenDraftProgramQuery({
    orgId: coachOrgId,
    programId,
  })

  const { wktIdsToDayIdcs: draftProgramWktIds } = draftProgramData

  const isLoadingData = publishedWkts?.isLoading || isLoadingDraft || draftProgramData?.isFiller || !programId
  // Fetching and loading publishedWkts
  if (isLoadingData) {
    return <div className='text-sm'>Loading...</div>
  }

  if (!publishedWkts) {
    return (
      <div>
        <div className='text-sm'>No workouts exist in this program.</div>
        <div className='pt-2'>
          <TextHint
            hintText={`Can't find a workout?`}
            expandedText='Workouts need to be published before they can be added to your App Layout.'
            updatedAtText={`This program was last published `}
            updatedAt={draftProgramData.program?.updatedAt}
          />
        </div>
      </div>
    )
  }

  const availableWorkouts = getAvailableWorkouts()

  if (!Object.keys(availableWorkouts).length) {
    return (
      <div>
        <div className='text-sm'>No workout results for this search.</div>
        <div className='pt-2'>
          <TextHint
            hintText={`Can't find a workout?`}
            expandedText='Workouts need to be published before they can be added to your App Layout.'
            updatedAtText={`This program was last published `}
            updatedAt={draftProgramData.program?.updatedAt}
          />
        </div>
      </div>
    )
  }

  const sortedByDayIdx = sortBy(Object.entries(availableWorkouts), ([key, wrkt]) => wrkt.dayIdx)

  return (
    <>
      <WorkoutSelectionList
        currentItem={currentItem}
        handleSelection={handleSelection}
        programId={programId}
        sortedByDayIdx={sortedByDayIdx}
      />
      <div className='sticky bottom-0 bg-white pt-2 pr-2'>
        <TextHint
          hintText={`Can't find a workout?`}
          expandedText='Workouts need to be published before they can be added to your App Layout.'
          updatedAtText={`This program was last published `}
          updatedAt={draftProgramData.program?.updatedAt}
        />
      </div>
    </>
  )

  function getAvailableWorkouts() {
    const availableWorkouts = {}

    each(publishedWkts, (workout, workoutId) => {
      const includesQuery = includesSearchQuery(workout.title, searchQuery)
      const itemType = availableItemTypes.WORKOUT

      const itemExists = getItemExists({
        existingItems,
        itemId: workoutId,
        itemType,
      })

      //ensure draftProgramWktIds exists when determing wasDeleted because
      //there's a small chance legacy program is published
      //but not yet initiated as a draft (for orgs that came before dashboard was released)
      const wasDeleted = draftProgramWktIds && !draftProgramWktIds.hasOwnProperty(workoutId)
      const isWktRest = workout?.type === 'rest'
      if (includesQuery && !itemExists && !wasDeleted && !isWktRest) {
        availableWorkouts[workoutId] = workout
      }
    })

    return availableWorkouts
  }
}

const WorkoutSelectionList = ({ sortedByDayIdx, currentItem, handleSelection, programId }) => {
  return sortedByDayIdx.map(([workoutId, workout]) => {
    const dayIdx = Number(workout.dayIdx)

    if (!dayIdx && dayIdx !== 0) return null
    const selectionType = availableItemTypes.WORKOUT
    const isSelected = currentItem.id === workoutId

    return (
      <Button
        key={workoutId}
        type='button'
        onClick={() =>
          handleSelection({
            id: workoutId,
            programId,
            type: selectionType,
          })
        }
        variant='secondary'
        size='md'
        css={[selectButtonBaseStyles, isSelected && selectButtonActiveStyles]}
      >
        <div className='relative flex-1'>
          <span className='flex'>{trim(workout.title) ? workout.title : 'Untitled'}</span>
          <span className='flex text-xxs text-gray-400'>
            Week {Math.floor(dayIdx / 7 + 1)} | Day {(dayIdx % 7) + 1}
          </span>
          {workout.type === 'single_video' && (
            <div className='absolute right-0 bottom-0 text-xxs px-1.5 bg-gray-500 text-white rounded-md font-medium'>
              video
            </div>
          )}
        </div>
      </Button>
    )
  })
}
