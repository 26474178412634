import tw, { css } from 'twin.macro'
import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CgMoreAlt, CgPen, CgTrash } from 'react-icons/cg'
import { format } from 'date-fns'
import * as Popover from '@radix-ui/react-popover'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'

import { useDuplicateProgramMutation, useRemoveProgramMutation } from '../programApi'

import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'
import { isUploadingAsset } from 'common/utils/fileUploading/uploadUtils'
import { setIdsOfResultsToDelete, setTitleOfContentToDelete } from 'modules/AppOnboardingQuiz/quizFlowSlice'
import { NEW_USER_QUIZ } from 'modules/App/components/Header'

import { CreateDialogBanner } from 'common/components/CreateDialogBanner/CreateDialogBanner'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { useAlert } from 'common/components/Alert/hooks/useAlert'

import { LoadingRow } from './LoadingRow'
import { PreviewImgTableCell } from './PreviewImgTableCell'
import { TableCell } from './styles'
import { Spinner } from 'common/components/Spinner/Spinner'
import { TagTableCell } from 'common/components/TableCells/TagTableCell'
import useQuizResultsExists from 'modules/AppOnboardingQuiz/hooks/useQuizResultsExists'

export function TableRow({ programId, program, setModalProgram, setEditDialogOpen }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const [removeProgram] = useRemoveProgramMutation({
    fixedCacheKey: 'programtable-shared-remove-program',
  })
  const [duplicateProgram] = useDuplicateProgramMutation()
  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: programId })

  let programAndWorkoutIds = { [programId]: true }
  Object.keys(program?.workouts || {}).forEach((wktId) => (programAndWorkoutIds[wktId] = true))
  const { quizResultExists, isQuizResultsLoading } = useQuizResultsExists({ coachOrgId, itemIds: programAndWorkoutIds })

  const rowActionContainerRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [isFixedOptions, setIsFixedOptions] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const { createAlert } = useAlert()
  const handleDelete = async () => {
    setLoading(true)
    await removeProgram({ coachOrgId, programId })
    setLoading(false)
    createAlert({ text: 'Program deleted!', type: 'success' })
  }

  const handleDuplicate = async () => {
    setLoading(true)
    await duplicateProgram({ orgId: coachOrgId, program })
    setIsFixedOptions(false)
    setLoading(false)
    createAlert({ text: 'Program duplicated!', type: 'success' })
  }

  const handleRowClick = (e) => {
    // Check that event did not happen in modal
    const occuredOutsideModal = e.currentTarget.contains(e.target)

    // Check that event did not happen in action row (edit/delete/more)
    const occuredOutsideActionRow = !rowActionContainerRef.current.contains(e.target)
    if (occuredOutsideModal && occuredOutsideActionRow) {
      navigate(`/programs/${programId}`)
    }
  }

  const isUploading = isUploadingAsset(assetDrafts, 'previewImg')

  return !program || !coachOrgId ? (
    <LoadingRow />
  ) : (
    <tr
      className='group'
      css={[tw`cursor-pointer h-24 hover:bg-gray-50`, isFixedOptions && tw`bg-gray-50`]}
      onClick={handleRowClick}
    >
      <PreviewImgTableCell isUploading={isUploading} previewImg={program?.previewImg} altText={program.name} />
      <TableCell tw='w-4/12'>
        <div className='w-96 flex flex-col'>
          <div className='font-bold text-tBlack capitalize truncate'>{program.name}</div>
          <div className='text-sm text-tGray-dark truncate'>{program.description || 'No description'}</div>
          <div className='flex items-center text-xs text-gray-500'>
            <span>{program.subtitle || 'No subtitle'}</span>
            <span className='text-lg mx-1'>&#8226;</span>
            <span>{program.reps === 1 || !program.reps ? '1 rep' : `${program.reps} reps`}</span>
          </div>
        </div>
      </TableCell>
      <TagTableCell
        tags={program['equipment']}
        noTagsText='No equipment set for this program'
        truncateCount={3}
        cellClassNames='!w-4/12'
      />
      <TableCell tw='w-2/12'>
        <div className='text-xs text-gray-500'>{program.updatedAt ? format(program.updatedAt, 'PP') : 'No date'}</div>
      </TableCell>
      <td className='relative'>
        <div
          ref={rowActionContainerRef}
          className='absolute top-1/2 -translate-y-1/2 right-3 inline-flex items-center justify-end bg-white group-hover:border group-hover:border-tBlack group-hover:border-opacity-20 rounded-lg group-hover:shadow-sm'
          css={[isFixedOptions && tw`border border-tBlack border-opacity-20 shadow-sm`]}
        >
          <div
            css={[
              tw`hidden group-hover:inline-block hover:text-tGreen py-1 px-2 border-r border-tBlack border-opacity-20 transition-all`,
              isFixedOptions && tw`inline-block`,
            ]}
          >
            <CgPen
              className='w-5 h-5'
              onClick={() => {
                setModalProgram({ programId, program })
                setEditDialogOpen(true)
              }}
            />
          </div>
          <Dialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen}>
            <DialogTrigger
              disabled={isUploading || isQuizResultsLoading}
              css={[
                tw`hidden group-hover:inline-block hover:text-tRed py-1 px-2 border-r border-tBlack border-opacity-20 transition-all disabled:cursor-not-allowed`,
                isFixedOptions && tw`inline-block`,
              ]}
            >
              <CgTrash className='w-5 h-5' />
            </DialogTrigger>
            <DialogContent>
              {quizResultExists ? (
                <CreateDialogBanner
                  titleText={null}
                  createBtnText='Go to quiz'
                  text='This program cannot be deleted because it or its workouts are used in the new user quiz. To delete the program, please first remove it and its workouts from the quiz.'
                  handleCreate={() => {
                    dispatch(setIdsOfResultsToDelete({ itemIds: programAndWorkoutIds }))
                    dispatch(setTitleOfContentToDelete(program.name))
                    navigate(NEW_USER_QUIZ)
                  }}
                />
              ) : (
                <DeleteDialogBanner
                  text={`This will permanently delete the ${program.name} program`}
                  loading={loading}
                  handleDelete={handleDelete}
                  typeToConfirm={true}
                />
              )}
            </DialogContent>
          </Dialog>
          <Popover.Root open={isFixedOptions} onOpenChange={(isOpen) => setIsFixedOptions(isOpen)}>
            <Popover.Trigger className='py-1 px-2'>
              <CgMoreAlt className='w-5 h-5 hover:text-tGreen' onClick={() => setIsFixedOptions(true)} />
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content align='end' css={[PopoverContetStyles]}>
                <Popover.Arrow className='fill-white' />
                <Popover.Close
                  css={[PopoverContetButtonStyles]}
                  onClick={() => {
                    setModalProgram({ programId, program })
                    setEditDialogOpen(true)
                  }}
                >
                  Edit
                </Popover.Close>
                <button disabled={loading} css={[PopoverContetButtonStyles]} onClick={() => handleDuplicate()}>
                  {loading && <Spinner />}
                  <span className='ml-.5'>Duplicate</span>
                </button>
                <Popover.Close
                  disabled={isUploading}
                  css={[PopoverContetButtonStyles, tw`text-tRed`]}
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Delete
                </Popover.Close>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>
      </td>
    </tr>
  )
}

const PopoverContetStyles = css`
  ${tw`
    flex
    flex-col
    bg-white
    py-1.5
    px-1.5
    shadow-md
    rounded-xl
    w-28
    z-50
  `}

  > span {
    bottom: 95% !important;
    right: 10px !important;
  }
`

const PopoverContetButtonStyles = tw`
  text-sm
  flex
  items-center
  justify-start
  w-full
  mb-0.5
  py-0.5
  px-2
  rounded-lg
  last:mb-0
  hover:bg-gray-100
  disabled:cursor-not-allowed
`
