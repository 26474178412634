import React, { Fragment } from 'react'
import * as Portal from '@radix-ui/react-portal'
import { CgCheck } from 'react-icons/cg'
import { Combobox } from '@headlessui/react'
import { TextHint } from 'common/components/TextHint/TextHint'

import { SuggestionContainer, SuggestionItem } from 'common/components/Suggestions/Suggestions'

import { getSuggestedPrograms } from '../utils'

export function ProgramInputOptions({ availablePrograms, value, formProgramValues, inputRef, popperContainer }) {
  const suggestedExercises = getSuggestedPrograms({
    availablePrograms,
    inputValue: value,
    allProgamInputValues: formProgramValues,
  })

  const inputWidth = inputRef?.current?.getBoundingClientRect()?.width
  return (
    <Portal.Root>
      <Combobox.Options className='z-[999]' ref={popperContainer} modal={false}>
        <SuggestionContainer containerStyle={{ width: inputWidth }} outerContent={<Hint />}>
          {Object.keys(suggestedExercises).length === 0 ? (
            <li className='cursor-default select-none py-2 px-2'>
              <div>No suggestions found.</div>
            </li>
          ) : (
            Object.entries(suggestedExercises).map(([programId, programName]) => (
              <Combobox.Option as={Fragment} key={programId} value={programId}>
                {({ selected, active }) => (
                  <SuggestionItem active={active}>
                    {selected && <CgCheck className='w-6 h-6 text-tGreen' aria-hidden='true' />}
                    {programName}
                  </SuggestionItem>
                )}
              </Combobox.Option>
            ))
          )}
        </SuggestionContainer>
      </Combobox.Options>
    </Portal.Root>
  )
}

function Hint() {
  return (
    <div className='sticky bottom-0 bg-white px-2 py-1'>
      <TextHint
        hintText={`Can't find a program?`}
        expandedText='Programs need to be published before they can be added to a Series.'
      />
    </div>
  )
}
