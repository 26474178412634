import 'twin.macro'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CgImage } from 'react-icons/cg'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty, isEqual, pick } from 'lodash'

import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useListenResourceImgQuery, useUpdateResourcesMutation } from '../featuredResourcesApi'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'
import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'

import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'
import { Switch } from 'common/components/Switch/Switch'
import { Input } from 'common/components/Input/Input'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { DeleteConfirmationBanner } from 'common/components/DeleteConfirmationBanner/DeleteConfirmationBanner'
import { createUID } from 'common/utils/createUID'
import TypeInput, { availableTypes } from './TypeInput'
import SceneInput, { availableScenes } from './SceneInput'
import VideoInput from './VideoInput'
import { schema } from './schema'

export default function ResourceForm({ resource, resourceEntries, coachOrgId }) {
  const [, setDialogOpen] = useDialog()
  const { createAlert } = useAlert()

  const [loading, setLoading] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  const [updateResources] = useUpdateResourcesMutation()
  const isNewResource = !Boolean(resource)
  const [resourceId, setResourceId] = useState(null)
  useEffect(() => {
    if (!isNewResource) {
      setResourceId(resource.id)
    } else {
      const newId = createUID()
      setResourceId(newId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: previewImg } = useListenResourceImgQuery({ coachOrgId, id: resourceId })
  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: resourceId })
  const { data: videoData } = useListenCoachVideosQuery({ coachOrgId })
  const videos = videoData || {}

  const defaultValues = {
    id: resource?.id || null,
    type: resource?.type || availableTypes[0].value,
    title: resource?.title || '',
    description: resource?.description || '',
    link: resource?.link || '',
    openInDefaultBrowser: false,
    scene: resource?.scene || availableScenes[0].value,
    videoId: resource?.videoId || '',
    previewImg: resource?.previewImg || '',
    resourceIdx: resource?.resourceIdx >= 0 ? resource?.resourceIdx : resourceEntries.length,
    isPaid: resource?.isPaid === undefined ? true : resource.isPaid,
  }
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const {
    watch,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors,
  } = methods

  const formState = watch()

  const onSubmit = async (data) => {
    if (!isEmpty(errors)) {
      return
    }

    const formData = getResourceFormData(data)
    const alertText = isNewResource ? 'Resource created!' : 'Resource updated!'
    const resourceUpdate = {
      [resourceId]: {
        ...formData,
        id: resourceId,
      },
    }

    setLoading(true)
    await updateResources({ coachOrgId, resources: resourceUpdate })
    setLoading(false)
    createAlert({ text: alertText, type: 'success' })
    setDialogOpen(false)
  }

  const handleDelete = async () => {
    const remainingResources = resourceEntries.filter(([resId]) => resId !== resource.id)
    let resourceUpdate = {
      [resourceId]: null,
    }
    remainingResources.forEach(([resId, res], index) => {
      resourceUpdate[resId] = { ...res, resourceIdx: index }
    })
    setLoading(true)
    await updateResources({ coachOrgId, resources: resourceUpdate })
    setLoading(false)
    createAlert({ text: 'Resource deleted!', type: 'success' })
    setDialogOpen(false)
  }

  const { addManyInputRefs, moveFocusOnKeyPress, clearControlledRefs } = useFormRefsControl()
  const typeRef = useRef()
  const titleRef = useRef()
  const descriptionRef = useRef()
  const linkRef = useRef()
  const sceneRef = useRef()
  const videoRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    clearControlledRefs()
    if (formState.type === 'link') {
      addManyInputRefs([
        { ref: typeRef, name: 'type' },
        { ref: titleRef, name: 'title' },
        { ref: descriptionRef, name: 'description' },
        { ref: linkRef, name: 'link' },
        { ref: submitRef, name: 'submit' },
      ])
    } else if (formState.type === 'scene') {
      addManyInputRefs([
        { ref: typeRef, name: 'type' },
        { ref: sceneRef, name: 'scene' },
        { ref: titleRef, name: 'title' },
        { ref: descriptionRef, name: 'description' },
        { ref: submitRef, name: 'submit' },
      ])
    } else if (formState.type === 'video') {
      addManyInputRefs([
        { ref: typeRef, name: 'type' },
        { ref: videoRef, name: 'video' },
        { ref: descriptionRef, name: 'description' },
        { ref: submitRef, name: 'submit' },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.type])

  useEffect(() => {
    const hasDefaultSceneImg = availableScenes.some((scene) => scene.defaultImg === formState.previewImg)
    if (formState.type !== 'scene' && !resource?.previewImg && hasDefaultSceneImg) {
      setValue('previewImg', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.type])

  useEventListener('keydown', (e) => moveFocusOnKeyPress(e, handleSubmit(onSubmit)))

  const createResourceOnUpload = () => {
    if (isNewResource) {
      const formData = getResourceFormData(formState)
      const resourcesUpdate = {
        [resourceId]: { ...formData, id: resourceId, previewImg: 'isUploading' },
      }
      updateResources({ coachOrgId, resources: resourcesUpdate })
      const alertText = 'Resource created!'
      createAlert({ text: alertText, type: 'success' })
    }
  }

  const isUploadDisabled =
    errors?.link ||
    errors?.title ||
    formState?.title.length === 0 ||
    (formState?.type === 'link' && formState?.link?.length === 0) ||
    (isNewResource && isEqual(formState, defaultValues))

  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form onSubmit={handleSubmit(onSubmit)} id='resourceForm'>
          <div className='flex flex-col px-10 py-4'>
            <TypeInput inputRef={typeRef} value={formState.type} setValue={setValue} clearErrors={clearErrors} />
            {formState.type === 'scene' && (
              <SceneInput
                inputRef={sceneRef}
                value={formState.scene}
                setValue={setValue}
                coachOrgId={coachOrgId}
                previewImg={formState.previewImg}
              />
            )}
            {formState.type !== 'video' && (
              <TextInput name='title' register={register} inputRef={titleRef} errors={errors} maxLength={30} />
            )}
            {formState.type === 'video' && (
              <VideoInput
                inputRef={videoRef}
                value={formState?.videoId}
                videoTitle={videos?.[formState?.videoId]?.title || ''}
                videos={videos}
                setValue={setValue}
                clearErrors={clearErrors}
                error={errors?.videoId}
              />
            )}
            <TextInput
              name='description'
              register={register}
              inputRef={descriptionRef}
              errors={errors}
              optional
              maxLength={60}
            />
            {formState.type === 'link' && (
              <>
                <TextInput name='link' register={register} inputRef={linkRef} errors={errors} />
                <div className='mb-4'>
                  <Switch
                    onChange={({ target: { checked } }) => {
                      if (checked) {
                        setValue('openInDefaultBrowser', false)
                      } else {
                        setValue('openInDefaultBrowser', true)
                      }
                    }}
                    label='Link opens in-app'
                    isChecked={!formState.openInDefaultBrowser}
                    tooltipText='If your link opens another app, ie. YouTube, Instagram, Telegram, etc. disable this for best user experience'
                  />
                </div>
              </>
            )}
            <div className='mb-4'>
              <Switch
                onChange={({ target: { checked } }) => {
                  setValue('isPaid', checked)
                }}
                label='Paid subscribers only'
                isChecked={formState.isPaid}
                labelContainerClasses='!mr-12'
              />
            </div>
            {(formState.type === 'scene' || formState.type === 'link') && (
              <>
                <UploadInput
                  name='previewImg'
                  label='Image'
                  register={register}
                  setValue={setValue}
                  coachOrgId={coachOrgId}
                  id={resourceId}
                  uploadType='featured-resource-image'
                  liveUrl={previewImg}
                  setError={setError}
                  onUpload={isNewResource ? createResourceOnUpload : null}
                  uploadDisabled={isUploadDisabled}
                  previewIcon={<CgImage className='!w-5 !h-5' />}
                  clearErrors={clearErrors}
                  fileType='image'
                />
                {errors?.previewImg && <p className='flex text-xs mt-1 text-tRed'>{errors.previewImg.message}</p>}
              </>
            )}
          </div>
        </form>
      </div>
      {resource && deleteConfirmation ? (
        <DeleteConfirmationBanner
          text='Are you sure?'
          handleDelete={handleDelete}
          handleGoBack={() => setDeleteConfirmation(false)}
          loading={loading}
        />
      ) : (
        <UpdateActions
          itemKey={resource?.id}
          handleSubmit={handleSubmit}
          handleDelete={() => setDeleteConfirmation(true)}
          loading={loading}
          actionText={resource ? 'Save' : 'Create'}
          deleteDisabled={isUploadingAssets(assetDrafts)}
          disabled={isEqual(formState, defaultValues)}
          form='resourceForm'
          hideDelete={!resource?.id}
          ref={submitRef}
        />
      )}
    </FormProvider>
  )
}

const TextInput = ({ register, inputRef, errors, name, optional, autoFocus, maxLength }) => (
  <div className='mb-4'>
    <label htmlFor={name} className='capitalize inline-flex cursor-pointer font-semibold text-tBlack' tw='mb-1'>
      {name}
    </label>
    <Input
      name={name}
      type='text'
      placeholder={`Enter ${name}${optional ? ' (optional)' : ''}`}
      register={register}
      inputRef={inputRef}
      error={errors[name]?.message}
      autoFocus={autoFocus}
      maxLength={maxLength}
    />
    {errors[name] && <p className='flex text-xs mt-1 text-tRed'>{errors[name]?.message}</p>}
  </div>
)

function getResourceFormData(data) {
  let formData = {}
  if (data?.type === 'link') {
    formData = pick(data, [
      'id',
      'type',
      'title',
      'description',
      'link',
      'openInDefaultBrowser',
      'previewImg',
      'resourceIdx',
      'isPaid',
    ])
  } else if (data?.type === 'scene') {
    formData = pick(data, ['id', 'type', 'title', 'description', 'scene', 'previewImg', 'resourceIdx', 'isPaid'])
  } else if (data?.type === 'video') {
    formData = pick(data, ['id', 'type', 'videoId', 'description', 'resourceIdx', 'isPaid'])
  }

  return formData
}
