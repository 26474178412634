import { createContext, useEffect, useState } from 'react'
import { find } from 'lodash'

export const RefsControlContext = createContext()
RefsControlContext.displayName = 'RefsControlContext'

export function RefsControlProvider({ isEditing, children }) {
  const [shouldFocusOnLast, setsShouldFocusOnLast] = useState(false)
  const [workoutHeaderRefs, setWorkoutHeaderRefs] = useState([])
  const [exerciseRefs, setExerciseRefs] = useState({})
  const [activeInputRef, setActiveInputRef] = useState(false)
  const [newEx, setNewEx] = useState(null)

  const addExerciseRefs = (refs, refsId, partIdx, exIdx, isLastExercise) => {
    const refData = {
      partIdx,
      exIdx,
      refs, //consists of two refs, one for name and one for instructions
    }
    setExerciseRefs((state) => {
      const combinedRefs = {
        ...state,
        [refsId]: refData,
      }

      let cleanedRefs = Object.fromEntries(
        Object.entries(combinedRefs).filter(([_, data]) => data?.refs?.name?.current != null)
      )
      return cleanedRefs
    })

    if (shouldFocusOnLast && isLastExercise) {
      //POSSIBLY DEPRECATD WITH NEW ProgramBUilder UI
      //Rough logic is, right before adding a new exercise
      //Set shouldFocusOnLast true
      //Once the new ref is added, it focuses on last and sets back the shouldFocusOnLast state to false
      setActiveInputRef(refs.name)
      focusOnRef(refs.name)
      setsShouldFocusOnLast(false)
    }
  }

  const addWorkoutHeaderRefs = (refs) => {
    setWorkoutHeaderRefs(refs)
  }

  const clearControlledRefs = () => {
    setWorkoutHeaderRefs([])
    setExerciseRefs([])
  }

  const setActiveInput = (ref) => {
    if (ref?.current) {
      setActiveInputRef(ref)
    } else {
      setActiveInputRef(false)
    }
  }

  const focusOnActiveInput = (shouldSelect) => {
    if (isEditing && activeInputRef?.current) {
      activeInputRef?.current?.focus({ preventScroll: true })

      if (shouldSelect) {
        activeInputRef?.current?.select()
      }

      const distanceFromTop = activeInputRef?.current?.getBoundingClientRect().top
      const viewportHeight = window?.innerHeight
      const isCloseToTop = distanceFromTop < viewportHeight * 0.25
      const isCloseToBottom = distanceFromTop > viewportHeight * 0.75
      if (isCloseToTop || isCloseToBottom)
        activeInputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
    }
  }

  const focusOnRef = (ref) => {
    ref?.current?.focus({ preventScroll: true })
    ref?.current?.select()

    const distanceFromTop = ref?.current?.getBoundingClientRect().top
    const viewportHeight = window?.innerHeight
    const isCloseToTop = distanceFromTop < viewportHeight * 0.25
    const isCloseToBottom = distanceFromTop > viewportHeight * 0.75
    if (isCloseToTop || isCloseToBottom)
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
  }

  const focusOnLastInput = () => {
    setsShouldFocusOnLast(true)
  }

  useEffect(() => {
    if (newEx) {
      const newExInputRef = find(exerciseRefs, (ex, exKey) => ex.partIdx === newEx.partIdx && ex.exIdx === newEx.exIdx)
      console.log(newExInputRef, newEx)
      if (newExInputRef) {
        focusOnRef(newExInputRef.refs.name)
        setNewEx(null)
      }
    }
  }, [exerciseRefs, newEx])

  const contextValue = {
    workoutHeaderRefs,
    exerciseRefs,
    activeInputRef,
    addExerciseRefs,
    addWorkoutHeaderRefs,
    clearControlledRefs,
    setActiveInput,
    focusOnActiveInput,
    focusOnLastInput,
    focusOnRef,
    setNewExParams: setNewEx,
  }

  return <RefsControlContext.Provider value={contextValue}>{children}</RefsControlContext.Provider>
}
