import { cloneDeep, isEmpty, sortBy } from 'lodash'
import { createUID } from 'common/utils/createUID'
import { globalApi } from 'modules/App/globalApi'
import { store } from '../../../store'

export const getFetchedQuestionsState = ({ coachOrgId }) =>
  globalApi.endpoints.listenDraftQuiz.select({ coachOrgId })(store.getState())?.data?.questions

const getDescendanDeleteFbUpdate = ({ initialIdToCheck, questions }) => {
  let descendantQuestionFbUpdate = {}
  let descendantResultFbUpdate = {}

  let descendantQuestionIdsToCheck = [initialIdToCheck]
  do {
    const idToCheck = descendantQuestionIdsToCheck[descendantQuestionIdsToCheck.length - 1]
    const foundQuestion = questions?.[idToCheck]
    descendantQuestionIdsToCheck.pop()
    if (!isEmpty(foundQuestion?.answers)) {
      const answerEntries = Object.entries(foundQuestion.answers)
      answerEntries.forEach(([, answer]) => {
        if (answer?.resultId) {
          descendantResultFbUpdate[`${answer.result.id}/${foundQuestion.id}/${answer?.resultId}`] = null
        } else {
          descendantQuestionFbUpdate[answer?.nextQuestionId] = null
          descendantQuestionIdsToCheck.push(answer?.nextQuestionId)
        }
      })
    }
  } while (descendantQuestionIdsToCheck?.length > 0)

  return { descendantQuestionFbUpdate, descendantResultFbUpdate }
}

export const copyQuestionOnly = ({ coachOrgId, copyQuestionId }) => {
  const questions = getFetchedQuestionsState({ coachOrgId })
  return questions[copyQuestionId]
}

// copy initial question and all of its decendants
export const copyQuestionWithDescendants = ({ coachOrgId, copyQuestionId }) => {
  const questions = getFetchedQuestionsState({ coachOrgId })
  let copiedQuestions = {}

  // iterate over all descendants and extract questions
  let descendantQuestionIdsToCheck = [copyQuestionId]
  do {
    const idToCheck = descendantQuestionIdsToCheck[descendantQuestionIdsToCheck.length - 1]
    const foundQuestion = questions?.[idToCheck]

    // copy found question
    copiedQuestions[idToCheck] = foundQuestion

    descendantQuestionIdsToCheck.pop()
    if (!isEmpty(foundQuestion?.answers)) {
      const answerEntries = Object.entries(foundQuestion.answers)
      answerEntries.forEach(([, answer]) => {
        // only non result answers branch out to other questions, extract them
        if (!answer?.resultId) {
          descendantQuestionIdsToCheck.push(answer?.nextQuestionId)
        }
      })
    }
  } while (descendantQuestionIdsToCheck?.length > 0)

  return copiedQuestions
}

const getAnswerPasteUpdate = ({ initialIdToCheck, copiedQuestions, pasteAtQuestionId }) => {
  let descendantQuestions = {}

  // get descendant questions
  let descendantQuestionIdsToCheck = [initialIdToCheck]
  descendantQuestions[initialIdToCheck] = copiedQuestions?.[initialIdToCheck]
  do {
    const idToCheck = descendantQuestionIdsToCheck[descendantQuestionIdsToCheck.length - 1]
    const foundQuestion = copiedQuestions?.[idToCheck]
    descendantQuestionIdsToCheck.pop()
    if (!isEmpty(foundQuestion?.answers)) {
      const answerEntries = Object.entries(foundQuestion.answers)
      answerEntries.forEach(([, answer]) => {
        // get descendant questions of non result answers
        if (!answer?.resultId) {
          descendantQuestions[answer?.nextQuestionId] = copiedQuestions?.[answer?.nextQuestionId]
          descendantQuestionIdsToCheck.push(answer?.nextQuestionId)
        }
      })
    }
  } while (descendantQuestionIdsToCheck?.length > 0)

  // map out the answer and question ids that need to be updated
  let oldQuestionIdtoNewQuestionId = {}
  let oldAnswerIdToNewAnswerId = {}
  Object.entries(descendantQuestions).forEach(([questionId, question]) => {
    oldQuestionIdtoNewQuestionId[questionId] = createUID()
    Object.entries(question?.answers || {}).forEach(([answerId]) => {
      oldAnswerIdToNewAnswerId[answerId] = createUID()
    })
  })

  let questionsUpdate = {}
  let resultsUpdate = {}

  Object.entries(descendantQuestions).forEach(([questionId, question]) => {
    const newQuestionId = oldQuestionIdtoNewQuestionId[questionId]
    let updatedAnswers = {}
    // update answer ids
    Object.entries(question?.answers || {}).forEach(([answerId, answer]) => {
      if (answer?.resultId) {
        const newAnswerId = oldAnswerIdToNewAnswerId[answerId]
        updatedAnswers[newAnswerId] = { ...answer, resultId: newAnswerId }
        if (answer?.result?.id) {
          resultsUpdate[`${answer?.result?.id}/${newQuestionId}/${newAnswerId}`] = true
        }
      } else {
        const newId = oldQuestionIdtoNewQuestionId[answerId]
        updatedAnswers[newId] = { ...answer, nextQuestionId: newId }
      }
    })

    // update question and previousQuestion ids
    const newPreviousQuestionId =
      questionId === initialIdToCheck ? pasteAtQuestionId : oldQuestionIdtoNewQuestionId[question.previousQuestionId]
    questionsUpdate[newQuestionId] = {
      ...question,
      id: newQuestionId,
      previousQuestionId: newPreviousQuestionId,
      answers: updatedAnswers,
    }
  })

  return { questionsUpdate, resultsUpdate, updatedAnswerId: oldQuestionIdtoNewQuestionId[initialIdToCheck] }
}

export const getFbUpdateOfPasteQuestionOnly = ({ pasteAtQuestion, copiedQuestion, coachOrgId }) => {
  const questions = getFetchedQuestionsState({ coachOrgId })

  let updatedPasteAtQuestion = cloneDeep(pasteAtQuestion)
  updatedPasteAtQuestion.answers = updatedPasteAtQuestion?.answers || {}
  // update paste at question title and description
  updatedPasteAtQuestion.title = copiedQuestion?.title || ''
  updatedPasteAtQuestion.description = copiedQuestion?.description || ''

  let questionsUpdate = {}
  let resultsUpdate = {}

  const sortedPasteAtAnswerEntries = sortBy(
    Object.entries(pasteAtQuestion?.answers || {}),
    ([, answer]) => answer?.index
  )
  const sortedCopiedAnswerEntries = sortBy(Object.entries(copiedQuestion?.answers || {}), ([, answer]) => answer?.index)
  sortedCopiedAnswerEntries.forEach(([, copiedAnswer], index) => {
    const pasteAtAnswer = sortedPasteAtAnswerEntries[index]
    // pasting answer over existing question answer
    // replace only title and description, maintain existing paths
    if (pasteAtAnswer) {
      const [answerId, answer] = pasteAtAnswer
      updatedPasteAtQuestion.answers[answerId] = {
        ...answer,
        title: copiedAnswer?.title || '',
        description: copiedAnswer?.description || '',
      }
    } else {
      // paste at question answer does not exist
      // create new answer
      const newQuestionId = createUID()
      const newQuestion = {
        id: newQuestionId,
        previousQuestionId: pasteAtQuestion.id,
      }
      updatedPasteAtQuestion.answers[newQuestionId] = {
        index,
        nextQuestionId: newQuestionId,
        title: copiedAnswer?.title || '',
        description: copiedAnswer?.description || '',
      }
      questionsUpdate[newQuestionId] = newQuestion
    }
  })

  // delete answers and their descendants that were overwritten by pasting
  if (sortedCopiedAnswerEntries.length < sortedPasteAtAnswerEntries.length) {
    sortedPasteAtAnswerEntries.forEach(([answerId, answer], index) => {
      if (index > sortedCopiedAnswerEntries.length - 1) {
        if (answer?.resultId) {
          resultsUpdate[`${answer?.result?.id}/${pasteAtQuestion.id}/${answerId}`] = null
        } else {
          const { descendantQuestionFbUpdate, descendantResultFbUpdate } = getDescendanDeleteFbUpdate({
            initialIdToCheck: answer?.nextQuestionId,
            questions,
          })
          questionsUpdate = { ...questionsUpdate, ...descendantQuestionFbUpdate }
          resultsUpdate = { ...resultsUpdate, ...descendantResultFbUpdate }
        }
        delete updatedPasteAtQuestion.answers[answerId]
      }
    })
  }

  questionsUpdate = { [updatedPasteAtQuestion.id]: updatedPasteAtQuestion, ...questionsUpdate }

  return { questionsUpdate, resultsUpdate }
}

export const getFbUpdateOfPasteEverything = ({ pasteAtQuestion, firstCopiedQuestion, copiedQuestions, coachOrgId }) => {
  const questions = getFetchedQuestionsState({ coachOrgId })

  let updatedPasteAtQuestion = cloneDeep(pasteAtQuestion)
  updatedPasteAtQuestion.answers = updatedPasteAtQuestion?.answers || {}
  // update paste at question title and description
  updatedPasteAtQuestion.title = firstCopiedQuestion?.title || ''
  updatedPasteAtQuestion.description = firstCopiedQuestion?.description || ''

  let questionsUpdate = {}
  let resultsUpdate = {}

  const sortedPasteAtAnswerEntries = sortBy(
    Object.entries(pasteAtQuestion?.answers || {}),
    ([, answer]) => answer?.index
  )
  const sortedCopiedAnswerEntries = sortBy(
    Object.entries(firstCopiedQuestion?.answers || {}),
    ([, answer]) => answer?.index
  )
  sortedCopiedAnswerEntries.forEach(([, copiedAnswer], index) => {
    const isQuestionEmpty = isEmpty(sortedPasteAtAnswerEntries)
    // paste at question has answers
    if (!isQuestionEmpty) {
      // paste at question is being overwritten with a copied question, delete overwritten question descendants
      const { descendantQuestionFbUpdate, descendantResultFbUpdate } = getDescendanDeleteFbUpdate({
        initialIdToCheck: pasteAtQuestion.id,
        questions,
      })
      questionsUpdate = { ...questionsUpdate, ...descendantQuestionFbUpdate }
      resultsUpdate = { ...resultsUpdate, ...descendantResultFbUpdate }
    }

    const pasteAtAnswer = sortedPasteAtAnswerEntries[index]
    // copied answer leads to result
    if (copiedAnswer?.resultId) {
      // write copied answer to paste at question
      const newAnswerId = createUID()
      updatedPasteAtQuestion.answers[newAnswerId] = {
        ...copiedAnswer,
        resultId: newAnswerId,
      }
      // copied answer is overwriting paste at question answer, delete overwritten answer
      if (pasteAtAnswer) {
        const [pasteAtAnswerId] = pasteAtAnswer
        delete updatedPasteAtQuestion.answers[pasteAtAnswerId]
      }
      resultsUpdate[`${copiedAnswer?.result?.id}/${pasteAtQuestion.id}/${newAnswerId}`] = true
    } else {
      // copied answer leads to question, get all question descendants
      const {
        questionsUpdate: qUpdate,
        resultsUpdate: rUpdate,
        updatedAnswerId,
      } = getAnswerPasteUpdate({
        initialIdToCheck: copiedAnswer.nextQuestionId,
        copiedQuestions,
        pasteAtQuestionId: pasteAtQuestion.id,
      })
      updatedPasteAtQuestion.answers[updatedAnswerId] = {
        ...copiedAnswer,
        nextQuestionId: updatedAnswerId,
      }
      if (pasteAtAnswer) {
        const [pasteAtAnswerId] = pasteAtAnswer
        delete updatedPasteAtQuestion.answers[pasteAtAnswerId]
      }
      questionsUpdate = { ...questionsUpdate, ...qUpdate }
      resultsUpdate = { ...resultsUpdate, ...rUpdate }
    }
  })

  // delete answers and their descendants that were overwritten by pasting
  if (sortedCopiedAnswerEntries.length < sortedPasteAtAnswerEntries.length) {
    sortedPasteAtAnswerEntries.forEach(([answerId, answer], index) => {
      if (index > sortedCopiedAnswerEntries.length - 1) {
        if (answer?.resultId) {
          resultsUpdate[`${answer?.result?.id}/${pasteAtQuestion.id}/${answerId}`] = null
        } else {
          const { descendantQuestionFbUpdate, descendantResultFbUpdate } = getDescendanDeleteFbUpdate({
            initialIdToCheck: answer?.nextQuestionId,
            questions,
          })
          questionsUpdate = { ...questionsUpdate, ...descendantQuestionFbUpdate }
          resultsUpdate = { ...resultsUpdate, ...descendantResultFbUpdate }
        }
        delete updatedPasteAtQuestion.answers[answerId]
      }
    })
  }

  questionsUpdate = { [updatedPasteAtQuestion.id]: updatedPasteAtQuestion, ...questionsUpdate }

  return {
    questionsUpdate,
    resultsUpdate,
  }
}

export const getFbUpdateOfDeleteQuestion = ({ deleteQuestion, coachOrgId }) => {
  const questions = getFetchedQuestionsState({ coachOrgId })

  let updatedDeleteQuestion = cloneDeep(deleteQuestion)
  updatedDeleteQuestion.answers = {}
  // update question title and description
  updatedDeleteQuestion.title = ''
  updatedDeleteQuestion.description = ''

  const { descendantQuestionFbUpdate, descendantResultFbUpdate } = getDescendanDeleteFbUpdate({
    initialIdToCheck: deleteQuestion.id,
    questions,
  })

  const isDeletingFirstQuestion = !deleteQuestion?.previousQuestionId
  const questionsUpdate = {
    [updatedDeleteQuestion.id]: isDeletingFirstQuestion ? null : updatedDeleteQuestion,
    ...descendantQuestionFbUpdate,
  }
  const resultsUpdate = { ...descendantResultFbUpdate }

  return {
    questionsUpdate,
    resultsUpdate,
  }
}
