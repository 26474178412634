import { useContext } from 'react'
import { DialogContext } from 'common/components/Dialog/context'

export function useDialog() {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('useDialog must be used within a DialogContext')
  }

  return context
}
