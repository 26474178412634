export const getVideoDuration = (file, setValue, name) => {
  var video = document.createElement('video')
  video.preload = 'metadata'
  video.onloadedmetadata = function () {
    window.URL.revokeObjectURL(video.src)
    setValue(name, getTimeDurationString(video.duration))
  }
  video.src = URL.createObjectURL(file)
  video.remove()
}

export const getTimeDurationString = (ms) => {
  let hrs = Math.floor(ms / 1000 / 60 / 60)
  let mins = Math.floor(ms / 1000 / 60) % 60
  let secs = (ms / 1000) % 60

  if (hrs < 1) {
    hrs = '00'
  } else {
    hrs = hrs.toString().padStart(2, '0')
  }

  if (mins < 1) {
    mins = '00'
  } else {
    mins = mins.toString().padStart(2, '0')
  }

  return `${hrs}:${mins}:${secs.toFixed(2).toString().padStart(5, '0')}`
}

export const getTimeWithoutFractional = (timestring) => timestring.split('.')[0]
