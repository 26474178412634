import tw from 'twin.macro'

export const PastePartButton = tw.button`
  uppercase
  items-center
  justify-center
  leading-none
  cursor-pointer
  absolute
  z-20
  hidden
  left-1/2
  px-3
  py-1.5
  bg-tBlack
  text-white
  hover:ring-1
  hover:ring-tGreen
  rounded-md
  text-xxs
  font-semibold
`

export const DayHeaderContainer = tw.div`
  flex
  flex-row
  items-center
  justify-between
  px-4
  py-4
  border-b
  border-gray-200
  bg-gray-50
`
