import { globalApi, listenFirebase, removeFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'
import { each, union } from 'lodash'
import { newExerciseUploaded } from './exerciseLibrarySlice'
import { createUID } from 'common/utils/createUID'
import { getStandardizedName } from 'common/utils/stringUtils'
import { removeInvalidFirebaseChars } from 'common/utils/fileUploading/nameAndIdUtils'

export const exerciseLibraryApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenExercises: build.query({
      queryFn: () => ({ data: { isFiller: true } }),
      onCacheEntryAdded: async ({ coachOrgId, exVidOrientation }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/exercises`, api, (exercises) => {
          const exNameToId = {}
          const exIdHasVideo = {}
          const timeNow = new Date().getTime()

          each(exercises, (exData, exId) => {
            const name = exData.name
            if (!name) return

            const nameStandardized = getStandardizedName(name)
            exNameToId[nameStandardized] = exId

            if (exData.createdAt && Number(exData.createdAt) > Number(timeNow) - 10000) {
              //Made in last 10 sec from load
              api.dispatch(
                newExerciseUploaded({
                  exId,
                  createdAt: exData.createdAt,
                })
              )
            }

            if (exVidOrientation === 'landscape' && exData.video) {
              exIdHasVideo[exId] = true
            } else if (exVidOrientation === 'portrait' && exData.portraitVid) {
              exIdHasVideo[exId] = true
            }
          })

          const tagsList = exercises
            ? union(
                ...Object.entries(exercises).map(([, value]) => (value.tags && value.tags.length > 0 ? value.tags : []))
              )
            : []

          const newCache = {
            exercises,
            exNameToId,
            exIdHasVideo,
            tagsList,
          }

          api.updateCachedData(() => newCache)
        })
      },
    }),
    listenExercise: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId, exerciseId }, api) => {
        await listenFirebase(`coachOrgs/${coachOrgId}/exercises/${exerciseId}`, api)
      },
    }),
    listenExLibEnabled: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        await listenFirebase(`coachOrgs/${coachOrgId}/mobilePreMountSettings/visibleLayout/exerciseLibrary`, api)
      },
    }),
    setExLibEnabled: build.mutation({
      queryFn: async ({ coachOrgId, enabled }) => {
        const { data, error } = await setFirebase(
          `coachOrgs/${coachOrgId}/mobilePreMountSettings/visibleLayout/exerciseLibrary`,
          enabled
        )
        return { data, error }
      },
    }),
    listenShowExLibLevels: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        await listenFirebase(`coachOrgs/${coachOrgId}/mobilePreMountSettings/visibleLayout/showExLibraryLevels`, api)
      },
    }),
    setShowExLibLevels: build.mutation({
      queryFn: async ({ coachOrgId, show }) => {
        const { data, error } = await setFirebase(
          `coachOrgs/${coachOrgId}/mobilePreMountSettings/visibleLayout/showExLibraryLevels`,
          show
        )
        return { data, error }
      },
    }),
    setExercise: build.mutation({
      queryFn: async ({ coachOrgId, exerciseKey, exercise }) => {
        const { data, error } = await setFirebase(`coachOrgs/${coachOrgId}/exercises/${exerciseKey}`, exercise)
        return { data, error }
      },
    }),
    duplicateExercise: build.mutation({
      queryFn: async ({ coachOrgId, exercise }) => {
        const newId = createUID()
        const exerciseKey = removeInvalidFirebaseChars(`${exercise.name}${newId}-`)
        const lowerExKey = exerciseKey.toLowerCase()
        const newExercise = { ...exercise, name: `${exercise.name} Copy`, createdAt: null, updatedAt: null }

        const { data, error } = await setFirebase(`coachOrgs/${coachOrgId}/exercises/${lowerExKey}`, newExercise)

        return { data, error }
      },
    }),
    updateExercises: build.mutation({
      queryFn: async ({ coachOrgId, exercises }) => {
        const { data, error } = await updateFirebase(`coachOrgs/${coachOrgId}/exercises`, exercises)
        return { data, error }
      },
    }),
    updateExercise: build.mutation({
      queryFn: async ({ coachOrgId, exerciseKey, exercise }) => {
        const { data, error } = await updateFirebase(`coachOrgs/${coachOrgId}/exercises/${exerciseKey}`, exercise)
        return { data, error }
      },
    }),
    removeExercise: build.mutation({
      queryFn: async ({ coachOrgId, exerciseKey }) => {
        const { data, error } = await removeFirebase(`coachOrgs/${coachOrgId}/exercises/${exerciseKey}`)
        return { data, error }
      },
    }),
    listenExerciseVideo: build.query({
      queryFn: () => ({ data: false }),
      onCacheEntryAdded: async ({ coachOrgId, exerciseId, exVidOrientation }, api) => {
        const vidKey = exVidOrientation === 'landscape' ? 'video' : 'portraitVid'
        listenFirebase(`coachOrgs/${coachOrgId}/exercises/${exerciseId}/${vidKey}`, api)
      },
    }),
    listenExerciseThumbnail: build.query({
      queryFn: () => ({ data: false }),
      onCacheEntryAdded: async ({ coachOrgId, exerciseId, exVidOrientation }, api) => {
        const thumbKey = exVidOrientation === 'landscape' ? 'thumbnail' : 'portraitThumbnail'
        listenFirebase(`coachOrgs/${coachOrgId}/exercises/${exerciseId}/${thumbKey}`, api)
      },
    }),
    listenExerciseAudio: build.query({
      queryFn: () => ({ data: false }),
      onCacheEntryAdded: async ({ coachOrgId, exerciseId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/exercises/${exerciseId}/audio`, api)
      },
    }),
    writeAWSError: build.mutation({
      //Temp code to track AWS auth errors
      queryFn: async ({ userId, awsError }) => {
        const newId = createUID()
        const payload = {
          userId,
          error: JSON.stringify(awsError),
          errorMessage: awsError?.message || 'No message on error',
        }
        const { data, error } = await setFirebase(`errors/awsErrors/${newId}`, payload)
        return { data, error }
      },
    }),
    listenHasReadVidGuidelines: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/dashboardOnboarding/exercises/hasReadVidGuidelines`, api)
      },
    }),
    setHasReadVidGuidelines: build.mutation({
      queryFn: async ({ coachOrgId }) => {
        const { data, error } = await setFirebase(
          `coachOrgs/${coachOrgId}/dashboardOnboarding/exercises/hasReadVidGuidelines`,
          true
        )
        return { data, error }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenExercisesQuery,
  useListenExerciseQuery,
  useListenExLibEnabledQuery,
  useSetExLibEnabledMutation,
  useListenShowExLibLevelsQuery,
  useSetShowExLibLevelsMutation,
  useSetExerciseMutation,
  useDuplicateExerciseMutation,
  useUpdateExercisesMutation,
  useUpdateExerciseMutation,
  useRemoveExerciseMutation,
  useListenExerciseVideoQuery,
  useListenExerciseThumbnailQuery,
  useListenExerciseAudioQuery,
  useWriteAWSErrorMutation,
  useListenHasReadVidGuidelinesQuery,
  useSetHasReadVidGuidelinesMutation,
} = exerciseLibraryApi
