import React from 'react'

import { useListenProgramQuery } from 'modules/Layout/layoutApi'

import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'

import { ItemCard } from './ItemCard'
import { ItemLoadingCard } from './ItemLoadingCard'

export const ProgramItem = React.memo(({ item, isDragging, attributes, listeners }) => {
  const { data: program } = useListenProgramQuery({ programId: item.id })
  const programLoading = program === undefined || program?.isLoading

  if (programLoading) {
    return <ItemLoadingCard />
  }

  if (!program) {
    return null
  }

  return (
    <ItemCard
      id={item.id}
      name={program?.name}
      description={program?.subtitle}
      type={availableItemTypes.PROGRAM}
      previewImg={program?.previewImg}
      isDragging={isDragging}
      attributes={attributes}
      listeners={listeners}
    />
  )
})
