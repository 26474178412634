import tw from 'twin.macro'
import React, { useState, useRef, useEffect } from 'react'
import { CgArrowsVAlt, CgClose } from 'react-icons/cg'
import { Combobox } from '@headlessui/react'
import { useFormContext } from 'react-hook-form'

import { usePopper } from 'common/hooks/usePopper'
import { ProgramInputOptions } from './ProgramInputOptions'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'

export const ProgramInput = React.memo(
  ({
    isDragOverlay,
    index,
    value,
    setValue,
    remove,
    availablePrograms,
    attributes,
    listeners,
    onKeyDown,
    onFocus,
    setAddingWithSelect,
    addNewItem,
    isLastProgram,
    inputRefsSortMethod,
  }) => {
    const inputRef = useRef(null)
    const [selectedProgramId, setSelectedProgramId] = useState(value)
    const [inputText, setInputText] = useState(availablePrograms?.[value] || '') //Displays program name, used for search
    const { addInputRef, removeInputRef } = useFormRefsControl()

    const {
      trigger,
      getValues,
      formState: { errors },
      register,
    } = useFormContext()

    const { ref } = register(`programs.${index}`)
    const error = errors?.programs?.[index]
    const formProgramValues = getValues('programs')

    const handleSelect = (programId) => {
      setSelectedProgramId(programId)
      setValue(programId)
      trigger(`programs.${index}`)
      setAddingWithSelect((state) => ({ ...state, [index]: true }))

      if (isLastProgram) {
        addNewItem()
      } else {
        // setFocus(`exercises.${index + 1}`)
      }
    }

    let [popperTrigger, popperContainer] = usePopper({
      placement: 'bottom-start',
      strategy: 'fixed',
    })

    useEffect(() => {
      addInputRef({
        ref: inputRef,
        posIdx: index + 1,
        name: 'program',
        programIdx: index,
        sortMethod: inputRefsSortMethod,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <div className='relative flex items-center'>
          {selectedProgramId && formProgramValues.length > 1 && (
            <div
              {...attributes}
              {...listeners}
              className='group-hover:visible group-hover:text-opacity-50 hover:!text-opacity-80 -left-5 -translate-y-1/2 
              invisible text-opacity-0 absolute top-1/2 text-tBlack transition-all duration-200 cursor-move
      '
            >
              <CgArrowsVAlt className='w-5 h-5' />
            </div>
          )}
          <Combobox value={selectedProgramId} onChange={handleSelect}>
            {/* If input is not used as dnd overlay, register it, otherwise do not to avoid conflicts*/}
            <div className='text-gray-400 font-semibold ml-3 w-6'>{index + 1}.</div>
            {!isDragOverlay ? (
              <div ref={popperTrigger} className='w-full'>
                <Combobox.Input
                  // {...restInputRegister}
                  ref={(e) => {
                    ref(e)
                    inputRef.current = e
                  }}
                  placeholder={`Enter program ${index + 1}`}
                  autoComplete='off'
                  displayValue={(programId) => (availablePrograms[programId] ? availablePrograms[programId] : '')}
                  className='text-ellipsis bg-inherit cursor-pointer'
                  css={[inputClasses, error && tw`focus:ring-1 ring-1 ring-tRed focus:ring-tRed`]}
                  onKeyDown={onKeyDown}
                  onFocus={onFocus}
                  onChange={(event) => {
                    setInputText(event.target.value)
                  }}
                  autoFocus={true}
                />
              </div>
            ) : (
              <Combobox.Input
                placeholder={`Enter program ${index + 1}`}
                autoComplete='off'
                displayValue={(programId) => (availablePrograms[programId] ? availablePrograms[programId] : '')}
                className='text-ellipsis bg-inherit cursor-pointer'
                css={[
                  inputClasses,
                  error && tw`focus:ring-1 hover:ring-1 ring-1 ring-tRed focus:ring-tRed hover:ring-tRed`,
                ]}
              />
            )}
            {inputText?.length > 0 && (
              <ProgramInputOptions
                popperContainer={popperContainer}
                value={inputText}
                availablePrograms={availablePrograms}
                formProgramValues={formProgramValues}
                inputRef={inputRef}
              />
            )}
          </Combobox>
          {formProgramValues.length > 1 && (
            <button
              type='button'
              aria-label='remove program'
              onClick={() => {
                remove(index)
                removeInputRef(inputRef, 'program')
              }}
              className='group-hover:visible group-hover:text-opacity-50 hover:!text-opacity-80 -right-6 -translate-y-1/2 
              invisible text-opacity-0 absolute top-1/2 ml-2 text-tBlack transition-all duration-200 cursor-pointer'
            >
              <CgClose />
            </button>
          )}
        </div>
        {error && (
          <div className='flex flex-col text-xs mt-1 ml-3'>
            <span className='text-tRed'>{error.message}</span>
          </div>
        )}
      </>
    )
  }
)

const inputClasses = tw`font-medium focus:ring-0 p-2 capitalize w-full
border-none overflow-hidden whitespace-nowrap placeholder:text-gray-400
hover:bg-gray-100 hover:bg-opacity-70 focus:bg-gray-100 focus:bg-opacity-70 rounded`
