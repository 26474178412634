import tw from 'twin.macro'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { CgCheckO, CgClose } from 'react-icons/cg'

import { useListenAllExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'
import { useUpdateExerciseMutation, useRemoveExerciseMutation } from '../exerciseLibraryApi'

import { exerciseNameChanged, uploadingExerciseCancelled } from '../uploadingExercisesSlice'

import { useCustomization } from 'common/contexts/Customization/useCustomization'
import { CircularProgress } from 'common/components/CircularProgress/CircularProgress'
import { Spinner } from 'common/components/Spinner/Spinner'
import { cancelAWSUpload } from 'common/utils/fileUploading/uploadUtils'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { getStandardizedName } from 'common/utils/stringUtils'
import { getLandOrPortExThumb } from 'common/utils/exerciseUtils'
import { UploadErrors } from './UploadErrors'
import { EX_NAME_MAX_LENGTH, EX_NAME_MIN_LENGTH } from '../ExerciseForm/schema'
import { uploadDoneBtnId } from './UploadDialogActions'

export function UploadingList({ uploadingExercises, coachOrgId, exerciseList, uploadErrors }) {
  const { exVidOrientation } = useCustomization()

  const { data: assetDrafts } = useListenAllExistingItemDraftsQuery({ coachOrgId })

  const exercises = Object.values(uploadingExercises)
  const uploadErrorValues = Object.values(uploadErrors)

  if (assetDrafts?.isFiller) {
    return (
      <div className='h-full flex items-center justify-center'>
        <Spinner className='w-6 h-6 text-gray-100' />
      </div>
    )
  }

  return (
    <div className='relative flex flex-col py-6 pl-4 md:pl-10 pr-4 overflow-y-auto h-full'>
      {exercises.map((ex, index) => (
        <ExerciseListItem
          key={ex.id}
          index={index}
          uploadingEx={ex}
          createdEx={exerciseList[ex.id]}
          coachOrgId={coachOrgId}
          exVidOrientation={exVidOrientation}
        />
      ))}
      {uploadErrorValues.length > 0 && (
        <div className='mt-4 pr-4 md:pr-6' id='failedUploads'>
          <UploadErrors uploadErrors={uploadErrorValues} />
        </div>
      )}
    </div>
  )
}

function ExerciseListItem({ index, uploadingEx, createdEx, coachOrgId, exVidOrientation }) {
  const dispatch = useDispatch()
  const { createAlert } = useAlert()
  const [updateExercise] = useUpdateExerciseMutation()
  const [removeExercise] = useRemoveExerciseMutation()
  const [error, setError] = useState(null)

  const handleChangeExName = async (e) => {
    if (error) {
      return
    }

    const name = e.target.value
    dispatch(exerciseNameChanged({ exerciseId: uploadingEx.id, name }))

    if (uploadingEx.progress >= 80) {
      await updateExercise({
        coachOrgId,
        exerciseKey: uploadingEx.id,
        exercise: { name: name.trim() },
      })
    }
  }

  useEffect(() => {
    if (
      uploadingEx.progress === 100 &&
      getStandardizedName(uploadingEx.name) !== getStandardizedName(createdEx?.name)
    ) {
      updateExercise({
        coachOrgId,
        exerciseKey: uploadingEx.id,
        exercise: { name: uploadingEx.name.trim() },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingEx.progress])

  const debouncedExNameChangeDispatch = debounce((e) =>
    dispatch(exerciseNameChanged({ exerciseId: uploadingEx.id, name: e.target.value }))
  )

  return (
    <div className='relative flex items-center mb-4 last:mb-0 group'>
      <div className='pr-3'>
        <div
          css={[
            tw`relative w-14 h-14 rounded-md overflow-hidden bg-tBlack`,
            exVidOrientation === 'portrait' && tw`w-10 h-16`,
          ]}
        >
          <div className='w-full h-full flex items-center justify-center'>
            {getLandOrPortExThumb(exVidOrientation, createdEx) ? (
              <img
                src={getLandOrPortExThumb(exVidOrientation, createdEx)}
                alt={uploadingEx.name}
                className='w-full h-full object-cover'
              />
            ) : (
              <Spinner className='w-5 h-5 text-gray-100 opacity-50' fillClassName='fill-tBlack opacity-50' />
            )}
          </div>
        </div>
      </div>
      <div className='relative w-full mr-2 md:mr-3'>
        <input
          id='exNameInputId'
          name='exerciseName'
          error={error}
          placeholder='Enter exercise name'
          onChange={(e) => {
            const nameTooShort = e.target.value.length < EX_NAME_MIN_LENGTH
            const nameTooLong = e.target.value.length > EX_NAME_MAX_LENGTH
            if (nameTooShort) {
              setError(`Name must be at least ${EX_NAME_MIN_LENGTH} characters`)
            } else if (nameTooLong) {
              setError(`Name cannot be more than ${EX_NAME_MAX_LENGTH} characters`)
            } else {
              setError(null)
              debouncedExNameChangeDispatch(e)
            }
          }}
          onBlur={(e) => {
            handleChangeExName(e)
          }}
          defaultValue={uploadingEx.name}
          className='text-ellipsis bg-inherit cursor-pointer'
          css={[
            exNameInputClasses,
            error && tw`focus:ring-1 hover:ring-1 ring-1 ring-tRed focus:ring-tRed hover:ring-tRed`,
          ]}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              handleChangeExName(e)
              const exNameInputList = document.querySelectorAll('#exNameInputId')
              const nextExNameInput = exNameInputList[index + 1]
              if (nextExNameInput) {
                nextExNameInput.focus()
              } else {
                document.getElementById(uploadDoneBtnId)?.focus()
              }
            }
          }}
        />
        {error && <span className='absolute bottom-0 left-[10px] text-tRed text-xxs lowercase'>{error}</span>}
      </div>
      <div className='ml-auto flex items-center' data-testid={`uploadingExActions-${uploadingEx.id}`}>
        {uploadingEx.progress === 80 && <div className='text-gray-500 mr-3 text-xs md:text-sm'>Compressing</div>}
        {uploadingEx.progress === 100 ? (
          <CgCheckO className='text-tGreen w-8 h-8 m-1 mx-[5px]' data-testid='checkIcon' />
        ) : (
          <CircularProgress radius='21' strokeWidth='2.5' percent={uploadingEx.progress} textClassnames='text-xs' />
        )}
        <CgClose
          className='flex visible md:invisible md:group-hover:visible ml-1 text-gray-500 w-5 h-5 cursor-pointer hover:text-tRed'
          onClick={() =>
            handleCancelUpload({ uploadingExercise: uploadingEx, coachOrgId, removeExercise, dispatch, createAlert })
          }
        />
      </div>
    </div>
  )
}

async function handleCancelUpload({ uploadingExercise, coachOrgId, removeExercise, dispatch, createAlert }) {
  if (uploadingExercise.progress < 80) {
    const storageKey = uploadingExercise.storageKey
    const onCancelCb = () => dispatch(uploadingExerciseCancelled({ exerciseId: uploadingExercise.id }))
    await cancelAWSUpload(storageKey, onCancelCb)
    createAlert({ text: 'Upload canceled!', type: 'success' })
  } else if (uploadingExercise.progress === 80) {
    dispatch(uploadingExerciseCancelled({ exerciseId: uploadingExercise.id }))

    await removeExercise({
      coachOrgId,
      exerciseKey: uploadingExercise.id,
    })

    createAlert({ text: 'Upload canceled!', type: 'success' })
  } else if (uploadingExercise.progress === 100) {
    dispatch(uploadingExerciseCancelled({ exerciseId: uploadingExercise.id }))
  }
}

const exNameInputClasses = tw`
  font-medium outline-none 
  focus:ring-0 p-[10px] 
  capitalize w-full 
  border-none overflow-hidden 
  whitespace-nowrap placeholder:text-gray-400
  hover:bg-gray-100 hover:bg-opacity-70 
  focus:bg-gray-100 focus:bg-opacity-70 
  rounded text-sm md:text-base
`
