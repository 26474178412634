import 'twin.macro'
import React from 'react'
import { sortBy } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { CgTrash, CgImage } from 'react-icons/cg'
import { FiMove } from 'react-icons/fi'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { availableItemTypes } from 'common/components/AddItemButton/constants/constants'

import { actionContainerClasses } from './styles'

export function ItemCard({ id, name, description, type, previewImg, isDragging, attributes, listeners }) {
  const { getValues, setValue } = useFormContext()
  const handleDelete = () => {
    const items = getValues('items')
    const updatedItemEntries = sortBy(items, (item) => item.index)
      .filter((item) => item.id !== id)
      .map((item, index) => [item.id, { ...item, index }])
    setValue('items', Object.fromEntries(updatedItemEntries))
  }

  return (
    <div className='group flex items-center relative p-3 rounded-md border shadow-sm h-[66px] mb-3 bg-white'>
      {!isDragging && (
        <div css={actionContainerClasses} className='group-hover:visible'>
          <Tooltip content='Drag to reorder' triggerClasses='flex'>
            <button {...attributes} {...listeners} tabIndex={-1}>
              <FiMove className='cursor-move w-3.5 h-3.5 my-[3px] text-gray-500 hover:text-tGreen' />
            </button>
          </Tooltip>
          <Tooltip content='Delete' triggerClasses='ml-1.5'>
            <CgTrash
              className='cursor-pointer w-[18px] h-[18px] text-gray-500 hover:text-tRed'
              onClick={() => handleDelete()}
            />
          </Tooltip>
        </div>
      )}
      <div className='relative h-10 w-12 rounded overflow-hidden bg-tBlack'>
        {previewImg ? (
          <img src={previewImg} alt={name} className='w-full h-full object-cover' />
        ) : (
          <CgImage className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-50 w-5 h-5' />
        )}
      </div>
      <div className='ml-3 w-[84%]'>
        <div className='flex items-center justify-between'>
          <h3 className='font-bold text-sm capitalize truncate'>{name}</h3>
          <div className='p-1 rounded bg-gray-100 text-xs text-gray-400 font-semibold ml-3 leading-none'>
            {type === availableItemTypes.PROGRAMGROUP ? 'series' : type.toLowerCase()}
          </div>
        </div>
        <p className='text-sm text-gray-500 truncate mt-[1px]'>{description}</p>
      </div>
    </div>
  )
}
