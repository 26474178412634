import tw from 'twin.macro'
import React, { useState } from 'react'
import { CgArrowsScrollV } from 'react-icons/cg'

import Tag from 'common/components/Tag/Tag'
import { TableCell } from './styles'

export function TagTableCell({ tags, truncateCount, noTagsText, cellClassNames }) {
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = (e) => {
    e.stopPropagation()
    setExpanded(!expanded)
  }

  if (!tags || tags.length === 0) {
    return (
      <TableCell tw='w-3/12 truncate' className={cellClassNames}>
        <div className='text-sm text-gray-500'>{noTagsText}</div>
      </TableCell>
    )
  }

  if (expanded) {
    return (
      <TableCell tw='w-3/12 truncate' className={cellClassNames}>
        <div className='flex flex-wrap'>
          {tags.map((tag, index) => (
            <Tag key={`${tag}${index}`} text={tag} />
          ))}
        </div>
        <span
          onClick={toggleExpand}
          className='inline-flex items-center cursor-pointer text-sm text-gray-500 hover:text-tGreen transition-colors'
        >
          <CgArrowsScrollV className='w-5 h-5' />
          Collapse
        </span>
      </TableCell>
    )
  }

  return (
    <TableCell tw='w-3/12 truncate' className={cellClassNames}>
      <div>
        {tags.map((tag, index) => index <= truncateCount - 1 && <Tag key={`${tag}${index}`} text={tag} />)}
        {tags.length > truncateCount && (
          <Tag
            css={tw`cursor-pointer hover:bg-tGreen hover:text-white transition-colors`}
            onClick={toggleExpand}
            text={`+${tags.length - truncateCount} more`}
          />
        )}
      </div>
    </TableCell>
  )
}
