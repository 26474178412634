import React, { useState } from 'react'
import tw from 'twin.macro'
import { CgAdd, CgSearch } from 'react-icons/cg'
import * as Popover from '@radix-ui/react-popover'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useListenBenchmarksQuery, useSetWorkoutExerciseMutation } from 'modules/Programs/programApi'
import { getStandardizedName } from 'common/utils/stringUtils'
import { createUID } from 'common/utils/createUID'

import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { ScrollArea } from 'common/components/ScrollArea/ScrollArea'
import { Spinner } from 'common/components/Spinner/Spinner'
import BenchmarksListHeader from './BenchmarksListHeader'
import BenchmarksListItem from './BenchmarksListItem'

export default function BenchmarksList({ orgId, workoutId, partIdx, exIdx, setBenchmarkDialogInfo }) {
  const { createAlert } = useAlert()
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedBenchmarkId, setSelectedBenchmarkId] = useState(null)

  const [setExercise] = useSetWorkoutExerciseMutation()

  const { data: benchmarks } = useListenBenchmarksQuery({ orgId })
  const isBenchmarksLoading = benchmarks === undefined || benchmarks?.isLoading
  const benchmarkEntries = isBenchmarksLoading || benchmarks === null ? [] : Object.entries(benchmarks)

  const filteredBenchmarks = benchmarkEntries.filter(
    ([_, benchmark]) =>
      !benchmark?.isArchived && getStandardizedName(benchmark.name)?.includes(getStandardizedName(searchQuery))
  )

  const handleAddBenchmark = async () => {
    const benchmark = benchmarks?.[selectedBenchmarkId] || {}
    await setExercise({
      orgId,
      workoutId,
      partIdx,
      exIdx,
      exercise: {
        dndUID: createUID(),
        id: selectedBenchmarkId,
        type: 'benchmark',
        ...benchmark,
      },
    })
    createAlert({ text: 'Benchmark is added!', type: 'success' })
  }

  if (isBenchmarksLoading) {
    return (
      <div className='flex flex-col w-64 min-h-[120px]'>
        <BenchmarksListHeader
          orgId={orgId}
          setSelectedBenchmarkId={setSelectedBenchmarkId}
          selectedBenchmark={benchmarks?.[selectedBenchmarkId]}
          setBenchmarkDialogInfo={setBenchmarkDialogInfo}
        />
        <Spinner className='w-4 h-4 text-gray-100 m-auto' />
      </div>
    )
  }

  if (!filteredBenchmarks?.length && !searchQuery?.length) {
    return (
      <div className='flex flex-col w-64 min-h-[120px]'>
        <BenchmarksListHeader
          orgId={orgId}
          setSelectedBenchmarkId={setSelectedBenchmarkId}
          selectedBenchmark={benchmarks?.[selectedBenchmarkId]}
          setBenchmarkDialogInfo={setBenchmarkDialogInfo}
        />
        <p className='text-center text-sm my-auto'>There are no benchmarks</p>
        <Button size='md' variant='primary' onClick={() => setBenchmarkDialogInfo({ open: true })}>
          Create benchmark
        </Button>
      </div>
    )
  }

  return (
    <div className='flex flex-col w-64 min-h-[120px]'>
      <BenchmarksListHeader
        orgId={orgId}
        setSelectedBenchmarkId={setSelectedBenchmarkId}
        selectedBenchmark={benchmarks?.[selectedBenchmarkId]}
        setBenchmarkDialogInfo={setBenchmarkDialogInfo}
      />
      <div className='flex items-center text-tBlack mb-2'>
        <CgSearch className='w-4 h-4' />
        <input
          type='text'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder='Search...'
          className='border-0 text-sm ml-2 bg-transparent placeholder:text-tBlack placeholder:text-opacity-50 focus:ring-0 p-0'
          autoComplete='off'
        />
      </div>
      <ScrollArea css={tw`max-h-80`}>
        <div className='flex flex-col'>
          {filteredBenchmarks.length ? (
            filteredBenchmarks.map(([benchmarkId, benchmark]) => (
              <BenchmarksListItem
                key={benchmarkId}
                benchmark={benchmark}
                selectedBenchmarkId={selectedBenchmarkId}
                setSelectedBenchmarkId={setSelectedBenchmarkId}
              />
            ))
          ) : (
            <p className='text-center text-sm'>
              There is no <strong>{searchQuery}</strong> benchmark
            </p>
          )}
        </div>
      </ScrollArea>
      {Boolean(filteredBenchmarks.length) && (
        <Popover.Close
          css={[buttonBase, buttonVariants.primary, buttonSizes.md, tw`mt-3`]}
          onClick={handleAddBenchmark}
          disabled={!selectedBenchmarkId}
        >
          Add benchmark <CgAdd className='w-5 h-5 ml-2' />
        </Popover.Close>
      )}
    </div>
  )
}
