/**
 * AWS allows the following characters as S3 Object Keys readily:
 * 0-9, a-z, A-Z,
 * Special Characters: /, !, -, _, ., *, ', (, )
 *
 * Other characters require parsing (e.g., plus sign), or are invalid
 *
 * Current implementation is to only allow the guaranteed ready keys as well as the "plus sign" (+)
 * https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
 */

import { removeFileExtension } from './nameAndIdUtils'

export const VID_FILESIZE_LIMIT = 250000000 //250mb
export const IMG_FILESIZE_LIMIT = 15000000 //15mb
export const AUDIO_FILESIZE_LIMIT = 250000000 //250mb
export const NAME_MIN_LENGTH = 3
export const NAME_MAX_LENGTH = 55

export const defaultSizeLimits = {
  video: VID_FILESIZE_LIMIT,
  image: IMG_FILESIZE_LIMIT,
  audio: AUDIO_FILESIZE_LIMIT,
}

export const validS3CharRegex = /^[\w/!_.*'()+ -]+$/
export const validFirebaseCharRegex = /^[\w_*()+ -]+$/
export const validUrlRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

export function isValidUrl(url) {
  const isValidUrl = validUrlRegex.test(url)
  return isValidUrl
}

export const validHttpsUrlRegex =
  /(https:\/\/)(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i

export const acceptedFileTypes = {
  video: ['.mp4', '.mov', '.avi', '.m4v'],
  image: ['.jpg', '.png', '.jpeg'],
  audio: ['.mp3', '.wav'],
}

export const errorCodes = {
  UPLOAD_ERROR: 'UPLOAD_ERROR',
  TOO_LARGE: 'TOO_LARGE',
  INVALID_TYPE: 'INVALID_TYPE',
  INVALID_NAME: 'INVALID_NAME',
  LONG_NAME: 'LONG_NAME',
  SHORT_NAME: 'SHORT_NAME',
  INVALID_VID_DIM: 'INVALID_VID_DIM',
  IMG_TOO_NARROW: 'IMG_TOO_NARROW',
  IMG_TOO_WIDE: 'IMG_TOO_WIDE',
}

export async function validateFile({ file, fileType, customFileSizeLimit, orientation }) {
  if (fileType === 'video') {
    return await validateVideoFile({ file, customFileSizeLimit, orientation })
  } else if (fileType === 'image') {
    return await validatePreviewImg({ file })
  } else {
    return { errorCode: errorCodes.INVALID_TYPE, valid: false }
  }
}

//Browse input only accepts videos, but drag and drop accepts anything
//However need to check dimensions. Maybe also block certain characters
//Prevent Upload Size
export async function validateVideoFile({ file, customFileSizeLimit, orientation = 'landscape' }) {
  if (!file) {
    return { error: 'File did not upload correctly', valid: false }
  }

  const sizeLimit = customFileSizeLimit || VID_FILESIZE_LIMIT //250mb default. Can pass a higher custom limit for long-form videos
  if (file.size > sizeLimit) {
    return { errorCode: errorCodes.TOO_LARGE, valid: false }
  }

  const VALID_TYPES = ['video/mp4', 'video/quicktime', 'video/avi'] //quicktime is .mov

  if (VALID_TYPES.indexOf(file.type) === -1) {
    return { errorCode: errorCodes.INVALID_TYPE, valid: false }
  }

  //No longer needed as we merely strip these characters before uploading instead
  // const validFileName = validS3CharRegex.test(file.name)
  // if (!validFileName) {
  //   return { errorCode: errorCodes.INVALID_NAME, valid: false }
  // }

  const nameTooLong = removeFileExtension(file?.name)?.length > NAME_MAX_LENGTH
  if (nameTooLong) {
    return { errorCode: errorCodes.LONG_NAME, valid: false }
  }

  const nameTooShort = removeFileExtension(file?.name)?.length < NAME_MIN_LENGTH
  if (nameTooShort) {
    return { errorCode: errorCodes.SHORT_NAME, valid: false }
  }

  const ENFORCED_ASPECT_RATIO = orientation === 'landscape' ? 16 / 9 : 9 / 16
  const aspectRatioErrorThreshold = orientation === 'landscape' ? 0.1 : 0.001
  const { width, height } = await getVideoDimensions(file)
  const wToH = width / height
  const deltaFromTargetAspectRatio = wToH.toFixed(4) - ENFORCED_ASPECT_RATIO.toFixed(4)
  const validAspectRatio = Math.abs(deltaFromTargetAspectRatio) <= aspectRatioErrorThreshold

  if (!validAspectRatio) {
    return { errorCode: errorCodes.INVALID_VID_DIM, valid: false }
  }

  return { valid: true }
}

export async function validatePreviewImg({ file }) {
  if (!file) {
    return { error: 'File did not upload correctly', valid: false }
  }

  const sizeLimit = IMG_FILESIZE_LIMIT
  if (file.size > sizeLimit) {
    return { errorCode: errorCodes.TOO_LARGE, valid: false }
  }

  const VALID_TYPES = ['image/jpg', 'image/jpeg', 'image/png']

  if (VALID_TYPES.indexOf(file.type) === -1) {
    return { errorCode: errorCodes.INVALID_TYPE, valid: false }
  }

  const validFileName = validS3CharRegex.test(file.name)
  if (!validFileName) {
    return { errorCode: errorCodes.INVALID_NAME, valid: false }
  }

  const nameTooLong = removeFileExtension(file?.name)?.length > NAME_MAX_LENGTH
  if (nameTooLong) {
    return { errorCode: errorCodes.LONG_NAME, valid: false }
  }

  const nameTooShort = removeFileExtension(file?.name)?.length < NAME_MIN_LENGTH
  if (nameTooShort) {
    return { errorCode: errorCodes.SHORT_NAME, valid: false }
  }

  const W_TO_H_MIN = 1.2
  const W_TO_H_MAX = 2
  const { width, height } = await getImageDimensions(file)
  const wToH = (width / height).toFixed(4)

  if (wToH < W_TO_H_MIN) {
    return { errorCode: errorCodes.IMG_TOO_NARROW, valid: false }
  } else if (wToH > W_TO_H_MAX) {
    return { errorCode: errorCodes.IMG_TOO_WIDE, valid: false }
  } else {
    return { valid: true }
  }
}

async function getImageDimensions(file, filetype) {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file)
    const image = document.createElement('img')
    image.onload = (evt) => {
      // Revoke when you don't need the url any more to release any reference
      URL.revokeObjectURL(url)
      resolve({ height: image.height, width: image.width })
    }
    image.src = url //loads automatically
  })
}

async function getVideoDimensions(file) {
  // if (file.type === 'video/quicktime') {
  //Leaving a comment that at some point
  //some quicktime videos height and width did not seem to be loading properly
  // }

  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file)
    const video = document.createElement('video')
    video.onloadedmetadata = (evt) => {
      // Revoke when you don't need the url any more to release any reference
      URL.revokeObjectURL(url)
      resolve({ height: video.videoHeight, width: video.videoWidth })
    }

    video.onerror = (evt) => {
      console.error('Invalid Chrome video codec used')
      URL.revokeObjectURL(url)
      resolve({ height: 1080, width: 1920 })
    }

    video.src = url
    video.load() // fetches metadata
  })
}

//Takes in number of bytes
//Returns a string formatted version
export function formatBytes(bytes) {
  if (!+bytes) return '0 Bytes'

  const kb = 1000
  const mb = 1000000
  const gb = 1000000000

  if (bytes < kb) {
    return `${bytes} Bytes`
  } else if (bytes < mb) {
    return `${parseFloat(bytes / kb).toFixed(1)} KB`
  } else if (bytes < gb) {
    return `${parseFloat(bytes / mb).toFixed(1)} MB`
  } else {
    return `${parseFloat(bytes / gb).toFixed(1)} GB`
  }
}
