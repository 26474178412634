import tw from 'twin.macro'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty, isEqual } from 'lodash'
import { CgInfo } from 'react-icons/cg'

import {
  useCreateBenchmarkMutation,
  useListenBenchmarkVideoQuery,
  useListenBenchmarkThumbnailQuery,
  useSetWorkoutExerciseMutation,
} from 'modules/Programs/programApi'
import { useListenExercisesQuery } from 'modules/ExerciseLibrary/exerciseLibraryApi'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useCustomization } from 'common/contexts/Customization/useCustomization'
import { getLandOrPortExThumb, getLandOrPortExVid } from 'common/utils/exerciseUtils'
import { createUID } from 'common/utils/createUID'
import { schema } from './schema'

import { Input } from 'common/components/Input/Input'
import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { Button } from 'common/components/Button/Button'
import Tag from 'common/components/Tag/Tag'
import { Textarea } from 'common/components/Textarea/Textarea'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import AddUnitButton, { getAdjustedUnit } from 'common/components/AddUnitButton/AddUnitButton'
import ExerciseInput from './ExerciseInput'
import { CueInputAccordion } from './CueInputAccordion'

export const inputRefsSortMethod = ['name', 'instructions', 'cue', 'submit']
const InputError = tw.p`flex text-xs mt-1 text-tRed`

export default function BenchmarkForm({
  coachOrgId,
  benchmarkDialogInfo,
  setBenchmarkDialogInfo,
  workoutId,
  partIdx,
  exIdx,
}) {
  const { createAlert } = useAlert()
  const { exVidOrientation } = useCustomization()
  const [createBenchmark] = useCreateBenchmarkMutation()
  const [setExercise] = useSetWorkoutExerciseMutation()

  const { data: video } = useListenBenchmarkVideoQuery({
    orgId: coachOrgId,
    benchmarkId: benchmarkDialogInfo?.benchmark?.id,
    exVidOrientation,
  })

  const { data: thumbnail } = useListenBenchmarkThumbnailQuery({
    orgId: coachOrgId,
    benchmarkId: benchmarkDialogInfo?.benchmark?.id,
    exVidOrientation,
  })

  const [loading, setLoading] = useState(false)
  const [exerciseId, setExerciseId] = useState(null)
  const [benchmarkId, setBenchmarkId] = useState(benchmarkDialogInfo?.benchmark?.id || null)
  const [accordionValue, setAccordionValue] = useState(null)

  const isEditing = Boolean(benchmarkDialogInfo?.benchmark)

  useEffect(() => {
    if (!isEditing) {
      setBenchmarkId(createUID())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: exData = {} } = useListenExercisesQuery({ coachOrgId, exVidOrientation })
  const { exercises = {} } = exData
  const exercise = exerciseId ? exercises[exerciseId] : null

  const defaultValues = isEditing
    ? { ...benchmarkDialogInfo.benchmark }
    : {
        name: '',
        instructions: '',
        portraitVid: '',
        portraitThumbnail: '',
        video: '',
        thumbnail: '',
        audio: '',
        units: [],
        cues: [],
      }

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const {
    watch,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors,
  } = methods

  const units = watch('units')
  const formState = watch()
  const isFormDisabled = isEqual(formState, defaultValues)

  const onSubmit = async (data) => {
    if (!isEmpty(errors)) {
      return
    }

    setLoading(true)
    let benchmarkToSave = {}
    if (isEditing) {
      benchmarkToSave =
        exVidOrientation === 'portrait'
          ? { id: benchmarkId, ...data, portraitVid: video, portraitThumbnail: thumbnail }
          : { id: benchmarkId, ...data, video, thumbnail }
    } else {
      benchmarkToSave = { id: benchmarkId, ...data }
    }

    await createBenchmark({
      orgId: coachOrgId,
      benchmarkId,
      benchmark: benchmarkToSave,
    })
    if (!isEditing) {
      await setExercise({
        orgId: coachOrgId,
        workoutId,
        partIdx,
        exIdx,
        exercise: {
          dndUID: createUID(),
          id: benchmarkId,
          type: 'benchmark',
          name: data?.name,
          units: data?.units,
        },
      })
    }
    setLoading(false)
    createAlert({ text: isEditing ? 'Benchmark updated!' : 'Benchmark created!', type: 'success' })
    setBenchmarkDialogInfo(null)
  }

  const { addInputRef, clearControlledRefs, moveFocusOnKeyPress } = useFormRefsControl()
  const exNameInputRef = useRef()
  const nameRef = useRef()
  const instructionsRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    if (exerciseId || isEditing) {
      const exCues = exercise?.cues || []
      if (!isEditing) {
        addInputRef({ ref: nameRef, posIdx: 0, name: 'name', sortMethod: inputRefsSortMethod })
      }
      addInputRef({
        ref: instructionsRef,
        posIdx: isEditing ? 0 : 1,
        name: 'instructions',
        sortMethod: inputRefsSortMethod,
      })
      addInputRef({ ref: submitRef, posIdx: exCues.length, name: 'submit', sortMethod: inputRefsSortMethod })
    } else {
      clearControlledRefs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseId, isEditing])

  useEffect(() => {
    if (!exerciseId && exNameInputRef?.current) {
      setAccordionValue(null)
      setTimeout(() => {
        exNameInputRef.current.focus()
      }, 50)
    }
  }, [exerciseId])

  useEffect(() => {
    if (!isEditing) {
      if (exerciseId) {
        setValue('name', exercise.name)
        const exCues = exercise?.cues || []
        setValue('cues', exCues)
        setValue('audio', exercise?.audio || '')
        if (exVidOrientation === 'portrait') {
          setValue('portraitVid', getLandOrPortExVid(exVidOrientation, exercise) || '')
          setValue('portraitThumbnail', getLandOrPortExThumb(exVidOrientation, exercise) || '')
        } else {
          setValue('video', getLandOrPortExVid(exVidOrientation, exercise) || '')
          setValue('thumbnail', getLandOrPortExThumb(exVidOrientation, exercise) || '')
        }
      } else {
        setValue('cues', [])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseId])

  const moveInstrFocusOnKeyPress = (e) => {
    if (e.code === 'Tab' || e.code === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      if (e.shiftKey) {
        moveFocusOnKeyPress(e)
      } else if (!e.shiftKey) {
        if (accordionValue !== 'formCues') {
          setAccordionValue('formCues')
        } else {
          moveFocusOnKeyPress(e)
        }
      }
    }
  }

  if (!isEditing && !exerciseId) {
    return (
      <div className='flex flex-col px-10 py-4'>
        <ExerciseInput
          inputRef={exNameInputRef}
          exercises={exercises}
          exerciseId={exerciseId}
          setExerciseId={setExerciseId}
          exVidOrientation={exVidOrientation}
        />
        <p className='text-gray-500 text-sm'>
          Selected exercise cues, video and thumbnail will be used for the benchmark.
        </p>
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form onSubmit={handleSubmit(onSubmit)} id='benchmarkForm'>
          {isEditing ? (
            <p className='text-gray-500 text-xs px-10 mt-4'>
              Note: Please be aware that users may have already logged results for this benchmark so changes could
              confuse them. For any meaningful changes, consider making a new benchmark.
            </p>
          ) : (
            <p className='text-gray-500 text-xs px-10 mt-4'>
              Note: Once created, editing this benchmark will become limited to ensure consistent benchmark history for
              users.
            </p>
          )}
          <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
            <div className='mb-4'>
              <div className='flex items-center justify-between mb-1'>
                <label htmlFor='name' className='capitalize inline-flex cursor-pointer font-semibold text-tBlack'>
                  Name
                </label>
                <Tooltip
                  content={
                    isEditing
                      ? 'This benchmark has been published and its name cannot be changed. Create a new benchmark instead to change.'
                      : 'Once a benchmark is created, its name cannot be changed.'
                  }
                  triggerClasses='ml-1 flex items-center cursor-pointer text-gray-500 hover:text-tGreen'
                >
                  <CgInfo className='w-4 h-4 mr-1' />{' '}
                  <span className='text-sm'>{isEditing ? 'Editing disabled' : 'Important note'}</span>
                </Tooltip>
              </div>
              <Input
                name='name'
                type='text'
                placeholder='Enter name'
                register={register}
                inputRef={nameRef}
                error={errors?.name?.message}
                autoFocus={true}
                maxLength={55}
                onKeyDown={moveFocusOnKeyPress}
                disabled={isEditing}
              />
              {errors?.name && <p className='flex text-xs mt-1 text-tRed'>{errors.name?.message}</p>}
            </div>
            <label htmlFor='instructions' className='capitalize inline-flex font-semibold text-tBlack' tw='mb-1'>
              Instructions
            </label>
            <Textarea
              name='instructions'
              placeholder='Enter instructions'
              register={register}
              inputRef={instructionsRef}
              error={errors?.instructions?.message}
              maxLength={160}
              onKeyDown={moveInstrFocusOnKeyPress}
              autoFocus={isEditing}
            />
            {errors?.instructions && <p className='flex text-xs mt-1 text-tRed'>{errors.instructions.message}</p>}
          </div>
          <CueInputAccordion accordionValue={accordionValue} setAccordionValue={setAccordionValue} />
          <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
            <div className='flex items-center justify-between mb-1'>
              <label className='capitalize inline-flex font-semibold text-tBlack'>Measurement Units</label>
              <Tooltip
                content={
                  isEditing
                    ? 'This benchmark has been published and its units cannot be changed. Create a new benchmark instead to change.'
                    : 'Once a benchmark is created, its measurement units cannot be changed.'
                }
                triggerClasses='ml-1 flex items-center cursor-pointer text-gray-500 hover:text-tGreen'
              >
                <CgInfo className='w-4 h-4 mr-1' />{' '}
                <span className='text-sm'>{isEditing ? 'Editing disabled' : 'Important note'}</span>
              </Tooltip>
            </div>
            <div className='flex items-center'>
              {units.map((unit) => (
                <Tag
                  key={getAdjustedUnit(unit)}
                  text={getAdjustedUnit(unit)}
                  remove={
                    isEditing
                      ? null
                      : () =>
                          setValue(
                            'units',
                            units.filter((u) => getAdjustedUnit(u) !== getAdjustedUnit(unit))
                          )
                  }
                  css={tw`mb-0 bg-black text-white px-2.5 py-1.5`}
                />
              ))}
              {!isEditing && units.length < 2 && (
                <AddUnitButton
                  units={units}
                  handleSelect={(value) => {
                    setValue('units', [...units, value])
                    clearErrors('units')
                  }}
                  isPortalled={false}
                />
              )}
            </div>
            {errors?.units && <p className='flex text-xs mt-1 text-tRed'>{errors.units.message}</p>}
          </div>
          <div className='flex flex-col px-10 py-4'>
            <UploadInput
              name={exVidOrientation === 'landscape' ? 'video' : 'portraitVid'}
              liveUrl={isEditing ? video : getLandOrPortExVid(exVidOrientation, exercise)}
              id={isEditing ? benchmarkId : exerciseId}
              coachOrgId={coachOrgId}
              register={register}
              setValue={setValue}
              label='Video'
              uploadType={isEditing ? 'update-benchmark-video' : ''}
              setError={setError}
              clearErrors={clearErrors}
              fileType='video'
              orientation={exVidOrientation}
              showUpload={isEditing}
            />
            {errors?.video || errors?.portraitVid ? (
              <InputError>{errors?.video?.message || errors?.portraitVid?.message}</InputError>
            ) : null}
          </div>
        </form>
      </div>
      <div className='flex flex-row justify-between items-center px-4 md:px-10 py-4 bg-white shadow-t'>
        {!isEditing && (
          <Button size='lg' variant='secondary' onClick={() => setExerciseId(null)}>
            Back
          </Button>
        )}
        <Button
          form='benchmarkForm'
          type='submit'
          loading={loading}
          onClick={handleSubmit}
          size='lg'
          ref={submitRef}
          disabled={isFormDisabled}
          onKeyDown={(e) => {
            if (e.code === 'Tab' && e.shiftKey) {
              if (accordionValue !== 'formCues') {
                setAccordionValue('formCues')
                moveFocusOnKeyPress(e)
              } else {
                moveFocusOnKeyPress(e)
              }
            } else {
              moveFocusOnKeyPress(e)
            }
          }}
        >
          {isEditing ? 'Save' : 'Create'}
        </Button>
      </div>
    </FormProvider>
  )
}
