import 'twin.macro'
import * as Accordion from '@radix-ui/react-accordion'
import { useFormContext } from 'react-hook-form'
import { CgChevronDown, CgAdd } from 'react-icons/cg'
import { FiCopy } from 'react-icons/fi'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { CueInputList } from 'common/components/CueInput/CueInputList'
import { inputRefsSortMethod } from './BenchmarkForm'

export function CueInputAccordion({ accordionValue, setAccordionValue }) {
  const { createAlert } = useAlert()

  const { watch } = useFormContext()
  const formCues = watch('cues')
  const { moveFocusOnKeyPress } = useFormRefsControl()

  const copyCues = async (e) => {
    e.preventDefault()
    const toCopy = formCues.join('\n')
    await navigator.clipboard.writeText(toCopy || '')
    createAlert({ text: 'Cues copied!', type: 'success' })
  }

  return (
    <Accordion.Root
      type='single'
      collapsible='true'
      value={accordionValue}
      onValueChange={setAccordionValue}
      className='border-b border-gray-200'
    >
      <Accordion.Item value='formCues'>
        <Accordion.Header>
          <Accordion.Trigger
            type='button'
            className='flex items-center w-full group px-4 md:px-10 py-4'
            tabIndex={-1}
            aria-label='Form cues'
          >
            <label htmlFor='cue1' className='capitalize inline-flex font-semibold text-tBlack' tw='my-2'>
              Form cues
            </label>
            {Boolean(formCues?.length) ? (
              <>
                <Tooltip content='Copy all cues' triggerClasses='mr-2 ml-auto text-tBlack hover:text-tGreen'>
                  <FiCopy className='w-[18px] h-[18px]' onClick={copyCues} aria-label='copy-cues' />
                </Tooltip>
                <CgChevronDown className='w-6 h-6 group-radix-state-open:-rotate-180 transition-all' />
              </>
            ) : (
              <CgAdd className='w-[22px] h-[22px] group-radix-state-open:-rotate-180 transition-all text-tBlack ml-auto' />
            )}
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <div className='pb-4'>
            <CueInputList
              setAccordionValue={setAccordionValue}
              inputRefsSortMethod={inputRefsSortMethod}
              onEmptyInputKeyPress={(e) => {
                moveFocusOnKeyPress(e)
                setAccordionValue(null)
              }}
              moveFocusOnKeyUp={false}
            />
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}
