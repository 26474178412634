import * as yup from 'yup'

export const loginSchema = yup
  .object({
    email: yup.string().email('Please check that the email is valid').required('Please enter a valid email'),
    password: yup.string().required('No password provided'),
  })
  .required()

export const signupSchema = yup
  .object({
    firstname: yup.string().required('Please enter your first name'),
    lastname: yup.string().required('Please enter your last name'),
    email: yup.string().email('Please check that the email is valid').required('Please enter a valid email'),
    password: yup
      .string()
      .required('No password provided')
      .min(8, 'Password is too short - should be at least 8 chararacters'),
  })
  .required()
