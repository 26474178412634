import tw from 'twin.macro'
import React, { useState, useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual, pick } from 'lodash'
import { CgImage } from 'react-icons/cg'

import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { Input } from 'common/components/Input/Input'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'
import { Switch } from 'common/components/Switch/Switch'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'
import { useListenDraftWorkoutImgQuery, useUpdateWorkoutMetadataMutation } from '../programApi'
import { schema } from './schema'
import Time from 'common/components/Time/Time'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import {
  useListenDifficultyQuery,
  useListenEquipmentQuery,
  useListenInstructorsQuery,
  useListenTagsQuery,
  useUpdateDifficultyContentMutation,
  useUpdateEquipmentContentMutation,
  useUpdateInstructorsContentMutation,
  useUpdateTagsContentMutation,
} from 'modules/ContentTags/contentTagsApi'
import { getTagsList } from 'modules/ContentTags/contentTagUtils'
import { TagInputsAccordion } from 'common/components/TagInputsAccordion/TagInputsAccordion'

const DIFFICULTY_LIMIT = 10
const EQUIPMENT_LIMIT = 10
const INSTRUCTORS_LIMIT = 4
const TAGS_LIMIT = 10

const Label = tw.label`inline-flex cursor-pointer font-semibold text-tBlack mb-1`
const InputError = tw.p`flex text-xs mt-1 text-tRed`

export function WorkoutInfoForm({ coachOrgId, workout, programId }) {
  const [updateWorkoutMetaData] = useUpdateWorkoutMetadataMutation()
  const [updateInstructors] = useUpdateInstructorsContentMutation()
  const [updateDifficulty] = useUpdateDifficultyContentMutation()
  const [updateEquipment] = useUpdateEquipmentContentMutation()
  const [updateTags] = useUpdateTagsContentMutation()

  const [, setDialogOpen] = useDialog()
  const { createAlert } = useAlert()

  const { data: difficultyData } = useListenDifficultyQuery({ coachOrgId })
  const difficultyTags = getTagsList(difficultyData)

  const { data: equipmentData } = useListenEquipmentQuery({ coachOrgId })
  const equipmentTags = getTagsList(equipmentData)

  const { data: instructorsData } = useListenInstructorsQuery({ coachOrgId })
  const instructorTags = getTagsList(instructorsData)

  const { data: tagsData } = useListenTagsQuery({ coachOrgId })
  const tags = getTagsList(tagsData)

  const [loading, setLoading] = useState(false)
  const [accordionValue, setAccordionValue] = useState(null)

  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: workout.id })
  const { data: workoutImg } = useListenDraftWorkoutImgQuery({ coachOrgId, workoutId: workout.id })

  const defaultValues = {
    timeEst: workout.timeEst || '00:00:00.00',
    bottomText: workout.bottomText || '',
    previewImg: workout.previewImg || '',
    difficulty: workout?.difficulty || [],
    equipment: workout?.equipment || [],
    instructors: workout?.instructors || [],
    tags: workout?.tags || [],
    isPaid: workout.isPaid || false,
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    context: { workout },
  })

  const {
    watch,
    setValue,
    setError,
    register,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = methods

  const formState = watch()
  const isFormDisabled = isEqual(formState, defaultValues)

  const isDifficultyLimitReached = formState.difficulty.length >= DIFFICULTY_LIMIT
  const isEquipmentLimitReached = formState.equipment.length >= EQUIPMENT_LIMIT
  const isInstructorsLimitReached = formState.instructors.length >= INSTRUCTORS_LIMIT
  const isTagsLimitReached = formState.tags.length >= TAGS_LIMIT

  const onSubmit = async (data) => {
    const workoutInfoData = pick(data, [
      'timeEst',
      'bottomText',
      'difficulty',
      'equipment',
      'instructors',
      'tags',
      'isPaid',
    ])
    const cleanedIsPaid = workoutInfoData?.isPaid || null //Instead of True / False. isPaid should be True or Null, so that it adopts the programs isPaid value
    const cleanedTimeEst = workoutInfoData?.timeEst === '00:00:00.00' ? null : data?.timeEst
    const cleanedData = {
      ...workoutInfoData,
      isPaid: cleanedIsPaid,
      timeEst: cleanedTimeEst,
      // bottomText will become legacy, start transitioning to subtitle
      subtitle: workoutInfoData.bottomText,
    }
    setLoading(true)
    try {
      await updateInstructors({
        coachOrgId,
        prevInstructors: defaultValues.instructors,
        currInstructors: workoutInfoData.instructors,
        contentId: workout.id,
        programId,
        contentType: 'workout',
      })
      await updateDifficulty({
        coachOrgId,
        prevDifficulty: defaultValues.difficulty,
        currDifficulty: workoutInfoData.difficulty,
        contentId: workout.id,
        programId,
        contentType: 'workout',
      })
      await updateEquipment({
        coachOrgId,
        prevEquipment: defaultValues.equipment,
        currEquipment: workoutInfoData.equipment,
        contentId: workout.id,
        programId,
        contentType: 'workout',
      })
      await updateTags({
        coachOrgId,
        prevTags: defaultValues.tags,
        currTags: workoutInfoData.tags,
        contentId: workout.id,
        programId,
        contentType: 'workout',
      })
      await updateWorkoutMetaData({
        coachOrgId,
        workoutId: workout.id,
        metadata: cleanedData,
      })
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    createAlert({ text: 'Workout updated!', type: 'success' })
    setDialogOpen(false)
  }

  const { addInputRef, moveFocusOnKeyPress } = useFormRefsControl()
  const hrsRef = useRef()
  const minsRef = useRef()
  const bottomTextRef = useRef()
  const difficultyRef = useRef()
  const equipmentRef = useRef()
  const instructorsRef = useRef()
  const tagsRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    addInputRef({ ref: hrsRef, posIdx: 0, name: 'hours' })
    addInputRef({ ref: minsRef, posIdx: 1, name: 'minutes' })
    addInputRef({ ref: bottomTextRef, posIdx: 2, name: 'bottomText' })
    addInputRef({
      ref: difficultyRef,
      posIdx: 3,
      name: 'difficulty',
    })
    addInputRef({
      ref: equipmentRef,
      posIdx: 4,
      name: 'equipment',
    })
    addInputRef({
      ref: instructorsRef,
      posIdx: 5,
      name: 'instructors',
    })
    addInputRef({
      ref: tagsRef,
      posIdx: 6,
      name: 'tags',
    })
    addInputRef({ ref: submitRef, posIdx: 7, name: 'submit' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', (e) => moveFocusOnKeyPress(e, handleSubmit(onSubmit)))

  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form onSubmit={handleSubmit(onSubmit)} id='workoutInfoForm'>
          {workout.type !== 'single_video' && (
            <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
              <div className='mb-2'>
                <Label htmlFor='timeEst'>Workout Length</Label>
                <Time
                  name={'timeEst'}
                  value={workout.timeEst}
                  setValue={(time) => setValue('timeEst', time)}
                  hrsRef={hrsRef}
                  minsRef={minsRef}
                  timeTypes={['hrs', 'mins']}
                  autoFocus='hrs'
                  onKeyDown={(e) => {
                    if (
                      (e.code === 'Enter' || e.code === 'Tab') &&
                      e.shiftKey &&
                      accordionValue !== 'tagInputs' &&
                      isFormDisabled &&
                      e.target.name === 'hrs'
                    ) {
                      setAccordionValue('tagInputs')
                      setTimeout(() => {
                        tagsRef.current.focus()
                      }, 50)
                    }
                  }}
                />
              </div>
            </div>
          )}

          <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
            <UploadInput
              name='previewImg'
              label='Cover image'
              register={register}
              setValue={setValue}
              coachOrgId={coachOrgId}
              id={workout.id}
              uploadType='workout-image'
              liveUrl={workoutImg || workout?.previewImg}
              setError={setError}
              previewIcon={<CgImage className='!w-5 !h-5' />}
              clearErrors={clearErrors}
              fileType='image'
            />
            {errors?.previewImg ? <InputError>{errors.previewImg.message}</InputError> : null}
          </div>

          <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
            <div className='mb-2'>
              <Label htmlFor='bottomText'>Subtitle</Label>
              <Input
                name='bottomText'
                type='textarea'
                placeholder='Enter subtitle'
                register={register}
                inputRef={bottomTextRef}
                error={errors?.bottomText?.message}
                onKeyDown={(e) => {
                  if ((e.code === 'Enter' || e.code === 'Tab') && !e.shiftKey && accordionValue !== 'tagInputs') {
                    setAccordionValue('tagInputs')
                    setTimeout(() => {
                      difficultyRef.current.focus()
                    }, 50)
                  }
                }}
              />
              {errors.bottomText && <p className='flex text-xs mt-1 text-tRed'>{errors.bottomText.message}</p>}
            </div>
          </div>
          <TagInputsAccordion
            accordionValue={accordionValue}
            setAccordionValue={setAccordionValue}
            formState={formState}
            register={register}
            difficultyRef={difficultyRef}
            equipmentRef={equipmentRef}
            instructorsRef={instructorsRef}
            tagsRef={tagsRef}
            difficultyTags={difficultyTags}
            equipmentTags={equipmentTags}
            instructorTags={instructorTags}
            tags={tags}
            setValue={setValue}
            isDifficultyLimitReached={isDifficultyLimitReached}
            isEquipmentLimitReached={isEquipmentLimitReached}
            isInstructorsLimitReached={isInstructorsLimitReached}
            isTagsLimitReached={isTagsLimitReached}
          />
          <div className='flex flex-col px-10 py-4 border-gray-200'>
            <Switch name='isPaid' label='Paid members only' register={register} isChecked={formState.isPaid} />
          </div>
        </form>
      </div>
      <UpdateActions
        handleSubmit={handleSubmit}
        loading={loading}
        hideDelete={!workout.id}
        deleteDisabled={isUploadingAssets(assetDrafts)}
        disabledReason='Please wait for media upload to complete'
        disabled={isFormDisabled}
        actionText={'Save'}
        form='workoutInfoForm'
        ref={submitRef}
        onKeyDown={(e) => {
          if (
            (e.code === 'Enter' || e.code === 'Tab') &&
            e.shiftKey &&
            accordionValue !== 'tagInputs' &&
            !isFormDisabled
          ) {
            setAccordionValue('tagInputs')
            setTimeout(() => {
              tagsRef.current.focus()
            }, 50)
          }
        }}
      />
    </FormProvider>
  )
}
