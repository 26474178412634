const knownErrors = {
  access_denied: 'Incorrect username or password. Please try again.',
  user_exists: 'This email is already in use.',
}

export const getErrorDisplayMessage = (authError) => {
  let message = ''
  if (!authError || !authError.code) {
    return message
  } else if (authError?.code && knownErrors[authError.code]) {
    message = knownErrors[authError.code]
  } else {
    message = 'An error occured. Please try again or contact support@trybe.do'
  }

  return message
}
