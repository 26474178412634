import tw from 'twin.macro'
import { ScrollArea } from 'common/components/ScrollArea/ScrollArea'

export function ItemsMissingVideoContainer({ scrollAreaRef, title, children }) {
  return (
    <>
      <h3 className='font-semibold mb-1'>{title}</h3>
      <div className='mb-6 rounded-lg overflow-hidden border-gray-200 border-opacity-75 border-2'>
        <ScrollArea ref={scrollAreaRef} css={tw`max-h-52 py-2 px-3`}>
          {children}
        </ScrollArea>
      </div>
    </>
  )
}
