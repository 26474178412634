import React, { memo } from 'react'
import { getAuth, signOut } from 'firebase/auth'
import { Auth } from 'aws-amplify'
import { Button } from 'common/components/Button/Button'
import useAuth from 'modules/Auth/AuthContext'

function LogoutButton() {
  const { logout } = useAuth() //Auth0
  const auth = getAuth() //Firebase

  return (
    <Button
      variant='tertiary'
      type='button'
      className='py-y px-6 text-base'
      onClick={async () => {
        try {
          await signOut(auth) //firbase
          await Auth.signOut() //AWS
        } catch (error) {
          console.log('error logging out', error)
        }
        logout() //Auth0
      }}
    >
      Logout
    </Button>
  )
}

export default memo(LogoutButton)
