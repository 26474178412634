import React, { memo, useState } from 'react'

import { useListenCoachOrgStringsQuery, useSetActiveCoachOrgMutation } from './trybeAdminApi'

import { SuggestionBox } from 'common/components/SuggestionBox/SuggestionBox'
import DetectOutsideClick from 'common/components/DetectOutsideClick/DetectOutsideClick'

const KEY_CODES = {
  ENTER: 13,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ESCAPE: 27,
}

function CoachOrgInputPicker({ userId, coachOrgId }) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [coachOrgInputValue, setCoachOrgInputValue] = useState('')

  const [setActiveCoachOrg] = useSetActiveCoachOrgMutation()
  const { data: coachOrgs } = useListenCoachOrgStringsQuery([])
  const isLoadingCoachOrgs = !coachOrgs || coachOrgs.isLoading

  const [isInputFocused, setIsInputFocused] = useState(false)

  let suggestedCoachOrgs = coachOrgs
  if (coachOrgInputValue.length >= 1 && coachOrgs?.length) {
    suggestedCoachOrgs = coachOrgs?.filter(
      (item) => item.toLowerCase().includes(coachOrgInputValue.toLowerCase()) && !item.includes(coachOrgId)
    )
  }

  const handleCoachOrgIdInputDown = (e) => {
    e.stopPropagation()

    if (e.keyCode === KEY_CODES.ARROW_DOWN) {
      e.preventDefault()
      setSelectedIndex(suggestedCoachOrgs.length === 0 ? -1 : (selectedIndex + 1) % suggestedCoachOrgs.length)
    }

    if (e.keyCode === KEY_CODES.ARROW_UP) {
      e.preventDefault()
      setSelectedIndex(selectedIndex <= 0 ? suggestedCoachOrgs.length - 1 : selectedIndex - 1)
    }

    if (e.keyCode === KEY_CODES.ESCAPE) {
      e.preventDefault()
      setSelectedIndex(-1)
    }

    if (e.keyCode === KEY_CODES.ENTER && selectedIndex !== -1) {
      e.preventDefault()
      const [orgId] = suggestedCoachOrgs[selectedIndex].split(' ')

      setActiveCoachOrg({ userId, coachOrgId: orgId })
      setCoachOrgInputValue(suggestedCoachOrgs[selectedIndex])
      setIsInputFocused(false)
      return
    }
  }

  const handleCoachOrgIdSelect = (coachOrg) => {
    const nameAndId = coachOrg.split(' || ')
    const orgId = nameAndId[1]

    setActiveCoachOrg({ userId, coachOrgId: orgId })
    setCoachOrgInputValue(coachOrg)
    setIsInputFocused(false)
  }

  const handleChange = (e) => {
    setSelectedIndex(-1)
    setCoachOrgInputValue(e.target.value)
  }

  const activeOrgId = isLoadingCoachOrgs ? null : coachOrgs.find((item) => item.includes(coachOrgId))

  return (
    <DetectOutsideClick
      isOpen={isInputFocused}
      setIsOpen={setIsInputFocused}
      className='relative flex items-center mb-2'
    >
      <input
        type='search'
        name='search'
        id='search'
        placeholder={`${activeOrgId || 'loading...'}`}
        value={coachOrgInputValue}
        onKeyDown={handleCoachOrgIdInputDown}
        onChange={handleChange}
        className='rounded-lg p-5 mt-5 border-offWhite text-sm bg-transparent  placeholder:text-tBlack w-full placeholder:text-opacity-100 focus:placeholder:text-opacity-50 focus:ring-0'
        autoComplete='off'
        onFocus={() => {
          setCoachOrgInputValue('')
          setIsInputFocused(true)
        }}
      />
      {isInputFocused && suggestedCoachOrgs.length > 0 && (
        <SuggestionBox
          containerClasses='!w-full top-[86px]'
          suggestions={suggestedCoachOrgs}
          handleSelect={handleCoachOrgIdSelect}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      )}
    </DetectOutsideClick>
  )
}

export default memo(CoachOrgInputPicker)
