import tw from 'twin.macro'
import React, { useState } from 'react'
import { CgSearch } from 'react-icons/cg'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenCoachVideosQuery } from './videoLibraryApi'
import {
  useListenDifficultyQuery,
  useListenEquipmentQuery,
  useListenInstructorsQuery,
  useListenTagsQuery,
} from 'modules/ContentTags/contentTagsApi'

import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { VideoTable } from './VideoTable/VideoTable'
import { VideoForm } from './VideoForm/VideoForm'
import { InputGroup } from 'common/components/Input/InputGroup'
import { Input } from 'common/components/Input/Input'
import { useFilter } from 'common/components/ContentTagFilter/hooks/useFilter'
import { Filter } from 'common/components/ContentTagFilter/Filter'

function VideoLibrary() {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: videoData } = useListenCoachVideosQuery({ coachOrgId })
  const videosLoading = videoData === undefined || videoData?.isLoading
  const videos = videosLoading || videoData === null ? {} : videoData

  const { data: difficultyData } = useListenDifficultyQuery({ coachOrgId })
  const difficulty = difficultyData || {}
  const { data: equipmentData } = useListenEquipmentQuery({ coachOrgId })
  const equipment = equipmentData || {}
  const { data: instructorsData } = useListenInstructorsQuery({ coachOrgId })
  const instructors = instructorsData || {}
  const { data: tagsData } = useListenTagsQuery({ coachOrgId })
  const tags = tagsData || {}

  const tagsLoading =
    difficulty === undefined ||
    difficulty?.isLoading ||
    equipment === undefined ||
    equipment?.isLoading ||
    instructors === undefined ||
    instructors?.isLoading ||
    tags === undefined ||
    tags?.isLoading

  const dataLoading = videosLoading || tagsLoading

  const { filters, setFilters, result: filteredVideos } = useFilter({ items: videos })

  const [searchQuery, setSearchQuery] = useState('')

  const filteredVideoEntries = Object.entries(filteredVideos).filter(
    ([_, video]) => video.title && video.title.toLowerCase().includes(searchQuery.toLowerCase())
  )
  const videosToRender = Object.fromEntries(filteredVideoEntries)

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  return (
    <div className='flex flex-col min-h-screen bg-offWhite px-4 md:px-16'>
      <div className='w-full max-w-7xl mx-auto mt-6 md:mt-12'>
        <div className='flex items-start justify-between flex-col md:flex-row'>
          <h1 className='font-bold text-3xl md:text-5xl text-tBlack mr-12'>Full Length Videos</h1>
          <div className='flex-1 flex justify-center w-full md:w-1/3 my-4 md:my-0'>
            <InputGroup css={[tw`max-w-md ring-offWhite`, searchQuery.length && tw`ring-tGreen ring-2`]}>
              <CgSearch tw='w-5 h-5' />
              <Input
                type='text'
                placeholder='Search for a video'
                onChange={handleSearchChange}
                value={searchQuery}
                hideRing
              />
              {searchQuery.length && (
                <Button variant='secondary' size='md' onClick={() => setSearchQuery('')}>
                  Clear
                </Button>
              )}
            </InputGroup>
            <Filter
              filters={filters}
              setFilters={setFilters}
              difficulty={difficulty}
              equipment={equipment}
              instructors={instructors}
              tags={tags}
            />
          </div>
          <div className='flex justify-end ml-0 md:ml-6 w-full md:w-1/4'>
            <Dialog>
              <DialogTrigger
                css={[buttonBase, buttonVariants.primary, buttonSizes.lg, tw`w-full md:w-44`]}
                data-testid='upload-video-btn'
              >
                Upload video
              </DialogTrigger>
              <DialogContent header='Upload video'>
                <FormRefsControlProvider>
                  <VideoForm coachOrgId={coachOrgId} />
                </FormRefsControlProvider>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div className='flex flex-col my-12 md:my-20'>
          <VideoTable coachOrgId={coachOrgId} filteredVideos={videosToRender} loading={dataLoading} />
        </div>
      </div>
    </div>
  )
}

export default VideoLibrary
