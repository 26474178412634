import { createContext, useMemo, useEffect, useState } from 'react'

import useAuth from 'modules/Auth/AuthContext'
import { getFirebase } from 'modules/App/globalApi'

export const CustomizationContext = createContext()
CustomizationContext.displayName = 'CustomizationContext'

export function CustomizationProvider({ children }) {
  const { coachOrgId, isAuthed } = useAuth()
  const [customization, setCustomization] = useState({
    exVidOrientation: null,
    enableLandscapeExAudio: null,
  })

  useEffect(() => {
    async function fetchCustomizations() {
      const coachOrgIdLoading = coachOrgId === undefined || coachOrgId?.isLoading
      if (isAuthed && !coachOrgIdLoading) {
        const exVidOrientationPath = `coachOrgs/${coachOrgId}/mobilePreMountSettings/exVidOrientation`
        const enableLandscapeExAudioPath = `coachOrgs/${coachOrgId}/mobilePreMountSettings/enableLandscapeExAudio`

        const exVidOrientationResp = await getFirebase(exVidOrientationPath)
        const exVidOrientation = exVidOrientationResp?.data || 'landscape'
        const enableLandscapeExAudioResp = await getFirebase(enableLandscapeExAudioPath)
        const enableLandscapeExAudio = enableLandscapeExAudioResp?.data || false

        setCustomization((state) => ({ ...state, exVidOrientation, enableLandscapeExAudio }))
      }
    }
    fetchCustomizations()
  }, [isAuthed, coachOrgId])

  const contextValue = useMemo(() => customization, [customization])

  return <CustomizationContext.Provider value={contextValue}>{children}</CustomizationContext.Provider>
}
