import React, { useCallback, useState, useEffect } from 'react'
import { ReactFlow, Controls, Panel } from '@xyflow/react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import QuestionNode from './QuestionNode'
import ResultNode from './ResultNode'
import TypeSelectorNode from './TypeSelectorNode'
import UpdateQuizLayout from './UpdateQuizLayout'
import DeleteResultsChecklist from './DeleteResultsChecklist'

import { useUpdateQuestionsMutation } from '../appOnboardingQuizApi'
import { checkForDeadNodes } from '../utils/flowDataUtils'

import { getLayoutedElements } from '../utils/flowLayoutingUtils'
import { getEdges, getNodes } from '../utils/flowDataUtils'

import { setLastSelectedNodeId, setSelectedNodeId } from '../quizFlowSlice'

import '@xyflow/react/dist/style.css'

const REACT_FLOW_VIEW_TOP_SCREEN_OFFSET = 240
const REACT_FLOW_VIEW_HORIZONTAL_SCREEN_OFFSET = 16

const nodeTypes = { question: QuestionNode, result: ResultNode, selector: TypeSelectorNode }

function QuizFlowView({ questions, coachOrgId }) {
  const dispatch = useDispatch()

  const idsOfResultsToDelete = useSelector((state) => state.quizFlow.idsOfResultsToDelete)
  const selectedNodeId = useSelector((state) => state.quizFlow.selectedNodeId)
  const lastSelectedNodeId = useSelector((state) => state.quizFlow.lastSelectedNodeId)

  const [updateQuestions] = useUpdateQuestionsMutation()

  const [elkLayoutNodes, setElkLayoutNodes] = useState([])
  const [elkLayoutEdges, setElkLayoutEdges] = useState([])

  const onNodesChange = useCallback((changes) => {
    const selectChangeExists = changes.find((c) => c?.type === 'select')
    if (selectChangeExists) {
      const selectedNode = changes.find((c) => c?.type === 'select' && Boolean(c?.selected))
      const deselectedNode = changes.find((c) => c?.type === 'select' && !c?.selected)

      if (selectedNode) {
        dispatch(setLastSelectedNodeId(null))
        dispatch(setSelectedNodeId(selectedNode?.id || null))
      } else {
        dispatch(setLastSelectedNodeId(deselectedNode?.id))
        dispatch(setSelectedNodeId(null))
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const deadNodes = checkForDeadNodes(questions)
    if (!isEmpty(deadNodes)) {
      console.log('Dead nodes found, cleaning up', deadNodes)
    }
    updateQuestions({
      coachOrgId,
      update: deadNodes,
    })
  }, [questions, coachOrgId, updateQuestions])

  useEffect(() => {
    const setLayout = async () => {
      const { nodes: initialNodes, edgeIdsToShow } = getNodes({
        questions: Object.values(questions),
        coachOrgId,
        selectedNodeId,
        lastSelectedNodeId,
        idsOfResultsToDelete,
      })
      const initialEdges = getEdges({
        questions: Object.values(questions),
        edgeIdsToShow,
        nodes: initialNodes,
      })
      const { nodes: layoutedNodes, edges: layoutedEdges } = await getLayoutedElements({
        nodes: initialNodes,
        edges: initialEdges,
        noNodesSelected: selectedNodeId === null,
      })

      setElkLayoutNodes(layoutedNodes)
      setElkLayoutEdges(layoutedEdges)
    }

    setLayout()
  }, [selectedNodeId, questions, lastSelectedNodeId, idsOfResultsToDelete, coachOrgId])

  useEffect(() => {
    const selectedNodeInLayout = elkLayoutNodes.find((node) => node.id === selectedNodeId)
    // selectedNodeId still exist in state, but not in layout. The node was probably deleted.
    // Make sure to reset selectedNodeId so that layout does not result in all nodes being hidden.
    if (selectedNodeId && !selectedNodeInLayout) {
      dispatch(setSelectedNodeId(null))
    }
  }, [selectedNodeId, elkLayoutNodes, dispatch])

  return (
    <ReactFlow
      nodes={elkLayoutNodes}
      edges={elkLayoutEdges}
      onNodesChange={onNodesChange}
      style={{ backgroundColor: '#fff', borderRadius: 8 }}
      nodeTypes={nodeTypes}
      fitView
      // onInit={(reactFlow) => {
      //   const firstQuestion = reactFlow.getNodes().find((node) => !node?.data?.previousQuestionId)
      //   dispatch(setSelectedNodeId(selectedNode?.id || null))
      // }}
      proOptions={{
        hideAttribution: true,
      }}
      panOnScroll
      maxZoom={1.2}
      minZoom={0.1}
      deleteKeyCode={null}
      selectionKeyCode={null}
      multiSelectionKeyCode={null}
      onlyRenderVisibleElements={true}
      nodesDraggable={false}
      nodesConnectable={false}
    >
      <Controls
        position='top-right'
        className='bg-white border-gray-300 border shadow-md rounded-md overflow-hidden'
        style={{
          // Changing to fixed positioning due to absolute positioning bug
          // see issue here https://github.com/xyflow/xyflow/issues/3483
          position: 'fixed',
          top: REACT_FLOW_VIEW_TOP_SCREEN_OFFSET,
          right: REACT_FLOW_VIEW_HORIZONTAL_SCREEN_OFFSET,
        }}
      />
      <UpdateQuizLayout selectedNodeId={selectedNodeId} layoutedNodes={elkLayoutNodes} />
      {!isEmpty(idsOfResultsToDelete) && (
        <Panel
          position='top-left'
          style={{
            // Changing to fixed positioning due to absolute positioning bug
            // see issue here https://github.com/xyflow/xyflow/issues/3483
            position: 'fixed',
            top: REACT_FLOW_VIEW_TOP_SCREEN_OFFSET,
            left: REACT_FLOW_VIEW_HORIZONTAL_SCREEN_OFFSET,
          }}
        >
          <DeleteResultsChecklist idsOfResultsToDelete={idsOfResultsToDelete} coachOrgId={coachOrgId} />
        </Panel>
      )}
      {/* <QuizSettings /> */}
    </ReactFlow>
  )
}

export default QuizFlowView
