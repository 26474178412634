import { each } from 'lodash'

// Check if progression name exists in all progressions and is not progression that is currently being edited
export const checkProgressionNameExists = ({ progressions, progressionName, progressionKey }) => {
  if (progressionKey === progressionName || !progressions) {
    return false
  }

  return Object.keys(progressions).find((progressionKey) => progressionKey.trim().toLowerCase() === progressionName)
}

export const removeEmptyExercises = (exercises) => {
  const cleaned = []

  each(exercises, (ex) => {
    if (ex) cleaned.push(ex)
  })

  return cleaned
}
