import { createContext, useMemo, useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import auth0 from 'auth0-js'
import config from 'config/config'
import { setCredentials } from 'modules/Auth/authReducer'
import { firebaseAuth, awsAuth } from 'domains/authDomain'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { startAWS } from 'domains/amplifyConfigure'
import { Auth, Hub } from 'aws-amplify'
import { useListenUserCoachOrgIdQuery } from 'modules/Users/userApi'

const authRedirectUrl = `${window.location.protocol}//${window.location.host}`
const webAuth = new auth0.WebAuth({
  domain: config.auth0Config?.authDomain,
  clientID: config.auth0Config?.authClientID,
  audience: config.auth0Config?.authAudience,
  redirectUri: authRedirectUrl,
  responseType: 'token id_token',
  scope: 'openid profile email',
})

export const AuthContext = createContext()

export function AuthProvider({ children }) {
  // const [alerts, setAlerts] = useState([])
  const [userId, setUserId] = useState('')
  const [coachOrgId, setCoachOrgId] = useState('')
  const [userProfile, setUserProfile] = useState({})
  const { data: coachOrgFetched } = useListenUserCoachOrgIdQuery({ userId })
  const [authLoading, setAuthLoading] = useState(null)
  const [authError, setAuthError] = useState(null)
  const [awsAuthError, setAWSAuthError] = useState(null)

  const [auth0LoginStatusChecked, setAuth0LoginStatusChecked] = useState(false)
  const [fbLoginStatusChecked, setFbLoginStatusChecked] = useState(false)
  const [awsLoginStatusChecked, setAwsLoginStatusChecked] = useState(false)

  const [auth0Authed, setAuth0Authed] = useState(false)
  const [fbAuthed, setFbAuthed] = useState(false)
  const [awsAuthed, setAWSAuthed] = useState(false)
  const isAuthed = userId && fbAuthed && auth0Authed //awsAuthed removed from conditional as it tends to bug

  const location = useLocation()
  const navigate = useNavigate()
  const fbAuth = getAuth()
  const dispatch = useDispatch()

  // If we change page, reset the error state.
  useEffect(() => {
    if (authError) setAuthError(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  //Upon first login Configure AWS && Check if user is already logged in
  useEffect(() => {
    startAWS({ fetchNewToken })
    webAuth.parseHash({ hash: window.location.hash }, function (error, authResult) {
      if (error || !authResult) {
        const { pathname, search } = location
        navigate({ pathName: pathname || '/', search: search })
        checkSession()
      } else if (authResult) {
        const { accessToken, idToken, idTokenPayload } = authResult
        onAuth0Success(accessToken, idToken, idTokenPayload)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('AWS hub event', event)
      if (event === 'signIn') {
        setAWSAuthed(true)
      }
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    setCoachOrgId(coachOrgFetched)
  }, [coachOrgFetched])

  /**
   * Two-part check on AWS services
   * See if current signed in user matches userId, and if credentials are found
   * If never logged-in before, then attempt to auth with AWS
   */
  function checkAndLoginAws(userId, idToken) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log('AWS - current authenicated user found')
        Auth.currentCredentials()
          .then((creds) => {
            console.log('AWS - current credentials found')
            if (user?.name === userId) {
              setAWSAuthed(true)
            } else {
              console.log('User ID mismatch for AWS login')
              logoutAllServices()
            }
          })
          .catch(async (err) => {
            console.log('AWS credentials not found. Require login again', err)
            setAWSAuthError(err)
            // logoutAllServices() //Commenting out until AWS login error is resolved
          })
      })
      .catch(async (err) => {
        //Attempt to login with AWS federated sign in
        if (idToken) {
          const { success: awsSuccess, error: awsError } = await awsAuth(idToken, userId)
          console.log('awsSuccess:', awsSuccess, 'awsError', awsError)
          setAWSAuthError(awsError)
        } else {
          console.log('No id token found', err)
          setAWSAuthError(err)
          // logoutAllServices() //Commenting out until AWS login error is resolved
        }
      })
    setAwsLoginStatusChecked(true)
  }

  async function logoutAllServices() {
    console.log('logout all services called')
    await Auth.signOut()
    await signOut(fbAuth)
    logout() //Auth0
    setFbAuthed(false)
    setAWSAuthed(false)
    setUserId('')
  }

  async function onAuth0Success(accessToken, idToken, idTokenPayload) {
    const { sub: userId, email, picture, given_name: firstname, family_name: lastname } = idTokenPayload
    if (userId) {
      firebaseAuth(accessToken, userId)
      checkAndLoginAws(userId, idToken)
      setUserId(userId)
      setAuth0Authed(true)
      setAuth0LoginStatusChecked(true)
      setUserProfile({ userId, email, picture, firstname: firstname || '', lastname: lastname || '' })
      const { pathname, search } = location
      navigate({ pathName: pathname || '/', search: search })
    } else {
      const error = {
        code: 'userid_not_fetched',
        description: 'Unable to fetch your user Id. Please try refreshing',
      }
      setAuthError(error)
    }
  }

  /**
   * Log into Auth0 using email + password combination.
   * Use resulting tokens to auth with Firebase & AWS (Federated Sign In)
   * @param {string} email
   * @param {string} password
   */
  function login(email, password) {
    setAuthLoading(true)
    try {
      webAuth.login(
        {
          username: email,
          password,
          realm: 'Username-Password-Authentication',
        },
        (err, data) => {
          if (err) {
            setAuthError(err)
            setAuthLoading(false)
          } else if (data) {
            const { accessToken, idToken, idTokenPayload } = data
            // console.log('data', data)
            onAuth0Success(accessToken, idToken, idTokenPayload)
            setAuthLoading(false)
          }
        }
      )
    } catch (error) {
      setAuthError(error)
    }
  }

  /**
   * Sign up in Auth0 -> then login (get tokens back)
   * @param {string} email
   * @param {string} firstname
   * @param {string} lastname
   * @param {string} password
   */
  function signup(email, firstname, lastname, password) {
    setAuthLoading(true)

    try {
      webAuth.signup(
        {
          email,
          password,
          given_name: firstname,
          family_name: lastname,
          user_metadata: {
            firstname,
            lastname,
          },
          connection: 'Username-Password-Authentication',
        },
        (err, data) => {
          //Callback upon succesful signup
          if (err) {
            setAuthError(err)
            setAuthLoading(false)
          } else {
            login(email, password)
          }
        }
      )
    } catch (error) {
      console.log('error', error)
      setAuthError(error)
    }
  }

  // Call the logout endpoint and then remove the user
  // from the state.
  function logout() {
    const clientID = config.auth0Config?.authClientID
    const returnTo = window.location.origin
    webAuth.logout({ clientID, returnTo })
  }

  function fetchNewToken() {
    return new Promise((resolve, reject) => {
      webAuth.checkSession({}, (err, data) => {
        // console.log('fetch new token err', err, 'new token data in checkSession', data)
        if (err) {
          reject(err)
        } else if (data) {
          resolve(data)
        }
      })
    })
  }

  /**
   * Check against auth0 domain us user is already authenticated
   * Cannot be used with the CORS-friendly webAuth.client.login method
   * Has to be used with webAuth.login but has a lot of CORS issues in development
   */
  function checkSession() {
    console.log('check session called')
    webAuth.checkSession({}, (err, data) => {
      // console.log('check sessiondata', data)
      if (err) {
        console.log('Error checking auth session', err)
        setAuthError(err)
        setAuth0LoginStatusChecked(true)
      } else if (data) {
        const { accessToken, idToken, idTokenPayload } = data
        onAuth0Success(accessToken, idToken, idTokenPayload)
      }
    })
  }

  //Firebase auth listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(fbAuth, (user) => {
      if (user) {
        if (userId === user.uid) {
          const fbAccessToken = fbAuth?.currentUser?.accessToken

          //TO-DO replace importing userId throughout. Don't use reducer, just hold in context
          dispatch(
            setCredentials({
              userId,
              fbAccessToken,
            })
          )
          setFbAuthed(true)
        } else {
          setFbAuthed(false)
          //add Error state handling?
        }
      } else {
        //User is signed out
        setFbAuthed(false)
      }
      setFbLoginStatusChecked(true)
    })

    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly. Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      isAuthed,
      userId,
      userProfile,
      coachOrgId,
      authLoading,
      authError,
      auth0LoginStatusChecked,
      awsLoginStatusChecked,
      fbLoginStatusChecked,
      auth0Authed,
      fbAuthed,
      awsAuthed,
      awsAuthError,
      login,
      signup,
      logout,
      fetchNewToken,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isAuthed,
      userId,
      userProfile,
      coachOrgId,
      authLoading,
      authError,
      auth0LoginStatusChecked,
      awsLoginStatusChecked,
      fbLoginStatusChecked,
      auth0Authed,
      fbAuthed,
      awsAuthed,
      awsAuthError,
    ]
  )

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext)
}
