import React, { useRef, useEffect } from 'react'
import tw, { css, styled } from 'twin.macro'
import { Sortable } from 'common/components/Sortable/Sortable'
import { DayView } from './DayView'
import { weekCardContainerId } from '../constants/cssSelectorConstants'
import { useGetIsInView } from '../utils/virtualizedWeekUtils'
import { useSelector, useDispatch } from 'react-redux'
import { scrolledToPreviewWeek, fullWeekRendered } from '../programRenderingSlice'
import { dayViewMinHeight } from '../constants/dayViewStyleConstants'
import { weekRenderTypes } from '../programRenderingSlice'
import { useContainerWidth } from 'common/hooks/useContainerWidth'
import { WeekHeader } from './WeekHeader'

const WeekCard = styled.div(() => [
  tw`flex flex-col justify-start`,
  css`
    &:hover #weekheader-actions {
      display: flex;
    }
  `,
])

export function Week(props) {
  const { weekIdx, weekWorkouts, editIdx, setEditIdx, orgId, setModalWorkout, setEditWorkoutInfoModalOpen } = props
  const dispatch = useDispatch()

  const lastScrolled = useSelector((state) => state.programRendering.lastScrolled)

  const isViewingWeek = useGetIsInView({
    containerId: weekCardContainerId + weekIdx,
    weekIdx,
    lastScrolled,
  })

  const weeksToRender = useSelector((state) => state.programRendering.weeksToRender)
  const renderType = weeksToRender[weekIdx]
  const prevRenderTypeRef = useRef()

  useEffect(() => {
    const didScrollToPreviewWk = isViewingWeek && renderType === weekRenderTypes.preview
    if (didScrollToPreviewWk) {
      dispatch(scrolledToPreviewWeek({ weekIdx }))
    }

    //if week was previously preview, and is now showing the workouts
    const wkJustRenderedFully =
      prevRenderTypeRef.current === weekRenderTypes.preview && renderType === weekRenderTypes.show
    if (wkJustRenderedFully) {
      dispatch(fullWeekRendered({ weekIdx }))
    }

    prevRenderTypeRef.current = renderType
  })

  const weekWidth = useContainerWidth({
    containerId: weekCardContainerId + weekIdx,
  })

  if (!renderType) {
    return null
  } else {
    return (
      <WeekCard>
        <WeekHeader orgId={orgId} weekIdx={weekIdx} weekWorkouts={weekWorkouts} />
        <div className='flex flex-row' style={{ minHeight: dayViewMinHeight }}>
          {weekWorkouts?.map((wktIdAndDayIdx) => {
            const dayIdx = wktIdAndDayIdx.dayIdx
            const isEditing = dayIdx === editIdx
            const isEditingAny = editIdx !== null
            const isEditingSameWk = isEditingAny && Math.floor(editIdx / 7) === weekIdx

            return (
              <Sortable
                key={wktIdAndDayIdx.id}
                id={wktIdAndDayIdx.id}
                draggingClasses='opacity-50 ring-2 ring-tGreen z-10'
                withHandle={true}
                variableSize={true}
                customData={{ itemType: 'workout' }}
              >
                <DayView
                  workoutId={wktIdAndDayIdx.id}
                  dayIdx={dayIdx}
                  weekIdx={weekIdx}
                  isEditing={isEditing}
                  isEditingSameWk={isEditingSameWk}
                  setEditIdx={setEditIdx}
                  orgId={orgId}
                  renderType={weeksToRender[weekIdx]}
                  weekWidth={weekWidth}
                  setModalWorkout={setModalWorkout}
                  setEditWorkoutInfoModalOpen={setEditWorkoutInfoModalOpen}
                />
              </Sortable>
            )
          })}
        </div>
      </WeekCard>
    )
  }
}
