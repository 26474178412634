import tw from 'twin.macro'
import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import useAuth from 'modules/Auth/AuthContext'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { signupSchema } from '../schema'
import { Input } from 'common/components/Input/Input'
import { Button } from 'common/components/Button/Button'
import AuthErrorBar from './AuthErrorBar'

//Vectors for background
import mountain from 'assets/images/mountain.svg'
import line1 from 'assets/images/line-vector-1.svg'
import line2 from 'assets/images/line-vector-2.svg'
// import line3 from 'assets/images/line-vector-3.svg'
import line4 from 'assets/images/line-vector-4.svg'

const InputError = tw.p`flex text-xs mt-1 text-tRed`

function Signup(props) {
  const { authError, signup } = useAuth()

  const submitRef = useRef()

  const defaultValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(signupSchema),
  })

  const {
    watch,
    setFocus,
    register,
    formState: { errors },
    handleSubmit,
  } = methods

  const formState = watch()

  const onSubmit = async (data) => {
    const { email, firstname, lastname, password } = data

    if (email && firstname && lastname && password) {
      signup(email, firstname, lastname, password)
    }
  }

  useEffect(() => {
    setFocus('firstname')
  }, [setFocus])

  return (
    <FormProvider {...methods}>
      <div className='relative flex flex-col w-screen h-screen items-center bg-gradient-to-r from-tPurple to-tGreen overflow-hidden'>
        <div className='flex flex-col bg-white rounded-md px-4 md:px-8 py-6 shadow max-w-[460px] items-center mt-4 md:mt-24 z-10 mx-4'>
          <div className='flex flex-row py-2'>
            <h3 className='flex font-bold text-2xl'>Sign Up</h3>
          </div>
          <AuthErrorBar authError={authError} />
          <form
            onSubmit={handleSubmit(onSubmit)}
            id='signupForm'
            className='w-full'
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault() //Otherwise form autosubmits on each enter press
              }
            }}
          >
            {/* <div className='text-base text-gray-500'>Create your Trybe account now</div> */}
            <div className='flex flex-row items-start w-full my-3'>
              <div className='w-1/2'>
                <div className='text-base font-bold mb-[4px]'>First name</div>
                <Input
                  name='firstname'
                  register={register}
                  placeholder='First name'
                  type='text'
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      setFocus('lastname')
                    }
                  }}
                />
                {errors?.firstname ? <InputError>{errors.firstname.message}</InputError> : null}
              </div>
              <div className='w-1/2 ml-4'>
                <div className='text-base font-bold mb-[4px] text-tBlack'>Last name</div>
                <Input
                  name='lastname'
                  register={register}
                  placeholder='Last name'
                  type='text'
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      setFocus('email')
                    }
                  }}
                />
                {errors?.lastname ? <InputError>{errors.lastname.message}</InputError> : null}
              </div>
            </div>

            <div className='w-full my-3'>
              <div className='text-base font-bold mb-[4px]'>Email</div>
              <Input
                name='email'
                placeholder='Email'
                type='email'
                register={register}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    setFocus('password')
                  }
                }}
              />
              {errors?.email ? <InputError>{errors.email.message}</InputError> : null}
            </div>
            <div className='w-full my-3'>
              <div className='text-base font-bold mb-[4px]'>Password</div>
              <Input
                name='password'
                placeholder='Password'
                type='password'
                register={register}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    submitRef?.current?.focus()
                  }
                }}
              />
              {errors?.password ? <InputError>{errors.password.message}</InputError> : null}
            </div>
            <div className='w-full mt-6 flex flex-col items-center justify-center'>
              <div className='w-full flex flex-row items-center justify-center'>
                <div className='text-sm ml-[4px]'>
                  By clicking Sign Up, you agree to Trybe's{' '}
                  <a
                    href='https://trybe.do/terms'
                    target='_blank'
                    rel='noreferrer'
                    className='text-tGreen font-semibold'
                    tabIndex={-1}
                  >
                    terms
                  </a>
                  {' & '}
                  <a
                    href='https://www.iubenda.com/privacy-policy/7826759'
                    target='_blank'
                    rel='noreferrer'
                    className='text-tGreen font-semibold'
                    tabIndex={-1}
                  >
                    privacy policy
                  </a>
                  .
                </div>
              </div>
            </div>
          </form>
          <Button
            className='mt-6 w-full'
            type='submit'
            ref={submitRef}
            onClick={handleSubmit(onSubmit)}
            disabled={!formState?.email || !formState?.firstname || !formState?.lastname || !formState?.password}
          >
            Sign Up
          </Button>
          <div className='flex flex-row text-sm mt-2'>
            <div>Already have an account?</div>
            <Link to='/login' className='text-tGreen font-semibold ml-1'>
              Log in
            </Link>
          </div>
        </div>
        <div className='text-white text-xs absolute bottom-0 mb-4 z-10'>
          &#169;2022 Trybe, all rights reserved. Made in SF, LA and Chicago
        </div>
        <img className='absolute top-[10%] right-[15%]' src={mountain} alt='' />
        <img className='absolute top-[20%] right-[30%]' src={mountain} alt='' />
        <img className='absolute top-[30%] right-[45%]' src={mountain} alt='' />
        <img className='absolute top-[40%] right-[60%]' src={mountain} alt='' />
        <img className='absolute left-0 top-0' src={line1} alt='' />
        <img className='absolute right-0 bottom-0' src={line2} alt='' />
        <img className='absolute left-0 bottom-[20%]' src={line4} alt='' />
        <img className='absolute right-0 top-0' src={line4} alt='' />
      </div>
    </FormProvider>
  )
}

export default Signup
