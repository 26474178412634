import { each, isEmpty } from 'lodash'
import { getFirebase, updateFirebase } from 'modules/App/globalApi'
import { asyncForEach } from 'common/utils/asyncForEach'

const convertOneDraftProgram = async ({ programId, orgId, orgExercises }) => {
  console.log('=====convertOneDraftProgram programId', programId, '=====')

  const progPath = `drafts/${orgId}/programs/${programId}`

  if (!orgId) {
    console.error('convertOneDraftProgram orgId', orgId)
  }

  if (!programId) {
    console.error('convertOneDraftProgram programId', programId)
  }

  const { data: program = {} } = await getFirebase(progPath)

  asyncForEach(program.workouts, async (dayIdx, wktId) => {
    const wktPath = `drafts/${orgId}/workouts/${wktId}`
    const { data: workout = {} } = (await getFirebase(wktPath)) || {}

    const fbWktUpdates = {}

    each(workout.parts, (part = {}, partIdx) => {
      each(part.exercises, (ex = {}, exIdx) => {
        if (!ex.name) return

        const exId = legacyGetExerciseId(ex.name)
        const idExistsInExLibrary = orgExercises[exId]

        if (!ex.id && idExistsInExLibrary) {
          const updateKey = `parts/${partIdx}/exercises/${exIdx}/id`
          fbWktUpdates[updateKey] = exId
        }
      })
    })

    if (!isEmpty(fbWktUpdates)) {
      console.log('dayIdx', dayIdx, 'fbWktUpdates', fbWktUpdates)
      updateFirebase(wktPath, fbWktUpdates)
    }
  })
}

const convertOrg = async ({ orgId }) => {
  console.log('convertOrg orgId', orgId)
  const orgExercises = await fetchOrgExercises(orgId)
  const orgDraftPrograms = await fetchOrgDraftPrograms(orgId)

  asyncForEach(orgDraftPrograms, async (programMeta, programId) => {
    await convertOneDraftProgram({ programId, orgId, orgExercises })
  })
}

export const convertAllOrgs = async () => {
  const { data: allOrgs } = await getFirebase('drafts')
  asyncForEach(allOrgs, async (orgData, orgId) => {
    await convertOrg({ orgId })
  })
}

const fetchOrgDraftPrograms = async (orgId) => {
  const path = `drafts/${orgId}/programs`
  const { data: programs } = await getFirebase(path)
  return programs
}

export const fetchOrgExercises = async (orgId) => {
  const path = `coachOrgs/${orgId}/exercises`
  const { data: exercises } = await getFirebase(path)
  return exercises
}

//LEGACY legacyGetExerciseId logic
const exceptions = {
  allowS: {
    'knees to elbows': true,
  },
  allowEBeforeS: {
    burpees: true,
    lunges: true,
    'pull up negatives': true,
  },
}

//if any exercise names end in 's', ' ', or 'es', remove it.
//unless the letter before it is another 's', like 'press'
export const legacyGetExerciseId = function (exerciseName) {
  if (!exerciseName) return null

  let result = exerciseName
  result = result.trim()
  result = result.toLowerCase()

  //then look for exceptions
  if (exceptions.allowS.hasOwnProperty(result)) {
    return result
  }

  //look for s at end
  if (result.charAt(result.length - 1) === 's') {
    //if e precedes s, remove both
    if (result.charAt(result.length - 2) === 'e') {
      //unless if exercise name ends in e, don't remove that e (ie. burpee)
      if (exceptions.allowEBeforeS.hasOwnProperty(result)) {
        result = result.slice(0, result.length - 1)
      } else {
        //else remove es. ie Snatches
        result = result.slice(0, result.length - 2)
      }
    } else if (result.charAt(result.length - 2) === 's') {
      //else if s precedes s, ignore (ie. press)
    } else {
      //otherwise just remove the s
      result = result.slice(0, result.length - 1)
    }
  }

  return result
}
