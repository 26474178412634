import React from 'react'
import tw, { styled, css } from 'twin.macro'
import { FiMove } from 'react-icons/fi'
import { CgPen } from 'react-icons/cg'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { ExerciseActions } from '../exercise/ExerciseActions'

export const EditBenchmark = React.memo(
  ({
    index,
    benchmark,
    exercise,
    isEditing,
    workoutId,
    partIdx,
    orgId,
    setBenchmarkDialogInfo,
    attributes,
    listeners,
  }) => {
    const units = benchmark?.units || []

    return (
      <BenchmarkContainer>
        {!benchmark?.isArchived && (
          <div id='benchmark-actions' css={actionContainerCSS}>
            <Tooltip content='Drag within block' triggerClasses='flex'>
              <button {...attributes} {...listeners} tabIndex={-1}>
                <FiMove className='cursor-move w-3.5 h-3.5 my-[3px] text-gray-500 hover:text-tGreen' />
              </button>
            </Tooltip>
            {isEditing && (
              <Tooltip content='Edit' triggerClasses='flex ml-1.5'>
                <button tabIndex={-1} onClick={() => setBenchmarkDialogInfo({ open: true, benchmark })}>
                  <CgPen className='cursor-pointer w-4 h-4 ml-[2px] text-gray-500 hover:text-tGreen' />
                </button>
              </Tooltip>
            )}
            {isEditing && (
              <div className='flex items-center ml-1.5'>
                <ExerciseActions exercise={exercise} workoutId={workoutId} partIdx={partIdx} orgId={orgId} />
              </div>
            )}
          </div>
        )}
        {benchmark?.isArchived && isEditing && (
          <div id='benchmark-actions' css={actionContainerCSS}>
            <ExerciseActions
              exercise={exercise}
              workoutId={workoutId}
              partIdx={partIdx}
              orgId={orgId}
              actions={['delete']}
            />
          </div>
        )}
        <div className='flex items-center ml-auto'>
          {benchmark?.isArchived && (
            <div className='px-1 py-0.5 rounded border-gray-100 border-2 text-xxs text-gray-400 font-semibold mb-1 mr-1 leading-none'>
              Archived
            </div>
          )}
          <div className='p-1 rounded bg-gray-100 text-xxs text-gray-400 font-semibold mb-1 leading-none'>
            Benchmark
          </div>
        </div>
        <div className='flex items-start ml-1'>
          <div className='font-medium text-sm capitalize'>{index + 1 + '. '}</div>
          <h4 className='font-medium text-sm capitalize ml-1 w-full truncate'>{benchmark?.name}</h4>
        </div>
        {isEditing && (
          <>
            <p className='text-xs text-gray-500 ml-1 mt-1'>{benchmark?.instructions}</p>
            <div className='flex items-center ml-1 mr-auto mt-2'>
              {units.map((unit) => (
                <div
                  key={unit}
                  className='p-1 leading-none rounded bg-gray-100 text-xxs text-gray-400 font-semibold mr-1'
                >
                  {unit}
                </div>
              ))}
            </div>
          </>
        )}
      </BenchmarkContainer>
    )
  }
)

const BenchmarkContainer = styled.div(() => [
  tw`flex flex-col relative p-2 rounded-md border shadow-sm`,
  css`
    &:hover #benchmark-actions {
      visibility: visible;
    }
  `,
])

const actionContainerCSS = tw`
  invisible absolute 
  z-20 right-2 top-2 
  flex items-center 
  py-1 px-1.5 rounded-md 
  border-[1px] bg-white 
  border-gray-200 shadow-sm
`
