import React, { useState } from 'react'
import { CgArrowsScrollV } from 'react-icons/cg'

import { TableCell } from 'modules/Progressions/ProgressionTable/styles'
import { capitalizeFirstLetterOfWords } from 'common/utils/stringUtils'

export function ExerciseCell({ exercises, exerciseList, hideOnMobile }) {
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = (e) => {
    e.stopPropagation()
    setExpanded(!expanded)
  }

  if (!exercises.length) {
    return (
      <TableCell tw='w-6/12 truncate' hideOnMobile={hideOnMobile}>
        <div className='text-sm text-gray-500'>No exercises for this progression</div>
      </TableCell>
    )
  }

  if (expanded) {
    return (
      <TableCell tw='w-6/12 truncate' hideOnMobile={hideOnMobile}>
        <div className='flex flex-wrap max-w-[800px] items-center text-sm text-gray-500'>
          {exercises.map((exId, idx, arr) => (
            <span key={exId} className='mr-2'>
              {getExerciseText({ exList: exerciseList, exId, idx, lastIdx: arr.length - 1 })}
            </span>
          ))}
          <span
            onClick={toggleExpand}
            className='inline-flex items-center cursor-pointer hover:text-tGreen transition-colors'
          >
            <CgArrowsScrollV className='w-5 h-5' />
            Collapse
          </span>
        </div>
      </TableCell>
    )
  }

  return (
    <TableCell tw='w-6/12 truncate' hideOnMobile={hideOnMobile}>
      <div className='flex flex-wrap max-w-[800px] items-center text-sm text-gray-500'>
        {exercises.map(
          (exId, idx, arr) =>
            idx < 4 && (
              <span key={exId} className='mr-2'>
                {getExerciseText({ exList: exerciseList, exId, idx, lastIdx: arr.length - 1 })}
              </span>
            )
        )}
        {exercises.length > 4 && (
          <span className='cursor-pointer hover:text-tGreen transition-colors' onClick={toggleExpand}>
            {`+${exercises.length - 4} more`}
          </span>
        )}
      </div>
    </TableCell>
  )
}

function getExerciseText({ exList, exId, idx, lastIdx }) {
  const exName = exList?.[exId]?.name
  const isLastItem = lastIdx === idx

  if (exName) {
    return !isLastItem ? `${capitalizeFirstLetterOfWords(exName)}, ` : capitalizeFirstLetterOfWords(exName)
  }

  return ''
}
