import { Skeleton } from 'common/components/Skeleton/Skeleton'

function ResultLoadingCard({ selected }) {
  if (selected) {
    return (
      <div className='flex items-center relative w-full h-9 bg-white'>
        <div>
          <Skeleton className='!h-10 w-12 rounded' />
        </div>
        <div className='ml-3 w-[84%]'>
          <Skeleton className='w-2/5' />
          <Skeleton className='w-4/5 mt-2' />
        </div>
      </div>
    )
  }

  return (
    <div className='flex items-center justify-between relative w-full h-7 bg-white'>
      <Skeleton className='w-2/5' />
      <Skeleton className='w-14' />
    </div>
  )
}

export default ResultLoadingCard
