import tw from 'twin.macro'
import React, { useState } from 'react'

import { useListenExerciseQuery } from 'modules/ExerciseLibrary/exerciseLibraryApi'

import { useCustomization } from 'common/contexts/Customization/useCustomization'

import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { EmptyStateContainer } from 'common/components/EmptyStateContainer/EmptyStateContainer'
import { getLandOrPortExVid } from 'common/utils/exerciseUtils'

export function ExerciseVideo({ coachOrgId, exerciseId }) {
  const { exVidOrientation } = useCustomization()
  const { data: exercise, isLoading } = useListenExerciseQuery({ coachOrgId, exerciseId })
  const videoLoading = exercise?.isLoading || isLoading
  const exVidUrl = getLandOrPortExVid(exVidOrientation, exercise)

  const [error, setError] = useState(null)

  if (videoLoading && exVidOrientation === 'landscape') {
    return (
      <Container>
        <Skeleton css={tw`max-w-2xl my-4 h-3`} />
        <Skeleton css={tw`max-w-7xl rounded-xl w-full h-[calc(100vw/2)] max-h-[720px]`} />
      </Container>
    )
  }

  if (videoLoading && exVidOrientation === 'portrait') {
    return (
      <Container>
        <Skeleton css={tw`max-w-2xl my-4 h-3 mx-auto`} />
        <Skeleton className='!rounded-xl mx-auto !h-[calc(100vh-240px)] aspect-[1/1.9]' />
      </Container>
    )
  }

  if (error || !exVidUrl) {
    return (
      <Container>
        <EmptyStateContainer text={error} />
      </Container>
    )
  }

  if (exVidOrientation === 'portrait') {
    return (
      <Container>
        <h1 className='text-3xl md:text-5xl font-bold text-tBlack mb-4 max-w-3xl capitalize mx-auto text-center'>
          {exercise?.name}
        </h1>
        <video
          autoPlay
          controls
          className='object-cover h-[calc(100vh-240px)] aspect-[1/1.777] rounded-xl overflow-hidden mx-auto'
        >
          <source onError={() => setError('No video found')} src={exVidUrl} />
        </video>
      </Container>
    )
  }

  return (
    <Container>
      <h1 className='text-3xl md:text-5xl font-bold text-tBlack mb-4 max-w-2xl capitalize'>{exercise?.name}</h1>
      <div className='w-full rounded-xl overflow-hidden'>
        <video autoPlay controls className='object-cover w-full h-full'>
          <source onError={() => setError('No video found')} src={exVidUrl} />
        </video>
      </div>
    </Container>
  )
}

function Container({ children }) {
  return (
    <div className='flex flex-col bg-offWhite px-4 md:px-16 py-12 min-h-[calc(100vh-80px)]'>
      <div className='w-full max-w-7xl m-auto'>{children}</div>
    </div>
  )
}
