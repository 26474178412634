import React from 'react'
import tw from 'twin.macro'

import { TableCell } from 'modules/ExerciseLibrary/ExerciseTable/styles'
import { Skeleton } from 'common/components/Skeleton/Skeleton'

export function LoadingRow({ exVidOrientation }) {
  return (
    <tr className='h-28' aria-label='loading-row'>
      <TableCell tw='w-1/12 pl-4 md:pl-10'>
        <Skeleton
          css={[
            tw`w-14 md:w-20 h-14 md:h-20 rounded-lg md:rounded-xl`,
            exVidOrientation === 'portrait' && tw`w-14 md:w-16 h-20 md:h-24 rounded-lg`,
          ]}
        />
      </TableCell>
      <TableCell tw='w-7/12'>
        <Skeleton />
      </TableCell>
      <TableCell tw='w-2/12' hideOnMobile>
        <Skeleton />
      </TableCell>
      <TableCell tw='w-3/12' hideOnMobile>
        <Skeleton />
      </TableCell>
      <TableCell tw='w-3/12 truncate' hideOnMobile>
        <Skeleton />
      </TableCell>
      <td className='relative'>
        <div className='absolute top-1/2 -translate-y-1/2 right-3 inline-flex items-center justify-end bg-white group-hover:border group-hover:border-tBlack group-hover:border-opacity-20 rounded-lg group-hover:shadow-sm'></div>
      </td>
    </tr>
  )
}
