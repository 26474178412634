import * as yup from 'yup'
import {
  trimString,
  emptyStringToNull,
  checkExerciseInOtherProgressions,
  checkDuplicateExercisesInProgression,
} from 'common/utils/yupSchemaUtils'

export const EX_NAME_MIN_LENGTH = 3
export const EX_NAME_MAX_LENGTH = 55

export const schema = yup
  .object({
    // Exercise
    name: yup.string().required().min(EX_NAME_MIN_LENGTH).max(EX_NAME_MAX_LENGTH).transform(trimString),
    video: yup.string().nullable().transform(emptyStringToNull),
    thumbnail: yup.string().nullable().transform(emptyStringToNull),
    audio: yup.string().nullable().transform(emptyStringToNull),
    level: yup.number().positive().integer().min(1).max(100).nullable().transform(emptyStringToNull),
    cues: yup.array().of(yup.string()),
    tags: yup.array().of(yup.string()),
    // Progressions
    exercises: yup
      .array()
      // When creating exercise do not check for required progression exercises
      .when('$progressionKey', ([progressionKey], schema) => (progressionKey ? schema.required().min(1) : schema))
      .of(
        yup
          .string()
          .nullable()
          // .test('invalid-exercise', checkExerciseIsValid)
          .test('in-use', checkExerciseInOtherProgressions)
          .test('duplicate', checkDuplicateExercisesInProgression)
      ),
  })
  .required()
