/**
 * Initiates an instance of the firebase app & real-time database (db). Imported into the <App /> component.
 */

import config from 'config/config'
import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

const { firebaseConfig } = config
const firebaseApp = initializeApp(firebaseConfig)
const db = getDatabase(firebaseApp)

export default db
