import tw from 'twin.macro'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useReactFlow } from '@xyflow/react'
import { IoIosCloseCircle } from 'react-icons/io'
import { FiMove } from 'react-icons/fi'
import { isEmpty, sortBy } from 'lodash'

import {
  useDeleteQuizMutation,
  useUpdateQuestionMutation,
  useUpdateQuestionsMutation,
  useUpdateResultsMutation,
} from '../appOnboardingQuizApi'

import { useDebounce } from 'common/hooks/useDebounce'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'

import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { getStandardizedName } from 'common/utils/stringUtils'

import { inputActiveClasses, inputClasses } from './QuestionNode'
import { getDescendantIds } from '../utils/flowDataUtils'
import { updateQuizError } from '../quizFlowSlice'

const Answer = React.memo(
  ({
    id,
    answer,
    answers,
    questionId,
    previousQuestionId,
    coachOrgId,
    errors,
    setQuestionTitle,
    setQuestionDescription,
    isNodeCollapsed,
    attributes,
    listeners,
  }) => {
    const dispatch = useDispatch()
    const { updateNodeData, getNodes } = useReactFlow()
    const [updateQuestion] = useUpdateQuestionMutation()
    const [updateQuestions] = useUpdateQuestionsMutation()
    const [deleteQuiz] = useDeleteQuizMutation()
    const [updateResults] = useUpdateResultsMutation()

    const [answerTitle, setAnswerTitle] = useState(answer?.title)

    const debouncedAnswerTitle = useDebounce(answerTitle, 500)
    useDebouncedTextMutation({
      stateText: answerTitle,
      dbText: answer?.title,
      mutation: (args) => {
        updateQuestion(args)
        updateNodeData(questionId, {
          answers: {
            ...answers,
            [id]: {
              ...answers[id],
              title: debouncedAnswerTitle,
            },
          },
        })
      },
      debouncedStateText: debouncedAnswerTitle,
      mutationArgs: {
        coachOrgId,
        question: {
          [`answers/${id}/title`]: debouncedAnswerTitle,
        },
        questionId,
      },
    })

    useEffect(() => {
      if (answer?.title !== answerTitle) {
        setAnswerTitle(answer?.title)
      }
      // eslint-disable-next-line
    }, [answer?.title])

    const [answerDescription, setAnswerDescription] = useState(answer?.description)
    const debouncedAnswerDescription = useDebounce(answerDescription, 500)
    useDebouncedTextMutation({
      stateText: answerDescription,
      dbText: answer?.description,
      mutation: (args) => {
        updateQuestion(args)
        updateNodeData(questionId, {
          answers: {
            ...answers,
            [id]: {
              ...answers[id],
              description: debouncedAnswerDescription,
            },
          },
        })
      },
      debouncedStateText: debouncedAnswerDescription,
      mutationArgs: {
        coachOrgId,
        question: {
          [`answers/${id}/description`]: debouncedAnswerDescription,
        },
        questionId,
      },
    })

    useEffect(() => {
      if (answer?.description !== answerDescription) {
        setAnswerDescription(answer?.description)
      }
      // eslint-disable-next-line
    }, [answer?.description])

    const deleteAnswer = () => {
      let deleteQuestionIds = {}
      let resultsUpdate = {}

      if (!previousQuestionId && Object.values(answers).length === 1) {
        //Delete entire quiz node if only one answer left and is the first question
        deleteQuiz({ coachOrgId })
      } else if (answer?.resultId && answer?.result?.id) {
        //Clear out result data
        resultsUpdate[`${answer.result.id}/${questionId}/${answer?.resultId}`] = null
      } else if (answer?.nextQuestionId) {
        //Delete all descendant nodes branching out from this answer
        deleteQuestionIds[answer.nextQuestionId] = true
        const nodes = getNodes()

        // Get all descendant nodes branching out from this question answers
        const { descendantQuestionIds, descendantResultsUpdate } = getDescendantIds({
          initialIdToCheck: answer.nextQuestionId,
          nodes,
          forDeletion: true,
        })
        deleteQuestionIds = { ...deleteQuestionIds, ...descendantQuestionIds }
        resultsUpdate = { ...resultsUpdate, ...descendantResultsUpdate }
      }

      let updatedAnswers = { ...answers }
      delete updatedAnswers[id]
      sortBy(Object.values(updatedAnswers), (a) => a?.index).forEach((a, idx) => {
        updatedAnswers[a?.resultId || a?.nextQuestionId] = {
          ...updatedAnswers[a?.resultId || a?.nextQuestionId],
          index: idx,
        }
      })

      let fbQuestionsUpdate = {}
      Object.keys(deleteQuestionIds).forEach((qId) => (fbQuestionsUpdate[qId] = null))

      if (isEmpty(updatedAnswers)) {
        //Revert back to a TypeSelector node
        fbQuestionsUpdate[`${questionId}/answers`] = null
        fbQuestionsUpdate[`${questionId}/title`] = null
        fbQuestionsUpdate[`${questionId}/description`] = null
        setQuestionTitle(null) //AL Note: This may be redundant with the useEffect checks in parent component
        setQuestionDescription(null) //AL Note: This may be redundant with the useEffect checks in parent component
      } else {
        fbQuestionsUpdate[`${questionId}/answers`] = updatedAnswers
      }

      // Clear out errors for this answer if any
      if (errors?.[id]) {
        let updatedErrors = { ...errors }
        delete updatedErrors[id]
        dispatch(updateQuizError({ nodeId: questionId, errors: isEmpty(updatedErrors) ? null : updatedErrors }))
      }

      updateQuestions({
        coachOrgId,
        update: fbQuestionsUpdate,
      })

      if (!isEmpty(resultsUpdate)) {
        updateResults({ coachOrgId, update: resultsUpdate })
      }
    }

    const isLastAnswer = answer?.index === Object.values(answers).length - 1

    return (
      <div className='relative p-1.5 rounded-md border shadow-sm'>
        <div
          css={[(!isNodeCollapsed || Boolean(getStandardizedName(answerDescription)?.length)) && tw`mb-1`]}
          className='flex items-center'
        >
          <div className='font-medium text-sm capitalize w-5 ml-1'>{answer?.index + 1 + '. '}</div>
          <div className='relative w-full'>
            <input
              type='text'
              maxLength={40}
              css={[
                inputClasses,
                !isNodeCollapsed && inputActiveClasses,
                !isNodeCollapsed &&
                  !getStandardizedName(answerTitle)?.length &&
                  tw`ring-1 ring-orange-300 focus:ring-1 focus:ring-orange-300`,
                errors?.[id] && tw`ring-1 ring-red-500 focus:ring-1 focus:ring-red-500`,
              ]}
              className='text-ellipsis bg-inherit nodrag nopan'
              placeholder={isNodeCollapsed ? 'Untitled answer' : 'Answer title'}
              onChange={(e) => {
                setAnswerTitle(e.target.value)
                if (errors?.[id]) {
                  let updatedErrors = { ...errors }
                  delete updatedErrors[id]
                  dispatch(
                    updateQuizError({ nodeId: questionId, errors: isEmpty(updatedErrors) ? null : updatedErrors })
                  )
                }
              }}
              value={answerTitle}
              autoFocus={!isNodeCollapsed && isLastAnswer}
            />
            {errors?.[id] && <p className='absolute bottom-0 right-1 text-xxs text-red-500'>{errors[id]}</p>}
          </div>
        </div>
        {(!isNodeCollapsed || Boolean(getStandardizedName(answerDescription)?.length)) && (
          <input
            type='text'
            maxLength={80}
            value={answerDescription}
            placeholder='Answer description (Optional)'
            onChange={(e) => setAnswerDescription(e.target.value)}
            css={[
              inputClasses,
              !isNodeCollapsed && inputActiveClasses,
              tw`font-normal text-gray-500 hover:text-black focus:text-black`,
            ]}
            className='text-ellipsis bg-inherit nodrag nopan'
          />
        )}
        <Dialog>
          <DialogTrigger
            tabIndex={-1}
            className='invisible absolute top-1/2 -translate-y-1/2 -right-2.5 group-hover:visible hover:visible nodrag nopan'
          >
            <IoIosCloseCircle className='cursor-pointer w-5 h-5 text-gray-500 hover:text-gray-600 transition-colors bg-white rounded-full' />
          </DialogTrigger>
          <DialogContent>
            <DeleteDialogBanner
              text={`This will delete answer ${answer?.index + 1} and the whole path that branches out from it.`}
              typeToConfirm={getShouldTypeToConfirm({ answer, nodes: getNodes() })}
              handleDelete={() => deleteAnswer()}
            />
          </DialogContent>
        </Dialog>
        {Object.values(answers)?.length > 1 && (
          <button
            {...attributes}
            {...listeners}
            tabIndex={-1}
            className='invisible absolute -top-1.5 -left-1.5 group-hover:visible bg-white border shadow-sm rounded-full p-1 hover:visible z-20 cursor-move nodrag nopan'
          >
            <FiMove className='w-3 h-3 text-gray-500 hover:text-tGreen' />
          </button>
        )}
      </div>
    )
  }
)

export default Answer

function getShouldTypeToConfirm({ answer, nodes }) {
  const nextQuestion = nodes.find((node) => node.id === answer?.nextQuestionId)
  return (
    (Boolean(answer?.nextQuestionId) && !isEmpty(nextQuestion?.data?.answers)) ||
    (Boolean(answer?.resultId) && !isEmpty(answer?.result))
  )
}
