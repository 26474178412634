export const clipboardTextId = {
  part: 'clipboardActions__partText',
  workout: 'clipboardActions__workoutText',
  week: 'clipboardActions__weekText',
}

export const clipboardCounterId = {
  part: 'clipboardActions__partCounter',
  workout: 'clipboardActions__workoutCounter',
  week: 'clipboardActions__weekCounter',
}

export const weekCardContainerId = 'weekCardContainer'

export const exerciseContainerId = 'exerciseContainer'
