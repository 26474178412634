import { BiMoveVertical } from 'react-icons/bi'
import { Skeleton } from 'common/components/Skeleton/Skeleton'

export function LinkViewSkeleton() {
  return (
    <div className='relative flex h-[104px] rounded-2xl bg-white mb-3'>
      <div className='flex flex-col border-r justify-center border-tGray border-opacity-20 text-gray-500'>
        <BiMoveVertical className='w-4 h-4 mx-2' />
      </div>
      <div className='px-6 py-4 flex justify-center flex-col'>
        <Skeleton className='!w-48 mb-4' />
        <Skeleton className='!w-20' />
      </div>
    </div>
  )
}
