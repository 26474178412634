import tw from 'twin.macro'

import React, { useState, useEffect } from 'react'
import { concat, filter, difference } from 'lodash'

import { TableHead } from 'modules/ExerciseLibrary/ExerciseTable/TableHead'
import { TableBody } from 'modules/ExerciseLibrary/ExerciseTable/TableBody'
import ExerciseForm from '../ExerciseForm/ExerciseForm'

import { handleSortOfDates, handleSortNames } from 'common/utils/sortUtils'
import { Dialog, DialogContent } from 'common/components/Dialog/Dialog'
import { Pagination } from 'common/components/Pagination/Pagination'
import { usePagination } from 'common/components/Pagination/hooks/usePagination'
import { EmptyStateContainer } from 'common/components/EmptyStateContainer/EmptyStateContainer'
import { useDispatch, useSelector } from 'react-redux'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import {
  exercisesPageSelected,
  getIsAllPageExSelected,
  getIsAnyExSelected,
  multiSelectReferencesCleared,
} from '../exerciseLibrarySlice'

function ExerciseTable({ coachOrgId, exercisesToRender, loading, progressions, exerciseList, limit = 20 }) {
  const dispatch = useDispatch()

  const exerciseEntries = Object.entries(exercisesToRender)
  const totalExercises = exerciseEntries?.length
  const [sortMethod, setSortMethod] = useState('updated')
  const [sortOrder, setSortOrder] = useState('asc')
  const { setCurrentPage, currentPage, pageLimit, totalPages, offset, itemCount } = usePagination({
    itemCount: totalExercises,
    initialLimit: limit,
  })
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [modalEx, setModalEx] = useState({})

  const tableCols = [
    { title: 'name', sortOrder, setSortOrder, sortMethod, setSortMethod, hideOnMobile: false },
    { title: 'media assets', hideOnMobile: true },
    { title: 'form cues', hideOnMobile: true },
    { title: 'updated', sortOrder, setSortOrder, sortMethod, setSortMethod, hideOnMobile: true },
  ]

  const newExercises = useSelector((state) => state.exercises.newExercises)
  const sortedEx = sortExercises(exerciseEntries, sortOrder, sortMethod, newExercises)
  const currPageExercises = sortedEx.slice(offset, currentPage * pageLimit)
  const currPageExIds = currPageExercises.map(([exId]) => exId)

  // Make sure to clear multi select from/to references when
  // current page, sort method, total exercise count (filtering, searching) changes
  // so that multi select would not happen across pages
  useEffect(() => {
    dispatch(multiSelectReferencesCleared())
  }, [currentPage, sortMethod, sortOrder, dispatch, totalExercises])

  const isAnyExSelected = useSelector((state) => getIsAnyExSelected(state))
  const isAllPageExSelected = useSelector((state) => getIsAllPageExSelected(state, currPageExIds))

  return (
    <>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        itemCount={totalExercises}
        totalPages={totalPages}
      />
      <div className='overflow-x-auto'>
        <div className='align-middle inline-block min-w-full'>
          <div className='relative overflow-hidden border-b border-gray-200 rounded-xl' data-testid='table-wrapper'>
            {!loading && itemCount === 0 ? (
              <EmptyStateContainer text='No exercises' />
            ) : (
              <>
                <div css={[tw`invisible flex items-center absolute left-[11px] top-4`, isAnyExSelected && tw`visible`]}>
                  <input
                    id='selectAllEx'
                    name='selectAllEx'
                    type='checkbox'
                    onClick={(e) => {
                      e.stopPropagation()
                      dispatch(multiSelectReferencesCleared())
                      dispatch(exercisesPageSelected({ pageExIds: currPageExIds }))
                    }}
                    value={isAllPageExSelected ? 'on' : 'off'}
                    className='cursor-pointer rounded-sm border-tBlack border-opacity-20 text-tGreen focus:ring-0 w-[18px] h-[18px]'
                    checked={isAllPageExSelected ? true : false}
                    readOnly
                  />
                </div>
                <table className='min-w-full divide-y divide-gray-200 table-fixed'>
                  <TableHead columns={tableCols} />
                  <TableBody
                    isAnyExSelected={isAnyExSelected}
                    loading={loading}
                    currPageExercises={currPageExercises}
                    progressions={progressions}
                    setModalEx={setModalEx}
                    setEditDialogOpen={setEditDialogOpen}
                  />
                </table>
              </>
            )}
          </div>
        </div>
      </div>
      <Dialog open={editDialogOpen} setOpen={setEditDialogOpen}>
        <DialogContent header='Edit exercise'>
          <FormRefsControlProvider>
            <ExerciseForm
              coachOrgId={coachOrgId}
              exercise={modalEx.exercise}
              exerciseKey={modalEx.exerciseKey}
              progressions={progressions}
              exerciseList={exerciseList}
            />
          </FormRefsControlProvider>
        </DialogContent>
      </Dialog>
      <div className='mt-4'>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageLimit={pageLimit}
          itemCount={totalExercises}
          totalPages={totalPages}
          counterContainerClasses='place-self-start'
        />
      </div>
    </>
  )
}

export function sortExercises(exercises, sortOrder, sortMethod, newExercises) {
  const newExercisesData = newExercises ? filter(exercises, ([key, exercise]) => newExercises[key]?.filterToTop) : []
  const oldExercisesData = difference(exercises, newExercisesData)
  const sortedNewExercises = newExercises ? handleSortOfDates(newExercisesData, 'asc', 'createdAt') : []
  if (sortMethod === 'updated') {
    return concat(sortedNewExercises, handleSortOfDates(oldExercisesData, sortOrder, 'updatedAt'))
  } else {
    return concat(sortedNewExercises, handleSortNames(oldExercisesData, sortOrder, 'name'))
  }
}

export default ExerciseTable
