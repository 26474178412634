import React, { useState } from 'react'
import tw from 'twin.macro'
import { CgSearch } from 'react-icons/cg'

import { useSearchContentQuery } from '../helpCenterApi'

import { InputGroup } from 'common/components/Input/InputGroup'
import { Input } from 'common/components/Input/Input'
import { map, upperFirst } from 'lodash'

const SearchSuggestions = tw.ul`
  absolute 
  top-full
  left-0
  w-full
  rounded-xl
  bg-white 
  mt-2
  divide-y
  shadow-st
  overflow-y-scroll
  max-h-[500px]
`

const SearchSuggestionItem = tw.a`
  block
  px-3
  py-4
  hover:bg-gray-100
`

function SearchInput() {
  const [searchQuery, setSearchQuery] = useState('')

  const { data: searchResult } = useSearchContentQuery(searchQuery)

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const startsWithUppercase = (str) => (str[0].toUpperCase() === str[0] ? true : false)

  const formattedSugestionTitle = (title) => {
    const words = title.split(' ')
    const newWords = map(words, (word) => {
      if (word.toLowerCase().includes(searchQuery.toLowerCase())) {
        return startsWithUppercase(word)
          ? word.replace(upperFirst(searchQuery), `<b>${upperFirst(searchQuery)}</b>`)
          : word.replace(searchQuery, `<b>${searchQuery}</b>`)
      }
      return word
    })
    return newWords.join(' ')
  }

  return (
    <InputGroup
      css={[tw`relative m-auto max-w-md ring-offWhite overflow-visible`, searchQuery.length && tw`ring-tGreen ring-2`]}
    >
      <CgSearch tw='w-5 h-5' />
      <Input type='text' placeholder='Search for what you need' onChange={handleSearchChange} value={searchQuery} />
      <SearchSuggestions>
        {searchResult?.map((suggestion) => (
          <li key={`${suggestion.contentId}-${suggestion.title}`}>
            <SearchSuggestionItem
              href={`/help/${suggestion.contentId}`}
              dangerouslySetInnerHTML={{
                __html: formattedSugestionTitle(suggestion.title),
              }}
            />
          </li>
        ))}
      </SearchSuggestions>
    </InputGroup>
  )
}

export default SearchInput
