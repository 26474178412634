import { times, each, sortBy, map } from 'lodash'
import { createUID } from 'common/utils/createUID'
import { getFilledWeek } from '../programModels'
import { maybePluralize } from 'common/utils/stringUtils'
import { createPartWithNewIds } from '../handlers/commonPartHandlers'
import { updateManyWorkoutContentTags } from './contentTagUtils'

export const handlePasteWorkout = async ({
  pastedAtDayIdx,
  workoutsCopied,
  wktIdsSorted,
  orgId,
  dayIdxIsEmpty,
  maxDayIdx,
  reorderProgram,
  programId,
  createAlert,
  actionText = 'pasted',
}) => {
  const { shouldOverwriteByCopyIdx, numNewWkts } = getShouldPasteOverwrite({
    copiedWorkouts: workoutsCopied,
    pastedAtDayIdx,
    dayIdxIsEmpty,
  })

  const {
    progWkts: progWktsPasted,
    wkts: newPastedWkts,
    wktsOverwritten,
  } = createPastedWorkouts({
    copiedWorkouts: workoutsCopied,
    shouldOverwriteByCopyIdx,
    wktIdsSorted,
    pastedAtDayIdx,
  })

  const onlyEmptyWktsOverflowed = getIfOnlyEmptyWktsOverflowed({
    dayIdxIsEmpty,
    numNewWkts,
    pastedAtDayIdx,
    maxDayIdx,
  })

  //If a new week will only have empty workouts, remove those
  //to prevent adding new week
  const { emptyWktsToRemove } = getEmptyWktsToRemove({
    onlyEmptyWktsOverflowed,
    existingProgWkts: getProgramWorkouts(wktIdsSorted),
    numNewWkts,
  })

  const { reorderedProgWkts: progWktsForExistingWkts, firebaseWktsUpdate: existingWktsUpdates } =
    updateExistingWktsOrder({
      wktIdsSorted,
      pastedAtDayIdx,
      numNewWkts,
      wktsOverwritten,
      emptyWktsToRemove,
    })

  const { newEmptyDays, progWktsForNewEmptyDays } = maybeFillWeek({
    onlyEmptyWktsOverflowed,
    progWktsForExistingWkts,
    progWktsPasted,
  })

  const reorderedProgWkts = {
    ...progWktsPasted,
    ...progWktsForExistingWkts,
    ...progWktsForNewEmptyDays,
    ...emptyWktsToRemove,
  }

  const workoutsUpdate = {
    ...newPastedWkts,
    ...existingWktsUpdates,
    ...newEmptyDays,
    ...emptyWktsToRemove,
  }

  await reorderProgram({
    orgId,
    programId,
    reorderedProgramWkts: reorderedProgWkts,
    fbUpdateWkts: workoutsUpdate,
  })
  await updateManyWorkoutContentTags({ orgId, workouts: newPastedWkts, programId, updateType: 'add' })

  const workoutText = maybePluralize({ text: 'Workout', count: workoutsCopied.length })
  createAlert({ text: `${workoutText} ${actionText}!`, type: 'success' })
}

const getProgramWorkouts = (wktIdsSorted) => {
  const existingProgWkts = {}

  each(wktIdsSorted, (wktId, dayIdx) => {
    existingProgWkts[wktId] = dayIdx
  })

  return existingProgWkts
}

export const getShouldPasteOverwrite = ({ copiedWorkouts, pastedAtDayIdx, dayIdxIsEmpty = {} }) => {
  let pastedAtEmpty
  let pasteRangeAllEmpty = true

  const numCopied = copiedWorkouts.length
  times(numCopied, (idx) => {
    const dayIdx = idx + pastedAtDayIdx
    const dayIsEmpty = dayIdxIsEmpty[dayIdx]

    if (!dayIsEmpty) pasteRangeAllEmpty = false

    if (idx === 0) {
      pastedAtEmpty = dayIsEmpty
    }
  })

  const shouldOverwriteByCopyIdx = copiedWorkouts.map((_, idx) => {
    if (idx === 0) {
      if (pastedAtEmpty) {
        return true
      } else {
        return false
      }
    } else if (pasteRangeAllEmpty) {
      return true
    } else {
      return false
    }
  })

  let numNewWkts
  if (pasteRangeAllEmpty) numNewWkts = 0
  else if (pastedAtEmpty) numNewWkts = numCopied - 1
  else numNewWkts = numCopied

  return {
    shouldOverwriteByCopyIdx,
    numNewWkts,
  }
}

export const createPastedWkt = ({ copiedWkt, dayIdx, shouldOverwrite, pasteDayWorkoutId }) => {
  let title

  const includedCopy = copiedWkt.title?.toLowerCase()?.includes('copy')
  if (includedCopy || copiedWkt.type === 'rest') {
    title = copiedWkt.title
  } else if (copiedWkt.title) {
    title = copiedWkt.title + ' Copy'
  } else {
    title = ''
  }

  if (!copiedWkt.parts) {
    //trying to catch hard to reproduce bug
    console.log('ERROR: no parts in copiedWkt', copiedWkt)
  }

  const partsWithNewIds = copiedWkt.parts.map((part) => createPartWithNewIds(part))
  const workoutId = shouldOverwrite ? pasteDayWorkoutId : createUID()

  const updatedWkt = {
    ...copiedWkt,
    title,
    parts: partsWithNewIds,
    dayIdx,
    id: workoutId,
  }

  return updatedWkt
}

export const createPastedWorkouts = (args) => {
  const { copiedWorkouts, shouldOverwriteByCopyIdx, wktIdsSorted, pastedAtDayIdx } = args

  const progWkts = {}
  const wkts = {}
  const wktsOverwritten = {}

  each(copiedWorkouts, async (copiedWkt, copiedWktIdx) => {
    const pasteDayIdx = pastedAtDayIdx + copiedWktIdx
    const shouldOverwrite = shouldOverwriteByCopyIdx[copiedWktIdx]

    const pastedWkt = createPastedWkt({
      copiedWkt,
      dayIdx: pasteDayIdx,
      shouldOverwrite,
      pasteDayWorkoutId: wktIdsSorted[pasteDayIdx],
    })

    const wktId = pastedWkt.id
    progWkts[wktId] = pastedWkt.dayIdx
    wkts[wktId] = pastedWkt

    if (shouldOverwrite) {
      wktsOverwritten[wktId] = true
    }
  })

  return { progWkts, wkts, wktsOverwritten }
}

export const updateExistingWktsOrder = (args) => {
  const { wktIdsSorted, pastedAtDayIdx, numNewWkts, wktsOverwritten, emptyWktsToRemove } = args

  const reorderedProgWkts = {}
  const firebaseWktsUpdate = {}

  //go thru existing workouts, update their dayIndices
  each(wktIdsSorted, (wktId, dayIdx) => {
    const toOverwrite = wktsOverwritten[wktId]
    const toRemove = emptyWktsToRemove.hasOwnProperty(wktId)

    if (toOverwrite || toRemove) {
      //no need to update dayIdx of workouts that will be overwritten
      //prevent issue with firebase update where same key is updated twice
      return
    }

    //if dayIdx is at or past pastedAtDayIdx, move workouts down
    if (dayIdx >= pastedAtDayIdx) {
      const updatedDayIdx = dayIdx + numNewWkts
      reorderedProgWkts[wktId] = updatedDayIdx

      const fbKey = `${wktId}/dayIdx`
      firebaseWktsUpdate[fbKey] = updatedDayIdx
    } else {
      //no need to change dayIdx. Populate with existing
      reorderedProgWkts[wktId] = dayIdx
    }
  })

  return { reorderedProgWkts, firebaseWktsUpdate }
}

export const getIfOnlyEmptyWktsOverflowed = ({ dayIdxIsEmpty, numNewWkts, pastedAtDayIdx, maxDayIdx }) => {
  const maxNonemptyDayIdx = getMaxNonEmptyDayIdx(dayIdxIsEmpty)

  //see if a non-empty workout was moved to a new week
  const numWksBefore = Math.floor(maxNonemptyDayIdx / 7) + 1
  const numWksAfter = Math.floor((maxNonemptyDayIdx + numNewWkts) / 7) + 1
  const pasteAffectsMaxNonEmptyDay = pastedAtDayIdx <= maxNonemptyDayIdx
  const willExistingWktMoveToNewWk = pasteAffectsMaxNonEmptyDay && numWksAfter > numWksBefore

  //see if pasted workout lands on a new week
  const currMaxWkIdx = Math.floor(maxDayIdx / 7)
  const maxDayIdxOfPastedWkts = pastedAtDayIdx + numNewWkts
  const maxWkIdxOfLastPastedWkt = Math.floor(maxDayIdxOfPastedWkts / 7)
  const pastedWktLandsInNewWk = maxWkIdxOfLastPastedWkt > currMaxWkIdx

  //remove empty workouts if ONLY empty workouts overflowed to a new week
  const onlyEmptyWktsOverflowed = !willExistingWktMoveToNewWk && !pastedWktLandsInNewWk

  return onlyEmptyWktsOverflowed
}

export const maybeFillWeek = ({ onlyEmptyWktsOverflowed, progWktsForExistingWkts, progWktsPasted }) => {
  let newEmptyDays = {}
  let progWktsForNewEmptyDays = {}

  if (!onlyEmptyWktsOverflowed) {
    const allWkts = { ...progWktsForExistingWkts, ...progWktsPasted }
    const numWkts = Object.keys(allWkts).length
    const newWkFilledDays = numWkts % 7
    const latestWeekIdx = Math.floor(numWkts / 7)

    const { newWorkouts, idToDayIdx } = getFilledWeek(latestWeekIdx, newWkFilledDays)

    newEmptyDays = { ...newWorkouts }
    progWktsForNewEmptyDays = { ...idToDayIdx }
  }

  return { newEmptyDays, progWktsForNewEmptyDays }
}

export const getEmptyWktsToRemove = ({ onlyEmptyWktsOverflowed, existingProgWkts, numNewWkts }) => {
  const emptyWktsToRemove = {}

  if (onlyEmptyWktsOverflowed) {
    const existingWkts = { ...existingProgWkts }
    const existingWktsToSort = map(existingWkts, (dayIdx, wktId) => ({ dayIdx, wktId }))
    const existingSorted = sortBy(existingWktsToSort, (val) => val.dayIdx).reverse()

    times(numNewWkts, (idx) => {
      const wktIdToRemove = existingSorted[idx].wktId
      emptyWktsToRemove[wktIdToRemove] = null //set to null, to pass to firebase removal
    })
  }

  return { emptyWktsToRemove }
}

const getMaxNonEmptyDayIdx = (dayIdxIsEmpty) => {
  let max = 0

  each(dayIdxIsEmpty, (isDayEmpty, dayIdx) => {
    const dayIdxNum = Number(dayIdx)
    if (isDayEmpty === false && dayIdxNum > max) {
      max = dayIdxNum
    }
  })

  return max
}
