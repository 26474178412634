import React, { useEffect } from 'react'
import { CgAdd, CgRemove, CgInfo } from 'react-icons/cg'
import { useFormContext } from 'react-hook-form'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenStandaloneWorkoutsIdQuery } from '../programApi'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'

import { Switch } from 'common/components/Switch/Switch'
import { Tooltip } from 'common/components/Tooltip/Tooltip'

export function SettingsForm({ programId, onSubmit, submitRef }) {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: standaloneWktsProgramId } = useListenStandaloneWorkoutsIdQuery({ coachOrgId })

  const methods = useFormContext()
  const { register, setValue, handleSubmit, watch } = methods
  const formState = watch()

  const isStandaloneWktsProgram = standaloneWktsProgramId === programId

  // ref control
  const { addManyInputRefs, moveFocusOnKeyPress } = useFormRefsControl()

  useEffect(() => {
    addManyInputRefs([{ ref: submitRef, name: 'submit' }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', (e) => {
    moveFocusOnKeyPress(e, handleSubmit(onSubmit))
  })

  return (
    <>
      {!isStandaloneWktsProgram && (
        <div className='flex flex-col px-10 py-4 border-b border-gray-200 min-h-[64px] justify-center'>
          <div className='relative flex items-center justify-between font-semibold text-tBlack'>
            <div className='flex items-center'>
              Program repetitions
              <div className='mx-1 cursor-pointer text-gray-500 hover:text-tGreen'>
                <Tooltip
                  content={
                    <div className='flex flex-col'>
                      <span className='mb-1'>Repeats the program a specific number of times</span>
                      <span>(ex. 2-week program with 3 reps becomes a 6-week program)</span>
                    </div>
                  }
                >
                  <CgInfo className='w-4 h-4' />
                </Tooltip>
              </div>
            </div>
            <div className='ml-3 inline-flex items-center justify-between'>
              <button
                type='button'
                className='cursor-pointer text-gray-500 hover:text-tGreen disabled:text-gray-400 disabled:cursor-not-allowed'
                disabled={Number(formState.reps) === 1}
                onClick={() => setValue('reps', Number(formState.reps) - 1)}
              >
                <CgRemove className='w-5 h-5' />
              </button>
              <div className='w-7 text-center'>{formState.reps}</div>
              <button
                type='button'
                className='cursor-pointer text-gray-500 hover:text-tGreen disabled:text-gray-400 disabled:cursor-not-allowed'
                disabled={Number(formState.reps) === 16}
                onClick={() => setValue('reps', Number(formState.reps) + 1)}
              >
                <CgAdd className='w-5 h-5' />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className='flex flex-col px-10 py-4 border-gray-200'>
        <Switch name='isPaid' label='Paid members only' register={register} isChecked={formState.isPaid} />
      </div>
      {/* Feature not ready in the app yet */}
      {/* <div className='flex flex-col px-10 py-4 border-t'>
  <Switch
    name='displayByDays'
    label='Display by days'
    register={register}
    isChecked={formState.displayByDays}
  />
</div> */}
    </>
  )
}
