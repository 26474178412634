import 'twin.macro'
import React from 'react'
import { sortBy, isEmpty } from 'lodash'
import { useFormContext } from 'react-hook-form'

import { useListenCollectionLinkImgQuery, useUpdateCollectionMutation } from '../collectionsApi'

import { LinkInputCard } from 'common/components/LinkInputCard/LinkInputCard'
import { Switch } from 'common/components/Switch/Switch'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { insertManyToArray } from 'common/utils/arrayUtils'
import { removeFileExtension } from 'common/utils/fileUploading/nameAndIdUtils'

import { initialInputRefsSortMethod } from './CollectionsForm'

export function LinkCard({
  coachOrgId,
  collectionId,
  linkItems,
  item,
  linkErrors,
  setLinkErrors,
  setIsCreated,
  attributes,
  listeners,
}) {
  const { createAlert } = useAlert()
  const { getValues, setValue, watch, errors } = useFormContext()
  const { removeInputRef } = useFormRefsControl()

  const [updateCollection] = useUpdateCollectionMutation()

  const { data: previewImg } = useListenCollectionLinkImgQuery({ coachOrgId, collectionId, itemId: item?.id })

  const openInDefaultBrowser = watch(`items.${item.id}.openInDefaultBrowser`)
  const isPaid = watch(`items.${item.id}.isPaid`)
  const collectionName = watch('name')

  const handleDelete = (inputRefs) => {
    const items = getValues('items')

    const updatedItemEntries = sortBy(items, (itm) => itm.index)
      .filter((itm) => item.id !== itm.id)
      .map((itm, index) => [itm.id, { ...itm, index }])
    setValue('items', Object.fromEntries(updatedItemEntries))

    setLinkErrors((errors) => {
      let errorsUpdate = errors
      delete errorsUpdate[item?.id]
      return { ...errorsUpdate }
    })

    if (inputRefs?.length) {
      inputRefs.forEach((ref) => removeInputRef(ref))
    }
  }

  const updateCollectionOnUpload = (event) => {
    const file = event.target.files[0]
    if (file && file.name) {
      const formState = getValues()
      const collectionName = formState.name || removeFileExtension(file.name)
      updateCollection({
        coachOrgId,
        id: collectionId,
        collection: { ...formState, name: collectionName, id: collectionId, updatedAt: Date.now() },
      })

      const alertText = 'Collection saved!'
      createAlert({ text: alertText, type: 'success' })
      setIsCreated(true)
    }
  }

  const linkItemInputNames = linkItems.map((linkItem) => `resource-${linkItem.id}`)
  const refsSortMethod = insertManyToArray(initialInputRefsSortMethod, 1, linkItemInputNames)
  const hasOnlyUploadErr = Object.keys(errors || {}).every(
    (errKey) => errKey === 'introVideo' || errKey === 'previewImg'
  )
  const isLinkUploadDisabled = !collectionName || (!isEmpty(errors) && !hasOnlyUploadErr) || !isEmpty(linkErrors)
  return (
    <div className='mb-3'>
      <LinkInputCard
        index={item.index}
        resources={linkItems}
        resource={item}
        collectionId={collectionId}
        uploadType='collection-link-image'
        handleDelete={(inputRefs) => handleDelete(inputRefs)}
        setErrors={setLinkErrors}
        error={linkErrors[item.id] || {}}
        updateValue={(val) => setValue(`items.${item.id}`, val)}
        onUpload={updateCollectionOnUpload}
        previewImg={previewImg}
        coachOrgId={coachOrgId}
        refsSortMethod={refsSortMethod}
        uploadDisabled={isLinkUploadDisabled}
        attributes={attributes}
        listeners={listeners}
      >
        <div className='mb-4'>
          <Switch
            onChange={({ target: { checked } }) => {
              if (checked) {
                setValue(`items.${item.id}.openInDefaultBrowser`, false)
              } else {
                setValue(`items.${item.id}.openInDefaultBrowser`, true)
              }
            }}
            label='Link opens in-app'
            isChecked={!openInDefaultBrowser}
            tooltipText='If your link opens another app, ie. YouTube, Instagram, Telegram, etc. disable this for best user experience'
            labelContainerClasses='!text-gray-500 !text-sm !mt-1 !ml-1'
            switchOuterClasses='!w-10 !h-6'
            switchInnerClasses='!w-4 !h-4'
          />
        </div>
        <Switch
          onChange={({ target: { checked } }) => {
            setValue(`items.${item.id}.isPaid`, checked)
          }}
          label='Paid members only'
          isChecked={isPaid}
          labelContainerClasses='!text-gray-500 !text-sm !ml-1'
          switchOuterClasses='!w-10 !h-6'
          switchInnerClasses='!w-4 !h-4'
        />
      </LinkInputCard>
    </div>
  )
}
