import tw from 'twin.macro'
import { CgImage } from 'react-icons/cg'
import { Spinner } from 'common/components/Spinner/Spinner'

export function PreviewImg({ isUploading, previewImg }) {
  if (isUploading) {
    return (
      <div css={tw`relative pl-10 px-6 py-4`}>
        <div css={tw`relative w-20 h-20 rounded-xl overflow-hidden bg-tBlack`}>
          <div className='w-full h-full flex items-center justify-center'>
            <Spinner className='w-5 h-5 text-gray-100 opacity-50' fillClassName='fill-tBlack opacity-50' />
          </div>
        </div>
      </div>
    )
  }

  if (previewImg) {
    return (
      <div css={tw`relative pl-10 px-6 py-4`}>
        <div css={tw`relative w-20 h-20 rounded-xl overflow-hidden`}>
          <img src={previewImg} alt='resource' className='w-full h-full object-cover' />
        </div>
      </div>
    )
  }

  return (
    <div css={tw`relative pl-10 px-6 py-4`}>
      <div css={tw`relative w-20 h-20 rounded-xl overflow-hidden bg-tBlack`}>
        <CgImage className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-50 w-7 h-7' />
      </div>
    </div>
  )
}
