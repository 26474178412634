import tw, { styled } from 'twin.macro'
import React, { useState } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { CgTime, CgClose, CgStopwatch } from 'react-icons/cg'
import { IoIosWater } from 'react-icons/io'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import Time from 'common/components/Time/Time'
import { Button } from 'common/components/Button/Button'

export function TimerInputPopover({
  showTimerActions,
  setShowTimerActions,
  isEditingWkt,
  exercise,
  instrRef,
  updateExTimers,
}) {
  const [timeState, setTime] = useState(exercise.time || '')
  const [restState, setRest] = useState(exercise.rest || '')
  const [timerPopoverType, setTimerPopoverType] = useState(false)

  function handleKeyDown(e, timerType) {
    const timerUpdate = timerType === 'time' ? { time: timeState } : { rest: restState }
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      updateExTimers(timerUpdate)
      instrRef.current.focus()
    } else if (e.key === 'Escape') {
      e.preventDefault()
      e.stopPropagation()
      setTimerPopoverType(null)
      instrRef.current.focus()
    }
  }

  if (Boolean(timerPopoverType) || showTimerActions) {
    return (
      <ActionsContainer
        id='timer-actions-container'
        isEditing={isEditingWkt}
        isTimerPopoverOpen={Boolean(timerPopoverType)}
      >
        <Popover.Root
          open={timerPopoverType === 'time'}
          onOpenChange={(isOpen) => {
            if (isOpen) {
              setTime(exercise.time || '')
              setTimerPopoverType('time')
            } else {
              setTimerPopoverType(null)
              setShowTimerActions(true)
            }
          }}
        >
          <Popover.Trigger
            onClick={(e) => {
              e.stopPropagation()
              setTimerPopoverType('time')
            }}
            tabIndex={-1}
          >
            <Tooltip content='Set timer'>
              <CgTime className='cursor-pointer w-4 h-4 mr-1.5 text-gray-500 hover:text-tGreen' />
            </Tooltip>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className='bg-white px-2 py-2 rounded-md shadow-xl border-2 border-gray-300 z-[99]'
              align='end'
              alignOffset={-3}
              sideOffset={5}
              onClick={(e) => e.stopPropagation()} // To prevent closing of the popover when clicking any content inside the popover
              arrowPadding={12}
            >
              <Popover.Arrow offset={12} className='fill-gray-300' />
              <div className='flex items-baseline'>
                <h4 className='font-medium text-sm mb-2'>Exercise Timer</h4>
                <CgClose
                  className='w-[14px] h-[14px] ml-auto cursor-pointer text-gray-500 hover:text-tGreen'
                  onClick={(e) => {
                    e.stopPropagation()
                    setTimerPopoverType(null)
                    instrRef.current.focus()
                  }}
                />
              </div>
              <Time
                name='time'
                value={timeState || '00:01:00.00'}
                setValue={setTime}
                inputClassNames='!py-2 !h-auto !text-sm !rounded-lg'
                onKeyDown={(e) => handleKeyDown(e, 'time')}
                autoFocus='mins'
                timeTypes={['mins', 'secs']}
              />
              <Button
                size='sm'
                css={tw`w-full mt-1`}
                onClick={(e) => {
                  e.stopPropagation()
                  updateExTimers({ time: timeState, hasStopWatch: null })
                  instrRef.current.focus()
                }}
                onKeyDown={(e) => handleKeyDown(e, 'time')}
              >
                Set timer
              </Button>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
        <Popover.Root
          open={timerPopoverType === 'rest'}
          onOpenChange={(isOpen) => {
            if (isOpen) {
              setRest(exercise.rest || '')
              setTimerPopoverType('rest')
            } else {
              setTimerPopoverType(null)
              setShowTimerActions(true)
            }
          }}
        >
          <Popover.Trigger
            onClick={(e) => {
              e.stopPropagation()
              setTimerPopoverType('rest')
            }}
            tabIndex={-1}
          >
            <Tooltip content='Set rest timer'>
              <IoIosWater className='cursor-pointer w-4 h-4 mr-1.5 text-gray-500 hover:text-tGreen' />
            </Tooltip>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className='bg-white px-2 py-2 rounded-md shadow-xl border-2 border-gray-300 z-[99]'
              align='end'
              alignOffset={-9}
              sideOffset={5}
              onClick={(e) => e.stopPropagation()} // To prevent closing of the popover when clicking any content inside the popover
              arrowPadding={12}
            >
              <Popover.Arrow offset={12} className='fill-gray-300' />
              <div className='flex items-baseline'>
                <h4 className='font-medium text-sm mb-2'>Rest Timer</h4>
                <CgClose
                  className='w-[14px] h-[14px] ml-auto cursor-pointer text-gray-500 hover:text-tGreen'
                  onClick={(e) => {
                    e.stopPropagation()
                    setTimerPopoverType(null)
                    instrRef.current.focus()
                  }}
                />
              </div>
              <Time
                name='restTime'
                value={restState || '00:01:00.00'}
                setValue={setRest}
                inputClassNames='!py-2 !h-auto !text-sm !rounded-lg'
                onKeyDown={(e) => handleKeyDown(e, 'rest')}
                autoFocus='mins'
                timeTypes={['mins', 'secs']}
              />
              <Button
                size='sm'
                css={tw`w-full mt-1`}
                onClick={(e) => {
                  e.stopPropagation()
                  updateExTimers({ rest: restState })
                  instrRef.current.focus()
                }}
                onKeyDown={(e) => handleKeyDown(e, 'rest')}
              >
                Set timer
              </Button>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
        <button
          onClick={(e) => {
            e.stopPropagation()
            updateExTimers({ time: null, hasStopWatch: true })
            instrRef.current.focus()
          }}
          tabIndex={-1}
        >
          <Tooltip content='Add stopwatch'>
            <CgStopwatch className='cursor-pointer w-[18px] h-[18px] text-gray-500 hover:text-tGreen' />
          </Tooltip>
        </button>
      </ActionsContainer>
    )
  }

  return null
}

const ActionsContainer = styled.div(({ isEditing, isTimerPopoverOpen }) => [
  tw`invisible absolute z-20 
  right-0 top-[1px] flex 
  items-center py-1 px-1.5 
  rounded-md border-[1px] bg-white 
  border-gray-200 shadow-sm`,
  !isEditing && tw`!invisible`,
  isTimerPopoverOpen && tw`visible`,
])
