import React, { memo } from 'react'
import { HiDownload } from 'react-icons/hi'
import tw from 'twin.macro'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import {
  useListenCoachOrgLogoQuery,
  useSetCoachLogoMutation,
  useListenCoachOrgNameQuery,
  useListenCoachNameQuery,
  useListenCoachOrgPrimaryUIColorQuery,
} from './accountApi'
import { useListenUserProfileQuery } from 'modules/Users/userApi'

import AccountLayout from './AccountLayout'
import BrandingForm from './components/accountForms/BrandingForm'
import { Button } from 'common/components/Button/Button'
import { Spinner } from 'common/components/Spinner/Spinner'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'

import { AWSupload } from 'common/utils/fileUploading/uploadUtils'
import { createUID } from 'common/utils/createUID'

const NoLogo = tw.div`
  flex 
  justify-center 
  items-center 
  text-tGray-med 
  text-4xl 
  w-28 
  h-28 
  rounded-full 
  bg-offWhite 
  rotate-180
`

const UploadImgBtn = tw.label`
  text-sm 
  font-bold 
  text-white 
  bg-tGreen 
  rounded-lg 
  py-2.5 
  px-4 
  mr-2.5 
  cursor-pointer
  mb-2
  md:mb-0
`

function Branding() {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: logo } = useListenCoachOrgLogoQuery(coachOrgId)
  const { data: coachOrgName } = useListenCoachOrgNameQuery(coachOrgId)
  const { data: coachName } = useListenCoachNameQuery(coachOrgId)
  const { data: coachOrgPrimaryUIColor } = useListenCoachOrgPrimaryUIColorQuery(coachOrgId)

  const [setCoachLogo] = useSetCoachLogoMutation()

  const handleUploadImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const uid = createUID()

      setCoachLogo({ coachOrgId, logo: 'loading' })

      AWSupload({
        file: e.target.files[0],
        uniqueId: uid,
        coachOrgId,
        uploadType: 'logo-image',
      })
    }
  }

  const handleRemoveImage = () => {
    setCoachLogo({ coachOrgId, logo: '' })
  }

  const isLoading = !coachOrgId || !coachOrgName || !coachOrgPrimaryUIColor

  return (
    <AccountLayout>
      {isLoading ? (
        <Spinner className={'w-10 h-10 text-gray-100 m-auto'} />
      ) : (
        <>
          <div className='flex items-center relative px-2.5 mb-10' data-testid='content' aria-label='branding'>
            <Logo logo={logo} />
            <div className='flex flex-col ml-5'>
              <h3 className='text-xl font-semibold text-tBlack mb-2.5'>Logo</h3>
              <div className='flex items-start md:items-center flex-col md:flex-row'>
                <UploadImgBtn htmlFor='avatar'>Upload new picture</UploadImgBtn>
                <input className='hidden' type='file' id='avatar' accept='image/*' onChange={handleUploadImage} />
                <Button className='rounded-xl' variant='secondary' size='md' onClick={handleRemoveImage}>
                  Remove
                </Button>
              </div>
            </div>
          </div>
          <FormRefsControlProvider>
            <BrandingForm
              userId={userId}
              coachOrgId={coachOrgId}
              orgName={coachOrgName}
              coachName={coachName}
              primaryUIColor={coachOrgPrimaryUIColor}
            />
          </FormRefsControlProvider>
        </>
      )}
    </AccountLayout>
  )
}

const Logo = memo(({ logo }) => {
  if (logo === 'loading') {
    return (
      <div className='flex items-center justify-center w-28 h-28 rounded-full bg-gray-50'>
        <Spinner className={'w-8 h-8 text-gray-200'} />
      </div>
    )
  } else if (logo) {
    return <img className='w-28 h-28 rounded-full object-contain' src={logo} alt='logo' />
  } else {
    return (
      <NoLogo>
        <HiDownload />
      </NoLogo>
    )
  }
})

export default Branding
