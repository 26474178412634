import { isEmpty } from 'lodash'
import { getLinkResourceErrors } from 'common/components/LinkInputCard/utils'
import { videoTitleErrKey } from './VideoResourceCard'

export const getInvalidResources = (resources) => {
  let invalidResources = {}

  Object.entries(resources).forEach(([resourceId, resource]) => {
    if (resource.type === 'video' && !resource.videoId) {
      invalidResources[resourceId] = { [videoTitleErrKey]: 'Video is required' }
    } else if (resource.type === 'link') {
      const linkResourceErrors = getLinkResourceErrors(resource)
      if (!isEmpty(linkResourceErrors)) {
        invalidResources[resourceId] = linkResourceErrors
      } else {
        delete invalidResources[resourceId]
      }
    }
  })

  return invalidResources
}
