import tw from 'twin.macro'
import { each } from 'lodash'
import { useDispatch } from 'react-redux'
import { CgSoftwareUpload } from 'react-icons/cg'

import { Button } from 'common/components/Button/Button'

import { useUpdateExercisesMutation } from '../exerciseLibraryApi'
import { uploadingExercisesCleared } from '../uploadingExercisesSlice'
import { initialFilterState } from '../Filter/constants'
import { isEmpty } from 'lodash'

export const uploadDoneBtnId = 'uploadDoneBtnId'

export function UploadDialogActions({
  uploadingExercises,
  coachOrgId,
  allExFinishedUploading,
  setFilters,
  setUploadDialogOpen,
  setUploadErrors,
  uploadErrors,
}) {
  const dispatch = useDispatch()
  const [updateExercises, { isLoading }] = useUpdateExercisesMutation()

  const handleSubmitBulkUpload = async () => {
    const fbExUpdate = {}

    each(uploadingExercises, (ex) => {
      fbExUpdate[`${ex.id}/name`] = ex.name
    })

    await updateExercises({ coachOrgId, exercises: fbExUpdate })
    dispatch(uploadingExercisesCleared())
    setUploadErrors({})
  }

  if (isEmpty(uploadingExercises) && !isEmpty(uploadErrors)) {
    return (
      <div
        className='flex items-center justify-end px-4 md:px-10 py-4 bg-white shadow-t z-10'
        data-testid='uploadDialogActionsContainer'
      >
        <Button size='md' onClick={() => setUploadErrors({})}>
          Upload again
        </Button>
      </div>
    )
  }

  if (!allExFinishedUploading) {
    return null
  }

  return (
    <div
      className='flex items-center px-4 md:px-10 py-4 bg-white shadow-t z-10'
      data-testid='uploadDialogActionsContainer'
    >
      <div className='hidden md:block'>
        <Button
          disabled={isLoading}
          variant='secondary'
          size='md'
          css={tw`mr-2 disabled:opacity-70 disabled:bg-gray-100 bg-gray-100`}
          onClick={() => {
            setFilters(() => ({ ...initialFilterState, 'recently-created': true }))
            setUploadDialogOpen(false)
          }}
        >
          Show recently uploaded
        </Button>
      </div>
      <div className='flex items-center ml-auto'>
        <Button
          disabled={isLoading}
          size='md'
          css={tw`mr-2 text-tGreen disabled:opacity-70 disabled:bg-transparent bg-transparent hover:bg-tGreen hover:bg-opacity-10`}
          onClick={() => {
            handleSubmitBulkUpload()
          }}
        >
          <CgSoftwareUpload className='w-5 h-5 mr-2' /> Upload more
        </Button>
        <Button
          id={uploadDoneBtnId}
          loading={isLoading}
          size='md'
          onClick={async () => {
            await handleSubmitBulkUpload()
            setUploadDialogOpen(false)
          }}
        >
          Done
        </Button>
      </div>
    </div>
  )
}
