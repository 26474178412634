import { useListenDraftResultsQuery, useListenPublishedResultsQuery } from '../appOnboardingQuizApi'
import { getQuizResultExists } from '../utils/flowDataUtils'

function useQuizResultsExists({ coachOrgId, itemIds }) {
  const { data: publishedQuizResults } = useListenPublishedResultsQuery({ coachOrgId })
  const { data: draftQuizResults } = useListenDraftResultsQuery({ coachOrgId })

  const isQuizResultsLoading =
    publishedQuizResults === undefined ||
    publishedQuizResults?.isLoading ||
    draftQuizResults === undefined ||
    draftQuizResults?.isLoading
  const quizResultExists =
    !isQuizResultsLoading &&
    (getQuizResultExists({ itemIds, quizResults: draftQuizResults }) ||
      getQuizResultExists({ itemIds, quizResults: publishedQuizResults }))

  return { quizResultExists, isQuizResultsLoading }
}

export default useQuizResultsExists
