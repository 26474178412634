import { globalApi, listenFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'

export const layoutApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenTabs: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/explore/tabs`, api)
      },
    }),
    listenTabContent: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId, tabId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/explore/tabContent/${tabId}`, api)
      },
    }),
    listenAllTabContent: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/explore/tabContent`, api)
      },
    }),
    listenProgramGroups: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/programGroups`, api)
      },
    }),
    listenProgramWorkouts: build.query({
      queryFn: ({ programId }) => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ programId }, api) => {
        listenFirebase(`programs/${programId}/workoutsV2`, api)
      },
    }),
    listenCoachPrograms: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/programs`, api)
      },
    }),
    listenProgram: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ programId }, api) => {
        listenFirebase(`programs/${programId}`, api)
      },
    }),
    updateTabs: build.mutation({
      queryFn: async ({ coachOrgId, tabs }) => {
        const path = `coachOrgs/${coachOrgId}/explore/tabs`
        const { data, error } = await updateFirebase(path, tabs)
        return { data, error }
      },
    }),
    setTabContent: build.mutation({
      queryFn: async ({ coachOrgId, tabContent, tabId }) => {
        const path = `coachOrgs/${coachOrgId}/explore/tabContent/${tabId}`
        const { data, error } = await setFirebase(path, tabContent)
        return { data, error }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenTabsQuery,
  useListenTabContentQuery,
  useListenAllTabContentQuery,
  useListenProgramGroupsQuery,
  useListenProgramWorkoutsQuery,
  useListenCoachProgramsQuery,
  useListenProgramQuery,
  useSetTabContentMutation,
  useUpdateTabsMutation,
} = layoutApi
